import React from 'react'
import { FaCalendarAlt } from 'react-icons/fa'


const ArticleHeader = (props) => {
    const { title } = props;
    const { date } = props;
    return (
        <div className='blog-post-header'>
            <h1>{title}</h1>
            <p><span className='me-2'><FaCalendarAlt /></span>Updated on: {date}</p>
        </div>
    )
}

export default ArticleHeader