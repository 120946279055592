import { useState } from "react";
import { HashLink } from "react-router-hash-link";
import Itinerary from "./Itinerary";
import TripInfo from "./TripInfo";
import TripDetail from "./TripDetail";
import Overview from "./Overview";
import Gallery from "./Gallery";
import Footer from "../../components/Footer";
import { Link, Route, Routes } from "react-router-dom";
import EBCHeliTourluxuryIcon from "../../components/Luxurypackage/EBCHeliTourluxuryIcon";
import ScrollToTop from "../../components/ScrollToTop";

const Everest = () => {
  const [overview, setOverView] = useState(false);
  const [itinerary, setItinerary] = useState(false);
  const [tripDetail, setTripDetail] = useState(false);
  const [gallery, setGallery] = useState(false);
  const [tripInfo, setTripInfo] = useState(true);
  const overviewHandler = () => {
    setOverView(true);
    setItinerary(false);
    setTripDetail(false);
    setGallery(false);
    setTripInfo(false);
  };

  const itineraryHandler = () => {
    setOverView(false);
    setItinerary(true);
    setTripDetail(false);
    setGallery(false);
    setTripInfo(false);
  };

  const tripDetailHandler = () => {
    setOverView(false);
    setItinerary(false);
    setTripDetail(true);
    setGallery(false);
    setTripInfo(false);
  };
  const galleryHandler = () => {
    setOverView(false);
    setItinerary(false);
    setTripDetail(false);
    setGallery(true);
    setTripInfo(false);
  };

  const tripInfolHandler = () => {
    setOverView(false);
    setItinerary(false);
    setTripDetail(false);
    setTripInfo(true);
  };

  const onTripChange = () => {
    sessionStorage.removeItem("tripTitle");
    sessionStorage.setItem("tripTitle", "Everest Base Camp Helicopter");
    sessionStorage.setItem(
      "tripPrice",
      "Sharing: $1050 pp | Private: $3900 pp"
    );
  };
  // console.log("setOverView", overview, itinerary, tripDetail, tripInfo);
  return (
    <>
      <ScrollToTop position='header' />
      <div className='trip' id='top'>
        <div className='trip-hero ebc-heli'>
          <div className='trip-box'>
            <div className='trip-detail'>
              <p className='main-title'>Everest Base Camp Helicopter</p>
              <div className='activity d-flex justify-content-center align-items-center'>
                <div className='luxury-package-info'>
                  <p className='text-center text-light ms-2'>Luxury package </p>
                </div>
                <EBCHeliTourluxuryIcon />
              </div>
              <div className='activity'>
                <p>
                  Activity: <span className='trip-modal-title'>Heli Tour</span>
                </p>
              </div>
              <div className='activity'>
                <p>
                  Private: <span className='trip-modal-title'>$3900</span>
                </p>
              </div>
              <div className='activity'>
                <p>
                  Sharing: <span className='trip-modal-title'>$950 pp</span>
                </p>
              </div>
              <div className='activity'>
                <p>
                  Luxury Sharing:
                  <span className='trip-modal-title'>$1250 pp</span>
                </p>
              </div>

              <p className='view-trip'>
                <HashLink
                  smooth
                  to='/booking'
                  className='link'
                  onClick={onTripChange}
                >
                  <div className='hover-underline-animation-black'>
                    Book Now
                  </div>
                </HashLink>
              </p>
            </div>
          </div>
        </div>
        <div>
          <div className='trip-navbar d-flex py-2' id='body'>
            <p className={tripInfo ? "active" : ""}>
              <Link
                onClick={tripInfolHandler}
                to='/everest-base-camp-helicopter-tour/info'
                className='link'
              >
                INFO
              </Link>
            </p>
            <span> &#x2022; </span>
            <p className={overview ? "active" : ""}>
              <Link
                onClick={overviewHandler}
                to='/everest-base-camp-helicopter-tour/overview'
                className='link'
              >
                OVERVIEW
              </Link>
            </p>
            <span> &#x2022; </span>

            <p className={itinerary ? "active" : ""}>
              <Link
                onClick={itineraryHandler}
                to='/everest-base-camp-helicopter-tour/itinerary'
                className='link'
              >
                ITINERARY
              </Link>
            </p>
            <span> &#x2022; </span>

            <p className={tripDetail ? "active" : ""}>
              <Link
                onClick={tripDetailHandler}
                to='/everest-base-camp-helicopter-tour/detail'
                className='link'
              >
                DETAIL
              </Link>
            </p>
            {/* <span> &#x2022; </span>
            <p className={gallery ? "active" : ""}>
              <Link onClick={galleryHandler} to="/booking" className="link">
                GALLERY
              </Link>
            </p> */}
            <span> &#x2022; </span>
            <p>
              <Link onClick={onTripChange} to='/booking' className='link'>
                BOOK NOW
              </Link>
            </p>
          </div>
          <Routes>
            <Route path='info' element={<TripInfo />} />
            <Route path='overview' element={<Overview />} />
            <Route path='itinerary' element={<Itinerary />} />
            <Route path='detail' element={<TripDetail />} />
            <Route path='gallery' element={<Gallery />} />
          </Routes>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default Everest;
