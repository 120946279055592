import React, { useState } from "react";
import {
  AiOutlineArrowUp,
  AiOutlineArrowDown,
  AiOutlineCloseCircle,
} from "react-icons/ai";
import { HashLink } from "react-router-hash-link";

const Modals = (props) => {
  const { tripTitle, sendmodaldetails } = props;
  const { id, modalhead, modaldetail } = sendmodaldetails;
  const [expandModal, setExpandModal] = useState(false);

  const modaldetailtrip = [
    {
      id: 1,
      modaldetail: [
        {
          modaltitle: "",
          modaltext: "",
        },
        {
          modaltitle: "Overview:",
          modaltext:
            "One of the best ways to see the Himalayas up close is on an Everest base camp tour. From Kathmandu, it will take about 3 to 4 hours to get to Everest base camp by helicopter. The Everest helicopter tour is a good choice for people who do not have much time. People of all ages and with disabilities can enjoy this kind of travel. So, a helicopter tour is a great choice. ",
        },
      ],
    },
    {
      id: 2,
      modaldetail: [
        {
          modaltitle: "Khumbu Region:",
          modaltext:
            "Mt. Everest is in the northeastern part of Nepal, in a place called Khumbu. The Sagarmatha National Park protects the Khumbu area. It starts at Monjo and goes all the way to the top of Everest. There are many well-known places to live, such as Lukla, Phakding, Namche Bazaar, Tengboche, Thame, Phortse, Chhomrong, Gokyo, Pangboche, Dingboche, Lobuche, and Gorak Shep. From Lukla to Everest, the elevation changes from 2,860 meters to 8,849 meters. The Khumbu region is also known for its beautiful alpine lakes, high mountain passes, moraines, glaciers, streams, and wildlife.",
        },
        {
          modaltitle: "Difficulty Level:",
          modaltext:
            "Compared to other tours and treks, the Everest helicopter tour is easy. The Everest helicopter tour does not include anything difficult. This is a great tour package for a family trip to Nepal that you can take with your kids and older family members. For a helicopter tour to EBC, you only need to set aside 4–5 hours of your day.",
        },
        {
          modaltitle: "Terrain:",
          modaltext:
            "The walking part only involves when you land at Kala patthar and at Everest View Hotel. It is just simple walking on rough terrain for a few minutes. Your body will not feel any stress or exhaustion.",
        },
        {
          modaltitle: "Altitude Sickness:",
          modaltext:
            "Even though the Everest Base Camp Helicopter Tour with Landing gets around all the physical problems, there is still a chance of getting altitude sickness. At the end of the day, you will fly from Kathmandu, which is 1,400 meters high, to Kalapatthar, which is 5,644 meters high. In a short amount of time, the altitude goes up a lot, which may cause some travelers to get altitude sickness. Even so, the chances are very low. We have not seen anyone get altitude sickness on the Everest base camp Heli tour up to this point. Since the trip is short, you will be able to get away from altitude sickness. We do not stay more than 10 minutes at Kalapatthar to avoid even a small chance of altitude sickness.",
        },
        {
          modaltitle: "Team:",
          modaltext:
            "We are a group of experts who have been working together for a long time to plan trips in the Himalayas. We have been working with one of the most experienced aviation companies in Nepal, which has been flying in the Himalayas for many years, on the Everest Base Camp helicopter tour. Together, our team and the flight crew can make sure your trip is safe and easy.",
        },
        {
          modaltitle: "Support:",
          modaltext:
            "When you travel with us in Nepal, we take care of everything you need. The people on our team will make sure that everything meets your needs. We get the necessary permits and help you get on board for the Everest helicopter tour and landing. We make sure that both booking and getting on the plane go smoothly. During the flight, our team will help you and make sure you are safe. Our goal for the Everest Base Camp Helicopter Tour with Landing is to make it as memorable as possible without putting your safety at risk.",
        },
        {
          modaltitle: "Equipment:",
          modaltext:
            "Since the Everest Base Camp tour is only one day, you will not need to bring a lot of things. Wear normal clothes and a windproof down jacket, which you can rent in Thamel. Also, wear warm socks and comfortable shoes. Bring a sun hat, sunglasses with UV protection, a reusable water bottle, toiletries (tissue paper, sunscreen, lip balm, etc.), a camera, your passport, and any other important documents",
        },
      ],
    },
    {
      id: 3,
      modaldetail: [
        {
          modaltitle: "Khumbu Region:",
          modaltext:
            "Mt. Everest is in the northeastern part of Nepal, in a place called Khumbu. The Sagarmatha National Park protects the Khumbu area. It starts at Monjo and goes all the way to the top of Everest. There are many well-known places to live, such as Lukla, Phakding, Namche Bazaar, Tengboche, Thame, Phortse, Chhomrong, Gokyo, Pangboche, Dingboche, Lobuche, and Gorak Shep. From Lukla to Everest, the elevation changes from 2,860 meters to 8,849 meters. The Khumbu region is also known for its beautiful alpine lakes, high mountain passes, moraines, glaciers, streams, and wildlife.",
        },
        {
          modaltitle: "Difficulty Level:",
          modaltext:
            "Compared to other tours and treks, the Everest helicopter tour is easy. The Everest helicopter tour does not include anything difficult. This is a great tour package for a family trip to Nepal that you can take with your kids and older family members. For a helicopter tour to EBC, you only need to set aside 4–5 hours of your day.",
        },
        {
          modaltitle: "Terrain:",
          modaltext:
            "The walking part only involves when you land at Kala patthar and at Everest View Hotel. It is just simple walking on rough terrain for a few minutes. Your body will not feel any stress or exhaustion.",
        },
        {
          modaltitle: "Altitude Sickness:",
          modaltext:
            "Even though the Everest Base Camp Helicopter Tour with Landing gets around all the physical problems, there is still a chance of getting altitude sickness. At the end of the day, you will fly from Kathmandu, which is 1,400 meters high, to Kalapatthar, which is 5,644 meters high. In a short amount of time, the altitude goes up a lot, which may cause some travelers to get altitude sickness. Even so, the chances are very low. We have not seen anyone get altitude sickness on the Everest base camp Heli tour up to this point. Since the trip is short, you will be able to get away from altitude sickness. We do not stay more than 10 minutes at Kalapatthar to avoid even a small chance of altitude sickness.",
        },
        {
          modaltitle: "Team:",
          modaltext:
            "We are a group of experts who have been working together for a long time to plan trips in the Himalayas. We have been working with one of the most experienced aviation companies in Nepal, which has been flying in the Himalayas for many years, on the Everest Base Camp helicopter tour. Together, our team and the flight crew can make sure your trip is safe and easy.",
        },
        {
          modaltitle: "Support:",
          modaltext:
            "When you travel with us in Nepal, we take care of everything you need. The people on our team will make sure that everything meets your needs. We get the necessary permits and help you get on board for the Everest helicopter tour and landing. We make sure that both booking and getting on the plane go smoothly. During the flight, our team will help you and make sure you are safe. Our goal for the Everest Base Camp Helicopter Tour with Landing is to make it as memorable as possible without putting your safety at risk.",
        },
        {
          modaltitle: "Equipment:",
          modaltext:
            "Since the Everest Base Camp tour is only one day, you will not need to bring a lot of things. Wear normal clothes and a windproof down jacket, which you can rent in Thamel. Also, wear warm socks and comfortable shoes. Bring a sun hat, sunglasses with UV protection, a reusable water bottle, toiletries (tissue paper, sunscreen, lip balm, etc.), a camera, your passport, and any other important documents",
        },
      ],
    },
    {
      id: 4,
      modaldetail: [
        {
          modaltitle: "Cuisine:",
          modaltext:
            "You can have either Nepalese or Continental food for breakfast. You will have a few food options for breakfast. In the same way, you can choose from drinks like tea, coffee, juice, etc. Pancakes, sandwiches, sausage, eggs, baked goods, soup, porridge, and other things will be available. If you want to be more fancy, you can order a glass of wine or champagne (additional charges applicable).",
        },
        {
          modaltitle: "Nepali Flavors:",
          modaltext:
            "For Nepali breakfast, there are options like roti tarkari, puri tarkari, Momo, Khaja set, etc.",
        },
        {
          modaltitle: "Suitability:",
          modaltext:
            "There are options for vegetarians, vegans, and gluten-free diners.",
        },
      ],
    },
    {
      id: 5,
      modaldetail: [
        // {
        //   modaltitle: "Khumbu Region:",
        //   modaltext:
        //     "Mt. Everest is located in the Khumbu region, the northeastern part of Nepal. Also known as the Everest region, it is part of the Solukhumbu district. Khumbu region is protected under the Sagarmatha National Park, which starts at Monjo & goes up to the top of Everest. Lukla, Phakding, Namche Bazaar, Tengboche, Thame, Phortse, Chhomrong, Gokyo, Pangboche, Dingboche, Lobuche, and Gorak Shep are famous settlement areas. From Lukla to Everest, the elevation varies 2,860 meters to 8,849 meters. The Khumbu region is also famous for its gorgeous alpine lakes, high mountain passes, moraines, glaciers, streams, and wildlife.",
        // },
        // {
        //   modaltitle: "Difficulty Level:",
        //   modaltext:
        //     "As we have been mentioning from the very start, the Everest base camp helicopter tour is easy. Unlike trekking, you do not need to walk 6 hours a day on steep, rugged paths whatsoever. There is no challenging factor in the Everest helicopter tour. This is a perfect family tour package in Nepal that you can do with your kids & senior members. You only need to commit 4 to 5 hours of your day for a helicopter tour to EBC.",
        // },
        // {
        //   modaltitle: "Terrain:",
        //   modaltext:
        //     "The walking part only involves when you land at Kala Patthar and at Everest View Hotel. It's simple walking on rough terrain for a few minutes. Your body will not see any stress or exhaustion.",
        // },
        // {
        //   modaltitle: "Altitude Sickness:",
        //   modaltext:
        //     "Even though the Everest base camp helicopter tour with landing bypasses all the physical difficulties, the risks of altitude sickness still remain there. At the end of the day, you'll be flying from Kathmandu at 1,400 m to Kala Patthar at 5,644 m. The elevation increase is drastic in the short time frame, which may trigger altitude sickness in some travelers. The chances are very less likely, though. Till this date, we have not seen any participants getting altitude sickness during the Everest base camp heli tour. It's a quick trip, so you'll get the opportunity to escape from altitude sickness. To reduce even the slightest possibility of altitude sickness, we do not stay more than 10 minutes at Kala Patthar.",
        // },
        // {
        //   modaltitle: "Team:",
        //   modaltext:
        //     "At Adventure Master, we have a professional team of individuals who love traveling and have been organizing ventures in the Himalayas for numerous years. For the Everest base camp helicopter tour, we are working with one of the most experienced aviation companies in Nepal, who have been flying in the Himalayas for multiple decades. Our office team and flight crew together make the finest team that you can fly with, in the Himalayas freely.",
        // },
        // {
        //   modaltitle: "Support:",
        //   modaltext:
        //     "We take care of all of your requests while traveling with us in Nepal. Our team will make sure everything is as per your requirements. For the Everest helicopter tour with landing, we obtain the necessary permits and help you with boarding. We make sure the booking and boarding both go seemingly. Our team will assist you during the flight and take care of your safety. Our goal is to make the Everest base camp helicopter tour with landing as memorable as possible but not at the cost of your safety.",
        // },
        // {
        //   modaltitle: "Equipment:",
        //   modaltext:
        //     "Another great thing about the Everest base camp helicopter tour is that you'll not need to pack numerous things. Wear normal appropriate clothes and a windproof down jacket (you can rent it in Thamel) with comfortable shoes & warm socks. Carry sun hat, UV protection sunglasses, reusable water bottle, toiletries (tissue paper, sunscreen, lip balm, etc), camera, passport & other documents.",
        // },
      ],
    },
    {
      id: 6,
      modaldetail: [
        {
          modaltitle: "Overview:",
          modaltext:
            "Everest base camp helicopter tour with landing has become the most popular package in 2022 and has been chosen as the best trip in the Khumbu region for 2023 and 2024. It is a once-in-a-lifetime experience that you will always remember and love. Since the helicopter tour is in the morning, you will have the rest of the day to yourself. The flight lets you witness breathtaking mountain views and glaciers up close. You will meet Sherpas and learn about their way of life. Far from the modern world, the Everest region is a place where nature and people live together. It will leave you in awe and wondering what it would be like to live in nature with all the comforts of modern life.",
        },
        {
          modaltitle: "Mountains:",
          modaltext:
            "You can see villages, valleys, rivers, streams, forests, moraines, and glaciers from above. On the tour, you can see mountains like Everest, Lhotse, Makalu, Cho Oyu, Kanchenjunga, Kongde Ri, Chamlang, Ama Dablam, Thamserku, Pumori, Island Peak, and more. The chance to see the Himalayas in a way that no one else can. The Himalayan mountains look just as beautiful from the air as they do on the ground. ",
        },
        {
          modaltitle: "ATM/Currency:",
          modaltext:
            "You will not get a chance to withdraw money once the Everest base camp helicopter tour begins. Therefore, get the money in Kathmandu. Well, you do not need additional money during the tour unless you are thinking of buying food & drinks offered out of the package. Have some money to tip. You can only use Nepali currency in the mountains.",
        },
        {
          modaltitle: "Internet:",
          modaltext:
            "You can get paid WIFI in the Hotel Everest View. Likewise, you can carry a Nepali sim to use cellular data throughout the tour, which is ideal. You can ask our team to help you get a Nepali sim with data. The internet connection may get unstable and poor at times",
        },
        {
          modaltitle: "Locals:",
          modaltext:
            "You meet with natives of the mountain on our helicopter tour to Kala Patthar. Expect to meet Sherpas and people from other communities. Unlike trekking, you will not have that huge opportunity to see their lifestyle and culture. The little interactions will keep you on your toes and make you happy.",
        },
        {
          modaltitle: "Language:",
          modaltext:
            "You can speak and communicate with the locals of the Khumbu region in English. The service providers can speak and understand the English language.",
        },
      ],
    },
  ];
  const expandHandler = () => {
    setExpandModal(!expandModal);
  };

  const collapseHandler = () => {
    setExpandModal(false);
  };

  const onTripChange = () => {
    sessionStorage.removeItem("tripTitle");
    sessionStorage.setItem("tripTitle", tripTitle);
  };

  return (
    <>
      <div className={`modals ${expandModal ? "expand" : ""}`}>
        <div className={`modal-head `} onClick={expandHandler}>
          <div className="modal-head-title">
            {!expandModal ? (
              <AiOutlineArrowUp fontSize="2rem" className="px-1" />
            ) : (
              <AiOutlineArrowDown fontSize="2rem" className="px-1" />
            )}
            <div className="head-title">{modalhead}</div>
          </div>
          <div className="iclose">
            {expandModal && (
              <AiOutlineCloseCircle
                fontSize="1.3rem"
                onClick={collapseHandler}
              />
            )}
          </div>
        </div>
        <div className={`modal-body ${expandModal ? "modal-body-expand" : ""}`}>
          {!expandModal ? (
            <>
              {modaldetail &&
                modaldetail?.map((detail, index) => (
                  <div className="modal-content" key={index}>
                    <div className="modal-title d-none d-sm-flex">
                      {detail.modaltitle}
                    </div>
                    <div className="modal-text">{detail.modaltext}</div>
                  </div>
                ))}
            </>
          ) : (
            <>
              {modaldetailtrip &&
                modaldetailtrip[id - 1]?.modaldetail?.map((detail, index) => (
                  <div className="modal-content" key={index}>
                    <div className="modal-title fw-bold">{detail.modaltitle}</div>
                    <div className="modal-text">{detail.modaltext}</div>
                  </div>
                ))}
            </>
          )}
        </div>
        <HashLink
          smooth
          to="/booking"
          className="modal-book link"
          style={{ color: "#000" }}
          onClick={onTripChange}
        >
          BOOK NOW
        </HashLink>
      </div>
    </>
  );
};

export default Modals;
