import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { HashLink } from "react-router-hash-link";

import header from "../../image/Logo/adventureMasterTrek.png";
import EverestRegion from "../../image/Everest/ebc.jpg";
import AnnapurnaRegion from "../../image/Annapurna/annapurnaBaseCamp.jpg";
import BuddhistPilgriamge from "../../image/BuddhistPilgriamge/swayambhu.jpg";
import ManasluRegion from "../../image/Manaslu/manasluCircuitTrek.jpg";

import { FaArrowAltCircleUp } from "react-icons/fa";

import whatsapp from "../../image/whatsapp.png";
import insta from "../../image/insta.png";

const Nav = () => {
  const [showNav, setShowNav] = useState(true);
  return (
    <>
      <div className="whatsapp-icon row">
        <div className="col">
          <a
            href="https://api.whatsapp.com/send/?phone=9779851033195&text&type=phone_number&app_absent=0"
            target="_blank"
            rel="noreferrer"
          >
            <img src={whatsapp} alt="whastapp" />
          </a>
        </div>
        <div className="col instaImg">
          <a
            href="https://www.instagram.com/adventuremastertrek/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={insta} alt="instagram" />
          </a>
        </div>
      </div>

      <div className="to-the-top">
        <a href="#" rel="noreferrer">
          <FaArrowAltCircleUp />
        </a>
      </div>

      <div className={`nav-main ${!showNav ? "nav-main-fixed" : ""}`}>
        <div
          onClick={() => setShowNav(!showNav)}
          class={`btns ${showNav ? "menu" : "open"}`}
        >
          <div className="icon-name btns d-none d-sm-flex">Explore</div>
          <div className={`btns icon ${showNav ? "menu" : "open"}`}>
            <div class="icon-left"></div>
            <div class="icon-right"></div>
          </div>
        </div>
        <div className="logo col-sm-12">
          <HashLink smooth to="/" onClick={() => setShowNav(true)}>
            <img src={header} alt="Adventure Master Trek" />
          </HashLink>
        </div>
      </div>
      <div
        className={`top-nav ${showNav ? "top-nav-close" : ""}`}
        onClick={() => setShowNav(true)}
      >
        <div className="nav-options">
          <Row className="justify-content-center">
            <Col md={3} className="text-center nav-option ">
              <div className="hover-underline-animation-white">
                <HashLink smooth to="/about" className="link">
                  ABOUT
                </HashLink>
              </div>
            </Col>
            <Col md={3} className="text-center nav-option">
              <div className="hover-underline-animation-white">
                <HashLink smooth to="/contact" className="link">
                  CONTACT
                </HashLink>
              </div>
            </Col>
            <Col md={3} className="text-center nav-option">
              <div className="hover-underline-animation-white">
                <a
                  href="https://www.tripadvisor.in/Attraction_Review-g293890-d8661857-Reviews-Adventure_Master_Treks-Kathmandu_Kathmandu_Valley_Bagmati_Zone_Central_Region.html"
                  className="link"
                  rel="noreferrer"
                  target="_blank"
                >
                  REVIEWS
                </a>
              </div>
            </Col>
          </Row>
        </div>
        <div className="nav-trips">
          <div className="nav-trip text-center">
            <HashLink smooth to="/everest-region" className="link">
              <div className="nav-trip-image">
                <img src={EverestRegion} alt="navimage" className="nav-img" />
              </div>
              <div className="nav-desc hover-underline-animation-white">
                Explore Everest Region
              </div>
            </HashLink>
          </div>

          <div className="nav-trip text-center">
            <HashLink smooth to="/annapurna-region" className="link">
              <img src={AnnapurnaRegion} alt="navimage" className="nav-img" />
              <div className="nav-desc hover-underline-animation-white">
                Explore Annapurna Region
              </div>
            </HashLink>
          </div>

          <div className="nav-trip text-center">
            <HashLink
              smooth
              to="/buddhist-pilgrimage-tour-7-days"
              className="link"
            >
              <img
                src={BuddhistPilgriamge}
                alt="navimage"
                className="nav-img"
              />
              <div className="nav-desc hover-underline-animation-white">
                Buddhist Pilgriamge Tour
              </div>
            </HashLink>
          </div>
          <div className="nav-trip text-center">
            <HashLink smooth to="/manaslu-circuit-trek" clasName="link">
              <img src={ManasluRegion} alt="navimage" className="nav-img" />
              <div className="nav-desc hover-underline-animation-white">
                Explore Manaslu Region
              </div>
            </HashLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default Nav;
