import { FaWhatsapp, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";
import Iframe from "react-iframe";
import Header from "../pages/components/Header";
import Footer from "../pages/components/Footer";
import { Container } from "react-bootstrap";
import ScrollToTop from "./components/ScrollToTop";

const ContactPage = () => {
  return (
    <>
      <ScrollToTop position="top" />
      <div>
        <Header title="Contact Us" />
        <div className="container contact " id="top">
          <div className="row">
            <div className="col-6">
              <h2>GET IN TOUCH</h2>
              <p>
                Ask questions, request more info, or connect with a private trip
                planner by contacting us:
              </p>
              <br></br>
              <p>
                <FaEnvelope />{" "}
                <a className="" href="mailto:adventure@mail.com" rel="noreferrer">
                  info@adventuremasterstrek.com
                </a>
              </p>
              <p>
                <a
                  href="https://api.whatsapp.com/send/?phone=9779851033195&text&type=phone_number&app_absent=0"
                  target="_blank" rel="noreferrer"
                >
                  <FaWhatsapp /> +977 985-1033195
                </a>
              </p>
              <p>
                <FaMapMarkerAlt />
                Thamel, Kathmandu, Nepal
              </p>
            </div>
            <div className="col-6">
              <form></form>
            </div>
          </div>
        </div>
        <Container className="mb-5">
          <Iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d403.9593820152931!2d85.3113652092521!3d27.71587052374091!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xfaf91016f607366d!2sAdventure%20Master%20Trek!5e0!3m2!1sen!2snp!4v1672038775696!5m2!1sen!2snp"
            width="100%"
            height="450"
            style={{ border: "0" }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            className=""
          ></Iframe>
        </Container>
        <Footer />
      </div></>
  );
};

export default ContactPage;
