import React from 'react'
import { Container } from 'react-bootstrap'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { FaGoogle, FaTripadvisor, FaStar } from 'react-icons/fa';

function Testimonial() {
    return (
        <>
            <Container className='py-5 my-3'>
                <h4 className='title text-center mb-5'>TRAVELER'S REVIEWS</h4>
                <div id="reviewSlider" className="carousel slide home-review-slider" data-bs-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active text-center">
                            <h4 className='title'><FaTripadvisor className='me-2 fs-1 text-success' />5-day Himalaya trekking experience with Adventure Master</h4>
                            <div className='text-warning'>
                                <FaStar />
                                <FaStar />
                                <FaStar />
                                <FaStar />
                                <FaStar />
                            </div>
                            <p className='content text-center'>I had a 5-day trekking trip with guide Sital to Annapurna base camp. Adventure Master was an excellent trekking agency with good service and easy communication. The service price was reasonable. Sital was an extravert and energetic guide. He knew the trekking routes well and planned everything for me perfectly in advance. My schedule was very tight so he was happy to trek with me everyday more than 8 hours. He spoke perfect English and had knowledge about Nepali culture and history. I had a very enjoyable trip with Adventure Master and Sital.</p>
                            <p><strong>Date: Jan 2023</strong></p>
                            <p><AccountCircleIcon /><strong>Thomas Z</strong></p>
                        </div>
                        <div className="carousel-item text-center">
                            <h4 className='title'><FaTripadvisor className='me-2 fs-1 text-success' />Best experience</h4>
                            <div className='text-warning'>
                                <FaStar />
                                <FaStar />
                                <FaStar />
                                <FaStar />
                                <FaStar />
                            </div>
                            <p className='content text-center'>I went on a hike in the amazing everest region and absolutely loved it. I hiked up lobuche east and it was such an special and unique adventure. My trip has been organised within just a few days and also based on my fitness. It was an outstanding experience and im definitely gonna be back. Big thanks to the team that made it possible. I knew i was in good hands and I never had the feeling they were just trying to make money on me.
                                This company just tries to make your stay in Nepal unforgettable</p>
                            <p><strong>Date: Dec 2022</strong></p>
                            <p><AccountCircleIcon /><strong>Michaela</strong></p>
                        </div>
                        <div className="carousel-item text-center">
                            <h4 className='title'><FaGoogle className='me-2 fs-1 text-primary' />Mardi Himal Trek</h4>
                            <div className='text-warning'>
                                <FaStar />
                                <FaStar />
                                <FaStar />
                                <FaStar />
                                <FaStar />
                            </div>
                            <p className='content text-center'>They were good to deal with all the way from booking to the hike.  We did the 5d/4n Mardi Himal hike.

                                Booking was easily made by WhatsApp. Good information was provided by the company and quick answers to our questions.

                                Our guide Sital  took his care of us, as we were not experienced outdoor people and adjusted the itinerary to suit our abilities.

                                Very happy with the guide and the company.  Highly recommended.</p>
                            <p><strong>Date: Dec 2022</strong></p>
                            <p><AccountCircleIcon /><strong>Alex Chan</strong></p>
                        </div>
                        <div className="carousel-item text-center">
                            <h4 className='title'><FaTripadvisor className='me-2 fs-1 text-success' />Trekking without any worries</h4>
                            <div className='text-warning'>
                                <FaStar />
                                <FaStar />
                                <FaStar />
                                <FaStar />
                                <FaStar />
                            </div>
                            <p className='content text-center'>Very kind people and extraordinary service! Very trustworthy and quick help when I had to book a flight from Lukla to Kathmandu after my trekking. After my flight was delayed they even organized a taxi to bring my luggage to the airport so I could reach my connecting flight in time. Highly recommendable :) Thanks for everything!</p>
                            <p><strong>Date: Dec 2022</strong></p>
                            <p><AccountCircleIcon /><strong>Jim D</strong></p>
                        </div>
                        <div className="carousel-item text-center">
                            <h4 className='title'><FaTripadvisor className='me-2 fs-1 text-success' />Annapurna circuit trek with adventure master</h4>
                            <div className='text-warning'>
                                <FaStar />
                                <FaStar />
                                <FaStar />
                                <FaStar />
                                <FaStar />
                            </div>
                            <p className='content text-center'>I completed annapurna circuit with adventure master team , it was great experience .well managed n organised through out my trip , sital was friendly guide , one of the professional trekking company in nepal , looking forward lot more upcoming treks , all the best</p>
                            <p><strong>Date: Dec 2022</strong></p>
                            <p><AccountCircleIcon /><strong>Praveen walishetti</strong></p>
                        </div>
                        <div className="carousel-item text-center">
                            <h4 className='title'><FaTripadvisor className='me-2 fs-1 text-success' />A great honeymoon</h4>
                            <div className='text-warning'>
                                <FaStar />
                                <FaStar />
                                <FaStar />
                                <FaStar />
                                <FaStar />
                            </div>
                            <p className='content text-center'>Adventure Master gave my wife and I a wondeful honeymoon experience. The people, the foods, the various temple sites and the sunrise at Pokhara. The Hotel Star Street 13 with an elevator presented us with fantatsic service and food.</p>
                            <p><strong>Date: Nov 2022</strong></p>
                            <p><AccountCircleIcon /><strong>thecakeman3404</strong></p>
                        </div>
                        <div className="carousel-item text-center">
                            <h4 className='title'><FaGoogle className='me-2 fs-1 text-primary' />Annapurna Circuit Trek</h4>
                            <div className='text-warning'>
                                <FaStar />
                                <FaStar />
                                <FaStar />
                                <FaStar />
                                <FaStar />
                            </div>
                            <p className='content text-center'>Exceptional multi-day trek of the Annapurna Circuit route with a very thoughtful and experienced guide, Guy. I would highly recommend to both experienced trekkers and newcomers. I had limited prior experience in the mountains, in fact this was my first visit to Himalayas, and was made to feel comfortable and confident in my ability every step of the journey. The accommodations and food on offer were excellent. Communication with the guide and Adventure Master staff was always super clear and friendly.</p>
                            <p><strong>Date: Dec 2022</strong></p>
                            <p><AccountCircleIcon /><strong>James T</strong></p>
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#reviewSlider" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#reviewSlider" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
                <div className='d-flex justify-content-center mt-5'>
                    <a href="https://www.tripadvisor.com/Attraction_Review-g293890-d8661857-Reviews-Adventure_Master_Trek-Kathmandu_Kathmandu_Valley_Bagmati_Zone_Central_Region.html" target={"_blank"}><button className='btn btn-outline-dark'>View All Reiviews</button></a>
                </div>
            </Container>
        </>
    )
}

export default Testimonial