import * as React from "react";
import Box from "@mui/material/Box";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { Container } from "react-bootstrap";

import annapurnaTilicho1 from "../../../image/Annapurna/AnnapurnaCircuitTilicho/1.jpg";
import annapurnaTilicho2 from "../../../image/Annapurna/AnnapurnaCircuitTilicho/2.jpg";
import annapurnaTilicho3 from "../../../image/Annapurna/AnnapurnaCircuitTilicho/3.jpg";
import annapurnaTilicho4 from "../../../image/Annapurna/AnnapurnaCircuitTilicho/4.jpg";
import annapurnaTilicho5 from "../../../image/Annapurna/AnnapurnaCircuitTilicho/5.jpg";
import annapurnaTilicho6 from "../../../image/Annapurna/AnnapurnaCircuitTilicho/6.jpg";
import annapurnaTilicho7 from "../../../image/Annapurna/AnnapurnaCircuitTilicho/7.jpg";
import annapurnaTilicho8 from "../../../image/Annapurna/AnnapurnaCircuitTilicho/8.jpg";
import annapurnaTilicho9 from "../../../image/Annapurna/AnnapurnaCircuitTilicho/9.jpg";
import annapurnaTilicho10 from "../../../image/Annapurna/AnnapurnaCircuitTilicho/10.jpg";
import annapurnaTilicho11 from "../../../image/Annapurna/AnnapurnaCircuitTilicho/11.jpg";
import annapurnaTilicho12 from "../../../image/Annapurna/AnnapurnaCircuitTilicho/12.jpg";
import annapurnaTilicho13 from "../../../image/Annapurna/AnnapurnaCircuitTilicho/13.jpg";
import annapurnaTilicho14 from "../../../image/Annapurna/AnnapurnaCircuitTilicho/14.jpg";
import annapurnaTilicho15 from "../../../image/Annapurna/AnnapurnaCircuitTilicho/15.jpg";
import annapurnaTilicho16 from "../../../image/Annapurna/AnnapurnaCircuitTilicho/16.jpg";
import annapurnaTilicho17 from "../../../image/Annapurna/AnnapurnaCircuitTilicho/17.jpg";
import annapurnaTilicho18 from "../../../image/Annapurna/AnnapurnaCircuitTilicho/18.jpg";
import annapurnaTilicho19 from "../../../image/Annapurna/AnnapurnaCircuitTilicho/19.jpg";
import annapurnaTilicho20 from "../../../image/Annapurna/AnnapurnaCircuitTilicho/20.jpg";
import annapurnaTilicho21 from "../../../image/Annapurna/AnnapurnaCircuitTilicho/21.jpg";
import annapurnaTilicho22 from "../../../image/Annapurna/AnnapurnaCircuitTilicho/22.jpg";
import annapurnaTilicho23 from "../../../image/Annapurna/AnnapurnaCircuitTilicho/23.jpg";
import annapurnaTilicho24 from "../../../image/Annapurna/AnnapurnaCircuitTilicho/24.jpg";
import annapurnaTilicho25 from "../../../image/Annapurna/AnnapurnaCircuitTilicho/25.jpg";
import annapurnaTilicho26 from "../../../image/Annapurna/AnnapurnaCircuitTilicho/26.jpg";
import annapurnaTilicho27 from "../../../image/Annapurna/AnnapurnaCircuitTilicho/27.jpg";
import annapurnaTilicho28 from "../../../image/Annapurna/AnnapurnaCircuitTilicho/28.jpg";
import annapurnaTilicho29 from "../../../image/Annapurna/AnnapurnaCircuitTilicho/29.jpg";
import annapurnaTilicho30 from "../../../image/Annapurna/AnnapurnaCircuitTilicho/30.jpg";
import annapurnaTilicho31 from "../../../image/Annapurna/AnnapurnaCircuitTilicho/31.jpg";
import annapurnaTilicho32 from "../../../image/Annapurna/AnnapurnaCircuitTilicho/32.jpg";
import annapurnaTilicho33 from "../../../image/Annapurna/AnnapurnaCircuitTilicho/33.jpg";
import annapurnaTilicho34 from "../../../image/Annapurna/AnnapurnaCircuitTilicho/34.jpg";
import annapurnaTilicho35 from "../../../image/Annapurna/AnnapurnaCircuitTilicho/35.jpg";
import annapurnaTilicho36 from "../../../image/Annapurna/AnnapurnaCircuitTilicho/36.jpg";
import annapurnaTilicho37 from "../../../image/Annapurna/AnnapurnaCircuitTilicho/37.jpg";
import annapurnaTilicho38 from "../../../image/Annapurna/AnnapurnaCircuitTilicho/38.jpg";
import annapurnaTilicho39 from "../../../image/Annapurna/AnnapurnaCircuitTilicho/39.jpg";
import annapurnaTilicho40 from "../../../image/Annapurna/AnnapurnaCircuitTilicho/40.jpg";
import annapurnaTilicho41 from "../../../image/Annapurna/AnnapurnaCircuitTilicho/41.jpg";
import annapurnaTilicho42 from "../../../image/Annapurna/AnnapurnaCircuitTilicho/42.jpg";
import annapurnaTilicho43 from "../../../image/Annapurna/AnnapurnaCircuitTilicho/43.jpg";
import annapurnaTilicho44 from "../../../image/Annapurna/AnnapurnaCircuitTilicho/44.jpg";
import annapurnaTilicho45 from "../../../image/Annapurna/AnnapurnaCircuitTilicho/45.jpg";

export default function MasonryImageList() {
  return (
    <>
      <h4 className="title mt-5">Photo Gallery</h4>
      <Box
        className="gallery-box mb-5"
        sx={{ overflowY: "scroll" }}
        style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
      >
        <ImageList variant="masonry" cols={3} gap={8}>
          {itemData.map((item) => (
            <ImageListItem key={item.img}>
              <img
                src={`${item.img}?w=248&fit=crop&auto=format`}
                srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                alt={item.title}
                // loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
    </>
  );
}

const itemData = [
  {
    img: annapurnaTilicho1,
    title: "annapurna circuit with tilicho lake trek",
  },
  {
    img: annapurnaTilicho2,
    title: "annapurna circuit with tilicho lake trek",
  },
  {
    img: annapurnaTilicho3,
    title: "annapurna circuit with tilicho lake trek",
  },
  {
    img: annapurnaTilicho4,
    title: "annapurna circuit with tilicho lake trek",
  },
  {
    img: annapurnaTilicho5,
    title: "annapurna circuit with tilicho lake trek",
  },
  {
    img: annapurnaTilicho6,
    title: "annapurna circuit with tilicho lake trek",
  },
  {
    img: annapurnaTilicho7,
    title: "annapurna circuit with tilicho lake trek",
  },
  {
    img: annapurnaTilicho8,
    title: "annapurna circuit with tilicho lake trek",
  },
  {
    img: annapurnaTilicho9,
    title: "annapurna circuit with tilicho lake trek",
  },
  {
    img: annapurnaTilicho10,
    title: "annapurna circuit with tilicho lake trek",
  },
  {
    img: annapurnaTilicho11,
    title: "annapurna circuit with tilicho lake trek",
  },
  {
    img: annapurnaTilicho12,
    title: "annapurna circuit with tilicho lake trek",
  },
  {
    img: annapurnaTilicho13,
    title: "annapurna circuit with tilicho lake trek",
  },
  {
    img: annapurnaTilicho14,
    title: "annapurna circuit with tilicho lake trek",
  },
  {
    img: annapurnaTilicho15,
    title: "annapurna circuit with tilicho lake trek",
  },
  {
    img: annapurnaTilicho16,
    title: "annapurna circuit with tilicho lake trek",
  },
  {
    img: annapurnaTilicho17,
    title: "annapurna circuit with tilicho lake trek",
  },
  {
    img: annapurnaTilicho18,
    title: "annapurna circuit with tilicho lake trek",
  },
  {
    img: annapurnaTilicho19,
    title: "annapurna circuit with tilicho lake trek",
  },
  {
    img: annapurnaTilicho20,
    title: "annapurna circuit with tilicho lake trek",
  },
  {
    img: annapurnaTilicho21,
    title: "annapurna circuit with tilicho lake trek",
  },
  {
    img: annapurnaTilicho22,
    title: "annapurna circuit with tilicho lake trek",
  },
  {
    img: annapurnaTilicho23,
    title: "annapurna circuit with tilicho lake trek",
  },
  {
    img: annapurnaTilicho24,
    title: "annapurna circuit with tilicho lake trek",
  },
  {
    img: annapurnaTilicho25,
    title: "annapurna circuit with tilicho lake trek",
  },
  {
    img: annapurnaTilicho26,
    title: "annapurna circuit with tilicho lake trek",
  },
  {
    img: annapurnaTilicho27,
    title: "annapurna circuit with tilicho lake trek",
  },
  {
    img: annapurnaTilicho28,
    title: "annapurna circuit with tilicho lake trek",
  },
  {
    img: annapurnaTilicho29,
    title: "annapurna circuit with tilicho lake trek",
  },
  {
    img: annapurnaTilicho30,
    title: "annapurna circuit with tilicho lake trek",
  },
  {
    img: annapurnaTilicho31,
    title: "annapurna circuit with tilicho lake trek",
  },
  {
    img: annapurnaTilicho32,
    title: "annapurna circuit with tilicho lake trek",
  },
  {
    img: annapurnaTilicho33,
    title: "annapurna circuit with tilicho lake trek",
  },
  {
    img: annapurnaTilicho34,
    title: "annapurna circuit with tilicho lake trek",
  },
  {
    img: annapurnaTilicho35,
    title: "annapurna circuit with tilicho lake trek",
  },
  {
    img: annapurnaTilicho36,
    title: "annapurna circuit with tilicho lake trek",
  },
  {
    img: annapurnaTilicho37,
    title: "annapurna circuit with tilicho lake trek",
  },
  {
    img: annapurnaTilicho38,
    title: "annapurna circuit with tilicho lake trek",
  },
  {
    img: annapurnaTilicho39,
    title: "annapurna circuit with tilicho lake trek",
  },
  {
    img: annapurnaTilicho40,
    title: "annapurna circuit with tilicho lake trek",
  },
  {
    img: annapurnaTilicho41,
    title: "annapurna circuit with tilicho lake trek",
  },
  {
    img: annapurnaTilicho42,
    title: "annapurna circuit with tilicho lake trek",
  },
  {
    img: annapurnaTilicho43,
    title: "annapurna circuit with tilicho lake trek",
  },
  {
    img: annapurnaTilicho44,
    title: "annapurna circuit with tilicho lake trek",
  },
  {
    img: annapurnaTilicho45,
    title: "annapurna circuit with tilicho lake trek",
  },
];
