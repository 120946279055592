import React from "react";

import { FaWhatsapp } from "react-icons/fa";

const headquater = () => {
  return (
    <div>
      <div>
        <div className="row wrap footer-headquater flex-wrap">
          <div className="col-6 col-md-3 col-lg-3 mb-3 text-center">
            <h5 className="footer-title">Nepal</h5>
            <p>
              <a
                href="https://api.whatsapp.com/send/?phone=9779849780311&text&type=phone_number&app_absent=0"
                target="_blank"
                rel="noreferrer"
              >
                <FaWhatsapp /> +977 9851 033195
              </a>
              <br />
              {/* info@adventuremaster.com */}
            </p>
          </div>
          <div className="col-6 col-md-3 col-lg-3 mb-3 text-center">
            <h5 className="footer-title">UK</h5>

            <p>
              <a
                href="https://api.whatsapp.com/send/?phone=447983806200&text&type=phone_number&app_absent=0"
                target="_blank"
                rel="noreferrer"
              >
                <FaWhatsapp /> +44 7983 806200
              </a>
              <br />
              {/* info@adventuremaster.com */}
            </p>
          </div>
          {/* <div className="col-6 col-md-3 col-lg-3 mb-3 text-center">
            <h5 className="footer-title">Uk</h5>
            <p>
              <a
                href="https://api.whatsapp.com/send/?phone=447380238254&text&type=phone_number&app_absent=0"
                target="_blank"
                rel="noreferrer"
              >
                <FaWhatsapp /> +44 7380 238254
              </a>
              <br />
            </p>
          </div> */}
          <div className="col-6 col-md-3 col-lg-3 mb-3 text-center">
            <h5 className="footer-title">Bahrain</h5>
            <p>
              <a
                href="https://api.whatsapp.com/send/?phone=97334383250&text&type=phone_number&app_absent=0"
                target="_blank"
                rel="noreferrer"
              >
                <FaWhatsapp /> +973 3438 3250
              </a>
              <br />
              {/* info@adventuremaster.com */}
            </p>
          </div>
          <div className="col-6 col-md-3 col-lg-3 mb-3 text-center">
            <h5 className="footer-title">Russia</h5>
            <p>
              <a
                href="https://api.whatsapp.com/send/?phone=79531748198&text&type=phone_number&app_absent=0"
                target="_blank"
                rel="noreferrer"
              >
                <FaWhatsapp /> +79 5317 48198
              </a>
              <br />
              {/* info@adventuremaster.com */}
            </p>
          </div>
          {/* <div className="col-6 col-md-3 col-lg-3 mb-3 text-center">
            <h5 className="footer-title">Australia</h5>
            <p>
              <a
                href="https://api.whatsapp.com/send/?phone=0433516355&text&type=phone_number&app_absent=0"
                target="_blank"
                rel="noreferrer"
              >
                <FaWhatsapp /> +0433 516 355
              </a>
              <br />
            </p>
          </div> */}
        </div>
      </div>
    </div>
  );
};
export default headquater;
