import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import CancelIcon from "@mui/icons-material/Cancel";

const TripDetail = () => {
  return (
    <>
      <div className='trip-detail container mt-5'>
        <div className='row'>
          <div className='col-2 d-none d-lg-block d-xl-block'>
            <div className='trip-sidebar'>
              <ul>
                <li>
                  <a href='#price'>Price Detail</a>
                </li>
                <li>
                  <a href='#highlight'>Highlight</a>
                </li>
                <li>
                  <a href='#include'>Cost Include</a>
                </li>
                <li>
                  <a href='#exclude'>Cost Exclude</a>
                </li>
                <li>
                  <a href='#disclaimer'>Disclaimer of Itinerary</a>
                </li>
                <li>
                  <a href='#important-note'>Important Note</a>
                </li>
                <li>
                  <a href='#safety'>Safety and Security</a>
                </li>
                <li>
                  <a href='#insurance'>Travel Insurance</a>
                </li>
                <li>
                  <a href='#age'>Minimum Age</a>
                </li>
                <li>
                  <a href='#time'>Best Time</a>
                </li>
                <li>
                  <a href='#tipping'>Tipping</a>
                </li>
                <li>
                  <a href='#group-leader'>Group Leader - What They Do</a>
                </li>
                <li>
                  <a href='#group-notes'>Size of Group Notes</a>
                </li>
                <li>
                  <a href='#transport'>Transport</a>
                </li>
                <li>
                  <a href='#local-flight'>Local Flight</a>
                </li>
                <li>
                  <a href='#solo-traveler'>Solo Travelers</a>
                </li>
                <li>
                  <a href='#joining'>Joining Instructions</a>
                </li>
                <li>
                  <a href='#arrival'>Arrival Complications</a>
                </li>
                <li>
                  <a href='#emergency-contact'>Emergency Contanct</a>
                </li>
                <li>
                  <a href='#visa'>Visa and Entry Requirement</a>
                </li>
                <li>
                  <a href='#money-exchange'>Money Exchange</a>
                </li>
                <li>
                  <a href='#emergency-fund'>Emergency Fund</a>
                </li>
                <li>
                  <a href='#first-aid'>First Aid</a>
                </li>
                <li>
                  <a href='#packing-list'>Trekking Equipment</a>
                </li>
              </ul>
            </div>
          </div>

          {/*---------------------- price starts --------------------- */}
          <div className='trip-info col-md-12 col-lg-10'>
            <h4 id='price' className='trip-detail-title'>
              Price Detail
            </h4>
            Luxury Price: USD $880 pp <br />
            Budget Price: USD $540 pp
            {/* <h4 className="trip-detail-title">Group Discount</h4>
            1-2 pax --------- USD $870 pp
            <br />
            upto 5 pax ---- USD $680 pp
            <br />
            upto 10 pax ---- USD $640 pp */}
            {/* ---------------- trip highlight starts --------------------- */}
            <h4 id='highlight' className='trip-detail-title'>
              Highlights
            </h4>
            <ul className='trip-highlight'>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Beautiful views of the mountains: The Mardi Himal trek offers
                  stunning views of the Annapurna massif, including peaks like
                  Annapurna South, Hiunchuli, and Machhapuchhre, as well as
                  different landscapes.
                </li>
              </span>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Off the beaten path: The Mardi Himal trail is less crowded
                  than other popular treks in the Annapurna region, making it a
                  great choice for people who want to get away from the crowds.
                </li>
              </span>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Culture: The Mardi Himal trekking route takes you through
                  traditional villages where you can learn about the Gurung and
                  Magar people's culture and traditions.
                </li>
              </span>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Different types of terrain: The Mardi Himal trek goes through
                  forests, meadows, and high-altitude trails.
                </li>
              </span>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  You will reach altitudes of up to 4,500 meters, giving you the
                  chance to trek at high altitudes and feel the excitement of
                  reaching a mountain pass.
                </li>
              </span>
            </ul>
            {/*---------------------- cost include starts --------------------- */}
            <h4 id='include' className='trip-detail-title'>
              Cost Include
            </h4>
            <ul className='cost-include'>
              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  All ground travel, including getting to and from the airport,
                  is done in a private car.
                </li>
              </span>
              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>The Kathmandu to Pokhara flight.</li>
              </span>
              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>Drive from Pokhara to Kande by private car.</li>
              </span>
              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>Drive from Siding to Pokhara by private jeep.</li>
              </span>
              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Drive from Pokhara to Kathmandu via a luxurious tourist bus.
                </li>
              </span>
              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  One night in Kathmandu and two nights in Pokhara at standard
                  hotel with breakfast (as per the itinerary).
                </li>
              </span>
              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  During the trek, you will be provided with three meals a day:
                  breakfast, lunch, and dinner with one cup of tea each meal.
                </li>
              </span>
              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  The best available lodge for two people to share during the
                  trek.
                </li>
              </span>
              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Strong, helpful porters with the right safety gear and walking
                  gear, as well as a salary, food, a place to live, and
                  insurance. (one porter for two people).
                </li>
              </span>
              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  A highly experienced, helpful, knowledgeable, friendly,
                  English-speaking guide with all of his salary, food, drinks,
                  lodging, transportation, and insurance covered.
                </li>
              </span>
              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>Taxes and official costs paid to the government.</li>
              </span>
              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Oxymeter to check your pulse, oxygen saturation, and heart
                  rate twice a day (very helpful to check for signs of Altitude
                  Mountain Sickness, or AMS). This will make sure that your trek
                  is in the safest hands possible, no matter how experienced you
                  are.
                </li>
              </span>
              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  A map of a trek and a certificate of completion of the trip.
                </li>
              </span>
              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  All papers that need to be done, Fees for entering permits you
                  need to go while hiking.
                </li>
              </span>
            </ul>
            {/*---------------------- cost exclude starts --------------------- */}
            <h4 id='exclude' className='trip-detail-title'>
              Cost Exclude
            </h4>
            <ul className='cost-exclude'>
              <span>
                <div className='cost-exclude-icon'>
                  <CancelIcon />
                </div>
                <li>
                  Food that you will eat in Kathmandu for lunch and dinner.
                </li>
              </span>
              <span>
                <div className='cost-exclude-icon'>
                  <CancelIcon />
                </div>
                <li>
                  <p>
                    The cost of a visa to enter Nepal is: <br />
                    $30 USD for a 15-day stay, <br />
                    30 Days - $50,
                    <br />
                    90 Days - $125
                  </p>
                </li>
              </span>
              <span>
                <div className='cost-exclude-icon'>
                  <CancelIcon />
                </div>
                <li>Your insurance for travel and medical care.</li>
              </span>
              <span>
                <div className='cost-exclude-icon'>
                  <CancelIcon />
                </div>
                <li>International Airfare.</li>
              </span>
              <span>
                <div className='cost-exclude-icon'>
                  <CancelIcon />
                </div>
                <li>Your Personal Expenses.</li>
              </span>
              <span>
                <div className='cost-exclude-icon'>
                  <CancelIcon />
                </div>
                <li>
                  All of the alcoholic and non-alcoholic cold and hot drinks you
                  buy on trek (along the way and in the Tea Houses at night).
                </li>
              </span>
              <span>
                <div className='cost-exclude-icon'>
                  <CancelIcon />
                </div>
                <li>
                  All of the sweet desserts, like chocolate, cake, pie, and
                  pudding.
                </li>
              </span>
              <span>
                <div className='cost-exclude-icon'>
                  <CancelIcon />
                </div>
                <li>
                  The tea houses have hot showers and places to charge your
                  phone which will cost you about 2$ to 5$.
                </li>
              </span>
              <span>
                <div className='cost-exclude-icon'>
                  <CancelIcon />
                </div>
                <li>
                  Tip to the guide, the porter, and the driver. (Tipping is
                  expected)
                </li>
              </span>
            </ul>
            {/*---------------------- Disclaimer of Itinerary starts --------------------- */}
            <h4 id='disclaimer' className='trip-detail-title'>
              Disclaimer of Itinerary
            </h4>
            <p className='trip-detail-content'>
              The schedule for our Mardi Himal trek may change depending on
              things like the weather, the state of the roads, and the
              availability of places to stay. We have the right to change the
              schedule if we need to.
            </p>
            <p className='trip-detail-content'>
              We try to stick to the plan for the Mardi Himal base camp trek,
              but there may be times when we have to change the route because of
              something unexpected. As trekking in a remote area can bring up
              unexpected problems, the trek leader may need to change the plan
              for the group's benefit.
            </p>
            {/*---------------------- important notes starts --------------------- */}
            <h4 id='important-note' className='trip-detail-title'>
              Important Notes
            </h4>
            <p className='trip-detail-content'>
              Here are a few important things to consider when trekking to the
              Mardi Himal:
            </p>
            <ul>
              <li>
                Physical fitness is important for the Mardi Himal trek, which is
                a moderate to difficult trek. Before starting the trip, you
                should make sure your body is in good shape. Before the trek, do
                regular physical activities to get your body ready.
              </li>
              <li>
                Altitude sickness: The Mardi Himal trek route goes up to 4,500
                meters, which can increase the chance of getting altitude
                sickness. Pay attention to your body and let your guide know if
                you get a headache, feel sick, or have trouble breathing.
              </li>
              <li>
                Pack light things : For the hike, pack light. Just the things
                you need, and leave anything else in the locker room of the
                hotel. Follow our list of things to pack.
              </li>
              <li>
                Proper clothes and gear: Wear layers and bring warm jackets,
                gloves, hats, and other clothes and gear that are right for the
                hike, like a warm hat and gloves. Also, bring shoes and clothes
                that won't get wet.
              </li>
              <li>
                Respect the culture and traditions of the area. The Mardi Himal
                trek is in Nepal's Annapurna region. You will be hiking through
                small towns. So, you should be aware of the natives and respect
                what they believe. Some of them may sound strange to you, but
                remember that, like yours, it's their way of life, and no one
                has the right to treat it badly. Follow the rules your trekking
                guide gives you, watch what you do, and don't leave trash
                behind.
              </li>
            </ul>
            {/*---------------------- safety and security starts --------------------- */}
            <h4 id='safety' className='trip-detail-title'>
              Safety and Security
            </h4>
            <p className='trip-detail-content'>
              Nepal is a safe place to visit, whether you are by yourself or
              with family. Since we are a registered Nepali trekking company,
              you can put your money and time in our hands. Our team members are
              experts in Nepal's tourism industry, and we work hard to make sure
              that all of our treks go smoothly.
            </p>
            <p className='trip-detail-content'>
              During the hike, the team leader will be in charge of your safety
              and well-being. The leader has a satellite phone in case of an
              emergency, and we stay in touch with the team often to make sure
              everything is going well on the trip. We care most about the
              safety of our guests.
            </p>
            <p className='trip-detail-content'>
              Concerning Covid-19, everyone on our team has been vaccinated, and
              the country is now open to tourists from all over the world. Most
              people have had one or both doses of the Covid-19 vaccine, so it
              is safe to make the trek.
            </p>
            <em>
              <strong>
                Note: Please keep in mind that it is always a good idea to
                follow local rules and guidelines (if there are any) to stop the
                spread of the virus, such as wearing a mask and keeping clean.
              </strong>
            </em>
            {/*---------------------- safety and security starts --------------------- */}
            <h4 id='insurance' className='trip-detail-title'>
              Travel Insurance
            </h4>
            <p className='trip-detail-content'>
              For the Mardi Himal trek, you must have travel insurance because
              there are risks that come with going to a remote area and climbing
              above 4,000 meters. Even though we take steps to keep people from
              getting altitude sickness, we cannot promise that no one will get
              it.
            </p>
            <p className='trip-detail-content'>
              Because of this, having travel insurance can help us get medical
              help quickly if we need it. For the Mardi Himal trek, it is
              important to make sure that your travel insurance covers emergency
              air evacuation up to 5,000 meters and medical costs.
            </p>
            {/*---------------------- minimum age starts --------------------- */}
            <h4 id='age' className='trip-detail-title'>
              Minimum Age
            </h4>
            <p className='trip-detail-content'>
              You have to be at least 18 years old to join our Mardi Himal base
              camp trek. If you are younger than 18, you must be with a parent
              or guardian. If you want to bring a child under 12 years old on
              the trek, please get in touch with us so we can see if they can
              join.
            </p>
            <p className='trip-detail-content'>
              As long as everyone's health is good, parents can trek to Mardi
              Himal with their kids. Our team is ready to give extra care to
              families with young children or older people. Please don't worry
              about anything while we are hiking.
            </p>
            {/*---------------------- best time starts --------------------- */}
            <h4 id='time' className='trip-detail-title'>
              Best Time
            </h4>
            <p className='trip-detail-content'>
              The best time to do the Mardi Himal Base Camp Trek depends on what
              kind of weather and number of people you want.
              <br />
              The best times to go trekking in the Annapurna area, including the
              Mardi Himal Base Camp Trek, are in the spring (March to May) and
              fall (September to November). During this time of change, the
              weather is most stable in the mountains, and a mild climate is
              perfect for exploring. During these times of year, the weather is
              usually clear and visible, which is great for hiking. People also
              know the area for its beautiful wildflowers, which are at their
              best in the spring. In Nepal, the monsoon season is usually from
              June to August. The Mardi Himal Base Camp Trek can be done during
              this time, but the trails can be slippery and there may be a lot
              of leeches.
            </p>
            <p className='trip-detail-content'>
              Winter (December to February) is also a good time to go because
              the trails are less crowded and the weather is cooler, which makes
              for good hiking. But the weather can be more unpredictable at
              higher elevations, where snow and cold weather are possible.
            </p>
            {/*---------------------- tipping starts --------------------- */}
            <h4 id='tipping' className='trip-detail-title'>
              Tipping
            </h4>
            <p className='trip-detail-content'>
              The cost of the Mardi Himal Trek does not include tips. We let our
              guests decide how much to tip the people on our field crew.
              Tipping is more of a cultural thing than something you have to do.
              But we recommend tipping to show your appreciation for your team's
              hard work, and it is best to tip as a group.
            </p>
            {/* ----------------------- Group Leader - What They Do starts ---------------- */}
            <h4 id='group-leader' className='trip-detail-title'>
              Group Leader - What They Do
            </h4>
            <p className='trip-detail-content'>
              Our group leaders are in charge of leading people and groups in
              the mountains. During the Mardi Himal trek, you will be with a
              skilled mountain guide. They are licensed and have a lot of
              experience leading treks in the Himalayas. They have had a lot of
              training and have a lot of certificates that show they can be
              trusted.
            </p>
            <p className='trip-detail-content'>
              We also work closely with the best local guides in the Himalayas,
              who have been doing this for a long time and are known for being
              friendly and helpful. When there are more than eight trekkers, an
              assistant guide will also be there to make sure everyone is safe
              and healthy.
            </p>
            {/*---------------------- Size of Group Notes starts --------------------- */}
            <h4 id='group-notes' className='trip-detail-title'>
              Size of Group Notes
            </h4>
            <p className='trip-detail-content'>
              When planning a trek, group size is an important thing to think
              about. It can change how the group works as a whole, how much help
              and guidance is given, and how much the trip costs as a whole.
            </p>
            <p className='trip-detail-content'>
              So, we try to keep our groups small so that we can be more
              personal and have a more intimate experience. Smaller groups, on
              the other hand, may cost more per person because they need more
              help and guidance.
            </p>
            <p className='trip-detail-content'>
              In the same way, we also organize trekking on the Mardi Himal for
              large groups. Larger groups may be cheaper, but they may also be
              harder to manage and not give as much room for customization. Find
              a balance that works for your needs and preferences, and let us
              know.
            </p>
            {/*---------------------- transport starts --------------------- */}
            <h4 id='transport' className='trip-detail-title'>
              Transport
            </h4>
            <p className='trip-detail-content'>
              We pick up and drop off our guests at the airport so that the
              beginning and end of their trip is easy and convenient. In the
              same way, we get from Kathmandu to Pokhara on a tourist bus or a
              private four-wheeler. In the same way, we take a local vehicle
              from Pokhara to the beginning of the trail and back to Kathmandu.
            </p>
            {/*---------------------- local flights starts --------------------- */}
            <h4 id='local-flight' className='trip-detail-title'>
              Local flight
            </h4>
            <p className='trip-detail-content'>
              No domestic flights are included in our Mardi Himal trek package.
              We get from one place to another by riding four-wheelers. If you
              want to upgrade your travel, you can take a short domestic flight
              from Kathmandu to Pokhara and back in 20 minutes. There will be
              extra costs.
            </p>
            {/*---------------------- solo travelers starts --------------------- */}
            <h4 id='solo-traveler' className='trip-detail-title'>
              Solor Travelers
            </h4>
            <p className='trip-detail-content'>
              As a solo traveler on the Mardi Himal trek, you will get to
              experience the culture and natural beauty of Nepal while also
              testing your physical and mental strength. The hike is good for
              intermediate to advanced hikers because it has some steep hills
              and rocky ground. But when you reach the top, you will feel proud
              of what you have done and be amazed by the view.
            </p>
            <p className='trip-detail-content'>
              On the Mardi Himal trek, people who are traveling alone can choose
              to join a group or go it alone. Joining a group can make you feel
              safe and give you friends, while joining a private trek gives you
              more freedom and independence. No matter which option you choose,
              make sure to prepare well and get used to the high altitude
              because it can be hard for some hikers.
            </p>
            {/*---------------------- joining instructions starts --------------------- */}
            <h4 id='joining' className='trip-detail-title'>
              Joining Instructions
            </h4>
            <p className='trip-detail-content'>
              It is easy to book a trek with us. Visit our website and book and
              pay for the trek using our secure payment gateway. You can send an
              inquiry if you have any questions, and one of our travel
              consultants will be happy to help you. Our system for making
              reservations is easy to use and won't take more than a few
              minutes.
            </p>
            <p className='trip-detail-content'>
              The best time to sign up for the Mardi Himal base camp trek for
              spring or fall 2023 is right now. Booking early will give you a
              lot of time to get ready for the trip and think about any problems
              you might face.
            </p>
            <p className='trip-detail-content'>
              If you have any questions about the Mardi Himal trek, feel free to
              contact us through the link above or by calling +977 985-1033195
              (WhatsApp/Viber).
            </p>
            {/*---------------------- arrival complications starts --------------------- */}
            <h4 id='arrival' className='trip-detail-title'>
              Problems With Arrival
            </h4>
            <p className='trip-detail-content'>
              If your flight is late or canceled, let us know as soon as you
              can. It will help us change the reservation to match the time you
              plan to arrive. Putting off the trip until the last minute might
              cost you more money.
            </p>
            <p className='trip-detail-content'>
              In the same way, if you can't find a member of our team at
              Tribhuvan International Airport, try calling us on WhatsApp or
              Viber. At the airport, you will be able to use the internet for
              free. You can also ask the government in your area for help.
            </p>
            <p className='trip-detail-content'>
              Before going to Nepal, travelers should make sure that their visas
              are up to date and in order. Any problems with visas can cause
              delays and other problems when you get there.
            </p>
            {/*---------------------- emergency contact starts --------------------- */}
            <h4 id='emergency-contact' className='trip-detail-title'>
              Emergency Contact
            </h4>
            <p className='trip-detail-content'>
              You can call us at any time on our emergency numbers. These
              numbers can also be used for WhatsApp and Viber. These are the
              numbers to call if you need to reach us in an emergency: +977
              9851033195, +44 7983806200, and +977 9849780311. You can talk to
              the main people on our team about these numbers. In the same way,
              you can call us during office hours at our office number. (M-F, 9
              a.m.–5 p.m. local time)
            </p>
            {/*---------------------- visa and entry requirements starts --------------------- */}
            <h4 id='visa' className='trip-detail-title'>
              Visa and Entry Requirements
            </h4>
            <p className='trip-detail-content'>
              To get into Nepal, you need a travel visa that is good for at
              least six months. Many countries can get a visa when they arrive
              in Nepal, which can be done at any of the country's entry points.
              If you arrive at Tribhuvan International Airport, you can get your
              visa before meeting with our company representatives.
            </p>
            <p className='trip-detail-content'>
              Check to see if your country can get a visa when you get there or
              if you need to go to the Nepalese embassy or consulate to get one.
              <br />
              Please keep in mind that it is the traveler's job to get their own
              visa.
            </p>
            <p className='trip-detail-content'>
              We suggest that you check the visa requirements and apply for a
              visa as soon as possible. We can give you a letter confirming your
              trip, which you may need for the visa application process. If you
              cannot get a visa and have to cancel your trip because of that,
              you may have to pay a cancellation fee.
            </p>
            {/*---------------------- money exchange starts --------------------- */}
            <h4 id='money-exchange' className='trip-detail-title'>
              Money Exchange
            </h4>
            <p className='trip-detail-content'>
              When traveling to Nepal, you will need to change your money. Here
              are some things you should remember:
            </p>
            <p className='trip-detail-content'>
              The Nepalese Rupee is the country's official currency (NPR). It is
              best to bring cash in small bills, since it may be hard to change
              large bills.
            </p>
            <p className='trip-detail-content'>
              Rates of exchange: Exchange rates can change, so check the current
              rate before you change your money.
            </p>
            <p className='trip-detail-content'>
              Places of exchange: Nepal has many places where you can exchange
              money, such as banks and money exchange centers. Most of the time,
              it is best to exchange money at a bank or a licensed money
              exchange office, as they tend to have better exchange rates.
            </p>
            <p className='trip-detail-content'>
              Credit cards: Most hotels, cafes, shopping centers, and larger
              stores in Nepal accept credit cards, but smaller shops and
              restaurants might not. Having some cash on hand is a good idea.
            </p>
            <p className='trip-detail-content'>
              ATMs: There are ATMs all over Kathmandu and other big cities and
              towns. At any ATM, you can use your international card to get
              cash.
            </p>
            {/*---------------------- emergency fund starts --------------------- */}
            <h4 id='emergency-fund' className='trip-detail-title'>
              Emergency Fund
            </h4>
            <p className='trip-detail-content'>
              Having a fund for emergencies can give you financial security and
              stability in case something unexpected happens, like a flight
              delay, lost or stolen items, or a medical emergency. Setting aside
              a certain amount of money before your trip is a good way to start
              a travel emergency fund.
            </p>
            <p className='trip-detail-content'>
              Depending on the length and location of your trip, we usually say
              that you should have at least a few hundred dollars saved in case
              of an emergency.
            </p>
            <p className='trip-detail-content'>
              You should keep emergency funds somewhere safe and easy to get to,
              like a travel credit card or a prepaid travel card. These options
              let you access your money while you're on the road and also
              protect you from fraud and make it safer.
            </p>
            {/* ------------------- first aid starts --------------------- */}
            <h4 id='first-aid' className='title'>
              First Aid
            </h4>
            <p>
              We will have a first-aid kit with us on the trek in case anyone
              needs basic medical care. Our team leader has been trained in
              first aid, so if anyone needs help, he or she will be able to give
              it.
            </p>
            <p className='trip-detail-content'>
              The kit has an Oximeter, bandages and plasters, ankle bandages,
              antiseptic ointment, moleskin for blisters, painkillers,
              rehydration salts, antibiotic throat lozenges, medicine for
              altitude sickness, and a broad-spectrum antibiotic.
            </p>
            <p className='trip-detail-content'>
              We also have sterile syringes and a gel for cleaning. You don't
              need to bring your own first-aid kit.
            </p>
            {/*---------------------- packing starts --------------------- */}
            <h4 id='packing-list' className='trip-detail-title'>
              Packing list
            </h4>
            <p className='trip-detail-content'>
              After you book, our team will also give you a personalized packing
              list that you can use to pack for the Mardi Himal trek.
              <br />
              For now, you can look at the things below to get a general idea.
            </p>
            <strong>Head and Hand</strong>
            <ul>
              <li>Sun Cap </li>
              <li>Wool/Fleece Hat </li>
              <li>Balaclava </li>
              <li>Neck Gaiter/High Neck</li>
              <li>Lightweight Synthetic Liner Glove </li>
              <li>Wind Stopper Fleece Gloves </li>
            </ul>
            <strong>Upper Body</strong>
            <ul>
              <li>Short-Sleeved Shirts/T-shirts </li>
              <li>Lightweight Top/Thermo Coat </li>
              <li>Synthetic or Fleece Jacket </li>
              <li>Down Insulated Jacket </li>
            </ul>
            <strong>Lower Body</strong>
            <ul>
              <li>Underwear</li>
              <li>Lightweight Long Underpants </li>
              <li>Midweight Long Underpants </li>
              <li>Trekking Pants </li>
              <li>Trekking Socks </li>
              <li>Sandals </li>
              <li>Lightweight Hiking/Trekking Boots </li>
            </ul>
            <strong>Toiletries</strong>
            <ul>
              <li>Hand Sanitizers & Hand wash </li>
              <li>Wet Tissues </li>
              <li>Toothpaste </li>
              <li>Toothbrush </li>
              <li>Bath Soap </li>
              <li>Shampoo</li>
              <li>Moisturizers </li>
              <li>Laundry Soap</li>
              <li>Eye Drops </li>
              <li>Zip-Lock Bags</li>
              <li>Sunscreen (-50 Spf)</li>
              <li>Lips Guard (-20/-50 Spf) </li>
              <li>Water Purification Tablets </li>
              <li>Baby Wipes or Wet Towels </li>
            </ul>
            <strong>Miscellaneous</strong>
            <ul>
              <li>Rucksacks (45 - 55 Ltr.) </li>
              <li>1 Duffel Bag (provided by the company)</li>
              <li>Trekking poles</li>
              <li>Sleeping bag</li>
              <li>Torchlight </li>
              <li>Water Bottles </li>
              <li>Thermos/Flask </li>
              <li>Pack Towel </li>
              <li>Spare batteries </li>
              <li>Waterproof Stuff Sacks </li>
              <li>Solar chargeable power bank (optional)</li>
              <li>Bars & Snacks</li>
            </ul>
            <strong>Travel Document</strong>
            <ul>
              <li>Valid Passport </li>
              <li>Valid Visa </li>
              <li>4 PP Size Photo </li>
              <li>Insurance Paper (Medical & Rescue) & Contact Address </li>
              <li>Family/Company Contact Number & Address </li>
              <li>Credit Card</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default TripDetail;
