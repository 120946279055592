import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Header from "../../components/Header";
import countries from "../../country.json";
import Footer from "../../components/Footer";

const TripBooking = () => {
  const tripTitle = sessionStorage.getItem("tripTitle");
  const tripPrice = sessionStorage.getItem("tripPrice");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [title, setTitle] = useState(tripTitle ? tripTitle : "");
  const [price] = useState(tripPrice ? tripPrice : "");
  const [contact_number, setContact_number] = useState("");
  const [body, setBody] = useState("");
  const [start_date, setStart_date] = useState("");
  const [no_of_traveler, setNo_of_traveler] = useState("");
  const [country, setCountry] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const availTrip = [
    { name: "Everest Base Camp Helicopter" },
    { name: "Everest Base Camp" },
    { name: "Manaslu Circuit" },
    { name: "Annapurna Base Camp" },
    { name: "Annapurna Circuit Trek with Tilicho Lake and Poonhill" },
    { name: "Gokyo Lake" },
    { name: "Buddhist Pilgrimage Tour" },
  ];

  const postDataAxios = async (bookNow) => {
    try {
      await fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/send-mail`, {
        method: "POST",
        mode: "cors",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(bookNow),
      }).then((res) => {
        console.log("res", res);
        setIsLoading(true);
      });
    } catch (error) {
      console.log("error ===>", error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const bookNow = {
      name,
      email,
      title,
      body,
      contact_number,
      start_date,
      no_of_traveler,
      country,
    };

    // console.log("bookNowbookNow", bookNow);
    if (
      bookNow.name !== "" &&
      bookNow.email !== "" &&
      bookNow.title !== "" &&
      bookNow.contact_number !== "" &&
      bookNow.start_date !== "" &&
      bookNow.no_of_traveler !== "" &&
      bookNow.country !== ""
    ) {
      postDataAxios(bookNow);
    } else {
      alert("PLease fill the required field *");
    }
  };
  return (
    <div className="main-book">
      <Header title="Book Your Trip" />
      <Container className="">
        <h1 className="mt-3 mt-md-5">Book Your Trip</h1>
        <div style={{ color: "#606060" }} className="mb-3">
          Our booking is available
        </div>
        {!isLoading ? (
          <Row className="">
            <Col md={12} lg={8} className="mb-3">
              {/* <form onSubmit={handleSubmit}> */}
              <h2>{tripTitle}</h2>
              {!tripTitle && (
                <Row className="">
                  <Col sm={6}>
                    <div className="options">
                      <label className="form-required">Select Trip</label>
                      <select
                        id="cars"
                        name="cars"
                        placeholder="please select trip"
                        onChange={(e) => setTitle(e.target.value)}
                      >
                        <option value="" disabled selected>
                          <span className="text-priamry">Select your trip</span>
                        </option>
                        {availTrip.map((key) => (
                          <option value={key.name}>{key.name}</option>
                        ))}
                      </select>
                    </div>
                  </Col>
                </Row>
              )}
              <form>
                <h3 className="my-3">Date and Travelers</h3>
                <Row>
                  <Col className="col-sm-7 col-md-6">
                    <div className="options">
                      <label className="form-required">No. Of Traveler</label>

                      <input
                        type="number"
                        onChange={(e) => setNo_of_traveler(e.target.value)}
                      ></input>
                    </div>
                  </Col>
                  <Col className="col-sm-7 col-md-6">
                    <div className="options">
                      <label className="form-required">Start Date</label>
                      <input
                        type="date"
                        onChange={(e) => setStart_date(e.target.value)}
                      ></input>
                    </div>
                  </Col>
                </Row>
                <h3 className="my-3">Lead Traveler Domain</h3>
                <Row>
                  <Col className="col-sm-12 col-md-6">
                    <div className="options">
                      <label className="form-required">Name</label>
                      <input
                        type="text"
                        required
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="col-7 col-sm-6 col-md-4"
                      />
                    </div>
                  </Col>
                  <Col className="col-sm-12 col-md-6">
                    <div className="options">
                      <label for="email" className="form-required">
                        Email
                      </label>
                      <input
                        type="email"
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="col-7 col-sm-6 col-md-4"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-sm-12 col-md-6">
                    <div className="options">
                      <label className="form-required">Phone Number</label>
                      <input
                        type="number"
                        required
                        value={contact_number}
                        onChange={(e) => setContact_number(e.target.value)}
                        className="col-7 col-sm-6 col-md-4"
                      />
                    </div>
                  </Col>
                  <Col className="col-sm-12 col-md-6">
                    <div className="options">
                      <label className="form-required">Country</label>
                      <select
                        id="cars"
                        name="cars"
                        onChange={(e) => setCountry(e.target.value)}
                      >
                        <option value="" disabled selected>
                          Select your country
                        </option>
                        {countries.map((key) => (
                          <option value={key.name}>{key.name}</option>
                        ))}
                      </select>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-12">
                    <div className="options book-textarea">
                      <label>Message</label>
                      <textarea
                        className="input"
                        onChange={(e) => setBody(e.target.value)}
                      ></textarea>
                    </div>
                  </Col>
                </Row>
                <div>
                  <button
                    className="btn btn-outline-secondary my-3"
                    onClick={handleSubmit}
                  >
                    BOOK NOW
                  </button>
                </div>
              </form>
            </Col>
            <Col sm={10} lg={4} className="mb-3">
              <div className="booking-sidebar">
                {title ? <h4>{title}</h4> : <h4>Please Select Trip</h4>}
                {price ? <h4>Price: {price}</h4> : <h4>Please Select Trip</h4>}

                {/* <img src={bookimg} alt="" className="book-img" /> */}
                <hr />
                <ul>
                  {start_date && (
                    <li>
                      <b>Start Date</b>
                      <span>{start_date ? start_date : ""}</span>
                    </li>
                  )}
                  {no_of_traveler && (
                    <li>
                      <b>No. of Traveler</b>
                      <span>{no_of_traveler}</span>
                    </li>
                  )}
                  {name && (
                    <li>
                      <b>Name</b>
                      <span>{name}</span>
                    </li>
                  )}
                  {email && (
                    <li>
                      <b>Email</b>
                      <span>{email}</span>
                    </li>
                  )}
                  {contact_number && (
                    <li>
                      <b>Phone Number</b>
                      <span>{contact_number}</span>
                    </li>
                  )}
                  {country && (
                    <li>
                      <b>Country</b>
                      <span>{country}</span>
                    </li>
                  )}
                  {/* {price && (
                    <li>
                      <b>Price</b>
                      <span>N/A</span>
                    </li>
                  )} */}
                </ul>
              </div>
            </Col>
          </Row>
        ) : (
          <div className="py-5 text-center">
            <h1 style={{ color: "yellow" }}>Your booking is successful.</h1>{" "}
            <br />
            <h5>We will contact you soon.</h5>
          </div>
        )}
      </Container>
      <Footer />
    </div>
  );
};

export default TripBooking;
