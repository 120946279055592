import Header from "./components/Header";
import Footer from "./components/Footer";
import { Container } from "react-bootstrap";
const ErrorPage = () => {
  return (
    <>
      <Header title="Unavailable" />
      <Container className="error-title">
        <h1>404 ERROR</h1>
        <br />
        We dont have this page available
      </Container>
      <Footer />
    </>
  );
};

export default ErrorPage;
