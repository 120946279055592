import React from 'react';
import { useState } from "react";
import { HashLink } from "react-router-hash-link";
import Itinerary from "./Itinerary";
import TripInfo from "./TripInfo";
import TripDetail from "./TripDetail";
import Overview from "./Overview";
import Footer from "../../components/Footer";
import { Link } from "react-router-dom";
import ABCLuxuryIcon from "../../components/Luxurypackage/ABCLuxuryIcon";
import ScrollToTop from "../../components/ScrollToTop";

const Everest = () => {
  const [overview, setOverView] = useState(false);
  const [itinerary, setItinerary] = useState(false);
  const [tripDetail, setTripDetail] = useState(false);
  const [tripInfo, setTripInfo] = useState(true);
  const [tripBooking, setTripBooking] = useState(true);
  const overviewHandler = () => {
    setOverView(true);
    setItinerary(false);
    setTripDetail(false);
    setTripInfo(false);
  };

  const itineraryHandler = () => {
    setOverView(false);
    setItinerary(true);
    setTripDetail(false);
    setTripInfo(false);
  };

  const tripDetailHandler = () => {
    setOverView(false);
    setItinerary(false);
    setTripDetail(true);
    setTripInfo(false);
  };

  const tripInfolHandler = () => {
    setOverView(false);
    setItinerary(false);
    setTripDetail(false);
    setTripInfo(true);
  };
  // console.log("setOverView", overview);

  const onTripChange = () => {
    sessionStorage.removeItem("tripTitle");
    sessionStorage.setItem("tripTitle", "Annapurna Base Camp Trek - 12 Days");
    sessionStorage.setItem("tripPrice", "$890 pp")
  };

  return (
    <>
      <ScrollToTop position="header" />
      <div className="trip" id="top">
        <div className="trip-hero abc-trek">
          <div className="trip-box">
            <div className="trip-detail">
              <p className="main-title">Annapurna Base Camp</p>
              <div className="activity d-flex justify-content-center align-items-center">
                <div className="luxury-package-info">
                  <p className="text-center text-light ms-2">Luxury package </p>
                </div>
                <ABCLuxuryIcon />
              </div>
              <div className="activity">
                <p>
                  Activity: <span className="trip-modal-title">Trekking</span>
                </p>
                <p>
                  Price: <span className="trip-modal-title">$890 pp</span>
                </p>
              </div>

              <p className="view-trip">
                <HashLink
                  smooth
                  to="/booking"
                  className="link"
                  onClick={onTripChange}
                >
                  <div className="hover-underline-animation-black">
                    Book Now
                  </div>
                </HashLink>
              </p>
            </div>
          </div>
        </div>
        <div>
          <div className="trip-navbar d-flex" id="body">
            <p className={tripInfo ? "active" : ""}>
              <Link
                onClick={tripInfolHandler}
                to="/annapurna-base-camp-trek/info"
                className="link"
              >
                INFO
              </Link>
            </p>
            <span> &#x2022; </span>
            <p className={overview ? "active" : ""}>
              <Link
                onClick={overviewHandler}
                to="/annapurna-base-camp-trek/overview"
                className="link"
              >
                OVERVIEW
              </Link>
            </p>
            <span> &#x2022; </span>

            <p className={itinerary ? "active" : ""}>
              <Link
                onClick={itineraryHandler}
                to="/annapurna-base-camp-trek/itinerary"
                className="link"
              >
                ITINERARY
              </Link>
            </p>
            <span> &#x2022; </span>

            <p className={tripDetail ? "active" : ""}>
              <Link
                onClick={tripDetailHandler}
                to="/annapurna-base-camp-trek/detail"
                className="link"
              >
                DETAIL
              </Link>
            </p>

            <span> &#x2022; </span>

            <p>
              <Link onClick={onTripChange} to="/booking" className="link">
                BOOK NOW
              </Link>
            </p>


          </div>
          {overview && <Overview />}
          {tripInfo && <TripInfo />}
          {itinerary && <Itinerary />}
          {tripDetail && <TripDetail />}
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Everest;
