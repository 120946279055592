import * as React from "react";
import Box from "@mui/material/Box";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { Container } from "react-bootstrap";

import ebcHeliTrek1 from "../../../image/Everest/ebcHeliTrek/1.jpg";
import ebcHeliTrek2 from "../../../image/Everest/ebcHeliTrek/2.jpg";
import ebcHeliTrek3 from "../../../image/Everest/ebcHeliTrek/3.jpg";
import ebcHeliTrek4 from "../../../image/Everest/ebcHeliTrek/4.jpg";
import ebcHeliTrek5 from "../../../image/Everest/ebcHeliTrek/5.jpg";
import ebcHeliTrek6 from "../../../image/Everest/ebcHeliTrek/6.jpg";
import ebcHeliTrek7 from "../../../image/Everest/ebcHeliTrek/7.jpg";
import ebcHeliTrek8 from "../../../image/Everest/ebcHeliTrek/8.jpg";
import ebcHeliTrek9 from "../../../image/Everest/ebcHeliTrek/9.jpg";
import ebcHeliTrek10 from "../../../image/Everest/ebcHeliTrek/10.jpg";
import ebcHeliTrek11 from "../../../image/Everest/ebcHeliTrek/11.jpg";
import ebcHeliTrek12 from "../../../image/Everest/ebcHeliTrek/12.jpg";
import ebcHeliTrek13 from "../../../image/Everest/ebcHeliTrek/13.jpg";
import ebcHeliTrek14 from "../../../image/Everest/ebcHeliTrek/14.jpg";
import ebcHeliTrek15 from "../../../image/Everest/ebcHeliTrek/15.jpg";
import ebcHeliTrek16 from "../../../image/Everest/ebcHeliTrek/16.jpg";
import ebcHeliTrek17 from "../../../image/Everest/ebcHeliTrek/17.jpg";
import ebcHeliTrek18 from "../../../image/Everest/ebcHeliTrek/18.jpg";
import ebcHeliTrek19 from "../../../image/Everest/ebcHeliTrek/19.jpg";
import ebcHeliTrek20 from "../../../image/Everest/ebcHeliTrek/20.jpg";
import ebcHeliTrek21 from "../../../image/Everest/ebcHeliTrek/21.jpg";
import ebcHeliTrek22 from "../../../image/Everest/ebcHeliTrek/22.jpg";
import ebcHeliTrek23 from "../../../image/Everest/ebcHeliTrek/23.jpg";
import ebcHeliTrek24 from "../../../image/Everest/ebcHeliTrek/24.jpg";
import ebcHeliTrek25 from "../../../image/Everest/ebcHeliTrek/25.jpg";
import ebcHeliTrek26 from "../../../image/Everest/ebcHeliTrek/26.jpg";
import ebcHeliTrek27 from "../../../image/Everest/ebcHeliTrek/27.jpg";
import ebcHeliTrek28 from "../../../image/Everest/ebcHeliTrek/28.jpg";
import ebcHeliTrek29 from "../../../image/Everest/ebcHeliTrek/29.jpg";
import ebcHeliTrek30 from "../../../image/Everest/ebcHeliTrek/30.jpg";
import ebcHeliTrek31 from "../../../image/Everest/ebcHeliTrek/31.jpg";
import ebcHeliTrek32 from "../../../image/Everest/ebcHeliTrek/32.jpg";
import ebcHeliTrek33 from "../../../image/Everest/ebcHeliTrek/33.jpg";
import ebcHeliTrek34 from "../../../image/Everest/ebcHeliTrek/34.jpg";
import ebcHeliTrek35 from "../../../image/Everest/ebcHeliTrek/35.jpg";
import ebcHeliTrek36 from "../../../image/Everest/ebcHeliTrek/36.jpg";
import ebcHeliTrek37 from "../../../image/Everest/ebcHeliTrek/37.jpg";
import ebcHeliTrek38 from "../../../image/Everest/ebcHeliTrek/38.jpg";
import ebcHeliTrek39 from "../../../image/Everest/ebcHeliTrek/39.jpg";
import ebcHeliTrek40 from "../../../image/Everest/ebcHeliTrek/40.jpg";

export default function MasonryImageList() {
  return (
    <>
      <h4 className="title mt-5">Photo Gallery</h4>
      <Box
        className="gallery-box mb-5"
        sx={{ overflowY: "scroll" }}
        style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
      >
        <ImageList variant="masonry" cols={3} gap={8}>
          {itemData.map((item) => (
            <ImageListItem key={item.img}>
              <img
                src={`${item.img}?w=248&fit=crop&auto=format`}
                srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                alt={item.title}
                // loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
    </>
  );
}

const itemData = [
  {
    img: ebcHeliTrek1,
    title: "everest heli",
  },
  {
    img: ebcHeliTrek2,
    title: "everest heli",
  },
  {
    img: ebcHeliTrek3,
    title: "everest heli",
  },
  {
    img: ebcHeliTrek4,
    title: "everest heli",
  },
  {
    img: ebcHeliTrek5,
    title: "everest heli",
  },
  {
    img: ebcHeliTrek6,
    title: "everest heli",
  },
  {
    img: ebcHeliTrek7,
    title: "everest heli",
  },
  {
    img: ebcHeliTrek8,
    title: "everest heli",
  },
  {
    img: ebcHeliTrek9,
    title: "everest heli",
  },
  {
    img: ebcHeliTrek10,
    title: "everest heli",
  },
  {
    img: ebcHeliTrek11,
    title: "everest heli",
  },
  {
    img: ebcHeliTrek12,
    title: "everest heli",
  },
  {
    img: ebcHeliTrek13,
    title: "everest heli",
  },
  {
    img: ebcHeliTrek14,
    title: "everest heli",
  },
  {
    img: ebcHeliTrek15,
    title: "everest heli",
  },
  {
    img: ebcHeliTrek16,
    title: "everest heli",
  },
  {
    img: ebcHeliTrek17,
    title: "everest heli",
  },
  {
    img: ebcHeliTrek18,
    title: "everest heli",
  },
  {
    img: ebcHeliTrek19,
    title: "everest heli",
  },
  {
    img: ebcHeliTrek20,
    title: "everest heli",
  },
  {
    img: ebcHeliTrek21,
    title: "everest heli",
  },
  {
    img: ebcHeliTrek22,
    title: "everest heli",
  },
  {
    img: ebcHeliTrek23,
    title: "everest heli",
  },
  {
    img: ebcHeliTrek24,
    title: "everest heli",
  },
  {
    img: ebcHeliTrek25,
    title: "everest heli",
  },
  {
    img: ebcHeliTrek26,
    title: "everest heli",
  },
  {
    img: ebcHeliTrek27,
    title: "everest heli",
  },
  {
    img: ebcHeliTrek28,
    title: "everest heli",
  },
  {
    img: ebcHeliTrek29,
    title: "everest heli",
  },
  {
    img: ebcHeliTrek30,
    title: "everest heli",
  },
  {
    img: ebcHeliTrek31,
    title: "everest heli",
  },
  {
    img: ebcHeliTrek32,
    title: "everest heli",
  },
  {
    img: ebcHeliTrek33,
    title: "everest heli",
  },
  {
    img: ebcHeliTrek34,
    title: "everest heli",
  },
  {
    img: ebcHeliTrek35,
    title: "everest heli",
  },
  {
    img: ebcHeliTrek36,
    title: "everest heli",
  },
  {
    img: ebcHeliTrek37,
    title: "everest heli",
  },
  {
    img: ebcHeliTrek38,
    title: "everest heli",
  },
  {
    img: ebcHeliTrek39,
    title: "everest heli",
  },
  {
    img: ebcHeliTrek40,
    title: "everest heli",
  },
];
