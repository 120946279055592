import * as React from "react";
import Box from "@mui/material/Box";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";

import image1 from "../../../image/IslandPeakClimbing/islandPeakClimbing.jpeg";
import image2 from "../../../image/IslandPeakClimbing/islandPeakBaseCamp.jpg";
import image3 from "../../../image/Everest/EBCTrek/4.jpg";
import image4 from "../../../image/chukkung.jpg";
import image5 from "../../../image/Everest/EBCTrek/1.jpg";
import image6 from "../../../image/Everest/EBCTrek/6.jpg";
import image7 from "../../../image/Everest/EBCTrek/7.jpg";
import image8 from "../../../image/Everest/EBCTrek/8.jpg";
import image9 from "../../../image/Everest/EBCTrek/9.jpg";
import image10 from "../../../image/Everest/EBCTrek/10.jpg";
import image11 from "../../../image/Everest/EBCTrek/11.jpg";
import image12 from "../../../image/Everest/EBCTrek/12.jpg";

export default function MasonryImageList() {
  return (
    <>
      <h4 className="title mt-5">Photo Gallery</h4>
      <Box
        className="gallery-box mb-5"
        sx={{ overflowY: "scroll" }}
        style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
      >
        <ImageList variant="masonry" cols={3} gap={8}>
          {itemData.map((item) => (
            <ImageListItem key={item.img}>
              <img
                src={`${item.img}?w=248&fit=crop&auto=format`}
                srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                alt={item.title}
                // loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
    </>
  );
}

const itemData = [
  {
    img: image1,
    title: "everest heli",
  },
  {
    img: image2,
    title: "island peak climbing",
  },
  {
    img: image3,
    title: "island peak climbing",
  },
  {
    img: image4,
    title: "island peak climbing",
  },
  {
    img: image5,
    title: "island peak climbing",
  },
  {
    img: image6,
    title: "island peak climbing",
  },
  {
    img: image7,
    title: "island peak climbing",
  },
  {
    img: image8,
    title: "island peak climbing",
  },
  {
    img: image9,
    title: "island peak climbing",
  },
  {
    img: image10,
    title: "island peak climbing",
  },
  {
    img: image11,
    title: "island peak climbing",
  },
  {
    img: image12,
    title: "island peak climbing",
  },
];
