import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Container } from "react-bootstrap";

function createData(name, price) {
  return { name, price };
}

const rows = [
  createData("Gorakshep to Lukla", "$700"),
  createData("Gorakshep to Kathmandu", "$1300"),
  createData("Gokyo to Lukla", "$1400"),
  createData("Dingboche to Kathmandu", "$1100"),
  createData("Tengboche to Kathmandu", "$1000"),
  createData("Namche to Kathmandu", "$1000"),
  createData("Lukla to Kathmandu", "$500"),
];

export default function BasicTable() {
  return (
    <>
      <h3 className="title pt-5">Helicopter pick up price</h3>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 350 }}
          aria-label="simple table"
          className="trip-table"
        >
          <TableHead>
            <TableRow>
              <TableCell>Name of place</TableCell>
              <TableCell align="right">Price Per Person</TableCell>
              {/* <TableCell align="right">Fat&nbsp;(g)</TableCell>
                            <TableCell align="right">Carbs&nbsp;(g)</TableCell>
                            <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="right">{row.price}</TableCell>
                {/* <TableCell align="right">{row.fat}</TableCell>
                                <TableCell align="right">{row.carbs}</TableCell>
                                <TableCell align="right">{row.protein}</TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
