import { useInView } from "react-intersection-observer";
import Modals from "./Modals";

import mardiMeals from "../../../image/MardiHimalTrek/mardiHimal/11.jpg";
import mardiTrek from "../../../image/MardiHimalTrek/mardiHimal/4.jpg";
import mardiAccommodation from "../../../image/MardiHimalTrek/mardiHimal/1.jpg";
import mardiTrek2 from "../../../image/MardiHimalTrek/mardiHimal/9.jpg";
import mardiTrek1 from "../../../image/MardiHimalTrek/mardiHimal/5.jpg";
import mardiTrek3 from "../../../image/MardiHimalTrek/mardiHimal/18.jpg";

const Overview = () => {
  const tripTitle = "Mardi Himal Base Camp Trek - 9 Days";
  const tripPrice = "$540 pp (Budget Price)";
  const modaltrip = [
    {
      id: 1,
      modalhead: "Trip Overview",
      modaldetail: [
        {
          modaltitle: "Difficulty Level",
          modaltext: <strong>Moderate to Difficult</strong>,
        },
        {
          modaltitle: "Maximum Altitude",
          modaltext: <strong>4,500 m/14,763 ft</strong>,
        },
        {
          modaltitle: "Best Season",
          modaltext: <strong>Spring & Autumn</strong>,
        },
      ],
      trip: "Mardi Himal Base Camp Trek",
    },
    {
      id: 2,
      modalhead: "The Challenge",
      modaldetail: [
        {
          modaltitle: "Difficulty Level",
          modaltext: <strong>Moderate to Difficult</strong>,
        },
        {
          modaltitle: "Maximum Altitude",
          modaltext: <strong>4,500 m/14,763 ft</strong>,
        },
      ],
      trip: "Mardi Himal Base Camp Trek",
    },
    {
      id: 3,
      modalhead: "Accommodations",
      modaldetail: [
        {
          modaltitle: "Accommodation on the trail",
          modaltext: <strong>Tea house</strong>,
        },
        {
          modaltitle: "In Kathmandu",
          modaltext: <strong>Three-star hotel</strong>,
        },
        {
          modaltitle: "Style",
          modaltext: <strong>Shared</strong>,
        },
      ],
      trip: "Mardi Himal Base Camp Trek",
    },
    {
      id: 4,
      modalhead: "Nutrition",
      modaldetail: [
        {
          modaltitle: "Meals in Kathmandu",
          modaltext: <strong>All breakfasts & welcome/farewell dinners</strong>,
        },
        {
          modaltitle: "Meals during the trek",
          modaltext: (
            <strong>Three meals a day (breakfast, lunch & dinner)</strong>
          ),
        },
        {
          modaltitle: "Cuisine",
          modaltext: <strong>Nepali, Tibetan, Continental & Indian</strong>,
        },
      ],
      trip: "Mardi Himal Base Camp Trek",
    },
    {
      id: 5,
      modalhead: "Regeneration",
      modaldetail: [
        {
          modaltitle: "Morning stretching",
          modaltext:
            "Practice yoga & meditation by yourself (Or upgrade to yoga trekking, led by a professional yoga instructor)",
        },
        {
          modaltitle: "",
          modaltext: (
            <strong>
              Find your true self in the grandeur of the Himalayas
            </strong>
          ),
        },
      ],
      trip: "Mardi Himal Base Camp Trek",
    },
    {
      id: 6,
      modalhead: "Destination",
      modaldetail: [
        {
          modaltitle: "Duration",
          modaltext: <strong>9 Days</strong>,
        },
        {
          modaltitle: "Location",
          modaltext: <strong>Annapurna Region, Nepal</strong>,
        },
        {
          modaltitle: "Province",
          modaltext: <strong>4</strong>,
        },
        {
          modaltitle: "Population",
          modaltext: <strong>100,000+</strong>,
        },
        {
          modaltitle: "Wildlife Species",
          modaltext: <strong>2,000+</strong>,
        },
        {
          modaltitle: "Highest Points",
          modaltext: <strong>Mardi Himal Base Camp (4,500 m/14,763 ft)</strong>,
        },
      ],
      trip: "Mardi Himal Base Camp Trek",
    },
  ];
  let sendmodaldetails = {};

  const { ref, inView } = useInView();
  const { ref: ref2, inView: inView2 } = useInView();
  const { ref: ref3, inView: inView3 } = useInView();
  const { ref: ref4, inView: inView4 } = useInView();
  const { ref: ref5, inView: inView5 } = useInView();
  const { ref: ref6, inView: inView6 } = useInView();

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll, true);
  // }, [handleScroll]);
  if (inView6 === true) {
    sendmodaldetails = modaltrip[5];
  } else if (inView5 === true) {
    sendmodaldetails = modaltrip[4];
  } else if (inView4 === true) {
    sendmodaldetails = modaltrip[3];
  } else if (inView3 === true) {
    sendmodaldetails = modaltrip[2];
  } else if (inView2 === true) {
    sendmodaldetails = modaltrip[1];
  } else if (inView === true) {
    sendmodaldetails = modaltrip[0];
  } else {
    sendmodaldetails = modaltrip[5];
  }
  return (
    <>
      <div ref={ref} className="trip-details-next container mt-5">
        <div className="trip-fact col-12 flex-sm-wrap mb-3">
          <div className="facts">
            <div className="fact mb-3">
              <h1>MARDI HIMAL TREK - 9 DAYS</h1>
            </div>
            <div ref={ref} className="title-content col-sm-12 col-md-7 col-lg-8 mb-3">
            The Mardi Himal trail hasn't been used as much because it just opened in 2012. Even though the number of people trekking the Mardi Himal has gone up in recent years, the trail is still not very busy. So, if you want to get away from the crowds without doing difficult treks, the Mardi Himal base camp trek is the best choice for you. Overall, the Mardi Himal trek takes 9 days and is a great trip with amazing views of Mt. Fishtail (6,993 m) and the Annapurna Range (8,091 m). The hiking trail goes from subtropical woods to alpine areas, with meadows and steep ridges in between.
        </div>
          </div>
        </div>
        <div ref={ref} className="big-title col-sm-12 col-md-7 col-lg-8 mb-3">
        A MEDIUM OFF-THE-BEATEN-TRAIL WITH FEW TOURIST FACILITIES. TEST BOTH YOUR MIND AND  BODY AT THE BASE OF MARDI HIMAL
        </div>
        <div ref={ref} className="title-content col-sm-12 col-md-7 col-lg-8 mb-3">
        The difficulty level of the Mardi Himal trek is considered to be moderate. There are some steep parts and some rocky and slippery trails. The Mardi Himsl base camp trek is at a total distance of about 65 kilometers, and it usually takes about 8 or 9 days to finish. Depending on the route and the speed of the group, a day of hiking can take anywhere from 5 to 8 hours. On the Mardi Himal base camp  trek, altitude is also something to think about because the trail goes up to 4,500 meters. Be aware of the possibility of altitude sickness and take the right steps to get used to the altitude.
        </div>

        <img
          ref={ref}
          src={mardiTrek}
          alt="mardi himal"
          className="col-sm-12 col-md-12 mb-3 w-100"
        />

        <div
          ref={ref2}
          className="row wrap justify-content-between col-sm-12 my-3"
        >
          <div className="col-sm-12 col-md-6">
            <div className="big-title col-sm-12 mb-3">
              A MODERATE OFF-THE-BEATEN TRAIL AND LIMITED TOURIST
              INFRASTRUCTURE. TEST YOUR MENTAL AND PHYSICAL STRENGTH.
            </div>
            <div className="title-content col-sm-12 mb-3">
              The Mardi Himal trek difficulty is considered a moderate-level,
              with several steep sections and some rocky and slippery trails.
              The trek involves a total distance of approximately 65 kilometers,
              and typically takes around 8-9 days to complete. The daily
              trekking distance ranges from 5-8 hours, depending on the route
              and the pace of the group. Altitude is also a factor to consider
              on the Mardi Himal trekking, as the route reaches elevations of up
              to 4,500 meters. Be aware of the risk of altitude sickness and
              take proper precautions to acclimatize properly.
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <img
              src={mardiTrek1}
              alt="mardi himal base camp trek"
              className="col-sm-12 mb-3 img-fluid"
            />
          </div>
        </div>
        <div
          ref={ref3}
          className="flex row wrap justify-content-between col-sm-12 mt-5"
        >
          <div className="col-sm-12 col-md-6">
            <img
              src={mardiAccommodation}
              alt="mardi himal"
              className="col-sm-12 img-fluid"
            />
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="big-title col-sm-12 mb-3">
            IN THE HEART OF THE WESTERN HIMALAYAS, FIND YOUR HOME AWAY FROM HOME. A COZY SPOT IN THE COUNTRY OF MOUNTAINS
            </div>
            <div className="title-content col-sm-12 mb-3">
            The simple tea houses along the trail are made very special by the friendly people who live there. Tea houses are the most basic type of place to stay in the Himalayas. On the Mardi Himal trek, you can relax and enjoy the friendly atmosphere of the tea houses after a day of hiking. You can talk with other trekkers over a hot drink in the cozy dining area. Most of the time, the rooms are shared and have two separate beds and not much else. Most of the time, you have to share the bathroom. After a long day of hiking, you will be glad to get into bed and let your muscles rest.
            </div>
          </div>
        </div>
        <div
          ref={ref4}
          className="row wrap justify-content-between col-sm-12 my-3"
        >
          <div className="col-sm-12 col-md-6">
            <div className="big-title col-sm-12 mb-3">
            DURING THE TRIP, DISCOVER THE FLAVORS OF THE HIMALAYAS AND TASTE THE REAL TASTE OF NEPAL.  
            </div>
            <div className="title-content col-sm-12 mb-3">
            Delicious local cuisine is a highlight of our travels through Nepal and the Himalayas. Before leaving, we make everyone of our guests try a selection of Nepal's greatest meals. The deliciousness and depth of history behind the meal gives the journey further meaning. Visitors' food and drink preferences can shed light on their personalities for the locals. Food, in our opinion, is a great unifier of society. It is  a great icebreaker and conversation starter. Travelers to Nepal will not  have a dull moment because of the wide variety of local cultures.
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <img
              src={mardiMeals}
              alt="mardi himal"
              className="col-sm-12 mb-3 img-fluid"
            />
          </div>
        </div>
        <div
          ref={ref5}
          className="flex row wrap justify-content-between col-sm-12 mt-5"
        >
          <div className="col-sm-12 col-md-6">
            <img
              src={mardiTrek3}
              alt="mardi himal"
              className="col-sm-12  img-fluid"
            />
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="big-title col-sm-12 mb-3">
            FIND PEACE OF MIND IN THE QUIET PEACEFUL HIMALAYAS.TAKE ON THE CHALLENGES AND YOU WILL BE REWARDED WITH OUT OF THIS WORLD VIEWS
            </div>
            <div className="title-content col-sm-12 mb-3">
            Trekking in the Himalayas is an amazing, life-changing experience that lets you connect with nature in a deep and meaningful way.While on the Mardi Himal base camp trek, As you walk through the beautiful scenery, you  will be surrounded by the beauty of the mountains, and every turn will give you a breathtaking view. Being in nature will make you feel calm and peaceful. Trekking in the Himalayas can also be exciting and hard as you push yourself to reach new heights and beat the elements. Face and overcome the physical and mental challenges of the trek, and you will gain a sense of strength and self-confidence that will last long after the trip is over.
            </div>
          </div>
        </div>
        <div
          ref={ref6}
          className="row wrap justify-content-between col-sm-12 my-3"
        >
          <div className="col-sm-12 col-md-6">
            <div className="big-title col-sm-12 mb-3">
            MARDI HIMAL BASE CAMP IS A GREAT ALTERNATIVE TO THE WELL-KNOWN ANNAPURNA BASE CAMP. 
            </div>
            <div className="title-content col-sm-12 mb-3">
            Mardi Himal is one of the many beautiful Himalayan mountains in the area around Annapurna. Our Mardi Himal trek package is designed to take travelers away from the busy trails and on an exciting adventure in the Annapurna region. The Mardi Himal trek is in a very remote area because the trail is not well set up for tourists. There are a few tea houses and guest houses in every village for trekkers. So, if you want a place to sleep for the night, you should book early. As you go up the trail, you will stop at different camps to spend the night. Because the trail goes through a dense forest, you will see a lot of different plants and animals. Nepal's national flower, Rhododendron, and the cross between a yak and a cow, called a dozkpo, are some of the best things about the Mardi Himal trekking route. The Mardi Himal base camp trek only takes a little over a week, but it is a once-in-a-lifetime experience because of the great trekking experience, beautiful mountain views, and rich cultural history of the Nepali people.
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <img
              src={mardiTrek2}
              alt="mardi himal"
              style={{ backgroundPosition: "top" }}
              className="col-sm-12 mb-3 img-fluid"
            />
          </div>
        </div>
      </div>
      {/* <div className="container map"></div> */}
      <div className="main-modal">
        <Modals
          sendmodaldetails={sendmodaldetails}
          tripTitle={tripTitle}
          tripPrice={tripPrice}
        />
      </div>
    </>
  );
};

export default Overview;
