import React from "react";
import { Card } from "react-bootstrap";
import { HashLink } from "react-router-hash-link";
import card1 from "../../image/Everest/EBC-Heli/everestHeliTour.jpg";
import card2 from "../../image/Everest/ebcTrek1.jpeg";

function popupcard() {
  return (
    <div>
      <div className="row popupcard">
        <div className="col-sm-12 col-md-6">
          <Card style={{ width: "18rem" }}>
            <Card.Img variant="top" src={card1} alt="card1" />
            <Card.Body>
              <Card.Title>EBC Helicopter Tour</Card.Title>
              {/* <Card.Text>Get The best view of Mount Everest.</Card.Text> */}
              <Card.Text>
                <strong>Price</strong>
                <br />
                <span className="discount-price">
                  <p>Private: $3900</p>
                  <p>Budget Sharing: $950</p>
                </span>{" "}
              </Card.Text>
              <button variant="primary" className="first-modal-btn btn">
                <HashLink
                  smooth
                  to="/everest-base-camp-helicopter-tour/info"
                  className="link"
                  target={"_blank"}
                >
                  View Trip
                </HashLink>
              </button>
            </Card.Body>
          </Card>
        </div>
        <div className="col-sm-12 col-md-6">
          <Card style={{ width: "18rem" }}>
            <Card.Img variant="top" src={card2} alt="card1" />
            <Card.Body>
              <Card.Title>Everest Base Camp Trek</Card.Title>
              <Card.Text>
                <strong>Price</strong>
                <br />
                <span className="discount-price">
                  <p>Luxury Price: $1290</p>
                  <p>
                    Budget Price: {""}
                    {/* <del className="text-danger">$950</del> */}
                    $1090
                  </p>
                </span>{" "}
              </Card.Text>
              <button variant="primary" className="first-modal-btn btn">
                <HashLink smooth to="/everest-base-camp-trek" className="link">
                  View Trip
                </HashLink>
              </button>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default popupcard;
