import React from "react";
import Accordion from "react-bootstrap/Accordion";

import { FaCheckCircle } from "react-icons/fa";
import CancelIcon from "@mui/icons-material/Cancel";

import GokyoTable from "./GokyoTable";
import Gallery from "./Gallery";
import TripFact from "./TripFact";

export default function Overview() {
  return (
    <div className="overview">
      <div className="container d-flex mt-5">
        <div className="row">
          <div className="col-12">
           <TripFact/>

            <Gallery/>

            <h4 className="overview-title mt-4">Outline Itinerary</h4>
            <ul className="p-0">
              <div className="overview-outline-itinerary">
                <div className="col-auto">
                  <strong>Day 01:</strong>
                </div>
                <li>Kathmandu arrival (1,300 m/4,265 ft)</li>
              </div>

              <div className="overview-outline-itinerary">
                <div className="col-auto">
                  <strong>Day 02:</strong>
                </div>
                <li>
                  Cultural tours around Kathmandu Valley & trek preparation
                </li>
              </div>

              <div className="overview-outline-itinerary">
                <div className="col-auto">
                  <strong>Day 03:</strong>
                </div>
                <li>
                  Fly to Lukla (2,846 m/9,337 ft) and trek to Phakding (2,610
                  m/8,563 ft)
                </li>
              </div>

              <div className="overview-outline-itinerary">
                <div className="col-auto">
                  <strong>Day 04:</strong>
                </div>
                <li>Trek to Namche Bazaar (3,440 m/11,290 ft)</li>
              </div>

              <div className="overview-outline-itinerary">
                <div className="col-auto">
                  <strong>Day 05:</strong>
                </div>
                <li>
                  Acclimatization day at Namche Bazaar: hike to Everest View
                  Hotel (3,962 m)
                </li>
              </div>

              <div className="overview-outline-itinerary">
                <div className="col-auto">
                  <strong>Day 06:</strong>
                </div>
                <li>Trek to Dole (4,200 m/13,799 ft)</li>
              </div>

              <div className="overview-outline-itinerary">
                <div className="col-auto">
                  <strong>Day 07:</strong>
                </div>
                <li>Trek to Machhermo (4,470 m/14,665 ft)</li>
              </div>

              <div className="overview-outline-itinerary">
                <div className="col-auto">
                  <strong>Day 08:</strong>
                </div>
                <li>Trek to Gokyo (4,750 m/15,583 ft)</li>
              </div>

              <div className="overview-outline-itinerary">
                <div className="col-auto">
                  <strong>Day 09:</strong>
                </div>
                <li>Gokyo Ri hiking (5,357 m/17,575 ft)</li>
              </div>

              <div className="overview-outline-itinerary">
                <div className="col-auto">
                  <strong>Day 10:</strong>
                </div>
                <li>Trek to Dole (4,200/13,799 ft)</li>
              </div>

              <div className="overview-outline-itinerary">
                <div className="col-auto">
                  <strong>Day 11:</strong>
                </div>
                <li>Trek to Monjo (2,835 m/9,301 ft)</li>
              </div>

              <div className="overview-outline-itinerary">
                <div className="col-auto">
                  <strong>Day 12:</strong>
                </div>
                <li>Trek to Lukla (2,846 m/9,337 ft)</li>
              </div>

              <div className="overview-outline-itinerary">
                <div className="col-auto">
                  <strong>Day 13:</strong>
                </div>
                <li>Fly to Kathmandu (1,300 m/4,265 ft)</li>
              </div>
            </ul>

            <h4 className="overview-title pt-5">Trip Highlight</h4>
            <ul className="trip-highlight">
              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>
                  Thrilling flight in and out of Lukla airport with captivating
                  aerial views
                </li>
              </span>

              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>Embark on a less crowded trail in the Everest region</li>
              </span>

              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>
                  Pass by picturesque villages and rhododendron forests,
                  crossing suspension bridges & waterfalls
                </li>
              </span>

              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>
                  Explore Namche Bazaar and hike to Hotel Everest View to
                  witness stunning mountain views
                </li>
              </span>

              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>
                  Get to see the daily lifestyle of Sherpa and their rich
                  cultural heritage
                </li>
              </span>

              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>
                  Witness stunning views of Ngozumpa Glaciers, the largest
                  glacier in the Himalayas
                </li>
              </span>

              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>Explore the beautiful Gokyo valley and holy Gokyo Lakes</li>
              </span>

              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>
                  Hike to Gokyo Ri to catch glorious sunrise and mountain
                  panorama
                </li>
              </span>
            </ul>

            <div className="row d-flex justify-content-between">
              <div className="col-12">
                <h4 className="overview-title pt-5">Cost Include</h4>
                <ul className="cost-include">
                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>
                      All ground transport in a private vehicle, including
                      airport transfers
                    </li>
                  </span>

                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>
                      Three nights' accommodation with breakfast at a 3-star
                      hotel in Kathmandu (as per the itinerary)
                    </li>
                  </span>

                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>
                      Three daily meals with tea/coffee (breakfast, lunch, and
                      dinner) during the trek
                    </li>
                  </span>

                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>
                      Best available twin-sharing lodge for accommodation during
                      the trek
                    </li>
                  </span>

                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>
                      One highly experienced and friendly guide with porters (1
                      porter for two people) and their expenses
                    </li>
                  </span>

                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>
                      Round trip flight Kathmandu – Lukla, including departure
                      taxes
                    </li>
                  </span>

                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>Trekking map and trip achievement certificate</li>
                  </span>

                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>First aid medicine kit</li>
                  </span>

                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>All the necessary trekking permits</li>
                  </span>

                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>Government taxes & office service charge</li>
                  </span>
                </ul>
              </div>
              <div className="col-12">
                <h4 className="overview-title pt-5">Cost Exclude</h4>
                <ul className="cost-exclude">
                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>
                      Meals not specified in the 'Meal Inclusions' in the above
                      itinerary
                    </li>
                  </span>

                  <span>
                    <div className="cost-include-icon">
                      <CancelIcon />
                    </div>
                    <li>Down jacket and sleeping bag</li>
                  </span>

                  <span>
                    <div className="cost-include-icon">
                      <CancelIcon />
                    </div>
                    <li>Duffle bag for trekking</li>
                  </span>

                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>Travel Insurance</li>
                  </span>

                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>International airfare</li>
                  </span>

                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>Nepal entry visa</li>
                  </span>

                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>Soft drinks, including mineral water</li>
                  </span>

                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>Snacks and other personal expenses</li>
                  </span>

                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>
                      Hot showers, charging, internet, etc expenses during the
                      trek
                    </li>
                  </span>

                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>Personal trekking equipment</li>
                  </span>

                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>Tips and gratuities for trekking staff and drivers</li>
                  </span>
                </ul>
              </div>
            </div>

            <GokyoTable />

            <h4 className="overview-title pt-5">FAQ</h4>

            <div className="trip-overview-accordion">
              <Accordion defaultActiveKey="0" flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <p class="accordion-title">
                      1) How long is the Gokyo Ri Trek?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class="overview-content">
                      Gokyo Lake Trek is a 14 days trek that begins from Lukla
                      and ascends to Gokyo Ri. You can customize the itinerary
                      with us and make it as per your needs.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <p class="accordion-title">2) How hard is Gokyo Trek?</p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class="accordion-content">
                      The difficulty of the Gokyo trek is mode rate. The trail
                      has a gentle pace with continuous ascends and descends.
                      Six hours of the daily walk is needed, so the trekkers
                      have to be fit enough to keep up with their stamina. Also,
                      the trekkers have to endure the high altitude and limited
                      services available along the way.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    <p class="accordion-title">
                      3) How do I get to Gokyo lake?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class="overview-content">
                      To reach Gokyo Lakes from Kathmandu, you have to take a
                      short flight to Lukla and walk for five days to reach
                      there. Or, you can take a helicopter ride from Kathmandu
                      or Lukla to Gokyo Lakes.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    <p class="accordion-title">
                      4) Is the Gokyo lake trek worth it?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class="overview-content">
                      Yes, the Gokyo Lake Trekking is very worth it. Every
                      aspect of this trek is worth all of your time and money.
                      It is a once-in-a-lifetime trek packed with adventures and
                      phenomenal views.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    <p class="accordion-title">
                      5) How many lakes are there in Gokyo?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class="overview-content">
                      There are altogether 19 lakes in the Gokyo lake system,
                      ranging from 4,600 m to 5,100 m. However, there are six
                      main Gokyo Lakes; Dudh Pokhari, Thonak Cho, Gyazumpa Cho,
                      Tanjung Cho, Ngojumba Cho, and Gokyo Lake.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header>
                    <p class="accordion-title">6) How high is Gokyo Peak?</p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class="overview-content">
                      Gokyo peak, or also known as Gokyo Ri, is 5,357 meters
                      above sea level.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                  <Accordion.Header>
                    <p className="accordion-title">
                      7) Can you see Everest from Gokyo Ri?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class="overview-content">
                      Yes, you can see Mt. Everest from Gokyo Ri. Moreover, the
                      view of Everest from Gokyo Ri is said to be one of the
                      best views of this mountain. Apart from Everest, you also
                      get to see other 8,000 meters high mountains like Cho Oyu,
                      Lhotse, and Makalu from Gokyo Peak.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                  <Accordion.Header>
                    <p class="accordion-title">
                      8) Can I do the Gokyo Lake trek alone?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class="overview-content">
                      Yes, you can do the Gokyo Lakes trek alone. We only
                      recommend trekking alone if you understand the Himalayan
                      trail and have good knowledge about the region.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
