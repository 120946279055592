import * as React from "react";
import Box from "@mui/material/Box";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";

import manasluTrek1 from "../../../image/Manaslu/manasluTrek/1.jpg";
import manasluTrek2 from "../../../image/Manaslu/manasluTrek/2.jpg";
import manasluTrek3 from "../../../image/Manaslu/manasluTrek/3.jpg";
import manasluTrek4 from "../../../image/Manaslu/manasluTrek/4.jpg";
import manasluTrek5 from "../../../image/Manaslu/manasluTrek/5.jpg";
import manasluTrek6 from "../../../image/Manaslu/manasluTrek/6.jpg";
import manasluTrek7 from "../../../image/Manaslu/manasluTrek/7.jpg";
import manasluTrek8 from "../../../image/Manaslu/manasluTrek/7v2.jpg";
import manasluTrek9 from "../../../image/Manaslu/manasluTrek/8.jpg";
import manasluTrek10 from "../../../image/Manaslu/manasluTrek/9v2.jpg";
import manasluTrek11 from "../../../image/Manaslu/manasluTrek/9.jpg";
import manasluTrek12 from "../../../image/Manaslu/manasluTrek/10.jpg";
import manasluTrek13 from "../../../image/Manaslu/manasluTrek/11.jpg";
import manasluTrek14 from "../../../image/Manaslu/manasluTrek/11v2.jpg";
import manasluTrek15 from "../../../image/Manaslu/manasluTrek/12.jpg";
import manasluTrek16 from "../../../image/Manaslu/manasluTrek/12v2.jpg";
import manasluTrek17 from "../../../image/Manaslu/manasluTrek/12v3.jpg";
import manasluTrek18 from "../../../image/Manaslu/manasluTrek/13.jpg";
import manasluTrek19 from "../../../image/Manaslu/manasluTrek/14.jpg";

export default function MasonryImageList() {
  return (
    <>
      <h4 className="title mt-5">Photo Gallery</h4>
      <Box
        className="gallery-box mb-5"
        sx={{ overflowY: "scroll" }}
        style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
      >
        <ImageList variant="masonry" cols={3} gap={8}>
          {itemData.map((item) => (
            <ImageListItem key={item.img}>
              <img
                src={`${item.img}?w=248&fit=crop&auto=format`}
                srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                alt={item.title}
                // loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
    </>
  );
}

const itemData = [
  {
    img: manasluTrek1,
    title: "everest heli",
  },
  {
    img: manasluTrek2,
    title: "everest heli",
  },
  {
    img: manasluTrek3,
    title: "everest heli",
  },
  {
    img: manasluTrek4,
    title: "everest heli",
  },
  {
    img: manasluTrek5,
    title: "everest heli",
  },
  {
    img: manasluTrek6,
    title: "everest heli",
  },
  {
    img: manasluTrek7,
    title: "everest heli",
  },
  {
    img: manasluTrek8,
    title: "everest heli",
  },
  {
    img: manasluTrek9,
    title: "everest heli",
  },
  {
    img: manasluTrek10,
    title: "everest heli",
  },
  {
    img: manasluTrek11,
    title: "everest heli",
  },
  {
    img: manasluTrek12,
    title: "everest heli",
  },
  {
    img: manasluTrek13,
    title: "everest heli",
  },
  {
    img: manasluTrek14,
    title: "everest heli",
  },
  {
    img: manasluTrek15,
    title: "everest heli",
  },
  {
    img: manasluTrek16,
    title: "everest heli",
  },
  {
    img: manasluTrek17,
    title: "everest heli",
  },
  {
    img: manasluTrek18,
    title: "everest heli",
  },
  {
    img: manasluTrek19,
    title: "everest heli",
  },
];
