import { useInView } from "react-intersection-observer";
import Modals from "./Modals";

import gokyo1 from "../../../image/Everest/gokyolake1.jpg";
import gokyo2 from "../../../image/Everest/gokyolaketrek.jpg";
import gokyo3 from "../../../image/Everest/gokyolaketrek1.jpeg";
import gokyo4 from "../../../image/Manaslu/manaslu3.jpg";
import gokyo5 from "../../../image/Everest/gokyolaketrek3.jpg";
import gokyo6 from "../../../image/Everest/gokyolaketrek2.jpg";

const Overview = () => {
  const tripTitle = "Gokyo Lake Trek - 13 Days";
  const tripPrice = "$1190 pp"
  const modaltrip = [
    {
      id: 1,
      modalhead: "Trip Overview",
      modaldetail: [
        {
          modaltitle: "Difficulty Level",
          modaltext: <strong>Moderate to Difficult</strong>,
        },
        {
          modaltitle: "Maximum Altitude",
          modaltext: <strong>5,357 m/17,575 ft</strong>,
        },
        {
          modaltitle: "Best Season",
          modaltext: <strong>Spring & Autumn</strong>,
        },
      ],
      trip: "Gokyo Lake Trek",
    },
    {
      id: 2,
      modalhead: "The Challenge",
      modaldetail: [
        {
          modaltitle: "Difficulty Level",
          modaltext: <strong>Moderate to Difficult</strong>,
        },
        {
          modaltitle: "Maximum Altitude",
          modaltext: <strong>5,357 m/17,575 ft</strong>,
        },
      ],
      trip: "Goky Lake Trek",
    },
    {
      id: 3,
      modalhead: "Accommodations",
      modaldetail: [
        {
          modaltitle: "Accommodation on the trail",
          modaltext: <strong>Lodge</strong>,
        },
        {
          modaltitle: "In Kathmandu",
          modaltext: <strong>Three-star hotel</strong>,
        },
        {
          modaltitle: "Style",
          modaltext: <strong>Shared</strong>,
        },
      ],
      trip: "Gokyo Lake Trek",
    },
    {
      id: 4,
      modalhead: "Nutrition",
      modaldetail: [
        {
          modaltitle: "Meals in Kathmandu",
          modaltext: <strong>All breakfasts & welcome/farewell dinners</strong>,
        },
        {
          modaltitle: "Meals during the trek",
          modaltext: (
            <strong>Three meals a day (breakfast, lunch & dinner)</strong>
          ),
        },
        {
          modaltitle: "Cuisine",
          modaltext: <strong>Nepali, Tibetan, Continental & Indian</strong>,
        },
      ],
      trip: "Gokyo Lake Trek",
    },
    {
      id: 5,
      modalhead: "Regeneration",
      modaldetail: [
        {
          modaltitle: "Morning stretching",
          modaltext:
            "Practice yoga & meditation by yourself (Or upgrade to yoga trekking, led by a professional yoga instructor)",
        },
        {
          modaltitle: "",
          modaltext: (
            <strong>Re-learn to live slow & be present in every moment</strong>
          ),
        },
      ],
      trip: "Gokyo Lake Trek",
    },
    {
      id: 6,
      modalhead: "Destination",
      modaldetail: [
        {
          modaltitle: "Duration",
          modaltext: <strong>13 Days</strong>,
        },
        {
          modaltitle: "Location",
          modaltext: <strong>Everest Region, Nepal</strong>,
        },
        {
          modaltitle: "Province",
          modaltext: <strong>1</strong>,
        },
        {
          modaltitle: "Population",
          modaltext: <strong>9,133+</strong>,
        },
        {
          modaltitle: "Wildlife Species",
          modaltext: <strong>1,500+</strong>,
        },
        {
          modaltitle: "Highest Points",
          modaltext: <strong>Gokyo Ri (5,357 m/17,575 ft)</strong>,
        },
      ],
      trip: "Gokyo Lake Trek",
    },
  ];
  let sendmodaldetails = {};

  const { ref, inView } = useInView();
  const { ref: ref2, inView: inView2 } = useInView();
  const { ref: ref3, inView: inView3 } = useInView();
  const { ref: ref4, inView: inView4 } = useInView();
  const { ref: ref5, inView: inView5 } = useInView();
  const { ref: ref6, inView: inView6 } = useInView();

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll, true);
  // }, [handleScroll]);
  if (inView6 === true) {
    sendmodaldetails = modaltrip[5];
  } else if (inView5 === true) {
    sendmodaldetails = modaltrip[4];
  } else if (inView4 === true) {
    sendmodaldetails = modaltrip[3];
  } else if (inView3 === true) {
    sendmodaldetails = modaltrip[2];
  } else if (inView2 === true) {
    sendmodaldetails = modaltrip[1];
  } else if (inView === true) {
    sendmodaldetails = modaltrip[0];
  } else {
    sendmodaldetails = modaltrip[5];
  }
  return (
    <>
      <div ref={ref} className="trip-details-next container mt-5">
        <div className="trip-fact col-10 flex-sm-wrap mb-3">
          <div className="facts">
            <div className="fact mb-3">
              <h1>GOKYO LAKE TREK - 13 DAYS</h1>
            </div>
          </div>
        </div>
        <div ref={ref} className="big-title col-sm-12 col-md-7 mb-3">
          GOKYO LAKE TREK IS ONE OF THE BEST ALTERNATIVE ROUTES IN THE EVEREST
          REGION FOR A LESS CROWDED TRAIL & STUNNING LANDSCAPES
        </div>
        <div ref={ref} className="title-content col-sm-12 col-md-7 mb-3">
          Unlike the famous Everest base camp trek, the Gokyo Lake trail is
          relatively less crowded. It is an adventurous trek with moderate
          difficulty that goes along the Ngozumpa Glacier, the largest glacier
          in the Himalayas, and takes you to the shores of six turquoise alpine
          lakes called Gokyo Lakes. Our Gokyo Lake trek itinerary involves a
          thrilling flight to Lukla airport, explores the iconic Namche Bazaar,
          and presents great insights into the daily lifestyle of the Sherpas.
          Mountains like Everest (8,849 m), Nuptse (7,861 m), Ama Dablam (6,812
          m), Thamserku (6,608 m), Island Peak (6,160 m), Baruntse (7,162 m),
          etc are seen constantly along the trail.
        </div>

        <img
          ref={ref}
          src={gokyo1}
          alt="everest"
          className="col-sm-12 col-md-12 mb-3 w-100"
        />

        <div
          ref={ref2}
          className="row wrap justify-content-between col-sm-12 my-3"
        >
          <div className="col-sm-12 col-md-6">
            <div className="big-title col-sm-12 mb-3">
              A PERFECT TREK FOR FIT ENOUGH WHO CAN WALK 6 HOURS A DAY ON
              RUGGED, STEEP TRAILS
            </div>
            <div className="title-content col-sm-12 mb-3">
              Trekking through the Sagarmatha National Park, we follow a
              different route from Namche Bazar onwards that leads to the
              gorgeous Gokyo Valley. The initial part of the trail goes through
              lush rhododendron and pine forests, crossing numerous suspension
              bridges and streams. The trail soon opens up to pastures and
              continues along rugged, steep paths to Gokyo village. Also known
              as the Gokyo Ri trek, you hike to Gokyo Ri, the highest point of
              the journey, following a steep, rocky path. From here onwards,
              it's a long walk back to Lukla with overnight stays at Dole and
              Monjo.
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <img
              src={gokyo2}
              alt="everest"
              className="col-sm-12 mb-3 long-img"
            />
          </div>
        </div>
        <div
          ref={ref3}
          className="flex row wrap justify-content-between col-sm-12 mt-5"
        >
          <div className="col-sm-12 col-md-6">
            <img src={gokyo3} alt="everest" className="col-sm-12 short-img" />
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="big-title col-sm-12 mb-3">
              WITNESS THE INTRIGUING LIFESTYLE OF THE SHERPAS ALONG & LEARN A
              LOT ABOUT SIMPLE LIVING DURING OVERNIGHT STAY IN LODGES
            </div>
            <div className="title-content col-sm-12 mb-3">
              You'll get comfortable lodges runned by local families to spend
              nights while trekking to the Gokyo Lakes. As the lodges are
              operated by Sherpa families, you get to have close interaction
              with them. The rooms are unheated but comfortable and clean.
              You'll need to bring a sleeping bag because the blanket provided
              in the lodge may not be enough to keep you warm. The rooms usually
              have two beds, so you are more likely to share with a fellow
              trekker. The facilities in the lodges are basic. You may get a hot
              shower, wifi, and charging facilities upon paying a few extra
              bucks.
            </div>
          </div>
        </div>
        <div
          ref={ref4}
          className="row wrap justify-content-between col-sm-12 my-3"
        >
          <div className="col-sm-12 col-md-6">
            <div className="big-title col-sm-12 mb-3">
              A JOURNEY FULL OF NEPALI FLAVORS AND GIVE YOU AMPLE SPACE TO
              INDULGE IN CUISINE FROM ALl AROUND THE WORLD
            </div>
            <div className="title-content col-sm-12 mb-3">
              Our Gokyo Lake trekking will let you taste several Nepali
              delicacies. The food menu has a good variety of Nepali and western
              dishes on the trail. Burgers and chips are not common like dal
              bhat, but you'll get them at some places. Of course, our favorite
              is dal bhat with veggies or curries (veg/non-veg) because of its
              delicious taste and all the nutrients. It's also affordable.
              Likewise, you'll find fresh bakery items, soup, dhido, momo,
              noodles, thukpa, Tibetan bread, stir-fries, etc on the menu.
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <img
              src={gokyo4}
              alt="everest"
              className="col-sm-12 mb-3 long-img"
            />
          </div>
        </div>
        <div
          ref={ref5}
          className="flex row wrap justify-content-between col-sm-12 mt-5"
        >
          <div className="col-sm-12 col-md-6">
            <img
              src={gokyo6}
              alt="everest"
              className="col-sm-12  mb-3 short-img"
            />
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="big-title col-sm-12 mb-3">
              UNWIND AT THE SHORE OF GORGEOUS GOKYO LAKES AND GET INSPIRED BY
              THE SERENITY OF THE EVEREST REGION
            </div>
            <div className="title-content col-sm-12 mb-3">
              The Goko lake trek Nepal is all about testing your limits in the
              remote area, finding a balance in your life, and rejuvenating your
              soul with the freshness of the Himalayas. Completely untouched and
              unbothered by the chaotic city life, you'll be traversing a
              peaceful part of the world. Learn about the mountain lifestyle and
              meet with humble locals. Get inspired by them and the views.
            </div>
          </div>
        </div>
        <div
          ref={ref6}
          className="row wrap justify-content-between col-sm-12 my-3"
        >
          <div className="col-sm-12 col-md-6">
            <div className="big-title col-sm-12 mb-3">
              EXPLORE THE STUNNING TURQUOISE GOKYO LAKES AND PICTURESQUE SHERPA
              VILLAGES OF THE KHUMBU REGION
            </div>
            <div className="title-content col-sm-12 mb-3">
              There are six Gokyo Lakes ranging from 4,700 m to 5,000 m. These
              are the world's highest freshwater lake systems and are also part
              of the Ramsar site. It means the Gokyo Lakes have huge
              international importance too. For the locals, Gokyo Lakes holds
              significant religious values. During our acclimatization day in
              Gokyo village, we’ll explore the 5th and 6th lakes. The dazzling
              turquoise color of the water surrounded by barren hills and
              snow-capped mountains is breathtaking.
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <img
              src={gokyo5}
              alt="everest"
              className="col-sm-12 mb-3 long-img"
            />
          </div>
        </div>
      </div>
      {/* <div className="container map"></div> */}
      <div className="main-modal">
        <Modals sendmodaldetails={sendmodaldetails} tripTitle={tripTitle} tripPrice={tripPrice} />
      </div>
    </>
  );
};

export default Overview;
