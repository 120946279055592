import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  FaMountain,
  FaUtensils,
  FaBed,
  FaHiking,
  FaCampground,
} from "react-icons/fa";
import { FaBus } from "react-icons/fa";
import { FaClock } from "react-icons/fa";
import kathmandu from "../../../image/kathmandu.jpeg";
import lukla from "../../../image/Everest/lukla-airport.jpg";

import "../../../custom-sass/pages/trip/_trip-detail.scss";
import day3 from "../../../image/phakding.jpg";
import day4 from "../../../image/Everest/EBCTrek/3.jpg";
import day5 from "../../../image/Everest/ebcHeliTrek/40.jpg";
import day6 from "../../../image/Everest/EBCTrek/5.jpg";
import day7 from "../../../image/Everest/EBCTrek/6.jpg";
import day8 from "../../../image/Everest/EBCTrek/7.jpg";
import day9 from "../../../image/Everest/EBCTrek/8.jpg";
import day10 from "../../../image/Everest/EBCTrek/9.jpg";
import day11 from "../../../image/chukkung.jpg";
import day12 from "../../../image/IslandPeakClimbing/islandPeakBaseCamp.jpg";
import day13 from "../../../image/IslandPeakClimbing/islandPeakClimbing.jpeg";
import day19 from "../../../image/kathmanduAirport.jpg";

import Map from "../../../image/Map/isandPeakClimbingWithEBCTrekMap.jpg";
import Pdf from "../../../itineraryDocuments/EverestRegion/IslandPeakClimbingWithEBCTrek.pdf";

const Itinerary = () => {
  const days = [
    {
      day1: [
        {
          title: "Day 1: Arrival in Kathmandu",
          image: kathmandu,
          caption:
            "Our airport team will be at the Tribhuvan international airport terminal to pick you up. They will take you to the hotel and help you with check-in and luggage. After taking some rest, meet with core team members in our Adventure Master office. We introduce you to the trek leader and go through some final preparation. The evening is free. You can buy last-minute things for the journey and explore the bustling streets of Thamel.  ",
          tripDetail: [
            // {
            //   icon: "FaMountain",
            //   detailTitle: "Maximum Altitude",
            //   detailCaption: "1400m",
            // },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "Null",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Hotel",
            },
          ],
        },
      ],
      day2: [
        {
          title:
            "Day 2: Flight from Kathmandu to Lukla airport (2,846 m/9,337 ft) and trek to Phakding (2,610 m/8,563 ft)",
          image: lukla,
          caption:
            "We have an early morning flight to Lukla today. The flight will be either from Kathmandu airport or Manthali airport as per the air traffic during your journey. If the flight is from the Manthali airport, you have to wake up at 2:00 AM and drive to the airport. It takes around 5 hours to reach Manthali airport from Thamel. The Lukla flight is short and scenic. ",
          caption1:
            "From Lukla, we have a short walk ahead of us to Phakding village, where we'll be spending the night. The trail descends through lush forests, following the Dudh Koshi river. We walk via Cheplung village (2,660 m) and Thado Koshi Gaon (2,580 m). On the way, cross many suspension bridges and get to see views of Kusum Kanguru & Kongde Ri. ",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Trek Distance",
              detailCaption: "6.2 km/3.8 miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "35 minutes flight & 3 hours trek",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B,L,D",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Lodge",
            },
          ],
        },
      ],
      day3: [
        {
          title:
            "Day 3: Trek from Phakding to Namche Bazaar (3,440 m/11,290 ft)",
          image: day3,
          caption:
            "The trail from Phakding ascends to Toktok (2,760 m) via lush magnolia and rhododendron forests. We then walk past Bengkar village (2,630 m) and cross two suspension bridges before reaching Monjo (2,835 m). One of the bridges we cross is the long Hillary Bridge. Leaving Monjo, we register our permits at the police check post and continue walking through dense alpine woods. On the way, we will be crossing many hanging bridges and steeply ascend to Namche Bazaar to spend the night. ",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Trek Distance",
              detailCaption: "7.4 km/4.6 miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "6 hours",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B,L,D",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Lodge",
            },
          ],
        },
      ],
      day4: [
        {
          title:
            "Day 4: Acclimatization in Namche Bazaar: hike to Syangboche Airstrip (3,748 m/12,297 ft) and Everest View Hotel (3,962 m/13,000 ft)",
          image: day4,
          caption:
            "We have a rest day in Namche Bazaar. We’ll spend the day hiking to Hotel Everest View, which let us see the first glimpse of Mt. Everest along with other mountains like Nuptse, Lhotse, Thamserku, Kangtega, etc. Hotel Everest View is a luxury lodge and also used to hold the Guinness World record for the highest luxury hotel in the world.",
          caption1:
            "We also have an optional hike to Khumjung monastery. It is one of the oldest monasteries in the Khumbu region. After the hike, we’ll return to Namche Bazaar to spend the rest of the day chilling and exploring the village. ",
          tripDetail: [
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "4-5 hours",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B,L,D",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Lodge",
            },
          ],
        },
      ],
      day5: [
        {
          title:
            "Day 5: Trek from Namche Bazaar to Tengboche Monastery (3,860 m/12,660 ft)",
          image: day5,
          caption:
            "We trek to Tengboche village on this day. The trail from Namche Bazaar goes along the verdant foothills. We reach Kyangjuma (3,550 m) and continue walking on a winding uphill trail. Soon, we drop down to Phungi Thanga (3,250 m) and cross a bridge before trekking through lush forests to Tengboche village. We'll visit the biggest monastery in the Khumbu region in this village. The views of Everest, Ama Dablam, Lhotse, Nuptse, etc are surreal from Tengboche.",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Trek Distance",
              detailCaption: "9.2 km/5.7 miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "5 hours",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B,L,D",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Lodge",
            },
          ],
        },
      ],
      day6: [
        {
          title: "Day 6: Trek from Tengboche to Dingboche (4,410 m/14,470 ft)",
          image: day6,
          caption:
            "Wake up early if you want to catch a gorgeous sunrise with mesmerizing mountain views. The trail from Tengboche heads to Dingboche village, also known as the summer valley of the Khumbu region. Leaving Tengboche, we will trek on a downhill trail through dense rhododendron forests to Deboche (3,320 m). We may come across blue sheep, musk deer, yaks, and mules on the way.",
          caption1:
            "Wake up early if you want to catch a gorgeous sunrise with mesmerizing mountain views. The trail from Tengboche heads to Dingboche village, also known as the summer valley of the Khumbu region. Leaving Tengboche, we will trek on a downhill trail through dense rhododendron forests to Deboche (3,320 m). We may come across blue sheep, musk deer, yaks, and mules on the way.",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Trek Distance",
              detailCaption: "12 km/7.45 miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "5 hours",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B,L,D",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Lodge",
            },
          ],
        },
      ],
      day7: [
        {
          title:
            "Day 7: Acclimatization in Dingboche; hike to Nagarjuna Hill (5,100 m/16,732 ft)",
          image: day7,
          caption:
            "We have a rest day in Dingboche as well. It is important that we acclimatize before we begin the climbing part of our trip. Today, we’ll take our time to hike to Nagarjuna hill, which offers a breathtaking panorama of mountains like Lobuche, Nuptse, Cholatse, Ama Dablam, and Kangtega. The hike will be in the morning, and we'll have the remaining day free to explore & rest.",
          tripDetail: [
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "4-5 hours",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B,L,D",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Lodge",
            },
          ],
        },
      ],
      day8: [
        {
          title: "Day 8: Trek from Dingboche to Lobuche (4,940 m/16,210 ft)",
          image: day8,
          caption:
            "Leaving Dingboche, we trek along a steep uphill route to Dusa (4,503 m). It will be a long ascent, so we will take a short break here and continue the walk to Dughla (4,620 m). On the way, cross a bridge just before reaching Dughla. The trail then goes past tombstones and follows a steep route to Lobuche village. The landscape drastically changes from verdant greens to rocky browns & grays. The mountains are very close to us. We can feel their dominating presence on us.",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Trek Distance",
              detailCaption: "8.5 km/5.2 miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "5-6 hours",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B,L,D",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Lodge",
            },
          ],
        },
      ],
      day9: [
        {
          title:
            "DAY 9: Trek from Lobuche to Everest Base Camp (5,364 m/17,598 ft) via Gorak Shep (5,164 m/16,942 ft) and back to Gorak Shep for a night's stay",
          image: day9,
          caption:
            "Everest base camp is located at 5,364 meters, which also makes a good acclimatization hike before climbing the Island peak. Leaving Lobuche village, we walk on the rocky path along the Khumbu Glacier. Walking past an Italian Research Center called Pyramid (4,970 m), we ascend to Gorak Shep, the last settlement before Everest base camp.",
          caption1:
            "From Gorak Shep onwards, the trail goes through glacial moraines. We'll come across alpine streams that are half-frozen. The Everest base camp welcomes us with a huge sense of achievement and a stunning view of Khumbu Icefall. After spending some time enjoying the views, we'll trek back to Gorak Shep to spend the night.",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Trek Distance",
              detailCaption: "15 km/9.32 miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "8 hours",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B,L,D",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Accomodation: Rivendell Lodge",
            },
          ],
        },
      ],
      day10: [
        {
          title:
            "DAY 10: Hike to Kala Patthar (5,644 m/18,519 ft) early in the morning and trek down to Dingboche (4,410 m/14,470 ft)",
          image: day10,
          caption:
            "We will wake at the crack of the dawn and hike to Kala Patthar to witness a gorgeous sunrise over the Himalayan mountains. The trail ascends steeply to Kala Patthar. Standing at Kala Patthar's viewpoint feels like standing at the edge of the world, and you can only see giant mountains pointing out of the clouds. We’ll spend the latter half of the day walking back to Gorak Shep and retracing our path to Dingboche. We have a long day ahead of us today.",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Trek Distance",
              detailCaption: "11.1 km/6.8 miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "8-9 hours",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B,L,D",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Lodge",
            },
          ],
        },
      ],
      day11: [
        {
          title: "DAY 11: Trek to Chhukung (4,750 m/15,580 ft)",
          image: day11,
          caption:
            "We'll have a delicious breakfast this morning and pack our bags to finally join the path that leads to the Island peak. Enjoying striking views of the snow-capped mountains like Ama Dablam, Tawache, Cholatse, Cho Oyu, Island Peak, etc, we trek to Chhukung valley. The trail goes through stone-walled fields and enters glacier moraines. Passing by Bibre, we come across the valley of Ama Dablam and the Amphu Labtsa mountains. The next stop from here is Chhukung village.",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Trek Distance",
              detailCaption: "5 km/3.1 miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "3 hours",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B,L,D",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Lodge",
            },
          ],
        },
      ],
      day12: [
        {
          title: "DAY 12: Trek to Island Peak Base Camp (5,200 m/17,060 ft)",
          image: day12,
          caption:
            "The trekking part ends here, and from today onwards, we begin climbing Island peak gradually. Our first destination is the Island peak base camp. From Chhukung, we follow a steep and rocky trail to the base camp of Island peak, located at 5,200 meters. Walking past Imja river, we head to Lhotse Glacier. The views surrounding us on this route are phenomenal.",
          caption1:
            "We'll walk on zigzag trails that move alongside the riverbed and interesting rocky terrain. Once we reach the Island peak base camp, our crew will begin setting up the tents and prepare meals for us. We spend the remaining day resting and getting pre-climbing training from our team leader.",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Trek Distance",
              detailCaption: "5 km/3.1 miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "4-5 hours",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B,L,D",
            },
            {
              icon: "fa fa-campground",
              detailTitle: "Accommodation",
              detailCaption: "Camp",
            },
          ],
        },
      ],
      day13: [
        {
          title:
            "DAY 13: Climb Island Peak (6,189 m/20,305 ft) and return to Chhukung",
          image: day13,
          caption:
            "A big day of our adventure in the Himalayas. We'll summit the Island peak today. From the base camp, we gradually head towards the high camp. The climbing route is rocky and steep. Walking through narrow passages, we'll reach high camp. We'll take a short break here and climb up to the glaciers. The route ascends to the snout of the glacier following the ridge. The guides will install the safety ropes for the group.",
          caption1:
            "Further, climbing on steep slopes, we reach the summit of Island peak. The views of the surrounding giant mountains are out of this world. After enjoying the view for a few minutes, we'll begin descending back to the base camp, retracing our path. Descending is as exciting as ascending, so we'll have a good time.",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Trek Distance",
              detailCaption: "5 km/3.1 miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Duration",
              detailCaption: "9-10 hours",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "Breakfaast,lunch and dinner",
            },
            {
              icon: "fa fa-campground",
              detailTitle: "Accommodation",
              detailCaption: "Camp",
            },
          ],
        },
      ],
      day14: [
        {
          title: "Day 14: Buffer day",
          caption:
            "We have an extra day in case we need to acclimatize or we come across bad weather. It is hard to predict in the Himalayas, so having a buffer day gives us flexibility if things do not go as we plan. If we do not spend this day in the mountains, then you'll have a full day in Kathmandu to enjoy.",
        },
      ],
      day15: [
        {
          title: "Day 15: Trek back to Tengboche (3,860 m/12,660 ft)",
          caption:
            "The trail from Chhukung first descends to Dingboche village, following the same trekking route. You will then descend to Pangboche village and cross a bridge before joining the forested path to Tengboche village. The trail ascends via rhododendron forests and offers stunning mountain panorama on the way.",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Trek Distance",
              detailCaption: "13 km/8 miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "3 hours",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "Breakfast,lunch and dinner",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Lodge",
            },
          ],
        },
      ],
      day16: [
        {
          title: "Day 16: Trek to Monjo (2,835 m/9,301 ft)",
          caption:
            "After breakfast, we will begin descending along the hillsides to Monjo. We will be retracing the path today as well, but the mountain scenery along the way will seem new. Enjoying the peaceful trail, we trek back to Monjo. It is a short trek, so we'll have a lot of time in the village to rest and explore.",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Trek Distance",
              detailCaption: "8.3 km/5.1 miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Duration",
              detailCaption: "6 hours",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "Breakfaast,lunch and dinner",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Lodge",
            },
          ],
        },
      ],
      day17: [
        {
          title: "Day 17: Trek to Lukla (2,846 m/9,337 ft)",
          caption:
            "",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Trek Distance",
              detailCaption: "13.6 km/8.3 miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "5 hours",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "Breakfaast,lunch and dinner",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Lodge",
            },
          ],
        },
      ],
      day18: [
        {
          title:
            "Day 18: Morning flight back to Kathmandu (1,400 m/4,600 ft( from Lukla airport & farewell dinner in the late evening",
          caption:
            "Early in the morning, fly back to Kathmandu, relishing the magical aerial view of the Himalayas. Our team will escort you to the hotel. You have the entire day free. You can do whatever you want. Unpack, get a massage, go window shopping, and explore the local market. Later in the evening, we meet you over a farewell dinner, which marks the end of the trip with us.",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Trek Distance",
              detailCaption: "5 km/3.1 miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Flight Duration",
              detailCaption: "35 minutes flight",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "Breakfaast and farewell dinner",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Hotel",
            },
          ],
        },
      ],
      day19: [
        {
          title: "Day 19: Final departure",
          image: day19,
          caption:
            "It is time to fly back to your home. Our airport team will drop you at the Tribhuvan International Airport three hours before your flight departure time. We hope to travel with you again in the Himalayas, until then goodbye.",
        },
      ],
    },
  ];

  return (
    <>
      <div className="detail-itinerary container mt-5">
        <div className="row">
          <div className="d-lg-none mb-5">
            <h4 className="title">Trekking Route Map</h4>
            <img
              src={Map}
              alt="island peak climbing with ebc trek map"
              style={{ width: "100%" }}
            />
            <button type="button" className="btn btn-dark mt-3 col-12">
              <a href={Pdf} target="_blank" className="text-light">
                Download Itinerary
              </a>
            </button>
          </div>
          <div className="col-md-12 col-lg-8">
            {days &&
              days.map((day) => (
                <>
                  {day.day1 &&
                    day.day1.map((day1) => (
                      <>
                        <h4 className="itinerary-title">{day1.title}</h4>
                        <div className="itinerary-image">
                          <img src={day1.image} alt="kathmandu" />
                          <figcaption className="text-secondary">
                            Kathmandu (Capital city of Nepal)
                          </figcaption>
                        </div>
                        <div className="row">
                          {day1.tripDetail &&
                            day1.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-auto"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day1.caption}
                        </div>
                      </>
                    ))}

                  {day.day2 &&
                    day.day2.map((day2) => (
                      <>
                        <h4 className="itinerary-title">{day2.title}</h4>
                        <div className="itinerary-image">
                          <img src={day2.image} alt="lukla airport" />
                          <figcaption className="text-secondary">
                            Lukla Airport
                          </figcaption>
                        </div>
                        <div className="row">
                          {day2.tripDetail &&
                            day2.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-autp"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day2.caption}
                          <br />
                          {day2.caption1}
                        </div>
                      </>
                    ))}
                  {day.day3 &&
                    day.day3.map((day3) => (
                      <>
                        <h4 className="itinerary-title">{day3.title}</h4>
                        <div className="itinerary-image">
                          <img src={day3.image} alt="phakding" />
                          <figcaption className="text-secondary">
                            Phakding
                          </figcaption>
                        </div>
                        <div className="row">
                          {day3.tripDetail &&
                            day3.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-autp"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day3.caption}
                        </div>
                      </>
                    ))}
                  {day.day4 &&
                    day.day4.map((day4) => (
                      <>
                        <h4 className="itinerary-title">{day4.title}</h4>
                        <div className="itinerary-image">
                          <img src={day4.image} alt="namche bazaar" />
                          <figcaption className="text-secondary">
                            Namche Bazaar
                          </figcaption>
                        </div>
                        <div className="row">
                          {day4.tripDetail &&
                            day4.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-autp"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day4.caption}
                          <br />
                          {day4.caption1}
                        </div>
                      </>
                    ))}
                  {day.day5 &&
                    day.day5.map((day5) => (
                      <>
                        <h4 className="itinerary-title">{day5.title}</h4>
                        <div className="itinerary-image">
                          <img src={day5.image} alt="Tengboche monastery" />
                          <figcaption className="text-secondary">
                            Tengboche Monastery
                          </figcaption>
                        </div>
                        <div className="row">
                          {day5.tripDetail &&
                            day5.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-autp"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day5.caption}
                        </div>
                      </>
                    ))}
                  {day.day6 &&
                    day.day6.map((day6) => (
                      <>
                        <h4 className="itinerary-title">{day6.title}</h4>
                        <div className="itinerary-image">
                          <img src={day6.image} alt="dingboche" />
                          <figcaption className="text-secondary">
                            Dingboche
                          </figcaption>
                        </div>
                        <div className="row">
                          {day6.tripDetail &&
                            day6.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-autp"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day6.caption}
                          <br />
                          {day6.caption1}
                        </div>
                      </>
                    ))}
                  {day.day7 &&
                    day.day7.map((day7) => (
                      <>
                        <h4 className="itinerary-title">{day7.title}</h4>
                        <div className="itinerary-image">
                          <img src={day7.image} alt="lukla airport" />
                        </div>
                        <div className="row">
                          {day7.tripDetail &&
                            day7.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaBus" && (
                                        <FaBus fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-autp"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day7.caption}
                        </div>
                      </>
                    ))}
                  {day.day8 &&
                    day.day8.map((day8) => (
                      <>
                        <h4 className="itinerary-title">{day8.title}</h4>
                        <div className="itinerary-image">
                          <img src={day8.image} alt="lobuche" />
                          <figcaption className="text-secondary">
                            Lobuche
                          </figcaption>
                        </div>
                        <div className="row">
                          {day8.tripDetail &&
                            day8.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-autp"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day8.caption}
                        </div>
                      </>
                    ))}
                  {day.day9 &&
                    day.day9.map((day9) => (
                      <>
                        <h4 className="itinerary-title">{day9.title}</h4>
                        <div className="itinerary-image">
                          <img src={day9.image} alt="everest base camp" />
                          <figcaption className="text-secondary">
                            Everest Base Camp
                          </figcaption>
                        </div>
                        <div className="row">
                          {day9.tripDetail &&
                            day9.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-autp"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day9.caption}
                          <br />
                          {day9.caption1}
                        </div>
                      </>
                    ))}
                  {day.day10 &&
                    day.day10.map((day10) => (
                      <>
                        <h4 className="itinerary-title">{day10.title}</h4>
                        <div className="itinerary-image">
                          <img src={day10.image} alt="kalapatthar" />
                          <figcaption className="text-secondary">
                            Kala Patthar
                          </figcaption>
                        </div>
                        <div className="row">
                          {day10.tripDetail &&
                            day10.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-autp"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day10.caption}
                        </div>
                      </>
                    ))}
                  {day.day11 &&
                    day.day11.map((day11) => (
                      <>
                        <h4 className="itinerary-title">{day11.title}</h4>
                        <div className="itinerary-image">
                          <img src={day11.image} alt="chukkung" />
                          <figcaption className="text-secondary">
                            Chukkung
                          </figcaption>
                        </div>
                        <div className="row">
                          {day11.tripDetail &&
                            day11.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-autp"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day11.caption}
                        </div>
                      </>
                    ))}
                  {day.day12 &&
                    day.day12.map((day12) => (
                      <>
                        <h4 className="itinerary-title">{day12.title}</h4>
                        <div className="itinerary-image">
                          <img src={day12.image} alt="island peak base camp" />
                          <figcaption className="text-secondary">
                            Island Peak Base Camp
                          </figcaption>
                        </div>
                        <div className="row">
                          {day12.tripDetail &&
                            day12.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon ===
                                        "fa fa-campground" && (
                                        <FaCampground fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-autp"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day12.caption}
                          <br />
                          {day12.caption1}
                        </div>
                      </>
                    ))}
                  {day.day13 &&
                    day.day13.map((day13) => (
                      <>
                        <h4 className="itinerary-title">{day13.title}</h4>
                        <div className="itinerary-image">
                          <img src={day13.image} alt="island peak climbing" />
                          <figcaption className="text-secondary">
                            Island Peak Climbing
                          </figcaption>
                        </div>
                        <div className="row">
                          {day13.tripDetail &&
                            day13.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon ===
                                        "fa fa-campground" && (
                                        <FaCampground fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-autp"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day13.caption}
                          <br />
                          {day13.caption1}
                        </div>
                      </>
                    ))}
                  {day.day14 &&
                    day.day14.map((day14) => (
                      <>
                        <h4 className="itinerary-title">{day14.title}</h4>
                        <div className="row">
                          {day14.tripDetail &&
                            day14.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-autp"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day14.caption}
                        </div>
                      </>
                    ))}
                  {day.day15 &&
                    day.day15.map((day15) => (
                      <>
                        <h4 className="itinerary-title">{day15.title}</h4>
                        <div className="row">
                          {day15.tripDetail &&
                            day15.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-autp"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day15.caption}
                        </div>
                      </>
                    ))}
                  {day.day16 &&
                    day.day16.map((day16) => (
                      <>
                        <h4 className="itinerary-title">{day16.title}</h4>
                        <div className="row">
                          {day16.tripDetail &&
                            day16.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-autp"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day16.caption}
                        </div>
                      </>
                    ))}
                  {day.day17 &&
                    day.day17.map((day17) => (
                      <>
                        <h4 className="itinerary-title">{day17.title}</h4>
                        <div className="row">
                          {day17.tripDetail &&
                            day17.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-autp"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day17.caption}
                        </div>
                      </>
                    ))}
                  {day.day18 &&
                    day.day18.map((day18) => (
                      <>
                        <h4 className="itinerary-title">{day18.title}</h4>
                        <div className="row">
                          {day18.tripDetail &&
                            day18.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-autp"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day18.caption}
                        </div>
                      </>
                    ))}
                  {day.day19 &&
                    day.day19.map((day19) => (
                      <>
                        <h4 className="itinerary-title">{day19.title}</h4>
                        <div className="itinerary-image">
                          <img src={day19.image} alt="kathmandu airport" />
                          <figcaption className="text-secondary">
                            Kathmandu Tribhuwan International Aiport (TIA)
                          </figcaption>
                        </div>
                        <div className="row">
                          {day19.tripDetail &&
                            day19.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-autp"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day19.caption}
                        </div>
                      </>
                    ))}
                </>
              ))}
          </div>

          <div className="main-modal-map d-none d-lg-flex">
            <div className="modal-map d-flex flex-column">
              <h4 className="title">Trekking Route Map</h4>
              <img src={Map} alt="island peak climbing with ebc trek map" />
              <button type="button" className="btn btn-dark mt-3">
                <a href={Pdf} target="_blank" className="text-light">
                  Download Itinerary
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Itinerary;
