import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { FaCheckCircle } from "react-icons/fa";
import CancelIcon from "@mui/icons-material/Cancel";
import TripFact from "./TripFact";
import Gallery from "./Gallery";
import AdditionalTable from "./AdditionalTable";

export default function Overview() {
  return (
    <div className='overview'>
      <div className='container d-flex mt-5'>
        <div className='row'>
          {/* <div className="col-lg-9 col-sm-12"> */}
          <div className='col-12'>
            <TripFact />

            <Gallery />

            <h4 className='overview-title mt-4'>Outline Itinerary</h4>
            <ul className='p-0'>
              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 01:</strong>{" "}
                </div>
                <li>Arrival in Kathmandu</li>
              </div>

              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 02: </strong>
                </div>
                <li>
                  Flight from Kathmandu to Lukla airport (2,846 m/9,337 ft) and
                  trek to Phakding (2,610 m/8,563 ft)
                </li>
              </div>

              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 03:</strong>
                </div>
                <li>Trek from Phakding to Namche Bazaar (3,440 m/11,290 ft)</li>
              </div>

              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 04:</strong>
                </div>
                <li>
                  Acclimatization in Namche Bazaar: hike to Syangboche Airstrip
                  (3,748 m/12,297 ft) and Everest View Hotel (3,962 m/13,000 ft)
                </li>
              </div>

              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 05:</strong>
                </div>
                <li>
                  Trek from Namche Bazaar to Tengboche/Dibuche (3,860 m/12,660
                  ft)
                </li>
              </div>

              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 06:</strong>
                </div>
                <li>
                  Trek from Tengboche/Dibuche to Dingboche (4,410 m/14,470 ft)
                </li>
              </div>

              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 07:</strong>
                </div>
                <li>Trek from Dingboche to Lobuche (4,940 m/16,210 ft)</li>
              </div>

              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 08:</strong>
                </div>
                <li>
                  Trek from Lobuche to Everest Base Camp (5,364 m/17,598 ft) via
                  Gorak Shep (5,164 m/16,942 ft) and back to Gorak Shep for a
                  night's stay
                </li>
              </div>

              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 09:</strong>
                </div>
                <li>
                  Hike to Kala Patthar (5,644 m/18,519 ft) early in the morning
                  coming back to Gorak shep and take helicopter flight back to
                  Lukla and flight back to Kathmandu
                </li>
              </div>
            </ul>

            <h4 className='overview-title pt-5'>Trip Highlight</h4>
            <ul className='trip-highlight'>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  A well-led and safe trek to Mt. Everest's base camp, Everest
                  lap.
                </li>
              </span>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  An exciting journey through the Khumbu area of Sagarmatha
                  National Park.
                </li>
              </span>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Explore the eastern Himalayas of Nepal, which are home to
                  eight-thousand mountains, the legendary Sherpa people, and
                  beautiful river valleys.
                </li>
              </span>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  See beautiful waterfalls and streams and use hanging bridges
                  to cross the Dudh Koshi river many times.
                </li>
              </span>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Spend the night in beautiful mountain villages and get to know
                  how the locals live.
                </li>
              </span>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Walk through rhododendron and magnolia forests, then climb
                  above the tree line and walk along glaciers and moraines.
                </li>
              </span>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Hike to Kala Patthar and enjoy the beautiful sunrise and views
                  of the mountains.
                </li>
              </span>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  On the trail, you can always see snow-capped mountains, green
                  hills, and other different landscapes.
                </li>
              </span>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Take a thrilling helicopter ride out of the Khumbu area and
                  look out over the breathtaking Himalayan landscapes.
                </li>
              </span>
            </ul>

            <div className='row d-flex justify-content-between'>
              <div className='col-12'>
                <h4 className='overview-title pt-5'>Package Cost Include</h4>
                <ul className='cost-include'>
                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>
                      All ground travel, including getting to and from the
                      airport, is done in a private car.
                    </li>
                  </span>
                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>
                      Two nights accomodation at a standard hotel in kathmandu
                      including breakfast ( as per the itinerary)
                    </li>
                  </span>
                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>The flight from kathmandu to lukla- kathmandu</li>
                  </span>
                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>
                      During the trek, you will be provided with three meals a
                      day: breakfast, lunch, and dinner with one cup of tea each
                      meal
                    </li>
                  </span>
                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>
                      You will be provided with the best available lodge with
                      private or shared bathrooms in Phakding, Namche, and
                      Lukla, where two people can share a room.
                    </li>
                  </span>
                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>
                      Strong, helpful porters with the right safety gear and
                      walking gear, as well as a salary, food, a place to live,
                      and insurance. (one porter for two people).
                    </li>
                  </span>
                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>
                      A highly experienced, helpful, knowledgeable, friendly,
                      English-speaking guide with all of his salary, food,
                      drinks, lodging, transportation, and insurance covered.
                    </li>
                  </span>
                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>The helicopter flight from Gorak Shep to Lukla.</li>
                  </span>
                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>
                      A map of a trek and a certificate of completion of the
                      trip.
                    </li>
                  </span>
                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>
                      All papers that need to be done, Fees for entering permits
                      you need to go while hiking.
                    </li>
                  </span>
                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>Helicopter Insurance Covered.</li>
                  </span>
                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>Taxes and official costs paid to the government.</li>
                  </span>

                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>
                      First aid kit- Oxymeter to check your pulse, oxygen
                      saturation, and heart rate twice a day (very helpful to
                      check for signs of Altitude Mountain Sickness, or AMS).
                      This will make sure that your trek is in the safest hands
                      possible, no matter how experienced you are.
                    </li>
                  </span>
                </ul>
              </div>

              <div className='col-12'>
                <h4 className='overview-title pt-5'>Package Cost Exclude</h4>
                <ul className='cost-exclude'>
                  <span>
                    <div className='cost-exclude-icon'>
                      <CancelIcon />
                    </div>
                    <li>
                      Food that you will eat in Kathmandu for lunch and dinner.
                    </li>
                  </span>
                  <span>
                    <div className='cost-exclude-icon'>
                      <CancelIcon />
                    </div>
                    <li>
                      <p>
                        The cost of a visa to enter Nepal is
                        <br />
                        $30 USD for a 15-day stay,
                        <br />
                        30 Days - $50,
                        <br />
                        90 Days - $125
                      </p>
                    </li>
                  </span>
                  <span>
                    <div className='cost-exclude-icon'>
                      <CancelIcon />
                    </div>
                    <li>Travel insurance.</li>
                  </span>
                  <span>
                    <div className='cost-exclude-icon'>
                      <CancelIcon />
                    </div>
                    <li>Your insurance for travel and medical care.</li>
                  </span>
                  <span>
                    <div className='cost-exclude-icon'>
                      <CancelIcon />
                    </div>
                    <li>International Airfare.</li>
                  </span>
                  <span>
                    <div className='cost-exclude-icon'>
                      <CancelIcon />
                    </div>
                    <li>Your Personal Expenses.</li>
                  </span>
                  <span>
                    <div className='cost-exclude-icon'>
                      <CancelIcon />
                    </div>
                    <li>
                      All of the alcoholic and non-alcoholic cold and hot drinks
                      you buy on trek (along the way and in the Tea Houses at
                      night).
                    </li>
                  </span>
                  <span>
                    <div className='cost-exclude-icon'>
                      <CancelIcon />
                    </div>
                    <li>
                      All of the sweet desserts, like chocolate, cake, pie, and
                      pudding.
                    </li>
                  </span>
                  <span>
                    <div className='cost-exclude-icon'>
                      <CancelIcon />
                    </div>
                    <li>
                      The tea houses have hot showers and places to charge your
                      phone which will cost you about 2$ to 5$
                    </li>
                  </span>
                  <span>
                    <div className='cost-exclude-icon'>
                      <CancelIcon />
                    </div>
                    <li>
                      Tip to the guide, the porter, and the driver. (Tipping is
                      expected)
                    </li>
                  </span>
                </ul>
              </div>
            </div>

            <AdditionalTable />

            {/* <EverestHeliNotice className="pt-5 px-0" />
            <EverestTable className="pt-5 px-0" /> */}

            <h4 className='overview-title pt-5'>FAQ</h4>

            <div className='trip-overview-accordion'>
              <Accordion defaultActiveKey='0' flush>
                <Accordion.Item eventKey='0'>
                  <Accordion.Header>
                    <p class='accordion-title'>
                      1) Can helicopters get to the base camp of Mount Everest?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='overview-content'>
                      Yes, a helicopter can get to Everest base camp, but most
                      of the time they do not land there because there is not
                      enough space for them to do so
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='1'>
                  <Accordion.Header>
                    <p class='accordion-title'>
                      2) Is the trek to Everest Base Camp hard?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='accordion-content'>
                      Trekking to Everest Base Camp is a convenient trek.
                      Depending on how healthy you are and how fit you are, the
                      trek may get hard for some people. Everest base camp
                      trekking is possible if you can walk for 6 hours a day,
                      eat simple food, and deal with few modern conveniences.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='2'>
                  <Accordion.Header>
                    <p class='accordion-title'>
                      3) How much cash should I bring on the trek to Everest
                      Base Camp? trek?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='overview-content'>
                      Aside from the package price you pay to your travel
                      company, we recommend that you set aside USD 15 per day to
                      buy snacks and extra services. You will also need money
                      for things like travel insurance, international flights,
                      shopping, tips, etc.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='3'>
                  <Accordion.Header>
                    <p class='accordion-title'>
                      4) What is the best month to hike to Everest Base Camp?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='overview-content'>
                      The best months for the Everest base camp trek are March,
                      April, early May, October, November, and early December.
                      At the end of the trek, you can take a helicopter back.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='4'>
                  <Accordion.Header>
                    <p class='accordion-title'>
                      5) How far is it from Kathmandu to Everest Base Camp?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='overview-content'>
                      From Kathmandu, it is about 300 km to the Everest base
                      camp. You take a 35-minute flight to the Lukla airport and
                      then walk 9–11 km each day for 65 km to get to EBC.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='5'>
                  <Accordion.Header>
                    <p class='accordion-title'>
                      6) Is it worth it to trek to Everest Base Camp?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='overview-content'>
                      Yes, it is worth it to trek to Everest base camp. And
                      coming back from Kala Patthar by helicopter makes the trip
                      even more amazing. You can see the Himalayas from both the
                      ground and the air, live a simple life, and relax as you
                      go.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='6'>
                  <Accordion.Header>
                    <p className='accordion-title'>
                      7) Can I trek to Everest Base Camp by myself?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='overview-content'>
                      Yes, you can go on the trek to Everest Base Camp by
                      yourself. Solo trekkers and backpackers should know that
                      the Everest trekking route is very busy during peak
                      seasons, so book your accommodations early.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='7'>
                  <Accordion.Header>
                    <p class='accordion-title'>
                      8) Does Everest Base Camp have WiFi?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='overview-content'>
                      No, Everest base camp does not have WiFi. At the base
                      camp, you must use a Nepali phone network called Ncell sim
                      to connect.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='8'>
                  <Accordion.Header>
                    <p class='accordion-title'>
                      9) Is Everest Base Camp trek for beginners?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='overview-content'>
                      Yes, the trek to Everest Base Camp is thought to be fine
                      for first-timers. But as a first-time trekker, you need to
                      be ready mentally and physically to enjoy the trek. EBC
                      trek is beginner-friendly but demanding. To get to the
                      base camp, you must push yourself and be determined.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='9'>
                  <Accordion.Header>
                    <p class='accordion-title'>
                      10) Is alcohol allowed on Everest?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='overview-content'>
                      During the climbing phase, you should not drink alcohol
                      because it will make your body lose water and make you
                      more likely to get altitude sickness. But if you are going
                      down and do not have any big climbs coming up soon, you
                      can drink alcohol. Try some Nepalese alcoholic drinks.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='10'>
                  <Accordion.Header>
                    <p class='accordion-title'>
                      11) How do you avoid getting sick from the high altitude
                      at Everest Base Camp?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='overview-content'>
                      To avoid altitude sickness, you must climb slowly and not
                      gain more than 500 meters a day. If you start to feel
                      sick, take the medicine and get some rest. If your
                      symptoms do not get better, go to a lower altitude. do not
                      smoke or drink alcohol. Eat well, stay hydrated, and get
                      some rest.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='11'>
                  <Accordion.Header>
                    <p class='accordion-title'>
                      12) What is the oxygen level at Everest Base Camp?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='overview-content'>
                      At the Everest base camp, the oxygen level is between 85%
                      and 87%. At sea level, the amount of oxygen is between 98%
                      and 99%.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
          {/* sidebar price
          <div className="col-lg-3 col-sm-12">
            <div className="overview-sidebar bg-light">
              <span className="badge text-wrap">Top Seller</span>
              <div className="">
                <span className="overview-sidebar-days">14 Days</span>
                <br />
                <span className="overview-sidebar-destination">
                  Kathmandu to Kathmandu
                </span>
                <br />
                <h4 className="price-text my-3">From USD $N/A</h4>
                <div className="group-discount mb-3">
                  <table className="group-discount-table">
                    <p>Group Discount</p>
                    <tr>
                      <td>Pax</td>
                      <td>Price</td>
                    </tr>
                    <tr>
                      <th>1 Pax</th>
                      <th>$N/A</th>
                    </tr>
                    <tr>
                      <th>2 Pax</th>
                      <th>$N/A</th>
                    </tr>
                    <tr>
                      <th>3 Pax</th>
                      <th>$N/A</th>
                    </tr>
                    <tr>
                      <th>4-6 Pax</th>
                      <th>$N/A</th>
                    </tr>
                    <tr>
                      <th>7-10 Pax</th>
                      <th>$N/A</th>
                    </tr>
                    <tr>
                      <th>11-18 Pax</th>
                      <th>$N/A</th>
                    </tr>
                    <tr>
                      <th>19-24 Pax</th>
                      <th>$N/A</th>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="">
                <HashLink smooth to="/booking" className="link">
                  <button className="book-btn">Book Now</button>
                </HashLink>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
