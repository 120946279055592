import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import CancelIcon from "@mui/icons-material/Cancel";

const TripDetail = () => {
  return (
    <>
      <div className='trip-detail container mt-5'>
        <div className='row'>
          <div className='col-2 d-none d-lg-block d-xl-block'>
            <div className='trip-sidebar'>
              <ul>
                <li>
                  <a href='#price'>Price Detail</a>
                </li>
                <li>
                  <a href='#highlight'>Highlight</a>
                </li>
                <li>
                  <a href='#include'>Cost Include</a>
                </li>
                <li>
                  <a href='#exclude'>Cost Exclude</a>
                </li>
                <li>
                  <a href='#disclaimer'>Disclaimer of Itinerary</a>
                </li>
                <li>
                  <a href='#important-note'>Important Note</a>
                </li>
                <li>
                  <a href='#safety'>Safety and Security</a>
                </li>
                <li>
                  <a href='#covid'>Covid-19 2022/2023 Travel Update</a>
                </li>
                <li>
                  <a href='#insurance'>Travel Insurance</a>
                </li>
                <li>
                  <a href='#age'>Minimum Age</a>
                </li>
                <li>
                  <a href='#time'>Best Time</a>
                </li>
                <li>
                  <a href='#tipping'>Tipping</a>
                </li>
                <li>
                  <a href='#activities'>Activities You Can Choose</a>
                </li>
                <li>
                  <a href='#group-leader'>Group Leader - What They Do</a>
                </li>
                <li>
                  <a href='#group-notes'>Size of Group Notes</a>
                </li>
                <li>
                  <a href='#transport'>Transport</a>
                </li>
                <li>
                  <a href='#local-flight'>Local Flight</a>
                </li>
                <li>
                  <a href='#solo-traveler'>Solo Travelers</a>
                </li>
                <li>
                  <a href='#joining'>Joining Instructions</a>
                </li>
                <li>
                  <a href='#arrival'>Arrival Complications</a>
                </li>
                <li>
                  <a href='#emergency-contact'>Emergency Contanct</a>
                </li>
                <li>
                  <a href='#visa'>Visa and Entry Requirement</a>
                </li>
                <li>
                  <a href='#money-exchange'>Money Exchange</a>
                </li>
                <li>
                  <a href='#emergency-fund'>Emergency Fund</a>
                </li>
              </ul>
            </div>
          </div>

          <div className='trip-info col-md-12 col-lg-10'>
            {/* ---------------- price starts --------------------- */}
            <h4 id='price' className='trip-detail-title'>
              Price Detail
            </h4>
            Trip Price: USD $1390 pp
            <h4 className='trip-detail-title'>Group Discount</h4>
            Upto 5 people --------- USD $1245
            <br />
            upto 10 people ---- USD $1120
            <br />
            {/* ---------------- trip highlight starts --------------------- */}
            <h4 id='highlight' className='trip-detail-title'>
              Highlights
            </h4>
            <ul className='trip-highlight'>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Explore the Manaslu Conservation Area, a protected region in
                  Nepal that is subject to a number of restrictions and
                  regulations.
                </li>
              </span>

              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  In Nepal's Himalayas, embark on the ultimate trek that is off
                  the beaten path.
                </li>
              </span>

              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Enjoy the scenery as you travel through a variety of climate
                  zones and set locations.
                </li>
              </span>

              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Travel across a number of rivers via exhilarating suspension
                  bridges, and take a stroll along the Budhi Gandaki Gorge.
                </li>
              </span>

              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  A route with fewer hikers that passes through picturesque
                  mountain towns at each layover.
                </li>
              </span>

              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  A chance to learn about Tibetan Buddhism and stop at some
                  historic monasteries along the way.
                </li>
              </span>

              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Take in stunning panoramas of the western Himalayas, complete
                  with glaciers, open meadows, waterfalls, and lakes, among
                  other natural features.
                </li>
              </span>

              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>Hike to Manaslu Base Camp or Birendra Lake.</li>
              </span>

              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Larkya La is a high mountain pass that must be traversed.
                </li>
              </span>
            </ul>
            {/*---------------------- cost include starts --------------------- */}
            <h4 id='include' className='trip-detail-title'>
              Cost Include
            </h4>
            <ul className='cost-include'>
              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  All ground travel, including getting to and from the airport,
                  is done in a private car.
                </li>
              </span>

              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  During the trek, you will be provided with three meals a day:
                  breakfast, lunch, and dinner with one cup of tea each meal.
                </li>
              </span>

              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  You will be provided with the best available lodge with
                  private or shared bathrooms.
                </li>
              </span>

              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  A highly experienced, helpful, knowledgeable, friendly,
                  English-speaking guide with all of his salary, food, drinks,
                  lodging, transportation, and insurance covered.
                </li>
              </span>

              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Strong, helpful porters with the right safety gear and walking
                  gear, as well as a salary, food, a place to live, and
                  insurance. (one porter for two people).
                </li>
              </span>

              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  A map of a trek and a certificate of completion of the trip.
                </li>
              </span>

              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>Taxes and official costs paid to the government.</li>
              </span>

              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  A map of a trek and a certificate of completion of the trip.
                </li>
              </span>

              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  All papers that need to be done, Fees for entering permits you
                  need to go while hiking.
                </li>
              </span>

              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>Duffle bag for trekking.</li>
              </span>

              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  First aid kit- Oxymeter to check your pulse, oxygen
                  saturation, and heart rate twice a day (very helpful to check
                  for signs of Altitude Mountain Sickness, or AMS). This will
                  make sure that your trek is in the safest hands possible, no
                  matter how experienced you are.
                </li>
              </span>
            </ul>
            {/*---------------------- cost exclude starts --------------------- */}
            <h4 id='exclude' className='trip-detail-title'>
              Cost Exclude
            </h4>
            <ul className='cost-exclude'>
              <span>
                <div className='cost-exclude-icon'>
                  <CancelIcon />
                </div>
                <li>
                  Food that you will eat in Kathmandu for lunch and dinner.
                </li>
              </span>

              <span>
                <div className='cost-exclude-icon'>
                  <CancelIcon />
                </div>
                <li>
                  <p>
                    The cost of a visa to enter Nepal is
                    <br />
                    $30 USD for a 15-day stay,
                    <br />
                    $50 USD for 30 Days,
                    <br />
                    90 Days - $125
                  </p>
                </li>
              </span>

              <span>
                <div className='cost-exclude-icon'>
                  <CancelIcon />
                </div>
                <li>Your insurance for travel and medical care.</li>
              </span>

              <span>
                <div className='cost-exclude-icon'>
                  <CancelIcon />
                </div>
                <li>Internamtional airfare.</li>
              </span>

              <span>
                <div className='cost-exclude-icon'>
                  <CancelIcon />
                </div>
                <li>Your personal expenses.</li>
              </span>

              <span>
                <div className='cost-exclude-icon'>
                  <CancelIcon />
                </div>
                <li>
                  All of the alcoholic and non-alcoholic cold and hot drinks you
                  buy on trek (along the way and in the Tea Houses at night)
                </li>
              </span>

              <span>
                <div className='cost-exclude-icon'>
                  <CancelIcon />
                </div>
                <li>
                  All of the sweet desserts, like chocolate, cake, pie, and
                  pudding.
                </li>
              </span>

              <span>
                <div className='cost-exclude-icon'>
                  <CancelIcon />
                </div>
                <li>
                  The tea houses have hot showers and places to charge your
                  phone which will cost you about 2$ to 5$.
                </li>
              </span>

              <span>
                <div className='cost-exclude-icon'>
                  <CancelIcon />
                </div>
                <li>
                  Tip to the guide, the porter, and the driver. (Tipping is
                  expected).
                </li>
              </span>
            </ul>
            {/*---------------------- Disclaimer of Itinerary starts --------------------- */}
            <h4 id='disclaimer' className='trip-detail-title'>
              Disclaimer of Itinerary
            </h4>
            <p className='trip-detail-content'>
              We'll stick to the Manaslu circuit trek plan unless something
              unexpected and out of our control gets in the way. The Manaslu
              region is one of the few places in the Himalayas that hasn't been
              touched by people. The weather is hard to predict, and the land is
              rough. There may be things that force us to change the plan (which
              rarely happens). In that kind of situation, the team leader will
              make the right choice to keep everyone safe.
            </p>
            {/*---------------------- important notes starts --------------------- */}
            <h4 id='important-note' className='trip-detail-title'>
              Important Notes
            </h4>
            <p className='trip-detail-content'>
              These are some of the little things you might not think about when
              getting ready for a big trip like this, but they will help you a
              lot to have a safe and smooth trekking trip.
            </p>
            <ul>
              <li>
                Manaslu is a restricted area, so you can't hike there alone or
                with a guide you find on your own. Besides that, the area is
                very remote and doesn't have that many services for travelers.
                Because of this, the trek that the company plans is much more
                comfortable and easy.
              </li>
              <li>
                On the way, you might see yaks and mules. Always stay on the
                safe side of the cliff.
              </li>
              <li>
                During the trek, drink a lot of water to stay hydrated and avoid
                altitude sickness.
              </li>
              <li>
                The Manaslu circuit trail isn't as well-used as some of the
                Himalayas' other popular trekking routes. So, bring everything
                you need and don't count on buying things along the way.
              </li>
              <li>
                Make sure your trekking boots are walkable. The only thing new
                boots will do is give you blisters and make it hard to walk.
              </li>
              <li>
                You can add on to the Manaslu circuit trek by going to Tsum
                valley, a hidden valley with beautiful monasteries and a rich
                Tibetan Buddhist cultural history.
              </li>
            </ul>
            {/*---------------------- safety and security starts --------------------- */}
            <h4 id='safety' className='trip-detail-title'>
              Safety and Security
            </h4>
            <p className='trip-detail-content'>
              At Adventure Master, the safety of our guests comes first. We make
              sure the trip is exciting, but not at the expense of our guests'
              safety and security. When there is a problem, everyone on the
              field must follow a strict set of rules. The trip will be safe
              because our team members have been trained to lead groups and
              individuals in the Himalayas.
            </p>
            <p className='trip-detail-content'>
              You will spend the night in Kathmandu at a three-star hotel that
              will meet all of your needs. During the trek, we make reservations
              at the best tea houses in the villages along the trail where we
              will spend the night. We work closely with the locals, so you can
              be sure that your overnight stays during the trek will be safe.
              Since our guides are friendly, you can tell them if something is
              bothering you.
            </p>
            <p className='trip-detail-content'>
              You won't have any trouble getting food and water. In the same
              way, the team leader also has a satellite phone in case of an
              emergency. We stay in touch with the group, and the team leader
              keeps us up to date. Our experienced team makes sure that your
              trip with us in the Himalayas goes smoothly.
            </p>
            {/*---------------------- covid 19 info starts --------------------- */}
            <h4 id='covid' className='trip-detail-title'>
              Covid-19 Nepal Travel News for 2022 and 2023
            </h4>
            <p className='trip-detail-content'>
              If Covid-19 is making you nervous, we want you to know that
              everything is fine. There aren't many cases going on at the
              moment. Most people have had a second shot of vaccines or a
              booster shot. Even when the big crisis was going on, there were
              only a few or no active cases in the Himalayas.
            </p>
            {/*---------------------- travel insurance starts --------------------- */}
            <h4 id='insurance' className='trip-detail-title'>
              Travel Insurance
            </h4>
            <p className='trip-detail-content'>
              For the Manaslu Circuit trek, you need to have travel insurance.
              It is a long hike in a remote area, and there is a chance of
              getting altitude sickness. There are no proper health care
              facilities in the area, so if the trekker needs medical help
              beyond first aid, we can only fly them to a hospital in Kathmandu
              or Pokhara. When it comes to building basic infrastructure, the
              Manaslu region of Nepal has not been touched. So, the Manaslu
              circuit trail does not have any places to get medical help.
            </p>
            {/*---------------------- minimum age starts --------------------- */}
            <h4 id='age' className='trip-detail-title'>
              Minimum Age
            </h4>
            <p className='trip-detail-content'>
              The oldest you can be to trek the Manaslu circuit is 18. If you
              are younger than 18 (up to age 12), you can join our trek as long
              as you have a responsible adult with you. If you want to trek in
              the Manaslu area with a toddler, you should first find out how
              hard the trail is and figure out if your child can handle the
              limits of the trip. Then, you can make a reservation. Contact us
              if you want to know anything about trekking for young people.
            </p>
            {/*---------------------- best time starts --------------------- */}
            <h4 id='time' className='trip-detail-title'>
              Best Time
            </h4>
            <p className='trip-detail-content'>
              The best time to go trekking is in the spring, between March and
              April, or in the fall, between October and November. During these
              times of the year, the weather is perfect for a pleasant hiking
              trip. You'll walk through dense forests, beautiful valleys, and
              clear mountain scenery. The weather won't be too cold or too hot,
              so there's no need to bring too much. Do bring a few warm things,
              because it's cold in the mornings and at night.
            </p>
            <p className='trip-detail-content'>
              Trekking on the Manaslu Circuit is not possible in the winter,
              summer, or rainy season. During these two times, we don't organize
              treks in the Manaslu area. Landslides happen on the trail, and the
              teahouses close. If you want to hike when it's not the peak
              season, do it at the beginning or end of the season. You have a
              good chance of making it through the trek without too many
              problems.
            </p>
            {/*---------------------- tipping starts --------------------- */}
            <h4 id='tipping' className='trip-detail-title'>
              Tipping
            </h4>
            <p className='trip-detail-content'>
              Tips are common and the best way to show appreciation for the team
              on the field (driver, tour guide, trek guide & porters). They work
              hard, and we pay them the best we can, no matter who they are. But
              a tip at the end of the trip, no matter how small, is the best way
              to say "thank you." So, we don't include tips in the price of our
              Manaslu Circuit trek, and travelers can give whatever they want as
              a tip.
            </p>
            <p className='trip-detail-content'>
              You can tip as much as you want, or you can not tip at all if the
              service wasn't good. In general, each member of the group pays USD
              2 for the driver, USD 5 for the tour guide, and USD 100 (shared
              with porters) for the trek guide. It's best to tip as a group
              because the cost per person is less.
            </p>
            {/*---------------------- Activities You Can Choose starts --------------------- */}
            <h4 id='activities' className='trip-detail-title'>
              Activities You Can Choose
            </h4>
            <p className='trip-detail-content'>
              We organize treks in other restricted areas of Nepal, like Upper
              Mustang Trek, Upper Dolo Trek, Kanchenjunga Circuit Trek, and
              Makalu Base Camp Trek, which are just as exciting and rewarding as
              Manaslu. Few people go on this trek because it costs a lot to get
              a permit and there aren't many good facilities. Because of this,
              they miss the best views. So, the trail is less busy and hasn't
              been messed up.
            </p>
            <p className='trip-detail-content'>
              Also, these adventurous trekking packages are outside of the
              restricted area: Everest Three Passes Trek, Gokyo Lake Trek,
              Langtang Gosaikunda Trek, Annapurna Circuit Trek, etc. If you are
              looking for short trekking packages, check out the Ghorepani Poon
              Hill trek, Langtang valley trek, Mardi Himal trek, and Khopra
              Danda trek.
            </p>
            <p className='trip-detail-content'>
              In Kathmandu, Pokhara, Chitwan, and Lumbini, we also offer day
              hikes and city tours. In the same way, you can customize the
              itinerary for the Manaslu Circuit trek with us. Get in touch with
              us so that we can make the package fit your needs.
            </p>
            {/*---------------------- Activities You Can Choose starts --------------------- */}
            <h4 id='group-leader' className='trip-detail-title'>
              Group Leader - What They Do
            </h4>
            <p className='trip-detail-content'>
              Our group leaders have worked in the Himalayas for many years and
              are the best there. They are well-trained people from the
              Himalayas who work hard for their communities and families. They
              will keep you safe in the mountains and become your reliable
              friend.
            </p>
            <p className='trip-detail-content'>
              They have been trained in different ways to survive in the wild
              and other things, so they know how to handle any problem they
              might face in the Himalayas. They will not only show you the best
              way, but they will also help you connect with the locals. He'll
              help you mingle with the locals and share interesting stories
              about the region.
            </p>
            {/*---------------------- Size of Group Notes starts --------------------- */}
            <h4 id='group-notes' className='trip-detail-title'>
              Size of Group Notes
            </h4>
            <p className='trip-detail-content'>
              Most of the time, our group size is between 2 and 12 people. If
              there are more than 8 people in the group, there will also be an
              assistant guide. We also organize trips for groups that have more
              than 12 people. If you are a solo trekker, you can join one of our
              fixed departure dates. You can trek with people from all over the
              world.
            </p>
            <p className='trip-detail-content'>
              Whether you are a solo traveler or in a small group, you can join
              our private trek. A guide and porter (as per the size of the
              group) will accompany you. According to the rules, to do the
              Manaslu circuit, there have to be two trekkers in the group, and
              you cannot count a guide or porter in it.
            </p>
            {/*---------------------- transport starts --------------------- */}
            <h4 id='transport' className='trip-detail-title'>
              Transport
            </h4>
            <p className='trip-detail-content'>
              We use private vehicles for the city tour (if any) and pickup/drop
              at the airport. We use a private jeep or a local jeep to get from
              the beginning of the trek to the end. All ground transportation is
              provided as per the itinerary. If you need to hire a car or a
              two-wheeler, let us know. We will help you get one at the best
              price.
            </p>
            {/*---------------------- local flights starts --------------------- */}
            <h4 id='transport' className='trip-detail-title'>
              Local Flights
            </h4>
            <p className='trip-detail-content'>
              There is no domestic flight involved in Manaslu Circuit trekking.
              We use four-wheelers for all of our ground transportation.
            </p>
            {/*---------------------- solo travelers starts --------------------- */}
            <h4 id='solo-traveler' className='trip-detail-title'>
              Solor Travelers
            </h4>
            <p className='trip-detail-content'>
              Solo travelers are welcome to join our group departure or
              participate in private trekking (for private trekking must be two
              participants) (for private trekking must be two participants). Our
              group departure lets solo travelers trek in the Manaslu region
              because there will be other trekkers too in the group to abide by
              the trekking rules of the Manalsu region.
            </p>
            {/*---------------------- joining instructions starts --------------------- */}
            <h4 id='joining' className='trip-detail-title'>
              Joining Instructions
            </h4>
            <p className='trip-detail-content'>
              Join the Manaslu Circuit trek for 15 days via our package page.
              You can send a booking inquiry and pay the package cost using our
              safe payment gateway. Once we get the booking request, our travel
              consultant will get in touch with you and take care of the rest.
              Joining a trek with us is easy. You need to send us some documents
              and some information (passport, travel insurance, etc). Once the
              team gets all the necessary paperwork and payment for the package,
              we'll send you a trip confirmation email and give you a travel
              consultant who will help you get ready for the trek. The booking
              is open for the Manaslu Circuit trek 2023/2024 peak seasons. Feel
              free to contact us if you need any assistance.
            </p>
            {/*---------------------- arrival complications starts --------------------- */}
            <h4 id='arrival' className='trip-detail-title'>
              Arrival Complications
            </h4>
            <p className='trip-detail-content'>
              If there are problems with your arrival and you cannot make it to
              Kathmandu on time, call our emergency number and let our team know
              what's going on so we can (if possible) change the trip. Also, if
              you don't see one of our people at the airport, you can call us or
              take a taxi to the hotel address. There is free WiFi at the
              airport. You can easily get in touch with us. Circumstances like
              these are rare, so you do not have to worry about anything.
            </p>
            {/*---------------------- emergency contact starts --------------------- */}
            <h4 id='emergency-contact' className='trip-detail-title'>
              Emergency Contact
            </h4>
            <p className='trip-detail-content'>
              You can reach out to us at +977 9851033195, +44 7983806200 and
              +977 9849780311. These are the numbers to call in case of an
              emergency. On weekdays (Sunday through Friday), the office is open
              from 9am to 5pm, so you can call us at +977 9851033195, +44
              7983806200, or +977 9849780311 during those times.
            </p>
            <p className='trip-detail-content'>
              These are the numbers for our Whatsapp and Viber accounts: +977
              9851033195, +44 7983806200, and +977 9849780311. Or, if you need
              help, send us an email at advmt2009@gmail.com.
            </p>
            {/*---------------------- visa and entry requirements starts --------------------- */}
            <h4 id='visa' className='trip-detail-title'>
              How to get a visa and get in
            </h4>
            <p className='trip-detail-content'>
              You can apply for a Nepali visa at the Nepalese Embassy or
              Consulate Office in your home country. Check the visa status for
              your country. Nepal allows on-arrival visas at all of its borders
              for travelers from many countries. You can apply for a visa upon
              landing at Tribhuvan International Airport or land borders. The
              process for getting a visa is simple and takes only a few minutes.
            </p>
            <p className='trip-detail-content'>
              Adventure Master does not take any responsibility for the visa. We
              can send you a letter confirming your trip, which you can use to
              get a visa. If you fail to obtain the visa, we cannot do anything.
              You can choose to put the trip off. If you cancel the trip, you'll
              be charged a cancellation fee as per our terms & conditions.
            </p>
            {/*---------------------- money exchange starts --------------------- */}
            <h4 id='money-exchange' className='trip-detail-title'>
              Exchange Money
            </h4>
            <p className='trip-detail-content'>
              You can change your money in Thamel at a Nepalese bank or a money
              exchange. Most shops, restaurants, and hotels in tourist cities
              like Kathmandu, Pokhara, Chitwan, and Lumbini accept
              Credit/Master/Visa cards. Some places may accept foreign currency
              too.
            </p>
            <p className='trip-detail-content'>
              In the mountains (while trekking), you cannot use cards or foreign
              currency. So, withdraw Nepali cash or exchange your foreign
              currency. Most ATMs in Kathmandu allow you to use your cards to
              get Nepali cash.
            </p>
            {/*---------------------- emergency fund starts --------------------- */}
            <h4 id='emergency-fund' className='trip-detail-title'>
              Emergency Fund
            </h4>
            <p className='trip-detail-content'>
              Make some extra budget to use in an emergency. You can keep USD
              2000 to USD 5000 aside as an emergency fund. You may have to deal
              with taxes, unseen charges, or additional stays in Kathmandu. If
              not used, the money will be saved, and you can use it in your home
              country.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default TripDetail;
