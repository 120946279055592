import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { FaCheckCircle } from "react-icons/fa";
import CancelIcon from "@mui/icons-material/Cancel";
import TripFact from "./TripFact";

import EverestHeliNotice from "../../components/EverestHeliNotice";
import EverestTable from "./EverestTable";
import Gallery from "./Gallery";

import { FaMapMarkerAlt } from "react-icons/fa";

export default function Overview() {
  return (
    <div className="overview">
      <div className="container d-flex mt-5">
        <div className="row">
          {/* <div className="col-lg-9 col-sm-12"> */}
          <div className="col-12">
            <TripFact />

            <Gallery />

            <h4 className="overview-title mt-4">Outline Itinerary</h4>
            <ul className="p-0">
              <div className="overview-outline-itinerary d-flex align-items-center">
                <div className="col-auto overview-outline-itinerary-days">
                 <strong>Day 01:</strong>
                </div>
                <li>Arrival in Kathmandu</li>
              </div>

              <div className="overview-outline-itinerary">
                <div className="col-auto overview-outline-itinerary-days">
                   <strong>Day 02: </strong>
                </div>
                <li>
                Flight from Kathmandu to Lukla airport (2,846 m/9,337 ft) and trek to Phakding (2,610 m/8,563 ft)
                </li>
              </div>

              <div className="overview-outline-itinerary">
                <div className="col-auto overview-outline-itinerary-days">
                   <strong>Day 03:</strong>
                </div>
                <li>Trek from Phakding to Namche Bazaar (3,440 m/11,290 ft)</li>
              </div>

              <div className="overview-outline-itinerary">
                <div className="col-auto overview-outline-itinerary-days">
                   <strong>Day 04:</strong>
                </div>
                <li>
                Acclimatization in Namche Bazaar: hike to Syangboche Airstrip (3,748 m/12,297 ft) and Everest View Hotel (3,962 m/13,000 ft)
                </li>
              </div>

              <div className="overview-outline-itinerary">
                <div className="col-auto overview-outline-itinerary-days">
                   <strong>Day 05:</strong>
                </div>
                <li>
                Trek from Namche Bazaar to Tengboche Monastery (3,860 m/12,660 ft)
              </li>
              </div>

              <div className="overview-outline-itinerary">
                <div className="col-auto overview-outline-itinerary-days">
                   <strong>Day 06:</strong>
                </div>
                <li>Trek from Tengboche to Dingboche (4,410 m/14,470 ft)</li>
              </div>

              <div className="overview-outline-itinerary">
                <div className="col-auto overview-outline-itinerary-days">
                   <strong>Day 07:</strong>
                </div>
                <li>
                Acclimatization in Dingboche; hike to Nagarjuna Hill (5,100 m/16,732 ft)
                </li>
              </div>

              <div className="overview-outline-itinerary">
                <div className="col-auto overview-outline-itinerary-days">
                  {" "}
                   <strong>Day 08:</strong>
                </div>
                <li>Trek from Dingboche to Lobuche (4,940 m/16,210 ft)</li>
              </div>

              <div className="overview-outline-itinerary">
                <div className="col-auto overview-outline-itinerary-days">
                   <strong>Day 09:</strong>
                </div>
                <li>
                Trek from Lobuche to Everest Base Camp (5,364 m/17,598 ft) via Gorak Shep (5,164 m/16,942 ft) and back to Gorak Shep for a night's stay
                </li>
              </div>

              <div className="overview-outline-itinerary">
                <div className="col-auto overview-outline-itinerary-days">
                   <strong>Day 10:</strong>
                </div>
                <li>
                Hike to Kala Patthar (5,644 m/18,519 ft) early in the morning and trek down to Pheriche (4,371 m/14,340 ft)
                </li>
              </div>

              <div className="overview-outline-itinerary">
                <div className="col-auto overview-outline-itinerary-days">
                   <strong>Day 11:</strong>
                </div>
                <li>Trek from Pheriche to Namche Bazaar (3,440 m/11,290 ft)</li>
              </div>

              <div className="overview-outline-itinerary">
                <div className="col-auto overview-outline-itinerary-days">
                   <strong>Day 12:</strong>
                </div>
                <li>Trek Namche Bazaar to Lukla (2,846 m/9,337 ft)</li>
              </div>

              <div className="overview-outline-itinerary">
                <div className="col-auto overview-outline-itinerary-days">
                  {" "}
                   <strong>Day 13:</strong>
                </div>
                <li>
                Morning flight back to Kathmandu (1,400 m/4,600 ft (from Lukla airport & farewell dinner in the late evening
                </li>
              </div>

              <div className="overview-outline-itinerary">
                <div className="col-auto overview-outline-itinerary-days">
                   <strong>Day 14:</strong>
                </div>
                <li>Final departure</li>
              </div>
            </ul>

            <h4 className="overview-title pt-5">Trip Highlight</h4>
            <ul className="trip-highlight">
              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>
                You have to trek to the base of Mount Everest, the world’s highest mountain.
                </li>
              </span>
              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>The exciting trip from Kathmandu to Lukla.</li>
              </span>
              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>
                Explore the Khumbu region to see its beautiful mountain villages, waterfalls, green forests,   rivers, and glaciers.
                </li>
              </span>
              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>Discover the Sherpas and their way of life.</li>
              </span>
              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>Stop along the way to see old monasteries.</li>
              </span>
              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>Relax and take in the beautiful scenery.</li>
              </span>
              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>From Kala Patthar, you can see a beautiful sunrise.</li>
              </span>
              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>
                Amazing views of the Himalayas all along the trail.
                </li>
              </span>
            </ul>

            <div className="row d-flex justify-content-between">
              <div className="col-12">
                <h4 className="overview-title pt-5">Package Cost Include</h4>
                <ul className="cost-include">
                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>
                    Private car service to and from the airport.
                    </li>
                  </span>
                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>
                    Two nights at a standard hotel in Kathmandu with breakfast for two people sharing a room.
                    </li>
                  </span>
                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>
                    You will get all three meals, including tea: breakfast, lunch, and dinner.
                    </li>
                  </span>
                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>
                    During the trek, you will be provided with three meals a day: breakfast, lunch, and dinner with one cup of tea. 
                    </li>
                  </span>
                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>
                    During the trek, you will be provided with Trekking Lodges (Tea Houses).
                    </li>
                  </span>
                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>
                    All papers that need to be done, Fees for entering Sagarmatha National Park and Khumbu Pasang Lhamu Rural Municipality
                    </li>
                  </span>
                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>Flight from Kathmandu to Lukla and back to Kathmandu, with a private airport transfer and airport tax within Nepal.</li>
                  </span>
                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>A highly experienced, helpful, knowledgeable, friendly, English-speaking guide with all of his salary, food, drinks, lodging, transportation, and insurance covered.</li>
                  </span>
                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>Strong, helpful porters with the right safety gear and walking gear, as well as a salary, food, a place to live, and insurance. (one porter for two people).</li>
                  </span>
                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>Setting up a service for an emergency helicopter (paid by your Travel Insurance company).</li>
                  </span>
                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>You can use a sleeping bag, a down jacket, and a duffel bag (if you don't have your own).</li>
                  </span>
                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>Taxes and official costs paid to the government.</li>
                  </span>
                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>Everest base camp trekking map.</li>
                  </span>
                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>A map of a trek and a certificate of completion of the trip</li>
                  </span>
                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>Oxymeter to check your pulse, oxygen saturation, and heart rate twice a day (very helpful to check for signs of Altitude Mountain Sickness, or AMS). This will make sure that your trek is in the safest hands possible, no matter how experienced you are.</li>
                  </span>
                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>Assistant guide group size above 10+ people.</li>
                  </span>
                </ul>
              </div>
              <div className="col-12">
                <h4 className="overview-title pt-5">Package Cost Exclude</h4>
                <ul className="cost-exclude"><span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>Food that you will eat in Kathmandu for lunch and dinner.</li>
                  </span>
                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>The cost of a visa to enter Nepal is $30 USD for a 15-day stay.</li>
                  </span>
                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>Your insurance for travel and medical care.</li>
                  </span>
                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>International Airfare.</li>
                  </span>
                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>Your Personal Expenses.</li>
                  </span>
                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>
                    All of the alcoholic and non-alcoholic cold and hot drinks you buy on trek (along the way and in the Tea Houses at night). 
                    </li>
                  </span>
                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>All of the sweet desserts, like chocolate, cake, pie, and pudding.</li>
                  </span>
                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>The tea houses have hot showers and places to charge your phone which will cost you about 2$ to 5$</li>
                  </span>
                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>Tip  to the guide, the porter, and the driver. (Tipping is expected)</li>
                  </span>
                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>More than 15 kg of extra luggage for the flight to Lukla.</li>
                  </span>
                </ul>
              </div>
            </div>

            <EverestHeliNotice className="pt-5 px-0" />
            <EverestTable className="pt-5 px-0" />

            <h4 className="overview-title pt-5">FAQ</h4>

            <div className="trip-overview-accordion">
              <Accordion defaultActiveKey="0" flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <p class="accordion-title">
                      1) How hard is the trek to Everest Base Camp?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class="overview-content">
                      Everest base camp trekking is a moderate to difficult
                      trek. The trail does not have technical climbing, and any
                      fit & healthy individual can do this trek. You have to be
                      well prepared both physically and mentally for the EBC
                      trek.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <p class="accordion-title">
                      2) Can a beginner do Everest Base Camp Trek?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class="accordion-content">
                      Yes, a beginner can do Mount Everest base camp trek. The
                      trail can get a bit challenging for beginner trekkers,
                      however if you can walk 6 hours a day on average, there
                      will be no issue.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    <p class="accordion-title">
                      3) Is Everest Base Camp Trek worth it?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class="overview-content">
                      Yes, the Everest base camp trek is worth it, and we'll
                      always be worth doing. The trek offers a lifetime
                      experience, a stunning mountain panorama, and a serene
                      time away from the chaos of modern society.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    <p class="accordion-title">4) Is Everest Base Camp safe?</p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class="overview-content">
                      Yes, Everest base camp trekking is safe. You get
                      accompanied by an experienced guide, and backed up by a
                      professional team who makes the journey very safe.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    <p class="accordion-title">
                      5) Can you take a helicopter to Everest Base Camp?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class="overview-content">
                      Yes, you can take a helicopter to the Everest base camp.
                      However, there can be a problem with landing because of
                      limited space. From Kathmandu, it takes 40 to 45 minutes
                      to reach Lukla and another 15 minutes to EBC. You can land
                      at Kala Patthar, though.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header>
                    <p class="accordion-title">
                      6) How is Everest Base Camp Trek prepared?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class="overview-content">
                      To prepare for the Everest base camp trek, you can do
                      cardio and strength training. You have to focus on
                      building long walking hours (6 hours in a day with a 5kgs
                      backpack) and stamina. Go to the gym or do activities like
                      swimming, hiking, cycling, running, etc.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                  <Accordion.Header>
                    <p className="accordion-title">
                      7) Is Everest Base Camp Trek crowded?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class="overview-content">
                      Yes, in the peak trekking seasons (spring & autumn), the
                      Everest base camp trail is pretty crowded. Even though
                      that trail is crowded, you will get ample tranquil time to
                      enjoy views and unwind.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                  <Accordion.Header>
                    <p class="accordion-title">
                      8) Can you fly a drone at Everest Base Camp?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class="overview-content">
                      You can only fly a drone at the Everest base camp if
                      permitted by the government. The Everest air space is
                      quite busy with helicopter flights in the peak season and
                      may affect the regular flight if the drone is used. So,
                      you have to get permission before using it.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
          {/* sidebar price
          <div className="col-lg-3 col-sm-12">
            <div className="overview-sidebar bg-light">
              <span className="badge text-wrap">Top Seller</span>
              <div className="">
                <span className="overview-sidebar-days">14 Days</span>
                <br />
                <span className="overview-sidebar-destination">
                  Kathmandu to Kathmandu
                </span>
                <br />
                <h4 className="price-text my-3">From USD $N/A</h4>
                <div className="group-discount mb-3">
                  <table className="group-discount-table">
                    <p>Group Discount</p>
                    <tr>
                      <td>Pax</td>
                      <td>Price</td>
                    </tr>
                    <tr>
                      <th>1 Pax</th>
                      <th>$N/A</th>
                    </tr>
                    <tr>
                      <th>2 Pax</th>
                      <th>$N/A</th>
                    </tr>
                    <tr>
                      <th>3 Pax</th>
                      <th>$N/A</th>
                    </tr>
                    <tr>
                      <th>4-6 Pax</th>
                      <th>$N/A</th>
                    </tr>
                    <tr>
                      <th>7-10 Pax</th>
                      <th>$N/A</th>
                    </tr>
                    <tr>
                      <th>11-18 Pax</th>
                      <th>$N/A</th>
                    </tr>
                    <tr>
                      <th>19-24 Pax</th>
                      <th>$N/A</th>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="">
                <HashLink smooth to="/booking" className="link">
                  <button className="book-btn">Book Now</button>
                </HashLink>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
