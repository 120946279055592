import React, { useState } from "react";
import {
  AiOutlineArrowUp,
  AiOutlineArrowDown,
  AiOutlineCloseCircle,
} from "react-icons/ai";
import { HashLink } from "react-router-hash-link";

const Modals = (props) => {
  const { tripTitle, sendmodaldetails } = props;
  const { id, modalhead, modaldetail } = sendmodaldetails;
  const [expandModal, setExpandModal] = useState(false);

  const modaldetailtrip = [
    {
      id: 1,
      modaldetail: [
        // {
        //   modaltitle: "Annapurna Region:",
        //   modaltext:
        //     "Annapurna region is the western part of Nepal. The region houses the seventh, eighth, and tenth highest mountains in the world; Dhaulagiri, Manaslu & Annapurna. The region is protected under the Annapurna Conservation Area. In the north of the main Himalayan ranges (Annapurna & Dhaulagiri) is the trans-Himalayan area and boasts arid landscape & Tibetan Buddhism cultural heritage. Annapurna region is famous for its adventurous trekking routes.",
        // },
        // {
        //   modaltitle: "Annapurna Region:",
        //   modaltext:
        //     "Annapurna region is the western part of Nepal. The region houses the seventh, eighth, and tenth highest mountains in the world; Dhaulagiri, Manaslu & Annapurna. The region is protected under the Annapurna Conservation Area. In the north of the main Himalayan ranges (Annapurna & Dhaulagiri) is the trans-Himalayan area and boasts arid landscape & Tibetan Buddhism cultural heritage. Annapurna region is famous for its adventurous trekking routes.",
        // },
      ],
    },
    {
      id: 2,
      modaldetail: [
        {
          modaltitle: "Difficulty Level:",
          modaltext:
            "You wake up early, eat breakfast, and then go out with a guide to see the historical sites. To get to the monuments, you don't have to climb anything except a few stairs.",
        },
        {
          modaltitle: "Terrain:",
          modaltext:
            "Most of the terrain will be flat and smooth, but there will be a few rough spots.",
        },
        {
          modaltitle: "Altitude Sickness:",
          modaltext:
            "No risk of altitude sickness during the Buddhist pilgrimage tour, as the itinerary does not see any drastic elevation change. ",
        },
        {
          modaltitle: "Team:",
          modaltext:
            "We work closely with local groups to make sure that tours and treks in Nepal go smoothly and without any problems. Our team will make sure that you have a place to stay during the tour and that you have the right permits to get into the monuments. You only have to worry about having fun on the way. We'll take care of everything behind the scenes.",
        },
        {
          modaltitle: "Support:",
          modaltext:
            "We make sure you have a place to stay and food to eat, get the necessary permits, and help you with little things like getting a SIM card, getting picked up and dropped off at the airport, etc. Our guides not only show people the way, but they also help bring locals and tourists together.",
        },
        {
          modaltitle: "Packing:",
          modaltext:
            "You will need to carry the clothes as per the season you are visiting Nepal. Do pack a couple of warm pieces to wear if you are doing the tour in spring or autumn. Our team will give you a packing list once the booking gets confirmed. ",
        },
      ],
    },
    {
      id: 3,
      modaldetail: [
        {
          modaltitle: "Facilities:",
          modaltext:
            "In Kathmandu and Lumbini, you will stay in three-star hotels. All of the modern conveniences will be in the hotels. We'll make sure you stay in the safest and most comfortable hotels. It will be a bed and breakfast with free wifi, hot showers, TV, air conditioning, and other amenities. You will have to pay extra for any extra room service, food, or drinks.",
        },
        {
          modaltitle: "Single room",
          modaltext:
            "Most of the time, two people share a room. If you want a single room, tell our travel agent when you make the reservation. Prices for single rooms are a bit high. Because of this, the price of the package may change.",
        },
        {
          modaltitle: "Hot water:",
          modaltext:
            "You can take a free hot shower at the hotel any time of day or night. It will not give you any trouble.",
        },
        {
          modaltitle: "Charging:",
          modaltext:
            "You can plug your devices into charging ports in the rooms. Nepal has three types of plugs: C, D, and M. In the same way, the voltage is 230 volts and the frequency is 50 Hz. Based on what you've learned above, buy an adaptor for your devices.",
        },
        {
          modaltitle: "WiFi:",
          modaltext:
            "Hotel WiFi is free. In the same way, cafes and restaurants will also give you free wifi. We recommend getting a Nepali SIM so you can use the internet on your way to work or while visiting historical sites. Cellular data is cheap and can be used when there is no WiFi.",
        },
      ],
    },
    {
      id: 4,
      modaldetail: [
        {
          modaltitle: "Food:",
          modaltext:
            "There are hundreds of restaurants in Kathmandu that serve food from all over the world. You can get food from China, Korea, Japan, and Thailand, all the way to the Middle East. There are also places where you can eat Italian, French, or Mexican food. If you want something in particular, you can look it up on Google and see where the best ones are.",
        },
        {
          modaltitle: "Nepali Flavors:",
          modaltext:
            "All restaurants that serve more than one type of food have Nepali dishes like Khaja sets, Thakali sets, and snacks like momos, sadheko peanuts, sukuti, choila, sekuwa, tass, etc. on their menus. You can go to places that serve food from Nepal. You can eat at Newari restaurants, Thakali restaurants, and so on.",
        },
        {
          modaltitle: "The Best Food",
          modaltext:
            "Nepalese people love to eat dal bhat. It has a lot of different sides. Dal is soup made from lentils, and bhat is steamed rice. Nepalese people eat it almost every day. You can choose between vegetarian and nonvegetarian options. There are pickles, yogurt, curries, salad, stir-fried vegetables, sweets, and more in the set.",
        },
        {
          modaltitle: "Suitability:",
          modaltext:
            "There are many options for vegetarians, vegans, and people who can't eat gluten.",
        },
      ],
    },
    {
      id: 5,
      // modaldetail: [
      //   {
      //     modaltitle: "Annapurna Region:",
      //     modaltext:
      //       "Annapurna region is the western part of Nepal. The region houses the seventh, eighth, and tenth highest mountains in the world; Dhaulagiri, Manaslu & Annapurna. The region is protected under the Annapurna Conservation Area. In the north of the main Himalayan ranges (Annapurna & Dhaulagiri) is the trans-Himalayan area and boasts arid landscape & Tibetan Buddhism cultural heritage. Annapurna region is famous for its adventurous trekking routes.",
      //   },
      //   {
      //     modaltitle: "Difficulty Level:",
      //     modaltext:
      //       "The difficulty level of the Annapurna base camp trek from Kathmandu/Pokhara is marked moderate. As the trek progresses and you touch high elevation, other factors like altitude, isolation of the region, etc add up to the difficulty. Overall the journey is moderate to difficult because you spend a long time in the mountains.",
      //   },
      //   {
      //     modaltitle: "Terrain:",
      //     modaltext:
      //       "The terrain is diverse. You begin walking through subtropical forests. Some part of the forested trail is dense and damp. There are many rocky staircases you walk on. Likewise, walking alongside foothills, you'll ascend and descend throughout the trek. The latter part of the trail involves meadows and an alpine zone. You walk on steep, rocky paths.",
      //   },
      //   {
      //     modaltitle: "Altitude Sickness:",
      //     modaltext:
      //       "The Annapurna base camp trek involves altitude sickness risks. Do not take altitude sickness lightly because, in the worst case, we have to evacuate you to a hospital in Pokhara or Kathmandu. The chances of altitude sickness during the trek get low if you follow the precautions. Our crew will make sure you are not being careless and doing things that may trigger altitude sickness while trekking.",
      //   },
      //   {
      //     modaltitle: "Team:",
      //     modaltext:
      //       "Our on-field team is very experienced and knowledgeable about the Annapurna base camp trek route. They are trained to guide travelers in the Himalayas and can tackle any emergency situation. All of our team members are vaccinated for Covid-19. We make sure they are healthy before embarking on any treks.",
      //   },
      //   {
      //     modaltitle: "Support:",
      //     modaltext:
      //       "We give full support for the Annapurna base camp trek in Nepal. We take care of your accommodation and meals, arrange the permits, and help you with little things like getting a sim card, airport pickup/drop, etc. Likewise, during the trek, you trek with a reliable and experienced trekking guide and porters. Our guides not only show the trail, but also bridge the gap between locals and tourists. ",
      //   },
      //   {
      //     modaltitle: "Equipment:",
      //     modaltext:
      //       "The Annapurna base camp trek does not need any advanced climbing gear. We'll provide you with a trekking packing list that you can follow. You can buy most of the trekking clothes and gear in Thamel, Kathmandu. Therefore, no heavy luggage. Moreover, there are also renting options available, so you do not have to spend lots of money if you are not an active trekker.",
      //   },
      // ],
    },
    {
      id: 6,
      modaldetail: [
        {
          modaltitle: "Overview:",
          modaltext:
            "Our Nepal Buddhist pilgrimage tour is one of the best Buddhist tour packages in Nepal. It takes you to many religious sites. Given the locations of some of the monuments you will see on the tour, this is a great plan that includes both religious and natural sights. Gautam Buddha was born in Lumbini, in the plains of Nepal, in 563 B.C., which is when Buddhism started in Nepal. From there on, many saints and wise people meditated in the Terai, hills, and mountains all over Nepal. Over the years, many monasteries and stupas have been built. And today, Nepal is the center of Buddhism, the place where it all started. Enjoy the guided tour to different landmarks, learn about Buddhism, watch monks meditating, relax, and get in touch with your inner self.",
        },

        {
          modaltitle: "ATM/Currency:",
          modaltext:
            "There are ATMs all over Lumbini and Kathmandu. You can take money out of an ATM with your international card. There may be a service fee added to your card, though. In most places, you can also pay with a card. But if you go to the local market, you should bring Nepali cash because you might not be able to use your card there.",
        },
        {
          modaltitle: "Internet:",
          modaltext:
            "Every hotel, restaurant, and coffee shop will have free WiFi. No matter where you are, the cell phone network works well. As you drive from one city to another, you might see some strange things. If you want, our team will help you get a Nepali SIM.",
        },
        {
          modaltitle: "Locals:",
          modaltext:
            "The people who live there are very helpful and kind. Everywhere you go, they will welcome you with open arms. During the tour, you will learn about how people live and what their culture is like.",
        },
        {
          modaltitle: "Language",
          modaltext:
            "Nepal's official language is Nepali. People all over the country speak it. Aside from that, people in the area speak their own native languages. English is used well all over the country. During the tour, we will give you a guide who can speak your language, so you will have any trouble talking to him. Learn some common Nepali phrases to get a better feel for the culture. ",
        },
        {
          modaltext:
          "During the Buddhist cultural tour, you will go to a lot of monasteries and stupas. Some of them are the Swayambhunath temple, the Boudhanath Stupa, the Kopan monastery, Namobuddha, the Maya Devi Temple, the Thai monastery, and the Korean temple. In the same way, the tour also stops at museums, ponds, gardens, and other places.",
        },

        {
          modaltitle: "Monasteries:",
          modaltext:
            "You'll visit numerous monasteries and stupas during the Buddhist cultural tour. Some of them are Swayambhunath temple, Boudhanath Stupa, Kopan monastery, Namobuddha, Maya Devi Temple, Thai monastery, Korean temple, etc. Likewise, the tour also involves museums, ponds, gardens, etc. ",
        },
      ],
    },
  ];
  const expandHandler = () => {
    setExpandModal(!expandModal);
  };

  const collapseHandler = () => {
    setExpandModal(false);
  };

  const onTripChange = () => {
    sessionStorage.removeItem("tripTitle");
    sessionStorage.setItem("tripTitle", tripTitle);
  };

  return (
    <>
      <div className={`modals ${expandModal ? "expand" : ""}`}>
        <div className={`modal-head `} onClick={expandHandler}>
          <div className="modal-head-title">
            {!expandModal ? (
              <AiOutlineArrowUp fontSize="2rem" className="px-1" />
            ) : (
              <AiOutlineArrowDown fontSize="2rem" className="px-1" />
            )}
            <div className="head-title">{modalhead}</div>
          </div>
          <div className="iclose">
            {expandModal && (
              <AiOutlineCloseCircle
                fontSize="1.3rem"
                onClick={collapseHandler}
              />
            )}
          </div>
        </div>
        <div className={`modal-body ${expandModal ? "modal-body-expand" : ""}`}>
          {!expandModal ? (
            <>
              {modaldetail &&
                modaldetail?.map((detail) => (
                  <div className="modal-content">
                    <div className="modal-title d-none d-sm-flex">
                      {detail.modaltitle}
                    </div>
                    <div className="modal-text">{detail.modaltext}</div>
                    <div className="modal-text">{detail.modaltext1}</div>
                  </div>
                ))}
            </>
          ) : (
            <>
              {modaldetailtrip &&
                modaldetailtrip[id - 1]?.modaldetail?.map((detail) => (
                  <div className="modal-content">
                    <div className="modal-title fw-bold">
                      {detail.modaltitle}
                    </div>
                    <div className="modal-text">{detail.modaltext}</div>
                  </div>
                ))}
            </>
          )}
        </div>
        <HashLink
          smooth
          to="/booking"
          className="modal-book link"
          style={{ color: "#000" }}
          onClick={onTripChange}
        >
          BOOK NOW
        </HashLink>
      </div>
    </>
  );
};

export default Modals;
