import { useState } from "react";
import { HashLink } from "react-router-hash-link";
import Itinerary from "./Itinerary";
import TripInfo from "./TripInfo";
import TripDetail from "./TripDetail";
import Overview from "./Overview";
import Footer from "../../components/Footer";
import { Link } from "react-router-dom";

import AnnapurnaCircuitTilicholuxuryIcon from "../../components/Luxurypackage/AnnapurnaCircuitTilicholuxuryIcon"

import Button from "@mui/material/Button";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import AnnapurnaCircuitDepartureDateModal from "../../components/DepartureDateModal/AnnapurnaCircuitDepartureDateModal";
import ScrollToTop from "../../components/ScrollToTop";

const Everest = () => {
  const [overview, setOverView] = useState(false);
  const [itinerary, setItinerary] = useState(false);
  const [tripDetail, setTripDetail] = useState(false);
  const [tripInfo, setTripInfo] = useState(true);
  const overviewHandler = () => {
    setOverView(true);
    setItinerary(false);
    setTripDetail(false);
    setTripInfo(false);
  };

  const itineraryHandler = () => {
    setOverView(false);
    setItinerary(true);
    setTripDetail(false);
    setTripInfo(false);
  };

  const tripDetailHandler = () => {
    setOverView(false);
    setItinerary(false);
    setTripDetail(true);
    setTripInfo(false);
  };

  const tripInfolHandler = () => {
    setOverView(false);
    setItinerary(false);
    setTripDetail(false);
    setTripInfo(true);
  };
  // console.log("setOverView", overview);

  const onTripChange = () => {
    sessionStorage.removeItem("tripTitle");
    sessionStorage.setItem(
      "tripTitle",
      "Anapurna Circuit Trek with Tilicho Lake and Poon Hill - 17 Days");
    sessionStorage.setItem("tripPrice", "$570 pp (Budget Price)");
  };

  const toolTipText = `Includes, local flights, accomodation in Kathmandu and mountain, meals (B,L,D), guide, porter, permite, transportation (pickup and drop), trekking map, duffle bag`;


  return (
    <>
      <ScrollToTop position="header" />
      <div className="trip" id="top">
        <div className="trip-hero annapurna-circuit-tilicho-lake-trek">
          <div className="trip-box">
            <div className="trip-detail">
              <p className="main-title">
                Annapurna Circuit Trek
                <br /> With Tilicho Lake & Poon Hill
              </p>
              <div className="activity d-flex justify-content-center align-items-center">
                <div className="luxury-package-info">
                  <p className="text-center text-light ms-2">Luxury package </p>
                </div>
                <AnnapurnaCircuitTilicholuxuryIcon />
              </div>

              <div className="activity">
                <p>
                  Activity: <span className="trip-modal-title">Trekking</span>
                </p>
                <p>
                  Luxury Price:
                  {/* <del className="text-warning">$1499</del> */}
                  <span className="trip-modal-title">  $1260 pp</span>
                </p>
                <p>
                  Budget Price:
                  {/* <del className="text-warning">$1499</del> */}
                  <span className="trip-modal-title">  $570 pp</span>
                </p>
              </div>
              <AnnapurnaCircuitDepartureDateModal />
              <p className="view-trip">
                <HashLink
                  smooth
                  to="/booking"
                  className="link"
                  onClick={onTripChange}
                >
                  <div className="hover-underline-animation-black">
                    Book Now
                  </div>
                </HashLink>
              </p>
            </div>
          </div>
        </div>
        <div>
          <div className="trip-navbar d-flex" id="body">
            <p className={tripInfo ? "active" : ""}>
              <Link
                onClick={tripInfolHandler}
                to="/annapurna-circuit-with-tilicho-lake-and-poon-hill-17-days/info"
                className="link"
              >
                INFO
              </Link>
            </p>
            <span> &#x2022; </span>
            <p className={overview ? "active" : ""}>
              <Link
                onClick={overviewHandler}
                to="/annapurna-circuit-with-tilicho-lake-and-poon-hill-17-days/overview"
                className="link"
              >
                OVERVIEW
              </Link>
            </p>
            <span> &#x2022; </span>

            <p className={itinerary ? "active" : ""}>
              <Link
                onClick={itineraryHandler}
                to="/annapurna-circuit-with-tilicho-lake-and-poon-hill-17-days/itinerary"
                className="link"
              >
                ITINERARY
              </Link>
            </p>
            <span> &#x2022; </span>

            <p className={tripDetail ? "active" : ""}>
              <Link
                onClick={tripDetailHandler}
                to="/annapurna-circuit-with-tilicho-lake-and-poon-hill-17-days/detail"
                className="link"
              >
                DETAIL
              </Link>
            </p>
            <span> &#x2022; </span>

            <p className={tripDetail ? "active" : ""}>
              <Link
                onClick={tripDetailHandler}
                to="/booking"
                className="link"
              >
                BOOK NOW
              </Link>
            </p>
          </div>
          {overview && <Overview />}
          {tripInfo && <TripInfo />}
          {itinerary && <Itinerary />}
          {tripDetail && <TripDetail />}
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Everest;
