import { useInView } from "react-intersection-observer";
import Modals from "./Modals";

import meraPeakClimbing from "../../../image/MeraPeakClimbing/meraPeakClimbing.jpg";
import meraPeakClimbing1 from "../../../image/MeraPeakClimbing/meraPeakClimbing1.jpg";
import meraPeakClimbing2 from "../../../image/MeraPeakClimbing/meraPeakClimbing2.jpg";
import meraPeakClimbing3 from "../../../image/MeraPeakClimbing/meraPeakClimbing3.jpg";
import meraPeakClimbing4 from "../../../image/MeraPeakClimbing/meraPeakClimbing4.jpg";
import meraPeakClimbing5 from "../../../image/MeraPeakClimbing/meraPeakClimbing5.jpg";

const Overview = () => {
  const tripTitle = "Mera Peak Climbing - 18 Days";
  const tripPrice = "$2650 pp";
  const modaltrip = [
    {
      id: 1,
      modalhead: "Trip Overview",
      modaldetail: [
        {
          modaltitle: "Difficulty Level",
          modaltext: <strong>Strenuous (Alpine PD)</strong>,
        },
        {
          modaltitle: "Maximum Altitude",
          modaltext: <strong>6,476 m/21,247 ft</strong>,
        },
        {
          modaltitle: "Best Season",
          modaltext: <strong>Spring & Autumn</strong>,
        },
      ],
      trip: "Mera Peak Climbing",
    },
    {
      id: 2,
      modalhead: "The Difficulty",
      modaldetail: [
        {
          modaltitle: "Difficult to strenuous",
          modaltext: <strong>Strenuous (Alpine PD)</strong>,
        },
        {
          modaltitle: "Maximum Altitude",
          modaltext: <strong> 6,476 m/21,247 ft</strong>,
        },
      ],
      trip: "Mera Peak Climbing",
    },
    {
      id: 3,
      modalhead: "Accommodations",
      modaldetail: [
        {
          modaltitle: "In Kathmandu",
          modaltext: <strong>Three-star hotel</strong>,
        },
        {
          modaltitle: "Accommodation on the trail",
          modaltext: <strong>Lodge</strong>,
        },
        {
          modaltitle: "During climbing",
          modaltext: <strong>Alpine tents</strong>,
        },
        {
          modaltitle: "Style",
          modaltext: <strong>Shared</strong>,
        },
      ],
      trip: "Mera Peak Climbing",
    },
    {
      id: 4,
      modalhead: "Nutrition",
      modaldetail: [
        {
          modaltitle: "Meals in Kathmandu",
          modaltext: <strong>All breakfasts & welcome/farewell dinners</strong>,
        },
        {
          modaltitle: "Meals during the trek",
          modaltext: (
            <strong>Three meals a day (breakfast, lunch & dinner)</strong>
          ),
        },
        {
          modaltitle: "Cuisine",
          modaltext: <strong>Nepali, Tibetan, Continental & Indian</strong>,
        },
      ],
      trip: "Mera Peak Climbing",
    },
    {
      id: 5,
      modalhead: "Regeneration",
      modaldetail: [
        {
          modaltitle: "Morning stretching",
          modaltext:
            "Practice yoga & meditation by yourself (Or upgrade to yoga trekking, led by a professional yoga instructor)",
        },
        {
          modaltitle: "",
          modaltext: (
            <strong>
              Learn to live in the moment, fight with your demons, and come back
              as a new individual
            </strong>
          ),
        },
      ],
      trip: "Mera Peak Climbing",
    },
    {
      id: 6,
      modalhead: "Destination",
      modaldetail: [
        {
          modaltitle: "Duration",
          modaltext: <strong>18 Days</strong>,
        },
        {
          modaltitle: "Location",
          modaltext: <strong>Khumbu Region, Nepal</strong>,
        },
        {
          modaltitle: "Province",
          modaltext: "1",
        },
        {
          modaltitle: "Population",
          modaltext: <strong> 9,133+</strong>,
        },
        {
          modaltitle: "Wildlife Species",
          modaltext: <strong>995+ (Makalu Barun National Park)</strong>,
        },
        {
          modaltitle: "Highest Points",
          modaltext: <strong>Mera Peak (6,476 m/21,247 ft)</strong>,
        },
      ],
      trip: "Mera Peak Climbing",
    },
  ];
  let sendmodaldetails = {};

  const { ref, inView } = useInView();
  const { ref: ref2, inView: inView2 } = useInView();
  const { ref: ref3, inView: inView3 } = useInView();
  const { ref: ref4, inView: inView4 } = useInView();
  const { ref: ref5, inView: inView5 } = useInView();
  const { ref: ref6, inView: inView6 } = useInView();

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll, true);
  // }, [handleScroll]);
  if (inView6 === true) {
    sendmodaldetails = modaltrip[5];
  } else if (inView5 === true) {
    sendmodaldetails = modaltrip[4];
  } else if (inView4 === true) {
    sendmodaldetails = modaltrip[3];
  } else if (inView3 === true) {
    sendmodaldetails = modaltrip[2];
  } else if (inView2 === true) {
    sendmodaldetails = modaltrip[1];
  } else if (inView === true) {
    sendmodaldetails = modaltrip[0];
  } else {
    sendmodaldetails = modaltrip[5];
  }
  return (
    <>
      <div ref={ref} className="trip-details-next container mt-5">
        <div className="trip-fact col-10 flex-sm-wrap mb-3">
          <div className="facts">
            <div className="fact mb-3">
              <h1>MERA PEAK CLIMBING - 18 DAYS</h1>
            </div>
          </div>
        </div>
        <div ref={ref} className="big-title col-sm-12 col-md-7 mb-3">
          REACH THE SUMMIT OF THE HIGHEST TREKKING PEAK IN NEPAL, MERA, ON THIS
          18-DAY ADVENTUROUS VENTURE IN THE HIMALAYAS OF NEPAL.
        </div>
        <div ref={ref} className="title-content col-sm-12 col-md-7 mb-3">
          Mera peak offers one of the most straightforward climbing routes in
          the Himalayas of Nepal, making it perfect for beginner climbers.
          You'll be mostly walking on thick snow slopes, following the footsteps
          of your team leader to the summit. Mera peak climbing lets the
          climbers explore a very unpopular part of the Khumbu region (Everest
          region). A serene offbeat trekking route through lush rhododendron
          forests and remote villages will take you to the Mera Peak Base Camp.
          Instead of trekking in the Sagarmatha National Park, you will be
          exploring the untouched Makalu Barun National Park, which begins on
          the way from Ningsow (2,863 m/9,394 ft) to Chhatra Khola (2,800
          m/9,187 ft). As the initial part of the journey is trekking, you get a
          chance to look into the daily lifestyle of the locals and their rich
          cultural heritage. Sherpas are one of the ethnic groups that reside on
          the Mera peak route. Trekking helps us to acclimatize and adjust to
          the environment, which prepares us for climbing. We take a rest day at
          Khare before pushing towards Mera High Camp (5,780 m/18,964 ft) and
          then to the summit. The team leader will give you pre-climbing
          training to boost your confidence before you will proceed above the
          base camp.
        </div>

        <img
          ref={ref}
          src={meraPeakClimbing}
          alt="mera peak climbing"
          className="col-sm-12 col-md-12 mb-3 w-100"
        />

        <div
          ref={ref2}
          className="row wrap justify-content-between col-sm-12 my-3"
        >
          <div className="col-sm-12 col-md-6">
            <div className="big-title col-sm-12 mb-3">
              CROSS NUMEROUS PASSES AND GUSHING RIVERS BEFORE WALKING THROUGH
              THE STEEP MORAINES OF DIG GLACIER TO DIG KHARKA. CHALLENGE
              YOURSELF ON THE OFFBEAT SECTION OF THE KHUMBU REGION.
            </div>
            <div className="title-content col-sm-12 mb-3">
              If we consider the technical aspect, then Mera Peak is much more
              manageable than Island Peak climbing and Lobuche peak climbing.
              Mera peak is higher than any other trekking peak in Nepal, which
              makes it difficult. The exposure to sun and wind, high elevation,
              harsh Himalayan surrounding, and snowy slopes make Mera peak
              climbing physically very demanding. The climbing route has a
              straight ascend following a glacier walk. The slopes do not exceed
              more than 30 degrees, and the team will fix ropes for more safety.
              You need basic climbing gear like crampons, ice axes, etc to
              climb. We have divided the entire journey into three phases. Phase
              I includes trekking to Mera Peak base camp, Phase II is our
              climbing period, and Phase III involves trekking back to Lukla. As
              per the mountaineering vocabulary, Mera's peak climbing difficulty
              is marked Alpine PD. The off-the-beaten trekking along Hinku Honku
              valley through dense forests, mountain passes, and moraines will
              prepare you for the mountaineering part.
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <img
              src={meraPeakClimbing1}
              alt="mera peak climbing"
              className="col-sm-12 mb-3 long-img"
            />
          </div>
        </div>
        <div
          ref={ref3}
          className="flex row wrap justify-content-between col-sm-12 mt-5"
        >
          <div className="col-sm-12 col-md-6">
            <img
              src={meraPeakClimbing2}
              alt="mera peak climging"
              className="col-sm-12  mb-3 short-img"
            />
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="big-title col-sm-12 mb-3">
              MODERN HOTEL IN KATHMANDU, EXPERIENCE LODGE/TEA HOUSE OVERNIGHT
              STAY WHILE TREKKING TO KHARE, AND TENTS DURING CLIMBING PERIOD.
            </div>
            <div className="title-content col-sm-12 mb-3">
              In Kathmandu, you will have the luxury of staying in comfortable
              hotels with all the modern conveniences. As you go on the trek, we
              will arrange for you to stay in simple lodges run by local
              villagers. These lodges offer twin-shared rooms with clean bedding
              and blankets. For the Mera peak climbing portion of the trip, we
              will spend one night at the Mera peak high camp in tents. The
              group will have shared dining and washroom tents, as well as get
              individual sleeping tents. The tents are provided by the company,
              so you do not need to bring your own.
            </div>
          </div>
        </div>
        <div
          ref={ref4}
          className="row wrap justify-content-between col-sm-12 my-3"
        >
          <div className="col-sm-12 col-md-6">
            <div className="big-title col-sm-12 mb-3">
              TASTE A WIDE VARIETY OF NEPALI DISHES- FLAVOURFUL, FILLING &
              NUTRITIOUS. MENU THAT WILL SURELY APPEAL TO YOUR TASTE PALETTE.
            </div>
            <div className="title-content col-sm-12 mb-3">
              Nepali dishes will greatly enhance the overall experience of your
              trip to Nepal, adding flavor and variety to the journey. Nepali
              cuisine is a delicious blend of Indian, Tibetan, and Chinese
              influences, and features a wide range of dishes that are sure to
              appeal to a variety of tastes. Trying out different Nepali dishes
              is a great way to immerse yourself in the local culture and
              experience new flavors and textures. Whether you are looking for a
              filling meal or a tasty snack, Nepali cuisine has something to
              offer for every occasion. And the best news is the Nepali staple
              meal dal bhat will become your best friend in the mountains.
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <img
              src={meraPeakClimbing3}
              alt="mera peak climbing"
              className="col-sm-12 mb-3 long-img"
            />
          </div>
        </div>
        <div
          ref={ref5}
          className="flex row wrap justify-content-between col-sm-12 mt-5"
        >
          <div className="col-sm-12 col-md-6">
            <img
              src={meraPeakClimbing4}
              alt="mera peak climbing"
              className="col-sm-12  mb-3 short-img"
            />
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="big-title col-sm-12 mb-3">
              HAVE A TRULY REJUVENATING AND RESTORATIVE EXPERIENCE, ALONG WITH
              FEELING A HUGE SENSE OF ACHIEVEMENT UPON REACHING THE MERA PEAK
              SUMMIT
            </div>
            <div className="title-content col-sm-12 mb-3">
              Climbing Mera Peak offers the opportunity for tranquility and
              relaxation amidst the beauty and grandeur of the mountain
              environment. As you make your way up the trail, you will have the
              chance to immerse yourself in the peaceful surroundings and take
              in the astonishing views of the surrounding landscape. The act of
              climbing itself is a form of unwinding, as it allows you to focus
              on the present moment and let go of any stress or distractions.
              The physical exertion and mental challenge of the climb, will help
              to clear the mind and provide a sense of accomplishment and
              satisfaction. At the peak, you will have the chance to truly
              de-stress and soak in the breathtaking sceneries of the mountains,
              glaciers, and valleys. The sense of achievement that comes with
              reaching the summit, combined with the peaceful atmosphere, will
              provide a truly rejuvenating and restorative experience.
            </div>
          </div>
        </div>
        <div
          ref={ref6}
          className="row wrap justify-content-between col-sm-12 my-3"
        >
          <div className="col-sm-12 col-md-6">
            <div className="big-title col-sm-12 mb-3">
              TRAVERSE OPPOSITE OF THE MAINSTREAM TRAIL TO EBC, AN UNDISTURBED
              SECTION OF THE EVEREST REGION IN THE MAKALU BARUN NATIONAL PARK
            </div>
            <div className="title-content col-sm-12 mb-3">
              If you want to climb a mountain in the Himalayas and are not sure
              whether your body will sustain the expedition or not, then we
              highly recommend checking out our Mera peak climbing details. Mera
              peak is one of the easiest peaks to climb in the Himalayas because
              of no technical sections. By saying this, we do not mean the
              expedition will be effortless. You still have to push yourself in
              the hostile environment of the Himalayas, however the success and
              safety rate are very high, which makes Mera peak climbing 18 days
              very approachable. A team of expert mountaineers will support you
              during the summit, so you do not have to worry about anything. The
              Mera peak climbing is an adventurous journey. Within the span of
              your 18 days in Nepal, you will make lifelong memories and come
              across some fantastic landscapes. From your landing at Kathmandu
              to a thrilling flight to Lukla and up to the summit of Mera peak,
              you will be enjoying every second of the trip with us, Adventure
              Master Treks.
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <img
              src={meraPeakClimbing5}
              alt="mera peak climbing"
              className="col-sm-12 mb-3 long-img"
            />
          </div>
        </div>
      </div>
      {/* <div className="container map"></div> */}
      <div className="main-modal">
        <Modals
          sendmodaldetails={sendmodaldetails}
          tripTitle={tripTitle}
          tripPrice={tripPrice}
        />
      </div>
    </>
  );
};

export default Overview;
