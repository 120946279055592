import React, { useState } from "react";
import {
  AiOutlineArrowUp,
  AiOutlineArrowDown,
  AiOutlineCloseCircle,
} from "react-icons/ai";
import { HashLink } from "react-router-hash-link";

const Modals = (props) => {
  const { tripTitle, tripPrice, sendmodaldetails } = props;
  const { id, modalhead, modaldetail } = sendmodaldetails;
  const [expandModal, setExpandModal] = useState(false);

  const modaldetailtrip = [
    {
      id: 1,
      modaldetail: [
        {
          modaltitle: "Khumbu Region:",
          modaltext:
            "Mt. Everest is located in the Khumbu region, the northeastern part of Nepal. Also known as the Everest region, it is part of the Solukhumbu district. Khumbu region is protected under the Sagarmatha National Park, which starts at Monjo & goes up to the top of Everest. Lukla, Phakding, Namche Bazaar, Tengboche, Thame, Phortse, Chhomrong, Gokyo, Pangboche, Dingboche, Lobuche, and Gorak Shep are famous settlement areas. From Lukla to Everest, the elevation varies 2,860 meters to 8,849 meters. The Khumbu region is also famous for its gorgeous alpine lakes, high mountain passes, moraines, glaciers, streams, and wildlife.",
        },
        // {
        //   modaltitle: "Difficulty Level:",
        //   modaltext:
        //     "As we have been mentioning from the very start, the Everest base camp helicopter tour is easy. Unlike trekking, you do not need to walk 6 hours a day on steep, rugged paths whatsoever. There is no challenging factor in the Everest helicopter tour. This is a perfect family tour package in Nepal that you can do with your kids & senior members. You only need to commit 4 to 5 hours of your day for a helicopter tour to EBC.",
        // },
        // {
        //   modaltitle: "Terrain:",
        //   modaltext:
        //     "The walking part only involves when you land at Kala Patthar and at Everest View Hotel. It's simple walking on rough terrain for a few minutes. Your body will not see any stress or exhaustion.",
        // },
        // {
        //   modaltitle: "Altitude Sickness:",
        //   modaltext:
        //     "Even though the Everest base camp helicopter tour with landing bypasses all the physical difficulties, the risks of altitude sickness still remain there. At the end of the day, you'll be flying from Kathmandu at 1,400 m to Kala Patthar at 5,644 m. The elevation increase is drastic in the short time frame, which may trigger altitude sickness in some travelers. The chances are very less likely, though. Till this date, we have not seen any participants getting altitude sickness during the Everest base camp heli tour. It's a quick trip, so you'll get the opportunity to escape from altitude sickness. To reduce even the slightest possibility of altitude sickness, we do not stay more than 10 minutes at Kala Patthar.",
        // },
        // {
        //   modaltitle: "Team:",
        //   modaltext:
        //     "At Adventure Master, we have a professional team of individuals who love traveling and have been organizing ventures in the Himalayas for numerous years. For the Everest base camp helicopter tour, we are working with one of the most experienced aviation companies in Nepal, who have been flying in the Himalayas for multiple decades. Our office team and flight crew together make the finest team that you can fly with, in the Himalayas freely.",
        // },
        // {
        //   modaltitle: "Support:",
        //   modaltext:
        //     "We take care of all of your requests while traveling with us in Nepal. Our team will make sure everything is as per your requirements. For the Everest helicopter tour with landing, we obtain the necessary permits and help you with boarding. We make sure the booking and boarding both go seemingly. Our team will assist you during the flight and take care of your safety. Our goal is to make the Everest base camp helicopter tour with landing as memorable as possible but not at the cost of your safety.",
        // },
        // {
        //   modaltitle: "Equipment:",
        //   modaltext:
        //     "Another great thing about the Everest base camp helicopter tour is that you'll not need to pack numerous things. Wear normal appropriate clothes and a windproof down jacket (you can rent it in Thamel) with comfortable shoes & warm socks. Carry sun hat, UV protection sunglasses, reusable water bottle, toiletries (tissue paper, sunscreen, lip balm, etc), camera, passport & other documents.",
        // },
      ],
    },
    {
      id: 2,
      modaldetail: [
        {
          modaltitle: "Khumbu Region:",
          modaltext:
            "Mt. Everest is located in the Khumbu region, the northeastern part of Nepal. Also known as the Everest region, it is part of the Solukhumbu district. Khumbu region is protected under the Sagarmatha National Park, which starts at Monjo & goes up to the top of Everest. Lukla, Phakding, Namche Bazaar, Tengboche, Thame, Phortse, Chhomrong, Gokyo, Pangboche, Dingboche, Lobuche, and Gorak Shep are famous settlement areas. From Lukla to Everest, the elevation varies 2,860 meters to 8,849 meters. The Khumbu region is also famous for its gorgeous alpine lakes, high mountain passes, moraines, glaciers, streams, and wildlife.",
        },
        {
          modaltitle: "Difficulty Level:",
          modaltext:
            "As we have been mentioning from the very start, the Everest base camp helicopter tour is easy. Unlike trekking, you do not need to walk 6 hours a day on steep, rugged paths whatsoever. There is no challenging factor in the Everest helicopter tour. This is a perfect family tour package in Nepal that you can do with your kids & senior members. You only need to commit 4 to 5 hours of your day for a helicopter tour to EBC.",
        },
        {
          modaltitle: "Terrain:",
          modaltext:
            "The walking part only involves when you land at Kala Patthar and at Everest View Hotel. It's simple walking on rough terrain for a few minutes. Your body will not see any stress or exhaustion.",
        },
        {
          modaltitle: "Altitude Sickness:",
          modaltext:
            "Even though the Everest base camp helicopter tour with landing bypasses all the physical difficulties, the risks of altitude sickness still remain there. At the end of the day, you'll be flying from Kathmandu at 1,400 m to Kala Patthar at 5,644 m. The elevation increase is drastic in the short time frame, which may trigger altitude sickness in some travelers. The chances are very less likely, though. Till this date, we have not seen any participants getting altitude sickness during the Everest base camp heli tour. It's a quick trip, so you'll get the opportunity to escape from altitude sickness. To reduce even the slightest possibility of altitude sickness, we do not stay more than 10 minutes at Kala Patthar.",
        },
        {
          modaltitle: "Team:",
          modaltext:
            "At Adventure Master, we have a professional team of individuals who love traveling and have been organizing ventures in the Himalayas for numerous years. For the Everest base camp helicopter tour, we are working with one of the most experienced aviation companies in Nepal, who have been flying in the Himalayas for multiple decades. Our office team and flight crew together make the finest team that you can fly with, in the Himalayas freely.",
        },
        {
          modaltitle: "Support:",
          modaltext:
            "We take care of all of your requests while traveling with us in Nepal. Our team will make sure everything is as per your requirements. For the Everest helicopter tour with landing, we obtain the necessary permits and help you with boarding. We make sure the booking and boarding both go seemingly. Our team will assist you during the flight and take care of your safety. Our goal is to make the Everest base camp helicopter tour with landing as memorable as possible but not at the cost of your safety.",
        },
        {
          modaltitle: "Equipment:",
          modaltext:
            "Another great thing about the Everest base camp helicopter tour is that you'll not need to pack numerous things. Wear normal appropriate clothes and a windproof down jacket (you can rent it in Thamel) with comfortable shoes & warm socks. Carry sun hat, UV protection sunglasses, reusable water bottle, toiletries (tissue paper, sunscreen, lip balm, etc), camera, passport & other documents.",
        },
      ],
    },
    {
      id: 3,
      modaldetail: [
        {
          modaltitle: "Facilities:",
          modaltext:
            "The facilities in the lodge are basic. The rooms are small and have clean bedding. The dining area is like a common place where you can hang out and mingle with other trekkers & locals. It is also the warmest space in the lodge. The washroom can be shared/private. ",
        },
        {
          modaltitle: "Single room:",
          modaltext:
            "Single rooms are not usually available on the trail and are a bit more expensive than a shared room. The Gokyo Lakes trek package cost covers double occupancy. If you do not feel comfortable sharing a room with a fellow trekker, let us know during the time of booking. We'll book you single rooms along the trail.",
        },
        {
          modaltitle: "Hot water:",
          modaltext:
            "Hot water is only available for general cleaning. If you want to take a hot shower during trekking, you have to pay a few extra bucks, which is not covered in the package cost. ",
        },
        {
          modaltitle: "Charging:",
          modaltext:
            "Charging stations are built separately in the teahouse & lodges along the trail. You can charge your electronic devices by paying a few extra bucks. If you need to charge your device frequently, then get spare batteries or a solar charger. ",
        },
        {
          modaltitle: "WIFI:",
          modaltext:
            "WiFi is available in villages like Lukla, Phakding, and Namche Bazaar. Of course, the service is not free. On request, we'll help you get a Nepali sim with adequate data to use the internet while trekking. ",
        },
      ],
    },
    {
      id: 4,
      modaldetail: [
        {
          modaltitle: "Cuisine:",
          modaltext:
            "Nepali, Chinese, and Indian cuisine with a good balance of Continental numbers are seen generally in the cafes & restaurants in Kathmandu. If you want to have cuisine like Korean, Japanese, Italian, etc, you can visit the specific dinners serving these cuisines in Kathmandu.",
        },
        {
          modaltitle: "Nepali Flavors:",
          modaltext:
            "You'll see lots of Nepali dishes on the menu that will give you a fair idea of Nepali flavors. If you want to try some authentic delicious Nepali food that we recommend you visit a Newari restaurant and have chatamari, choila, bara, yomori, etc. Likewise, do not forget to taste sekuwa, tass, lapsi candy, gundruk, dhido, etc. ",
        },
        {
          modaltitle: "Super Food:",
          modaltext:
            "The staple meal in Nepal is dal bhat. It's a heavy meal set that has varieties of side dishes with steamed rice and lentil soup. You have the option to choose between veg/non-veg. This particular menu provides all the necessary nutrients and energy. ",
        },
        {
          modaltitle: "Suitablility:",
          modaltext:
            "Whether you are a vegetarian, vegan, or gluten-free diner, you'll have no tough time. Especially for vegetarians, Nepal is a haven because a good number of people here are vegetarians.",
        },
      ],
    },
    {
      id: 5,
      modaldetail: [
        // {
        //   modaltitle: "ATM/Currency:",
        //   modaltext:
        //     " There is no ATM after Namche Bazaar. You can withdraw Nepali cash to use for personal expenses while trekking in Kathmandu or Namche Bazaar.",
        // },
        // {
        //   modaltitle: "Internet:",
        //   modaltext:
        //     "Internet service in the Khumbu region is decent. You'll get the network using Ncell sim up to EBC even though it is unstable most of the time.",
        // },
        // {
        //   modaltitle: "Flora + Fauna:",
        //   modaltext:
        //     "There is a wide range of vegetation along the way. The Everest region is home to many birds and animals. You may come across a few of them.",
        // },
        // {
        //   modaltitle: "Locals:",
        //   modaltext:
        //     "Gokyo Lake trek 14 days is also very culturally enriching. The stunning villages along the way let you experience the mountain lifestyle. The intimate encounters with the locals and glimpse of their tradition make the journey even more fascinating. You'll come across many stupas, prayer wheels, prayer flags, and monasteries on the way.",
        // },
        // {
        //   modaltitle: "Language:",
        //   modaltext:
        //     "The locals speak many languages. You can communicate in English. Most of them understand English well enough to respond. You can learn some Nepali gestures like Namaste (Hello) and Dhanyabad (Thank You) to sound polite.",
        // },
        // {
        //   modaltitle: "Monasteries:",
        //   modaltext:
        //     "Along the trail, you'll come across numerous stupas, chortens, prayer wheels, and a few old monasteries. Most of the palaces are marked with colorful prayer flags.",
        // },
      ],
    },
    {
      id: 6,
      modaldetail: [
        {
          modaltitle: "ATM/Currency:",
          modaltext:
            " There is no ATM after Namche Bazaar. You can withdraw Nepali cash to use for personal expenses while trekking in Kathmandu or Namche Bazaar.",
        },
        {
          modaltitle: "Internet:",
          modaltext:
            "Internet service in the Khumbu region is decent. You'll get the network using Ncell sim up to EBC even though it is unstable most of the time.",
        },
        {
          modaltitle: "Flora + Fauna:",
          modaltext:
            "There is a wide range of vegetation along the way. The Everest region is home to many birds and animals. You may come across a few of them.",
        },
        {
          modaltitle: "Locals:",
          modaltext:
            "Gokyo Lake trek 14 days is also very culturally enriching. The stunning villages along the way let you experience the mountain lifestyle. The intimate encounters with the locals and glimpse of their tradition make the journey even more fascinating. You'll come across many stupas, prayer wheels, prayer flags, and monasteries on the way.",
        },
        {
          modaltitle: "Language:",
          modaltext:
            "The locals speak many languages. You can communicate in English. Most of them understand English well enough to respond. You can learn some Nepali gestures like Namaste (Hello) and Dhanyabad (Thank You) to sound polite.",
        },
        {
          modaltitle: "Monasteries:",
          modaltext:
            "Along the trail, you'll come across numerous stupas, chortens, prayer wheels, and a few old monasteries. Most of the palaces are marked with colorful prayer flags.",
        },
      ],
    },
  ];
  const expandHandler = () => {
    setExpandModal(!expandModal);
  };

  const collapseHandler = () => {
    setExpandModal(false);
  };

  const onTripChange = () => {
    sessionStorage.removeItem("tripTitle");
    sessionStorage.removeItem("tripPrice");
    sessionStorage.setItem("tripTitle", tripTitle);
    sessionStorage.setItem("tripPrice", tripPrice)
  };

  return (
    <>
      <div className={`modals ${expandModal ? "expand" : ""}`}>
        <div className={`modal-head `} onClick={expandHandler}>
          <div className="modal-head-title">
            {!expandModal ? (
              <AiOutlineArrowUp fontSize="2rem" className="px-1" />
            ) : (
              <AiOutlineArrowDown fontSize="2rem" className="px-1" />
            )}
            <div className="head-title">{modalhead}</div>
          </div>
          <div className="iclose">
            {expandModal && (
              <AiOutlineCloseCircle
                fontSize="1.3rem"
                onClick={collapseHandler}
              />
            )}
          </div>
        </div>
        <div className={`modal-body ${expandModal ? "modal-body-expand" : ""}`}>
          {!expandModal ? (
            <>
              {modaldetail &&
                modaldetail?.map((detail) => (
                  <div className="modal-content">
                    <div className="modal-title d-none d-sm-flex">
                      {detail.modaltitle}
                    </div>
                    <div className="modal-text">{detail.modaltext}</div>
                  </div>
                ))}
            </>
          ) : (
            <>
              {modaldetailtrip &&
                modaldetailtrip[id - 1]?.modaldetail?.map((detail) => (
                  <div className="modal-content">
                    <div className="modal-title fw-bold">{detail.modaltitle}</div>
                    <div className="modal-text">{detail.modaltext}</div>
                  </div>
                ))}
            </>
          )}
        </div>
        <HashLink
          smooth
          to="/booking"
          className="modal-book link"
          style={{ color: "#000" }}
          onClick={onTripChange}
        >
          BOOK NOW
        </HashLink>
      </div>
    </>
  );
};

export default Modals;
