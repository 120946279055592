import { useInView } from "react-intersection-observer";
import Modals from "./Modals";

import abc1 from "../../../image/Annapurna/abc1.jpg";
import abc4 from "../../../image/Annapurna/abc4.jpg";
import abc5 from "../../../image/Annapurna/abc5.jpg";
import annapurna1 from "../../../image/Annapurna/AnnapurnaCircuit/annapurnaCircuit.jpg";
import poonhill from "../../../image/Annapurna/Poonhill/poonHill.jpg";

import image1 from "../../../image/Annapurna/AnnapurnaCircuitTilicho/10.jpg";
import image3 from "../../../image/Annapurna/AnnapurnaCircuitTilicho/9.jpg";
import image4 from "../../../image/Annapurna/AnnapurnaCircuitTilicho/3.jpg";
import image5 from "../../../image/Annapurna/AnnapurnaCircuitTilicho/16.jpg";
import image6 from "../../../image/Annapurna/AnnapurnaCircuitTilicho/24.jpg";
import image7 from "../../../image/Annapurna/AnnapurnaCircuitTilicho/13.jpg";
const Overview = () => {
  const tripTitle =
    "Annapurna Circuit Trek with Tilicho Lake and Poon Hill - 17 Days";
  const tripPrice = "$570 pp (Budget Price)";

  const modaltrip = [
    {
      id: 1,
      modalhead: "Trip Overview",
      modaldetail: [
        {
          modaltitle: "Difficulty Level",
          modaltext: <strong>Moderate to Difficult</strong>,
        },
        {
          modaltitle: "Maximum Altitude",
          modaltext: <strong>4,130 m/13,549 ft</strong>,
        },
        {
          modaltitle: "Best Season",
          modaltext: <strong>Spring & Autumn</strong>,
        },
      ],
      trip: "Annapurna Base Camp Trek",
    },
    {
      id: 2,
      modalhead: "The Challenge",
      modaldetail: [
        {
          modaltitle: "Difficulty Level",
          modaltext: <strong>Moderate to Difficult</strong>,
        },
        {
          modaltitle: "Maximum Altitude",
          modaltext: <strong>4,130 m/13,549 ft</strong>,
        },
      ],
      trip: "Annapurna Base Camp Trek",
    },
    {
      id: 3,
      modalhead: "Accommodations",
      modaldetail: [
        {
          modaltitle: "Accommodation on the trail",
          modaltext: <strong>Lodge</strong>,
        },
        {
          modaltitle: "In Kathmandu",
          modaltext: <strong>Three-star hotel</strong>,
        },
        {
          modaltitle: "Style",
          modaltext: <strong>Shared</strong>,
        },
      ],
      trip: "Annapurna Base Camp Trek",
    },
    {
      id: 4,
      modalhead: "Nutrition",
      modaldetail: [
        {
          modaltitle: "Meals in Kathmandu & Pokhara",
          modaltext: <strong>All breakfasts & farewell dinner</strong>,
        },
        {
          modaltitle: "Meals during the trek",
          modaltext: (
            <strong>Three meals a day (breakfast, lunch & dinner)</strong>
          ),
        },
        {
          modaltitle: "Cuisine",
          modaltext: <strong>Nepali, Tibetan, Continental & Indian</strong>,
        },
      ],
      trip: "Annapurna Base Camp Trek",
    },
    {
      id: 5,
      modalhead: "Regeneration",
      modaldetail: [
        {
          modaltitle: "Morning stretching",
          modaltext:
            "Practice yoga & meditation by yourself (Or upgrade to yoga trekking, led by a professional yoga instructor)",
        },
        {
          modaltitle: "",
          modaltext: (
            <strong>Re-learn to live slow & be present in every moment</strong>
          ),
        },
      ],
      trip: "Annapurna Base Camp Trek",
    },
    {
      id: 6,
      modalhead: "Destination",
      modaldetail: [
        {
          modaltitle: "Duration",
          modaltext: <strong>13 Days</strong>,
        },
        {
          modaltitle: "Location",
          modaltext: <strong>Annapurna Region, Nepal</strong>,
        },
        {
          modaltitle: "Province",
          modaltext: <strong>1</strong>,
        },
        {
          modaltitle: "Population",
          modaltext: <strong>100,000+</strong>,
        },
        {
          modaltitle: "Wildlife Species",
          modaltext: <strong>2,000+</strong>,
        },
        {
          modaltitle: "Highest Points",
          modaltext: <strong>Poon Hill (3,210 m) & ABC (4,1300 m)</strong>,
        },
      ],
      trip: "Annapurna Base Camp Trek",
    },
  ];
  let sendmodaldetails = {};

  const { ref, inView } = useInView();
  const { ref: ref2, inView: inView2 } = useInView();
  const { ref: ref3, inView: inView3 } = useInView();
  const { ref: ref4, inView: inView4 } = useInView();
  const { ref: ref5, inView: inView5 } = useInView();
  const { ref: ref6, inView: inView6 } = useInView();

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll, true);
  // }, [handleScroll]);
  if (inView6 === true) {
    sendmodaldetails = modaltrip[5];
  } else if (inView5 === true) {
    sendmodaldetails = modaltrip[4];
  } else if (inView4 === true) {
    sendmodaldetails = modaltrip[3];
  } else if (inView3 === true) {
    sendmodaldetails = modaltrip[2];
  } else if (inView2 === true) {
    sendmodaldetails = modaltrip[1];
  } else if (inView === true) {
    sendmodaldetails = modaltrip[0];
  } else {
    sendmodaldetails = modaltrip[5];
  }
  return (
    <>
      <div ref={ref} className="trip-details-next container">
        <div className="trip-fact col-10 flex-sm-wrap mb-2 mb-sm-3">
          <div className="facts">
            <div className="fact">
              <h1>
                Annapurna Circuit Trek With Tilicho Lake Trek And Poon Hill - 17
                Days
              </h1>
            </div>
          </div>
        </div>
        <div ref={ref} className="big-title col-sm-12 col-md-7 mb-3">
          EMBARK ON THE AMAZING TEAHOUSE TREK IN THE BEAUTIFUL ANNAPURNA REGION
          AND ENJOY AN ADVENTUROUS HIKE THROUGH LUSH FORESTS, PASTURES, REMOTE
          VILLAGES, HIGH PASS & PILGRIMAGE SITE
        </div>
        <div ref={ref} className="title-content col-sm-12 col-md-7 mb-3">
          Explore a very different side of the Himalayas, rich in flora, fauna,
          and home to people of several communities on the Annapurna circuit
          trek with Tilicho lake. Annapurna circuit is a famous and classic
          trekking route in the Himalayas of Nepal. You'll be walking through
          lush rhododendron forests, mist-shrouded valleys, landslide areas,
          picturesque villages, and narrow rough trails. The unique lifestyle of
          the locals, artistic monasteries & temples, rivers, cascading
          waterfalls, and fluttering prayer flags along the trail will inspire
          those seeking a new perspective on the Himalayas. Spend a long time in
          the mountains, enjoying diverse landscapes and western Himalayan
          mountains like Annapurna (8,091 m/26,545 ft), Dhaulagiri (8,167
          m/26,795 ft), Nilgiri (7,061 m/23,166 ft), Tukuche (6,920 m/22,703
          ft), Gangapurna (7,455 m/24,459 ft), Chulu Peak (6,584 m/21,601 ft),
          Tilicho Peak (7,134 m/23,406 ft), Thorong Peak (6,144 m/20,157 ft),
          Khatungkang (6,484 m/21,272 ft), etc. Make memories to cherish forever
          and lifetime friends.
        </div>

        <img
          ref={ref}
          src={image1}
          alt="annapurna circuit"
          className="col-sm-12 col-md-12 mb-3 w-100"
        />

        <div
          ref={ref2}
          className="row wrap justify-content-between col-sm-12 my-3"
        >
          <div className="col-sm-12 col-md-6">
            <div className="big-title col-sm-12 mb-3">
              HONE YOUR TREKKING SKILLS BY CROSSING THE HIGHEST PASS IN THE
              HIMALAYAS, THORONG LA & LEARN TO SHINE IN ADVERSITY AT THE SHORE
              OF TILICHO LAKE
            </div>
            <div className="title-content col-sm-12 mb-3">
              There's a lot to learn during the Annapurna circuit and Tilicho
              Lake trekking. Whether it's about testing your limits, shaping
              your mind to not get afraid in front of challenges, or honing your
              mountain knowledge. Every new village will present you with a new
              set of challenges that will be no doubt way more arduous than the
              previous. However, as you overcome every new challenge, your
              confidence will be at its peak. Every difficult walk will present
              you with ten times better scenery.
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <img
              src={image7}
              alt="annapurna circuit"
              className="col-sm-12 mb-3 img-fluid"
            />
          </div>
        </div>
        <div
          ref={ref3}
          className="flex row wrap justify-content-between col-sm-12 mt-5"
        >
          <div className="col-sm-12 col-md-6">
            <img
              src={image4}
              alt="annapurna circuit"
              className="col-sm-12  mb-3 img-fluid"
            />
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="big-title col-sm-12 mb-3">
              EXPERIENCE TEAHOUSE ACCOMMODATION, CLEAN & COMFORTABLE BEDDING TO
              SPEND NIGHTS AFTER A LONG WALKING DAY
            </div>
            <div className="title-content col-sm-12 mb-3">
              The Annapurna circuit with Tilicho lake trail has teahouse
              accommodation. Tea houses are the most basic accommodation in the
              Himalayas. You can take it as it's better to have a roof over your
              head than to spend the night under the stars. Some parts of the
              trail offer better accommodation facilities. Anyway, you'll get
              comfortable and clean bedding to have a goodnight's sleep. The
              rooms will have nothing much in terms of interior and furniture.
            </div>
          </div>
        </div>
        <div
          ref={ref4}
          className="row wrap justify-content-between col-sm-12 my-3"
        >
          <div className="col-sm-12 col-md-6">
            <div className="big-title col-sm-12 mb-3">
              INDULGE IN A WIDE VARIETY OF FOOD DURING THE TREK, TRY AUTHENTIC
              NEPALI DISHES & MOUTHWATERING FLAVORS
            </div>
            <div className="title-content col-sm-12 mb-3">
              None of our trips are complete without food. We take special care
              of meals during any trip with us. We believe the best way to learn
              about new cultures and mingle with the locals is by tasting their
              food. Food sparks conversation and curiosity. Delights hearts &
              above all tells you a lot about the locals. Nepal is home to
              numerous ethnic groups. All of them present distinct varieties of
              food. During the Annapurna circuit and Tilicho Lake trek, you'll
              have many authentic Nepali dishes.
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <img
              src={image5}
              alt="annapurna circuit"
              className="col-sm-12 mb-3 img-fluid"
            />
          </div>
        </div>
        <div
          ref={ref5}
          className="flex row wrap justify-content-between col-sm-12 mt-5"
        >
          <div className="col-sm-12 col-md-6">
            <img
              src={image3}
              alt="annapurna circuit"
              className="col-sm-12 mb-3 img-fluid"
            />
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="big-title col-sm-12 mb-3">
              PUSH YOUR MIND AND BODY TO THEIR FULL CAPACITY, UNWIND & ENJOY THE
              TRANQUILITY OF THE ANNAPURNA REGION
            </div>
            <div className="title-content col-sm-12 mb-3">
              The Annapurna circuit with Tilicho lake trek has a good balance of
              adventure and also lets you unwind and enjoy the surroundings to
              full. Disconnect with the digital world and once again live in the
              real world. Be aware of your surroundings and every breath you
              take. Escape in nature and live with the soothing sound of
              waterfalls & chirping of birds. Learn to live a simple lifestyle
              like the locals along the trail. Unwind your thoughts and connect
              to your higher self.
            </div>
          </div>
        </div>
        <div
          ref={ref6}
          className="row wrap justify-content-between col-sm-12 my-3"
        >
          <div className="col-sm-12 col-md-6">
            <div className="big-title col-sm-12 mb-3">
              COME ACROSS THE RICHES OF THE ANNAPURNA CONSERVATION AREA & GET TO
              KNOW THE NEPALESE MOUNTAIN COMMUNITIES
            </div>
            <div className="title-content col-sm-12 mb-3">
              Annapurna circuit trek package is also known as Around Annapurna
              Trek. Trekking through the subtropical forested paths, the trail
              ascends to paddy fields, pastures, and a high pass before
              descending to Lower Mustang and continues along the deepest river
              gorge, apple orchards, and hot springs. You'll be trekking in the
              Annapurna Conservation Area, which protects the area and its
              natural wonders. The ancient cultural heritage of different ethnic
              groups and the heartwarming welcome of the locals make the trek
              very immersive.
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <img
              src={image6}
              alt="annapurna circuit"
              className="col-sm-12 mb-3 img-fluid"
            />
          </div>
        </div>
      </div>
      {/* <div className="container map"></div> */}
      <div className="main-modal">
        <Modals
          sendmodaldetails={sendmodaldetails}
          tripTitle={tripTitle}
          tripPrice={tripPrice}
        />
      </div>
    </>
  );
};

export default Overview;
