import React from "react";
import TripCard from "../../components/TripCard/EverestRegion/TripCard";
// import MainImage from "../../../image/Everest/ebc.jpeg";
import Footer from "../../components/Footer";
// import wave from "../../../image/pattern.svg";
import EverestHeliNotice from "../../components/EverestHeliNotice";
import EverestTable from "../../trips/EverestBaseCampTrek/EverestTable";

import { cardInfo } from "../../components/TripCard/EverestRegion/EverestRegionTripCardInfo";
import { Container } from "react-bootstrap";
import ScrollToTop from "../../components/ScrollToTop";

const MainEverest = () => {
  return (
    <>
      <ScrollToTop position="top" />
      <div>
        <div className="everest-region-header">

        </div>
        {/* <TripCards /> */}
        <div className="container my-5">
          <h1 className="title-1 text-center text-lg">Explore Everest Region</h1>
          <TripCard cardInfo={cardInfo} />
        </div>
        <Container>
          <EverestHeliNotice />
          <EverestTable />
        </Container>
        <div className="container">

          <h2 className="title mt-5">Everest Region</h2>
          <p className="content mb-5">
            George Leigh Mallory stated that the reason he wanted to climb Mount
            Everest in the 1920s was "because it is there." It still serves as a
            perfectly appropriate answer to the question of why hundreds of
            trekkers chose to travel through the Solu Khumbu to the foot of the
            highest peak in the world almost a century later. Among the dozens of
            beguilingly beautiful mountains that side valleys home to people whose
            hospitality is revered globally, Everest is merely the most well-known
            summit. There is no reason not to travel to this land of superlatives.
            <br />
            <br />
            Whether you refer to it as Mount Everest, Sagarmatha, or Chomolungma
            (Mother Goddess of the World), it is the ultimate goal for everyone
            who has ever dreamed of climbing a mountain. It makes sense why this
            trek has grown to be the most well-known in the Himalayas. since it is
            present. You may travel to Everest in luxury or on a tight budget with
            Magical Nepal. If you want to avoid the throng, think about trekking
            via Tibet to the North Face. The Rongbuk base camp, at 5182 meters, is
            now accessible by road. If adequately acclimatized, you can even go on
            to Advanced Base Camp at 6492m.
            <br />
            <br />
            Or, if you long for the great outdoors, join us on the East or
            Kangshung Face, the least traveled and most difficult path of all. You
            spend the majority of your time above 4000 meters and cross the Karo
            La pass at 5300 meters while camping in remote, wildflower-filled
            alpine meadows away from any crowds. You stop at holy lakes, glaciers,
            monasteries, and historic forts along the trip.
            <br />
            You have views of Makalu, Lhotse, and a variety of other notable
            summits in addition to seeing Everest and its South Col from a unique
            vantage point, ensuring an outstanding experience.
          </p>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default MainEverest;
