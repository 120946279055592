import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { FaCheckCircle } from "react-icons/fa";
import CancelIcon from "@mui/icons-material/Cancel";
import TripFact from "./TripFact";
import AdditionalTable from "./AdditionalTable";

import EverestHeliNotice from "../../components/EverestHeliNotice";
import Gallery from "./Gallery";

export default function Overview() {
  return (
    <div className='overview'>
      <div className='container d-flex mt-5'>
        <div className='row'>
          {/* <div className="col-lg-9 col-sm-12"> */}
          <div className='col-12'>
            <TripFact />
            <Gallery />

            <h4 className='overview-title mt-4'>Outline Itinerary</h4>
            <ul className='p-0'>
              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 01:</strong>{" "}
                </div>
                <li>Arrival in Kathmandu</li>
              </div>

              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 02: </strong>
                </div>
                <li>
                  Flight from Kathmandu to Lukla airport (2,846 m/9,337 ft) and
                  trek to Phakding (2,610 m/8,563 ft)
                </li>
              </div>

              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 03:</strong>
                </div>
                <li>Trek from Phakding to Namche Bazaar (3,440 m/11,290 ft)</li>
              </div>

              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 04:</strong>
                </div>
                <li>
                  Acclimatization in Namche Bazaar: hike to Syangboche Airstrip
                  (3,748 m/12,297 ft) and Everest View Hotel (3,962 m/13,000 ft)
                </li>
              </div>

              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 05:</strong>
                </div>
                <li>
                  Trek from Namche Bazaar to Tengboche Monastery (3,860 m/12,660
                  ft)
                </li>
              </div>

              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 06:</strong>
                </div>
                <li>Trek from Tengboche to Dingboche (4,410 m/14,470 ft)</li>
              </div>

              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 07:</strong>
                </div>
                <li>
                  Acclimatization in Dingboche; hike to Nagarjuna Hill (5,100
                  m/16,732 ft)
                </li>
              </div>

              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 08:</strong>
                </div>
                <li>Trek from Dingboche to Lobuche (4,940 m/16,210 ft)</li>
              </div>

              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 09:</strong>
                </div>
                <li>
                  Trek from Lobuche to Everest Base Camp (5,364 m/17,598 ft) via
                  Gorak Shep (5,164 m/16,942 ft) and back to Gorak Shep for a
                  night's stay
                </li>
              </div>

              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 10:</strong>
                </div>
                <li>
                  Hike to Kala Patthar (5,644 m/18,519 ft) early in the morning
                  and trek down to Dingboche (4,410 m/14,470 ft){" "}
                </li>
              </div>
              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 11:</strong>
                </div>
                <li>Trek to Chhukung (4,750 m/15,580 ft)</li>
              </div>
              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 12:</strong>
                </div>
                <li>Trek to Island Peak Base Camp (5,200 m/17,060 ft)</li>{" "}
              </div>
              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 13:</strong>
                </div>
                <li>
                  Climb Island Peak (6,189 m/20,305 ft) and return to Chhukung
                </li>
              </div>
              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 14:</strong>
                </div>
                <li>Buffer Day</li>
              </div>
              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 15:</strong>
                </div>
                <li>Trek back to Tengboche (3,860 m/12,660 ft)</li>
              </div>
              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 16:</strong>
                </div>
                <li>Trek to Monjo (2,8235 m/9,301 ft)</li>
              </div>
              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 17:</strong>
                </div>
                <li>Trek to Lukla (2,846 m/9,337 ft)</li>
              </div>
              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 18:</strong>
                </div>
                <li>
                  Morning flight back to Kathmandu (1,400 m/4,600 ft( from Lukla
                  airport & farewell dinner in the late evening
                </li>
              </div>
              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 19:</strong>
                </div>
                <li>Final departure</li>
              </div>
            </ul>

            <h4 className='overview-title pt-5'>Trip Highlight</h4>
            <ul className='trip-highlight'>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  A well-guided and safe trek to Mt. Everest lap, Everest base
                  camp
                </li>
              </span>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  An adventurous journey in the Khumbu region within the
                  Sagarmatha National Park
                </li>
              </span>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Explore the eastern Himalayas of Nepal, home to many
                  eight-thousander mountains, the legendary Sherpa community,
                  and beautiful river valleys
                </li>
              </span>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Come across stunning waterfalls & streams, and cross Dudh
                  Koshi river numerous times via hanging bridges
                </li>
              </span>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Sped nights in picturesque mountain villages and get to see
                  the lifestyle of the natives
                </li>
              </span>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Walk through lush rhododendron and magnolia forests before
                  ascending above the tree line and walking via glaciers and
                  moraines
                </li>
              </span>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Hike to Kala Patthar, enjoying the gorgeous sunrise and
                  mountain views
                </li>
              </span>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  The trail offers constant views of snow-capped mountains, lush
                  hills, and diverse landscapes
                </li>
              </span>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Take a thrilling helicopter fight out of the Khumbu region,
                  overlooking the jaw-dropping aerial landscapes of the
                  Himalayas
                </li>
              </span>
            </ul>

            <div className='row d-flex justify-content-between'>
              <div className='col-12'>
                <h4 className='overview-title pt-5'>Package Cost Include</h4>
                <ul className='cost-include'>
                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>
                      All ground travel, including getting to and from the
                      airport, is done in a private car.
                    </li>
                  </span>
                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>
                      One night accommodations at a hotel in Kathmandu including
                      breakfast as per the itinerary.
                    </li>
                  </span>
                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>
                      During the trek, you will be provided with three meals a
                      day: breakfast, lunch, and dinner with one cup of tea each
                      meal.
                    </li>
                  </span>
                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>
                      The best available lodge with twin sharing room during the
                      trek.
                    </li>
                  </span>
                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>
                      A highly experienced, helpful, knowledgeable, friendly,
                      English-speaking guide with all of his salary, food,
                      drinks, lodging, transportation, and insurance covered.
                    </li>
                  </span>
                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>
                      Strong, helpful porters with the right safety gear and
                      walking gear, as well as a salary, food, a place to live,
                      and insurance. (one porter for two people).
                    </li>
                  </span>
                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>
                      A map of a trek and a certificate of completion of the
                      trip.
                    </li>
                  </span>
                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>Taxes and official costs paid to the government.</li>
                  </span>
                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>
                      A map of a trek and a certificate of completion of the
                      trip.
                    </li>
                  </span>
                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>
                      All papers that need to be done, Fees for entering permits
                      you need to go while hiking.
                    </li>
                  </span>
                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>Duffle bag for trekking.</li>
                  </span>
                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>
                      First aid kit- Oxymeter to check your pulse, oxygen
                      saturation, and heart rate twice a day (very helpful to
                      check for signs of Altitude Mountain Sickness, or AMS).
                      This will make sure that your trek is in the safest hands
                      possible, no matter how experienced you are.
                    </li>
                  </span>
                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                      <li>The cost includes Helicopter flight charges.</li>
                    </div>
                  </span>
                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                      <li>
                        Round trip flight from kathmandu to Lukla, including
                        taxes.
                      </li>
                    </div>
                  </span>
                </ul>
              </div>
              <div className='col-12'>
                <h4 className='overview-title pt-5'>Package Cost Exclude</h4>
                <ul className='cost-exclude'>
                  <span>
                    <div className='cost-exclude-icon'>
                      <CancelIcon />
                    </div>
                    <li>
                      Food that you will eat in Kathmandu for lunch and dinner.
                    </li>
                  </span>
                  <span>
                    <div className='cost-exclude-icon'>
                      <CancelIcon />
                    </div>
                    <li>
                      <p>
                        The cost of a visa to enter Nepal is
                        <br />
                        $30 USD for a 15-day stay,
                        <br />
                        30 Days - $50,
                        <br />
                        90 Days - $125
                      </p>
                    </li>
                  </span>
                  <span>
                    <div className='cost-exclude-icon'>
                      <CancelIcon />
                    </div>
                    <li>Your insurance for travel and medical care.</li>
                  </span>
                  <span>
                    <div className='cost-exclude-icon'>
                      <CancelIcon />
                    </div>
                    <li>International airfare</li>
                  </span>
                  <span>
                    <div className='cost-exclude-icon'>
                      <CancelIcon />
                    </div>
                    <li>Your personal expenses.</li>
                  </span>
                  <span>
                    <div className='cost-exclude-icon'>
                      <CancelIcon />
                    </div>
                    <li>
                      All of the alcoholic and non-alcoholic cold and hot drinks
                      you buy on trek (along the way and in the Tea Houses at
                      night).
                    </li>
                  </span>
                  <span>
                    <div className='cost-exclude-icon'>
                      <CancelIcon />
                    </div>
                    <li>
                      All of the sweet desserts, like chocolate, cake, pie, and
                      pudding.
                    </li>
                  </span>
                  <span>
                    <div className='cost-exclude-icon'>
                      <CancelIcon />
                    </div>
                    <li>
                      The tea houses have hot showers and places to charge your
                      phone which will cost you about 2$ to 5$.
                    </li>
                  </span>
                  <span>
                    <div className='cost-exclude-icon'>
                      <CancelIcon />
                    </div>
                    <li>
                      Tip to the guide, the porter, and the driver. (Tipping is
                      expected)
                    </li>
                  </span>
                </ul>
              </div>
            </div>

            <div className='my-5'>
              <EverestHeliNotice />
            </div>

            <AdditionalTable />

            <h4 className='overview-title pt-5'>FAQ</h4>

            <div className='trip-overview-accordion'>
              <Accordion defaultActiveKey='0' flush>
                <Accordion.Item eventKey='0'>
                  <Accordion.Header>
                    <p class='accordion-title'>
                      1) Can helicopters get to Everest Base Camp?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='overview-content'>
                      Yes, a helicopter can get to Everest base camp, but they
                      don't land there most of the time because of inadequate
                      landing space.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='1'>
                  <Accordion.Header>
                    <p class='accordion-title'>
                      2) Is the Everest Base Camp Trek tough?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='accordion-content'>
                      Everest base camp trekking is a moderate trek. Depending
                      on your health and physical fitness, the trek may get a
                      little difficult for some trekkers. If you can walk 6
                      hours a day and can endure simple meals & limited modern
                      facilities, then you can do Everest base camp trekking.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='2'>
                  <Accordion.Header>
                    <p class='accordion-title'>
                      3) How much money should I take on the Everest Base Camp
                      Trek?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='overview-content'>
                      Apart from the package cost that you pay to your travel
                      company, we suggest you budget USD 15 for each day to buy
                      additional snacks and services. Apart from that, you will
                      need money for travel insurance, international flights,
                      shopping, tips, etc.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='3'>
                  <Accordion.Header>
                    <p class='accordion-title'>
                      4) Which month is best for the Everest Base Camp Trek?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='overview-content'>
                      March, April, early-May, October, November, and early
                      December are the best months for the Everest base camp
                      trek, and combine a helicopter return at the end of the
                      trek.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='4'>
                  <Accordion.Header>
                    <p class='accordion-title'>
                      5) How far is Everest Base Camp from Kathmandu?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='overview-content'>
                      From Kathmandu, the Everest base camp is approximately 300
                      km away. You take a 35 minutes flight to Lukla airport and
                      walk 65 km to EBC, covering 9-11 km each day.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='5'>
                  <Accordion.Header>
                    <p class='accordion-title'>
                      6) Is Everest Base Camp worth it?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='overview-content'>
                      Yes, trekking to Everest base camp is totally worth it.
                      And combining a helicopter return from Kala Patthar makes
                      the journey even more fantastic. You get to enjoy the
                      views of the Himalayas from both ground and sky, live a
                      simple life, and unwind along the way.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='6'>
                  <Accordion.Header>
                    <p className='accordion-title'>
                      7) Can I do Everest Base Camp Trek alone?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='overview-content'>
                      Yes, you can do the Everest base camp trek alone. Solo
                      trekkers/backpackers should know that the Everest trekking
                      route is very busy in the peak seasons, so book your
                      accommodation in advance.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='7'>
                  <Accordion.Header>
                    <p class='accordion-title'>
                      8) Is there Wifi at Everest Base Camp?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='overview-content'>
                      Yes, there is WiFi at Everest base camp. You have to use
                      Ncell sim, a Nepali telecommunication network, to get a
                      connection at the base camp.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='8'>
                  <Accordion.Header>
                    <p class='accordion-title'>
                      9) Is Everest Base Camp Trek for beginners?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='overview-content'>
                      Yes, the Everest base camp trek is considered okay for
                      beginners. However, as a new trekker, you have to be
                      physically and mentally ready to enjoy the trek. EBC trek
                      is beginner-friendly but demanding. You have to push your
                      limits and be determined to reach the base camp.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='9'>
                  <Accordion.Header>
                    <p class='accordion-title'>
                      10) Can I drink alcohol on Everest?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='overview-content'>
                      If you are in the climbing phase, we recommend not
                      drinking alcohol as it will dehydrate your body and make
                      you vulnerable to altitude sickness. However, if you are
                      descending and have no major ascending involved further,
                      you can drink alcohol. Try Nepali alcoholic beverages.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='10'>
                  <Accordion.Header>
                    <p class='accordion-title'>
                      11) How do you prevent altitude sickness at Everest Base
                      Camp?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='overview-content'>
                      To prevent altitude sickness, you have to ascend slowly
                      and not gain more than 500 meters a day. If you see
                      symptoms, then have the available medication and rest.
                      Drop to a lower altitude if you do not see any improvement
                      in the symptoms, and stay away from smoking & alcoholic
                      drinks. Eat well, drink plenty of water, and rest.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='11'>
                  <Accordion.Header>
                    <p class='accordion-title'>
                      12) What is the oxygen level at Everest Base Camp?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='overview-content'>
                      The oxygen level is around 85% and 87% at the Everest base
                      camp. At sea level, the oxygen level is about 98%-99%.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
          {/* sidebar price
          <div className="col-lg-3 col-sm-12">
            <div className="overview-sidebar bg-light">
              <span className="badge text-wrap">Top Seller</span>
              <div className="">
                <span className="overview-sidebar-days">14 Days</span>
                <br />
                <span className="overview-sidebar-destination">
                  Kathmandu to Kathmandu
                </span>
                <br />
                <h4 className="price-text my-3">From USD $N/A</h4>
                <div className="group-discount mb-3">
                  <table className="group-discount-table">
                    <p>Group Discount</p>
                    <tr>
                      <td>Pax</td>
                      <td>Price</td>
                    </tr>
                    <tr>
                      <th>1 Pax</th>
                      <th>$N/A</th>
                    </tr>
                    <tr>
                      <th>2 Pax</th>
                      <th>$N/A</th>
                    </tr>
                    <tr>
                      <th>3 Pax</th>
                      <th>$N/A</th>
                    </tr>
                    <tr>
                      <th>4-6 Pax</th>
                      <th>$N/A</th>
                    </tr>
                    <tr>
                      <th>7-10 Pax</th>
                      <th>$N/A</th>
                    </tr>
                    <tr>
                      <th>11-18 Pax</th>
                      <th>$N/A</th>
                    </tr>
                    <tr>
                      <th>19-24 Pax</th>
                      <th>$N/A</th>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="">
                <HashLink smooth to="/booking" className="link">
                  <button className="book-btn">Book Now</button>
                </HashLink>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
