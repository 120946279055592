import React from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";

function TravelInsurance() {
  return (
    <>
      <ScrollToTop position='top' />
      <Header title='Travel Insurance' />
      <div className='container'>
        <div className='title mt-5'>Travel Insurance</div>
        <p className='content'>
          We care most about your safety. Trekking in the Himalayas is a great
          adventure that you can only do once in your life. But we will never
          say that the trip is easy, even though it is beautiful. You have to
          spend days in a place that doesn't have a lot to offer. Everything
          will be different, and it may take you a while to get used to the
          place and the weather. Altitude sickness, or AMS, is more likely the
          higher we go on our trip. Make sure that you are both physically and
          mentally ready for this exciting trip. Other than that, make sure that
          all of your risks are covered by your travel insurance. For example,
          travel insurance covers costs like health problems, accidents while
          hiking, flight cancellations, and trip cancellations due to
          uncontrollable events like a pandemic, a natural disaster, protests,
          or a regional epidemic to make your trip safe and risk-free.
        </p>
        <p className='content'>
          We do not accept a reservation if the person who wants to go into the
          Himalayas does not have good travel insurance. You should know that
          most of our treks are in hard-to-reach, undeveloped areas at high
          altitudes. If there is a medical problem on the trek that first aid
          cannot fix, we will need to send a helicopter to rescue the person.
          The insurance will not only cover your risks, but it will also help
          you travel without stress. Travel insurance is a must if you want your
          trip to be safe and enjoyable.
        </p>
        <p className='content'>
          Before you buy your insurance, make sure your provider knows the
          schedule given by the company or as part of your plan so that it can
          cover all the things that will be done on the tour. Make sure it
          covers both your medical expenses and a helicopter rescue in case of
          an emergency, as well as trip cancellation or interruption insurance.
          If something goes wrong with your plans, like a health problem, a
          family problem, or something else you can't change, this insurance
          will cover you. As we have already said, trekking in the Himalayas is
          a great trip, but there is a chance that you could get sick. So, make
          sure that your health insurance covers all risks and that you know
          what kind of coverage it gives. Evacuation insurance is important
          because altitude sickness is more likely to happen in the Himalayas.
          There are no good hospitals or services in the area. We will give you
          a first-aid kit for sure. But if there is a lot more than that, we
          will have to move you to a place where you can get the right care in
          case of a medical emergency. Losses of luggage and personal items, as
          well as life insurance, also must be included. Make sure your
          insurance company covers your risk according to the tour package that
          you have chosen.
        </p>
      </div>
      <Footer />
    </>
  );
}

export default TravelInsurance;
