import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import ContactPage from "./pages/ContactPage";
import ErrorPage from "./pages/ErrorPage";
import BookPage from "./pages/BookPage";
import Nav from "./pages/components/Nav";

import EverestBaseCampHelicopter from "./pages/trips/EverestBaseCampHelicopter/EverestBaseCampHelicopter";
import EverestBaseCampHelicopterInfo from "./pages/trips/EverestBaseCampHelicopter/TripInfo";
import EverestBaseCampHelicopterOverview from "./pages/trips/EverestBaseCampHelicopter/Overview";
import EverestBaseCampHelicopterItinerary from "./pages/trips/EverestBaseCampHelicopter/Itinerary";
import EverestBaseCampHelicopterTripDetail from "./pages/trips/EverestBaseCampHelicopter/TripDetail";
import EverestBaseCampHelicopterGallery from "./pages/trips/EverestBaseCampHelicopter/Gallery";

import EverestBaseCampTrek from "./pages/trips/EverestBaseCampTrek/EverestBaseCampTrek";
import EverestBaseCampTrekInfo from "./pages/trips/EverestBaseCampTrek/TripInfo";
import EverestBaseCampTrekOverview from "./pages/trips/EverestBaseCampTrek/Overview";
import EverestBaseCampTrekItinerary from "./pages/trips/EverestBaseCampTrek/Itinerary";
import EverestBaseCampTrekTripDetail from "./pages/trips/EverestBaseCampTrek/TripDetail";

import AnnapurnaBaseCampTrek from "./pages/trips/AnnapurnaBaseCamp/AnnapurnaBaseCamp";
import AnnapurnaBaseCampTrekInfo from "./pages/trips/AnnapurnaBaseCamp/TripInfo";
import AnnapurnaBaseCampTrekOverview from "./pages/trips/AnnapurnaBaseCamp/Overview";
import AnnapurnaBaseCampTrekItinerary from "./pages/trips/AnnapurnaBaseCamp/Itinerary";
import AnnapurnaBaseCampTrekTripDetail from "./pages/trips/AnnapurnaBaseCamp/TripDetail";

import ManasluCircuitTrek from "./pages/trips/ManasluCircuit/ManasluCircuit";
import ManasluCircuitTrekInfo from "./pages/trips/ManasluCircuit/TripInfo";
import ManasluCircuitTrekOverview from "./pages/trips/ManasluCircuit/Overview";
import ManasluCircuitTrekItinerary from "./pages/trips/ManasluCircuit/Itinerary";
import ManasluCircuitTrekTripDetail from "./pages/trips/ManasluCircuit/TripDetail";

import GokyoLakeTrek from "./pages/trips/GokyoLakeTrek/GokyoLakeTrek";
import GokyoLakeTrekInfo from "./pages/trips/GokyoLakeTrek/TripInfo";
import GokyoLakeTrekOverview from "./pages/trips/GokyoLakeTrek/Overview";
import GokyoLakeTrekItinerary from "./pages/trips/GokyoLakeTrek/Itinerary";
import GokyoLakeTrekTripDetail from "./pages/trips/GokyoLakeTrek/TripDetail";

import BuddhistPilgrimageTour from "./pages/trips/BuddhistPilgrimageTour/BuddhistPilgrimageTour";
import BuddhistPilgrimageTourInfo from "./pages/trips/BuddhistPilgrimageTour/TripInfo";
import BuddhistPilgrimageTourOverview from "./pages/trips/BuddhistPilgrimageTour/Overview";
import BuddhistPilgrimageTourItinerary from "./pages/trips/BuddhistPilgrimageTour/Itinerary";
import BuddhistPilgrimageTourTripDetail from "./pages/trips/BuddhistPilgrimageTour/TripDetail";

import AnnapurnaCircuitTrekWithTilicho from "./pages/trips/AnnapurnaTilichoLakePoonHill/AnnapurnaTilichoLakePoonHill";
import AnnapurnaCircuitTrekWithTilichoInfo from "./pages/trips/AnnapurnaTilichoLakePoonHill/TripInfo";
import AnnapurnaCircuitTrekWithTilichoOverview from "./pages/trips/AnnapurnaTilichoLakePoonHill/Overview";
import AnnapurnaCircuitTrekWithTilichoItinerary from "./pages/trips/AnnapurnaTilichoLakePoonHill/Itinerary";
import AnnapurnaCircuitTrekWithTilichoTripDetail from "./pages/trips/AnnapurnaTilichoLakePoonHill/TripDetail";

import IslandPeakClimbWithEBC from "./pages/trips/IslandPeakClimbingWIthEBC/IslandPeakClimbingWithEBC";
import IslandPeakClimbWithEBCInfo from "./pages/trips/IslandPeakClimbingWIthEBC/TripInfo";
import IslandPeakClimbWithEBCOverview from "./pages/trips/IslandPeakClimbingWIthEBC/Overview";
import IslandPeakClimbWithEBCItinerary from "./pages/trips/IslandPeakClimbingWIthEBC/Itinerary";
import IslandPeakClimbWithEBCTripDetail from "./pages/trips/IslandPeakClimbingWIthEBC/TripDetail";

import MardiHimalTrek from "./pages/trips/MardiHimalTrek/MardiHimalTrek";
import MardiHimalTrekInfo from "./pages/trips/MardiHimalTrek/TripInfo";
import MardiHimalTrekOverview from "./pages/trips/MardiHimalTrek/Overview";
import MardiHimalTrekItinerary from "./pages/trips/MardiHimalTrek/Itinerary";
import MardiHimalTrekTripDetail from "./pages/trips/MardiHimalTrek/TripDetail";

import EBCHeliTrek from "./pages/trips/EverestBaseCampHeliTrek/EverestBaseCampHeliTrek";
import EBCHeliTrekInfo from "./pages/trips/EverestBaseCampHeliTrek/TripInfo";
import EBCHeliTrekOverview from "./pages/trips/EverestBaseCampHeliTrek/Overview";
import EBCHeliTrekItinerary from "./pages/trips/EverestBaseCampHeliTrek/Itinerary";
import EBCHeliTrekTripDetail from "./pages/trips/EverestBaseCampHeliTrek/TripDetail";

import MeraPeakClimbing from "./pages/trips/MeraPeakClimbing/MeraPeakClimbing";
import MeraPeakClimbingInfo from "./pages/trips/MeraPeakClimbing/TripInfo";
import MeraPeakClimbingOverview from "./pages/trips/MeraPeakClimbing/Overview";
import MeraPeakClimbingItinerary from "./pages/trips/MeraPeakClimbing/Itinerary";
import MeraPeakClimbingTripDetail from "./pages/trips/MeraPeakClimbing/TripDetail";

import EverestRegion from "./pages/Trekking Region/EverestRegion/EverestRegion";
import AnnapurnaRegion from "./pages/Trekking Region/AnnapurnaRegion/AnnapurnaRegion";
import TermsAndCondition from "./pages/TermsAndCondition";
import Santosh from "./pages/Team/Santosh";

import EverestHeliTourBlog from "../src/pages/components/Article/Articles/EverestHeliTourBlog";
import NoTreks from "../src/pages/components/Article/Articles/NoTreks";
import TopDestinations from "../src/pages/components/Article/Articles/TopDestinations";
import EssentialThingsToPack from "../src/pages/components/Article/Articles/EssentialThingsToPack";

import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/fontawesome/index";
import ScrollToTop from "./pages/components/ScrollToTop";
import { Helmet } from "react-helmet";
import TripBooking from "./pages/trips/EverestBaseCampTrek/TripBooking";
import TravelInsurance from "./pages/TravelInsurance";
import ArticlePage from "./pages/ArticlePage";
function App() {
  return (
    <>
      <Router>
        {/* <ScrollToTop /> */}
        <div className='nav'>
          <Nav />
        </div>

        <div className='main-body'>
          <Routes>
            <Route path='/' element={<HomePage />} exact />
            <Route path='about' element={<AboutPage />} exact />
            <Route path='contact' element={<ContactPage />} exact />

            {/* everest base camp trek routing starts */}
            <Route
              path='everest-base-camp-trek'
              element={<EverestBaseCampTrek />}
              exact
            >
              <Route path='info' element={EverestBaseCampTrekInfo} exact />
              <Route
                path='overview'
                element={EverestBaseCampTrekOverview}
                exact
              />
              <Route
                path='itinerary'
                element={EverestBaseCampTrekItinerary}
                exact
              />
              <Route
                path='detail'
                element={EverestBaseCampTrekTripDetail}
                exact
              />

              {/* <Route
                path="gallery"
                element={EverestBaseCampTrekGallery}
                exact
              /> */}
            </Route>
            {/* everest base camp trek routing ends */}

            {/* everest base camp heli tour routing starts */}
            <Route
              path='everest-base-camp-helicopter-tour'
              element={<EverestBaseCampHelicopter />}
              exact
            >
              <Route
                path='info'
                element={EverestBaseCampHelicopterInfo}
                exact
              />
              <Route
                path='overview'
                element={EverestBaseCampHelicopterOverview}
                exact
              />
              <Route
                path='itinerary'
                element={EverestBaseCampHelicopterItinerary}
                exact
              />
              <Route
                path='detail'
                element={EverestBaseCampHelicopterTripDetail}
                exact
              />
              <Route
                path='gallery'
                element={EverestBaseCampHelicopterGallery}
                exact
              />
            </Route>
            {/* everest base camp heli tour routing ends */}

            {/* annapurna base camp trek routing starts  */}
            <Route
              path='annapurna-base-camp-trek'
              element={<AnnapurnaBaseCampTrek />}
              exact
            >
              <Route path='info' element={AnnapurnaBaseCampTrekInfo} exact />
              <Route
                path='overview'
                element={AnnapurnaBaseCampTrekOverview}
                exact
              />
              <Route
                path='itinerary'
                element={AnnapurnaBaseCampTrekItinerary}
                exact
              />
              <Route
                path='detail'
                element={AnnapurnaBaseCampTrekTripDetail}
                exact
              />
              <Route
                path='gallery'
                element={EverestBaseCampHelicopterGallery}
                exact
              />
            </Route>
            {/* annapurna base camp trek routing ends  */}

            {/* buddhist pilgrimage tour routing starts */}
            <Route
              path='buddhist-pilgrimage-tour-7-days'
              element={<BuddhistPilgrimageTour />}
              exact
            >
              <Route path='info' element={BuddhistPilgrimageTourInfo} exact />
              <Route
                path='overview'
                element={BuddhistPilgrimageTourOverview}
                exact
              />
              <Route
                path='itinerary'
                element={BuddhistPilgrimageTourItinerary}
                exact
              />
              <Route
                path='detail'
                element={BuddhistPilgrimageTourTripDetail}
                exact
              />
              {/* <Route
                path="gallery"
                element={BuddhistPilgrimageTourGallery}
                exact
              /> */}
            </Route>
            {/* buddhist pilgrimage tour routing ends */}

            {/* manaslu circuit trek routing starts */}
            <Route
              path='manaslu-circuit-trek'
              element={<ManasluCircuitTrek />}
              exact
            >
              <Route path='info' element={ManasluCircuitTrekInfo} exact />
              <Route
                path='overview'
                element={ManasluCircuitTrekOverview}
                exact
              />
              <Route
                path='itinerary'
                element={ManasluCircuitTrekItinerary}
                exact
              />
              <Route
                path='detail'
                element={ManasluCircuitTrekTripDetail}
                exact
              />
              {/* <Route
                path="gallery"
                element={EverestBaseCampHelicopterGallery}
                exact
              /> */}
            </Route>
            {/* manaslu circuit trek routing ends */}

            {/* annapuran circuit with tilicho lake and poonhill routing starts */}
            <Route
              path='annapurna-circuit-with-tilicho-lake-and-poon-hill-17-days'
              element={<AnnapurnaCircuitTrekWithTilicho />}
              exact
            >
              <Route
                path='info'
                element={AnnapurnaCircuitTrekWithTilichoInfo}
                exact
              />
              <Route
                path='overview'
                element={AnnapurnaCircuitTrekWithTilichoOverview}
                exact
              />
              <Route
                path='itinerary'
                element={AnnapurnaCircuitTrekWithTilichoItinerary}
                exact
              />
              <Route
                path='detail'
                element={AnnapurnaCircuitTrekWithTilichoTripDetail}
                exact
              />
              {/* <Route
                path="gallery"
                element={AnnapurnaCircuitTrekWithTilichoGallery}
                exact
              /> */}
            </Route>
            {/* annapuran circuit with tilicho lake and poonhill routing ends */}

            {/* gokyo lake trek routing starts */}
            <Route
              path='gokyo-lake-trek-13-days'
              element={<GokyoLakeTrek />}
              exact
            >
              <Route path='info' element={GokyoLakeTrekInfo} exact />
              <Route path='overview' element={GokyoLakeTrekOverview} exact />
              <Route path='itinerary' element={GokyoLakeTrekItinerary} exact />
              <Route path='detail' element={GokyoLakeTrekTripDetail} exact />
              {/* <Route
                path="gallery"
                element={GokyoLakeTrekGallery}
                exact
              /> */}
            </Route>
            {/* gokyo lake trek routing ends */}

            {/* island peak climbing with ebc routing starts */}
            <Route
              path='island-peak-climbing-with-ebc-19-days'
              element={<IslandPeakClimbWithEBC />}
              exact
            >
              <Route path='info' element={IslandPeakClimbWithEBCInfo} exact />
              <Route
                path='overview'
                element={IslandPeakClimbWithEBCOverview}
                exact
              />
              <Route
                path='itinerary'
                element={IslandPeakClimbWithEBCItinerary}
                exact
              />
              <Route
                path='detail'
                element={IslandPeakClimbWithEBCTripDetail}
                exact
              />
              {/* <Route
                path="gallery"
                element={GokyoLakeTrekGallery}
                exact
              /> */}
            </Route>
            {/* island peak climbing with ebc routing ends */}

            {/* mardi himal terk 9 days routing starts */}
            <Route
              path='mardi-himal-base-camp-trek-9-days'
              element={<MardiHimalTrek />}
              exact
            >
              <Route path='info' element={MardiHimalTrekInfo} exact />
              <Route path='overview' element={MardiHimalTrekOverview} exact />
              <Route path='itinerary' element={MardiHimalTrekItinerary} exact />
              <Route path='detail' element={MardiHimalTrekTripDetail} exact />
              {/* <Route
                path="gallery"
                element={GokyoLakeTrekGallery}
                exact
              /> */}
            </Route>
            {/* mardi himal trek 9 days routing ends */}

            {/* mera peak climbing 18 days routing starts */}
            <Route
              path='mera-peak-climbing-18-days'
              element={<MeraPeakClimbing />}
              exact
            >
              <Route path='info' element={MeraPeakClimbingInfo} exact />
              <Route path='overview' element={MeraPeakClimbingOverview} exact />
              <Route
                path='itinerary'
                element={MeraPeakClimbingItinerary}
                exact
              />
              <Route path='detail' element={MeraPeakClimbingTripDetail} exact />
              {/* <Route
                path="gallery"
                element={GokyoLakeTrekGallery}
                exact
              /> */}
            </Route>
            {/* mera peak climbing 18 days routing ends */}

            {/* ebc heli trek routing starts */}
            <Route
              path='everest-base-camp-helicopter-return-trek-9-days'
              element={<EBCHeliTrek />}
              exact
            >
              <Route path='info' element={EBCHeliTrekInfo} exact />
              <Route path='overview' element={EBCHeliTrekOverview} exact />
              <Route path='itinerary' elemen={EBCHeliTrekItinerary} exact />
              <Route path='detail' element={EBCHeliTrekTripDetail} exact />
              {/* <Route
                path="gallery"
                element={GokyoLakeTrekGallery}
                exact
              /> */}
            </Route>
            {/* ebc heli trek routing ends */}

            <Route path='annapurna-region' element={<AnnapurnaRegion />} />
            <Route path='everest-region' element={<EverestRegion />} />
            <Route path='booking' element={<BookPage />} />
            <Route path='travel-insurance' element={<TravelInsurance />} />
            <Route path='travel-blog-and-news' element={<ArticlePage />} />
            <Route path='*' element={<ErrorPage />} />
            <Route path='*/' element={<ErrorPage />} />
            <Route
              path='termsandcondition'
              element={<TermsAndCondition />}
              exact
            />
            <Route path='santosh' element={<Santosh />} exact />
            <Route
              path='everest-heli-tour-blog'
              element={<EverestHeliTourBlog />}
            />
            <Route
              path='no-treks-in-nepal-are-allowed-without-a-guide'
              element={<NoTreks />}
            />
            <Route
              path='top-summer-destinations-for-2023-2024'
              element={<TopDestinations />}
            />
            <Route
              path='essential-things-to-pack-while-you-are-on-a-trek-in-himalayas'
              element={<EssentialThingsToPack />}
            />
            <Route path='trip-booking' element={<TripBooking />} />
          </Routes>
        </div>
      </Router>
    </>
  );
}
export default App;
