import React from "react";
import {
  FaMapMarkerAlt,
  FaBed,
  FaMapPin,
  FaCalendarAlt,
  FaMountain,
  FaUtensils,
  FaUsers,
  FaCloudSun,
  FaWalking,
  FaBusAlt,
  FaBinoculars,
  FaUserFriends,
} from "react-icons/fa";

const TripFact = () => {
  const facts = [
    {
      fact1: [
        {
          title: "Destination",
          subtitle: "Nepal",
          icon: <FaMapMarkerAlt />,
        },
      ],
      fact2: [
        {
          title: "Activity",
          subtitle: "Sightseeing",
          icon: <FaBinoculars />,
        },
      ],
      fact3: [
        {
          title: "Accommodation",
          subtitle: "3-Star Hotel",
          icon: <FaBed />,
        },
      ],
      fact4: [
        {
          title: "Styles",
          subtitle: "Family Holiday, School Travel",
          icon: <FaWalking />,
        },
      ],
      fact5: [
        {
          title: "Start/End Point",
          subtitle: "Kathmandu - Kathmandu",
          icon: <FaMapPin />,
        },
      ],
      fact6: [
        {
          title: "Duration",
          subtitle: "7 Days",
          icon: <FaCalendarAlt />,
        },
      ],
      fact7: [
        {
          title: "Max. Altitude",
          subtitle: "1,440 m (Kathmandu)",
          icon: <FaMountain />,
        },
      ],
      fact8: [
        {
          title: "Meals Included",
          subtitle: "Breakfast",
          icon: <FaUtensils />,
        },
      ],
      fact9: [
        {
          title: "Group Size",
          subtitle: "2-5",
          icon: <FaUsers />,
        },
      ],
      fact10: [
        {
          title: "Best Season",
          subtitle: "Throughout the year",
          icon: <FaCloudSun />,
        },
      ],
      fact11: [
        {
          title: "Tour Type",
          subtitle: "Guided Buddhist Sites Tour",
          icon: <FaUserFriends />,
        },
      ],
      fact12: [
        {
          title: "Transportation",
          subtitle: "Land & Air",
          icon: <FaBusAlt />,
        },
      ],
    },
  ];
  return (
    <>
      <div className="trip-fact">
        <h4 className="title">Trip Fact</h4>
        {facts &&
          facts.map((fact) => (
            <>
              {fact.fact1 &&
                fact.fact1.map((fact1) => (
                  <>
                    <div className="row">
                      <div className="col-12 col-md-6 col-lg-3 mt-3">
                        <div className="row d-flex align-items-center">
                          <div className="col-auto col-md-auto">
                            <div className="trip-fact-icon">{fact1.icon}</div>
                          </div>
                          <div className="col-10">
                            <div>
                              <strong>
                                <span className="trip-fact-title">
                                  {fact1.title}{" "}
                                </span>
                              </strong>
                              <br />
                              <span className="trip-fact-subtitle">
                                {fact1.subtitle}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-lg-3 mt-3">
                        {fact.fact2 &&
                          fact.fact2.map((fact2) => (
                            <div className="row d-flex align-items-center">
                              <div className="col-auto col-md-auto">
                                <div className="trip-fact-icon">
                                  {fact2.icon}
                                </div>
                              </div>
                              <div className="col-10">
                                <div>
                                  <strong>
                                    <span className="trip-fact-title">
                                      {fact2.title}{" "}
                                    </span>
                                  </strong>
                                  <br />
                                  <span className="trip-fact-subtitle">
                                    {fact2.subtitle}
                                  </span>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                      <div className="col-12 col-md-6 col-lg-3 mt-3">
                        {fact.fact3 &&
                          fact.fact3.map((fact3) => (
                            <div className="row d-flex align-items-center">
                              <div className="col-auto col-md-auto">
                                <div className="trip-fact-icon">
                                  {fact3.icon}
                                </div>
                              </div>
                              <div className="col-10">
                                <div>
                                  <strong>
                                    <span className="trip-fact-title">
                                      {fact3.title}{" "}
                                    </span>
                                  </strong>
                                  <br />
                                  <span className="trip-fact-subtitle">
                                    {fact3.subtitle}
                                  </span>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                      <div className="col-12 col-md-6 col-lg-3 mt-3">
                        {fact.fact4 &&
                          fact.fact4.map((fact4) => (
                            <div className="row d-flex align-items-center">
                              <div className="col-auto col-md-auto">
                                <div className="trip-fact-icon">
                                  {fact4.icon}
                                </div>
                              </div>
                              <div className="col-10">
                                <div>
                                  <strong>
                                    <span className="trip-fact-title">
                                      {fact4.title}{" "}
                                    </span>
                                  </strong>
                                  <br />
                                  <span className="trip-fact-subtitle">
                                    {fact4.subtitle}
                                  </span>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="row d-flex align-items-center">
                          <div className="col-12 col-md-6 col-lg-3 mt-3">
                            {fact.fact5 &&
                              fact.fact5.map((fact5) => (
                                <div className="row d-flex align-items-center">
                                  <div className="col-auto col-md-auto">
                                    <div className="trip-fact-icon">
                                      {fact5.icon}
                                    </div>
                                  </div>
                                  <div className="col-10">
                                    <div>
                                      <strong>
                                        <span className="trip-fact-title">
                                          {fact5.title}{" "}
                                        </span>
                                      </strong>
                                      <br />
                                      <span className="trip-fact-subtitle">
                                        {fact5.subtitle}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                          <div className="col-12 col-md-6 col-lg-3 mt-3">
                            {fact.fact6 &&
                              fact.fact6.map((fact6) => (
                                <div className="row d-flex align-items-center">
                                  <div className="col-auto col-md-auto">
                                    <div className="trip-fact-icon">
                                      {fact6.icon}
                                    </div>
                                  </div>
                                  <div className="col-10">
                                    <div>
                                      <strong>
                                        <span className="trip-fact-title">
                                          {fact6.title}{" "}
                                        </span>
                                      </strong>
                                      <br />
                                      <span className="trip-fact-subtitle">
                                        {fact6.subtitle}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                          <div className="col-12 col-md-6 col-lg-3 mt-3">
                            {fact.fact7 &&
                              fact.fact7.map((fact7) => (
                                <div className="row d-flex align-items-center">
                                  <div className="col-auto col-md-auto">
                                    <div className="trip-fact-icon">
                                      {fact7.icon}
                                    </div>
                                  </div>
                                  <div className="col-10">
                                    <div>
                                      <strong>
                                        <span className="trip-fact-title">
                                          {fact7.title}{" "}
                                        </span>
                                      </strong>
                                      <br />
                                      <span className="trip-fact-subtitle">
                                        {fact7.subtitle}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                          <div className="col-12 col-md-6 col-lg-3 mt-3">
                            {fact.fact8 &&
                              fact.fact8.map((fact8) => (
                                <div className="row d-flex align-items-center">
                                  <div className="col-auto col-md-auto">
                                    <div className="trip-fact-icon">
                                      {fact8.icon}
                                    </div>
                                  </div>
                                  <div className="col-10">
                                    <div>
                                      <strong>
                                        <span className="trip-fact-title">
                                          {fact8.title}{" "}
                                        </span>
                                      </strong>
                                      <br />
                                      <span className="trip-fact-subtitle">
                                        {fact8.subtitle}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="row d-flex align-items-center">
                          <div className="col-12 col-md-6 col-lg-3 mt-3">
                            {fact.fact9 &&
                              fact.fact9.map((fact9) => (
                                <div className="row d-flex align-items-center">
                                  <div className="col-auto col-md-auto">
                                    <div className="trip-fact-icon">
                                      {fact9.icon}
                                    </div>
                                  </div>
                                  <div className="col-10">
                                    <div>
                                      <strong>
                                        <span className="trip-fact-title">
                                          {fact9.title}{" "}
                                        </span>
                                      </strong>
                                      <br />
                                      <span className="trip-fact-subtitle">
                                        {fact9.subtitle}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                          <div className="col-12 col-md-6 col-lg-3 mt-3">
                            {fact.fact10 &&
                              fact.fact10.map((fact10) => (
                                <div className="row d-flex align-items-center">
                                  <div className="col-auto col-md-auto">
                                    <div className="trip-fact-icon">
                                      {fact10.icon}
                                    </div>
                                  </div>
                                  <div className="col-10">
                                    <div>
                                      <strong>
                                        <span className="trip-fact-title">
                                          {fact10.title}{" "}
                                        </span>
                                      </strong>
                                      <br />
                                      <span className="trip-fact-subtitle">
                                        {fact10.subtitle}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                          <div className="col-12 col-md-6 col-lg-3 mt-3">
                            {fact.fact11 &&
                              fact.fact11.map((fact11) => (
                                <div className="row d-flex align-items-center">
                                  <div className="col-auto col-md-auto">
                                    <div className="trip-fact-icon">
                                      {fact11.icon}
                                    </div>
                                  </div>
                                  <div className="col-10">
                                    <div>
                                      <strong>
                                        <span className="trip-fact-title">
                                          {fact11.title}{" "}
                                        </span>
                                      </strong>
                                      <br />
                                      <span className="trip-fact-subtitle">
                                        {fact11.subtitle}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                          <div className="col-12 col-md-6 col-lg-3 mt-3">
                            {fact.fact12 &&
                              fact.fact12.map((fact12) => (
                                <div className="row d-flex align-items-center">
                                  <div className="col-auto col-md-auto">
                                    <div className="trip-fact-icon">
                                      {fact12.icon}
                                    </div>
                                  </div>
                                  <div className="col-10">
                                    <div>
                                      <strong>
                                        <span className="trip-fact-title">
                                          {fact12.title}{" "}
                                        </span>
                                      </strong>
                                      <br />
                                      <span className="trip-fact-subtitle">
                                        {fact12.subtitle}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
            </>
          ))}
      </div>
    </>
  );
};
export default TripFact;

// const TripFact = (props) => {
//   const { tripFactInfo } = props;
//   const renderfacts = (fact, index) => {
//     return (
//       <>
//         <div className="row d-flex">
//           <div className="col-4">
//             <div className="row d-flex align-items-center justify-content-start">
//               <div className="col-auto trip-fact-icon">
//                 {fact.destinationIcon}
//               </div>
//               <div className="col-auto">
//                 <strong>{fact.destinationTitle}</strong>
//                 <br />
//                 {fact.destination}
//               </div>
//             </div>
//           </div>
//         </div>
//       </>
//     );
//   };

//   return <div>{tripFactInfo.map(renderfacts)}</div>;
// };
