import React from "react";
import { Col, Row } from "react-bootstrap";
import { FaMountain, FaUtensils, FaBed, FaHiking } from "react-icons/fa";
import { FaBus } from "react-icons/fa";
import { FaClock } from "react-icons/fa";

import "../../../custom-sass/pages/trip/_trip-detail.scss";
import Map from "../../../image/Map/annapurnaBaseCampTrekMap.jpg";
import Pdf from "../../../itineraryDocuments/AnapurnaRegion/AnnapurnaBaseCampTrek.pdf";

const Itinerary = () => {
  const days = [
    {
      day1: [
        {
          title: "Day 1: Arrival in Kathmandu (1400m) and transfer to the hotel",
          caption:
            "When you get to Tribhuvan International Airport in Kathmandu, Adventure Master representatives will meet you there and take you to your hotel. Since we do not know when you will arrive, we left this day open. You can take it easy and then come see us at our office later. We will tell you about the trek and introduce you to the guide. In the evening, we will pack for the trip.",
          tripDetail: [
            // {
            //   icon: "FaMountain",
            //   detailTitle: "Maximum Altitude",
            //   detailCaption: "1400m",
            // },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "Null",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Hotel",
            },
          ],
        },
      ],
      day2: [
        {
          title: "Day 2: Drive from Kathmandu to Pokhara (822m)",
          caption:
            "We will drive to Pokhara on day 2 of our journey. This city is an hour away from the place where the trek to Annapurna's base camp starts. Early in the morning, we leave Kathmandu and drive along the beautiful Trishuli and Marsyangdi rivers. Along the way, we see rock mountains, green hills, and waterfalls. The ride is easy and beautiful.",
          tripDetail: [
            {
              icon: "FaBus",
              detailTitle: "Trek Distance",
              detailCaption: "205 km/127 miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "6-7 hours",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B,L",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Hotel",
            },
          ],
        },
      ],
      day3: [
        {
          title:
            "Day 3: Drive from Pokhara to Nayapul (1,070 m) and trek to Ulleri (1,960 m/5,430 ft) via Tikhedhunga)",
          caption:
            "We start the day with a tasty breakfast and then put our bags in the car. We drive along a quiet road until we reach Nayapul (1,070 m/3,510 ft). We can stop here or drive on to Tikhedhunga (1,540 m/5,052 ft), which is also an option. The ride is smooth up to Birethanti (1,025 m/3,362 ft), but from there it is off-road all the way to Tikhedhunga. As soon as we reach Tikhedhunga, we start walking to Ulleri. We get our ACAP permits at Birethanti, and then we walk through forest paths and small settlements that are far away from each other.",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Trek Distance",
              detailCaption: "7 km/4.34 miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "1.5/2 hours drive & 3/4 hours trek",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B,L,D",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Lodge",
            },
          ],
        },
      ],
      day4: [
        {
          title: "Day 4: Trek from Ulleri to Ghorepani(2,860 m/9,385 ft)",
          caption:
            "When we leave Ulleri village, we walk uphill to Banthanti, which is 2,210 m (7,250 ft) above sea level and has beautiful views of Annapurna South and Hiunchuli. We walk through rhododendron forests from here. On the way, we might see animals and birds. The trail then follows the Bhurungdi Khola to Nanggethanti, which is 2,430 meters (7,972 feet) away. From here, our next stop will be the lovely village of Ghorepani. From the village of Ghorepani, there are great views of snow-capped mountains like Dhaulagiri, Tukuche, Nilgiri, Dhampus Peak, Annapurna South, Fishtail, etc.",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Trek Distance",
              detailCaption: "11 km/6 miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "3/4 hours",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B,L,D",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Lodge",
            },
          ],
        },
      ],
      day5: [
        {
          title:
            "Day 5: Early morning hike to Poon Hill viewpoint (3,210 m/10,525 ft), then trek to Tadapani (2,630 m/8,630 ft)",
          caption:
            "Early in the morning, we will take a side trip to Poon Hill to see a beautiful sunrise over the Himalayan mountains. From the viewpoint, you can see Nilgiri, Fishtail, the Manaslu range, the Dhaulagiri range, the Annapurna range, and more. Later, we will walk back to the village of Ghorepani and then down through thick forests to Deurali, which is 3,106 meters (10,190 feet) away. From here, the trail goes over three bridges and ends at BanThanti, which is 3,180 m (10,433 ft) away. From here, it is a short trip down to Tadapani.",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Trek Distance",
              detailCaption: "11.50 km/7.1 miles  ",
            },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "5 hours",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B,L,D",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Lodge",
            },
          ],
        },
      ],
      day6: [
        {
          title: "Day 6: Hike from Tadapani to Chomrong, which is 2,165 m (7,105 ft) ",
          caption:
            "We will walk by a few teahouses on the way from Tadapani. The trail crosses a bridge over Kimrong Khola, and then we go down to Kimrong village (1,800 m/5,905 ft). As we walk on, we get closer and closer to Chomrong Pass and then hike to Chomrong Village. On the way, the views of Annapurna and Fishtail are great.",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Trek Distance",
              detailCaption: "10 km/6.2 miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "5 hours",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B,L,D",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Lodge",
            },
          ],
        },
      ],
      day7: [
        {
          title: "Day 7: Hike from Chomrong to Himalaya, which is 2,920 m (9,580 ft)",
          caption:
            "We leave Chomrong and walk on a path that goes uphill until we reach a bridge. The path goes up slowly until it reaches Sinuwa (2,360 m/7,742 ft). We walk through rhododendron and oak forests along the Modi Khola river. Soon, we'll reach Khuldighar (2,540 m/8,333 ft) and then go down to Bamboo (2,310 m/7,578 ft). From here, we walk to a suspension bridge that crosses Modi Khola. The path goes up to Dovan (2,600 m/8,530 ft), and then we pass the Pujinim Barah temple on our way to Himalaya (2,920 m/9,580 ft).",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Trek Distace",
              detailCaption: "12 km/7.4 miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "6/7 hours",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B,L,D",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Lodge",
            },
          ],
        },
      ],
      day8: [
        {
          title:
            "Day 8: Trek from Himalaya to Annapurna Base Camp (4,130 m/13,550 ft) via Machapuchare Base Camp",
          caption:
            "The trail goes up from the Himalaya to Hinku Cave, which is 3,170 m (10,400 ft) away. We will walk close to Modi Khola to get there. We will soon get to a place called Deurali, which is 3,230 m (10,597 ft) high. We walk by a dangerous area where avalanches are likely to happen as we cross a couple of bridges on the Modi Khola. After crossing one more bridge, we reach the base camp for Machhapuchhre at 3,700 m (12,139 ft).",
          caption1:
            "From here, the path goes to the base camp for Annapurna. On this part of the trail, we will walk next to the south Annapurna glacier. All along the trail, you can see Annapurna South, Mardi Himal, Gangaurna, Hiunchuli, Annapurna I, Annapurna III, Tent Peak, etc.",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Trek Distance",
              detailCaption: "12.5 km/7.7 miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "5 hours",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B,L,D",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Lodge",
            },
          ],
        },
      ],
      day9: [
        {
          title:
            "DAY 9: Trek from Annapurna Base Camp to Bamboo (2,310 m/7,578 ft)",
          caption:
            "After a night at the base camp, we will walk back to Bamboo along the same path. The path goes down through areas that are prone to avalanches and over a couple of bridges until it reaches the Himalaya (2,920 m/9,580 ft). Then we keep going down along Modi Khola until we reach Dovan at 2,600 m (8,530 ft). We will walk back to Bamboo for the night, crossing a suspension bridge on the way.",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Trek Distance",
              detailCaption: "14 km/8.6 miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "6 hours",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B,L,D",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Lodge",
            },
          ],
        },
      ],
      day10: [
        {
          title: "DAY 10: Trek from Bamboo to Jhinu Danda (1,780 m/6,393 ft)",
          caption:
            "The path goes down to Khuldighar, which is 2,540 m (8,333 ft) away. We will walk along the Modi Khola and then go down to Sinuwa, which is 2,360 m (7,742 ft) away. As the path goes down, it crosses a bridge over Chomrong Khla. Then, we walk in a zigzag pattern until we reach Jhinu Danda. On this part of the trail, there are also oak and rhododendron forests. At Jhinu Danda, we can pay a few bucks to use the natural hot springs.",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Trek Distance",
              detailCaption: "8.5 km/5.2 miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "3/4 hours",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B,L,D",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Lodge",
            },
          ],
        },
      ],
      day11: [
        {
          title:
            "DAY 11: Trek to Siwa/Ghandruk Phedi (1,150 m/3,770 ft) and drive to Pokhara (820 m) via Nayapul",
          caption:
            "After leaving Jhinu Danda, we travel to the village of Ghandruk, which is located at an altitude of 1,940 meters (6,364 feet), along a route that gradually descends. From this point on, the hike leads down to Kimche, which is located 1,640 meters (5,380 feet) below. We will be traveling on foot via the wooded path that runs parallel to the Modi Khola. Syauli Bazar is located at an elevation of 1,220 meters (4,002 feet), and Chimrong is located at an elevation of 1,130 meters (3,707 feet). As we continue to travel downward, we will reach Birethanti (1,025 m/3,362 ft) just before we arrive in Nayapul (1,070 m/3,510 ft). From Nayapul, Pokhara may be reached in a short amount of time by car.",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Trek Distance",
              detailCaption: "4 km/2.4 miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "1.5/2 hours drive & 3 hours trek",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B,L",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Hotel",
            },
          ],
        },
      ],
      day12: [
        {
          title: "DAY 12: Drive from Pokhara to Kathmandu",
          caption:
            "This morning, we will begin our journey back to Kathmandu by car. Like it was the last time, the journey from Pokhara to Kathmandu is relaxing and filled with beautiful scenery. We will drop you off at the hotel after we have arrived in Kathmandu. You should spend the entire day browsing the local market and window shopping in Thamel. You can expect to see us for a goodbye meal late in the evening.",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Trek Distance",
              detailCaption: "205 km/127 miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "6/7 hours",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B,L & Farewell Dinner",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Lodge",
            },
          ],
        },
      ],
      day13: [
        {
          title: "DAY 13: Final Departure",
          caption:
            "The journey you took with us has come to a conclusion. You have the option of extending your vacation and joining one of our other exciting trips in the Himalayas. In that case, our airport staff will handle your departure from the airport in accordance with the departure time of your flight.",
          // tripDetail: [
          //   {
          //     icon: "FaClock",
          //     detailTitle: "Flight Duration",
          //     detailCaption: "35 minutes flight",
          //   },
          //   {
          //     icon: "fa fa-meals",
          //     detailTitle: "Meals",
          //     detailCaption: "Breakfaast & farewell dinner",
          //   },
          //   {
          //     icon: "fa fa-bed",
          //     detailTitle: "Accommodation",
          //     detailCaption: "Hotel",
          //   },
          // ],
        },
      ],
    },
  ];

  return (
    <>
      <div className="detail-itinerary container mt-5">
        <div className="row">
          <div className="d-lg-none mb-5">
            <h4 className="title">Trekking Route Map</h4>
            <img
              src={Map}
              alt="annapurna base camp trek map"
              style={{ width: "100%" }}
            />
            <button type="button" className="btn btn-dark mt-3 col-12">
                <a href={Pdf} target="_blank" className="text-light">
                  Download Itinerary
                </a>
              </button>
          </div>
          <div className="col-md-12 col-lg-8">
            {days &&
              days.map((day) => (
                <>
                  {day.day1 &&
                    day.day1.map((day1) => (
                      <>
                        <h4 className="itinerary-title">{day1.title}</h4>
                        <div className="row">
                          {day1.tripDetail &&
                            day1.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-auto"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day1.caption}
                        </div>
                      </>
                    ))}

                  {day.day2 &&
                    day.day2.map((day2) => (
                      <>
                        <h4 className="itinerary-title">{day2.title}</h4>
                        <div className="row">
                          {day2.tripDetail &&
                            day2.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaBus" && (
                                        <FaBus fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-autp"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day2.caption}
                        </div>
                      </>
                    ))}
                  {day.day3 &&
                    day.day3.map((day3) => (
                      <>
                        <h4 className="itinerary-title">{day3.title}</h4>
                        <div className="row">
                          {day3.tripDetail &&
                            day3.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-autp"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day3.caption}
                        </div>
                      </>
                    ))}
                  {day.day4 &&
                    day.day4.map((day4) => (
                      <>
                        <h4 className="itinerary-title">{day4.title}</h4>
                        <div className="row">
                          {day4.tripDetail &&
                            day4.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-autp"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day4.caption}
                        </div>
                      </>
                    ))}
                  {day.day5 &&
                    day.day5.map((day5) => (
                      <>
                        <h4 className="itinerary-title">{day5.title}</h4>
                        <div className="row">
                          {day5.tripDetail &&
                            day5.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-autp"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day5.caption}
                        </div>
                      </>
                    ))}
                  {day.day6 &&
                    day.day6.map((day6) => (
                      <>
                        <h4 className="itinerary-title">{day6.title}</h4>
                        <div className="row">
                          {day6.tripDetail &&
                            day6.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-autp"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day6.caption}
                        </div>
                      </>
                    ))}
                  {day.day7 &&
                    day.day7.map((day7) => (
                      <>
                        <h4 className="itinerary-title">{day7.title}</h4>
                        <div className="row">
                          {day7.tripDetail &&
                            day7.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaBus" && (
                                        <FaBus fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-autp"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day7.caption}
                        </div>
                      </>
                    ))}
                  {day.day8 &&
                    day.day8.map((day8) => (
                      <>
                        <h4 className="itinerary-title">{day8.title}</h4>
                        <div className="row">
                          {day8.tripDetail &&
                            day8.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-autp"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day8.caption}
                          <br />
                          {day8.caption1}
                        </div>
                      </>
                    ))}
                  {day.day9 &&
                    day.day9.map((day9) => (
                      <>
                        <h4 className="itinerary-title">{day9.title}</h4>
                        <div className="row">
                          {day9.tripDetail &&
                            day9.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-autp"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day9.caption}
                        </div>
                      </>
                    ))}
                  {day.day10 &&
                    day.day10.map((day10) => (
                      <>
                        <h4 className="itinerary-title">{day10.title}</h4>
                        <div className="row">
                          {day10.tripDetail &&
                            day10.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-autp"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day10.caption}
                        </div>
                      </>
                    ))}
                  {day.day11 &&
                    day.day11.map((day11) => (
                      <>
                        <h4 className="itinerary-title">{day11.title}</h4>
                        <div className="row">
                          {day11.tripDetail &&
                            day11.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-autp"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day11.caption}
                        </div>
                      </>
                    ))}
                  {day.day12 &&
                    day.day12.map((day12) => (
                      <>
                        <h4 className="itinerary-title">{day12.title}</h4>
                        <div className="row">
                          {day12.tripDetail &&
                            day12.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-autp"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day12.caption}
                        </div>
                      </>
                    ))}
                  {day.day13 &&
                    day.day13.map((day13) => (
                      <>
                        <h4 className="itinerary-title">{day13.title}</h4>
                        <div className="row">
                          {day13.tripDetail &&
                            day13.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-autp"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day13.caption}
                        </div>
                      </>
                    ))}
                </>
              ))}
          </div>

          <div className="main-modal-map d-none d-lg-flex">
            <div className="modal-map d-flex flex-column">
              <h4 className="title">Trekking Route Map</h4>
              <img src={Map} alt="annapurna base camp trek map" />
              <button type="button" className="btn btn-dark mt-3 col-12">
                <a href={Pdf} target="_blank" className="text-light">
                  Download Budget Itinerary
                </a>
              </button>
              <button type="button" className="btn btn-outline btn-dark mt-3 col-12">
                <a href={Pdf} target="_blank" className="text-light">
                  Download Luxury Itinerary
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Itinerary;
