import React, { useState } from "react";
import {
  AiOutlineArrowUp,
  AiOutlineArrowDown,
  AiOutlineCloseCircle,
} from "react-icons/ai";
import { HashLink } from "react-router-hash-link";
import EBCDepartureDateModalTripInfo from "../../components/DepartureDateModal/DepartureDateForTripInfo/EBCDepartureDateModalTripInfo";

const Modals = (props) => {
  const { tripTitle, tripPrice, sendmodaldetails } = props;
  const { id, modalhead, modaldetail } = sendmodaldetails;
  const [expandModal, setExpandModal] = useState(false);

  const modaldetailtrip = [
    {
      id: 1,
      modaldetail: [
        {
          modaltitle: "Difficulty Level:",
          modaltext:
            "Everest base camp trail is considered moderate. As you spend almost 11 days in the remote mountains, the challenges only add up. Yes, there are comfortable lodges and services that make the journey somewhat bearable, but this is not the lifestyle we are used to. So, the trek only gets difficult as it progresses. ",
        },
        // {
        //   modaltitle: "Terrain:",
        //   modaltext:
        //     "The Everest base camp trekking route has a blend of plain and rough paths. The first steep ascent you encounter is while approaching Namche Bazaar from Phakding. From here, there are many steep ups & downs throughout the trek. Unless the trail goes beyond the treeline, you walk through dense alpine woodlands and along the verdant foothills. The trail crosses many thrilling suspension and old wooden bridges over rushing rivers & streams. A final couple of days before reaching EBC involves a glacial walk and passes by a narrow moraine.",
        // },
        // {
        //   modaltitle: "Altitude Sickness:",
        //   modaltext:
        //     "Altitude sickness is an illness seen during high-altitude ventures. It's common, at the same time avoidable and treatable. Flying from Kathmandu to Lukla airport itself brings a drastic change in the elevation in a short time. Therefore, we descend to Phakding village on the same day we fly to acclimatize our bodies. Likewise, we have two acclimatization days in our itinerary that provides ample time for trekkers to adjust to the elevation. The acclimatization days are well used by hiking to a high point and returning to the same village to sleep low. We also make sure the group does not ascend more than 500 meters a day.",
        // },
        // {
        //   modaltitle: "Team:",
        //   modaltext:
        //     "Our Adventure Master team is composed of the most experienced trekking leaders and porters who know the Everest base camp trek route inside-out. We are your map on the trail that not only shows the path but also tells all the stories & myths encircling the trail. We help you hone your trekking and survival skills amidst the mountains of the Himalayas.",
        // },
        // {
        //   modaltitle: "Support:",
        //   modaltext:
        //     "Our Everest base camp trekking package has everything that makes the overall trip hassle-free for our guests. Likewise, our team obtains all the permits on your behalf and arranges all other things. We do all the bookings in Kathmandu and along the EBC trail in advance. The porters carry all the heavy luggage during trekking. We are committed to offering safe and satisfying treks in the Himalayas.",
        // },
        // {
        //   modaltitle: "Equipment:",
        //   modaltext:
        //     "Some of the major trekking equipment you need during the Everest base camp trek are a trekking pole, trekking boots, trekking clothes, sleeping bag, pocket torch, toiletries, snacks, etc. You can bring all the gear from your home if you want, but we highly recommend you buy or rent in Thamel, Kathmandu, instead. It'll save you a lot of baggage. Go through the checklist provided once the trip is confirmed and pack according to that.",
        // },
      ],
    },
    {
      id: 2,
      modaldetail: [
        {
          modaltitle: "Khumbu Region:",
          modaltext:
            "First of all, the Khumbu region is also known as the Everest region because it houses Mt. Everest. In Nepal, the Khumbu region is located northeast of the country and is part of the Solukhumbu district. The elevation ranges from 2,860 meters (Lukla) to 8,849 meters (top of Mt. Everest). The region has a protected area called Sagarmatha National Park that begins at Monjo and goes up to Everest. Lukla, Phakding, Namche Bazaar, Thame, Phortse, Tengboche, Pangboche, Dingboche, Lobuche, and Goraks Shep are some major settlement areas.",
        },
        {
          modaltitle: "Difficulty Level:",
          modaltext:
            "Everest base camp trail is considered moderate. As you spend almost 11 days in the remote mountains, the challenges only add up. Yes, there are comfortable lodges and services that make the journey somewhat bearable, but this is not the lifestyle we are used to. So, the trek only gets difficult as it progresses. ",
        },
        {
          modaltitle: "Terrain:",
          modaltext:
            "The Everest base camp trekking route has a blend of plain and rough paths. The first steep ascent you encounter is while approaching Namche Bazaar from Phakding. From here, there are many steep ups & downs throughout the trek. Unless the trail goes beyond the treeline, you walk through dense alpine woodlands and along the verdant foothills. The trail crosses many thrilling suspension and old wooden bridges over rushing rivers & streams. A final couple of days before reaching EBC involves a glacial walk and passes by a narrow moraine.",
        },
        {
          modaltitle: "Altitude Sickness:",
          modaltext:
            "Altitude sickness is an illness seen during high-altitude ventures. It's common, at the same time avoidable and treatable. Flying from Kathmandu to Lukla airport itself brings a drastic change in the elevation in a short time. Therefore, we descend to Phakding village on the same day we fly to acclimatize our bodies. Likewise, we have two acclimatization days in our itinerary that provides ample time for trekkers to adjust to the elevation. The acclimatization days are well used by hiking to a high point and returning to the same village to sleep low. We also make sure the group does not ascend more than 500 meters a day.",
        },
        {
          modaltitle: "Team:",
          modaltext:
            "Our Adventure Master team is composed of the most experienced trekking leaders and porters who know the Everest base camp trek route inside-out. We are your map on the trail that not only shows the path but also tells all the stories & myths encircling the trail. We help you hone your trekking and survival skills amidst the mountains of the Himalayas.",
        },
        {
          modaltitle: "Support:",
          modaltext:
            "Our Everest base camp trekking package has everything that makes the overall trip hassle-free for our guests. Likewise, our team obtains all the permits on your behalf and arranges all other things. We do all the bookings in Kathmandu and along the EBC trail in advance. The porters carry all the heavy luggage during trekking. We are committed to offering safe and satisfying treks in the Himalayas.",
        },
        {
          modaltitle: "Equipment:",
          modaltext:
            "Some of the major trekking equipment you need during the Everest base camp trek are a trekking pole, trekking boots, trekking clothes, sleeping bag, pocket torch, toiletries, snacks, etc. You can bring all the gear from your home if you want, but we highly recommend you buy or rent in Thamel, Kathmandu, instead. It'll save you a lot of baggage. Go through the checklist provided once the trip is confirmed and pack according to that.",
        },
      ],
    },
    {
      id: 3,
      modaldetail: [
        {
          modaltitle: "Facilities:",
          modaltext:
            "The facilities in the lodges are limited. The rooms are clean but small. The interior is very simple. You can get additional paid services not covered in the package cost. If you want, you can upgrade the package to luxury and get all the below services for free with bigger rooms, a private washroom, and excellent space.",
        },
        {
          modaltitle: "Single room:",
          modaltext:
            "Single rooms are on the little expensive side. You need to inform us in advance to book a single room along the trail. The price of the package may change slightly if you choose to stay in a single room.",
        },
        {
          modaltitle: "Hot water:",
          modaltext:
            "Hot water for showers is available on paid in most of the villages. If you want to take a hot shower, you can ask for it in the lodge. The charge for the service is not included in the package cost, so you have to bear it by yourself. Carry some extra Nepali cash with you for service like this. ",
        },
        {
          modaltitle: "Charging:",
          modaltext:
            "There are no electric outlets in the rooms. The charging station is built around the reception area, where trekkers are allowed to charge their devices by paying a few extra bucks. Yes, everything costs money in the Himalayas. You can carry spare batteries or a solar charger.",
        },
        {
          modaltitle: "WiFi:",
          modaltext:
            "WiFi is available in villages like Phakding, Namche Bazaar, Tengboche, etc. Of course, it's also paid. The connection may not be stable all the time because of high altitude and weather disturbances. It's better to use a Nepali sim card and use cellular data for the internet. This is a much more cost-effective option, gives you the chance to use the Internet while on the trail, and the network is better too. ",
        },
      ],
    },
    {
      id: 4,
      modaldetail: [
        {
          modaltitle: "Cuisine:",
          modaltext:
            "In Kathmandu, you'll get cuisine from around the world. Fine dining restaurants have done an excellent job in curating world-class cuisines. The EBC trail has a decent variety of food options that also includes pizza, burger, pasta, steak, soup, and bakery items in some villages like Lukla, Phakding, Namche Bazaar, and Tengboche. ",
        },
        {
          modaltitle: "Nepali Flavors:",
          modaltext:
            "You cannot leave Nepal without tasting some of its famous delicacies like the oh-so Nepali pizza called chatamari. Likewise, some other dishes we recommend you try are yomori, momo, sekuwa, sukuti, taas, Newari khaja set, chyang, etc. ",
        },
        {
          modaltitle: "Super Food:",
          modaltext:
            "The staple meal during the trek will be dal bhat tarkari. It means rice, lentil soup, and veggies with some other side dishes. Dal bhat is the staple Nepali meal and also provides all the nutrients. While trekking, this is one of the freshest items you can get on the menu and at a reasonable price. Dal bhat will not give you indigestion or bloating issues.",
        },
        {
          modaltitle: "Suitability:",
          modaltext:
            "There are plenty of options for vegetarians, vegans, and gluten-free diners.",
        },
      ],
    },
    {
      id: 5,
      modaldetail: [
        // {
        //   modaltitle: "Khumbu Region:",
        //   modaltext:
        //     "First of all, the Khumbu region is also known as the Everest region because it houses Mt. Everest. In Nepal, the Khumbu region is located northeast of the country and is part of the Solukhumbu district. The elevation ranges from 2,860 meters (Lukla) to 8,849 meters (top of Mt. Everest). The region has a protected area called Sagarmatha National Park that begins at Monjo and goes up to Everest. Lukla, Phakding, Namche Bazaar, Thame, Phortse, Tengboche, Pangboche, Dingboche, Lobuche, and Goraks Shep are some major settlement areas.",
        // },
        // {
        //   modaltitle: "Difficulty Level:",
        //   modaltext:
        //     "Everest base camp trail is considered moderate. As you spend almost 11 days in the remote mountains, the challenges only add up. Yes, there are comfortable lodges and services that make the journey somewhat bearable, but this is not the lifestyle we are used to. So, the trek only gets difficult as it progresses. ",
        // },
        // {
        //   modaltitle: "Terrain:",
        //   modaltext:
        //     "The Everest base camp trekking route has a blend of plain and rough paths. The first steep ascent you encounter is while approaching Namche Bazaar from Phakding. From here, there are many steep ups & downs throughout the trek. Unless the trail goes beyond the treeline, you walk through dense alpine woodlands and along the verdant foothills. The trail crosses many thrilling suspension and old wooden bridges over rushing rivers & streams. A final couple of days before reaching EBC involves a glacial walk and passes by a narrow moraine.",
        // },
        // {
        //   modaltitle: "Altitude Sickness:",
        //   modaltext:
        //     "Altitude sickness is an illness seen during high-altitude ventures. It's common, at the same time avoidable and treatable. Flying from Kathmandu to Lukla airport itself brings a drastic change in the elevation in a short time. Therefore, we descend to Phakding village on the same day we fly to acclimatize our bodies. Likewise, we have two acclimatization days in our itinerary that provides ample time for trekkers to adjust to the elevation. The acclimatization days are well used by hiking to a high point and returning to the same village to sleep low. We also make sure the group does not ascend more than 500 meters a day.",
        // },
        // {
        //   modaltitle: "Team:",
        //   modaltext:
        //     "Our Adventure Master team is composed of the most experienced trekking leaders and porters who know the Everest base camp trek route inside-out. We are your map on the trail that not only shows the path but also tells all the stories & myths encircling the trail. We help you hone your trekking and survival skills amidst the mountains of the Himalayas.",
        // },
        // {
        //   modaltitle: "Support:",
        //   modaltext:
        //     "Our Everest base camp trekking package has everything that makes the overall trip hassle-free for our guests. Likewise, our team obtains all the permits on your behalf and arranges all other things. We do all the bookings in Kathmandu and along the EBC trail in advance. The porters carry all the heavy luggage during trekking. We are committed to offering safe and satisfying treks in the Himalayas.",
        // },
        // {
        //   modaltitle: "Equipment:",
        //   modaltext:
        //     "Some of the major trekking equipment you need during the Everest base camp trek are a trekking pole, trekking boots, trekking clothes, sleeping bag, pocket torch, toiletries, snacks, etc. You can bring all the gear from your home if you want, but we highly recommend you buy or rent in Thamel, Kathmandu, instead. It'll save you a lot of baggage. Go through the checklist provided once the trip is confirmed and pack according to that.",
        // },
      ],
    },
    {
      id: 6,
      modaldetail: [
        {
          modaltitle: "Overview :",
          modaltext:
            "Everest base camp trek 14 days is one of the most famous and best Everest base camp trek packages. This itinerary has ample acclimatization days and tempting night stops. Sufficed with the bustle of travelers from all around the world and the serenity of nature, the Everest base camp trek provides a very gratifying experience. You get to challenge your strength and unlock a lifelong achievement. Learn about your inner self as you unwind and make new friends. Walk past forests, bridges, moraines, and glaciers. ",
        },
        {
          modaltitle: "Restaurants & Tea Shops:",
          modaltext:
            "In villages like Lukla, Phakding, Namche Bazaar, Tengboche, and Dingboche, there are many cafes & restaurants. The Everest base camp trekking route has numerous tea shops too, where you can buy refreshments. Likewise, in Namche Bazaar, there are book shops, souvenir shops, gear shops, etc.",
        },
        {
          modaltitle: "ATM/Currency :",
          modaltext:
            "ATM is only available in Namche Bazaar. You can withdraw Nepali currency here to use while trekking. Most of the lodges and tea shops do not accept cards or other currency, so carry ample Nepali cash. ",
        },
        {
          modaltitle: "Internet:",
          modaltext:
            "Paid WiFi is available in villages at lower elevations. The connection fluctuates a lot because of the weather and remoteness of the region. The Nepali telecommunication company Ncell offers a somewhat decent network up to the Everest base camp. We'll help you get a Nepali sim with data to use the internet on the EBC trail. ",
        },
        {
          modaltitle: "Flora + Fauna:",
          modaltext:
            "The trekking route goes through lush forests of rhododendron, pine, fir, oak, birch, etc. You'll come across a range of alpine vegetation and wildlife along with different species of birds on the way to EBC. Dingboche onwards, the trail leaves the treeline and passes by meadows & bushes before joining the glacial & rocky paths.",
        },
        {
          modaltitle: "Locals:",
          modaltext:
            "The Khumbu region is resided by people from different ethnic groups like Rai, Bhotiya & Limbu, along with various clans of Sherpas. They are very friendly and kind to travelers. With a range of cultures, customs & values, you get to see their lifestyle and daily chores.",
        },
        {
          modaltitle: "Language:",
          modaltext:
            "The locals speak different languages. There are many dialects of the Tibetan language. Most of the lodge owners and tea shop owners understand & speak English, so you'll have no issues communicating.",
        },
        {
          modaltitle: "Monasteries:",
          modaltext:
            "Tengboche monastery is the biggest monastery, whereas Pangboche and Khumjung monasteries are one of the oldest monasteries in the Khumbu region. There are many other small stupas, chortens, prayer wheels, sculpted rocks, artifacts, and prayer flags throughout the route. ",
        },
      ],
    },
  ];
  const expandHandler = () => {
    setExpandModal(!expandModal);
  };

  const collapseHandler = () => {
    setExpandModal(false);
  };

  const onTripChange = () => {
    sessionStorage.removeItem("tripTitle");
    sessionStorage.removeItem("tripPrice");
    sessionStorage.setItem("tripTitle", tripTitle);
    sessionStorage.setItem("tripPrice", tripPrice);
  };

  return (
    <>
      <div className={`modals ${expandModal ? "expand" : ""}`}>
        <div className={`modal-head `} onClick={expandHandler}>
          <div className="modal-head-title">
            {!expandModal ? (
              <AiOutlineArrowUp fontSize="2rem" className="px-1" />
            ) : (
              <AiOutlineArrowDown fontSize="2rem" className="px-1" />
            )}
            <div className="head-title">{modalhead}</div>
          </div>
          <div className="iclose">
            {expandModal && (
              <AiOutlineCloseCircle
                fontSize="1.3rem"
                onClick={collapseHandler}
              />
            )}
          </div>
        </div>
        <div className={`modal-body ${expandModal ? "modal-body-expand" : ""}`}>
          {!expandModal ? (
            <>
              {modaldetail &&
                modaldetail?.map((detail) => (
                  <div className="modal-content">
                    <div className="modal-title d-none d-sm-flex">
                      {detail.modaltitle}
                    </div>
                    <div className="modal-text">{detail.modaltext}</div>
                  </div>
                ))}
            </>
          ) : (
            <>
              {modaldetailtrip &&
                modaldetailtrip[id - 1]?.modaldetail?.map((detail) => (
                  <div className="modal-content">
                    <div className="modal-title fw-bold">
                      {detail.modaltitle}
                    </div>
                    <div className="modal-text">{detail.modaltext}</div>
                  </div>
                ))}
            </>
          )}
        </div>
        <div className="modal-book">
          <HashLink
            smooth
            to="/booking"
            className="modal-book link p-0"
            style={{ color: "#000" }}
            onClick={onTripChange}
          >
            BOOK NOW
          </HashLink>
          {/* <EBCDepartureDateModalTripInfo /> */}
        </div>
      </div>
    </>
  );
};

export default Modals;
