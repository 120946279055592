import React from "react";
import ArticleCards from "./ArticleCards";
import { Container } from "react-bootstrap";
import Footer from "../Footer";

export default function Article() {
  return (
    <>
      <div className="article-section">
        <Container>
          <h3 className=" text-center pb-5">Recent Blogs & News</h3>
          <ArticleCards />
        </Container>
      </div>
      {/* <Footer /> */}
    </>
  );
}
