import HelmetExport, { Helmet } from "react-helmet";

const TripAdvisorReview = () => {
  return (
    // <div
    //   id="TA_selfserveprop809"
    //   class="TA_selfserveprop tripadvisor-widget-custom"
    // >
    //   <ul id="FGPnkShx68" class="TA_links ldlYp76">
    //     <li id="HiWwyxc50" class="UCwKaR">
    //       <a
    //         target="_blank"
    //         href="https://www.tripadvisor.com/Attraction_Review-g293890-d8661857-Reviews-Adventure_Master_Trek-Kathmandu_Kathmandu_Valley_Bagmati_Zone_Central_Region.html"
    //       >
    //         <img
    //           src="https://www.tripadvisor.com/img/cdsi/img2/branding/v2/Tripadvisor_lockup_horizontal_secondary_registered-11900-2.svg"
    //           alt="TripAdvisor"
    //         />
    //       </a>
    //     </li>
    //   </ul>
    // </div>
    <>
      <div id="TA_certificateOfExcellence219" class="TA_certificateOfExcellence"><ul id="icLmdB" class="TA_links 9ZHLz09"><li id="BUbRVCx75H" class="i9HBqlB"><a target="_blank" href="https://www.tripadvisor.com/Attraction_Review-g293890-d8661857-Reviews-Adventure_Master_Trek-Kathmandu_Kathmandu_Valley_Bagmati_Zone_Central_Region.html"><img src="https://static.tacdn.com/img2/travelers_choice/widgets/tchotel_2023_L.png" alt="TripAdvisor" class="widCOEImg" id="CDSWIDCOELOGO"/></a></li></ul></div><script async src="https://www.jscache.com/wejs?wtype=certificateOfExcellence&amp;uniq=219&amp;locationId=8661857&amp;lang=en_US&amp;year=2023&amp;display_version=2" data-loadtrk onload="this.loadtrk=true"></script>
    </>
  );
};

export default TripAdvisorReview;
