import Header from "../pages/components/Header";
import Footer from "../pages/components/Footer";
import { Container } from "react-bootstrap";

import TeamCard from "../pages/components/team";

const AboutPage = () => {
  return (
    <>
      <Header title='About Us' />
      <div className='about-page'>
        <Container>
          <div>
            <h2 className=''>
              Welcome to Adventure Master, Your Local Nepal Trekking Experts{" "}
            </h2>
            <p className='content mt-3'>
              With a mission to cater to an authentic and unforgettable
              traveling experience, the team of Adventure Master is taking its
              travelers on a trip of a lifetime. We bring you face-to-face with
              nature, the off-beaten paths, the local people, and the delight
              and rich food, culture, and history of the country. Started in
              2003, Adventure Master has catered to tens of thousands of happy
              travelers from many countries.
              <br />
              At Adventure Master, we are guided by PASSION, TRUST, COMMITMENT,
              and SERVICE that we carry within us.
              <br />
              Our PASSION is visible in the packages we design. By covering
              every little detail in the expedition and ensuring that you live
              an experience ranging from cultural, natural, spiritual, and full
              of adventure.
              <br />
              Our COMMITMENT is visible in the success of every expedition. All
              of our trips have a guaranteed departure unless there is an
              unforeseen situation such as a natural disaster, pandemic,
              government restrictions, political riots, or anything out of our
              control.
              <br />
              We are TRUSTED by tens of thousands of customers across the world.
              We are also a certified trekking agency that is recognized by the
              Nepalese government. Our team has been with us since the
              beginning, and with that kind of trust and passion, we look
              forward to boarding you on a trip with us.
              <br />
              Our SERVICE is clearly visible in our dedicated team members who
              assist you from the beginning to the end of your trip. We are
              available 24/7 for resolving your inquiries and doubts while
              helping you out in deciding on a perfect trip.
              <br />
              <strong>
                <em>
                  Make your journey in Nepal hassle-free and smooth with us,
                  Adventure Master!
                </em>
              </strong>
            </p>
          </div>
        </Container>

        <Container>
          <TeamCard />
        </Container>

        <Container>
          <h2>Why Choose Adventure Master?</h2>
          <p className='content'>
            The Adventure Master Trek is a reputable company. The company's best
            services help travelers from all over the world. We look out for the
            best interests of our customers to make your trip safer and
            smoother. Our team is made up of professionals who have worked in
            the field for a long time and know a lot about all the trips you go
            on with us. "The safety of our customers is our top priority," we
            say when we talk about safety. Before you go on a trip with us, we
            will first make sure you are safe. Our company's culture and team
            will do their best to give you an unforgettable trip in the
            Himalayas.
          </p>
          <p className='content'>
            Adventure Master Trek is known for having the best services because
            we have a team of experts who try out the routes and help you 24
            hours a day with our customer service. We have a safe way to make
            payments, a team with a lot of knowledge and licenses, and a 100%
            set departure if everything goes as planned.
          </p>
          <p className='content'>
            "One day helicopter landing at the Everest base camp" is a
            well-known tour we offer. This is one of the best tours that saves
            time and can be done by anyone in a safe and easy way. If you do not
            have much time, the Everest helicopter tour is a good option. This
            kind of travel is fun for people of all ages. So, a trip by
            helicopter is a great idea. You can even try our trip to Everest
            base camp, which is one of the hardest and most beautiful treks.
            During your trekking, you will see many beautiful sights and learn
            about the sherpas' history and way of life. Get away from your busy
            life and go somewhere without internet or services.
          </p>
          <p className='content'>
            With us, you can choose from a wide range of options and get the
            best service. Also on our top list are the Annapurna Base Camp Trek,
            the Annapurna Circuit Trek, and the Mardi Himal Trek. You can even
            try out our treks to the island peak, the Mera peak, and the Manaslu
            circuit. We can also give you a tour of Nepal, such as a Buddhist
            pilgrimage tour, at a reasonable price that includes stops at many
            well-known places. You can also count on our service. We will do our
            best to make sure your trip is unique and full of things you will
            remember for a long time.
          </p>
          <p className='content'>
            We will help you through the booking process and the paperwork as
            well. When you book a tour with us, we will make sure to answer all
            your questions as soon as we can and give you the best deal we can.
            You can find out more about the trips on our website,
            https://www.adventuremastertrek.com/ . There, you can find
            information about each tour and its itinerary. Please keep in mind
            that we can even plan your tour based on what you want.
          </p>
          <em className='text-light'>
            “Adventure Master Trek will make your trip worth it."{" "}
          </em>
          <h2>
            The team of adventure Master welcomes you to a phenomenal journey
            into the land of mountains.
          </h2>
          <p className='content'>
            We have already catered to thousands of travelers across many
            countries in the world. It's their love and passion for trekking
            that pushes us to achieve an even bigger number in the coming years.
            Unlike other trekking agencies who only promise an adventurous trip
            but fail to deliver it, we shape those promises into realities.
          </p>
          <h4 className='title'>Passion for travel</h4>
          <p className='content'>
            Our passion for travel is the first and foremost reason why
            Adventure Master exist today. This passion has created this platform
            which now fulfills the desire for a perfect trip. Travelling creates
            the kind of impact that will stay with you for a lifetime. With this
            in mind, our team puts all the effort into these excursions and
            ensures that the enthusiasm for traveling grows bigger and bigger.
          </p>
          <h4 className='title'>
            Exceeding the expectations in the Trekking World
          </h4>
          <p className='content'>
            We at Adventure Master not only aim to meet your expectations, but
            take every possible action to achieve more than that. Our three
            principles which aid to achieve this are flexibility, hospitality,
            and safety.
            <ul>
              <li>
                <strong>Flexibility:</strong> You will be getting the
                custom-made option to design your itinerary. You can do this by
                taking budget, time constraints, and other preferences into
                account. Our team will always be one call away to help you with
                this.{" "}
              </li>
              <li>
                <strong>Hospitality:</strong> From your arrival to this
                wonderful country till your departure, you will not feel like a
                left-out tourist in some foreign land. Our team personally
                greets and welcomes every single traveler. We make every
                arrangement for you, starting from pickup and drop at the
                airport to accommodation and welcome and farewell dinner.
              </li>
              <li>
                <strong>Safety:</strong> The first and foremost important factor
                once you are on the trails. No matter how easy or strenuous a
                trek is, you will always need assistance with one thing or the
                other. Not to forget about any unfavorable situation, which we
                hope never occurs. This is where our expertise comes in handy.
                We have a rapid solution available for every unfortunate event.
              </li>
            </ul>
          </p>
          <h4 className='title'>Awareness of the trekking world </h4>
          <p className='content'>
            We have moved closer to the mountains after being in the field for
            more than 18 years. The greatest mountain range on Earth, a
            significant portion is shared by Nepal. We want to instill
            everything. We have curated every single trip possible in the
            Himalayas. We have strong ties to the mountains, rivers, valleys,
            grasslands, and snowfields.
            <br />
            To make the Himalayan adventures worthwhile, we have personally
            trekked on foot to the highest and most inaccessible place. We have
            explored the high elevations and have seen it all, whether it was
            during the winter, monsoon, summer, or fall. We are born in these
            regions, as we already said. So we know everything about it.
          </p>
          <h4 className='title'>Expertise in the Trekking World</h4>
          <p className='content'>
            We devote months to searching for Nepal's hidden gems. We traversed
            the Gokyo lakes, the Khumbu icefalls, and glaciers, as well as
            enormous mountains in Nepal that were all above 8,000-7,000 meters
            in elevation. Our trekking leaders plan, create, and test each and
            every route. We have a lot of expertise in organizing and carrying
            out successful adventure groups and solo trips.
            <br />
            These unique opportunities include anything from the cultural
            heritage, wildlife, culinary, and photographic trips to leisurely
            mountain excursions, extreme Himalayan expeditions, and
            mountaineering. Our whole crew was born and nurtured in remote
            high-altitude settlements. This makes us naturally suited to guiding
            excursions since we are familiar with the area.
          </p>

          <h4 className='title'>Custom-made Itinerary</h4>
          <p className='content'>
            You have the freedom to alter your arrangements before your trip if
            necessary. While you're abroad, your specialist will always be
            available, assisted by our excellent local colleagues.
            <br />
            You can count on your expert to plan your vacation just as you've
            imagined it from the very first chat. We'll make sure you travel in
            a manner that makes you feel secure and at ease, offering a
            selection of less-traveled routes, small and comfortable
            accommodations, and personally guided excursions.
          </p>
          <h4 className='title'>
            Supporting communities in the Trekking World
          </h4>
          <p className='content'>
            By hiring local mountain guides and porters and paying them
            competitive wages and benefits that are far higher than the national
            average, Adventure Master aims to strengthen Nepal's rural
            communities. As promised, we make sure to provide adequate work for
            the people. We collaborate with locals to provide transportation,
            lodging, technical support, and medical aid so that the communities
            can grow as this adventure activity develops.
            <br />
            We use privately owned and operated lodges, use locals as our team's
            porters and guides, dine at small neighborhood eateries, and hire
            local caterers to produce the food we bring on our trips to show our
            support for small businesses. We urge all of our visitors to spend
            money on local goods and services in order to support the
            community's economy.
          </p>
          <h4 className='title'>Safety Measures in the Trekking World</h4>
          <p className='content'>
            Our track record for safety is excellent. We strictly adhere to all
            international safety regulations when trekking. Our trekking leaders
            are certified and have all the knowledge in leading small groups
            across Nepal. They are all certified to do their jobs.
            <br />
            For every tour, each of our guides is outfitted with a first aid
            pack, maps, satellite phones, and radios in case of any emergency.
            We won't even consider taking a small chance with it.
          </p>
          <h4 className='title'>
            Sustainability and Responsibility in the Trekking World
          </h4>
          <p className='content'>
            We are full of cultural and environmental awareness, which further
            motivates the local community to protect the environment. We offer
            hot water to trekkers on longer travels, but we also give them
            advice on how to save it as much as possible. Additionally, we
            always encourage our visitors to respect Nepal's traditions,
            customs, religion, and culture by refraining from bringing plastic
            bottles that might pollute the environment. Instead, we advise them
            to use reusable bottles.
            <br />
            In order for future tourists from around the world to be able to
            enjoy the significance of these historic and distinctive landscapes
            of Nepal, Adventure Master places a high priority on caring for and
            preserving our environment. We do this by keeping the trails clean
            as possible by following the no-littering rule on the trails.
            <br />
            We adhere to our stringent responsible travel guidelines while
            running all of our trips, and we ask that you do the same. We have
            been the locals' supporters and protectors over the years.
            Significant connections with local tour operators, hotels,
            transportation companies, and everyone else has made our work even
            more successful.
          </p>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default AboutPage;
