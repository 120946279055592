import React, { useState } from "react";
import {
  AiOutlineArrowUp,
  AiOutlineArrowDown,
  AiOutlineCloseCircle,
} from "react-icons/ai";
import { HashLink } from "react-router-hash-link";

const Modals = (props) => {
  const { tripTitle, tripPrice, sendmodaldetails } = props;
  const { id, modalhead, modaldetail } = sendmodaldetails;
  const [expandModal, setExpandModal] = useState(false);

  const modaldetailtrip = [
    {
      id: 1,
      modaldetail: [
        {
          modaltitle: "Difficulty Level:",
          modaltext:
            "The trek is moderately hard, with a few steep parts and some rocky, slippery trails. To get around the different terrain, you need to be in good shape and have the right shoes and gear.",
        },
        // {
        //   modaltitle: "Terrain:",
        //   modaltext:
        //     "The Everest base camp trekking route has a blend of plain and rough paths. The first steep ascent you encounter is while approaching Namche Bazaar from Phakding. From here, there are many steep ups & downs throughout the trek. Unless the trail goes beyond the treeline, you walk through dense alpine woodlands and along the verdant foothills. The trail crosses many thrilling suspension and old wooden bridges over rushing rivers & streams. A final couple of days before reaching EBC involves a glacial walk and passes by a narrow moraine.",
        // },
        // {
        //   modaltitle: "Altitude Sickness:",
        //   modaltext:
        //     "Altitude sickness is an illness seen during high-altitude ventures. It's common, at the same time avoidable and treatable. Flying from Kathmandu to Lukla airport itself brings a drastic change in the elevation in a short time. Therefore, we descend to Phakding village on the same day we fly to acclimatize our bodies. Likewise, we have two acclimatization days in our itinerary that provides ample time for trekkers to adjust to the elevation. The acclimatization days are well used by hiking to a high point and returning to the same village to sleep low. We also make sure the group does not ascend more than 500 meters a day.",
        // },
        // {
        //   modaltitle: "Team:",
        //   modaltext:
        //     "Our Adventure Master team is composed of the most experienced trekking leaders and porters who know the Everest base camp trek route inside-out. We are your map on the trail that not only shows the path but also tells all the stories & myths encircling the trail. We help you hone your trekking and survival skills amidst the mountains of the Himalayas.",
        // },
        // {
        //   modaltitle: "Support:",
        //   modaltext:
        //     "Our Everest base camp trekking package has everything that makes the overall trip hassle-free for our guests. Likewise, our team obtains all the permits on your behalf and arranges all other things. We do all the bookings in Kathmandu and along the EBC trail in advance. The porters carry all the heavy luggage during trekking. We are committed to offering safe and satisfying treks in the Himalayas.",
        // },
        // {
        //   modaltitle: "Equipment:",
        //   modaltext:
        //     "Some of the major trekking equipment you need during the Everest base camp trek are a trekking pole, trekking boots, trekking clothes, sleeping bag, pocket torch, toiletries, snacks, etc. You can bring all the gear from your home if you want, but we highly recommend you buy or rent in Thamel, Kathmandu, instead. It'll save you a lot of baggage. Go through the checklist provided once the trip is confirmed and pack according to that.",
        // },
      ],
    },
    {
      id: 2,
      modaldetail: [
        {
          modaltitle: "Annapurna Region:",
          modaltext:
            "Nepal's Annapurna region is a popular place to go trekking because of its beautiful mountain scenery and diverse culture. The area is in the central Himalayas and is home to the Annapurna massif, a range of peaks that includes Annapurna South, Annapurna I, and other peaks that are higher than 7,000 meters. There are many different types of ecosystems in the Annapurna region, such as subtropical forests, alpine meadows, and high-altitude deserts. It is also home to many different kinds of people, like the Gurung, Magar, and Thakali. There are many different kinds of hiking trails in the area, from short, easy walks to longer trips that take several weeks.",
        },
        {
          modaltitle: "Difficulty Level:",
          modaltext:
            "The trek is moderately hard, with a few steep parts and some rocky, slippery trails. To get around the different terrain, you need to be in good shape and have the right shoes and gear.",
        },
        {
          modaltitle: "Terrain:",
          modaltext:
            "The Mardi Himal trek route goes through a variety of landscapes, from subtropical forests to alpine meadows. The Mardi Himal  trek starts at Kande, which is 1,700 meters above sea level, and goes up to the Mardi Himal Base Camp, which is 4,500 meters above sea level. The trail goes through mostly forested areas with uneven paths. It sometimes crosses hanging bridges and areas that are prone to landslides.",
        },
        {
          modaltitle: "Altitude Sickness:",
          modaltext:
            "The trail to Mardi Himal goes up to an altitude of 4,500 meters. Altitude sickness is something you need to be aware of. To lower the risk of Acute Mountain Sickness (AMS), it is  important to acclimate properly by taking rest days, drinking a lot of water, and not doing too much. Talk to your trek guide (if you have one) about your health history and be aware of the signs and symptoms of AMS. Altitude sickness can cause symptoms like headaches, dizziness, tiredness, trouble sleeping, and loss of appetite.",
        },
        {
          modaltitle: "Team:",
          modaltext:
            "You will be helped on your trek by a group of professionals who are in charge of planning and setting up the trip. This group usually has a trekking guide, some porters, and maybe some other helpers.",
        },
        {
          modaltitle: "Support:",
          modaltext:
            "The trek guide is the leader of the group and is in charge of finding the way, telling the group about the culture and history of the area, and making sure everyone is safe. The guide will also know how to give first aid and help with any medical problems that might come up. Porters are in charge of carrying the gear and supplies for the group.",
        },
        {
          modaltitle: "Equipment:",
          modaltext:
            "For a smooth Mardi Himal trek, you need to bring the right clothes and hiking gear. Check out our Packing List at the end of the trip details for more information.",
        },
      ],
    },
    {
      id: 3,
      modaldetail: [
        {
          modaltitle: "Facilities:",
          modaltext:
            "During the Mardi Himal trek, accommodation is typically provided in teahouses or guest houses, which are small, locally owned, and offer basic amenities such as beds and blankets. These accommodations may have shared bathrooms and may only offer hot water showers at an additional cost.",
        },
        {
          modaltitle: "Single room:",
          modaltext:
            " If you go on the Mardi Himal trek in the right season, you might be able to get a single room. We offer the option to book a single room at an additional cost at the time of booking. Do let us know in advance.",
        },
        {
          modaltitle: "Hot water:",
          modaltext:
            " If you want to shower with hot water, you can ask the owner of the tea house . However, they may charge additional money for hot water.",
        },
        {
          modaltitle: "Charging:",
          modaltext:
            "As you move along the trek route, there will be less and less places to charge your phone. In some places, you might not even be able to find a power outlet. This is especially true as you climb to higher altitudes, where charging stations may be hard to find or not exist at all. Be prepared for limited or no access to charging facilities during the Mardi Himal trek. Bring a power bank or another way to charge your electronic devices that you can take with you.",
        },
        {
          modaltitle: "WiFi:",
          modaltext:
            "In some of the  developed areas, such as the starting point at Kande and the ending point at Sidding, WiFi may be more readily available in hotels and other accommodations. But keep in mind that the signal might be weak or unreliable and that service might not be available all the time. However, you can use a Nepali Sim for cellular data.",
        },
      ],
    },
    {
      id: 4,
      modaldetail: [
        {
          modaltitle: "Cuisine:",
          modaltext:
            "Nepal's cuisine is influenced by its location at the crossroads of India, Tibet, and China. As a result, it has a rich and varied history of cooking. Nepalese food is known for its use of many different spices, lentils, and grains. It also often has spicy and flavorful dishes. In big cities like Kathmandu and Pokhara, there are a lot of restaurants that serve different kinds of food.",
        },
        {
          modaltitle: "Nepali Flavors:",
          modaltext:
            "The flavors of Nepali dishes are often complex and varied, with a mix of spicy, sour, sweet, and savory elements. Nepalese food is often spicy and full of flavor, like daal bhat, which is a hearty dish of rice and lentils served with a variety of side dishes, and Sekuwa, which is a kind of barbecued meat. Nepalese snacks and sweets like Sel roti, titaura, bara, chatamari, and chhurpi also have unique flavors that reflect the local cuisine.",
        },
        {
          modaltitle: "Super Food:",
          modaltext:
            'During the trek, Dal bhat will be your superfood. Dal bhat is a staple food in Nepal. Most people eat it for lunch and dinner every day. It is often served in the traditional way of putting all the dishes on a platter called a "Thali" at the same time.',
        },
        {
          modaltitle: "Suitability:",
          modaltext:
            "There are plenty of options for vegetarians, vegans, and gluten-free diners.",
        },
      ],
    },
    {
      id: 5,
      modaldetail: [
        // {
        //   modaltitle: "Khumbu Region:",
        //   modaltext:
        //     "First of all, the Khumbu region is also known as the Everest region because it houses Mt. Everest. In Nepal, the Khumbu region is located northeast of the country and is part of the Solukhumbu district. The elevation ranges from 2,860 meters (Lukla) to 8,849 meters (top of Mt. Everest). The region has a protected area called Sagarmatha National Park that begins at Monjo and goes up to Everest. Lukla, Phakding, Namche Bazaar, Thame, Phortse, Tengboche, Pangboche, Dingboche, Lobuche, and Goraks Shep are some major settlement areas.",
        // },
        // {
        //   modaltitle: "Difficulty Level:",
        //   modaltext:
        //     "Everest base camp trail is considered moderate. As you spend almost 11 days in the remote mountains, the challenges only add up. Yes, there are comfortable lodges and services that make the journey somewhat bearable, but this is not the lifestyle we are used to. So, the trek only gets difficult as it progresses. ",
        // },
        // {
        //   modaltitle: "Terrain:",
        //   modaltext:
        //     "The Everest base camp trekking route has a blend of plain and rough paths. The first steep ascent you encounter is while approaching Namche Bazaar from Phakding. From here, there are many steep ups & downs throughout the trek. Unless the trail goes beyond the treeline, you walk through dense alpine woodlands and along the verdant foothills. The trail crosses many thrilling suspension and old wooden bridges over rushing rivers & streams. A final couple of days before reaching EBC involves a glacial walk and passes by a narrow moraine.",
        // },
        // {
        //   modaltitle: "Altitude Sickness:",
        //   modaltext:
        //     "Altitude sickness is an illness seen during high-altitude ventures. It's common, at the same time avoidable and treatable. Flying from Kathmandu to Lukla airport itself brings a drastic change in the elevation in a short time. Therefore, we descend to Phakding village on the same day we fly to acclimatize our bodies. Likewise, we have two acclimatization days in our itinerary that provides ample time for trekkers to adjust to the elevation. The acclimatization days are well used by hiking to a high point and returning to the same village to sleep low. We also make sure the group does not ascend more than 500 meters a day.",
        // },
        // {
        //   modaltitle: "Team:",
        //   modaltext:
        //     "Our Adventure Master team is composed of the most experienced trekking leaders and porters who know the Everest base camp trek route inside-out. We are your map on the trail that not only shows the path but also tells all the stories & myths encircling the trail. We help you hone your trekking and survival skills amidst the mountains of the Himalayas.",
        // },
        // {
        //   modaltitle: "Support:",
        //   modaltext:
        //     "Our Everest base camp trekking package has everything that makes the overall trip hassle-free for our guests. Likewise, our team obtains all the permits on your behalf and arranges all other things. We do all the bookings in Kathmandu and along the EBC trail in advance. The porters carry all the heavy luggage during trekking. We are committed to offering safe and satisfying treks in the Himalayas.",
        // },
        // {
        //   modaltitle: "Equipment:",
        //   modaltext:
        //     "Some of the major trekking equipment you need during the Everest base camp trek are a trekking pole, trekking boots, trekking clothes, sleeping bag, pocket torch, toiletries, snacks, etc. You can bring all the gear from your home if you want, but we highly recommend you buy or rent in Thamel, Kathmandu, instead. It'll save you a lot of baggage. Go through the checklist provided once the trip is confirmed and pack according to that.",
        // },
      ],
    },
    {
      id: 6,
      modaldetail: [
        {
          modaltitle: "Mardi Himal Trek Route:",
          modaltext:
            "The Mardi Himal trek starts in the city of Pokhara, where you will take a short drive to the starting point at Kande. From there, you will start climbing up through beautiful forests and terraced fields until you reach the Mardi Himal Base Camp, which is 4,500 meters above sea level. The hike then goes through the Mardi Himal Ridge, which has stunning views of the peaks and valleys around it. You will stop at small places to rest where you can learn about the culture and hospitality of the area. You will pass through Pitam Deurali and Low Camp on your way to High Camp, which is 3,580 meters above sea level. From High Camp, you will go to the base camp for Mardi Himal and then come back to High Camp to sleep for the night. From Low Camp, the trail goes in a different direction and drops down to Siding village, where the trek ends.",
        },
        {
          modaltitle: "Restaurants and Tea Shops:",
          modaltext:
            'Kathmandu has a lot of restaurants that serve both international and local food. The Mardi Himal trek goes through small villages and gives you the chance to visit local tea shops. On the trails, you will often see these tea shops, which are also called "chai stalls." They sell a variety of hot and cold drinks, as well as simple snacks like biscuits and noodles. Tea shops are a great place to take a break, put your feet up, and talk to the friendly locals.',
        },
        {
          modaltitle: "ATM and Currency :",
          modaltext:
            "On the Mardi Himal trek route, there are no ATMs. So, get enough cash out of an ATM in Kathmandu or Pokhara so you can use it while trekking.",
        },
        {
          modaltitle: "Internet:",
          modaltext:
            "Because the Mardi Himal trek is in a remote part of Nepal, it can be hard to connect to the internet. In most of the villages and small towns along the trek route, you can't connect to the internet.",
        },
        {
          modaltitle: "Flora + Fauna:",
          modaltext:
            "The Mardi Himal trek route goes through many different types of ecosystems, from subtropical forests to alpine meadows. This means that the plants and animals that live there are very different. On the trek route, you might see many different kinds of plants, such as rhododendrons, juniper, pine, and oak trees. A lot of different kinds of animals live in the area, such as langur monkeys, Himalayan Tahr, and musk deer. During your trek, be kind to the plants and animals in the area. This means you shouldn't pick flowers or bother the animals in the area. Follow the rules about how to throw away trash, because litter can hurt the environment and the animals that live there.",
        },
        {
          modaltitle: "Locals:",
          modaltext:
            "The Mardi Himal trek route goes through Nepal's Annapurna region, which is home to Gurung, Magar, and Thakali communities, among others. These places have a lot of history and traditions that have been passed down from generation to generation. During the trek, you'll be able to talk to people from the area and learn about how they live. Many of the villages along the route are out in the middle of nowhere and don't see many people. So the people who live there might be happy to meet and talk to trekkers.",
        },
        {
          modaltitle: "Language:",
          modaltext:
            'Some of the people we meet along the way may not speak English, so our trekking guide will talk to them and translate as needed. You should also learn a few basic Nepali phrases, like "namaste" (hello), "dhanyabaad" (thank you), and "maaf garnus"(excuse me). Even a little bit of effort to speak the local language can go a long way toward making friends and showing respect for the culture of the area.',
        },
        {
          modaltitle: "Monasteries:",
          modaltext:
            "On the Mardi Himal trail, you might not come across a big temple or monastery, but you will see a few stupas and prayer wheels here and there. Many temples, monasteries, palaces, and courtyards that are hundreds of years old can be seen in Kathmandu and Pokhara.",
        },
      ],
    },
  ];
  const expandHandler = () => {
    setExpandModal(!expandModal);
  };

  const collapseHandler = () => {
    setExpandModal(false);
  };

  const onTripChange = () => {
    sessionStorage.removeItem("tripTitle");
    sessionStorage.removeItem("tripPrice");
    sessionStorage.setItem("tripTitle", tripTitle);
    sessionStorage.setItem("tripPrice", tripPrice);
  };

  return (
    <>
      <div className={`modals ${expandModal ? "expand" : ""}`}>
        <div className={`modal-head `} onClick={expandHandler}>
          <div className="modal-head-title">
            {!expandModal ? (
              <AiOutlineArrowUp fontSize="2rem" className="px-1" />
            ) : (
              <AiOutlineArrowDown fontSize="2rem" className="px-1" />
            )}
            <div className="head-title">{modalhead}</div>
          </div>
          <div className="iclose">
            {expandModal && (
              <AiOutlineCloseCircle
                fontSize="1.3rem"
                onClick={collapseHandler}
              />
            )}
          </div>
        </div>
        <div className={`modal-body ${expandModal ? "modal-body-expand" : ""}`}>
          {!expandModal ? (
            <>
              {modaldetail &&
                modaldetail?.map((detail) => (
                  <div className="modal-content">
                    <div className="modal-title d-none d-sm-flex">
                      {detail.modaltitle}
                    </div>
                    <div className="modal-text">{detail.modaltext}</div>
                  </div>
                ))}
            </>
          ) : (
            <>
              {modaldetailtrip &&
                modaldetailtrip[id - 1]?.modaldetail?.map((detail) => (
                  <div className="modal-content">
                    <div className="modal-title fw-bold">
                      {detail.modaltitle}
                    </div>
                    <div className="modal-text">{detail.modaltext}</div>
                  </div>
                ))}
            </>
          )}
        </div>
        <HashLink
          smooth
          to="/booking"
          className="modal-book link"
          style={{ color: "#000" }}
          onClick={onTripChange}
        >
          BOOK NOW
        </HashLink>
      </div>
    </>
  );
};

export default Modals;
