import React from "react";

import ArticleHeader from "../ArticleHeader";
import Footer from "../../Footer";
import ScrollToTop from "../../ScrollToTop";

import Hero from "../../../../image/Manaslu/manaslu4.jpg";
import newsImage from "../../../../image/newsImage.jpeg";
import newsImage1 from "../../../../image/newImage1.jpeg";

import { Helmet } from "react-helmet";

function NoTreks() {
  return (
    <>
      <Helmet>
        <meta />
      </Helmet>
      <ScrollToTop position='top' />
      <ArticleHeader
        title='No treks in Nepal are allowed without a guide'
        date='April 6, 2023'
      />
      <div className='container blog-post-body'>
        <img src={Hero} alt='everest heli tour' />
        <h1 className='title'>No treks in Nepal are allowed without a guide</h1>
        <p className='content'>
          As of April 1, 2023, anyone trekking in the Himalayas must have a
          licensed guide with them at all times, the Khumbu Pasang Lhamu Rural
          Municipality says. According to TAAN, trekking in Nepal's designated
          national parks and protected regions must be done with a local guide
          or porter, and trekkers must get a trekkers' information management
          system (TIMS) card before going on treks in managed areas.
        </p>
        <p className='content'>
          The Adventure Master Trek is a reputable company, and the safety and
          security of the trekkers are our top priorities. The implied rule has
          numerous benefits and is the ideal choice if you want your journey to
          go smoothly and safely. “ Customers safety is Our top priority”
        </p>
        <p className='content'>
          There are benefits to having a local guide with you on the trek:
          <ul>
            <li>
              Before you go hiking, the local guide will know what to check with
              whom.
            </li>
            <li>
              The local guide will know all the routes while you go trekking.
            </li>
            <li>They will know what to do when there is an emergency.</li>
            <li>
              They will show you the way and tell you all you need to know, as
              well as give information regarding different places.{" "}
            </li>
            <li>
              They will also help you with altitude sickness and help you train
              your body to get rid of it.
            </li>
            <li>
              The local guides and porters on our trekking team are the best at
              what they do and are also very friendly
            </li>
          </ul>
        </p>
        <div className='row d-flex'>
          <div className='col-sm-12 col-md-6'>
            <img src={newsImage} />
          </div>
          <div className='col-sm-12 col-md-6'>
            <img src={newsImage1} />
          </div>
        </div>
        <h2 className='title'>Note</h2>
        <p className='content'>
          According to the National Tourism Board, a day after the Nepal Tourism
          Board declared that guides and trekking permits are required on 44
          trekking trails, including a few in the Everest region, the Khumbu
          Pasang Lhamu Rural Municipality said they don't have to follow the
          board's guidelines.
        </p>
        <p className='content'>
          In a news release, the rural municipality that Everest is part of
          announced that you don't have to hire a guide to go trekking on some
          of the trails in the Everest area. Nepalese hikers don't have to hire
          a guide or pay the trekking fee, nevertheless. However, the head of
          the Nepal Tourism Board, Dhananjay Regmi, told the Post that the new
          rules have already been put into place in all of the major trekking
          zones.
        </p>
        <em className='content'>
          "It is highly irresponsible of the rural municipalities in the Khumbu
          region not to follow it,” Regmi said. “Many trekkers traveling without
          guides have been lost or died due to terrain difficulties.” However,
          till date there is No guide required in Khumbu region”
        </em>
        <p className='content'>
          <strong>Refrence:</strong> <br />
          <a
            href='https://kathmandupost.com/money/2023/04/03/everest-region-nepal-tourism-board-dispute-over-solo-trek-ban-heats-up'
            target='_blank'
          >
            Kathmandu Post
          </a>
        </p>
      </div>
      <Footer />
    </>
  );
}

export default NoTreks;
