import React from 'react'
import InfoIcon from "@mui/icons-material/Info";
import { FaCheckCircle } from 'react-icons/fa';
import CancelIcon from "@mui/icons-material/Cancel";


function EBCHeliReturnluxuryIcon() {
    return (
        <div>
            <div class="modal fade luxury-package-modal" id="ebchelitreturnluxuryicon" aria-hidden="true" aria-labelledby="ebchelitreturnluxuryiconLabel" tabindex="-1">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5 " id="ebchelitreturnluxuryiconLabel">Luxury package cost Include</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body ">
                            <ul className="cost-include">
                                <span>
                                    <div className="cost-include-icon">
                                        <FaCheckCircle />
                                    </div>
                                    <li>Gorakshep to Lukla by helicopter</li>
                                </span>
                                <span>
                                    <div className="cost-include-icon">
                                        <FaCheckCircle />
                                    </div>
                                    <li>3 star hotel accommodation in Kathmandu</li>
                                </span>
                                <span>
                                    <div className="cost-include-icon">
                                        <FaCheckCircle />
                                    </div>
                                    <li>Deluxe teahouse accommodation at mountain</li>
                                </span>
                                <span>
                                    <div className="cost-include-icon">
                                        <FaCheckCircle />
                                    </div>
                                    <li>Meals (B, L, D)</li>
                                </span>
                                <span>
                                    <div className="cost-include-icon">
                                        <FaCheckCircle />
                                    </div>
                                    <li>Guide</li>
                                </span>
                                <span>
                                    <div className="cost-include-icon">
                                        <FaCheckCircle />
                                    </div>
                                    <li>Porter</li>
                                </span>
                                <span>
                                    <div className="cost-include-icon">
                                        <FaCheckCircle />
                                    </div>
                                    <li>Permit</li>
                                </span>
                                <span>
                                    <div className="cost-include-icon">
                                        <FaCheckCircle />
                                    </div>
                                    <li>Transportation (Pickup and drop)</li>
                                </span>
                                <span>
                                    <div className="cost-include-icon">
                                        <FaCheckCircle />
                                    </div>
                                    <li>Trekking map</li>
                                </span>
                                <span>
                                    <div className="cost-include-icon">
                                        <FaCheckCircle />
                                    </div>
                                    <li>Duffle bag</li>
                                </span>
                                <span>
                                    <div className="cost-include-icon">
                                        <FaCheckCircle />
                                    </div>
                                    <li>Certificate</li>
                                </span>
                                <span>
                                    <div className="cost-include-icon">
                                        <FaCheckCircle />
                                    </div>
                                    <li>Farewell dinner</li>
                                </span>
                            </ul>
                        </div>
                        <div class="modal-footer">
                            <button class="btn btn-dark" data-bs-target="#ebchelitreturnluxuryicon2" data-bs-toggle="modal">View cost exclude</button>
                            <button class="btn btn-outline-secondary" data-bs-target="" data-bs-toggle="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade luxury-package-modal" id="ebchelitreturnluxuryicon2" aria-hidden="true" aria-labelledby="ebchelitreturnluxuryiconLabel2" tabindex="-1">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5 " id="ebchelitreturnluxuryiconLabel2">Luxury package cost exclude</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body ">
                            <ul className="cost-exclude">
                                <span>
                                    <div className="cost-exclude-icon">
                                        <CancelIcon />
                                    </div>
                                    <li>
                                        Meals not specified in the 'Meal Inclusions' in the
                                        above itinerary
                                    </li>
                                </span>
                                <span>
                                    <div className="cost-exclude-icon">
                                        <CancelIcon />
                                    </div>
                                    <li>Travel insurance</li>
                                </span>
                                <span>
                                    <div className="cost-exclude-icon">
                                        <CancelIcon />
                                    </div>
                                    <li>International airfare</li>
                                </span>
                                <span>
                                    <div className="cost-exclude-icon">
                                        <CancelIcon />
                                    </div>
                                    <li>Nepal entry visa</li>
                                </span>
                                <span>
                                    <div className="cost-exclude-icon">
                                        <CancelIcon />
                                    </div>
                                    <li>Soft drinks, including mineral water</li>
                                </span>
                                <span>
                                    <div className="cost-exclude-icon">
                                        <CancelIcon />
                                    </div>
                                    <li>Snacks and other personal expenses</li>
                                </span>
                                <span>
                                    <div className="cost-exclude-icon">
                                        <CancelIcon />
                                    </div>
                                    <li>
                                        Hot showers, charging, internet, etc expenses during
                                        the trek
                                    </li>
                                </span>
                                <span>
                                    <div className="cost-exclude-icon">
                                        <CancelIcon />
                                    </div>
                                    <li>Personal trekking equipment</li>
                                </span>
                                <span>
                                    <div className="cost-exclude-icon">
                                        <CancelIcon />
                                    </div>
                                    <li>
                                        Tips and gratuities for trekking staff and drivers
                                    </li>
                                </span>
                            </ul>
                        </div>
                        <div class="modal-footer">
                            <button class="btn btn-dark" data-bs-target="#ebchelitreturnluxuryicon" data-bs-toggle="modal">View cost include</button>
                            <button class="btn btn-outline-secondary" data-bs-target="" data-bs-toggle="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
            <button class="luxury-package-modal-btn" data-bs-target="#ebchelitreturnluxuryicon" data-bs-toggle="modal"><InfoIcon /></button>
        </div>
    )
}

export default EBCHeliReturnluxuryIcon



