import abc from "../../../../image/Annapurna/annapurna2.jpg";
import annapurnaTilichoWithPoonHill from "../../../../image/Annapurna/TilichoLake/tilichoLake.jpg";
import annapurnaCircuit from "../../../../image/Annapurna/AnnapurnaCircuit/annapurnaCircuit.jpg";
import mardi from "../../../../image/MardiHimalTrek/mardi1.jpg";
import { FaStar, FaCalendarAlt, FaMapMarkerAlt } from "react-icons/fa";

export const cardInfo = [
  {
    image: abc,
    reviewIcon1: <FaStar />,
    reviewIcon2: <FaStar />,
    reviewIcon3: <FaStar />,
    reviewIcon4: <FaStar />,
    reviewIcon5: <FaStar />,
    // review: "23 Reviews",
    countryIcon: <FaMapMarkerAlt />,
    country: "Nepal",
    durationIcon: <FaCalendarAlt />,
    duration: "12 Days",
    title: "Annapurna Base Camp Trek",
    price: "USD $890 pp",
    btn: "View Trip",
    link: "./annapurna-base-camp-trek",
  },
  {
    image: annapurnaTilichoWithPoonHill,
    reviewIcon1: <FaStar />,
    reviewIcon2: <FaStar />,
    reviewIcon3: <FaStar />,
    reviewIcon4: <FaStar />,
    reviewIcon5: <FaStar />,
    // review: "19 Reviews",
    countryIcon: <FaMapMarkerAlt />,
    country: "Nepal",
    durationIcon: <FaCalendarAlt />,
    duration: "17 Days",
    title: "Annapurna Circuit Trek With Tilicho Lake & Poon Hill",
    price: "USD $1260 pp",
    btn: "View Trip",
    link: "./annapurna-circuit-with-tilicho-lake-and-poon-hill-17-days",
  },
  // {
  //   image: annapurnaCircuit,
  //   reviewIcon1: <FaStar />,
  //   reviewIcon2: <FaStar />,
  //   reviewIcon3: <FaStar />,
  //   reviewIcon4: <FaStar />,
  //   reviewIcon5: <FaStar />,
  //   // review: "12 Reviews",
  //   countryIcon: <FaMapMarkerAlt />,
  //   country: "Nepal",
  //   durationIcon: <FaCalendarAlt />,
  //   duration: "15 Days",
  //   title: "Annapurna Circuit Trek",
  //   price: "USD $1499 pp",
  //   btn: "View Trip",
  //   link: "#",
  // },
  {
    image: mardi,
    reviewIcon1: <FaStar />,
    reviewIcon2: <FaStar />,
    reviewIcon3: <FaStar />,
    reviewIcon4: <FaStar />,
    reviewIcon5: <FaStar />,
    // review: "10 Reviews",
    countryIcon: <FaMapMarkerAlt />,
    country: "Nepal",
    durationIcon: <FaCalendarAlt />,
    duration: "9 Days",
    title: "Mardi Himal Base Camp Trek",
    price: "USD $870 pp",
    btn: "View Trip",
    link: "./mardi-himal-base-camp-trek-9-days",
  },
];
// }
