import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { FaCheckCircle } from "react-icons/fa";
import CancelIcon from "@mui/icons-material/Cancel";
import TripFact from "./TripFact";
import Gallery from "./Gallery";

export default function Overview() {
  return (
    <div className='overview'>
      <div className='container d-flex mt-5'>
        <div className='row'>
          {/* <div className="col-lg-9 col-sm-12"> */}
          <div className='col-12'>
            <TripFact />
            <Gallery />

            <h4 className='mt-5 pt-4 title'>
              Mardi Himal Base Camp Trek Video
            </h4>
            <iframe
              className='mb-4'
              width='100%'
              height='600px'
              src='https://www.youtube.com/embed/ET6Yr29gQmg'
              title='Mardi Himal Base Camp Trek'
              frameborder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowfullscreen
            ></iframe>

            <h4 className='overview-title mt-4'>Outline Itinerary</h4>
            <ul className='p-0'>
              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 01:</strong>{" "}
                </div>
                <li>Arrive in Kathmandu</li>
              </div>

              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 02: </strong>
                </div>
                <li>Flight from Kathmandu to Pokhara (827 m/2,713 ft)</li>
              </div>

              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 03:</strong>
                </div>
                <li>
                  Drive to Kande (1,700 m/5,577 ft) and trek to Pitam Deurali
                  (1,900 m/6,233 ft)
                </li>
              </div>

              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 04:</strong>
                </div>
                <li>Pitam Deurali to Low Camp (2,985 m/9,793 ft)</li>
              </div>

              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 05:</strong>
                </div>
                <li>Trek to High Camp (3,580 m/11,745 ft</li>
              </div>

              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 06:</strong>
                </div>
                <li>
                  Trek to Mardi Himal Base Camp (4,500 m/14,763 ft) and back to
                  High Camp (3,580 m/11,745 ft)
                </li>
              </div>

              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 07:</strong>
                </div>
                <li>
                  Trek to Siding (1,885 m/6,184 ft) and drive to Pokhara (827
                  m/2,713 ft)
                </li>
              </div>

              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 08:</strong>
                </div>
                <li>Drive back to Kathmandu</li>
              </div>

              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 09:</strong>
                </div>
                <li>Final departure</li>
              </div>
            </ul>

            <h4 className='overview-title pt-5'>Trip Highlight</h4>
            <ul className='trip-highlight'>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Beautiful views of the mountains: The Mardi Himal trek offers
                  stunning views of the Annapurna massif, including peaks like
                  Annapurna South, Hiunchuli, and Machhapuchhre, as well as
                  different landscapes.
                </li>
              </span>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Off the beaten path: The Mardi Himal trail is less crowded
                  than other popular treks in the Annapurna region, making it a
                  great choice for people who want to get away from the crowds.
                </li>
              </span>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Culture: The Mardi Himal trekking route takes you through
                  traditional villages where you can learn about the Gurung and
                  Magar people's culture and traditions.
                </li>
              </span>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Different types of terrain: The Mardi Himal trek goes through
                  forests, meadows, and high-altitude trails.
                </li>
              </span>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  You will reach altitudes of up to 4,500 meters, giving you the
                  chance to trek at high altitudes and feel the excitement of
                  reaching a mountain pass.
                </li>
              </span>
            </ul>

            <div className='row d-flex justify-content-between'>
              <div className='col-12'>
                <h4 className='overview-title pt-5'>Package Cost Include</h4>
                <ul className='cost-include'>
                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>
                      All ground travel, including getting to and from the
                      airport, is done in a private car.
                    </li>
                  </span>
                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>The Kathmandu to Pokhara flight.</li>
                  </span>
                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>Drive from Pokhara to Kande by private car.</li>
                  </span>
                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>Drive from Siding to Pokhara by private jeep.</li>
                  </span>
                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>
                      Drive from Pokhara to Kathmandu via a luxurious tourist
                      bus.
                    </li>
                  </span>
                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>
                      One night in Kathmandu and two nights in Pokhara at
                      standard hotel with breakfast (as per the itinerary).
                    </li>
                  </span>
                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>
                      During the trek, you will be provided with three meals a
                      day: breakfast, lunch, and dinner with one cup of tea each
                      meal.
                    </li>
                  </span>
                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>
                      The best available lodge for two people to share during
                      the trek.
                    </li>
                  </span>
                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>
                      Strong, helpful porters with the right safety gear and
                      walking gear, as well as a salary, food, a place to live,
                      and insurance. (one porter for two people).
                    </li>
                  </span>
                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>
                      A highly experienced, helpful, knowledgeable, friendly,
                      English-speaking guide with all of his salary, food,
                      drinks, lodging, transportation, and insurance covered.
                    </li>
                  </span>
                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>Taxes and official costs paid to the government.</li>
                  </span>
                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>
                      Oxymeter to check your pulse, oxygen saturation, and heart
                      rate twice a day (very helpful to check for signs of
                      Altitude Mountain Sickness, or AMS). This will make sure
                      that your trek is in the safest hands possible, no matter
                      how experienced you are.
                    </li>
                  </span>
                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>
                      A map of a trek and a certificate of completion of the
                      trip.
                    </li>
                  </span>
                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>
                      All papers that need to be done, Fees for entering permits
                      you need to go while hiking.
                    </li>
                  </span>
                </ul>
              </div>

              <div className='col-12'>
                <h4 className='overview-title pt-5'>Package Cost Exclude</h4>
                <ul className='cost-exclude'>
                  <span>
                    <div className='cost-exclude-icon'>
                      <CancelIcon />
                    </div>
                    <li>
                      Food that you will eat in Kathmandu for lunch and dinner.
                    </li>
                  </span>
                  <span>
                    <div className='cost-exclude-icon'>
                      <CancelIcon />
                    </div>
                    <li>
                      <p>
                        The cost of a visa to enter Nepal is: <br />
                        $30 USD for a 15-day stay, <br />
                        30 Days - $50,
                        <br />
                        90 Days - $125
                      </p>
                    </li>
                  </span>
                  <span>
                    <div className='cost-exclude-icon'>
                      <CancelIcon />
                    </div>
                    <li>Your insurance for travel and medical care.</li>
                  </span>
                  <span>
                    <div className='cost-exclude-icon'>
                      <CancelIcon />
                    </div>
                    <li>International Airfare.</li>
                  </span>
                  <span>
                    <div className='cost-exclude-icon'>
                      <CancelIcon />
                    </div>
                    <li>Your Personal Expenses.</li>
                  </span>
                  <span>
                    <div className='cost-exclude-icon'>
                      <CancelIcon />
                    </div>
                    <li>
                      All of the alcoholic and non-alcoholic cold and hot drinks
                      you buy on trek (along the way and in the Tea Houses at
                      night).
                    </li>
                  </span>
                  <span>
                    <div className='cost-exclude-icon'>
                      <CancelIcon />
                    </div>
                    <li>
                      All of the sweet desserts, like chocolate, cake, pie, and
                      pudding.
                    </li>
                  </span>
                  <span>
                    <div className='cost-exclude-icon'>
                      <CancelIcon />
                    </div>
                    <li>
                      The tea houses have hot showers and places to charge your
                      phone which will cost you about 2$ to 5$.
                    </li>
                  </span>
                  <span>
                    <div className='cost-exclude-icon'>
                      <CancelIcon />
                    </div>
                    <li>
                      Tip to the guide, the porter, and the driver. (Tipping is
                      expected)
                    </li>
                  </span>
                </ul>
              </div>
            </div>

            {/* <EverestHeliNotice className="pt-5 px-0" />
            <EverestTable className="pt-5 px-0" /> */}

            <h4 className='overview-title pt-5'>FAQ</h4>

            <div className='trip-overview-accordion'>
              <Accordion defaultActiveKey='0' flush>
                <Accordion.Item eventKey='0'>
                  <Accordion.Header>
                    <p class='accordion-title'>
                      1) How long is the Mardi Himal trek?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='overview-content'>
                      The Mardi Himal trek typically takes around 7 to 9 days to
                      complete. The exact length of the trek will depend on the
                      route and pace of the group. The trek begins with a short
                      drive from Pokhara to Kande. Trekking through the
                      Annapurna region, reach elevations of up to 4,500 meters.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='1'>
                  <Accordion.Header>
                    <p class='accordion-title'>
                      2) How difficult is the Mardi Himal trek?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='accordion-content'>
                      The Mardi Himal Trek is considered a moderate to
                      challenging trek due to its high altitude and rugged
                      terrain. The trek involves long days of hiking, with some
                      steep and rocky sections. It is important to be in the
                      good physical condition and to acclimatize to the high
                      altitudes before embarking on the trek.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='2'>
                  <Accordion.Header>
                    <p class='accordion-title'>
                      3) How much does it cost for a Mardi Himal trek?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='overview-content'>
                      The cost of the Mardi Himal Trek will vary depending on
                      several factors, including the duration of the trek, the
                      level of service, and the time of year. On average, you
                      can expect the cost to range from USD 750 to USD 950 per
                      person in a group.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='3'>
                  <Accordion.Header>
                    <p class='accordion-title'>
                      4) How long is the Mardi trek from Pokhara?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='overview-content'>
                      From Pokhara, the trek typically takes around 5 to 7 days
                      to complete, depending on the route and pace of the group.
                      You will approximately cover 57 kilometers of distance
                      during the trek.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='4'>
                  <Accordion.Header>
                    <p class='accordion-title'>5) Is Mardi Trek risky?</p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='overview-content'>
                      Like any trek in the mountains, the Mardi Himal trekking
                      involves some inherent risks. These risks may include
                      physical challenges such as altitude sickness, injuries,
                      or exposure to the elements. It is important to be aware
                      of these risks and to take precautions to minimize them.
                      That being said, the Mardi Himal trek is generally
                      considered a safe trek, and many people of all fitness
                      levels have successfully completed the journey.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='5'>
                  <Accordion.Header>
                    <p class='accordion-title'>
                      6) Do you need a guide for the Mardi Himal trek?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='overview-content'>
                      You can do the Mardi Himal trek without a guide. But, it
                      is recommended to hire a guide, as they can provide
                      valuable expertise and assistance throughout the journey.
                      A guide will have a deep understanding of the route and
                      can help you navigate the trek safely. They can also
                      provide valuable insights into the local culture and
                      traditions of the area and can assist with any logistical
                      or practical issues that may arise during the trek.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='6'>
                  <Accordion.Header>
                    <p className='accordion-title'>
                      7) Is there network in Mardi Himal Trek?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='overview-content'>
                      There is limited mobile phone and internet coverage in the
                      Mardi Himal region. While it is possible to get some
                      coverage in certain areas, it is not reliable and should
                      not be relied upon as a primary means of communication.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='7'>
                  <Accordion.Header>
                    <p class='accordion-title'>
                      8) Is Mardi Himal and Machhapuchhre the same?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='overview-content'>
                      No, Mardi Himal and Machhapuchhre (also known as Fishtail
                      Mountain) are two separate peaks in the Annapurna region
                      of Nepal. Mardi Himal is a lesser-known peak located east
                      of the Annapurna massif, while Machhapuchhre (Fishtail
                      Mountain) is a popular and iconic peak that is visible
                      from many parts of the Annapurna region.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='8'>
                  <Accordion.Header>
                    <p class='accordion-title'>
                      9) Do you need a sleeping bag for Mardi Himal trekking?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='overview-content'>
                      It is generally recommended to bring a sleeping bag for
                      the Mardi Himal trek, as the temperatures can drop
                      significantly at high altitudes. A sleeping bag will help
                      keep you warm and comfortable during the night and can be
                      a valuable piece of equipment to have in case of
                      unexpected changes in weather or other circumstances. You
                      can rent or buy a sleeping bag in Thamel, Kathmandu.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
          {/* sidebar price
          <div className="col-lg-3 col-sm-12">
            <div className="overview-sidebar bg-light">
              <span className="badge text-wrap">Top Seller</span>
              <div className="">
                <span className="overview-sidebar-days">14 Days</span>
                <br />
                <span className="overview-sidebar-destination">
                  Kathmandu to Kathmandu
                </span>
                <br />
                <h4 className="price-text my-3">From USD $N/A</h4>
                <div className="group-discount mb-3">
                  <table className="group-discount-table">
                    <p>Group Discount</p>
                    <tr>
                      <td>Pax</td>
                      <td>Price</td>
                    </tr>
                    <tr>
                      <th>1 Pax</th>
                      <th>$N/A</th>
                    </tr>
                    <tr>
                      <th>2 Pax</th>
                      <th>$N/A</th>
                    </tr>
                    <tr>
                      <th>3 Pax</th>
                      <th>$N/A</th>
                    </tr>
                    <tr>
                      <th>4-6 Pax</th>
                      <th>$N/A</th>
                    </tr>
                    <tr>
                      <th>7-10 Pax</th>
                      <th>$N/A</th>
                    </tr>
                    <tr>
                      <th>11-18 Pax</th>
                      <th>$N/A</th>
                    </tr>
                    <tr>
                      <th>19-24 Pax</th>
                      <th>$N/A</th>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="">
                <HashLink smooth to="/booking" className="link">
                  <button className="book-btn">Book Now</button>
                </HashLink>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
