import React from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";

function TermsAndCondition() {
  return (
    <>
      <ScrollToTop position="top" />
      <Header />

      <div className="container mt-5">
        <h2 className="my-3">Terms And Condition</h2>
        <p className="overview-content">
          Your agreement with Adventure Master, and its partners, hereinafter
          referred to as Adventure Master ("we," "us," or "our"), is based on
          these reservation conditions, our privacy policy, Website Terms of
          Use, and any additional written form we brought to your attention
          before confirming your booking.
          <br />
          They spell out our rights and duties, so please examine them
          carefully. References to "you," "they," "them," "their," "he," "she,"
          "participants", and "your" in these Booking Terms and Conditions
          encompass the first identified person on the reservation as well as
          other relevant parties on whose behalf a reservation is made or any
          additional person to whom a reservation is added or transferred.
        </p>

        <p className="overview-content">
          <em>
            <strong>
              By making a reservation, the first person on the reservation
              certifies on behalf of all parties listed on the reservation that
              they:
            </strong>{" "}
          </em>
          <ul>
            <li>
              Have read, understood, and agreed to be bound by these Booking
              Conditions and our general information pages (such as the parts
              titled "Trip Info," "Itinerary," "FAQs," and "Dates and Prices" in
              our brochure and/or on our website)
            </li>
            <li>
              Recognize and embrace the hazards associated with adventure travel
            </li>
            <li>
              Authorize all individuals named on the reservation to disclose
              their personal information to us, including any relevant special
              data categories (such as information on health conditions,
              disabilities, and dietary restrictions), and consents to our use
              of that information under our <strong>Privacy Policy</strong>
            </li>
            <li>
              Certify that they and all other members of the party are of legal
              age to purchase excursions from Adventure Master when making an
              order for services subject to age limitations
            </li>
            <li>
              Accept financial responsibility for arranging the reservation
              payment on behalf of the other party.
            </li>
          </ul>
        </p>
        <h4 className="overview-title mt-5">TRIP & BOOKING CONTRACT</h4>
        <p className="overview-content">
          After Adventure Master receives the necessary money, a formal
          confirmation of your reservation and a contract is issued. Please
          carefully examine your confirmation, and immediately notify us or your
          designated agent of any errors or omissions. Please double-check that
          the names match exactly what is on the corresponding passport.
        </p>

        <h4 className="overview-title mt-5">Confirm Booking</h4>
        <p className="overview-content">
          Unless otherwise specified before receiving your deposit, a deposit of
          25% of the excursion's total cost for each participant is required to
          activate your reservation when booking through the website. Before
          making a reservation, speak with our adviser assigned to you about any
          installment payment plans.
          <br />
          When you make a reservation request, we will consider it a
          not-guaranteed reservation because it is merely tentative at this
          time. Only once we give you a Welcome email to let you know that your
          on-request booking has been confirmed is your reservation approved and
          guaranteed. At this time, a legal agreement between you and us is
          formed.
          <br />
          Any acknowledgement of your booking request that we give to you in the
          interim for reservations made through our website is not a
          confirmation of your reservation. We reserve the right, at our sole
          discretion, to refuse any booking.
          <br />
          If you make a reservation on our website, we'll get in touch with you
          by email. As a result, you need to regularly check your email. If we
          are unable to reach you by email for whatever reason, we may still
          reach out to you by phone and/or mail. You might need to mail some
          papers. Email communication is included when sent, and in-writing or
          similar expressions are used under certain scenarios. If you need to
          reach us for any of the following reasons, you should email us.
        </p>
        <h4 className="overview-title mt-5">Late/Last Minute Booking</h4>
        <p className="overview-content">
          Small group trips are our area of expertise, therefore the earlier you
          book, the better. We also provide a private hike & tour on your chosen
          dates. We are entitled to pass along any additional costs incurred as
          a result of the late disposition of the booking for reservations made
          less than 30 days before the start of your tour (or earlier for
          climbs/customized trips). We will inform you of any such added
          expenses at the time that you make your reservation.
          <br />
          The contract between you and us becomes effective for reservations
          made less than 30 days before your tour (or sooner for climbs or
          bespoke excursions) as soon as we or our authorized travel agency has
          received full payment.
          <br />
          <strong>Please Note:</strong> If you make a last-minute or on-request
          reservation, we highly advise against booking any flights or making
          any other travel plans until you have received written confirmation
          from us that your selected trip is still available. If you decide to
          make travel plans before getting written assurance of our availability
          from us, we will not be liable to you for any returns of the expenses
          you have already paid if your preferred booking is not truly
          available.
        </p>
        <h4 className="overview-title mt-5">Payment For Your Tour</h4>
        <p className="overview-content">
          No more than 30 days before the commencement of your trip, the total
          amount payable, including any surcharges in effect at the time, must
          be received by us (or our approved travel agency). Before the
          commencement of your journey, full payment is required for climbs,
          expeditions, treks, and bespoke tours. While this is the case, you
          will be informed when making the reservation.
          <br />
          We retain the right to regard your reservation as canceled by you if
          we do not receive this amount in full and on time. Deposits are made
          at the time of reservation on the website, and future payments can be
          made there, by personal check or cashier's check made out in USD to
          Adventure Master or by bank transfer to the company's XXXXXXX address.
          <br />
          By using a credit or debit card, you may pay the deposit and the
          entire sum. We accept Maestro/Solo, Visa, and MasterCard. On our
          booking page, there can be other payment options.
          <br />
        </p>

        <h4 className="overview-title mt-4">Payment Process</h4>
        <p className="overview-content">
          We accept booking deposits by bank transfer or Credit/Debit Card
          (Union Pay, Visa, American Express, MasterCard, and JCP).
          <br />
          <strong>
            Payment by Credit Card and Debit Card (Union Pay, Visa, American
            Express, MasterCard, and JCP):
          </strong>
          <br />
          If you want to do the payment through the Debit Card (Union Pay, Visa,
          American Express, MasterCard, and JCP), then fill in all the details
          with your Debit Card information and signature (same as in your
          passport). Once you are done completing the form, email it to us.
          <br />
          You can download the form (PDF version ) here.
          <br />
          If you want to do the payment through Credit Card, you will be charged
          by ABC Bank on behalf of Adventure Master.
          <br />
          <em>
            <strong>
              *Note - There will be an extra 4% charge when you do the payment
              through a Credit Card.
            </strong>
          </em>
          <br />
          <strong>Payment via Wire/Bank Transfer:</strong>
          <br />
          Our Bank Details for Wire Transfer:
          <br />
          Pay to [Company Name]:
          <br />
          Company Street Address: <br />
          Company Contact info: <br />
          Current A/c No: <br />
          Paying Bank: <br />
          Banking Branch: <br />
          Account Type: <br />
          Bank Street Address:
          <br />
          SWIFT CODE: <br />
          Adventure Master does not hold responsibility for any loss or delay
          that might occur sometime during the transfer process. Likewise, there
          can be a scenario when the money received by us may not be equal to
          what you send because of the mediator bank. In that case, we request
          you to known their service charge before hand and make payment as per
          that. If the money received us is less than stated, we kindly request
          you to pay us accordingly before the trip starts.
          <br />
          <em>
            <strong>
              *Note:Send us the bank reference number or remittance slips
              picture on our mail after depositing the advance payment. It will
              help us confirm the details and proceed with your booking.
            </strong>
          </em>
          <br />
          <strong>Final Payment/Acceptance of Booking/Client details:</strong>
          <br />
          You can make the final payment after you arrive in Nepal (before the
          commencement of the trip). We accept bank transfers, cash, or by
          Credit Card/Debit Card (Union Pay, Visa, American Express, MasterCard,
          and JCP).
          <br />
          <em>
            <strong>
              *Note - You may get charged 4% extra when the payment is made by
              card.
            </strong>
          </em>
        </p>
        <h4 className="overview-title mt-5">Pricing</h4>
        <p className="overview-content">
          Our travel plans are priced in USD by default. With your designated
          Adventure Master agent, you may inquire about the cost in your home
          currency. We have the right to change unsold trip rates at any moment,
          and to fix pricing issues for confirmed trips. Additionally, we retain
          the right to raise the cost of already-confirmed journeys only to
          cover increases that are a direct result of changes in:
          <br />
          <ul>
            <li>
              The cost of passenger transportation due to changes in the price
              of gasoline or other energy sources
            </li>
            <li>
              The level of taxes or fees levied by third parties who are not
              directly engaged in the performance of the trip, such as tourist
              taxes, landing taxes, or embarkation or disembarkation fees at
              ports and airports
            </li>
            <li>Currency rates applicable to the package</li>
          </ul>
          Such adjustments may include but are not limited to, changes in
          airline prices that are part of our contracts with airlines, local
          operators, and other transportation providers.
          <br />
          You will be offered the choice to accept an alteration to another trip
          if we can offer one; if this trip is of the equivalent or greater
          price, you will not be required to pay extra; if it is of the lesser
          price, you will be reimbursed the difference in cost. This applies
          only if this means that you must pay a rise of more than 8% of the
          cost of your booked trip (excluding any insurance premiums, amendment
          charges, and/or additional services or travel arrangements).
          <br />
          If for any reason you choose to cancel, you must do so within 7 days
          of the issuance date indicated on your last invoice. If you can
          demonstrate that you are not able to transfer or reuse your policy, we
          will take into account a proper return of insurance premiums paid.
          <br />
          Any refund that is owed to you if the cost of your trip decreases as a
          result of the adjustments described above will be given to you for
          less than a $100 administrative charge. Please be aware, though, that
          not all travel arrangements are bought in local currency, and certain
          seeming changes do not affect the cost of your trip because of legal
          and other protections in place. Within 30 days of your departure,
          neither the cost of your booked trip nor any reimbursements will be
          subject to change.
          <br />
          Within 30 days of your departure, neither the cost of your booked trip
          nor any reimbursements will be subject to change.
        </p>
        <h4 className="overview-title mt-5">Cutting Your Trip Short</h4>
        <p className="overview-content">
          We are not required to reimburse the cost of any travel arrangements
          you have not utilized if you are obliged to shorten the itinerary for
          any personal reason. If you shorten your stay and head home early in a
          situation when you don't have a good basis to criticize the level of
          the accommodations or services offered. Your travel insurance may
          provide coverage for limitations depending on the situation, therefore
          we advise filing any claims directly with them.
        </p>
        <h4 className="overview-title mt-5">Cancellation Policy</h4>
        <p className="overview-content">
          After receiving our email confirmation, if you need to amend any
          aspect of your reservation, you must contact us in writing as soon as
          possible. The main participant on the reservation should complete
          this. Even while we'll try our best to help, we can't promise that
          we'll be able to make the desired adjustment.
          <br />
          All deposit amounts are non-refundable and final. In the event of
          cancellation, we will keep your deposit on file for all time to be
          used for a future trip or date. Please be aware that if the journey is
          delayed by more than a year, the cost may alter.
          <br />
          Within 48 hours of giving cancellation notice for the original trip,
          the customer must choose a new date. 100% of the money will be
          non-refundable and non-transferable as per the aforementioned
          cancellation charge concerning the days previous to the arrival date
          if the replacement date is not received by Adventure Master. Please be
          aware that you can modify your travel dates for the same trip, but
          that the cost may rise, and we may ask you to pay a higher fare.
          <br />
          Any unsold hotel rooms, airline tickets, or other tour services or
          amenities will not be refunded or otherwise compensated. You are aware
          that unexpected things might happen while you are traveling, including
          but not limited to political unrest, natural disasters, and accidents
          or illnesses in remote areas without access to medical care. To the
          fullest degree allowed by Nepalese law, you agree to take all risks
          related to the trip.
          <br />
          If you need to cancel your trip within 30 days of the start date
          because of a significant hindrance that is beyond your control, such
          as COVID travel restrictions, a pandemic, lockdowns, strikes,
          political unrest, terrorism, etc., you will be given the option to
          apply any money you have already paid to another date or another trip
          of equal value, except any costs already incurred during the
          preparation of the trek.
          <br />
          We recognize that this is a challenging circumstance, but we'll do our
          best to collaborate with you and reach a just conclusion.
        </p>
        <h4 className="overview-title mt-5">
          If cancellation is done by the Client:{" "}
        </h4>
        <p className="overview-content">
          You have to provide us with your cancellation notice in the written
          form, and it must be approved by the company for any further
          amendments. The applicable cancellation charge is concluded depending
          on the date the company receives the request.
          <br />
          <em>
            <strgon>
              The cancellation charges are executed hereafter as a percentage of
              the total tour price:
            </strgon>
          </em>
          <ol>
            <li>
              Canceling 40 days or more before departure: 15% of full payment
              will be deducted.
            </li>
            <li>
              Canceling 20 days before departure: 50% of full payment will be
              deducted.
            </li>
            <li>
              Canceling less than 7 days before departure: 80% of full payment
              will be deducted.
            </li>
          </ol>
          <em>
            <strong>Note:</strong>
          </em>
          <strong>
            <ul>
              <li>
                For the best return, the Client is advised to get cancellation
                insurance at the time of the booking.
              </li>
              <li>
                Adventure Master will make no refund if the client voluntarily
                leaves the trip for any reason. No refunds will be given for any
                services that are not used that includes accommodation, meals,
                transport, sightseeing, or services not listed here.{" "}
              </li>
            </ul>
          </strong>
        </p>
        <h4 className="overview-title mt-5">Changes In The Itinerary By You</h4>
        <p className="overview-content">
          In case you decide to quit a trip on your own after it has started,
          there will be no reimbursements given. If you must quit a trip against
          your choice for whatever reason, Adventure Master may issue a refund
          at its discretion. For any lodging, transportation, sightseeing,
          meals, or services that are not used, there will be no reimbursements
          given. Please be aware that these terms might change.
          <br />
          If your plans change after receiving confirmation, you must give us a
          written notice at least 60 days before your departure date. Any
          expenditures or additional fees incurred as a result of the
          modification will be paid at that time. You are responsible for
          covering any costs associated with airline cancellations as well as
          any administrative fees that could result from unanticipated changes
          to your arrival time or ability to start the journey.
          <br />
          Due to the nature of trekking, the weather, local regulations, and
          other factors beyond the control of Adventure Master or its partners,
          Adventure Master retains the right to cancel the trip or amend the
          route. If this happens, you will be given the option of another trip
          of comparable quality. However, there won't be a refund given.
          <br />
          Adjustments are contingent on availability. If you cancel a
          reservation, you can ask for a credit voucher for the amount you paid.
          This must be applied to a new booking within three months of the date
          of issuing. The voucher is not transferable, may only be used for one
          booking, and cannot be used to pay for another booking. If the price
          of the new booking is less than the value of the credit voucher, there
          will be neither a refund nor another credit voucher issued.
          <br />
          When we cannot fulfill your request and you decide not to proceed with
          the original booking, we shall see this as a cancellation on your
          part.
        </p>
        <h4 className="overview-title mt-5">
          If We Change Or Cancel Your Trip
        </h4>
        <p className="overview-content">
          <strong>Note:</strong> If we need to make a little change to your
          trip, we'll attempt to let you know as soon as we can, assuming
          there's still time before your departure, but we won't be held liable.
          Minor adjustments include things like rescheduling your outgoing or
          return flights by less than 12 hours or moving to a hotel with better
          service.
          <br />
          Flexible planning is required for these excursions from both the
          organizers and the participants. The excursion's ultimate goal and the
          daily program are viewed as goals rather than legally enforceable
          obligations.
          <br />
          You must agree to this flexibility and that Adventure Master cannot be
          held liable for cancellations, delays, changes in the itinerary, or
          costs incurred as a result of major hindrances such as strikes,
          illness, weather and other forces of nature, war, quarantine,
          pandemic, political unrest, or other unforeseen circumstances before
          you can sign up for any of our excursions. Any such costs incurred
          that, under certain conditions, would be covered by your insurance are
          your responsibility.
          <br />
          To make sure you have the right coverage, you must be sure to disclose
          all of these things and conditions to your insurance carrier,
          including any planned travel to high altitudes. You must obey the
          leader's authority on a trip of this kind since they are speaking for
          Adventure Master. The trip's conduct, schedule, and goals are
          ultimately decided by the tour guide. Your assent to this is indicated
          by your signature on our reservation/booking form. You may be asked to
          leave the tour without the right to a refund if the leader determines
          that your actions or physical condition endangers the safety, welfare,
          and overall well-being of the group (which are the paramount
          considerations). This decision will be made after consulting with the
          trek medical professional if one is provided.
          <br />
          If there is time to do so before departure, we will notify you as soon
          as possible if we must make a significant change or cancel. You will
          then have the option of:
          <br />
          <ul>
            <li>Accepting the changed arrangements </li>
            <li>
              Receiving a refund of all payments made, minus the non-refundable
              deposit{" "}
            </li>
            <li>
              Accepting our offer of alternative travel arrangements of a
              comparable or higher standard, if available.
            </li>
            <li>
              Accepting, if offered, substitute arrangements of equal or
              superior quality with a refund or add-on for the price difference
              between the substitute arrangements and the original arrangements.
            </li>
            <li>
              Provide the money paid as a credit voucher to a different
              individual with your permission so they can use it to book a trip
              worth at least as much as the one that was originally booked.
            </li>
          </ul>
          Within 7 days of our offer, you must inform us of your decision. We
          will get in touch with you once again to ask for a notice of your
          choice if we don't hear from you within 7 days. We'll presume that
          you've decided to accept the adjustment or alternative booking
          arrangements if you don't react once more.
          <br />
          If we are unable to fulfill a sizable portion of the arrangements you
          have reserved with us after you have left, we will, if at all
          possible, make substitute arrangements for you at no additional cost,
          and if those substitute arrangements are of a lower caliber, we will
          give you a suitable price reduction.
        </p>
        <h4 className="overview-title mt-5">For Complaints</h4>
        <p className="overview-content">
          While we make every effort to ensure that your tour arrangements go
          successfully. If anything goes wrong, please let our tour guide know
          as soon as possible, and she/he will try to make things right. Please
          get in touch with us by phone at XXXXXXX or email at XXXXXXXXXXX if
          your issue cannot be handled locally.
          <br />
          If the issue cannot be resolved and you desire to file a formal
          written complaint with us, you must do so within 28 days after the
          conclusion of your stay, together with your booking reference and any
          other applicable details. Please be precise and direct in your letter.
          This will help us recognize your issues more immediately and respond
          to you more swiftly.
          <br />
          <em>
            <strong>
              Note: If you don't follow the instructions in this section, we and
              the relevant provider may not be able to properly investigate your
              complaint, which will have an impact on your rights under this
              contract.
            </strong>
          </em>
        </p>
        <h4 className="overview-title mt-5">COMPLIANCE AND INSURANCE</h4>
        <p className="overview-content">
          Participants consent to observing all local laws, ordinances,
          immunization requirements, and entrance requirements established for
          travel to the destination country. Participants are solely responsible
          for making all necessary preparations, such as obtaining visas.
          Adventure Master do not claim any liability if they are unable to do
          so in time for departure.
          <br />
          Participants must have insurance that includes the expense of
          evacuation and helicopter rescue if they become too unwell or injured
          to continue to participate in the trips. They must get an insurance
          plan that is intended to cover this kind of travel.
          <br />
          Throughout the trip, they must travel with proof of insurance (such as
          your insurance certificate) as they will be obliged to purchase
          appropriate coverage. If they fail to comply, they may be prevented
          from continuing the trip and will not be eligible for reimbursement
          for any services that were not rendered. (For further information
          about insurance, vaccines, equipment, and necessities, please see any
          description pages.)
        </p>
        <h4 className="overview-title mt-5">Insurance Disclaimer*</h4>
        <p className="overview-content">
          In the event of an emergency evacuation, your insurance must pay for
          them all without your insurance company's prior consent. Since,
          without a quick response, the situation in mountainous areas might
          suddenly get worse, Adventure Master retains the right to make these
          choices without having to wait for insurance companies to approve them
          to protect the safety of the visitors.
          <br />
          The costs associated with conducting the evacuation for the guest's
          safety are thus not subject to any liability on our part. It will
          entirely be the responsibility of the visitors to work with their
          insurance to meet the responsibilities. The participants must agree
          that any costs spent by Adventure Master during the process of
          rescuing and evacuating them will be covered either by them or by
          their insurance.
        </p>
        <h4 className="overview-title mt-5">PASSPORT AND VISA INFORMATION</h4>
        <p className="overview-content">
          When traveling with Adventure Master, participants must have a current
          passport and the necessary visas. You must make sure you have the
          appropriate visas for the destinations you want to visit. Please make
          sure your passport is still valid six months after the vacation ends.
          If you are denied access to a nation or location due to improper visa
          papers, we will not be responsible.
        </p>
        <h4 className="overview-title mt-5">Risks Invloved</h4>
        <p className="overview-content">
          Participants must understand that a tour of this nature necessitates
          some degree of flexibility. Understand that because of unforeseen
          local conditions, the itinerary, accommodations, and forms of
          transportation are all subject to change. Adventure Master may decide
          to shorten, alter, or reroute any trip if it is deemed necessary to do
          so for any reason. These modifications are final, and if the cause is
          beyond our control, participants are responsible for any additional
          costs. Adventure Master holds the right to cancel a trip before it is
          assured to run.
          <br />
          Unless a journey is assured to go through if it has at least 1 fully
          paid traveler. Any flights you take as part of your vacation will be
          governed by the specific airline's General Conditions of Carriage and
          Conditions of Contract, which will be noted on your airline ticket.
          These flights will also be governed by international law, which
          frequently places restrictions on the airlines' obligation to
          passengers for things like death or physical harm, delays, and luggage
          loss or damage. If required, we have the right to use different kinds
          of vehicles, transports, and/or aircraft.
          <br />
          Adventure Master do not claim all responsibility for any loss or
          damage, regardless of the circumstance. We do not, however, assume
          responsibility for any loss, damage, or accident to any luggage and
          property, even though we will always treat your luggage and personal
          items with great care while in our control (such as being carried by
          porters, yaks, and horses).
        </p>
        <h4 className="overview-title">
          HEALTH, DISABILITIES, AND MEDICAL CONDITIONS
        </h4>
        <p className="overview-content">
          In case of the presence of any medical condition or disability, which
          may directly or indirectly affect your active participation in the
          tour, please inform us before you confirm the booking so that we can
          assist you in deciding whether the proposed tour is appropriate for
          you and/or help you make the reservation. In any case, you must
          provide us with complete information at the time of booking and keep
          us updated if/when your health or impairment changes.
          <br />
          We could ask you to provide a medical note attesting to your fitness
          to do the trip. We shall not confirm the booking, cancel it, and apply
          the necessary charges if you are unable to meet the demands of the
          people in question or if you do not provide all the necessary
          information at the time of booking.
        </p>
        <h4 className="overview-title mt-5">FLIGHTS/TRANSPORT/DELAYS</h4>
        <p className="overview-content">
          Any delays or cancellations in participants' international flights
          during the arrival or departure from the destination country must be
          immediately informed to Adventure Master. Expenses incurred during the
          changes in domestic or international flights will be the participants'
          responsibility
        </p>
        <h4 className="overview-title mt-5">Foreign Office Advice</h4>
        <p className="overview-content">
          Participants are responsible for informing themselves of Foreign
          Office recommendations regarding the security of the nations and
          regions they will be visiting and base their decisions on those
          recommendations. Events outside the control of Adventure Master may
          include recommendations from the Foreign Office to stay away from or
          depart a certain nation.
        </p>
        <h4 className="overview-title mt-5">Booking From Partner Websites</h4>
        <p className="overview-content">
          Participants who book their adventure trip from a third-party website
          must agree to Adventure Masters’ terms and conditions. Trip packages
          booked from a third-party platform will be subject to the terms and
          conditions of that third-party website. Adventure Master will not be
          responsible for any refunds or assistance when booked from a
          third-party marketplace.
        </p>
        <h4 className="overview-title mt-5">
          FREE TRIP GIVEAWAY/ PROMOTIONAL CAMPAIGNS TERMS AND CONDITIONS
        </h4>
        <p className="overview-content">
          Any free trip giveaway packages or promotional trip packages may not
          appear on the Adventure Master official website as it is created with
          the purpose to satisfy the criteria for redeeming the gift. In case, a
          person fails to meet the criteria, the gift will be transferred to
          another person picked at random who fulfills the criteria.
          <br />
          Free giveaways cannot be transferred and can only be used by the
          person who earned them. The person can use it within two years from
          the day of winning the giveaway. If not, we will assume that the
          person is no more interested in using the freebie.
          <br />
          <em>
            <strong>
              *Other terms that may apply for free giveaway trip packages. We
              reserve the right to make changes to the terms at our discretion.
            </strong>
          </em>
          <br />
          Every participant must read the terms and conditions and agree to
          them. They must be aware that the trip excursion is arranged by
          Adventure Master and that it involves certain risks and dangers.
          Participants must agree to accept all the involved risks, dangers, and
          conditions as well as agree to pay any additional cost that may arise.
        </p>
      </div>
      <Footer />
    </>
  );
}

export default TermsAndCondition;
