// import React from "react";

// import ebcheli1 from "../../../image/Everest/EBC-Heli/everest-heli-tour.jpeg";
// import ebcheli2 from "../../../image/Everest/EBC-Heli/everestHeliTour.jpg";
// import ebcheli3 from "../../../image/Everest/EBC-Heli/HeliTourGallery/Adventure_master_1.JPG";
// import ebcheli4 from "../../../image/Everest/EBC-Heli/HeliTourGallery/am_1.JPG";
// import ebcheli5 from "../../../image/Everest/EBC-Heli/HeliTourGallery/am_2.JPG";
// import ebcheli6 from "../../../image/Everest/EBC-Heli/HeliTourGallery/am_3.JPG";
// import ebcheli7 from "../../../image/Everest/EBC-Heli/HeliTourGallery/am_4.JPG";
// import ebcheli8 from "../../../image/Everest/EBC-Heli/HeliTourGallery/am_5.JPG";
// import ebcheli9 from "../../../image/Everest/EBC-Heli/HeliTourGallery/am_10.JPG";
// import ebcheli10 from "../../../image/Everest/EBC-Heli/HeliTourGallery/am_11.JPG";
// import ebcheli11 from "../../../image/Everest/EBC-Heli/HeliTourGallery/am_17.JPG";
// import ebcheli12 from "../../../image/Everest/EBC-Heli/HeliTourGallery/am_18.JPG";
// import ebcheli13 from "../../../image/Everest/EBC-Heli/HeliTourGallery/am_20.JPG";
// import ebcheli14 from "../../../image/Everest/EBC-Heli/HeliTourGallery/am_21.JPG";
// import ebcheli15 from "../../../image/Everest/EBC-Heli/HeliTourGallery/am_24.JPG";
// import ebcheli16 from "../../../image/Everest/EBC-Heli/HeliTourGallery/am.JPG";
// import ebcheli17 from "../../../image/Everest/EBC-Heli/HeliTourGallery/amnt1.jpg";
// import ebcheli18 from "../../../image/Everest/EBC-Heli/HeliTourGallery/amt.JPG";
// import ebcheli19 from "../../../image/Everest/EBC-Heli/HeliTourGallery/amt1.JPG";

// function Gallery() {
//   return (
//     <div className="container adm-gallery">
//       <h4 className="my-4 text-center title">
//         Some Glimpse During Everest Heli Tour
//       </h4>

//       <div class="row">
//         <div class="col-lg-4 col-md-12 mb-4 mb-lg-0">
//           <a
//             href="./static/media/everest-heli-tour.9d544f7c3582d2460cd7.jpeg"
//             target="_blank"
//           >
//             <img
//               src={ebcheli1}
//               class="w-100 shadow-1-strong rounded mb-4"
//               alt="everest base camp helicopter tour"
//             />
//           </a>
//           <img
//             src={ebcheli2}
//             class="w-100 shadow-1-strong rounded mb-4"
//             alt="everest base camp helicopter tour"
//           />
//         </div>

//         <div class="col-lg-4 mb-4 mb-lg-0">
//           <img
//             src={ebcheli3}
//             class="w-100 shadow-1-strong rounded mb-4"
//             alt="everest base camp helicopter tour"
//           />
//         </div>
//         <div className="col-lg-4 mb-4 mb-lg">
//           <img
//             src={ebcheli4}
//             class="w-100 shadow-1-strong rounded mb-4"
//             alt="everest base camp helicopter tour"
//           />
//           <img
//             src={ebcheli6}
//             class="w-100 shadow-1-strong rounded mb-4"
//             alt="everest base camp helicopter tour"
//           />
//         </div>
//       </div>

//       <div className="row">
//         <div class="col-lg-4 mb-4 mb-lg-0">
//           <img
//             src={ebcheli3}
//             class="w-100 shadow-1-strong rounded mb-4"
//             alt="everest base camp helicopter tour"
//           />
//         </div>
//         <div className="col-lg-4 mb-4 mb-lg">
//           <img
//             src={ebcheli7}
//             class="w-100 shadow-1-strong rounded mb-4"
//             alt="everest base camp helicopter tour"
//           />
//           <img
//             src={ebcheli11}
//             class="w-100 shadow-1-strong rounded mb-4"
//             alt="everest base camp helicopter tour"
//           />
//         </div>
//         <div class="col-lg-4 mb-4 mb-lg-0">
//           <img
//             src={ebcheli8}
//             class="w-100 shadow-1-strong rounded mb-4"
//             alt="everest base camp helicopter tour"
//           />
//         </div>
//       </div>

//       <div class="row">
//         <div class="col-lg-4 col-md-12 mb-4 mb-lg-0">
//           <img
//             src={ebcheli13}
//             class="w-100 shadow-1-strong rounded mb-4"
//             alt="everest base camp helicopter tour"
//           />

//           <img
//             src={ebcheli14}
//             class="w-100 shadow-1-strong rounded mb-4"
//             alt="everest base camp helicopter tour"
//           />
//         </div>

//         <div class="col-lg-4 mb-4 mb-lg-0">
//           <img
//             src={ebcheli17}
//             class="w-100 shadow-1-strong rounded mb-4"
//             alt="everest base camp helicopter tour"
//           />
//         </div>
//         <div className="col-lg-4 mb-4 mb-lg">
//           <img
//             src={ebcheli15}
//             class="w-100 shadow-1-strong rounded mb-4"
//             alt="everest base camp helicopter tour"
//           />
//           <img
//             src={ebcheli16}
//             class="w-100 shadow-1-strong rounded mb-4"
//             alt="everest base camp helicopter tour"
//           />
//         </div>
//       </div>

//       <div className="row">
//         <div class="col-lg-4 mb-4 mb-lg-0">
//           <img
//             src={ebcheli10}
//             class="w-100 shadow-1-strong rounded mb-4"
//             alt="everest base camp helicopter tour"
//           />
//         </div>
//         <div class="col-lg-4 mb-4 mb-lg-0">
//           <img
//             src={ebcheli12}
//             class="w-100 shadow-1-strong rounded mb-4"
//             alt="everest base camp helicopter tour"
//           />
//         </div>
//         <div class="col-lg-4 mb-4 mb-lg-0">
//           <img
//             src={ebcheli19}
//             class="w-100 shadow-1-strong rounded mb-4"
//             alt="everest base camp helicopter tour"
//           />
//         </div>
//       </div>

//       <div className="row">
//         <div class="col-lg-4 mb-4 mb-lg-0">
//           <img
//             src={ebcheli18}
//             class="w-100 shadow-1-strong rounded mb-4"
//             alt="everest base camp helicopter tour"
//           />
//         </div>
//         <div class="col-lg-4 mb-4 mb-lg-0">
//           <img
//             src={ebcheli9}
//             class="w-100 shadow-1-strong rounded mb-4"
//             alt="everest base camp helicopter tour"
//           />
//         </div>
//         <div class="col-lg-4 mb-4 mb-lg-0">
//           <img
//             src={ebcheli5}
//             class="w-100 shadow-1-strong rounded mb-4"
//             alt="everest base camp helicopter tour"
//           />
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Gallery;

import * as React from "react";
import Box from "@mui/material/Box";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { Container } from "react-bootstrap";

import ebcheli1 from "../../../image/Everest/EBC-Heli/everest-heli-tour.jpeg";
import ebcheli2 from "../../../image/Everest/EBC-Heli/everestHeliTour.jpg";
import ebcheli3 from "../../../image/Everest/EBC-Heli/HeliTourGallery/Adventure_master_1a.jpg";
import ebcheli4 from "../../../image/Everest/EBC-Heli/HeliTourGallery/am_1a.jpg";
import ebcheli5 from "../../../image/Everest/EBC-Heli/HeliTourGallery/am_2a.jpg";
import ebcheli6 from "../../../image/Everest/EBC-Heli/HeliTourGallery/am_3a.jpg";
import ebcheli7 from "../../../image/Everest/EBC-Heli/HeliTourGallery/am_4a.jpg";
import ebcheli8 from "../../../image/Everest/EBC-Heli/HeliTourGallery/am_5a.jpg";
import ebcheli9 from "../../../image/Everest/EBC-Heli/HeliTourGallery/am_10a.jpg";
import ebcheli10 from "../../../image/Everest/EBC-Heli/HeliTourGallery/am_11a.jpg";
import ebcheli11 from "../../../image/Everest/EBC-Heli/HeliTourGallery/am_17a.jpg";
import ebcheli12 from "../../../image/Everest/EBC-Heli/HeliTourGallery/am_18a.jpg";
import ebcheli13 from "../../../image/Everest/EBC-Heli/HeliTourGallery/am_20a.jpg";
import ebcheli14 from "../../../image/Everest/EBC-Heli/HeliTourGallery/am_21a.jpg";
import ebcheli15 from "../../../image/Everest/EBC-Heli/HeliTourGallery/am_24a.jpg";
import ebcheli16 from "../../../image/Everest/EBC-Heli/HeliTourGallery/ama.jpg";
import ebcheli17 from "../../../image/Everest/EBC-Heli/HeliTourGallery/amnt1a.jpg";
import ebcheli18 from "../../../image/Everest/EBC-Heli/HeliTourGallery/amta.jpg";
import ebcheli19 from "../../../image/Everest/EBC-Heli/HeliTourGallery/amt1a.jpg";

export default function MasonryImageList() {
  return (
    <>
      <h4 className="title mt-5">Photo Gallery</h4>
      <Box
        className="gallery-box mb-5"
        sx={{ overflowY: "scroll" }}
        style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
      >
        <ImageList variant="masonry" cols={3} gap={8}>
          {itemData.map((item) => (
            <ImageListItem key={item.img}>
              <img
                src={`${item.img}?w=248&fit=crop&auto=format`}
                srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                alt={item.title}
                // loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
    </>
  );
}

const itemData = [
  {
    img: ebcheli1,
    title: "everest heli",
  },
  {
    img: ebcheli2,
    title: "everest heli",
  },
  {
    img: ebcheli3,
    title: "everest heli",
  },
  {
    img: ebcheli4,
    title: "everest heli",
  },
  {
    img: ebcheli5,
    title: "everest heli",
  },
  {
    img: ebcheli6,
    title: "everest heli",
  },
  {
    img: ebcheli7,
    title: "everest heli",
  },
  {
    img: ebcheli8,
    title: "everest heli",
  },
  {
    img: ebcheli9,
    title: "everest heli",
  },
  {
    img: ebcheli10,
    title: "everest heli",
  },
  {
    img: ebcheli11,
    title: "everest heli",
  },
  {
    img: ebcheli12,
    title: "everest heli",
  },
  {
    img: ebcheli13,
    title: "everest heli",
  },
  {
    img: ebcheli14,
    title: "everest heli",
  },
  {
    img: ebcheli15,
    title: "everest heli",
  },
  {
    img: ebcheli16,
    title: "everest heli",
  },
  {
    img: ebcheli17,
    title: "everest heli",
  },
  {
    img: ebcheli18,
    title: "everest heli",
  },
  {
    img: ebcheli19,
    title: "everest heli",
  },
];
