import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import CancelIcon from "@mui/icons-material/Cancel";

const TripDetail = () => {
  return (
    <>
      <div className='trip-detail container mt-5'>
        <div className='row'>
          <div className='col-2 d-none d-lg-block d-xl-block'>
            <div className='trip-sidebar'>
              <ul>
                <li>
                  <a href='#price'>Price Detail</a>
                </li>
                <li>
                  <a href='#highlight'>Highlight</a>
                </li>
                <li>
                  <a href='#highlight'>Highlight</a>
                </li>
                <li>
                  <a href='#include'>Cost Include</a>
                </li>
                <li>
                  <a href='#exclude'>Cost Exclude</a>
                </li>
                <li>
                  <a href='#disclaimer'>Disclaimer of Itinerary</a>
                </li>
                <li>
                  <a href='#important-note'>Important Note</a>
                </li>
                <li>
                  <a href='#safety'>Safety and Security</a>
                </li>
                <li>
                  <a href='#insurance'>Travel Insurance</a>
                </li>
                <li>
                  <a href='#age'>Minimum Age</a>
                </li>
                <li>
                  <a href='#time'>Best Season</a>
                </li>
                <li>
                  <a href='#tipping'>Tipping</a>
                </li>
                <li>
                  <a href='#group-leader'>Group Leader - What They Do</a>
                </li>
                <li>
                  <a href='#group-notes'>Size of Group Notes</a>
                </li>
                <li>
                  <a href='#transport'>Transport</a>
                </li>
                <li>
                  <a href='#local-flight'>Local Flight</a>
                </li>
                <li>
                  <a href='#solo-traveler'>
                    Island Peak Climbing without a guide
                  </a>
                </li>
                <li>
                  <a href='#joining'>Joining Instructions</a>
                </li>
                <li>
                  <a href='#arrival'>Arrival Complications</a>
                </li>
                <li>
                  <a href='#emergency-contact'>Emergency Contanct</a>
                </li>
                <li>
                  <a href='#visa'>Visa and Entry Requirement</a>
                </li>
                <li>
                  <a href='#money-exchange'>Money Exchange</a>
                </li>
                <li>
                  <a href='#emergency-fund'>Emergency Fund</a>
                </li>
                <li>
                  <a href='#first-aid'>First Aid</a>
                </li>
              </ul>
            </div>
          </div>

          {/*---------------------- price starts --------------------- */}
          <div className='trip-info col-md-12 col-lg-10'>
            <h4 id='price' className='trip-detail-title'>
              Price Detail
            </h4>
            Trip Price: USD $2700 pp
            {/* <h4 className="trip-detail-title">Group Discount</h4>
            1-2 pax --------- USD $1490
            <br />
            upto 5 pax ---- USD $1360
            <br />
            upto 10 pax ---- USD $1245 */}
            {/* ---------------- trip highlight starts --------------------- */}
            <h4 id='highlight' className='trip-detail-title'>
              Highlights
            </h4>
            <ul className='trip-highlight'>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>Thrilling scenic flight to Lukla from Kathmandu</li>
              </span>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Adventurous trail through alpine woods, river valleys,
                  glaciers, and moraines
                </li>
              </span>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Perfect for first-time climbers with the full support of
                  professional mountaineers
                </li>
              </span>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Trek through lush rhododendron forests along the Dudh Koshi
                  river, crossing it numerous times on hanging bridges
                </li>
              </span>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>Walk past beautiful waterfalls and terraces</li>
              </span>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Spend nights in fine mountain villages resided by many ethnic
                  groups, including Sherpas
                </li>
              </span>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Get to see the lifestyle and culture of the natives and visit
                  ancient monasteries on the way
                </li>
              </span>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Exciting acclimatization hikes to Hotel Everest View and
                  Nagarjuna Hill
                </li>
              </span>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>Explore Everest base camp and Kala Patthar </li>
              </span>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Scale Island Peak, a famous trekking peak in the Himalayas
                </li>
              </span>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Witness surreal mountain panoramas throughout the journey
                </li>
              </span>
            </ul>
            {/*---------------------- cost include starts --------------------- */}
            <h4 id='include' className='trip-detail-title'>
              Cost Include
            </h4>
            <ul className='cost-include'>
              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  All ground travel, including getting to and from the airport,
                  is done in a private car.
                </li>
              </span>
              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  One night accommodations at a hotel in Kathmandu including
                  breakfast as per the itinerary.
                </li>
              </span>
              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  During the trek, you will be provided with three meals a day:
                  breakfast, lunch, and dinner with one cup of tea each meal.
                </li>
              </span>
              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  The best available lodge with twin sharing room during the
                  trek.
                </li>
              </span>
              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  A highly experienced, helpful, knowledgeable, friendly,
                  English-speaking guide with all of his salary, food, drinks,
                  lodging, transportation, and insurance covered.
                </li>
              </span>
              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Strong, helpful porters with the right safety gear and walking
                  gear, as well as a salary, food, a place to live, and
                  insurance. (one porter for two people).
                </li>
              </span>
              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  A map of a trek and a certificate of completion of the trip.
                </li>
              </span>
              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>Taxes and official costs paid to the government.</li>
              </span>
              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  A map of a trek and a certificate of completion of the trip.
                </li>
              </span>
              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  All papers that need to be done, Fees for entering permits you
                  need to go while hiking.
                </li>
              </span>
              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>Duffle bag for trekking.</li>
              </span>
              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  First aid kit- Oxymeter to check your pulse, oxygen
                  saturation, and heart rate twice a day (very helpful to check
                  for signs of Altitude Mountain Sickness, or AMS). This will
                  make sure that your trek is in the safest hands possible, no
                  matter how experienced you are.
                </li>
              </span>
              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                  <li>The cost includes Helicopter flight charges.</li>
                </div>
              </span>
              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                  <li>
                    Round trip flight from kathmandu to Lukla, including taxes.
                  </li>
                </div>
              </span>
            </ul>
            {/*---------------------- cost exclude starts --------------------- */}
            <h4 id='exclude' className='trip-detail-title'>
              Cost Exclude
            </h4>
            <ul className='cost-exclude'>
              <span>
                <div className='cost-exclude-icon'>
                  <CancelIcon />
                </div>
                <li>
                  Food that you will eat in Kathmandu for lunch and dinner.
                </li>
              </span>
              <span>
                <div className='cost-exclude-icon'>
                  <CancelIcon />
                </div>
                <li>
                  <p>
                    The cost of a visa to enter Nepal is
                    <br />
                    $30 USD for a 15-day stay,
                    <br />
                    30 Days - $50,
                    <br />
                    90 Days - $125
                  </p>
                </li>
              </span>
              <span>
                <div className='cost-exclude-icon'>
                  <CancelIcon />
                </div>
                <li>Your insurance for travel and medical care.</li>
              </span>
              <span>
                <div className='cost-exclude-icon'>
                  <CancelIcon />
                </div>
                <li>International airfare</li>
              </span>
              <span>
                <div className='cost-exclude-icon'>
                  <CancelIcon />
                </div>
                <li>Your personal expenses.</li>
              </span>
              <span>
                <div className='cost-exclude-icon'>
                  <CancelIcon />
                </div>
                <li>
                  All of the alcoholic and non-alcoholic cold and hot drinks you
                  buy on trek (along the way and in the Tea Houses at night).
                </li>
              </span>
              <span>
                <div className='cost-exclude-icon'>
                  <CancelIcon />
                </div>
                <li>
                  All of the sweet desserts, like chocolate, cake, pie, and
                  pudding.
                </li>
              </span>
              <span>
                <div className='cost-exclude-icon'>
                  <CancelIcon />
                </div>
                <li>
                  The tea houses have hot showers and places to charge your
                  phone which will cost you about 2$ to 5$.
                </li>
              </span>
              <span>
                <div className='cost-exclude-icon'>
                  <CancelIcon />
                </div>
                <li>
                  Tip to the guide, the porter, and the driver. (Tipping is
                  expected)
                </li>
              </span>
            </ul>
            {/*---------------------- Disclaimer of Itinerary starts --------------------- */}
            <h4 id='disclaimer' className='trip-detail-title'>
              Disclaimer of Itinerary
            </h4>
            <p className='trip-detail-content'>
              As a participant in our climbing venture, it is your
              responsibility to ensure that you are in good physical condition
              and properly equipped for the demands of the trip. We stick to our
              proposed itinerary until the situation demands a change in it.{" "}
              <br />
              Climbing a mountain, even if it is technically not so advanced, is
              physically challenging and may include steep terrain and extreme
              weather conditions. You should consult with a doctor before
              participating in any of our climbing programs to ensure that you
              are able to complete the journey. <br />
              We will provide experienced guides who are trained in first aid
              and wilderness survival, help you cross any technical sections,
              and do their best to tackle any odd event. At the same time, you
              are also responsible for your actions and decisions while on the
              mountain, and you should never undertake any activity that you
              feel is beyond your abilities.
              <br />
              We will make every effort to ensure the success and enjoyment of
              your climbing venture, but we cannot be held responsible for any
              injuries, illnesses, or other adverse events that may occur during
              the trip. By participating in our Island peak climbing, you
              acknowledge and accept these risks and responsibilities.
            </p>
            {/*---------------------- important notes starts --------------------- */}
            <h4 id='important-note' className='trip-detail-title'>
              Important Notes
            </h4>
            <p className='trip-detail-content'>
              Below we have mentioned a few things that will help you during the
              Island peak climbing:
            </p>
            <ul>
              <li>
                Be cautious of altitude sickness. Symptoms of altitude sickness
                include headache, nausea, difficulty breathing, dizziness, and
                insomnia. If you experience these symptoms, stop climbing and
                rest until they subside. If they persist, seek medical attention
                immediately.
              </li>
              <li>
                Stay hydrated and nourished. The high altitude and exertion of
                trekking can cause dehydration and altitude sickness. Drink
                plenty of water and eat a balanced diet to keep your body
                healthy and strong.
              </li>
              <li>
                Follow the lead of your guide all the time, and do not take
                unnecessary risks by going off track.
              </li>
              <li>Inform your guide immediately if you see any discomfort.</li>
              <li>
                Get travel insurance that covers emergency air evacuation.
              </li>
              <li>
                Carry your favorite bars and snacks to eat while climbing.
              </li>
            </ul>
            {/*---------------------- safety and security starts --------------------- */}
            <h4 id='safety' className='trip-detail-title'>
              Safety and Security
            </h4>
            <p className='trip-detail-content'>
              Nepal's Himalayan region is a beautiful and popular destination
              for trekkers and adventurers from around the world. However, it is
              also a remote and challenging environment that requires careful
              planning and preparation. Here are some tips for staying safe and
              secure while traveling in the Himalayas of Nepal:
              <ul>
                <li>
                  Research your route and itinerary carefully. Make sure you
                  understand the challenges and potential hazards of the route
                  you will be taking.
                </li>
                <li>
                  Be prepared for extreme weather conditions. The Himalayas can
                  experience sudden and severe changes in weather, including
                  heavy rain, snow, and strong winds.{" "}
                </li>
                Make sure you have the appropriate clothing and equipment to
                keep you warm and dry.
                <li>
                  Stay alert for natural disasters. Nepal is prone to
                  earthquakes and landslides, especially in the Himalayan
                  region. Be aware of your surroundings and listen to{" "}
                </li>
                the advice of your guide or local authorities in case of an
                emergency.
                <li>
                  Use common sense and be aware of your surroundings. Avoid
                  walking alone at night, and keep your valuables secure. Trust
                  your instincts and stay alert for{" "}
                </li>
                potential dangers.
                <li>
                  Be respectful to locals and their cultural heritage. Do not
                  hurt their emotional and religious sentiments.{" "}
                </li>
              </ul>
              The key to staying safe and secure in the Himalayas of Nepal is to
              be prepared, be cautious, and be aware. By following these tips,
              you can have a safe and enjoyable adventure in Nepal.
            </p>
            {/*---------------------- safety and security starts --------------------- */}
            <h4 id='insurance' className='trip-detail-title'>
              Travel Insurance
            </h4>
            <p className='trip-detail-content'>
              If you are planning to do Island peak climbing in Nepal, it is
              strongly recommended that you purchase travel insurance before
              your trip. Travel insurance can provide financial protection in
              case of unexpected events, such as illness, injury, or natural
              disasters, that may disrupt or cancel your trip.
              <br />
              When purchasing travel insurance, make sure to choose a policy
              that covers activities such as mountain climbing. Many standard
              travel insurance policies do not cover extreme or high-risk
              activities, so it is important to select a policy that
              specifically covers mountain climbing.
              <br />
              In addition to providing financial protection, travel insurance
              can also provide access to emergency assistance services while you
              are in Nepal. This can include medical air evacuation services,
              which can be essential in the event of an injury or illness while
              climbing a peak.
            </p>
            {/*---------------------- minimum age starts --------------------- */}
            <h4 id='age' className='trip-detail-title'>
              Minimum Age
            </h4>
            <p className='trip-detail-content'>
              The minimum age requirement for climbing a peak in Nepal varies
              depending on the specific peak and the trekking company or guide
              you are using. In general, the participants have to be at least 18
              years old for peak climbing expeditions.
              <br />
              It is also important to note that age is not the only factor to
              consider when deciding whether or not to attempt the Island peak
              climbing expedition in Nepal, or not. Physical fitness and
              experience are also important considerations.
              <br />
              Peak climbing in Nepal can be physically demanding and requires a
              high level of fitness and stamina. It is recommended that
              participants have previous experience with mountain climbing and
              are in good physical condition before attempting to climb a peak
              in Nepal.
            </p>
            {/*---------------------- best time starts --------------------- */}
            <h4 id='time' className='trip-detail-title'>
              Island Peak Climbing Season
            </h4>
            <p className='trip-detail-content'>
              The best season for climbing Island Peak in Nepal is during the
              spring and autumn months. During these seasons, the weather is
              typically stable and clear, with relatively mild temperatures and
              little precipitation. This makes for ideal climbing conditions and
              offers the best chance for successful summit attempts.
              <br />
              The spring season typically runs from March to May, while the
              autumn season runs from September to November. These seasons offer
              the best visibility and the least risk of storms or other adverse
              weather conditions.
              <br />
              However, it is important to note that weather in the Himalayas can
              be unpredictable and can change quickly. Even during the best
              seasons, it is always possible for storms or other adverse
              conditions to arise. It is important to be prepared for changing
              weather and to have a backup plan in case the weather does not
              cooperate.
            </p>
            {/*---------------------- tipping starts --------------------- */}
            <h4 id='tipping' className='trip-detail-title'>
              Tipping
            </h4>
            <p className='trip-detail-content'>
              Tipping your mountain guide and porter is a common practice in
              Nepal and is greatly appreciated by these hardworking individuals.
              It is a way to show your appreciation for their efforts and to
              acknowledge the valuable contribution they make to your trekking
              experience.
              <br />
              The amount that you choose to tip your guide and porter is
              entirely up to you and will depend on your budget and personal
              preference. There is no set amount or percentage that is
              considered standard or expected.
              <br />
              When deciding how much to tip, consider factors such as the length
              of the trek, the difficulty of the route, and the quality of the
              service provided. You can also ask your trekking company or other
              trekkers for their suggestions or recommendations.
            </p>
            {/*---------------------- Activities You Can Choose starts --------------------- */}
            <h4 id='group-leader' className='trip-detail-title'>
              Group Leader - What They Do
            </h4>
            <p className='trip-detail-content'>
              You'll climb Island peak with a responsible mountaineer who will
              be leading the group, providing guidance and instruction on
              mountain climbing techniques and safety measures. He will also be
              responsible for managing the logistics of the expedition,
              including organizing camps, arranging meals and supplies, and
              coordinating with support staff.
              <br />
              In addition to the technical and logistical skills, our Sherpa
              guides are also skilled at managing group dynamics and ensuring
              that all members of the group are working together and supporting
              each other. They are also knowledgeable about the local culture
              and customs and can provide valuable insights and information
              about the places and people you will encounter on your trail.
            </p>
            {/*---------------------- Size of Group Notes starts --------------------- */}
            <h4 id='group-notes' className='trip-detail-title'>
              Size of Group Notes
            </h4>
            <p className='trip-detail-content'>
              In general, our peak climbing groups tend to be small, with an
              average size of around 4-8 people. This allows us to offer a more
              personalized and intimate experience and allows the group to move
              more quickly and efficiently through the mountains.
              <br />
              However, it is important to note that the ideal group size will
              vary depending on the specific circumstances of the expedition.
              Some peaks and routes may be better suited to larger or smaller
              groups, and the preferences and abilities of the group members
              should also be taken into account. <br />
              This maximum size is determined based on factors such as the route
              being used, the availability of support staff and equipment, and
              the carrying capacity of the area.
            </p>
            {/*---------------------- transport starts --------------------- */}
            <h4 id='transport' className='trip-detail-title'>
              Transport
            </h4>
            <p className='trip-detail-content'>
              We provide private transportation for airport pick-up and
              drop-off, as well as for any included sightseeing tours. The
              Everest base camp trek itinerary includes a short domestic flight
              between Kathmandu and Lukla airports. All the transportation will
              be provided as per our Island peak climbing itinerary.
            </p>
            {/*---------------------- local flights starts --------------------- */}
            <h4 id='local-flight' className='trip-detail-title'>
              Local flight
            </h4>
            <p className='trip-detail-content'>
              Kathmandu Lukla flight: The Kathmandu Lukla flight is a popular
              and scenic route that connects the capital city of Kathmandu with
              the mountain town of Lukla, which is the starting point of our
              Island Peak climbing with Everest base camp trek.
              <br />
              Delays on the Kathmandu Lukla flight are common and can be caused
              by a variety of factors, including weather conditions, technical
              issues, and logistical challenges. The mountainous terrain and
              unpredictable weather in the Everest region can make it difficult
              for flights to operate on schedule, and delays are often
              inevitable. So, always have a contingency day.
              <br />
              In case our flight gets delayed or canceled, we use the next
              possible flight. You also have the option to charter a helicopter
              from Lukla to Kathmandu or vice versa.
            </p>
            {/*---------------------- solo travelers starts --------------------- */}
            <h4 id='solo-traveler' className='trip-detail-title'>
              Island Peak Climbing without a guide
            </h4>
            <p className='trip-detail-content'>
              Climbing Island Peak without a guide can be dangerous and is not
              recommended for inexperienced climbers. The route is steep and
              technical, and the high altitude and unpredictable weather can
              make it even more challenging.
              <br />
              Moreover, climbing without a guide is illegal in Nepal. The
              Nepalese government requires all climbers to be accompanied by a
              licensed guide, and failure to do so can result in fines or other
              penalties. You have to be accompanied by a licensed guide to climb
              any peak or mountain in Nepal. <br />
              And we happen to have the best climbing guides out there!
            </p>
            {/*---------------------- joining instructions starts --------------------- */}
            <h4 id='joining' className='trip-detail-title'>
              Joining Instructions
            </h4>
            <p className='trip-detail-content'>
              If you want to join our Island peak climbing 2023, get in touch
              with us anytime to book a spot. Our team will provide you with all
              the necessary information regarding booking, preparation, and
              cancellation. We highly recommend you go through our cancellation
              policy in terms & conditions.
              <br />
              You can send us a booking inquiry here or talk to our travel
              consultant if you have any questions.
            </p>
            {/*---------------------- arrival complications starts --------------------- */}
            <h4 id='arrival' className='trip-detail-title'>
              Arrival Complications
            </h4>
            <p className='trip-detail-content'>
              If you encounter complications upon arrival in Nepal, such as visa
              issues, flight delays, or difficulty meeting with airport
              representatives, there are several steps you can take to address
              these issues and ensure a smooth start to your trip.
              <br />
              First, if you are having difficulties with your visa, you can
              contact the Nepalese embassy or consulate in your home country for
              assistance. They can provide information on visa requirements and
              procedures and can help you to obtain a visa if necessary.
              <br />
              If your flight is delayed, you should contact us as soon as
              possible to inform us about the delay. We can assist with
              arranging alternative transportation or accommodations if
              necessary. If you are unable to meet with airport representatives,
              you can try to contact them by phone or email to let them know of
              your arrival and to arrange a meeting.
            </p>
            {/*---------------------- emergency contact starts --------------------- */}
            <h4 id='emergency-contact' className='trip-detail-title'>
              Emergency Contact
            </h4>
            <p className='trip-detail-content'>
              Here are our emergency contact numbers +977 9851033195, +44
              7983806200 and +977 9849780311. You can reach out to us at these
              numbers anytime and talk to our travel consultant. These numbers
              also have our 24/7 WhatsApp/Viber service. <br />
              Likewise, you can call us at our office number during office hours
              (Weekdays, 9 am to 5 pm local time)
            </p>
            {/*---------------------- visa and entry requirements starts --------------------- */}
            <h4 id='visa' className='trip-detail-title'>
              Visa and Entry Requirements
            </h4>
            <p className='trip-detail-content'>
              To travel to Nepal, you will need a valid passport with at least
              six months of remaining validity. You may be able to obtain a visa
              upon arrival in Nepal at Tribhuvan International Airport or other
              entry points but check with Nepal's embassy or consulate to make
              sure that your country is eligible for an on-arrival visa. <br />
              It is your responsibility to obtain a visa, so make sure to
              research the requirements and bring the necessary documents with
              you. We can provide you with a trip confirmation letter, but
              please note that the company will not be responsible if you are
              unable to obtain a visa.
            </p>
            {/*---------------------- money exchange starts --------------------- */}
            <h4 id='money-exchange' className='trip-detail-title'>
              Money Exchange
            </h4>
            <p className='trip-detail-content'>
              When traveling to Nepal, exchange your home currency for the local
              currency, the Nepalese Rupee. This will make it easier to pay for
              things, like transportation, food, and souvenirs while you are
              there. You can exchange money at banks, money exchange counters at
              the airport, or authorized money changers in major cities. <br />
              Keep in mind that you may need to present your passport to
              complete the transaction. It is also a good idea to carry some
              small bills, as it can be difficult to break larger denominations
              at some shops and restaurants. It is always a good idea to check
              the current exchange rate before you go, so you have an idea of
              how much money to bring with you.
              <br />
              You may get to use your credit card to pay your bills in the
              hotels, restaurants, cafes, and shops in Kathmandu. But in the
              mountains, you have to use Nepali currency to pay. There is an ATM
              in Namche Bazaar, where you can withdraw money if you forget to
              withdraw in Kathmandu.
            </p>
            {/*---------------------- emergency fund starts --------------------- */}
            <h4 id='emergency-fund' className='trip-detail-title'>
              Emergency Fund
            </h4>
            <p className='trip-detail-content'>
              An emergency fund is a reserve of money that you can use in case
              of unforeseen circumstances while traveling. This could include
              things like medical expenses, unexpected travel delays, or
              emergency flights home. It is important to have an emergency fund,
              especially when traveling to a foreign country because unexpected
              events can happen, and you want to be prepared. <br />
              The amount of money you should have in your emergency fund will
              depend on your situation, but it is generally a good idea to have
              at least a few hundred dollars available. You can keep your
              emergency fund in a separate savings account, or you can bring the
              money with you in the form of cash or traveler's checks. Just make
              sure to keep it in a safe place so you can access it if you need
              it.
            </p>
            {/*---------------------- emergency fund starts --------------------- */}
            <h4 id='first-aid' className='trip-detail-title'>
              First-Aid
            </h4>
            <p className='trip-detail-content'>
              Our crew is equipped with a first-aid kit, so you do not have to
              bring your personal first-aid kit. We have all the necessary
              medicines and tools in our first-aid kit to provide quick medical
              assistance. Our guides are well trained to give first-aid in the
              mountains, so you can rely on them. <br />
              If you are on any medications, let us know, and consult your
              doctor. We will be spending numerous days above 5,000 meters
              acclimatizing. So you have to be sure that your medicine does not
              get affected because of high elevation.
            </p>
            <h4>Packing list for Island Peak Climbing</h4>
            <strong>Head and Hand</strong>
            <ul>
              <li>Balaclava </li>
              <li>Neck Gaiter/High Neck</li>
              <li>Sun Cap </li>
              <li>Wool/Fleece Hat </li>
              <li>Lightweight Synthetic Liner Glove </li>
              <li>Wind Stopper Fleece Gloves </li>
              <li>Heavy Gloves (Mitten) </li>
            </ul>
            <strong>Upper Body</strong>
            <ul>
              <li>Lightweight Top/Thermo Coat </li>
              <li>Short-Sleeved Shirts/T-shirts </li>
              <li>Midweight Top</li>
              <li>Down Insulated Jacket </li>
              <li>Synthetic or Fleece Jacket </li>
              <li>Gore-Tex Jacket</li>
            </ul>
            <strong>Lower Body</strong>
            <ul>
              <li>Lightweight Long Underpants </li>
              <li>Midweight Long Underpants </li>
              <li>Trekking Pants </li>
              <li>Gore-Tex Pants </li>
              <li>Synthetic Insulated Pants </li>
              <li>Underwear</li>
              <li>Summit Socks </li>
              <li>Trekking & Climbing Socks </li>
            </ul>
            <strong>Trekking Devices/Climbing Gears</strong>
            <ul>
              <li>Harness </li>
              <li>Carabiners (Both Lock & Unlock) </li>
              <li>Ascenders/Jumar</li>
              <li>Climbing Helmet </li>
              <li>Headlamp with Spare Batteries (Petzl/BD) </li>
              <li>Ice Axe Semi-technical </li>
              <li>Crampons </li>
              <li>1 Sleeping Bag: -30 +C to -40 +C </li>
              <li>Thermarest Cell Foam Mattress </li>
              <li>Belay Device (ATC Guide/Figure of 8) </li>
              <li>Assistant Rope </li>
              <li>Tape Sling </li>
              <li>Ice Screw</li>
              <li>Summit Boot/G2SM </li>
              <li>Snow Goggle UV Protection </li>
              <li>Sun/Glacier Glasses UV Protection </li>
              <li>Extendable Trekking Poles (BD Alpine Flz) </li>
            </ul>
            <strong>First Aid Kit</strong>
            <ul>
              <li>Water Purification Tablets </li>
              <li>Sunscreen (-50 Spf) </li>
              <li>Lips Guard (-20/-50 Spf) </li>
              <li>Zip-Lock Bags</li>
              <li>Baby Wipes or Wet Towels </li>
              <li>Handy Plaster </li>
              <li>Crack Bandage </li>
              <li>Tincture </li>
              <li>Lodine </li>
              <li>Aspirin </li>
              <li>Sinex </li>
              <li>Strepsils </li>
              <li>Antibiotic </li>
              <li>Paracetamol</li>
              <li>Anti-Diarrhea Capsule/Eldoper </li>
              <li>Brufen/Ibuprofens </li>
              <li>Diamox </li>
              <li>Eye Drops </li>
            </ul>
            <strong>Toilatries</strong>
            <ul>
              <li>Toothpaste </li>
              <li>Toothbrush Hand Sanitizers & Hand wash </li>
              <li>Wet Tissues</li>
              <li>Laundry Soap </li>
              <li>Garbage Bag </li>
              <li>Bath Soap </li>
              <li>Shampoo</li>
              <li>Moisturizers </li>
            </ul>
            <strong>Miscellaneous</strong>
            <ul>
              <li>Rucksacks (45 - 55 Ltr.) </li>
              <li>1 Duffel Bag </li>
              <li>Water Bottles </li>
              <li>Thermos/Flask </li>
              <li>Swiss Knife </li>
              <li>Pee Bottle </li>
              <li>Pack Towel </li>
              <li>Umbrella/RainCoat </li>
              <li>Spare batteries </li>
              <li>Waterproof Stuff Sacks </li>
              <li>Sandals </li>
              <li>Lightweight Hiking/Trekking Boots </li>
              <li>Camp Booties</li>
              <li>Satellite Phone (if possible)</li>
              <li>Solar chargeable power bank (optional)</li>
              <li>Book/Board Game</li>
              <li>Journal/Pen</li>
              <li>Bars & Snacks</li>
            </ul>
            <strong>Travel Document</strong>
            <ul>
              <li>Valid Visa </li>
              <li>Valid Passport </li>
              <li>4 PP Size Photo </li>
              <li>Family/Company Contact Number & Address </li>
              <li>Insurance Paper (Medical & Rescue) & Contact Address </li>
              <li>Credit Card</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default TripDetail;
