import React from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { HashLink } from "react-router-hash-link";

import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import GroupIcon from "@mui/icons-material/Group";

function createData(date, available, status, book) {
  return { date, available, status, book };
}

const rows = [
  createData(
    <span>
      <EventAvailableIcon className='me-2 text-success' />
      April 3, 2023
    </span>,
    <span>
      <GroupIcon className='me-2 text-success' />
      4/12
    </span>,
    <span className='badge rounded-pill text-bg-danger departure-date-badge'>
      <CheckCircleIcon className='me-2' />
      Closed
    </span>
    // <a href="/everest-base-camp-trek" target="_blank" className='btn btn-success btn-sm'>Book Now</a>
  ),

  createData(
    <span>
      <EventAvailableIcon className='me-2 text-success' />
      April 17, 2023
    </span>,
    <span>
      <GroupIcon className='me-2 text-success' />
      2/12
    </span>,
    <span className='badge rounded-pill text-bg-danger departure-date-badge'>
      <CheckCircleIcon className='me-2' />
      Closed
    </span>
    // <a href="/everest-base-camp-helicopter-return-trek-9-days" target="_blank" className='btn btn-success btn-sm'>Book Now</a>
  ),
  createData(
    <span>
      <EventAvailableIcon className='me-2 text-success' />
      May 7, 2023
    </span>,
    <span>
      <GroupIcon className='me-2 text-success' />
      12/12
    </span>,
    <span className='badge rounded-pill text-bg-danger departure-date-badge'>
      <CheckCircleIcon className='me-2' />
      Closed
    </span>
    // <a href="/everest-base-camp-helicopter-return-trek-9-days" target="_blank" className='btn btn-success btn-sm'>Book Now</a>
  ),
  createData(
    <span>
      <EventAvailableIcon className='me-2 text-success' />
      May 15, 2023
    </span>,
    <span>
      <GroupIcon className='me-2 text-success' />
      3/12
    </span>,
    <span className='badge rounded-pill text-bg-danger departure-date-badge'>
      <CheckCircleIcon className='me-2' />
      Closed
    </span>
    // <a href="/everest-base-camp-helicopter-return-trek-9-days" target="_blank" className='btn btn-success btn-sm'>Book Now</a>
  ),
];

function AnnapurnaCircuitDepartureDateModal() {
  return (
    <div>
      <div
        class='modal fade luxury-package-modal'
        id='annapurnacircuitDepartureDateModal'
        aria-hidden='true'
        aria-labelledby='annapurnacircuitDepartureDateModalLabel'
        tabindex='-1'
      >
        <div class='modal-dialog modal-dialog-centered departure-date-modal'>
          <div class='modal-content'>
            <div class='modal-header'>
              <h1
                class='modal-title fs-5'
                id='annapurnacircuitDepartureDateModalLabel'
              >
                Annapurna Circuit With Tilicho Lake and Poonhill Trek Fix
                departure dates
              </h1>
              <button
                type='button'
                class='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>
            <div class='modal-body'>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell align='right'>Availibility</TableCell>
                      <TableCell align='right'>Status</TableCell>
                      <TableCell align='right'></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow
                        key={row.date}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component='th' scope='row'>
                          {row.date}
                        </TableCell>
                        <TableCell align='right'>{row.available}</TableCell>
                        <TableCell align='right'>{row.status}</TableCell>
                        <TableCell align='right'>{row.book}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <div className='text-center fs-6'>
                  <em>
                    For inquiry please send us message at:{" "}
                    <a
                      href='mailto:info@adventuremastertrek.com'
                      target={"_blank"}
                    >
                      info@adventuremastertrek.com
                    </a>
                  </em>
                </div>
              </TableContainer>
            </div>
            <div class='modal-footer'>
              <HashLink
                smooth
                to='/annapurna-circuit-with-tilicho-lake-and-poon-hill-17-days'
                className='link'
              >
                <button
                  class='btn btn-dark'
                  data-bs-target='#annapurnacircuitDepartureDateModal2'
                  data-bs-toggle='modal'
                >
                  View Trip
                </button>
              </HashLink>
              <button
                class='btn btn-outline-secondary'
                data-bs-target='#annapurnacircuitDepartureDateModal2'
                data-bs-toggle='modal'
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className='d-flex justify-content-center py-2'>
        <button
          class='btn btn-warning'
          data-bs-target='#annapurnacircuitDepartureDateModal'
          data-bs-toggle='modal'
        >
          View fix departure dates
        </button>
      </div>
    </div>
  );
}

export default AnnapurnaCircuitDepartureDateModal;
