import React from "react";
import TripCard from "../../components/TripCard/EverestRegion/TripCard";
import MainImage from "../../../image/Everest/ebc.jpeg";
import Footer from "../../components/Footer";
import wave from "../../../image/pattern.svg";
import cloud1 from "../../../image/clouds/cloud1.png";
import cloud2 from "../../../image/clouds/cloud2.png";
import cloud3 from "../../../image/clouds/cloud3.png";
import cloud4 from "../../../image/clouds/cloud4.png";
import cloud5 from "../../../image/clouds/cloud5.png";

import { cardInfo } from "../../components/TripCard/AnnapurnaRegion/AnnapurnaRegionTripCardInfo";
import AnnapurnaTable from "../../components/AnnapurnaTable";
import ScrollToTop from "../../components/ScrollToTop";

const MainEverest = () => {
  return (
    <>
      <ScrollToTop position="top" />
      <div>
        <div className="annapurna-region-header">
          {/* <section className="annapurna-region banner-wave">
          <img className="wave" src={wave} />
          <img className="cloud-animate" src={cloud1} style={{ "--i": 1 }} />
          <img className="cloud-animate" src={cloud2} style={{ "--i": 2 }} />
          <img className="cloud-animate" src={cloud3} style={{ "--i": 3 }} />
          <img className="cloud-animate" src={cloud4} style={{ "--i": 4 }} />
          <img className="cloud-animate" src={cloud5} style={{ "--i": 5 }} />
        </section> */}
        </div>

        <div className="container my-5">
          <h1 className="title-1 text-center text-lg">Explore Annapurna Region</h1>
          <TripCard cardInfo={cardInfo} />
        </div>

        <AnnapurnaTable />

        <div className="container">
          <h2 className="mt-5 title">Annapurna Region</h2>
          <p className="content mb-5">
            One other Nepalese peak, Annapurna, has joined Everest as a symbol of
            extreme adventure. Superlatives fall short of capturing the allure of
            this beautiful massif's tempting pathways, breathtaking landscape, and
            fascinating culture. It seems sense that devoted hikers and
            mountaineers would be drawn to these captivating peaks. When he wrote:
            Escape from the shell of your tiny affairs and follow for a while
            those strange trails of the spirit that lead nowhere and everywhere in
            his 1947 anthology "The Mountain Top," Frank Smith encapsulated the
            allure of trekking. You will then understand beauty. Such is the
            Annapurnas' character.
            <br />
            The term Annapurna, which is derived from a prehistoric Sanskrit word
            for the goddess of abundance, refers to a group of four peaks that are
            numbered according to height. When the sun first rises, the jagged
            spires are bathed in a beautiful, hazy light. However, soon after,
            clouds move in, leaving the jagged spires hovering like friendly
            ghosts. They are shrouded in fiery demons during sunset, and at night
            they glitter in the moonlight. While storms occasionally engulf the
            rampart, other times they appear as a vision of tranquility on earth
            in the cold waters of a lake.
            <br />
            <br />
            The Annapurna Circuit and the Annapurna Sanctuary are the two
            well-known treks in the area, and Adventure Master Trek cordially
            invites you to join us on one of them.
            <br />
            <br />
            The circuit offers an astounding variety of scenery, including
            terraced hillsides, thick rainforest, steamy bamboo clusters, cloud
            forest canopies, dreary, scary woodlands, and quiet alpine glades. The
            countryside is dotted with charming farmhouses, and women washing
            clothes by the river wear vibrant saris. A Hindu god may capture your
            attention somewhere else and grin at you.
            <br />
            <br />
            The ground is bare higher up. Fairy-tale citadels are supported by
            strangely eroded towers, which cast shadows over medieval settlements
            and hermit caves. Buddhist monks sing dramatic chants in monasteries
            draped in colorful prayer flags that cling to precipitous rock faces
            while being partially entranced by the fragrant juniper incense. The
            upper ramparts, which are guarded by Himalayan Thar and Snow Leopard,
            conceal countless birds with an astounding variety of vegetation. The
            Annapurna Circuit is still among the world's top hikes.
            <br />
            <br />
            For the Annapurna Sanctuary Trek, picture a nearly full ring of high
            peaks, six of which rise beyond 7000 meters, and one, the enigmatic
            Annapurna, which rises at 8000 meters.
            <br />
            <br />
            Imagine this enormous amphitheater surrounded by glaciers, covered
            with snowfields, and supported by towering rock walls that rise from a
            basin of ancient moraines and raging streams. If you like, imagine a
            silver moon illuminating the picture, a sunrise highlighting the
            mountaintops, and mist building into clouds that gradually fill the
            basin. And one of the most charismatic summits is the most recent one
            to be overtaken by it all:
            <br />
            <br />
            Machhapuchhre, the recognizable "fishtail" peak, is the protector of
            the Annapurna Sanctuary and the world's greatest seducer. There are
            stunning sights everywhere you look. If there is a heaven on earth, it
            is now, it is now, it is now, said renowned poet and mountaineer
            Wilfred Noyce in the epilogue to his book about Machhapuchhre's
            effort.
          </p>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default MainEverest;
