import React from "react";
import { Col, Row } from "react-bootstrap";
import { FaMountain, FaUtensils, FaBed, FaHiking } from "react-icons/fa";
import { FaBus } from "react-icons/fa";
import { FaClock } from "react-icons/fa";

import "../../../custom-sass/pages/trip/_trip-detail.scss";
import Map from "../../../image/Map/ManasluCircuitTrekMap.jpg";
import Pdf from "../../../itineraryDocuments/ManasluRegion/ManasluCircuitTrek.pdf";

import day1 from "../../../image/kathmandu.jpeg";
import day2 from "../../../image/Manaslu/manasluTrek/1.jpg";
import day3 from "../../../image/Manaslu/manasluTrek/2.jpg";
import day4 from "../../../image/Manaslu/manasluTrek/3.jpg";
import day5 from "../../../image/Manaslu/manasluTrek/4.jpg";
import day6 from "../../../image/Manaslu/manasluTrek/5.jpg";
import day7 from "../../../image/Manaslu/manasluTrek/6.jpg";
import day8 from "../../../image/Manaslu/manasluTrek/7.jpg";
import day9 from "../../../image/Manaslu/manasluTrek/8.jpg";
import day10 from "../../../image/Manaslu/manasluTrek/9.jpg";
import day11 from "../../../image/Manaslu/manasluTrek/10.jpg";
import day12 from "../../../image/Manaslu/manasluTrek/11.jpg";
import day13 from "../../../image/Manaslu/manasluTrek/12.jpg";
import day14 from "../../../image/Manaslu/manasluTrek/13.jpg";

const Itinerary = () => {
  const days = [
    {
      day1: [
        {
          title: "Day 1: Arrival in Kathmandu",
          image: day1,
          caption:
            "At the Tribhuvan International Airport, our staff will be there to meet and greet you. You will be gathered by the team from the terminal at the airport. They will provide a private vehicle for you to ride in as you are taken to the specified hotel. Later , we will meet you at our office so that we can introduce you to the mountain guide. We will finish preparing for our trip by packing our bags. Spend the last part of the day wandering around Thamel and indulging in a tasty Nepalese dinner.",
          tripDetail: [
            {
              icon: "FaMountain",
              detailTitle: "Maximum Altitude",
              detailCaption: "1400m",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "Null",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Hotel",
            },
          ],
        },
      ],
      day2: [
        {
          title:
            "Day 2: Travel by vehicle from Kathmandu to Soti Khola, which is located at 2,896 feet (730 meters), via Arughat.",
          image: day2,
          caption:
            "On this day, we leave Kathmandu and make the journey by car to Soti Khola. It marks the beginning of our journey through the wilderness. The scenery along the way is absolutely breathtaking. We will have the opportunity to view mountains, rivers, mountains, and terraced fields. As the ride draws to a close, the pavement begins to exhibit some undulations, signaling that we have reached a more mountainous region.",
          tripDetail: [
            {
              icon: "FaBus",
              detailTitle: "Distance",
              detailCaption: "160 km/99.4 miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "(6/8 hours)",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Teahouse",
            },

            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B, L, D",
            },
          ],
        },
      ],
      day3: [
        {
          title: "Day 3: Trek from Soti Khola to Machha Khola (2,965 ft/890 m)",
          image: day3,
          caption:
            "The path that we took to get to Maccha Khola, which was our destination for the day, began at Soti Khola. This is a lovely and undemanding hike that takes you through sal forests and past cascading waterfalls. On our journey, we might come across some mules. Machhakhola is reached after a precipitous ascent that passes through Lapubesi (884 m) and Khanibesi (970 m). A bridge will need to be traversed in order for us to enter Maccha Khola. The view to the northeast of Ganesh Himal from Macchia Khola is absolutely breathtaking.",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Trek Distance",
              detailCaption: "9.7 km/6 miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "5/6 horus",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Teahouse",
            },
            {
              icon: "FaMountain",
              detailTitle: "Maximum Altitude",
              detailCaption: "890m",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B, L, D",
            },
          ],
        },
      ],
      day4: [
        {
          title: "Day 4: Travel from Machhakhola to Jagat (1,410 meters/4,625 feet)",
          image: day4,
          caption:
            "On the way up from Machha Khola to Jagat village, the path travels over a suspension bridge and goes by Khorlabeshi, which is located 970 meters above sea level. On our way to Tatopani, we will follow the Budhi Gandaki river and go through the area of the landslide (990 m). From Tatopani to Jagat, there are two different routes to take. The alternative path that goes through Dobhan (1,070 m) and Yaruphant will be the one that we take (1,170 m). Along the way, there are great mountain views as well as bridges, waterfalls, and areas that are prone to landslides. As we make our way to Jagat village from Yaruphant, we make our way past the rockfall area and along a stone-paved path.",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Trek Distance",
              detailCaption: "22.2 km/13.7 miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "6/7 hours",
            },
            {
              icon: "FaMountain",
              detailTitle: "Maximum Altitude",
              detailCaption: "890m",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Teahouse",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B, L, D",
            },
          ],
        },
      ],
      day5: [
        {
          title: "Day 5: From Jagat to Deng, a distance of 1,804 meters (5,917 feet)",
          image: day5,
          caption:
            "We begin our journey to Salleri by crossing a bridge and then walking along the Budhi Gandaki river until we reach our destination (1,353 m). The path continues to climb to Sirdibas, which is located at an elevation of 1,420 meters, and then continues along a winding path that leads to Philim, which is located at an elevation of 1,570 meters. After registering for our MCAP permit, we will take a stroll alongside the Ekle Bhatti river (1,600 m). The path climbs up via Thangurmu, which is 1,660 meters in elevation, and then crosses a brand-new suspension bridge at Lokpa (2,240 ). The path leads through a bamboo forest and gradually gains elevation as it makes its way to Deng. Today will be the day that we enter Nupri, the mountainous western region.",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Trek Distance",
              detailCaption: "19.9 km/12.3 miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "6/7 horus",
            },
            {
              icon: "FaMountain",
              detailTitle: "Maximum Altitude",
              detailCaption: "1804m",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B, L, D",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Teahouse",
            },
          ],
        },
      ],
      day6: [
        {
          title: "Day 6: Deng to Namrung (2,630 m/8,626 ft)",
          image: day6,
          caption:
            "Immediately after leaving the village of Deng, we will travel across the Budh Gandaki river on a bridge. On the way to Rana, the path passes through some beautiful forests filled with rhododendron, fir, and oak (1,910 m). From this point, we continue our ascent to Bihi by following a path that gradually rises to Bhi Phedi, which is 1,990 meters away (2,130 m). We go an alternate way through the village of Prok, which is at an elevation of 2,397 meters and is a viewpoint of the Siringi Himal. On our way to Namrung village, we pass a waterfall and then continue through an area that is prone to landslides. We will travel over three suspension bridges on the way to our destination.",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Trek Distance",
              detailCaption: "19.5 km/12.1 miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "5/6 horus",
            },
            {
              icon: "FaMountain",
              detailTitle: "Maximum Altitude",
              detailCaption: "1804m",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B, L, D",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Teahouse",
            },
          ],
        },
      ],
      day7: [
        {
          title: "Day 07: Namrung to Shyala (3,500 m/11,482 ft)",
          image: day7,
          caption:
            "The village of Lihi can be reached by following the path that winds through the alpine woodlands (2,920 m). Up until the village of Sho, there are numerous landslides visible (2,880 m). In this section of the trail, the vistas of Himchuli are absolutely breathtaking. We will continue our hike to Lho from Sho by way of the region that was affected by the earthquake (3,180 m). Along the entirety of the trail are a great number of stupas, chortens, and mani walls. After that, we take a trail that gradually declines in elevation before beginning our ascent to Shyala (3,500 m).",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Trek Distance",
              detailCaption: "10 km/6.21 miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "5 horus",
            },
            {
              icon: "FaMountain",
              detailTitle: "Maximum Altitude",
              detailCaption: "3500m",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B, L, D",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Teahouse",
            },
          ],
        },
      ],
      day8: [
        {
          title:
            "Day 8: You will hike to Pung Gyen Gompa, which is located at 4,800 meters (15,748 feet), and then walk to Samagaun, which is located at 3,520 meters (11,548 feet), where you will spend the night",
          image: day8,
          caption:
            "After leaving Shyala, we walk for about half an hour before reaching a bridge. Pung Gyen Gompa is reached via a detour to the south of Samagaun on the trail. Pung Gyen Glacier is visible to us while we are traveling there. We get to take in some breathtaking vistas of snow-covered peaks such as Manaslu, Simnang Himal, Naike Peak, and Himahchuli, amongst others. Following our exploration of the gompa, we will continue on a steep and rocky path leading uphill to Samagaun, where we will spend the night.",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Duration",
              detailCaption: "10.8 km/6.71 miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "6 hours",
            },
            {
              icon: "FaMountain",
              detailTitle: "Maximum Altitude",
              detailCaption: "4,800 m/15,748 ft",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B, L, D",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Teahouse",
            },
          ],
        },
      ],
      day9: [
        {
          title: "Day 9: Acclimatization day in Samagaun",
          image: day9,
          caption:
            "In order to better acclimatize ourselves to the altitude, we will remain in Samagaon for one more day. Hiking up to the Birendra Tal is on the agenda for today (lake). We climb up through a series of switchbacks until we reach a junction, and then we turn left to head toward the lake. The lake and the rolling hills and towering mountains that surround it are both breathtaking sights.",
          tripDetail: [
            // {
            //   icon: "FaHiking",
            //   detailTitle: "Duration",
            //   detailCaption: "8.2 km/5 miles",
            // },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "4/5 hours",
            },
            // {
            //   icon: "FaMountain",
            //   detailTitle: "Maximum Altitude",
            //   detailCaption: "3875",
            // },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B, L, D",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Teahouse",
            },
          ],
        },
      ],
      day10: [
        {
          title: "Day 10: Samagaun to Samdo (3,875 m/12,713 ft) Kathmandu",
          image: day10,
          caption:
            "The path that leads from Samagaon to Samdo involves a strenuous ascent up a vertical incline. We are currently traveling through juniper and birch forests. Mount Manaslu can be seen in its entirety as hikers make their way up the trail that follows the Budhi Gandaki river. The Tibetan refugee community of Samdo is characterized by its expansive mani walls and numerous stupas.",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Duration",
              detailCaption: "8.2 km/5 miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "3/4 horus",
            },
            {
              icon: "FaMountain",
              detailTitle: "Maximum Altitude",
              detailCaption: "3875m",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B, L, D",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Teahouse",
            },
          ],
        },
      ],
      day11: [
        {
          title:
            "Day 11: Samdo to Larke Phedi (Dharamsala) (4,480 m/14,698 ft)",
          image: day11,
          caption:
            "From Samdo to Larke Phedi is only a short distance on foot. This location is also referred to by the name Dharmasala. After climbing up from Samdo, we will make our way to a bridge that spans the Budhi Gandaki river. The trail then passes through the town of Larkya Bazar (4,090 m). As we make our way to Larke Phedi, we will walk alongside mani walls and stone huts. There are glaciers that we can see along the way.",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Duration",
              detailCaption: "11.7 km/7.2 miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "4/5 horus",
            },
            {
              icon: "FaMountain",
              detailTitle: "Maximum Altitude",
              detailCaption: "4480m",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B, L, D",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Teahouse",
            },
          ],
        },
      ],
      day12: [
        {
          title:
            "Day 12: Dharamsala to Bhimtang (3,720 m/12,205 ft) by crossing Larkya la pass (5,160 m/16,929 ft)",
          image: day12,
          caption:
            "Today is going to be a very long day for us. After leaving Larke Phedi, the path follows the Larkya Glacier for a while. We are climbing steep, rocky terrain both going up and coming down. Because this section of the Manaslu circuit trail is fairly difficult and exposed to the wind, we start our hike relatively early in the morning. It is the glaciers that are all around us.",
          caption1:
            "After traversing a moraine, we will descend to a lake and walk along a steep grassy slope until we reach the water's edge. After that, we make our way past four frozen lakes and then climb a hill to reach the summit of Larkya La. The views of the surrounding mountains are breathtaking once you reach the summit of the pass. After that, we walk in an area that has a snow screen and a grassy moraine rockfall. The path makes its way down to Bimthang, where travelers will spend the night.",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Duration",
              detailCaption: "24.7 km/15.3 miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "8/9 horus",
            },
            {
              icon: "FaMountain",
              detailTitle: "Maximum Altitude",
              detailCaption: "3720m",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B, L, D",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Teahouse",
            },
          ],
        },
      ],
      day13: [
        {
          title: "Day 13: Bimthang to Tilije (1,700 m / 5,576 ft)",
          image: day13,
          caption:
            "Tilije can be reached after a protracted descent from Bimthang. We make our way to Hampuk via a steep descent of the terrain (3,430 m). After traversing a bridge, we continue our journey to Yak Kharka along a path that gradually declines in elevation (3,020 m). Karche is at the end of this path, which takes us through a verdant forest (2,700 m). From this vantage point, we continue on through Gho, which is at an elevation of 2,515, and then we begin our descent to the village of Tilije.",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Duration",
              detailCaption: "26 km",
            },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "5/6 horus",
            },
            {
              icon: "FaMountain",
              detailTitle: "Maximum Altitude",
              detailCaption: "1700m",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B, L, D",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Teahouse",
            },
          ],
        },
      ],
      day14: [
        {
          title:
            "Day 14: Tilije to Dharapani and to Kathmandu (1,400 m) via Besisahar (760 m/2,493 ft)",
          image: day14,
          caption:
            "The final day of the journey has finally arrived. From Tilije to Dharapani is only a few minutes' walk away. On the way, we make a pit stop at Thonje Gumpa, which is located at an elevation of 1,965 meters, and we also cross a suspension bridge. After leaving Dharapani, we get in a jeep driven by locals and travel to Besisahar. We switch to another comfortable vehicle at this point and continue on to Kathmandu by road. You are welcome to spend the afternoon window shopping in Thamel and then come join us for a farewell dinner.",
          tripDetail: [
            {
              icon: "FaBus",
              detailTitle: "Duration",
              detailCaption: "224 km/139 miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "8/9 horus",
            },
            {
              icon: "FaMountain",
              detailTitle: "Maximum Altitude",
              detailCaption: "1300m",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B, L, D",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Hotel",
            },
          ],
        },
      ],
      day15: [
        {
          title: "Day 15:  Final departure",
          caption:
            "You will be escorted to the international airport by the airport team in preparation for your departure. You are welcome to meet them at the specified time in the lobby of your hotel. If you would like to, you will have the opportunity to join us on another hike if we organize one.",
        },
      ],
    },
  ];
  return (
    <>
      <div className="detail-itinerary container p-3 mt-5">
        <div className="row">
          <div className="d-lg-none mb-5">
            <div className="">
              <h4 className="title">Trekking Route Map</h4>
              <img
                src={Map}
                alt="manaslu circuit trek map"
                style={{ width: "100%" }}
              />
              <button type="button" className="btn btn-dark mt-3 col-12">
                <a href={Pdf} target="_blank" className="text-light">
                  Download Itinerary
                </a>
              </button>
            </div>
          </div>
          <div className="col-md-12 col-lg-8">
            {days &&
              days.map((day) => (
                <>
                  {day.day1 &&
                    day.day1.map((day1) => (
                      <>
                        <h4 className="itinerary-title">{day1.title}</h4>
                        <div className="itinerary-image">
                          <img src={day1.image} alt="Kathmandu" />
                          {/* <figcaption className="text-secondary">
                            Lukla airport
                          </figcaption> */}
                        </div>
                        <div className="row">
                          {day1.tripDetail &&
                            day1.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-auto"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day1.caption}
                        </div>
                      </>
                    ))}

                  {day.day2 &&
                    day.day2.map((day2) => (
                      <>
                        <h4 className="itinerary-title">{day2.title}</h4>
                        <div className="itinerary-image">
                          <img src={day2.image} alt="Kathmandu" />
                          {/* <figcaption className="text-secondary">
                            Lukla airport
                          </figcaption> */}
                        </div>
                        <div className="row">
                          {day2.tripDetail &&
                            day2.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaBus" && (
                                        <FaBus fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title p-0"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day2.caption}
                        </div>
                      </>
                    ))}
                  {day.day3 &&
                    day.day3.map((day3) => (
                      <>
                        <h4 className="itinerary-title">{day3.title}</h4>
                        <div className="itinerary-image">
                          <img src={day3.image} alt="Kathmandu" />
                          {/* <figcaption className="text-secondary">
                            Lukla airport
                          </figcaption> */}
                        </div>
                        <div className="row">
                          {day3.tripDetail &&
                            day3.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title p-0"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day3.caption}
                        </div>
                      </>
                    ))}
                  {day.day4 &&
                    day.day4.map((day4) => (
                      <>
                        <h4 className="itinerary-title">{day4.title}</h4>
                        <div className="itinerary-image">
                          <img src={day4.image} alt="Kathmandu" />
                          {/* <figcaption className="text-secondary">
                            Lukla airport
                          </figcaption> */}
                        </div>
                        <div className="row">
                          {day4.tripDetail &&
                            day4.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title p-0"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day4.caption}
                        </div>
                      </>
                    ))}
                  {day.day5 &&
                    day.day5.map((day5) => (
                      <>
                        <h4 className="itinerary-title">{day5.title}</h4>
                        <div className="itinerary-image">
                          <img src={day5.image} alt="Kathmandu" />
                          {/* <figcaption className="text-secondary">
                            Lukla airport
                          </figcaption> */}
                        </div>
                        <div className="row">
                          {day5.tripDetail &&
                            day5.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title p-0"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day5.caption}
                        </div>
                      </>
                    ))}
                  {day.day6 &&
                    day.day6.map((day6) => (
                      <>
                        <h4 className="itinerary-title">{day6.title}</h4>
                        <div className="itinerary-image">
                          <img src={day6.image} alt="Kathmandu" />
                          {/* <figcaption className="text-secondary">
                            Lukla airport
                          </figcaption> */}
                        </div>
                        <div className="row">
                          {day6.tripDetail &&
                            day6.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title p-0"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day6.caption}
                        </div>
                      </>
                    ))}
                  {day.day7 &&
                    day.day7.map((day7) => (
                      <>
                        <h4 className="itinerary-title">{day7.title}</h4>
                        <div className="itinerary-image">
                          <img src={day7.image} alt="Kathmandu" />
                          {/* <figcaption className="text-secondary">
                            Lukla airport
                          </figcaption> */}
                        </div>
                        <div className="row">
                          {day7.tripDetail &&
                            day7.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title p-0"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day7.caption}
                        </div>
                      </>
                    ))}
                  {day.day8 &&
                    day.day8.map((day8) => (
                      <>
                        <h4 className="itinerary-title">{day8.title}</h4>
                        <div className="itinerary-image">
                          <img src={day8.image} alt="Kathmandu" />
                          {/* <figcaption className="text-secondary">
                            Lukla airport
                          </figcaption> */}
                        </div>
                        <div className="row">
                          {day8.tripDetail &&
                            day8.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title p-0"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day8.caption}
                        </div>
                      </>
                    ))}
                  {day.day9 &&
                    day.day9.map((day9) => (
                      <>
                        <h4 className="itinerary-title">{day9.title}</h4>
                        <div className="itinerary-image">
                          <img src={day9.image} alt="Kathmandu" />
                          {/* <figcaption className="text-secondary">
                            Lukla airport
                          </figcaption> */}
                        </div>
                        <div className="row">
                          {day9.tripDetail &&
                            day9.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {/* {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )} */}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title p-0"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day9.caption}
                        </div>
                      </>
                    ))}
                  {day.day10 &&
                    day.day10.map((day10) => (
                      <>
                        <h4 className="itinerary-title">{day10.title}</h4>
                        <div className="itinerary-image">
                          <img src={day10.image} alt="Kathmandu" />
                          {/* <figcaption className="text-secondary">
                            Lukla airport
                          </figcaption> */}
                        </div>
                        <div className="row">
                          {day10.tripDetail &&
                            day10.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title p-0"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day10.caption}
                        </div>
                      </>
                    ))}
                  {day.day11 &&
                    day.day11.map((day11) => (
                      <>
                        <h4 className="itinerary-title">{day11.title}</h4>
                        <div className="itinerary-image">
                          <img src={day11.image} alt="Kathmandu" />
                          {/* <figcaption className="text-secondary">
                            Lukla airport
                          </figcaption> */}
                        </div>
                        <div className="row">
                          {day11.tripDetail &&
                            day11.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title p-0"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day11.caption}
                        </div>
                      </>
                    ))}
                  {day.day12 &&
                    day.day12.map((day12) => (
                      <>
                        <h4 className="itinerary-title">{day12.title}</h4>
                        <div className="itinerary-image">
                          <img src={day12.image} alt="Kathmandu" />
                          {/* <figcaption className="text-secondary">
                            Lukla airport
                          </figcaption> */}
                        </div>
                        <div className="row">
                          {day12.tripDetail &&
                            day12.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title p-0"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day12.caption}
                          <br />
                          {day12.caption1}
                        </div>
                      </>
                    ))}
                  {day.day13 &&
                    day.day13.map((day13) => (
                      <>
                        <h4 className="itinerary-title">{day13.title}</h4>
                        <div className="itinerary-image">
                          <img src={day13.image} alt="Kathmandu" />
                          {/* <figcaption className="text-secondary">
                            Lukla airport
                          </figcaption> */}
                        </div>
                        <div className="row">
                          {day13.tripDetail &&
                            day13.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title p-0"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day13.caption}
                        </div>
                      </>
                    ))}
                  {day.day14 &&
                    day.day14.map((day14) => (
                      <>
                        <h4 className="itinerary-title">{day14.title}</h4>
                        <div className="itinerary-image">
                          <img src={day14.image} alt="Kathmandu" />
                          <figcaption className="text-secondary">
                            Lukla airport
                          </figcaption>
                        </div>
                        <div className="row">
                          {day14.tripDetail &&
                            day14.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaBus" && (
                                        <FaBus fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title p-0"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day14.caption}
                        </div>
                      </>
                    ))}
                  {day.day15 &&
                    day.day15.map((day15) => (
                      <>
                        <h4 className="itinerary-title">{day15.title}</h4>
                        <div className="row">
                          {day15.tripDetail &&
                            day15.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-12 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title p-0"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day15.caption}
                        </div>
                      </>
                    ))}
                </>
              ))}
          </div>

          <div className="main-modal-map d-md-none d-lg-flex">
            <div className="modal-map d-flex flex-column">
              <h4 className="title">Trekking Route Map</h4>
              <img src={Map} alt="Manaslu circuit trek map" />
              <button type="button" className="btn btn-dark mt-3 col-12">
                <a href={Pdf} target="_blank" className="text-light">
                  Download Itinerary
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Itinerary;
