import React from "react";
import { Col, Row } from "react-bootstrap";
import { FaMountain, FaUtensils, FaBed, FaHiking } from "react-icons/fa";
import { FaBus } from "react-icons/fa";
import { FaClock } from "react-icons/fa";

import swayambhu from "../../../image/BuddhistPilgriamge/swayambhu.jpg";
import boudha from "../../../image/BuddhistPilgriamge/boudha.jpg";
import lumbini from "../../../image/BuddhistPilgriamge/lumbini.jpg";
import kapan from "../../../image/BuddhistPilgriamge/kapan.jpg";
import namobuddha from "../../../image/BuddhistPilgriamge/namobuddha.jpg";
import tilaurakot from "../../../image/BuddhistPilgriamge/tilaurakot.jpg";

import "../../../custom-sass/pages/trip/_trip-detail.scss";

const Itinerary = () => {
  const days = [
    {
      day1: [
        {
          title: "Day 1: Arrival in Kathmandu, visit Swayambhunath Temple",
          image: swayambhu,
          imageCaption: "Swayambhu Temple",
          caption:
            "When you arrive in Kathmandu, the team from Adventure Master Trek will pick you up at Tribhuvan International Airport. They will help you get to the hotel and carry your bags. After you check in, you'll have some time to get cleaned up and put your things away. Later that day, we'll introduce you to your guide, who will be your friend for the whole time you're with us in Nepal.",
          caption1:
            "If there is time, we will show you around Swayambhunath Stupa with a guide. People also call this place the monkey temple. On a hilltop is where Swayambhunath is. To get to the top of the hill, you have to walk up a lot of stairs. If you want to skip this climb, we can drive to the west side, where there are fewer steps. From Swayambhunath, you can get a great view of Kathmandu from above.",
          tripDetail: [
            // {
            //   icon: "FaMountain",
            //   detailTitle: "Maximum Altitude",
            //   detailCaption: "1400m",
            // },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "Welcome dinner",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Hotel",
            },
          ],
        },
      ],
      day2: [
        {
          title: "Day 02: Visit Boudhanath Stupa and Kopan Monastery",
          image1: boudha,
          imageCaption1: "Boudhanath Stupa",
          image2: kapan,
          imageCaption2: "Kapan Monastery",
          caption:
            "After breakfast this morning, we will go to Boudhanath Stupa. Boudhanath is a magical place in the middle of Kathmandu's chaotic streets and scary traffic. It is 11 km from the city's center and northeastern edge. The stupa is on a trade route from the past. It is Nepal's biggest stupa. Monasteries and many cafes, restaurants, and shops are all around the main stupa.",
          caption1:
            "After exploring Boudhanath Stupa, we take a small break for lunch and continue the tour to the Kopan monastery. It is located on the outskirts of Kathmandu, 11 km away from the bustle of the city. We'll meditate here and soak in the calmness of the surroundings before heading back to Kathmandu.",
          tripDetail: [
            // {
            //   icon: "FaBus",
            //   detailTitle: "Trek Distance",
            //   detailCaption: "205 km/127 miles",
            // },
            // {
            //   icon: "FaClock",
            //   detailTitle: "Trek Duration",
            //   detailCaption: "6-7 hours",
            // },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B,L,D",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Hotel",
            },
          ],
        },
      ],
      day3: [
        {
          title:
            "Day 3: Tour of Namo Buddha with meditation, stay in Namobuddha for the night",
          image: namobuddha,
          imageCaption: "Namobuddha",
          caption:
            "OWe go to Namobuddha on this day. It is between 40 and 45 km from Kathmandu. We take our own car to get to Namobuddha. We drive to Namobuddha by way of Banepa, Panauti, which is a very pretty route. We will be  spending the night here, so make sure you bring everything you need. We will have a great time here because it is surrounded by green hills and thick forests.",
          caption1:
            "We go to Namobuddha on this day. It is between 40 and 45 km from Kathmandu. We take our own car to get to Namobuddha. We drive to Namobuddha by way of Banepa, Panauti, which is a very pretty route. We're going to spend the night here, so make sure you bring everything you need. We'll have a great time here because it's surrounded by green hills and thick forests.",
          tripDetail: [
            // {
            //   icon: "FaHiking",
            //   detailTitle: "Trek Distance",
            //   detailCaption: "7 km/4.34 miles",
            // },
            // {
            //   icon: "FaClock",
            //   detailTitle: "Trek Duration",
            //   detailCaption: "1.5/2 hours drive & 3/4 hours trek",
            // },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B,L,D",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Hotel",
            },
          ],
        },
      ],
      day4: [
        {
          title:
            "Day 04: Fly from Kathmandu to Bhairawaha, then drive to Lumbini ,Day tour of Lumbini",
          image: lumbini,
          imageCaption: "Lumbini",
          caption:
            "After a tasty breakfast in the early morning, we will drive back to the airport to catch a flight to Bairahawa. When we get here, we will drive to Lumbini, which is 22 kilometers away, in a private four-wheeler. You will have a couple of hours to relax and eat lunch, and then we'll go on to see more of the city and its historical sites.",
          caption1:
            "After a tasty breakfast in the early morning, we will drive back to the airport to catch a flight to Bairahawa. When we get here, we'll drive to Lumbini, which is 22 km away, in a private four-wheeler. You'll have a couple of hours to relax and eat lunch, and then we'll go on to see more of the city and its historical sites.",
          tripDetail: [
            // {
            //   icon: "FaHiking",
            //   detailTitle: "Trek Distance",
            //   detailCaption: "11 km/6 miles",
            // },
            {
              icon: "FaClock",
              detailTitle: "FLight Duration",
              detailCaption: "30 minutes",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B,L,D",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Hotel",
            },
          ],
        },
      ],
      day5: [
        {
          title: "Day 5: Explore Lumbini and Tilaurakot",
          image: tilaurakot,
          imageCaption: "Tilaurakot",
          caption:
            "We will use this to see the rest of Lumbini and the other monuments. Later, we will drive to Tilaurakot, near Kapilvastu. It is 59 km from Lumbini and is the place where Gautam Buddha grew up. We will use a private car to get from one place to the other. After the tour, go back to Lumbini and spend the night walking around and taking it easy in the holy garden.",
          tripDetail: [
            // {
            //   icon: "FaHiking",
            //   detailTitle: "Trek Distance",
            //   detailCaption: "11.50 km/7.1 miles  ",
            // },
            // {
            //   icon: "FaClock",
            //   detailTitle: "Trek Duration",
            //   detailCaption: "5 hours",
            // },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B,L,D",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Hotel",
            },
          ],
        },
      ],
      day6: [
        {
          title:
            "Day 6: Fly back to Kathmandu, go shopping, and check out the Himalayan Buddhist Meditation Center.",
          caption:
            "The pilgrimage tour for Buddhists is coming to an end. Today, we will fly back to Kathmandu first thing in the morning. The rest of your free time will be spent in Kathmandu. We take you to the Himalayan Buddhist Meditation Center. After that, you can go shopping at the nearby market. We will have a farewell dinner with traditional Nepali music and dance in the late evening.",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Flight Distance",
              detailCaption: "30 minutes",
            },
            // {
            //   icon: "FaClock",
            //   detailTitle: "Trek Duration",
            //   detailCaption: "5 hours",
            // },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "Breakfast and farewell dinner",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Hotel",
            },
          ],
        },
      ],
      day7: [
        {
          title: "Day 7: Final departure",
          caption:
            "Our airport team will take you to Tribhuvan International Airport for your flight back home. They will pick you up in the lobby of your hotel and take you to the airport terminal gate. Check the hotel room one more time before you leave. We hope to see you again soon.",
          // tripDetail: [
          //   {
          //     icon: "FaHiking",
          //     detailTitle: "Trek Distace",
          //     detailCaption: "12 km/7.4 miles",
          //   },
          //   {
          //     icon: "FaClock",
          //     detailTitle: "Trek Duration",
          //     detailCaption: "6/7 hours",
          //   },
          //   {
          //     icon: "fa fa-meals",
          //     detailTitle: "Meals",
          //     detailCaption: "B,L,D",
          //   },
          //   {
          //     icon: "fa fa-bed",
          //     detailTitle: "Accommodation",
          //     detailCaption: "Lodge",
          //   },
          // ],
        },
      ],
      // day8: [
      //   {
      //     title:
      //       "Day 8: Trek from Himalaya to Annapurna Base Camp (4,130 m/13,550 ft) via Machapuchare Base Camp",
      //     caption:
      //       "The trail from Himalaya ascends to Hinku Cave (3,170 m/10,400 ft). We'll be walking closely to Modi Khola. Soon we reach a place called Deurali (3,230 m/10,597 ft). Crossing a couple of bridges on the Modi Khola, we walk by a dangerous avalanche-prone area. Further, we cross one more bridge, and Machhapuchhre base camp (3,700 m/12,139 ft) arrives.",
      //     caption1:
      //       "From here, the trail turns towards the Annapurna base camp. We'll be walking alongside south Annapurna glacier on this part of the trail. The views of Annapurna South, Mardi Himal, Gangaurna, Hiunchuli, Annapurna I, Annapurna III, Tent Peak, etc, are seen throughout the trail.",
      //     tripDetail: [
      //       {
      //         icon: "FaHiking",
      //         detailTitle: "Trek Distance",
      //         detailCaption: "12.5 km/7.7 miles",
      //       },
      //       {
      //         icon: "FaClock",
      //         detailTitle: "Trek Duration",
      //         detailCaption: "5 hours",
      //       },
      //       {
      //         icon: "fa fa-meals",
      //         detailTitle: "Meals",
      //         detailCaption: "B,L,D",
      //       },
      //       {
      //         icon: "fa fa-bed",
      //         detailTitle: "Accommodation",
      //         detailCaption: "Lodge",
      //       },
      //     ],
      //   },
      // ],
      // day9: [
      //   {
      //     title:
      //       "DAY 9: Trek from Annapurna Base Camp to Bamboo (2,310 m/7,578 ft)",
      //     caption:
      //       "After spending a night at the base camp, we retrace the path back to Bamboo. The trail descends through avalanche-prone areas and a couple of bridges to the Himalaya (2,920 m/9,580 ft). We then further descend along Modi Khola to Dovan (2,600 m/8,530 ft). Crossing a suspension bridge, we walk back to Bamboo for a night's stay.",
      //     tripDetail: [
      //       {
      //         icon: "FaHiking",
      //         detailTitle: "Trek Distance",
      //         detailCaption: "14 km/8.6 miles",
      //       },
      //       {
      //         icon: "FaClock",
      //         detailTitle: "Trek Duration",
      //         detailCaption: "6 hours",
      //       },
      //       {
      //         icon: "fa fa-meals",
      //         detailTitle: "Meals",
      //         detailCaption: "B,L,D",
      //       },
      //       {
      //         icon: "fa fa-bed",
      //         detailTitle: "Accommodation",
      //         detailCaption: "Lodge",
      //       },
      //     ],
      //   },
      // ],
      // day10: [
      //   {
      //     title: "DAY 10: Trek from Bamboo to Jhinu Danda (1,780 m/6,393 ft)",
      //     caption:
      //       "The trail descends to Khuldighar (2,540 m/8,333 ft). We'll be walking alongside the Modi Khola and drop down to Sinuwa (2,360 m/7,742 ft). The trail keeps descending and crosses a bridge over Chomrong Khla. We then walk on a zigzag route that leads to Jhinu Danda. The part of the trail also goes through rhododendron and oak forests. At Jhinu Danda, we get to enjoy natural hot springs by paying a few bucks.",
      //     tripDetail: [
      //       {
      //         icon: "FaHiking",
      //         detailTitle: "Trek Distance",
      //         detailCaption: "8.5 km/5.2 miles",
      //       },
      //       {
      //         icon: "FaClock",
      //         detailTitle: "Trek Duration",
      //         detailCaption: "3/4 hours",
      //       },
      //       {
      //         icon: "fa fa-meals",
      //         detailTitle: "Meals",
      //         detailCaption: "B,L,D",
      //       },
      //       {
      //         icon: "fa fa-bed",
      //         detailTitle: "Accommodation",
      //         detailCaption: "Lodge",
      //       },
      //     ],
      //   },
      // ],
      // day11: [
      //   {
      //     title:
      //       "DAY 11: Trek to Siwa/Ghandruk Phedi (1,150 m/3,770 ft) and drive to Pokhara (820 m) via Nayapul",
      //     caption:
      //       "Leaving Jhinu Danda, we walk following a downhill route through small settlement areas to Ghandruk village (1,940 m/6,364 ft). From here, the trail descends to Kimche (1,640 m/5,380 ft). We'll be walking through the forested route alongside the Modi Khola. The trail passes by Syauli Bazar (1,220 m/4,002 ft) and Chimrong (1,130 m/3,707 ft). We keep descending and pass by Birethanti (1,025 m/3,362 ft) before reaching Nayapul (1,070 m/3,510 ft). From Nayapul, it's a short drive to Pokhara.",
      //     tripDetail: [
      //       {
      //         icon: "FaHiking",
      //         detailTitle: "Trek Distance",
      //         detailCaption: "4 km/2.4 miles",
      //       },
      //       {
      //         icon: "FaClock",
      //         detailTitle: "Trek Duration",
      //         detailCaption: "1.5/2 hours drive & 3 hours trek",
      //       },
      //       {
      //         icon: "fa fa-meals",
      //         detailTitle: "Meals",
      //         detailCaption: "B,L",
      //       },
      //       {
      //         icon: "fa fa-bed",
      //         detailTitle: "Accommodation",
      //         detailCaption: "Hotel",
      //       },
      //     ],
      //   },
      // ],
      // day12: [
      //   {
      //     title: "DAY 12: Drive from Pokhara to Kathmandu ",
      //     caption:
      //       "Today, we’ll drive back to Kathmandu. The Pokhara Kathmandu drive is comfortable and scenic, like last time. Upon reaching Kathmandu, we'll drop you at the hotel. Spend the remaining day doing window shopping in Thamel and exploring the local market. We meet you in the late evening for farewell dinner.",
      //     tripDetail: [
      //       {
      //         icon: "FaHiking",
      //         detailTitle: "Trek Distance",
      //         detailCaption: "205 km/127 miles",
      //       },
      //       {
      //         icon: "FaClock",
      //         detailTitle: "Trek Duration",
      //         detailCaption: "6/7 hours",
      //       },
      //       {
      //         icon: "fa fa-meals",
      //         detailTitle: "Meals",
      //         detailCaption: "B,L & Farewell Dinner",
      //       },
      //       {
      //         icon: "fa fa-bed",
      //         detailTitle: "Accommodation",
      //         detailCaption: "Lodge",
      //       },
      //     ],
      //   },
      // ],
      // day13: [
      //   {
      //     title: "DAY 13: Final Departure",
      //     caption:
      //       "Your trek with us has ended. You can extend the trip and join our other adventurous trips in the Himalayas. If not, our airport team will take care of your airport departure as per your flight departure time.",
      //     // tripDetail: [
      //     //   {
      //     //     icon: "FaClock",
      //     //     detailTitle: "Flight Duration",
      //     //     detailCaption: "35 minutes flight",
      //     //   },
      //     //   {
      //     //     icon: "fa fa-meals",
      //     //     detailTitle: "Meals",
      //     //     detailCaption: "Breakfaast & farewell dinner",
      //     //   },
      //     //   {
      //     //     icon: "fa fa-bed",
      //     //     detailTitle: "Accommodation",
      //     //     detailCaption: "Hotel",
      //     //   },
      //     // ],
      //   },
      // ],
    },
  ];

  return (
    <>
      <div className="detail-itinerary container mt-5">
        <div className="row">
          <div className="col-md-12 col-lg-8">
            {days &&
              days.map((day) => (
                <>
                  {day.day1 &&
                    day.day1.map((day1) => (
                      <>
                        <h4 className="itinerary-title">{day1.title}</h4>
                        <div className="itinerary-image mb-3">
                          <img src={day1.image} alt="swayambhu temple" />
                          <em className="text-secondary">
                            {day1.imageCaption}
                          </em>
                        </div>
                        <div className="pb-2 itinerary-content">
                          {day1.caption}
                          <br />
                          {day1.caption1}
                          <br />
                          <h4 className="title pt-3">Soyambhunath</h4>
                          <ul>
                            <li>
                            The temple complex is 3 km west of Kathmandu on a hill that is thought to have been made from an ancient lake. The lake and the story about it go back more than two thousand years.
                            </li>
                            <li>
                            A monument says that Swayambhunath was built in the year 460 AD. People think that before this temple, there was another one built by Emperor Ashoka in the 3rd century, but it burned down.
                            </li>
                            <li>
                            The main stupa has a cube-shaped base, and on each of its four sides is a pentagon-shaped toran.
                            </li>
                            <li>
                            On each side of the stupa, there is a pair of eyes, which shows that God is everywhere. Each set of eyes has one more eye on top, which is called the "eye of wisdom". Each side of the stupa has a Panch Buddha, which is a group of five Buddhas.
                            </li>
                          </ul>
                          <h4 className="title pt-3">
                            Tourist attractions in Swayambhunath Temple:
                          </h4>
                          <ul>
                            <li>Harati Devi Temple</li>
                            <li>Shantipur</li>
                            <li>Pratapur and Anantapur</li>
                            <li>Shree Karma Raj Mahavihar</li>
                            <li>Vajra</li>
                          </ul>
                        </div>
                        <div className="row pb-5 ms-5 ">
                          {day1.tripDetail &&
                            day1.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-12 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-auto"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                      </>
                    ))}

                  {day.day2 &&
                    day.day2.map((day2) => (
                      <>
                        <h4 className="itinerary-title">{day2.title}</h4>
                        <div className="pb-2 itinerary-content">
                          {day2.caption}
                          <br />
                          {day2.caption}
                          <div className="itinerary-image">
                            <img src={day2.image1} alt="boudha stupa" />
                            <em className="text-secondary mb-2">
                              {day2.imageCaption1}
                            </em>
                          </div>
                          <h4 className="title pt-3">Boudhanath Stupa:</h4>
                          <ul>
                            <li>
                            Boudhanath, Khasti Chaitya, and Khsa Chaitya are all names for Boudhanath Stupa.
                            </li>
                            <li>
                            It was put on the list of UNESCO World Heritage Sites in 1979.
                            </li>
                            <li>
                            It is thought that the first stupa was built around 600 A.D. It was destroyed by Mughal emperors. But there is also another story that says the stupa was built in the 5th century by the Lichhavi king Sivadeva.
                            </li>
                            <li>
                            In the late 15th and early 16th centuries, Amshu Verma's bones were found by archaeologists.
                            </li>
                            <li>
                            The shape of Boudhanath is like a mandala, which is a 3D reminder of Buddha's journey to enlightenment.
                            </li>
                            <li>
                            The top of the stupa has 13 spires, which means that people have to go through 13 steps to reach Nirvana.
                            </li>
                            <li>
                            The tourists move the prayer wheels as they walk in a circle (kora) in a clockwise direction.
                            </li>
                          </ul>
                          <div className="itinerary-image my-3">
                            <img src={day2.image2} alt="kapan monastery" />
                            <em className="text-secondary">
                              {day2.imageCaption2}
                            </em>
                          </div>
                          <h4 className="title pt-3">Kopan Monastery:</h4>
                          <ul>
                            <li>
                            The Foundation for the Preservation of the Mahayana Tradition is a member of Kopan Monastery (FPMT).
                            </li>
                            <li>
                            In 1970, the monastery was built as a place to study and think.
                            </li>
                            <li>
                            People who want to learn about Buddhism often come to this monastery. In 1971, the first class on meditation was held.
                            </li>
                            <li>
                            There is a big garden and a library at the monastery.
                            </li>
                            <li>
                            Around the grounds, there are a lot of places to eat and buy gifts.
                            </li>
                            <li>
                            The Phulbari monastery is above the Kapan monastery. It takes an hour to walk there.
                            </li>
                          </ul>
                        </div>
                        <div className="row pb-5 ms-5 ">
                          {day2.tripDetail &&
                            day2.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-12 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaBus" && (
                                        <FaBus fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-autp"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                      </>
                    ))}
                  {day.day3 &&
                    day.day3.map((day3) => (
                      <>
                        <h4 className="itinerary-title">{day3.title}</h4>
                        <div className="itinerary-image mb-3">
                          <img src={day3.image} alt="namobuddha" />
                          <em className="text-secondary">
                            {day3.imageCaption}
                          </em>
                        </div>
                        <div className="pb-2 itinerary-content">
                          {day3.caption}
                          <br />
                          {day3.caption}
                          <h4 className="title pt-3">Namobuddha:</h4>
                          <ul>
                            <li>
                            After Swayambhunath and Boudhanath in Kathmandu, Namobuddha is another important pilgrimage site.
                            </li>
                            <li>
                            On the full moon day of Baisakh (May), which is also the birthday of Lord Buddha, a big festival is held here.
                            </li>
                            <li>
                            Near the monastery, there are a lot of hotels, resorts, and restaurants.
                            </li>
                          </ul>
                        </div>
                        <div className="row pb-5 ms-5 ">
                          {day3.tripDetail &&
                            day3.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-12 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-autp"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                      </>
                    ))}
                  {day.day4 &&
                    day.day4.map((day4) => (
                      <>
                        <h4 className="itinerary-title">{day4.title}</h4>
                        <div className="itinerary-image mb-3">
                          <img src={day4.image} alt="lumbini" />
                          <em className="text-secondary">
                            {day4.imageCaption}
                          </em>
                        </div>
                        <div className="pb-2 itinerary-content">
                          {day4.caption}
                          <br />
                          {day4.caption}
                          <h4 className="title pt-3">Lumbini:</h4>
                          <ul>
                            <li>
                            Lord Gautam Buddha was born in Lumbini in 623 BC.
                            </li>
                            <li>
                            Emperor Ashoka built the commemorative pillar during his visit to Lumbini. It is known as the Ashoka Pillar.
                            </li>
                            <li>
                            The city has great archaeological importance as there are still remains associated with Lord Buddha's birth.
                            </li>
                            <li>
                            Many monasteries are built around Lumbini by different countries as a way of showing their respect for the religion.
                            </li>
                          </ul>
                          <h4 className="title pt-3">
                            Places to visit in Lumbini:
                          </h4>
                          <ol>
                            <li>Maya Devi Temple</li>
                            <li>Ashoka Pillar</li>
                            <li>Bodhi Tree</li>
                            <li>Holy Pond </li>
                            <li>Dharma Swami Maharaja Buddha Vihar</li>
                            <li>Lumbini Museum</li>
                            <li>World Peace Pagoda</li>
                            <li>
                              Eternal peace flamGolden Lotus stupa built by
                              German
                            </li>
                            <li>Chinese temple & monastery complex</li>
                            <li>Korean Temple</li>
                            <li>Cambodian temple</li>
                            <li>Myanmar Golden Temple</li>
                            <li>Mahabodhi Society Temple India</li>
                            <li>Thai Monastery</li>
                            <li>Vietnamese monastery</li>
                            <li>Burmese Swedagoan pagoda</li>
                            <li>
                              Nepalese temple built by International Bhikshuni
                              Association
                            </li>
                            <li>Sri Lankan Temple </li>
                          </ol>
                          <em>Note: Out of 18 choose 4 place</em>
                        </div>
                        <div className="row pb-5 ms-5 ">
                          {day4.tripDetail &&
                            day4.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-12 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-autp"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                      </>
                    ))}
                  {day.day5 &&
                    day.day5.map((day5) => (
                      <>
                        <h4 className="itinerary-title">{day5.title}</h4>
                        <div className="itinerary-image mb-3">
                          <img src={day5.image} alt="tilaurakot" />
                          <em className="text-secondary">
                            {day5.imageCaption}
                          </em>
                        </div>
                        <div className="pb-2 itinerary-content">
                          {day5.caption}
                          <h4 className="title pt-3">Tilaurakot:</h4>
                          <ul>
                            <li>
                            King Suddhodana and Queen Maya Devi were the royal parents of Lord Gautama Buddha. Gautam Buddha was actually a prince who lived as a king until he was 29 years old.
                            </li>
                            <li>
                            The ruins of the palace where Buddha spent his early life are in Tilaurakot. This area is protected because it is of international interest.
                            </li>
                            <li>
                            Check out the village, the palace ruins, and the market.
                            </li>
                            <li>
                            Learn stories from the locals and check out some interesting old things.
                            </li>
                          </ul>
                        </div>
                        <div className="row pb-5 ms-5 ">
                          {day5.tripDetail &&
                            day5.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-12 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-autp"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                      </>
                    ))}
                  {day.day6 &&
                    day.day6.map((day6) => (
                      <>
                        <h4 className="itinerary-title">{day6.title}</h4>
                        <div className="pb-2 itinerary-content">
                          {day6.caption}
                        </div>
                        <div className="row pb-5 ms-5 ">
                          {day6.tripDetail &&
                            day6.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-12 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-autp"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                      </>
                    ))}
                  {day.day7 &&
                    day.day7.map((day7) => (
                      <>
                        <h4 className="itinerary-title">{day7.title}</h4>
                        <div className="pb-2 itinerary-content">
                          {day7.caption}
                        </div>
                        <div className="row pb-5 ms-5 ">
                          {day7.tripDetail &&
                            day7.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-12 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaBus" && (
                                        <FaBus fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-autp"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                      </>
                    ))}
                </>
              ))}
          </div>

          <div className="main-modal d-none d-lg-flex">
            <div className="modal-map">
              {/* img src={ManasluMap} needs to implement map here */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Itinerary;
