import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import CancelIcon from "@mui/icons-material/Cancel";

const TripDetail = () => {
  return (
    <>
      <div className="trip-detail container mt-5">
        <div className="row">
          <div className="col-2 d-none d-lg-block d-xl-block">
            <div className="trip-sidebar">
              <ul>
                <li>
                  <a href="#price">Price Detail</a>
                </li>
                <li>
                  <a href="#highlight">Highlight</a>
                </li>
                <li>
                  <a href="#include">Cost Include</a>
                </li>
                <li>
                  <a href="#exclude">Cost Exclude</a>
                </li>
                <li>
                  <a href="#disclaimer">Disclaimer of Itinerary</a>
                </li>
                <li>
                  <a href="#difference">
                    Difference Between Annapurna Base Camp and Annapurna Circuit
                  </a>
                </li>
                <li>
                  <a href="#important-note">Important Note</a>
                </li>
                <li>
                  <a href="#safety">Safety and Security</a>
                </li>
                <li>
                  <a href="#insurance">Travel Insurance</a>
                </li>
                <li>
                  <a href="#age">Minimum Age</a>
                </li>
                <li>
                  <a href="#time">Best Time</a>
                </li>
                <li>
                  <a href="#tipping">Tipping</a>
                </li>
                <li>
                  <a href="#activities">Activities You Can Choose</a>
                </li>
                <li>
                  <a href="#group-leader">Group Leader - What They Do</a>
                </li>
                <li>
                  <a href="#group-notes">Size of Group Notes</a>
                </li>
                <li>
                  <a href="#transport">Transport</a>
                </li>
                <li>
                  <a href="#local-flight">Local Flight</a>
                </li>
                <li>
                  <a href="#solo-traveler">Solo Travelers</a>
                </li>
                <li>
                  <a href="#joining">Joining Instructions</a>
                </li>
                <li>
                  <a href="#arrival">Arrival Complications</a>
                </li>
                <li>
                  <a href="#emergency-contact">Emergency Contanct</a>
                </li>
                <li>
                  <a href="#visa">Visa and Entry Requirement</a>
                </li>
                <li>
                  <a href="#money-exchange">Money Exchange</a>
                </li>
                <li>
                  <a href="#emergency-fund">Emergency Fund</a>
                </li>
              </ul>
            </div>
          </div>

          <div className="trip-info col-md-12 col-lg-10">
            {/* ---------------- price starts --------------------- */}
            <h4 id="price" className="trip-detail-title">
              Price Detail
            </h4>
            Trip Price: USD $890 pp
            <h4 className="trip-detail-title">Group Discount</h4>
            2 pax --------- USD $790 pp
            <br />
            3/5pax ---- USD $720 pp
            <br />
            5/10 pax ---- USD $665 pp
            <em>Day white water rafting free who booked the trip</em>
            {/* ---------------- trip highlight starts --------------------- */}
            <h4 id="highlight" className="trip-detail-title">
              Highlights
            </h4>
            <ul className="trip-highlight">
              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>
                  Explore the western Himalayas and go on a base camp trek
                </li>
              </span>

              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>
                  Annapurna Conservation Area has green foothills and thick
                  forests that you can hike through
                </li>
              </span>

              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>See different plants and animals along the way</li>
              </span>

              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>
                  See a beautiful mountain panorama with many different kinds of
                  scenery
                </li>
              </span>

              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>
                  Talk to people from different places while spending the night
                  in lovely villages
                </li>
              </span>

              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>
                  From Poon Hill, you can see a beautiful sunrise over the
                  Himalayas
                </li>
              </span>

              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>
                  Spend the night at the base camp of Annapurna, the world's
                  tenth-highest mountain
                </li>
              </span>

              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>Untangle your mind and take it easy</li>
              </span>

              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>
                  Enjoy the fresh mountain air, the sound of birds chirping, the
                  smell of the forest, the friendly people, and the rich
                  cultural history
                </li>
              </span>
            </ul>
            {/*---------------------- cost include starts --------------------- */}
            <h4 id="include" className="trip-detail-title">
              Cost Include
            </h4>
            <ul className="cost-include">
                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>
                      Private car service to and from the airport.
                    </li>
                  </span>

                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>
                      KDrive to Pokhara from Kathmandu-pokhara via a luxurious tourist bus. 
                    </li>
                  </span>

                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>
                      Drive to Nayapul from Pokhara via private jeep. 
                    </li>
                  </span>

                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>
                      Drive from Pokhara to Siwae via private jeep. 
                    </li>
                  </span>

                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>
                      Hotel stays in Kathmandu and Pokhara, including breakfast (as per the itinerary)
                    </li>
                  </span>

                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>During the trek, you will be provided with three meals a day: breakfast, lunch, and dinner with one cup of tea each meal.</li>
                  </span>

                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>A highly experienced, helpful, knowledgeable, friendly, English-speaking guide with all of his salary, food, drinks, lodging, transportation, and insurance covered.</li>
                  </span>

                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>You can use a sleeping bag, a down jacket, and a duffel bag (if you don't have your own you can rent.</li>
                  </span>

                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>
                      A map of a trek and a certificate of completion of the trip.
                    </li>
                  </span>

                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>Taxes and official costs paid to the government.</li>
                  </span>

                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>Oxymeter to check your pulse, oxygen saturation, and heart rate twice a day (very helpful to check for signs of Altitude Mountain Sickness, or AMS). This will make sure that your trek is in the safest hands possible, no matter how experienced you are.</li>
                  </span>

                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>Strong, helpful porters with the right safety gear and walking gear, as well as a salary, food, a place to live, and insurance. (one porter for two people).</li>
                  </span>
                  <span>
                    <div className="cost-include-icon">
                      All papers that need to be done, Fees for entering permits you need to go while hiking.
                    </div>
                  </span>
                </ul>
            {/*---------------------- cost exclude starts --------------------- */}
            <h4 id="exclude" className="trip-detail-title">
              Cost Exclude
            </h4>
            <ul className="cost-exclude">
                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>
                      Food that you will eat in Kathmandu for lunch and dinner.
                    </li>
                  </span>

                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>The cost of a visa to enter Nepal is
                      <p>
                        $30 USD: 15 Days <br/>
                      $50 USD: 30 Days<br/>
                      $50 USD: 90 Days
                      </p>
                      </li>
                  </span>

                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>Your insurance for travel and medical care.</li>
                  </span>

                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>Inernational airfare.</li>
                  </span>

                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>Your personal expenses.</li>
                  </span>

                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>All of the alcoholic and non-alcoholic cold and hot drinks you buy on trek (along the way and in the Tea Houses at night). </li>
                  </span>

                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>All of the sweet desserts, like chocolate, cake, pie, and pudding.</li>
                  </span>

                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>
                      You can use a sleeping bag, a down jacket (if you don't have your own you can rent in Thamel)
                    </li>
                  </span>

                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>The tea houses have hot showers and places to charge your phone which will cost you about 2$ to 5$</li>
                  </span>

                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>Tip  to the guide, the porter, and the driver. (Tipping is expected)</li>
                  </span>
                </ul>
            {/*---------------------- Disclaimer of Itinerary starts --------------------- */}
            <h4 id="disclaimer" className="trip-detail-title">
              Disclaimer of Itinerary
            </h4>
            <p className="trip-detail-content">
              Our objective is to complete the Annapurna base camp hike in
              accordance with the schedule that we have outlined. On the other
              hand, if the weather is particularly unfavorable or if unforeseen
              circumstances arise while we are hiking, our schedule could have
              to be adjusted. When anything like this happens, the person in
              charge of leading the hike will make any necessary adjustments to
              the schedule so that it is in everyone's best interest. In order
              to keep everyone in the group safe, he made a decision that we
              want you to please respect and accept.
            </p>
            {/*---------------------- Difference between ABC and Annapurna Circuit starts --------------------- */}
            <h4 id="difference" className="trip-detail-title">
              Difference between the Annapurna Base camp trek and Annapurna
              circuit
            </h4>
            <p className="trip-detail-content">
              The trek to Annapurna base camp is shorter than the trek around
              Annapurna circuit . Annapurna Circuit goes over the Thorung La
              pass at 5,416m, and the Annapurna Base Camp trek goes to 4,100m.
              So, the highest point of the Annapurna base camp trek is 1,300m
              lower than that of the Annapurna circuit. As such, the annapurna
              circuit has a much higher risk of altitude sickness than the
              annapurna base camp trek. Also, The ABC trek begins in Pokhara,
              but the AC begins in Besisahar.
            </p>
            {/*---------------------- important notes starts --------------------- */}
            <h4 id="important-note" className="trip-detail-title">
              Important Notes
            </h4>
            <p className="trip-detail-content">
              Think about the following tips for a safe and smooth hiking trip:
            </p>
            <ul>
              <li>
                Annapurna base camp trekking is a way to see some of the most
                remote parts of the western Himalayas. The basic infrastructure
                of the trail is not good enough. So, watch out for your health
                and try to get used to the facilities that are there.
              </li>
              <li>
                Altitude sickness can be avoided by drinking a lot of water.
              </li>
              <li>Drink plenty of water to avoid altitude sickness. </li>
              <li>
                A problem that keeps coming up on the ABC trek is altitude
                sickness. You can not get rid of it, but you can try to avoid
                it. One of the best ways to do that is to stay hydrated and be
                both mentally and physically ready.
              </li>
              <li>
                You can change the Annapurna base camp trek to fit your needs if
                you have any special requests.
              </li>
              <li>
                Do not wear brand new trekking boots while trekking. Most
                trekking clothes and gear can be rented or bought in Thamel,
                which is in Kathmandu.
              </li>
            </ul>
            {/*---------------------- safety and security starts --------------------- */}
            <h4 id="safety" className="trip-detail-title">
              Safety and Security
            </h4>
            <p className="trip-detail-content">
              The Adventure Master Trek is a reputable company. The company's
              best services help travelers from all over the world. We look out
              for our customers' best interests so we can offer the best service
              at the best Everest helicopter landing price. Our company's
              culture and team will do their best to give you a trip in the
              Himalayas that you will remember forever.
            </p>
            <p className="trip-detail-content">
              Over the years, we have helped many travelers with their Annapurna
              base camp trekking. People know us for having the best prices and
              best service.
            </p>
            <p className="trip-detail-content">
              The local guides and porters on our trekking team are the best at
              what they do and are also very friendly. The company takes care of
              all the meals, lodging, and permits, so you won't have to do
              anything. We make sure you get the best service for your money by
              booking everything in advance.
            </p>
            <p className="trip-detail-content">
              In the mountains, you will be taken care of by our reliable
              on-field crew. Our crew has the first-aid supplies they need and
              satellite phones so they can stay in touch with us. During the
              trek, we talk to our guides and get daily updates. Whenever you do
              something exciting with us, your safety is our top priority.
            </p>
            <p className="trip-detail-content">
              Also, Our whole team has been vaccinated against Covid-19. Nepal
              has opened all of its trekking routes so that tourists from all
              over the world can visit. There is no need to be in quarantine,
              and you can show your vaccinations card to get in. Most people in
              Nepal have now had a second dose of vaccine and a booster shot as
              well.
            </p>
            {/*---------------------- safety and security starts --------------------- */}
            <h4 id="insurance" className="trip-detail-title">
              Travel Insurance
            </h4>
            <p className="trip-detail-content">
              For the 13-day trek to Annapurna Base Camp, you must have travel
              insurance. This trek has risks like altitude sickness because it
              takes place in a remote area and goes above 4,000 meters in
              altitude. We take care to make sure that our guests do not get
              altitude sickness while trekking, but everyone's bodies react
              differently, so we can not promise that no one will get sick. So,
              if you have travel insurance, it will help us a lot to help you
              quickly if you need medical help. <br />
              <em>
                <strong>
                  *Note: If you are doing the Annapurna base camp trek, your
                  travel insurance should cover medical bills and emergency air
                  evacuation up to 5,000 meters.
                </strong>
              </em>
            </p>
            {/*---------------------- minimum age starts --------------------- */}
            <h4 id="age" className="trip-detail-title">
              Minimum Age
            </h4>
            <p className="trip-detail-content">
              The minimum age to join our Annapurna base camp trek without the
              guidance of any guardian is 18 years old. If you are below 18
              years old, then you have to trek with an identified guardian. For
              trekkers below 12 years old or if you want to trek with your
              toddler, then contact us. We'll do some consultations and see if
              your child is eligible to be part of Annapurna base camp trekking
              or not. <br />
              No doubt, many couples have trekked with their child to ABC, and
              you can too if your child's health is in good condition. Plus, our
              team gives extra attention to family treks with kids and
              elderlies, so you do not have to worry about anything.
            </p>
            {/*---------------------- best time starts --------------------- */}
            <h4 id="time" className="trip-detail-title">
              Best Time
            </h4>
            <p className="trip-detail-content">
              Spring and fall are the best times to hike to Annapurna base camp.
              Spring (before the monsoon) is in March and April, and Fall (after
              the monsoon) is in October and November. During these months,
              trekkers do not have to worry about bad weather or rain. The
              weather is not too hot or too cold, and the days are clear and
              bright. The average daytime temperature is between 12°C and 20°C,
              and the average evening and morning temperature is between 3°C and
              5°C.
            </p>
            <p className="trip-detail-content">
              With blooming rhododendrons and other alpine plants, the Annapurna
              base camp trail is very pretty in the spring. On the way, you
              might see a lot of birds and animals. In the same way, Autumn has
              its own festive charm. Autumn is when the biggest holidays are
              held, such as Dashain, Bhai Tika, and Chhath puja Nepal . So, you
              will have a great chance to learn about the rich culture of Nepal.
            </p>
            <p className="trip-detail-content">
              Trekkers from all over the world light up the trail. The best
              times to go trekking in the Himalayas are in the spring and fall.
              If you want to go hiking alone during the busiest hiking times,
              you should book places to stay along your route ahead of time.
              There are many trekkers in the Himalayas, especially on well-known
              trails like the ABC. So, it is best to make reservations. If you
              do not, you might have to spend the night in the dining room or
              outside.
            </p>
            {/*---------------------- tipping starts --------------------- */}
            <h4 id="tipping" className="trip-detail-title">
              Tipping
            </h4>
            <p className="trip-detail-content">
              To make tipping easy for you, we have made this guideline that you
              can take as a reference. First of all, tipping trek/tour guides,
              drivers, and porters is common in Nepal. At the end of the trip,
              they may approach you for it, so do not feel awkward. You can tip
              whatever amount you feel comfortable with. For instance, USD 2 for
              the driver, USD 5 for the tour guide, and USD 100 for the trek
              guide (shared with the porter) is the best number.
              <em>
                <strong>
                  *Note: Always tip in the group. Each member can contribute the
                  above number.
                </strong>
              </em>
            </p>
            {/*---------------------- Activities You Can Choose starts --------------------- */}
            <h4 id="activities" className="trip-detail-title">
              Activities You Can Choose
            </h4>
            <p className="trip-detail-content">
              If your budget allows, you can try our luxury Annapurna base camp
              trekking or add a helicopter ride at the end of the trek to see
              the mountains from the air. Pokhara is also a great place for many
              other outdoor activities, like paragliding, mountain biking,
              bungee jumping, ultra-light flight, etc.
            </p>
            <p className="trip-detail-content">
              There are many different treks in the Annapurna region, such as
              Ghorepani Poon Hill Trek, Mohare Danda Trek, Tilicho Lake trek,
              and so on. Nar Phu Valley and Mardi Himal Trek are both great
              treks in the Annapurna area if you want to do something different
              and less crowded.
            </p>
            {/*---------------------- Activities You Can Choose starts --------------------- */}
            <h4 id="group-leader" className="trip-detail-title">
              Group Leader - What They Do
            </h4>
            <p className="trip-detail-content">
              Our group leaders, who are also called "trekking guides," have
              been trained to lead trekkers in the Himalayas. They have had a
              lot of training and earned a lot of certificates, which makes them
              very trustworthy. We work very closely with the best local guides
              in every part of the Himalayas. Their many years of experience and
              friendly personalities will make your trip safe and fun.
            </p>
            <p className="trip-detail-content">
              When there are more than 8 trekkers in a group, an assistant guide
              also goes with the group. The group leader will make sure that
              everyone is safe and will make decisions that are best for the
              whole group. If you feel uncomfortable or have any questions while
              hiking, feel free to ask the guide. They will do everything they
              can to make sure you're comfortable while trekking.
            </p>
            {/*---------------------- Size of Group Notes starts --------------------- */}
            <h4 id="group-notes" className="trip-detail-title">
              Size Of Group Notes
            </h4>
            <p className="trip-detail-content">
              We can change the size of our groups. We set up treks for people
              traveling alone and for groups of more than 12 people. In general,
              our fixed departure group size ranges between 2 to 12 trekkers. If
              you want to hike alone, you can join us on a private hike. You
              will be with our best guide. You will not feel like you have to
              keep up with the group and walk quickly. With our team, you will
              have the best Annapurna base camp trek.
            </p>
            {/*---------------------- transport starts --------------------- */}
            <h4 id="transport" className="trip-detail-title">
              Transports
            </h4>
            <p className="trip-detail-content">
              First, there is transportation to and from the airport in
              Kathmandu. Between Kathmandu and Pokhara, we take a comfortable
              tourist bus or van. We also use the local vehicle to get to and
              from the trailhead and the end of the trail. All ground
              transportation is taken care of according to the plan. You can
              also ask us for a private car if you want to use it to get around
              the city by yourself.
            </p>
            {/*---------------------- local flights starts --------------------- */}
            <h4 id="local-flight" className="trip-detail-title">
              Local Flight
            </h4>
            <p className="trip-detail-content">
              There are no domestic flights as part of the Annapurna base camp
              trek. If you don't want to drive from Kathmandu to Pokhara, you
              can take a short flight instead. If you do that, it will take 30
              minutes to fly. Most of the time, the flights leave on time, but
              sometimes they are late because of the weather. If you want, you
              can also hire a helicopter to fly from Kathmandu to Pokhara.
            </p>
            {/*---------------------- solo travelers starts --------------------- */}
            <h4 id="solo-traveler" className="trip-detail-title">
              Solor Travelers
            </h4>
            <p className="trip-detail-content">
              Our Annapurna base camp trekking package is open to people who are
              traveling alone. You can join one of our groups or go on a private
              trek, depending on what you want. The Everest base camp trek is
              led by an experienced guide who knows how to handle the group,
              knows the trail, and looks out for everyone's safety.
            </p>
            <p className="trip-detail-content">
              If you do not want a guide or a porter, you can trek on your own.
              Every year, there are a fair number of solo trekkers on the
              Annapurna Base Camp Trail. Make sure you know about all the
              obstacles, have everything you need, a good map or compass, a
              place to stay booked ahead of time, and all the necessary permits.
              All the permits are available in Kathmandu and Pokhara tourism
              board offices.
            </p>
            {/*---------------------- joining instructions starts --------------------- */}
            <h4 id="joining" className="trip-detail-title">
              Joining Instructions
            </h4>
            <p className="trip-detail-content">
              You can join the 13-day trek to Annapurna Base Camp by sending us
              an inquiry. On our package page, you can find a very simple way to
              book the trek in just a few minutes.
            </p>
            <p className="trip-detail-content">
              Autumn is the best time to join the Annapurna base camp trek in
              2023. You will also have enough time to get ready for the trek.
              You can also book the Annapurna Base Camp Trek 2023 departure in
              the spring or fall. When you book early, you have time to get
              ready for the trip and learn about the challenges it will bring.
              You can send us a request to book here, or if you have any
              questions, you can talk to our travel consultant at{" "}
              <a href="mailto:info@adventuremastertrek.com">
                info@adventuremastertrek.com
              </a>
            </p>
            {/*---------------------- arrival complications starts --------------------- */}
            <h4 id="arrival" className="trip-detail-title">
              Problems With Arrival
            </h4>
            <p className="trip-detail-content">
              If you cannot get to Kathmandu in time for the trip, let us know
              as soon as possible. We will postpone the trip because you
              requested us to. You can call the emergency number we have given
              you and tell the team about your situation. You may also have to
              pay some cancellation fees, depending on when you tell us.
            </p>
            <p className="trip-detail-content">
              We will do our best to give you a satisfying alternative. Also, if
              you could not find our airport representative, you could use the
              free WIFI at the airport to call the number they gave you. You can
              call us and we will get you a taxi to the hotel.
            </p>
            {/*---------------------- emergency contact starts --------------------- */}
            <h4 id="emergency-contact" className="trip-detail-title">
              Emergency Contact
            </h4>
            <p className="trip-detail-content">
              You can call us at any time at our emergency numbers. These
              numbers can also be used for WhatsApp and Viber. These are the
              numbers to call if you need to reach us in an emergency: +977
              9851033195, +44 7983806200, and +977 9849780311. You can even call
              us during office hours at our office number. (Monday - Friday, 9
              a.m.–5 p.m. local time)
            </p>
            {/*---------------------- visa and entry requirements starts --------------------- */}
            <h4 id="visa" className="trip-detail-title">
              Visa and Entry Requirements
            </h4>
            <p className="trip-detail-content">
              People who want to go to Nepal need a valid passport that is still
              valid for a minimum of six months and a Visa. Most countries can
              get a visa when they arrive in Nepal. This is true at Tribhuvan
              International Airport and other entry points. Make sure to find
              out if your country can get a visa when you arrive or not. If not,
              you can apply for a travel visa at Nepal's embassy or consulate in
              your home country.
            </p>
            <p className="trip-detail-content">
              The traveler is responsible for getting a visa. Check all of the
              visa requirements and make sure you have the right papers with
              you. We can send you a letter confirming your trip. If you don't
              get a visa, the company won't be responsible for that.
            </p>
            {/*---------------------- money exchange starts --------------------- */}
            <h4 id="money-exchange" className="trip-detail-title">
              Money Exchange
            </h4>
            <p className="trip-detail-content">
              You must use Nepali money in Nepal. There are many banks and
              places to exchange money in Kathmandu where you can get a good
              rate. You can use your international cards at most ATMs in
              Kathmandu to get cash.
            </p>
            <p className="trip-detail-content">
              Most hotels, restaurants, cafes, shops, entertainment centers, and
              other places in Kathmandu accept Visa, Mastercard, and other
              credit cards. You will not be able to get cash out during the
              Everest Base Camp trek, so get it in Kathmandu.
            </p>
            {/*---------------------- payment process starts --------------------- */}
            <h4 id="payment-process" className="trip-detail-title">
              Payment Process
            </h4>
            <p className="trip-detail-content">
              You need to pay a 25% deposit up front. If you pay with a credit
              card, you might have to pay an extra 4% charge.
            </p>
            {/*---------------------- cancellation policy starts --------------------- */}
            <h4 id="payment-process" className="trip-detail-title">
              Cancellation Policy
            </h4>
            <p className="trip-detail-content">
              The cancellation fees are calculated as a percentage of the total
              tour price:
            </p>
            <p className="trip-detail-content">
              Cancellation one week before departure: 15% of full payment will
              be deducted Cancellation on the same day: 25% of full payment will
              be deducted
            </p>
            {/*---------------------- emergency fund starts --------------------- */}
            <h4 id="emergency-fund" className="trip-detail-title">
              Emergency Fund
            </h4>
            <p className="trip-detail-content">
            Set aside some money in case you need to stay longer in Nepal, pay for something you did not expect to need, or spend more than you planned on shopping. This emergency fund will hold you back and give you the freedom to travel without worrying.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default TripDetail;
