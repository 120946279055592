import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop(props) {
  const { position } = props;
  const { pathname } = useLocation();

  useEffect(() => {
    if (position === "top") {
      window.scrollTo(0, 0);
    } else if (position === "header") {
      window.scrollTo(0, window.innerHeight - 50);
    }
    console.log("pathnamepathname", pathname);
  }, [pathname]);

  return null;
}
