import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  FaMountain,
  FaUtensils,
  FaBed,
  FaHiking,
  FaBus,
  FaClock,
} from "react-icons/fa";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import kathmandu from "../../../image/kathmandu.jpeg";
import lukla from "../../../image/Everest/lukla-airport.jpg";
import Map from "../../../image/Map/EverestBaseCampTrekWhite.jpg";
import "../../../custom-sass/pages/trip/_trip-detail.scss";
import Pdf from "../../../itineraryDocuments/EverestRegion/EBCTrek.pdf";
import Pdf_Luxury from "../../../itineraryDocuments/EverestRegion/EBCTrek_Luxury.pdf";

import day2 from "../../../image/Everest/EBCTrek/1.jpg";
import day3 from "../../../image/Everest/EBCTrek/2.jpg";
import day4 from "../../../image/Everest/EBCTrek/3.jpg";
import day5 from "../../../image/Everest/EBCTrek/4.jpg";
import day6 from "../../../image/Everest/EBCTrek/5.jpg";
import day7 from "../../../image/Everest/EBCTrek/6.jpg";
import day8 from "../../../image/Everest/EBCTrek/7.jpg";
import day9 from "../../../image/Everest/EBCTrek/8.jpg";
import day10 from "../../../image/Everest/EBCTrek/9.jpg";
import day11 from "../../../image/Everest/EBCTrek/10.jpg";
import day12 from "../../../image/Everest/EBCTrek/11.jpg";
import day13 from "../../../image/Everest/EBCTrek/12.jpg";

const Itinerary = () => {
  const days = [
    {
      day1: [
        {
          title: "Day 1: Arrival in Kathmandu (1400m)",
          image: kathmandu,
          caption:
            "Our airport team will be waiting for you at the international airport terminal to take you to the hotel. Also, they will help you check in and carry your bags. After getting some rest, get together with the rest of the core team in the office of our Adventure Master. We tell you about the trek leader and go over some last-minute details. Evening is free. You can get last-minute things for your trek and walk around Thamel's busy streets.",
          tripDetail: [
            // {
            //   icon: "FaMountain",
            //   detailTitle: "Maximum Altitude",
            //   detailCaption: "1400m",
            // },
            {
              icon: "RestaurantMenuIconfa fa-meals",
              detailTitle: "Meals",
              detailCaption: "Null",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Hotel",
            },
          ],
        },
      ],
      day2: [
        {
          title:
            "Day 2: Flight from Kathmandu to Lukla airport (2,846 m/9,337 ft) and trek to Phakding (2,610 m/8,563 ft)",
          image: day2,
          caption:
            "Get up early to catch an exciting flight from the airport in Kathmandu to the airport in Lukla. The flight is short and has beautiful views. As soon as we land at Tenzing Hillary Airport, we are joined by porters and start walking toward Phakding village.  From Lukla, it is an easy walk downhill through lush forests and along the Dudh Koshi river. On the way to Phakding village, we pass by Cheplung village (2,660 m) and Thado Koshi Gaon (2,580 m). You go over a few bridges. Get to see views of Kusum Kangguru and Kongde Ri.",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Trek Distance",
              detailCaption: "6.2 km/3.8 miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "35 minutes flight & 3 hours trek",
            },
            {
              icon: "RestaurantMenuIcon",
              detailTitle: "Meals",
              detailCaption: "B,L,D",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Lodge",
            },
          ],
        },
      ],
      day3: [
        {
          title:
            "Day 3: Trek from Phakding to Namche Bazaar (3,440 m/11,290 ft)",
          image: day3,
          caption:
            "From the village of Phakding, the path leads through rhododendron and magnolia forests to the town of Toktok (2,760 m). To get to Monjo from Bengkar Village (2,630 m), you have to cross two bridges, including the long Hillary Bridge (2,835 m). At the police checkpoint, we will turn in our permits, and then we will keep walking through lush forests, over a few suspension bridges, and through small villages until we reach Namche Bazaar. As we get closer to Namche Bazaar, the path gets steeper, and we get our first look at Everest.",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Trek Distance",
              detailCaption: "7.4 km/4.6 miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "6 hours",
            },
            {
              icon: "RestaurantMenuIconfa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B,L,D",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Lodge",
            },
          ],
        },
      ],
      day4: [
        {
          title:
            "Day 4: Acclimatization in Namche Bazaar: hike to Syangboche Airstrip (3,748 m/12,297 ft) and Everest View Hotel (3,962 m/13,000 ft)",
          image: day4,
          caption:
            "We rest for a day in Namche Bazaar so that our bodies can get used to the high altitude. Consequently, we will take the Syangboche Airstrip above Namche Bazaar to get to the Everest View Hotel. Therefore, this will be a great hike that starts early in the morning. During the hike, we can see Everest, Thamserku, Ama Dablam, Lhotse, Nuptse, Taboche, Khumbila, and other mountains. The Guinness World Record is held by the Everest View Hotel, which is one of the highest luxury hotels in the world.  We could keep hiking to Khumjung monastery, which is one of the oldest monasteries in the Khumbu area. After the hike, we will go down to Namche Bazaar to spend the rest of the day. In the village, we can visit gift shops, cafes, museums, and other places.",
          tripDetail: [
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "4-5 hours",
            },
            {
              icon: "RestaurantMenuIconfa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B,L,D",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Lodge",
            },
          ],
        },
      ],
      day5: [
        {
          title:
            "Day 5: Trek from Namche Bazaar to Tengboche Monastery (3,860 m/12,660 ft)",
          image: day5,
          caption:
            "The trail goes up along the green foothills from Namche Bazaar to Kyangjuma (3,550 m). Amazing mountain views can be seen along the way. Therefore, the trail winds its way uphill and soon drops down to Phungi Thanga (3,250 m). We walk through a lush forest and up to the village of Tengboche after crossing a bridge. From the back of the Tengboche monastery, you can see Everest, Lhotse, Nuptse, and Ama Dablam very well.",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Trek Distance",
              detailCaption: "9.2 km/5.7 miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "5 hours",
            },
            {
              icon: "RestaurantMenuIconfa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B,L,D",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Lodge",
            },
          ],
        },
      ],
      day6: [
        {
          title: "Day 6: Trek from Tengboche to Dingboche (4,410 m/14,470 ft)",
          image: day6,
          caption:
            "On this day, we get up early to see a beautiful sunrise. We leave Tengboche after breakfast and go down through the lush rhododendron forest to Deboche (3,320 m). We might see musk deer, blue sheep, and monal as we go further. We pass through the village of Pangboche after crossing a suspension bridge over the Imja Khola (3,930 m). Keep walking and cross another bridge. The trail will soon pass through Shomare (4,010 m), then Orsho (4,190 m). Then we walk along the Imja Khola and slowly climb up to the village of Dingboche.",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Trek Distance",
              detailCaption: "12 km/7.45 miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "5 hours",
            },
            {
              icon: "RestaurantMenuIconfa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B,L,D",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Lodge",
            },
          ],
        },
      ],
      day7: [
        {
          title:
            "Day 7: Getting used to the altitude in Dingboche; Hike to Nagarjuna Hill (5,100 m/16,732 ft).",
          image: day7,
          caption:
            "We have the second day off in the village of Dingboche. Therefore, to have a good trekking, you need to get used to the altitude as you go. However, in the morning, we hike to Nagarjuna Hill, which has beautiful views of Lobuche Peak, Lhotse, Nuptse, Cholatse, Kangtega, Ama Dablam, and many other mountains. We spend the rest of the day relaxing and getting to know the village.",
          tripDetail: [
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "4-5 hours",
            },
            {
              icon: "RestaurantMenuIconfa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B,L,D",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Lodge",
            },
          ],
        },
      ],
      day8: [
        {
          title: "Day 8: Hike from Dingboche to Lobuche, which is 4,940 meters (16,210 feet) up.",
          image: day8,
          caption:
            "The path keeps going up a steep hill that leads to Dusa (4,503 m). We take a short break here and then keep going up to Dughla (4,620 m), where we will cross a bridge. On the way, we see tombstones and climb steeply to the village of Lobuche, crossing the Dughla pass (4,830 m). We have passed the tree line, and the scenery has changed in a big way. Views of rocky hills make the mountains stand out more.",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Trek Distance",
              detailCaption: "8.5 km/5.2 miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "5-6 hours",
            },
            {
              icon: "RestaurantMenuIconfa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B,L,D",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Lodge",
            },
          ],
        },
      ],
      day9: [
        {
          title:
            "DAY 9: Walk from Lobuche to Everest Base Camp (5,364 m/17,598 ft) via Gorak Shep (5,164 m/16,942 ft) and back to Gorak Shep for the night.",
          image: day9,
          caption:
            "Today is going to be a long day. First, early in the morning, we start to walk. We go up along the Khumbu Glacier on a rocky path and pass a place called Pyramid (4,970 m). It is a research center in Italy. Therefore, the trail goes up to Gorak Shep from here. After that, we walk past moraines made by glaciers and streams. The way to the Everest Base Camp is well-known. The whole time, we will walk along the Khumbu Glacier and can see Khumbu Icefall. Thus, we will spend the night in Gorka Shep after spending some time at the base camp.",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Trek Distance",
              detailCaption: "15 km/9.32 miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "8 hours",
            },
            {
              icon: "RestaurantMenuIconfa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B,L,D",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Accomodation: Rivendell Lodge",
            },
          ],
        },
      ],
      day10: [
        {
          title:
            "DAY 10: Hike to Kala Patthar (5,644 m/18,519 ft) early in the morning, then trek down to Pheriche (4,371 m/14,340 ft).",
          image: day10,
          caption:
            "A lookout called Kala Patthar is above Gorak Shep. Early in the morning, we hike to Kala Patthar to see a beautiful sunrise and a view of the Everest Himalayan range. As the trail goes up a steep, rough path, tall, snow-covered mountains come into view. Therefore, after taking in the view of the mountains from this spot, which is 30 degrees high, we go down to Gorak Shep. We will walk back the way we came to get to Lobuche and cross Dughla pass again. The path drops down to the village of Pheriche, which is near some small settlements.",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Trek Distance",
              detailCaption: "9.6 km/6 miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "6 hours",
            },
            {
              icon: "RestaurantMenuIconfa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B,L,D",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Lodge",
            },
          ],
        },
      ],
      day11: [
        {
          title:
            "DAY 11: Trek from Pheriche to Namche Bazaar (3,440 m/11,290 ft)",
          image: day11,
          caption:
            "To get to Namche Bazaar from Pheriche, you have to go down a long way. Therefore, you will follow the same path back to the village of Pangboche (3,930 m). We walk through lush rhododendron forests to the village of Tengboche, crossing a number of bridges and small villages on the way (3,860 m). The path descends to Phungi Thanga (3,250 m). From here, we walk down to Namche Bazaar on a winding path along the Dudh Koshi river.",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Trek Distance",
              detailCaption: "17.7 km/10.9 miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "7-8 hours",
            },
            {
              icon: "RestaurantMenuIconfa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B,L,D",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Lodge",
            },
          ],
        },
      ],
      day12: [
        {
          title: "DAY 12: Trek Namche Bazaar to Lukla (2,846 m/9,337 ft)",
          image: day13,
          caption:
            "From Namche Bazaar to Lukla, the trail follows the same path we used to go up. Therefore, we start going down steeply and cross a bridge. To get to Phakding, the trail goes back through dense forests, over several suspension bridges, and through a few small villages (2,610 m). Consequently, we climb up from here to get to Lukla.",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Trek Distance",
              detailCaption: "13.6 km/8.3 miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "8 hours",
            },
            {
              icon: "RestaurantMenuIconfa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B,L,D",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Lodge",
            },
          ],
        },
      ],
      day13: [
        {
          title:
            "DAY 13: Fly back to Kathmandu from the Lukla airport (1,400 m/4,600 ft) in the morning, and have a farewell dinner in the late evening.",
          image: day12,
          caption:
            "The trek to EBC is over. We fly back to Kathmandu early in the morning, taking in the last views of the Himalayas. You will be taken to the hotel by the team. You have a full leisure day. Thamel is a great place to look around the city and window shop. You can go to the spa and the market in town. In the evening, we will have a dinner to say goodbye and celebrate your success in getting to EBC.",
          tripDetail: [
            {
              icon: "FaClock",
              detailTitle: "Flight Duration",
              detailCaption: "35 minutes flight",
            },
            {
              icon: "RestaurantMenuIconfa fa-meals",
              detailTitle: "Meals",
              detailCaption: "Breakfaast & farewell dinner",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Hotel",
            },
          ],
        },
      ],
      day14: [
        {
          title: "DAY 14: Final departure",
          caption:
            "Our airport team will take care of your luggage and check out. You'll be picked up from the hotel according to your flight departure time.",
        },
      ],
    },
  ];

  return (
    <>
      <div className="detail-itinerary container mt-4">
        <div className="row">
          <div className="d-lg-none mb-5">
            <h4 className="title">Trekking Route Map</h4>
            <img
              src={Map}
              alt="everest base camp trek map"
              style={{ width: "100%" }}
            />
            <button type="button" className="btn btn-dark mt-3">
                <a href={Pdf} target="_blank" className="text-light">
                  Download Budget Itinerary
                </a>
              </button>
               <button type="button" className="btn btn-dark mt-3">
                <a href={Pdf_Luxury} target="_blank" className="text-light">
                  Download Luxury Itinerary
                </a>
              </button>
          </div>

          <div className="col-md-12 col-lg-8">
            {days &&
              days.map((day) => (
                <>
                  {day.day1 &&
                    day.day1.map((day1) => (
                      <>
                        <h4 className="itinerary-title">{day1.title}</h4>
                        <div className="itinerary-image">
                          <img src={day1.image} alt="kathmandu" />
                          <figcaption className="text-secondary">
                            Kathmandu (Capital city of Nepal)
                          </figcaption>
                        </div>
                        <div className="row">
                          {day1.tripDetail &&
                            day1.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon ===
                                        "RestaurantMenuIconfa fa-meals" && (
                                        <RestaurantMenuIcon />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-auto"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day1.caption}
                        </div>
                      </>
                    ))}

                  {day.day2 &&
                    day.day2.map((day2) => (
                      <>
                        <h4 className="itinerary-title">{day2.title}</h4>
                        <div className="itinerary-image">
                          <img src={day2.image} alt="lukla airport" />
                          <figcaption className="text-secondary">
                            Lukla airport
                          </figcaption>
                        </div>
                        <div className="row">
                          {day2.tripDetail &&
                            day2.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon ===
                                        "RestaurantMenuIcon" && (
                                        <RestaurantMenuIcon />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-auto"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day2.caption}
                        </div>
                      </>
                    ))}
                  {day.day3 &&
                    day.day3.map((day3) => (
                      <>
                        <h4 className="itinerary-title">{day3.title}</h4>
                        <div className="itinerary-image">
                          <img src={day3.image} alt="" />
                          <figcaption className="text-secondary">
                            Chorten on the way to Namche Bazaar
                          </figcaption>
                        </div>
                        <div className="row">
                          {day3.tripDetail &&
                            day3.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon ===
                                        "RestaurantMenuIconfa fa-meals" && (
                                        <RestaurantMenuIcon />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-auto"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day3.caption}
                        </div>
                      </>
                    ))}
                  {day.day4 &&
                    day.day4.map((day4) => (
                      <>
                        <h4 className="itinerary-title">{day4.title}</h4>
                        <div className="itinerary-image">
                          <img src={day4.image} alt="kathmandu" />
                          <figcaption className="text-secondary">
                            View of Nameche Bazaar
                          </figcaption>
                        </div>
                        <div className="row">
                          {day4.tripDetail &&
                            day4.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon ===
                                        "RestaurantMenuIconfa fa-meals" && (
                                        <RestaurantMenuIcon />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-auto"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day4.caption}
                        </div>
                      </>
                    ))}
                  {day.day5 &&
                    day.day5.map((day5) => (
                      <>
                        <h4 className="itinerary-title">{day5.title}</h4>
                        <div className="itinerary-image">
                          <img src={day5.image} alt="kathmandu" />
                          <figcaption className="text-secondary">
                            View of Mount Ama Dablam
                          </figcaption>
                        </div>
                        <div className="row">
                          {day5.tripDetail &&
                            day5.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon ===
                                        "RestaurantMenuIconfa fa-meals" && (
                                        <RestaurantMenuIcon />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-auto"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day5.caption}
                        </div>
                      </>
                    ))}
                  {day.day6 &&
                    day.day6.map((day6) => (
                      <>
                        <h4 className="itinerary-title">{day6.title}</h4>
                        <div className="itinerary-image">
                          <img src={day6.image} alt="kathmandu" />
                          <figcaption className="text-secondary">
                            The valley of Dingboche
                          </figcaption>
                        </div>
                        <div className="row">
                          {day6.tripDetail &&
                            day6.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon ===
                                        "RestaurantMenuIconfa fa-meals" && (
                                        <RestaurantMenuIcon />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-auto"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day6.caption}
                        </div>
                      </>
                    ))}
                  {day.day7 &&
                    day.day7.map((day7) => (
                      <>
                        <h4 className="itinerary-title">{day7.title}</h4>
                        <div className="itinerary-image">
                          <img src={day7.image} alt="kathmandu" />
                          <figcaption className="text-secondary">
                            View from Nagarjuna Hill
                          </figcaption>
                        </div>
                        <div className="row">
                          {day7.tripDetail &&
                            day7.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaBus" && (
                                        <FaBus fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon ===
                                        "RestaurantMenuIconfa fa-meals" && (
                                        <RestaurantMenuIcon />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-auto"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day7.caption}
                        </div>
                      </>
                    ))}
                  {day.day8 &&
                    day.day8.map((day8) => (
                      <>
                        <h4 className="itinerary-title">{day8.title}</h4>
                        <div className="itinerary-image">
                          <img src={day8.image} alt="kathmandu" />
                          {/* <figcaption className="text-secondary">
                            Kathmandu (Capital city of Nepal)
                          </figcaption> */}
                        </div>
                        <div className="row">
                          {day8.tripDetail &&
                            day8.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon ===
                                        "RestaurantMenuIconfa fa-meals" && (
                                        <RestaurantMenuIcon />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-auto"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day8.caption}
                        </div>
                      </>
                    ))}
                  {day.day9 &&
                    day.day9.map((day9) => (
                      <>
                        <h4 className="itinerary-title">{day9.title}</h4>
                        <div className="itinerary-image">
                          <img src={day9.image} alt="kathmandu" />
                          <figcaption className="text-secondary">
                            Our customer enjoying the view at Everest Base Camp
                          </figcaption>
                        </div>
                        <div className="row">
                          {day9.tripDetail &&
                            day9.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon ===
                                        "RestaurantMenuIconfa fa-meals" && (
                                        <RestaurantMenuIcon />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-auto"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day9.caption}
                        </div>
                      </>
                    ))}
                  {day.day10 &&
                    day.day10.map((day10) => (
                      <>
                        <h4 className="itinerary-title">{day10.title}</h4>
                        <div className="itinerary-image">
                          <img src={day10.image} alt="kathmandu" />
                          <figcaption className="text-secondary">
                            On the way to Kala Patthar
                          </figcaption>
                        </div>
                        <div className="row">
                          {day10.tripDetail &&
                            day10.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon ===
                                        "RestaurantMenuIconfa fa-meals" && (
                                        <RestaurantMenuIcon />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-auto"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day10.caption}
                        </div>
                      </>
                    ))}
                  {day.day11 &&
                    day.day11.map((day11) => (
                      <>
                        <h4 className="itinerary-title">{day11.title}</h4>
                        <div className="itinerary-image">
                          <img src={day11.image} alt="kathmandu" />
                          {/* <figcaption className="text-secondary">
                            Kathmandu (Capital city of Nepal)
                          </figcaption> */}
                        </div>
                        <div className="row">
                          {day11.tripDetail &&
                            day11.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon ===
                                        "RestaurantMenuIconfa fa-meals" && (
                                        <RestaurantMenuIcon />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-auto"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day11.caption}
                        </div>
                      </>
                    ))}
                  {day.day12 &&
                    day.day12.map((day12) => (
                      <>
                        <h4 className="itinerary-title">{day12.title}</h4>
                        <div className="itinerary-image">
                          <img src={day12.image} alt="kathmandu" />
                          <figcaption className="text-secondary">
                            View from Everest view hotel
                          </figcaption>
                        </div>
                        <div className="row">
                          {day12.tripDetail &&
                            day12.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon ===
                                        "RestaurantMenuIconfa fa-meals" && (
                                        <RestaurantMenuIcon />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-auto"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day12.caption}
                        </div>
                      </>
                    ))}
                  {day.day13 &&
                    day.day13.map((day13) => (
                      <>
                        <h4 className="itinerary-title">{day13.title}</h4>
                        <div className="itinerary-image">
                          <img src={day13.image} alt="kathmandu" />
                          <figcaption className="text-secondary">
                            Lukla airport
                          </figcaption>
                        </div>
                        <div className="row">
                          {day13.tripDetail &&
                            day13.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon ===
                                        "RestaurantMenuIconfa fa-meals" && (
                                        <RestaurantMenuIcon />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-auto"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day13.caption}
                        </div>
                      </>
                    ))}
                  {day.day14 &&
                    day.day14.map((day14) => (
                      <>
                        <h4 className="itinerary-title">{day14.title}</h4>

                        {/* <div className="row">
                          {day14.tripDetail &&
                            day14.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "RestaurantMenuIconfa fa-meals" && (
                                        <RestaurantMenuIcon/>
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      className="itinerary-icon-title col-auto"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div> 
                        <div className="pb-5 itinerary-content">
                          {day14.caption}
                        </div>*/}
                      </>
                    ))}
                </>
              ))}
          </div>

          <div className="main-modal-map d-none d-lg-flex">
            <div className="modal-map d-flex flex-column">
              <h4 className="title">Trekking Route Map</h4>
              <img src={Map} alt="everest base camp trek map" />
               <button type="button" className="btn btn-dark mt-3">
                <a href={Pdf} target="_blank" className="text-light">
                  Download Budget Itinerary
                </a>
              </button>
               <button type="button" className="btn btn-dark mt-3">
                <a href={Pdf_Luxury} target="_blank" className="text-light">
                  Download Luxury Itinerary
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Itinerary;
