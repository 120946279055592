import { useInView } from "react-intersection-observer";
import Modals from "./Modals";

import everest from "../../../image/Everest/EBCTrek/mountEverest.jpg";
import ebchelitour3 from "../../../image/Everest/everestmeals.jpeg";
import ebctrek from "../../../image/Everest/EBCTrek/5.jpg";
import ebctrek1 from "../../../image/Everest/EBCTrek/7.jpg";
import ebctrek3 from "../../../image/Everest/EBCTrek/4.jpg";
import ebctrek2 from "../../../image/Everest/dingboche.jpg";

const Overview = () => {
  const tripTitle = "Everest Base Camp Trek - 14 Days";
  const tripPrice = "$1090 pp (Budget Price)";
  const modaltrip = [
    {
      id: 1,
      modalhead: "Trip Overview",
      modaldetail: [
        {
          modaltitle: "Difficulty Level",
          modaltext: <strong>Moderate to Difficult</strong>,
        },
        {
          modaltitle: "Maximum Altitude",
          modaltext: <strong>5,644 m /18,517 ft</strong>,
        },
        {
          modaltitle: "Best Season",
          modaltext: <strong>Spring & Autumn</strong>,
        },
      ],
      trip: "Everest Base Camp Trek",
    },
    {
      id: 2,
      modalhead: "The Challenge",
      modaldetail: [
        {
          modaltitle: "Difficulty Level",
          modaltext: <strong>Moderate to Difficult</strong>,
        },
        {
          modaltitle: "Maximum Altitude",
          modaltext: <strong>5,644 m /18,519 ft</strong>,
        },
      ],
      trip: "Everest Base Camp Trek",
    },
    {
      id: 3,
      modalhead: "Accommodations",
      modaldetail: [
        {
          modaltitle: "Accommodation on the trail",
          modaltext: <strong>Lodge</strong>,
        },
        {
          modaltitle: "In Kathmandu",
          modaltext: <strong>Three-star hotel</strong>,
        },
        {
          modaltitle: "Style",
          modaltext: <strong>Shared</strong>,
        },
      ],
      trip: "Everest Base Camp Trek",
    },
    {
      id: 4,
      modalhead: "Nutrition",
      modaldetail: [
        {
          modaltitle: "Meals in Kathmandu",
          modaltext: <strong>All breakfasts & welcome/farewell dinners</strong>,
        },
        {
          modaltitle: "Meals during the trek",
          modaltext: (
            <strong>Three meals a day (breakfast, lunch & dinner)</strong>
          ),
        },
        {
          modaltitle: "Cuisine",
          modaltext: <strong>Nepali, Tibetan, Continental & Indian</strong>,
        },
      ],
      trip: "Everest Base Camp Trek",
    },
    {
      id: 5,
      modalhead: "Regeneration",
      modaldetail: [
        {
          modaltitle: "Morning stretching",
          modaltext:
            "Practice yoga & meditation by yourself (Or upgrade to yoga trekking, led by a professional yoga instructor)",
        },
        {
          modaltitle: "",
          modaltext: (
            <strong>Re-learn to live slow & be present in every moment</strong>
          ),
        },
      ],
      trip: "Everest Base Camp Trek",
    },
    {
      id: 6,
      modalhead: "Destination",
      modaldetail: [
        {
          modaltitle: "Duration",
          modaltext: <strong>14 Days</strong>,
        },
        {
          modaltitle: "Location",
          modaltext: <strong>Khumbu Region, Nepal</strong>,
        },
        {
          modaltitle: "Province",
          modaltext: <strong>1</strong>,
        },
        {
          modaltitle: "Population",
          modaltext: <strong>9,133+</strong>,
        },
        {
          modaltitle: "Wildlife Species",
          modaltext: <strong>1,500+</strong>,
        },
        {
          modaltitle: "Highest Points",
          modaltext: <strong>EBC (5,364 m) & Kala Patthar (5,644 m)</strong>,
        },
      ],
      trip: "Everest Base Camp Trek",
    },
  ];
  let sendmodaldetails = {};

  const { ref, inView } = useInView();
  const { ref: ref2, inView: inView2 } = useInView();
  const { ref: ref3, inView: inView3 } = useInView();
  const { ref: ref4, inView: inView4 } = useInView();
  const { ref: ref5, inView: inView5 } = useInView();
  const { ref: ref6, inView: inView6 } = useInView();

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll, true);
  // }, [handleScroll]);
  if (inView6 === true) {
    sendmodaldetails = modaltrip[5];
  } else if (inView5 === true) {
    sendmodaldetails = modaltrip[4];
  } else if (inView4 === true) {
    sendmodaldetails = modaltrip[3];
  } else if (inView3 === true) {
    sendmodaldetails = modaltrip[2];
  } else if (inView2 === true) {
    sendmodaldetails = modaltrip[1];
  } else if (inView === true) {
    sendmodaldetails = modaltrip[0];
  } else {
    sendmodaldetails = modaltrip[5];
  }
  return (
    <>
      <div ref={ref} className="trip-details-next container mt-5">
        <div className="trip-fact col-10 flex-sm-wrap mb-3">
          <div className="facts">
            <div className="fact mb-3">
              <h1>EVEREST BASE CAMP TREK - 14 DAYS</h1>
            </div>
            <div ref={ref} className="title-content col-sm-12 col-md-8 mb-3">
              The Everest Base Camp Trek is one of the most exciting ways to get
              to the base of the world's highest mountain, Mount Everest. The
              Everest Base Camp Trek is a great choice for adventure-loving
              people who are ready to take on any challenge. Therefore, the EBC
              trek is an excellent choice.
            </div>
          </div>
        </div>
        <div ref={ref} className="big-title col-sm-12 col-md-7 mb-3">
        MAJOR TOURIST ATTRACTION AT EVEREST BASE CAMP TREK
        </div>
        <div ref={ref} className="title-content col-sm-12 col-md-7 mb-3">
        Ama Dablam (6,812 m/22,349 ft), Thamserku (6,608 m/21,680 ft), Pumori (7,161 m/23,494 ft), Nuptse (7,861 m/25,791 ft), Kangtega (6,782 m/22,251 ft), and Chamlang (7,319 m/24,012 ft) Consequently, hike to different places with great views, like Everest View Hotel (3,962 m/13,000 ft), Nagarjuna Hill (5,100 m/16,732 ft), and Kala Patthar (5,644 m/18,519 ft). The best thing about the Mount Everest base camp trek is getting to know the sherpas and getting to Everest Base Camp (5,364 m/17,598 ft).
        </div>

        <img
          ref={ref}
          src={everest}
          alt="everest"
          className="col-sm-12 col-md-12 mb-3 w-100"
        />

        <div
          ref={ref2}
          className="row wrap justify-content-between col-sm-12 my-3"
        >
          <div className="col-sm-12 col-md-6">
            <div className="big-title col-sm-12 mb-3">
              FACE DIFFICULTIES AND OVERCOME THEM TO UNLOCK A LIFETIME ACHIEVEMENT
            </div>
            <div className="title-content col-sm-12 mb-3">
            This is the kind of plan that is meant to get trekkers to the base camp of the world's highest mountain, Mount Everest, no matter what challenges they face. Therefore, you can test your endurance and give yourself a lifelong achievement. Our expert guides will be with you on the field to make sure everyone is safe. Build your core strength in one of the most extreme places on Earth.
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <img
              src={ebctrek1}
              alt="everest"
              className="col-sm-12 mb-3 long-img"
            />
          </div>
        </div>
        <div
          ref={ref3}
          className="flex row wrap justify-content-between col-sm-12 mt-5"
        >
          <div className="col-sm-12 col-md-6">
            <img src={ebctrek} alt="everest" className="col-sm-12 short-img" />
            <em className="photo-caption text-muted">Everest View Hotel</em>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="big-title col-sm-12 mb-3">
            ALONG THE TREKKING ROUTE, YOU CAN SPEND THE NIGHT IN CLEAN AND COMFORTABLE BASIC LODGES RUN BY SHERPA FAMILIES.
            </div>
            <div className="title-content col-sm-12 mb-3">
            At the end of the day, enjoy the warm hospitality of the lodge owners and unwind in the cozy dining area by chatting with other hikers and drinking hot chocolate or tea. The rooms have two separate beds and very little furniture, but the view from the windows is great. Most people use the same bathroom. After a long, tiring day of walking, there's nothing better than getting into bed and stretching out your sore muscles.
            </div>
          </div>
        </div>
        <div
          ref={ref4}
          className="row wrap justify-content-between col-sm-12 my-3"
        >
          <div className="col-sm-12 col-md-6">
            <div className="big-title col-sm-12 mb-3">
            TRY THE REAL TASTE OF THE MOUNTAINS. SIMPLE AND HEALTHY MEALS FILLED WITH LOVE
            </div>
            <div className="title-content col-sm-12 mb-3">
            When you go on a trip with Adventure Master, food is a big part of the trip. In fact, fresh flavors, delicious dishes, and the stories behind them bring travelers and locals closer together. However, if you have never been to Nepal or eaten Nepali food before, it may take a few times to get used to the taste. Therefore, we do everything we can to get our guests to eat real Nepali food at their meals. In fact, Nepal is home to many different groups, so you can expect a wide range of foods.
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <img
              src={ebchelitour3}
              alt="everest"
              className="col-sm-12 mb-3 long-img"
            />
          </div>
        </div>
        <div
          ref={ref5}
          className="flex row wrap justify-content-between col-sm-12 mt-5"
        >
          <div className="col-sm-12 col-md-6">
            <img
              src={ebctrek2}
              alt="everest"
              className="col-sm-12  mb-3 short-img"
            />
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="big-title col-sm-12 mb-3">
            LET YOUR BODY AND MIND REFRESH IN THE HIMALAYAS.
            </div>
            <div className="title-content col-sm-12 mb-3">
            Adventure is only worthwhile if it has a good mix of excitement and calm. All the little things that big mountains and glaciers conceal are what make the trek to Everest base camp a true adventure. We call them the sound of birds chirping, the sound of rivers rushing, the smell of lush forests, the sound of waterfalls falling, the cool air, and the friendly people who live there. Hence, you will learn to take things slowly and pay attention to each breath you take. Furthermore, you will learn how to be strong and have more faith in yourself.  
            </div>
          </div>
        </div>
        <div
          ref={ref6}
          className="row wrap justify-content-between col-sm-12 my-3"
        >
          <div className="col-sm-12 col-md-6">
            <div className="big-title col-sm-12 mb-3">
            EVEREST BASE CAMP, AT THE FOOT OF MOUNT EVEREST, THE HIGHEST MOUNTAIN IN THE WORLD
            </div>
            <div className="title-content col-sm-12 mb-3">
            Trekking to Everest Base Camp is an unforgettable adventure in the Himalayas. Therefore, the trek to Sagarmatha National Park takes 14 days. The trail winds through rhododendron forests and small mountain villages where the well-known Sherpas live. Therefore, the trail is impressive because it has a lot of plants and animals and offers a variety of beautiful views. Hence, You will get to know the locals and see mule and yak herds. When in the Himalayas, you may feel as though giants are watching over you.
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <img
              src={ebctrek3}
              alt="everest"
              className="col-sm-12 mb-3 long-img"
            />
          </div>
        </div>
      </div>
      {/* <div className="container map"></div> */}
      <div className="main-modal">
        <Modals
          sendmodaldetails={sendmodaldetails}
          tripTitle={tripTitle}
          tripPrice={tripPrice}
        />
      </div>
    </>
  );
};

export default Overview;
