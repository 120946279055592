import React from 'react'
import InfoIcon from "@mui/icons-material/Info";
import { FaCheckCircle } from 'react-icons/fa';
import CancelIcon from "@mui/icons-material/Cancel";


function EBCHeliTourluxuryIcon() {
    return (
        <div>
            <div class="modal fade luxury-package-modal" id="ebchelitourluxuryicon" aria-hidden="true" aria-labelledby="ebchelitourluxuryiconLabel" tabindex="-1">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5 " id="ebchelitourluxuryiconLabel">Luxury package cost Include</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body ">
                            <ul className="cost-include">
                                <span>
                                    <div className="cost-include-icon">
                                        <FaCheckCircle />
                                    </div>
                                    <li>Transportation airport pick up drop by car </li>
                                </span>
                                <span>
                                    <div className="cost-include-icon">
                                        <FaCheckCircle />
                                    </div>
                                    <li>Airport tax</li>
                                </span>
                                <span>
                                    <div className="cost-include-icon">
                                        <FaCheckCircle />
                                    </div>
                                    <li>5/6 tour lukla/kalapather landing</li>
                                </span>
                                <span>
                                    <div className="cost-include-icon">
                                        <FaCheckCircle />
                                    </div>
                                    <li>Overfly EBC/Everest View Hotel</li>
                                </span>
                                <span>
                                    <div className="cost-include-icon">
                                        <FaCheckCircle />
                                    </div>
                                    <li>Landing at Everest View Hotel</li>
                                </span>
                                <span>
                                    <div className="cost-include-icon">
                                        <FaCheckCircle />
                                    </div>
                                    <li>Lukla</li>
                                </span>
                                <span>
                                    <div className="cost-include-icon">
                                        <FaCheckCircle />
                                    </div>
                                    <li>No extra credit card fee </li>
                                </span>
                                <span>
                                    <div className="cost-include-icon">
                                        <FaCheckCircle />
                                    </div>
                                    <li>First aid kit box </li>
                                </span>
                                <span>
                                    <div className="cost-include-icon">
                                        <FaCheckCircle />
                                    </div>
                                    <li>Oxygen cylinder</li>
                                </span>
                                <span>
                                    <div className="cost-include-icon">
                                        <FaCheckCircle />
                                    </div>
                                    <li>Full insurance</li>
                                </span>
                                <span>
                                    <div className="cost-include-icon">
                                        <FaCheckCircle />
                                    </div>
                                    <li>Certificate</li>
                                </span>
                                <span>
                                    <div className="cost-include-icon">
                                        <FaCheckCircle />
                                    </div>
                                    <li>Farewell dinner</li>
                                </span>
                            </ul>
                        </div>
                        <div class="modal-footer">
                            <button class="btn btn-dark" data-bs-target="#ebchelitourluxuryicon2" data-bs-toggle="modal">View cost exclude</button>
                            <button class="btn btn-outline-secondary" data-bs-target="" data-bs-toggle="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade luxury-package-modal" id="ebchelitourluxuryicon2" aria-hidden="true" aria-labelledby="ebchelitourluxuryiconLabel2" tabindex="-1">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5 " id="ebchelitourluxuryiconLabel2">Luxury package cost exclude</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body ">
                            <ul className="cost-exclude">
                                <span>
                                    <div className="cost-exclude-icon">
                                        <CancelIcon />
                                    </div>
                                    <li>
                                        Breakfast
                                    </li>
                                </span>
                                <span>
                                    <div className="cost-exclude-icon">
                                        <CancelIcon />
                                    </div>
                                    <li>National park fee</li>
                                </span>
                                <span>
                                    <div className="cost-exclude-icon">
                                        <CancelIcon />
                                    </div>
                                    <li>Down jacket</li>
                                </span>
                                <span>
                                    <div className="cost-exclude-icon">
                                        <CancelIcon />
                                    </div>
                                    <li>Water bottle</li>
                                </span>
                                <span>
                                    <div className="cost-exclude-icon">
                                        <CancelIcon />
                                    </div>
                                    <li>Personal insurance</li>
                                </span>
                            </ul>
                        </div>
                        <div class="modal-footer">
                            <button class="btn btn-dark" data-bs-target="#ebchelitourluxuryicon" data-bs-toggle="modal">View cost include</button>
                            <button class="btn btn-outline-secondary" data-bs-target="" data-bs-toggle="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
            <button class="luxury-package-modal-btn" data-bs-target="#ebchelitourluxuryicon" data-bs-toggle="modal"><InfoIcon /></button>
        </div>
    )
}

export default EBCHeliTourluxuryIcon



