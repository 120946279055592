import React from "react";
import { FaCheckCircle } from "react-icons/fa";

import Accordion from "react-bootstrap/Accordion";
import CancelIcon from "@mui/icons-material/Cancel";

import TripOptionTable from "./TripOptionTable.js";
import Gallery from "./Gallery";
import TripFact from "./TripFact.jsx";
export default function Overview() {
  return (
    <div className="overview">
      <div className="container d-flex mt-5">
        <div className="row">
          {/* <div className="col-lg-9 col-sm-12"> */}
          <div className="col-12">
            <TripFact />
            <Gallery />

            <TripOptionTable />

            <h4 className="overview-title pt-5">Outline Itinerary</h4>
            <ul className="p-0">
              <div className="overview-outline-itinerary">
                <div className="col-auto">
                  <strong>Day 01:</strong>
                </div>
                <li> Arrival in Kathmandu</li>
              </div>

              <div className="overview-outline-itinerary">
                <div className="col-auto">
                  <strong>Day 02:</strong>
                </div>
                <li> Kathmandu to Besisahar (760 m/2,493 ft)</li>
              </div>

              <div className="overview-outline-itinerary">
                <div className="col-auto">
                  <strong>Day 03:</strong>
                </div>
                <li>Drive from Besisahar– Chame (2,670 m/8,759 ft)</li>
              </div>

              <div className="overview-outline-itinerary">
                <div className="col-auto">
                  <strong>Day 04:</strong>
                </div>
                <li> Chame – Upper Pisang (3,300 m/10,826 ft)</li>
              </div>

              <div className="overview-outline-itinerary">
                <div className="col-auto">
                  <strong>Day 05:</strong>
                </div>
                <li> Pisang – Manang (3,540 m/11,300 ft)</li>
              </div>

              <div className="overview-outline-itinerary">
                <div className="col-auto">
                  <strong>Day 06:</strong>
                </div>
                <li> Manang acclimatization</li>
              </div>

              <div className="overview-outline-itinerary">
                <div className="col-auto">
                  <strong>Day 07:</strong>
                </div>
                <li> Manang to Siri Kharka (3,800 m/12,467 ft)</li>
              </div>

              <div className="overview-outline-itinerary">
                <div className="col-auto">
                  <strong>Day 08:</strong>
                </div>
                <li>Siri Kharka to Tilicho Base Camp (4,150 m/13,615 ft)</li>
              </div>

              <div className="overview-outline-itinerary">
                <div className="col-auto">
                  <strong>Day 09:</strong>
                </div>
                <li>Tilicho Base Camp to Tilicho Lake (4,920 m/16,141 ft)</li>
              </div>

              <div className="overview-outline-itinerary">
                <div className="col-auto">
                  <strong>Day 10:</strong>
                </div>
                <li>Tilicho Base Camp to Yak Kharka (4,050 m/13,287 ft)</li>
              </div>

              <div className="overview-outline-itinerary">
                <div className="col-auto">
                  <strong>Day 11:</strong>
                </div>
                <li> Yak Kharka – Thorong Phedi (4,525 m/14,525 ft)</li>
              </div>

              <div className="overview-outline-itinerary">
                <div className="col-auto">
                  <strong>Day 12:</strong>
                </div>
                <li>
                  Trek from Thorong Phedi to Muktinath Temple (3,760 m/12,623
                  ft) via Thorong La pass (5,416 m/17,756 ft)
                </li>
              </div>

              <div className="overview-outline-itinerary">
                <div className="col-auto">
                  <strong>Day 13:</strong>
                </div>
                <li>
                  Drive from Muktinath to Jomsom (2,720 m/8,924 ft) and continue
                  to Tatopani (1,200 m/3,940 ft)
                </li>
              </div>

              <div className="overview-outline-itinerary">
                <div className="col-auto">
                  <strong>Day 14:</strong>
                </div>
                <li> Trek Tatopani to Ghorepani (2,860 m/9,385 ft)</li>
              </div>

              <div className="overview-outline-itinerary">
                <div className="col-auto">
                  <strong>Day 15:</strong>
                </div>
                <li>
                  Early morning hike to Poon Hill (3,210 m/10,525 ft), then trek
                  to Nayapul & drive to Pokhara
                </li>
              </div>

              <div className="overview-outline-itinerary">
                <div className="col-auto">
                  <strong>Day 16:</strong>
                </div>
                <li> Pokhara to Kathmandu (1,300 m/4,265 ft)</li>
              </div>

              <div className="overview-outline-itinerary">
                <div className="col-auto">
                  <strong>Day 17:</strong>
                </div>
                <li> Departure from Kathmandu</li>
              </div>
            </ul>

            <h4 className="overview-title pt-5">Trip Highlight</h4>
            <ul className="trip-highlight">
              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>
                  Walk through lush rhododendron forests and meadows, crossing
                  thrilling suspension bridges
                </li>
              </span>

              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>Overnight stays in picturesque villages along the trail</li>
              </span>

              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>
                  Go on a side excursion to Gangapurna Lake (ice lake) during
                  acclimatization day in Manang village
                </li>
              </span>

              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>Unwind and relax at the shore of Tilicho Lake</li>
              </span>

              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>Cross the highest pass in Nepal, Thorong La</li>
              </span>

              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>Get blessing in the Muktinath Temple, a pilgrimage site</li>
              </span>

              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>
                  Walking along the deepest gorge in the world, Kali Gandaki
                  gorge
                </li>
              </span>

              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>
                  Pass by beautiful apple orchards and white-washed houses on
                  the way to Jomsom
                </li>
              </span>

              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>
                  Enjoy natural hot springs in Tatopani and hike to the famous
                  Poon hill viewpoint to catch gorgeous sunrise
                </li>
              </span>

              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>
                  Spend a night in Pokhara, enjoying tranquility of Phewa Lake
                </li>
              </span>
            </ul>

            <div className="row d-flex justify-content-between">
              <div className="col-12">
                <h4 className="overview-title pt-5">Cost Include</h4>
                <ul className="cost-include">
                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>
                      All ground transportation, including airport transfers, is done by private car.
                    </li>
                  </span>
                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>Drive from Pokhara from Kathmandu by luxurious tourist bus. </li>
                  </span>
                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>Drive from Kathmandu to Besisahar via bus.</li>
                  </span>

                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>
                      Drive from Muktinath to Tatopani to Jomsom by bus. 
                    </li>
                  </span>

                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>
                      One night accommodation in a standard hotel at kathmandu with breakfast included. (as per the itinerary)
                    </li>
                  </span>

                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>
                      One night accommodation in a standard hotel at Pokhara with breakfast included. (as per the itinerary)
                    </li>
                  </span>

                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>
                      The best available lodge for two people to share during the trek
                    </li>
                  </span>

                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>During the trek, you will be provided with three meals a day: breakfast, lunch, and dinner with one cup of tea. </li>
                  </span>

                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>The best available lodge for two people to share during the trek</li>
                  </span>

                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>A map of a trek and a certificate of completion of the trip</li>
                  </span>

                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>Taxes and official costs paid to the government.</li>
                  </span>

                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>Oxymeter to check your pulse, oxygen saturation, and heart rate twice a day (very helpful to check for signs of Altitude Mountain Sickness, or AMS). This will make sure that your trek is in the safest hands possible, no matter how experienced you are.</li>
                  </span>

                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>Strong, helpful porters with the right safety gear and walking gear, as well as a salary, food, a place to live, and insurance. (one porter for two people).</li>
                  </span>

                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>A highly experienced, helpful, knowledgeable, friendly, English-speaking guide with all of his salary, food, drinks, lodging, transportation, and insurance covered.</li>
                  </span>

                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>All papers that need to be done, Fees for entering permits you need to go while hiking.</li>
                  </span>
                </ul>
              </div>

              <div className="col-12">
                <h4 className="overview-title pt-5">Cost Exclude</h4>
                <ul className="cost-exclude">
                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>
                      Food that you will eat in Kathmandu for lunch and dinner
                    </li>
                  </span>

                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>
                      <p>The cost of a visa to enter Nepal is: <br/>
                      $30 USD for a 15-day stay<br/> 
                      30 Days - $50, <br/>
                      90 Days - $125  
                      </p>
                    </li>
                  </span>

                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>International Airfare.</li>
                  </span>

                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>Your personal expenses.</li>
                  </span>

                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>All of the alcoholic and non-alcoholic cold and hot drinks you buy on trek (along the way and in the Tea Houses at night).</li>
                  </span>

                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>All of the sweet desserts, like chocolate, cake, pie, and pudding.</li>
                  </span>

                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>The tea houses have hot showers and places to charge your phone which will cost you about 2$ to 5$</li>
                  </span>

                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>
                      Tip to the guide, the porter, and the driver. (Tipping is expected)
                    </li>
                  </span>
                </ul>
              </div>
            </div>

            <h4 className="overview-title pt-5">FAQ</h4>

            <div className="trip-overview-accordion">
              <Accordion defaultActiveKey="0" flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <p class="accordion-title">
                      1) How difficult is Tilicho Lake Trek?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class="overview-content">
                      The Tilicho lake with Annapurna circuit is a strenuous
                      trek, which means it is one of the most challenging treks
                      in the Himalayas. The terrain is rugged and steep,
                      involves glacial/snow walking, and crosses a high pass.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <p class="accordion-title">
                      2) How fit do you have to be to do the Annapurna circuit
                      trek with Tilicho lake?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class="accordion-content">
                      To join the Annapurna circuit trek with Tilicho lake, you
                      have to be fit enough to walk 6 hours a day and adjust
                      with simple accommodation & food. Your stamina and
                      physical fitness matter a lot for this trek.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    <p class="accordion-title">
                      3) Can a beginner do Annapurna Circuit and Tilicho lake
                      trekking?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class="overview-content">
                      No, we do not recommend this trek to beginners because of
                      its high elevation and trodden trail. This trek requires
                      strong physical fitness and determination. The trail
                      involves long steep ascents and descents.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    <p class="accordion-title">
                      4) Is Tilicho Lake the highest lake in the world?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class="overview-content">
                      Tilicho lake is the second highest lake in the world, with
                      an elevation of 4,919 meters. The Kajin Sara lake in the
                      Chame rural municipality is the highest in the world
                      situated at 5,020 m.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    <p class="accordion-title">
                      5) What is the depth of Tilicho Lake?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class="overview-content">
                      Tilicho Lake is the second highest lake in the world,
                      situated at 4,919 m. Its depth is 85 m (279 ft), and its
                      surface area is 4.8 km2 (1.9 sq mi).
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header>
                    <p class="accordion-title">
                      6) Is the Annapurna circuit trek with Tilicho lake still
                      worth it?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class="overview-content">
                      Yes, the Annapurna circuit and Tilicho lake trek are
                      totally worth it. It is a very adventurous trek with lots
                      of beautiful surprises. The trail winds through remote
                      villages and gorgeous valleys to the highest pass in the
                      Himalayas, Throng La & a jaw-dropping glacial lake,
                      Tilicho Lake.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                  <Accordion.Header>
                    <p className="accordion-title">
                      7) How long does it take to hike Annapurna Circuit with
                      Tilicho lake?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class="overview-content">
                      It takes 17 to 22 days to do Annapurna Circuit with
                      Tilicho lake in Nepal. The Annapurna Circuit trails cover
                      around 160 km to 230 km, and combining the Tilicho Lake &
                      Annapurna sanctuary will increase the distance.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                  <Accordion.Header>
                    <p class="accordion-title">
                      8) Can you swim in Tilicho Lake?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class="overview-content">
                      We advise you not to swim in Tilicho Lake because of the
                      inaccessible shoreline and cold temperature of the water.
                      There are no public swimming facilities available at
                      Tilicho Lake.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="8">
                  <Accordion.Header>
                    <p class="accordion-title">
                      9) Can you see Everest from the Annapurna Circuit?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class="overview-content">
                      No, Mt. Everest is not visible during the Annapurna
                      circuit and Tilicho lake trek. However, you'll get to
                      enjoy stunning views of many other mountains like
                      Annapurna, Dhaulagiri, Nilgiri, Tilicho Peak, Chulu Peak,
                      Pisang Peak, Gangapurna, Tukuche, Fishtail, etc.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="9">
                  <Accordion.Header>
                    <p class="accordion-title">
                      10) How cold is Annapurna Circuit?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class="overview-content">
                      The upper part of the trail is colder, and the temperature
                      ranges from 5°C to 10°C. The mornings and nights are
                      comparatively colder. We recommend you carry a few warm
                      pieces of clothes with you even when trekking in
                      spring/winter.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="10">
                  <Accordion.Header>
                    <p class="accordion-title">
                      11) What is the difference between Annapurna Base Camp and
                      Annapurna Circuit?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class="overview-content">
                      The Annapurna base camp and Annapurna Circuit explore two
                      different trekking routes in the Himalayas. The ABC trek
                      ascends up to 4,131 m/13,553 ft. The Annapurna Circuit
                      ascends up to 5,416 m/17,769 ft. The Annapurna Circuit
                      trek is tougher than the ABC tek and is only recommended
                      for experienced trekkers.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="11">
                  <Accordion.Header>
                    <p class="accordion-title">
                      12) Is Annapurna Circuit open now?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class="overview-content">
                      Yes, the Annapurna circuit and Tilicho lake tail are open
                      to travelers from around the world. You can enjoy this
                      adventurous tek with friends and family or go on a solo
                      private trek with us.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="11">
                  <Accordion.Header>
                    <p class="accordion-title">
                      13) Can you hike the Annapurna Circuit on your own?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class="overview-content">
                      Yes, you can embark on Annapurna Circuit and Tilicho Lake
                      trek on your own. Unlike other trekking routes, the
                      Annapurna circuit trail is more strenuous and risky in
                      terms of steep & rough trails. We recommend this trek with
                      a guide for a safe and wonderful trekking experience.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="11">
                  <Accordion.Header>
                    <p class="accordion-title">
                      14) How to book an Annapurna Circuit Trek with Tilicho
                      Lake?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class="overview-content">
                      To book the Annapurna circuit with Tilicho lake trek, send
                      us your inquiry through our site. O, you can also do
                      booking directly from our site and proceed with the
                      payments. Our team will get in touch with us as soon as we
                      get your booking request and help you with further process
                      & Preparation.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
