import React from "react";

const TripCard = (props) => {
  const { cardInfo } = props;
  const renderCard = (card, index) => {
    return (
      <div className="col-sm-12 col-md-6 col-lg-4 mt-5">
        <div className="trip-card" key={index}>
          <div className="trip-card-image">
            <a href={card.link} target="_blank" rel="noreferrer">
              <img src={card.image} alt="img" />
            </a>
          </div>
          <div className="trip-card-review"></div>
          <div className="d-flex justify-content-between mt-3 align-items-center">
            <div className="trip-card-destination">
              <span className="me-1">{card.countryIcon}</span>
              {card.country}
            </div>
            <div className="trip-card-review align-items-center">
              <a href="https://www.tripadvisor.com/Attraction_Review-g293890-d8661857-Reviews-Adventure_Master_Trek-Kathmandu_Kathmandu_Valley_Bagmati_Zone_Central_Region.html" target="_blank">
                <span className="me-1 trip-card-review-icon">
                  {card.reviewIcon1}
                  {card.reviewIcon2}
                  {card.reviewIcon3}
                  {card.reviewIcon4}
                  {card.reviewIcon5}
                </span>
              </a>
              <p className="trip-card-review-number">{card.review}</p>
            </div>
            <div className="trip-card-days">
              <span className="me-1">{card.durationIcon}</span>
              {card.duration}
            </div>
          </div>
          <h4 className="trip-card-title py-1 my-4">{card.title}</h4>
          <div className="d-flex justify-content-between align-items-center mb-3 px-1">
            <div className="trip-card-price fw-bold">{card.price}</div>
            <a href={card.link} target="_blank" rel="noreferrer">
              <div className="trip-card-btn btn btn-dark">{card.btn}</div>
            </a>
          </div>
        </div>
      </div>
    );
  };

  return <div className="row tripCard">{cardInfo.map(renderCard)}</div>;
};

export default TripCard;
