import React from "react";
import { Col, Row } from "react-bootstrap";
import { FaMountain, FaUtensils, FaBed, FaHiking } from "react-icons/fa";
import { FaBus } from "react-icons/fa";
import { FaClock } from "react-icons/fa";

import "../../../custom-sass/pages/trip/_trip-detail.scss";

import day1 from "../../../image/kathmandu.jpeg";
import day2 from "../../../image/thamel.jpg";
import day3 from "../../../image/phakding.jpg";
import day4 from "../../../image/Everest/EBCTrek/3.jpg";
import day5 from "../../../image/Everest/EBCTrek/2.jpg";
import day6 from "../../../image/GokyoLake/dole.jpg";
import day7 from "../../../image/GokyoLake/machhermo.jpg";
import day8 from "../../../image/Everest/gokyolaketrek1.jpeg";
import day9 from "../../../image/Everest/gokyolaketrek2.jpg";
import day10 from "../../../image/GokyoLake/dole1.jpg";
import day11 from "../../../image/GokyoLake/monjo.jpg";
import day12 from "../../../image/Everest/EBCTrek/1.jpg";
import day13 from "../../../image/kathmanduAirport.jpg";

import Map from "../../../image/Map/gokyoLakeTrekMap.jpg";
import Pdf from "../../../itineraryDocuments/EverestRegion/GokyoLakeTrek.pdf";

const Itinerary = () => {
  const days = [
    {
      day1: [
        {
          title: "Day 1: Kathmandu arrival (1,400m)",
          image: day1,
          caption:
            "Our team welcomes you at the airport on your arrival day. We use a private vehicle to escort you to the designated hotel. Nothing is planned for this day. We only meet you in the late evening over welcome dinners. The rest of the day, you can explore and unpack.",
          tripDetail: [
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "Null",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Hotel",
            },
          ],
        },
      ],
      day2: [
        {
          title:
            "Day 2: Cultural tours around Kathmandu Valley & trek preparation",
          image: day2,
          caption:
            "We have half-day sightseeing planned for today. One of our friendly tour guides will accompany you during the excursion. You'll visit famous tourist sites like the Monkey Temple, Boudhnath Stupa, Pashupatinath Temple, and Kathmandu Durbar Square. All of these monuments represent Nepal's diverse culture and religion. After the tour, we'll take a short break and get together in our office. You'll meet with the trek guide and begin preparing for the trek. We arranged all the documents and permits. The team leader will help you with final packing and double-check all the trekking supplies.",
          tripDetail: [
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "Breakfast only",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Hotel",
            },
          ],
        },
      ],
      day3: [
        {
          title:
            "Day 3: Fly to Lukla (2,846 m/9,337 ft) and trek to Phakding (2,610 m/8,563 ft)",
          image: day3,
          caption:
            "Wake up early in the morning and have a warm cup of coffee before leaving for the airport. A 35 minutes flight will take you to Lukla Airport, where you will meet with the rest of the crew members. The flight offers sensational views of the Himalayas. From Lukla, you descend to Phakding for a night's stay, following a forested path. The trail passes by Ghat village before reaching Phakding.",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Distance",
              detailCaption: "6.2 km/3.8 miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Duration",
              detailCaption: "35 minutes flight & 3-4 hours trek",
            },
            {
              icon: "FaMountain",
              detailTitle: "Maximum Altitude",
              detailCaption: "2846m",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B,L,D",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Lodge",
            },
          ],
        },
      ],
      day4: [
        {
          title: "Day 4: Trek to Namche Bazaar (3,440 m/11,290 ft)",
          image: day4,
          caption:
            "The trail today follows Sagarmatha National Park from Monjo village onwards. From Phakding, you walk through lush forests crossing several suspension bridges over the Dudh Koshi River. Upon reaching Monjo, you will register your permits at the police check post and continue trekking towards Namche Bazar. The trail will involve steep and rugged ascent, therefore walk safely. ",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Distance",
              detailCaption: "7.4 km/4.6 miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Duration",
              detailCaption: "5-6 hours",
            },
            {
              icon: "FaMountain",
              detailTitle: "Maximum Altitude",
              detailCaption: "3440m",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B,L,D",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Lodge",
            },
          ],
        },
      ],
      day5: [
        {
          title:
            "Day 05: Acclimatization day at Namche Bazaar: hike to Everest View Hotel (3,962 m)",
          image: day5,
          caption:
            "Rest day is very important during the trek to acclimate your body and keep you away from altitude sickness. It is also the best thing during the trek as you will get to spend more time in the major villages and explore hidden areas. During your rest day in Namche Bazar, you will go on an excursion to the Everest View Hotel (3,962 m) and Syangboche Airstrip, which offers stunning views of the Himalayan mountains, including Mt. Everest. There are a few other alternatives available to explore around this village.",
          tripDetail: [
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "5 hours",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B,L,D",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Lodge",
            },
          ],
        },
      ],
      day6: [
        {
          title: "Day 06: Trek to Dole (4,200 m/13,799 ft)",
          image: day6,
          caption:
            "It's a beautiful trail from Namche to Dole. We’ll walk uphill to Mong. Here, we take a small break for lunch. The trail from here separates EBC and Gokyo Lakes. We continue trekking up to Mong La pass, passing by shades of trees. The trail gradually gets rugged and dusty. The views of the mountains are phenomenal on the way to Dole.",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Trek Distance",
              detailCaption: "12 km/7.4 miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "5 hours",
            },
            {
              icon: "FaMountain",
              detailTitle: "Maximum Altitude",
              detailCaption: "4200m",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B,L,D",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Lodge",
            },
          ],
        },
      ],
      day7: [
        {
          title: "Day 7: Trek to Machhermo (4,470 m/14,665 ft)",
          image: day7,
          caption:
            "Leaving DOle, we followed the trail to Gokyo Lakes. We slowly ascend, enjoying prominent views of the snow-capped mountains. It's a short walking day, so we'll have ample time to rest in Machhermo. Soon, we reach Machhermo to spend the night. We interact with the locals and spend the rest of the day enjoying the food and the company of the group.",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Trek Distance",
              detailCaption: "7 km/4.3 miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "5 hours",
            },
            {
              icon: "FaMountain",
              detailTitle: "Maximum Altitude",
              detailCaption: "4470m",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B,L,D",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Lodge",
            },
          ],
        },
      ],
      day8: [
        {
          title: "Day 8:  Trek to Gokyo (4,750 m/15,583 ft)",
          image: day8,
          caption:
            "From Machhermo, we ascend towards Gokyo village. The terrain has a gentle pace and mesmerizing views. Walking through small settlement areas, we soon pass by the third lake, Dudh Pokhara, where the Gokyo village is located. Gokyo village is stunning and offers snow-capped mountain views around it.",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Duration",
              detailCaption: "5.7 km/3.5 miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "4-5 hours",
            },
            {
              icon: "FaMountain",
              detailTitle: "Maximum Altitude",
              detailCaption: "4750m",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B,L,D",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Lodge",
            },
          ],
        },
      ],
      day9: [
        {
          title: "DAY 9:  Gokyo Ri hiking (5,357 m/17,575 ft)",
          image: day9,
          caption:
            "Today, we take a rest day in Gokyo village. We'll go hiking to Gokyo Ri. It is a famous viewpoint that offers stunning views of our eight-thousanders; Everest, Lhotse, Makalu, and Cho Oyu, along with other mountains. Later, we'll explore other Gokyo Lakes and Gokyo villages.",
          tripDetail: [
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "4-5 hours",
            },
            {
              icon: "FaMountain",
              detailTitle: "Maximum Altitude",
              detailCaption: "2310m",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B,L,D",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Lodge",
            },
          ],
        },
      ],
      day10: [
        {
          title: "DAY 10: Trek to Dole (4,200/13,799 ft)",
          image: day10,
          caption:
            "After having breakfast, we retrace our path to Machhermo. The trail then gradually descends along foothills to Dole. The views are fantastic. We pass by small villages and come across tributaries on the way.",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Duration",
              detailCaption: "12.7 km/7.8 miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "7 hours",
            },
            {
              icon: "FaMountain",
              detailTitle: "Maximum Altitude",
              detailCaption: "4200m",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B,L,D",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Lodge",
            },
          ],
        },
      ],
      day11: [
        {
          title: "DAY 11: Trek to Monjo (2,835 m/9,301 ft)",
          image: day11,
          caption:
            "The trail continues to Namche Bazaar. From here, we steeply descend to Monjo village, crossing many suspension bridges and walking through dense forests. This part of the trail offers diverse flora and fauna.",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Duration",
              detailCaption: "16.1 km/10 miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "6 hours",
            },
            {
              icon: "FaMountain",
              detailTitle: "Maximum Altitude",
              detailCaption: "2835m",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B,L,D",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Hotel",
            },
          ],
        },
      ],
      day12: [
        {
          title: "DAY 12: Trek to Lukla (2,846 m/9,337 ft)",
          image: day12,
          caption:
            "We begin trekking and reach Phakding village walking through lush forests and crossing multiple bridges. The trail then ascends to Lukla along the Dudh Kosi river. It's the last day of our trek.",
          tripDetail: [
            {
              icon: "FaBus",
              detailTitle: "Duration",
              detailCaption: "13 km/8.8 miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "5-6 hours",
            },
            {
              icon: "FaMountain",
              detailTitle: "Maximum Altitude",
              detailCaption: "2846m",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B,L & Farewell Dinner",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Hotel",
            },
          ],
        },
      ],
      day13: [
        {
          title: "DAY 13:  Fly to Kathmandu (1,400m)",
          image: day13,
          caption:
            "Have a warm breakfast and take an early flight back to Kathmandu. One of our team members will take you to the hotel. Enjoy the rest of the day as you desire, and later meet with the team for a farewell dinner. Do not forget to do window shopping in Thamel.",
          tripDetail: [
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "35 minutes",
            },
            {
              icon: "FaMountain",
              detailTitle: "Maximum Altitude",
              detailCaption: "1300m",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "Breakfast & farewell dinner",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Hotel",
            },
          ],
        },
      ],
      day14: [
        {
          title: "DAY 14: Free (Contingency) day",
          caption:
            "We have one extra day to recover from any unseen event during the trek. If we complete the trek as per our plan, then you'll have an extra day in Kathmandu. ",
          // tripDetail: [
          //   {
          //     icon: "FaClock",
          //     detailTitle: "Trek Duration",
          //     detailCaption: "35 minutes",
          //   },
          //   {
          //     icon: "FaMountain",
          //     detailTitle: "Maximum Altitude",
          //     detailCaption: "1300m",
          //   },
          //   {
          //     icon: "fa fa-meals",
          //     detailTitle: "Meals",
          //     detailCaption: "Breakfast & farewell dinner",
          //   },
          //   {
          //     icon: "fa fa-bed",
          //     detailTitle: "Accommodation",
          //     detailCaption: "Hotel",
          //   },
          // ],
        },
      ],
    },
  ];
  return (
    <>
      <div className="detail-itinerary container mt-5">
        <div className="row">
          <div className="col-md-12 col-lg-8">
            {days &&
              days.map((day) => (
                <>
                  {day.day1 &&
                    day.day1.map((day1) => (
                      <>
                        <h4 className="itinerary-title">{day1.title}</h4>
                        <div className="itinerary-image">
                          <img src={day1.image} alt="kathmandu" />
                          <figcaption className="text-secondary">
                            Kathmandu
                          </figcaption>
                        </div>
                        <div className="row">
                          {day1.tripDetail &&
                            day1.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-auto"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-2 itinerary-content">
                          {day1.caption}
                        </div>
                      </>
                    ))}

                  {day.day2 &&
                    day.day2.map((day2) => (
                      <>
                        <h4 className="itinerary-title">{day2.title}</h4>
                        <div className="itinerary-image">
                          <img src={day2.image} alt="kathmandu" />
                          <figcaption className="text-secondary">
                            Kathmandu, Thamel{" "}
                          </figcaption>
                        </div>
                        <div className="row">
                          {day2.tripDetail &&
                            day2.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaBus" && (
                                        <FaBus fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-auto"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-2 itinerary-content">
                          {day2.caption}
                        </div>
                      </>
                    ))}
                  {day.day3 &&
                    day.day3.map((day3) => (
                      <>
                        <h4 className="itinerary-title">{day3.title}</h4>
                        <div className="itinerary-image">
                          <img src={day3.image} alt="phakding" />
                          <figcaption className="text-secondary">
                            Phakding
                          </figcaption>
                        </div>
                        <div className="row">
                          {day3.tripDetail &&
                            day3.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-auto"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-2 itinerary-content">
                          {day3.caption}
                        </div>
                      </>
                    ))}
                  {day.day4 &&
                    day.day4.map((day4) => (
                      <>
                        <h4 className="itinerary-title">{day4.title}</h4>
                        <div className="itinerary-image">
                          <img src={day4.image} alt="namche bazaar" />
                          <figcaption className="text-secondary">
                            Namche Bazaar
                          </figcaption>
                        </div>
                        <div className="row">
                          {day4.tripDetail &&
                            day4.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-auto"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-2 itinerary-content">
                          {day4.caption}
                        </div>
                      </>
                    ))}
                  {day.day5 &&
                    day.day5.map((day5) => (
                      <>
                        <h4 className="itinerary-title">{day5.title}</h4>
                        <div className="itinerary-image">
                          <img src={day5.image} alt="namche" />
                        </div>
                        <div className="row">
                          {day5.tripDetail &&
                            day5.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-auto"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-2 itinerary-content">
                          {day5.caption}
                        </div>
                      </>
                    ))}
                  {day.day6 &&
                    day.day6.map((day6) => (
                      <>
                        <h4 className="itinerary-title">{day6.title}</h4>
                        <div className="itinerary-image">
                          <img src={day6.image} alt="dole" />
                          <figcaption className="text-secondary">
                            Dole
                          </figcaption>
                        </div>
                        <div className="row">
                          {day6.tripDetail &&
                            day6.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-auto"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-2 itinerary-content">
                          {day6.caption}
                        </div>
                      </>
                    ))}
                  {day.day7 &&
                    day.day7.map((day7) => (
                      <>
                        <h4 className="itinerary-title">{day7.title}</h4>
                        <div className="itinerary-image">
                          <img src={day7.image} alt="machhermo" />
                          <figcaption className="text-secondary">
                            Machhermo
                          </figcaption>
                        </div>
                        <div className="row">
                          {day7.tripDetail &&
                            day7.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaBus" && (
                                        <FaBus fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-auto"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-2 itinerary-content">
                          {day7.caption}
                        </div>
                      </>
                    ))}
                  {day.day8 &&
                    day.day8.map((day8) => (
                      <>
                        <h4 className="itinerary-title">{day8.title}</h4>
                        <div className="itinerary-image">
                          <img src={day8.image} alt="gokyo" />
                          <figcaption className="text-secondary">
                            Gokyo
                          </figcaption>
                        </div>
                        <div className="row">
                          {day8.tripDetail &&
                            day8.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-auto"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-2 itinerary-content">
                          {day8.caption}
                        </div>
                      </>
                    ))}
                  {day.day9 &&
                    day.day9.map((day9) => (
                      <>
                        <h4 className="itinerary-title">{day9.title}</h4>
                        <div className="itinerary-image">
                          <img src={day9.image} alt="gokyo ri" />
                          <figcaption className="text-secondary">
                            Gokyo Ri
                          </figcaption>
                        </div>
                        <div className="row">
                          {day9.tripDetail &&
                            day9.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-auto"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-2 itinerary-content">
                          {day9.caption}
                        </div>
                      </>
                    ))}
                  {day.day10 &&
                    day.day10.map((day10) => (
                      <>
                        <h4 className="itinerary-title">{day10.title}</h4>
                        <div className="itinerary-image">
                          <img src={day10.image} alt="dole" />
                          <figcaption className="text-secondary">
                            Dole
                          </figcaption>
                        </div>
                        <div className="row">
                          {day10.tripDetail &&
                            day10.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-auto"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-2 itinerary-content">
                          {day10.caption}
                        </div>
                      </>
                    ))}
                  {day.day11 &&
                    day.day11.map((day11) => (
                      <>
                        <h4 className="itinerary-title">{day11.title}</h4>
                        <div className="itinerary-image">
                          <img src={day11.image} alt="monjo" />
                          <figcaption className="text-secondary">
                            Monjo
                          </figcaption>
                        </div>
                        <div className="row">
                          {day11.tripDetail &&
                            day11.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-auto"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-2 itinerary-content">
                          {day11.caption}
                        </div>
                      </>
                    ))}
                  {day.day12 &&
                    day.day12.map((day12) => (
                      <>
                        <h4 className="itinerary-title">{day12.title}</h4>
                        <div className="itinerary-image">
                          <img src={day12.image} alt="lukla" />
                          <figcaption className="text-secondary">
                            Lukla Airport
                          </figcaption>
                        </div>
                        <div className="row">
                          {day12.tripDetail &&
                            day12.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaBus" && (
                                        <FaBus fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-auto"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-2 itinerary-content">
                          {day12.caption}
                        </div>
                      </>
                    ))}
                  {day.day13 &&
                    day.day13.map((day13) => (
                      <>
                        <h4 className="itinerary-title">{day13.title}</h4>
                        <div className="itinerary-image">
                          <img src={day13.image} alt="kathmandu airport" />
                          <figcaption className="text-secondary">
                            Kathmandu Tribhuwan International Airport (TIA)
                          </figcaption>
                        </div>
                        <div className="row">
                          {day13.tripDetail &&
                            day13.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 col-lg-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaBus" && (
                                        <FaBus fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-auto"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-2 itinerary-content">
                          {day13.caption}
                        </div>
                      </>
                    ))}
                </>
              ))}
          </div>

          <div className="main-modal-map d-none d-lg-flex">
            <div className="modal-map d-flex flex-column">
              <h4 className="title">Trekking Route Map</h4>
              <img src={Map} alt="island peak climbing with ebc trek map" />
              <button type="button" className="btn btn-dark mt-3">
                <a href={Pdf} target="_blank" className="text-light">
                  Download Itinerary
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Itinerary;

/*
day title
day caption
trip detail - ( with icon
- Accommodation
- meals
-Maximum Altitude
)
*/
