import { useInView } from "react-intersection-observer";
import Modals from "./Modals";

import hotelRoom from "../../../image/hotel.jpg";
import swayambhu from "../../../image/BuddhistPilgriamge/swayambhu.jpg";
import boudha from "../../../image/BuddhistPilgriamge/boudha.jpg";
import lumbini from "../../../image/BuddhistPilgriamge/lumbini.jpg";
import kapan from "../../../image/BuddhistPilgriamge/kapan.jpg";
import namobuddha from "../../../image/BuddhistPilgriamge/namobuddha.jpg";

const Overview = () => {
  const tripTitle = "Buddhist Pilgrimage Tour - 7 Days";
  const modaltrip = [
    {
      id: 1,
      modalhead: "Trip Overview",
      modaldetail: [
        {
          modaltitle: "Difficulty Level",
          modaltext: <strong>Easy</strong>,
        },
        {
          modaltitle: "Maximum Altitude",
          modaltext: <strong>1,750 m/5,741 ft</strong>,
        },
        {
          modaltitle: "Best Season",
          modaltext: <strong>Spring, Autumn and Winter</strong>,
        },
      ],
      trip: "Buddhist Pilgrimage Tour",
    },
    {
      id: 2,
      modalhead: "The Challenge",
      modaldetail: [
        {
          modaltitle: "Difficulty Level",
          modaltext: <strong>Easy</strong>,
        },
        {
          modaltitle: "Maximum Altitude",
          modaltext: <strong>1,750 m/5,741 ft</strong>,
        },
      ],
      trip: "Buddhist Pilgrimage Tour",
    },
    {
      id: 3,
      modalhead: "Accommodations",
      modaldetail: [
        {
          modaltitle: "Accommodation on the trail",
          modaltext: <strong>Three Star Hotel</strong>,
        },
        {
          modaltitle: "In Kathmandu",
          modaltext: <strong>Three-star hotel</strong>,
        },
        {
          modaltitle: "Style",
          modaltext: <strong>Shared</strong>,
        },
      ],
      trip: "Buddhist Pilgrimage Tour",
    },
    {
      id: 4,
      modalhead: "Nutrition",
      modaldetail: [
        {
          modaltitle: "Meals in Kathmandu & Pokhara",
          modaltext: <strong>All breakfasts & welcome/farewell dinners</strong>,
        },
        {
          modaltitle: "Meals during the trek",
          modaltext: (
            <strong>Three meals a day (breakfast, lunch & dinner)</strong>
          ),
        },
        {
          modaltitle: "Cuisine",
          modaltext: <strong>Nepali, Tibetan, Continental & Indian</strong>,
        },
      ],
      trip: "Buddhist Pilgrimage Tour",
    },
    {
      id: 5,
      modalhead: "Regeneration",
      modaldetail: [
        {
          modaltitle: "Morning stretching",
          modaltext:
            "Practice yoga & meditation by yourself (Or hire a professional yoga instructor)",
        },
        {
          modaltitle: "",
          modaltext: (
            <strong>
              Combine sound healing, spiritual massages, mandala painting class,
              thangka class, aerial yoga, etc sessions in your trip (additional
              charges apply) Unwind, rejuvenate, and find peace within you
            </strong>
          ),
        },
      ],
      trip: "Buddhist Pilgrimage Tour",
    },
    {
      id: 6,
      modalhead: "Destination",
      modaldetail: [
        {
          modaltitle: "Duration",
          modaltext: <strong>7 Days</strong>,
        },
        {
          modaltitle: "Location",
          modaltext: <strong>Kathmandu & Lumbini, Nepal</strong>,
        },
        {
          modaltitle: "Province",
          modaltext: <strong>3 & 5</strong>,
        },
        {
          modaltitle: "Population",
          modaltext: <strong>1,400,000+</strong>,
        },
        // {
        //   modaltitle: "Wildlife Species",
        //   modaltext: <strong>2,000+</strong>,
        // },
        {
          modaltitle: "Highest Points",
          modaltext: (
            <strong>Kopan monastery (917 m) & Namobuddha (1,750 m)</strong>
          ),
        },
      ],
      trip: "Buddhist Pilgrimage Tour",
    },
  ];
  let sendmodaldetails = {};

  const { ref, inView } = useInView();
  const { ref: ref2, inView: inView2 } = useInView();
  const { ref: ref3, inView: inView3 } = useInView();
  const { ref: ref4, inView: inView4 } = useInView();
  const { ref: ref5, inView: inView5 } = useInView();
  const { ref: ref6, inView: inView6 } = useInView();

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll, true);
  // }, [handleScroll]);
  if (inView6 === true) {
    sendmodaldetails = modaltrip[5];
  } else if (inView5 === true) {
    sendmodaldetails = modaltrip[4];
  } else if (inView4 === true) {
    sendmodaldetails = modaltrip[3];
  } else if (inView3 === true) {
    sendmodaldetails = modaltrip[2];
  } else if (inView2 === true) {
    sendmodaldetails = modaltrip[1];
  } else if (inView === true) {
    sendmodaldetails = modaltrip[0];
  } else {
    sendmodaldetails = modaltrip[5];
  }
  return (
    <>
      <div ref={ref} className="trip-details-next container">
        <div className="trip-fact col-12 flex-sm-wrap mb-2 mb-sm-3">
          <div className="facts">
            <div className="fact">
              <h1>7 DAYS TOUR TO THE BUDDHIST PILGRIMAGE OF NEPAL</h1>
            </div>
            <div ref={ref} className="title-content col-sm-12 col-md-7 col-lg-8 mb-3">
          You can go on a Buddhist pilgrimage tour in Nepal if you want to learn more about Buddhism or just want to see some beautiful monasteries. It is an easy tour that is good for the whole family. The tour visits busy cities, the countryside, hills, and beautiful gardens.  Therefore, you can visit museums, palaces, and shrines. You and your family will remember the trip for the rest of your lives.
        </div>
          </div>
        </div>
        <div ref={ref} className="big-title col-sm-12 col-md-7 col-lg-8 mb-3">
        A COMFORTABLE TRIP TO VARIOUS BUDDHIST PILGRIMAGE SITES IN NEPAL THAT IS FRIENDLY TO FAMILIES
        </div>
        <div ref={ref} className="title-content col-sm-12 col-md-7 col-lg-8 mb-3">
        The Buddhist pilgrimage tour is easy. So, there is no difficult or long walking. This tour is open to people of all ages. You do not have to do anything to get ready. The only thing you need is to be in good health. Therefore, you can take your whole family on the Buddhist pilgrimage tour in Nepal. You can join the tour at any time. Also, our expert staff will help you plan and book your trip. The team on the field will make sure you are safe and that the tour goes well.
        </div>

        <img
          ref={ref}
          src={boudha}
          alt="everest"
          className="col-sm-12 col-md-12 mb-3 w-100"
        />

        <div
          ref={ref2}
          className="row wrap justify-content-between col-sm-12 my-3"
        >
          <div className="col-sm-12 col-md-6">
            <div className="big-title col-sm-12 mb-3">
              A COMFORTABLE TRIP TO DIFFERENT BUDDHIST PILGRIMAGE SITES IN
              NEPAL- FAMILY FRIENDLY.
            </div>
            <div className="title-content col-sm-12 mb-3">
              The Buddhist pilgrimage tour is an easy trip. It does not involve
              any tough or long walking. People of all ages can join this tour.
              You do not need to do any physical preparation. The only
              requirement is sound health. You can do the Buddhist pilgrimage
              tour in Nepal with all the members of your family. Join the tour
              anytime you want. Our expert team will help you with booking and
              tour preparation. The on-field team will take care of your safety
              and make sure the tour goes smoothly.
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <img
              src={swayambhu}
              alt="everest"
              className="col-sm-12 mb-3 long-img"
            />
          </div>
        </div>
        <div
          ref={ref3}
          className="flex row wrap justify-content-between col-sm-12 mt-5"
        >
          <div className="col-sm-12 col-md-6">
            <img
              src={hotelRoom}
              alt="everest"
              className="col-sm-12  mb-3"
              style={{ width: "100%" }}
            />
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="big-title col-sm-12 mb-3">
            DURING THE TOUR, STAY AT A THREE-STAR HOTEL WITH SAFE AND COMFORTABLE ROOMS THAT HAVE ALL MODERN AMENITIES
            </div>
            <div className="title-content col-sm-12 mb-3">
            The people there will welcome you with open arms and a humble spirit. Also, the 7-day Buddhist pilgrimage tour includes stays in three-star hotels in Kathmandu and Lumbini. The rooms will be big and have everything you need. You can choose between a single room or any other room of your choice. The hotels will be modern and simple, with a touch of Nepalese art and furniture.
            </div>
          </div>
        </div>
        <div
          ref={ref4}
          className="row wrap justify-content-between col-sm-12 my-3"
        >
          <div className="col-sm-12 col-md-6">
            <div className="big-title col-sm-12 mb-3">
            MOUTHWATERING, FLAVORFUL NEPALI DISHES, AS WELL AS OPTIONS FROM MANY OTHER CUISINES
            </div>
            <div className="title-content col-sm-12 mb-3">
            The best part of our Buddhist pilgrimage in Nepal is the food. Therefore, you can have food that will make your heart and soul feel good. During the Buddhist pilgrimage tour, you will eat a lot of Nepalese food. Each meal will give you a chance to try something new. Our guide will tell you about the foods and help you decide which ones are the best. We will do our best to serve you flavors that are true to Nepal. 
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <img
              src={lumbini}
              alt="everest"
              className="col-sm-12 mb-3 long-img"
            />
          </div>
        </div>
        <div
          ref={ref5}
          className="flex row wrap justify-content-between col-sm-12 mt-5"
        >
          <div className="col-sm-12 col-md-6">
            <img
              src={kapan}
              alt="everest"
              className="col-sm-12  mb-3 short-img"
            />
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="big-title col-sm-12 mb-3">
              PEACE IS A JOURNEY OF A THOUSAND MILES. TAKE IT ONE STEP AT A TIME
              WITH US INTO THE ROOTS OF BUDDHISM.
            </div>
            <div className="title-content col-sm-12 mb-3">
            If there is one thing our Buddhist pilgrimage tour is about, it is peace and quiet. From Kathmandu to trips to nearby hills and Lumbini, these places will help you relax, feel better, and get your mind off of things. One minute, you will be in the middle of busy streets, and the next, you will feel a sense of peace in your heart and soul, as if by magic. The energy around the monuments, the calming smell of incense, the soft chanting in the air, and the people living and working inside make your time there unforgettable. So, a Buddhist pilgrimage tour is the best way to get away from your busy life and calm your mind.
            </div>
          </div>
        </div>
        <div
          ref={ref6}
          className="row wrap justify-content-between col-sm-12 my-3"
        >
          <div className="col-sm-12 col-md-6">
            <div className="big-title col-sm-12 mb-3">
            LUMBINI- THE BIRTHPLACE OF GAUTAM BUDDHA, THE Founder OF BUDDHISM
            </div>
            <div className="title-content col-sm-12 mb-3">
            The Buddhist temple tour takes you to some of the most important and easily accessible Buddhist pilgrimage sites in Nepal. It is a 7-day program that visits monasteries, stupas, and gardens. The tour starts in Kathmandu, where you will see the famous Monkey Temple (Swayambhunath) and Boudhanath Stupa. Then we take you to Kopan monastery and head toward Namobuddha. The next stop on this part of the tour is on the edge of Kathmandu. The second part of the Buddhist pilgrimage tour goes to Lumbini, where Gautam Buddha was born. Here, you can spend a couple of nights visiting the Maya Devi Temple, the holy pond, the Ashoka pillar, the Bodhi tree, museums, monasteries, etc.
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <img
              src={namobuddha}
              alt="everest"
              className="col-sm-12 mb-3 long-img"
            />
          </div>
        </div>
      </div>
      {/* <div className="container map"></div> */}
      <div className="main-modal">
        <Modals sendmodaldetails={sendmodaldetails} tripTitle={tripTitle} />
      </div>
    </>
  );
};

export default Overview;
