import React from "react";
import { FaCheckCircle } from "react-icons/fa";

const TripDetail = () => {
  return (
    <>
      <div className="trip-detail container mt-5">
        <div className="row">
          <div className="col-2 d-none d-lg-block d-xl-block">
            <div className="trip-sidebar">
              <ul>
                <li>
                  <a href="#price">Price Detail</a>
                </li>
                <li>
                  <a href="#highlight">Highlight</a>
                </li>
                <li>
                  <a href="#highlight">Highlight</a>
                </li>
                <li>
                  <a href="#include">Cost Include</a>
                </li>
                <li>
                  <a href="#exclude">Cost Exclude</a>
                </li>
                <li>
                  <a href="#disclaimer">Disclaimer of Itinerary</a>
                </li>
                <li>
                  <a href="#important-note">Important Note</a>
                </li>
                <li>
                  <a href="#booking">Booking</a>
                </li>
                <li>
                  <a href="#safety">Safety and Security</a>
                </li>
                <li>
                  <a href="#insurance">Travel Insurance</a>
                </li>
                <li>
                  <a href="#age">Minimum Age</a>
                </li>
                <li>
                  <a href="#time">Best Time</a>
                </li>
                <li>
                  <a href="#tipping">Tipping</a>
                </li>
                <li>
                  <a href="#activities">Activities You Can Choose</a>
                </li>
                <li>
                  <a href="#group-leader">Group Leader - What They Do</a>
                </li>
                <li>
                  <a href="#group-notes">Size of Group Notes</a>
                </li>
                <li>
                  <a href="#transport">Transport</a>
                </li>
                <li>
                  <a href="#local-flight">Local Flight</a>
                </li>
                <li>
                  <a href="#solo-traveler">Solo Travelers</a>
                </li>
                <li>
                  <a href="#joining">Joining Instructions</a>
                </li>
                <li>
                  <a href="#arrival">Arrival Complications</a>
                </li>
                <li>
                  <a href="#emergency-contact">Emergency Contanct</a>
                </li>
                <li>
                  <a href="#visa">Visa and Entry Requirement</a>
                </li>
                <li>
                  <a href="#money-exchange">Money Exchange</a>
                </li>
                <li>
                  <a href="#emergency-fund">Emergency Fund</a>
                </li>
                <li>
                  <a href="#advance-payment">Advance Payment</a>
                </li>
              </ul>
            </div>
          </div>

          <div className="trip-info col-md-12 col-lg-10">
            {/* ---------------- price starts --------------------- */}
            <h4 id="price" className="trip-detail-title">
              Price Detail
            </h4>
            Trip Price: USD $1190 pp
            <h4 className="trip-detail-title">Group Discount</h4>
            1-2 pax --------- USD $1190 pp
            <br />
            upto 5 pax ---- USD $1090 pp
            <br />
            uptp 10 pax ---- USD $990 pp
            {/* ---------------- trip highlight starts --------------------- */}
            <h4 id="highlight" className="trip-detail-title">
              Highlights
            </h4>
            <ul className="trip-highlight">
              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>
                  Thrilling flight in and out of Lukla airport with captivating
                  aerial views
                </li>
              </span>

              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>Embark on a less crowded trail in the Everest region</li>
              </span>

              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>
                  Pass by picturesque villages and rhododendron forests,
                  crossing suspension bridges & waterfalls
                </li>
              </span>

              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>
                  Explore Namche Bazaar and hike to Hotel Everest View to
                  witness stunning mountain views
                </li>
              </span>

              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>
                  Get to see the daily lifestyle of Sherpa and their rich
                  cultural heritage
                </li>
              </span>

              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>
                  Witness stunning views of Ngozumpa Glaciers, the largest
                  glacier in the Himalayas
                </li>
              </span>

              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>Explore the beautiful Gokyo valley and holy Gokyo Lakes</li>
              </span>

              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>
                  Hike to Gokyo Ri to catch glorious sunrise and mountain
                  panorama
                </li>
              </span>
            </ul>
            {/*---------------------- cost include starts --------------------- */}
            <h4 id="include" className="trip-detail-title">
              Cost Include
            </h4>
            <ul className="cost-include">
              <span>
                <div className="cost-include-icon">
                  <FaCheckCircle />
                </div>
                <li>
                  All ground transport in a private vehicle, including airport
                  transfers
                </li>
              </span>

              <span>
                <div className="cost-include-icon">
                  <FaCheckCircle />
                </div>
                <li>
                  Three nights' accommodation with breakfast at a 3-star hotel
                  in Kathmandu (as per the itinerary)
                </li>
              </span>

              <span>
                <div className="cost-include-icon">
                  <FaCheckCircle />
                </div>
                <li>
                  Three daily meals with tea/coffee (breakfast, lunch, and
                  dinner) during the trek
                </li>
              </span>

              <span>
                <div className="cost-include-icon">
                  <FaCheckCircle />
                </div>
                <li>
                  Best available twin-sharing lodge for accommodation during the
                  trek
                </li>
              </span>

              <span>
                <div className="cost-include-icon">
                  <FaCheckCircle />
                </div>
                <li>
                  One highly experienced and friendly guide with porters (1
                  porter for two people) and their expenses
                </li>
              </span>

              <span>
                <div className="cost-include-icon">
                  <FaCheckCircle />
                </div>
                <li>
                  Round trip flight Kathmandu – Lukla, including departure taxes
                </li>
              </span>

              <span>
                <div className="cost-include-icon">
                  <FaCheckCircle />
                </div>
                <li>Down jacket and sleeping bag</li>
              </span>

              <span>
                <div className="cost-include-icon">
                  <FaCheckCircle />
                </div>
                <li>Duffle bag for trekking</li>
              </span>

              <span>
                <div className="cost-include-icon">
                  <FaCheckCircle />
                </div>
                <li>Trekking map and trip achievement certificate</li>
              </span>

              <span>
                <div className="cost-include-icon">
                  <FaCheckCircle />
                </div>
                <li>First aid medicine kit</li>
              </span>

              <span>
                <div className="cost-include-icon">
                  <FaCheckCircle />
                </div>
                <li>All the necessary trekking permits</li>
              </span>

              <span>
                <div className="cost-include-icon">
                  <FaCheckCircle />
                </div>
                <li>Government taxes & office service charge</li>
              </span>
            </ul>
            {/*---------------------- cost exclude starts --------------------- */}
            <h4 id="exclude" className="trip-detail-title">
              Cost Exclude
            </h4>
            <ul className="cost-exclude">
              <span>
                <div className="cost-exclude-icon">
                  <FaCheckCircle />
                </div>
                <li>
                  Meals not specified in the 'Meal Inclusions' in the above
                  itinerary
                </li>
              </span>

              <span>
                <div className="cost-exclude-icon">
                  <FaCheckCircle />
                </div>
                <li>Travel Insurance</li>
              </span>

              <span>
                <div className="cost-exclude-icon">
                  <FaCheckCircle />
                </div>
                <li>International airfare</li>
              </span>

              <span>
                <div className="cost-exclude-icon">
                  <FaCheckCircle />
                </div>
                <li>Nepal entry visa</li>
              </span>

              <span>
                <div className="cost-exclude-icon">
                  <FaCheckCircle />
                </div>
                <li>Soft drinks, including mineral water</li>
              </span>

              <span>
                <div className="cost-exclude-icon">
                  <FaCheckCircle />
                </div>
                <li>Snacks and other personal expenses</li>
              </span>

              <span>
                <div className="cost-exclude-icon">
                  <FaCheckCircle />
                </div>
                <li>
                  Hot showers, charging, internet, etc expenses during the trek
                </li>
              </span>

              <span>
                <div className="cost-exclude-icon">
                  <FaCheckCircle />
                </div>
                <li>Personal trekking equipment</li>
              </span>

              <span>
                <div className="cost-exclude-icon">
                  <FaCheckCircle />
                </div>
                <li>Tips and gratuities for trekking staff and drivers</li>
              </span>
            </ul>
            {/*---------------------- Disclaimer of Itinerary starts --------------------- */}
            <h4 id="disclaimer" className="trip-detail-title">
              Disclaimer of Itinerary
            </h4>
            <p className="trip-detail-content">
              We'll stick to our offered Gokyo Lake trek itinerary unless not
              obstructed by unseen circumstances like bad weather, trail
              blockage, or political intervention. Because Gokyo Lake trekking
              is a remote venture, where there is no access to basic
              infrastructure, and the weather keeps on changing, we may have to
              make necessary changes in the itinerary if the situation demands.
              The group leader will make the best decision and update the city
              team. We request you follow his instructions and respect his
              decision for the safety of the group.
            </p>
            {/*---------------------- important notes starts --------------------- */}
            <h4 id="important-note" className="trip-detail-title">
              Important Notes
            </h4>
            <p className="trip-detail-content">
              Below we have mentioned a few things that you should know before
              joining the Everest base camp trek:
            </p>
            <ul>
              <li>
                Do not trek in new trekking boots. You'll have a hard time
                walking and may get blisters.
              </li>
              <li>
                Carry a reusable water bottle and water purification tablets.
                Buying bottled water every time will cost you a lot of money.
              </li>
              <li>
                Keep yourself hydrated because a dehydrated body is more prone
                to altitude sickness.
              </li>
              <li>
                Maintain your pace while trekking. You do not want to lose your
                energy in the first half of the trail. Take plenty of rest and
                eat carbohydrate-rich meals.{" "}
              </li>
              <li>
                Always walk on the cliff side while passing by mules and yaks.{" "}
              </li>
              <li>
                Pack your favorite snacks and bars to munch while trekking.
              </li>
            </ul>
            {/*---------------------- Booking starts --------------------- */}
            <h4 id="booking" className="trip-detail-title">
              Booking
            </h4>
            <p className="trip-detail-content">
              Booking a trek in an unknown country that leads to the Himalayas
              may be a daring thing to do. We understand all the concerns a
              trekker may have. Therefore, we have made sure to build a
              professional team who will assist you during your booking process
              and in the Himalayas.
              <br />
              Our booking policy and charges are transparent. You do not have to
              worry about your money with us. We have a safe payment system. You
              can book Gokyo Lake Trek 2023 anytime through our package page.
            </p>
            {/*---------------------- safety and security starts --------------------- */}
            <h4 id="safety" className="trip-detail-title">
              Safety and Security
            </h4>
            <p className="trip-detail-content">
              Trekking in Nepal is safe whether you are traveling alone or with
              your friends. Research the region you are trekking and the trail
              difficulty before you decide to embark on a solo trek. Some
              trekking routes, for example, Manaslu Circuit, Upper Mustang,
              Upper Dolpo, etc are restricted areas, so you cannot trek alone.
              For Gokyo lake trekking, you can trek solo, but as it's less
              crowded than the Everest base camp trail and the terrain is more
              challenging, we recommend Gokyo Lake trek with a guide.
              <br />
              Trekking with us, Adventure Master, will make sure you are safe
              all the time. We'll arrange all the accommodations and meals, so
              you have no problem on the trail. Likewise, our expert trekking
              crew will take care of you in the mountains. If you are wondering
              about the Covid-19 situation, then do not worry. Nepal is open to
              travelers from around the world because the number of active cases
              is minimal and well-taken care of by the officials.
              <br />
              We are always equipped with first aid during the trek. Similarly,
              our guides are well trained in providing first aid in the
              Himalayas and have many years of expertise doing so. Our first
              response to any medical emergency during the trek is to evaluate
              it and see if it needs air evacuation. Depending upon the
              situation, we either take preventive measures or airlift the
              trekker to the hospital.
              <br />
            </p>
            {/*---------------------- safety and security starts --------------------- */}
            <h4 id="insurance" className="trip-detail-title">
              Travel Insurance
            </h4>
            <p className="trip-detail-content">
              Travel insurance is needed to join the Gokyo Lake trek for safety
              purposes. The venture leads to the remote Himalayas, where
              everything is unpredictable, and things can take a turn in a
              matter of seconds. We make sure you have a safe trek with us, but
              on the offside, if any medical emergency occurs beyond the control
              of our team, we need travel insurance to give you prompt medical
              assistance.
            </p>
            {/*---------------------- minimum age starts --------------------- */}
            <h4 id="age" className="trip-detail-title">
              Minimum Age
            </h4>
            <p className="trip-detail-content">
              To trek with us, the trekker should be 18 years old or older. If
              you are below 18 years old (up to 12 years old), then you have to
              trek with an identified guardian. Likewise, if you are planning to
              trek with your toddler, then talk to our travel consultant once.
              We previously organized a family trek with kids to Gokyo Lake, but
              under certain conditions. So let us know about your planning as
              well as the health of your toddler.
            </p>
            {/*---------------------- best time starts --------------------- */}
            <h4 id="time" className="trip-detail-title">
              Best Time
            </h4>
            <p className="trip-detail-content">
              The best time of the year to do Gokyo Lake trekking is in March,
              April, October, and November. These months have the perfect
              weather conditions to trek in the Himalayas and enjoy its
              irresistible beauty. The daytime is warm and great for walking.
              The nights and mornings have a refreshing hint of chillness in it.
              <br />
              The temperature ranges from 18°C to 5°C. The temperature may drop
              furthermore at the higher elevation, so carry a few warm pieces.
              In case of bad weather, we do have a contingency day in our Gokyo
              lake trek itinerary.
              <br />
              Apart from Spring (March to April) and Autumn (October to
              November), other seasons, summer/monsoon and winter, are off time
              for this trek. Trekking in the off-season may bring additional
              hurdles like unstable weather, gloomy days, and unclear views. The
              trail gets affected by heavy rainfall and snowfall in the
              respective season.
            </p>
            {/*---------------------- tipping starts --------------------- */}
            <h4 id="tipping" className="trip-detail-title">
              Tipping
            </h4>
            <p className="trip-detail-content">
              Tipping is a crucial part of the Gokyo Lake trek in Nepal. As the
              package does not cover tips, we advise you to tip the on-field
              crew members (guides & porters) at the end of the journey. You can
              tip whatever amount you feel like. For instance, USD 5 for a tour
              guide and USD 100 for a trekking guide by each member of the group
              is the minimum number.
              <br />
              Tipping is the culture in Nepal, so you may see our team
              approaching it for you. Do not hesitate to tip your heart out. If
              you are unsatisfied with their service, then it's okay. You do not
              have to tip. If you have any complaints, do let us know. We'll do
              our best to fix them.
            </p>
            {/*---------------------- Activities You Can Choose starts --------------------- */}
            <h4 id="activities" className="trip-detail-title">
              Activities You Can Choose
            </h4>
            <p className="trip-detail-content">
              If you have a few extra days to explore more, you can{" "}
              <strong>extend the trek with the Everest base camp.</strong>{" "}
              Likewise, you can also add{" "}
              <strong>Renjo La pass or Cho La pass</strong> to the Gokyo Lake
              trek itinerary to make the trek furthermore adventurous.
              <br />
              We also offer a helicopter return from Gokyo village that allows
              the trekkers to witness a jaw-dropping aerial panorama of the
              Himalayas and also save a few days of walking back to Lukla.
              <br />
              Here are some other trekking packages that you should check out;
              <strong>
                Everest view trek, Everest base camp luxury trek, Langtang
                valley trek, EBC helicopter tour, Annapurna base camp trek, etc.
              </strong>{" "}
              You can also{" "}
              <strong>tailor-make the Gokyo Lake trekking itinerary</strong>{" "}
              with us.
            </p>
            {/*---------------------- Activities You Can Choose starts --------------------- */}
            <h4 id="group-leader" className="trip-detail-title">
              Group Leader - What They Do
            </h4>
            <p className="trip-detail-content">
              You trek with a solid team of guides and porters. Depending upon
              the group size, we also give an assistant guide. Our guides are
              well trained and have numerous years of trekking experience. They
              have led groups from all around the world to the base camp of Mt.
              Everest an uncountable number of times, so you can trek with us
              freely. <br />
              Our guides are certified with trekking licenses, remote survival,
              first-aid, etc. We handle everything on your trip to Nepal. We
              assure you a safe journey in the Himalayas with utmost
              satisfaction. We are one of the most reliable and experienced
              local trekking companies in Nepal with the best itineraries, cost
              & service.
            </p>
            {/*---------------------- Group Leader - What They Do starts --------------------- */}
            <h4 id="group-notes" className="trip-detail-title">
              Group Leader - What They Do
            </h4>
            <p className="trip-detail-content">
              Trek with the most experienced and friendly guides with us. Our
              guides have extensive knowledge about the Everest region and the
              Gokyo Lake trekking route.
              <br />
              They are the natives of the region and are well aware of
              everything. Trekking with them benefits you from trekking safely
              to getting to know the hidden stories thriving along the way.
              <br />
              Likewise, our porters are very friendly and experienced too. You
              trekking with a guide and porter is creating employment
              opportunities for them. Don't feel sorry for them. They work hard
              for it.
            </p>
            {/*---------------------- Size of Group Notes starts --------------------- */}
            <h4 id="group-notes" className="trip-detail-title">
              Size of Group Notes
            </h4>
            <p className="trip-detail-content">
              We organize treks in different group sizes. Our group size goes
              from 2 people to 12 people and more. You can trek with your loved
              ones with us or join one of the groups as a solo traveler. Joining
              our groups lets you trek with other trekkers from around the
              world. Likewise, you can also do a private trek with us.
            </p>
            {/*---------------------- transport starts --------------------- */}
            <h4 id="transport" className="trip-detail-title">
              Transport
            </h4>
            <p className="trip-detail-content">
              The Gokyo lake trekking involves a domestic flight from Kathmandu
              to Lukla airport. The flight is scheduled as per our Gokyo Lake
              trek itinerary. Likewise, we use a private vehicle to commute
              during the city tour and to/from the airport. All the
              transportation is arranged as per the itinerary. You can also book
              a four-wheeler or two-wheeler from us to explore the city on your
              own.
            </p>
            {/*---------------------- local flights starts --------------------- */}
            <h4 id="local-flight" className="trip-detail-title">
              Local flight
            </h4>
            <p className="trip-detail-content">
              The Kathmandu Lukla flight is 35 minutes long and usually departs
              in the morning. There are almost no flights from Kathmandu to
              Lukla and vice versa during the day/evening time because of
              unfavorable weather conditions. However, in the peak trekking
              seasons, there are several flights between Kathmandu and Lukla.
              The weather in peak season is much more stable, so the chances of
              delays and cancellations are very low.
              <br />
              In the off-season (winter & monsoon/summer), flights are usually
              delayed or canceled because of unstable weather. If your flight
              gets canceled or delayed, you have the option to charter a
              helicopter flight from Lukla to Kathmandu at the last minute.
              There are many regular flights to/from Lukla in the peak seasons,
              so you'll get to join group departures, which cost less per head.
              <br />
              Most of the trekkers go for a helicopter flight to/from Lukla to
              avoid overwhelming commercial flights between these two airports.
              Moreover, a helicopter flight is much more comfortable, saves
              time, and presents heavenly aerial views that only a handful of
              travelers get to see.
            </p>
            {/*---------------------- solo travelers starts --------------------- */}
            <h4 id="solo-traveler" className="trip-detail-title">
              Solor Travelers
            </h4>
            <p className="trip-detail-content">
              If you are a solo traveler and want to do the Kathmandu to Gokyo
              Lake trek alone, then make sure to be well prepared. You can book
              an independent guide and a porter for yourself from Lukla. If not,
              you can join our fixed group departure or do a private trek with
              us.
              <br />
              Traveling alone in Nepal is safe, whether you are a male or female
              traveler. However, trekking alone in the Himalayas is not safe.
              Even if you are capable of finding the route, there can be medical
              emergencies during the trek. You may need someone to understand
              your needs and help you interact with the locals.
              <br />
              Trekking alone is fun if you have already visited Nepal a few
              times before and know the trail & the natives. Otherwise, booking
              accommodation by yourself and managing every little thing might
              make the venture exhausting before you even hit the trail.
            </p>
            {/*---------------------- joining instructions starts --------------------- */}
            <h4 id="joining" className="trip-detail-title">
              Joining Instructions
            </h4>
            <p className="trip-detail-content">
              Our booking policy is quite clear and transparent. There are no
              hidden charges. We pre-inform about all the charges that the
              trekker may have to bear during the trek. Read below to know our
              booking clauses. Once you place a booking with us, you are
              agreeing to abide by all of our clauses.
              <ul>
                <li>
                  You have thoroughly read our Terms & Conditions and general
                  information provided about the package on-site
                </li>
                <li>
                  You acknowledge and accept all the risks involved in the trek
                </li>
                <li>
                  You give us consent to use your personal information to obtain
                  necessary documents related to trek and do reservations
                </li>
                <li>
                  You give us consent to use photos and videos shared by you
                  across our social media
                </li>
                <li>
                  You are honestly providing us all the information regarding
                  your medical history, allergies, or anything we know to
                  subside possible medical emergency during the trek
                </li>
                <li>
                  You are over 18 years of age and accountable for your booking
                  decision
                </li>
                <li>
                  You are responsible to pay all the charges involved in the
                  trip (mentioned by the Company)
                </li>
              </ul>
            </p>
            {/*---------------------- arrival complications starts --------------------- */}
            <h4 id="arrival" className="trip-detail-title">
              Arrival Complications
            </h4>
            <p className="trip-detail-content">
              If your flight to Kathmandu gets canceled or delayed due to any
              reason, let us know as soon as you can so that we can rearrange
              the accommodation and other things on time. You can contact us on
              our emergency number or drop us a message on our
              Whatsapp/Viber/E-mail. Likewise, if you land at the airport before
              the arrival time, then contact our representative on the given
              number. We'll pick you up immediately.
            </p>
            {/*---------------------- emergency contact starts --------------------- */}
            <h4 id="emergency-contact" className="trip-detail-title">
              Emergency Contact
            </h4>
            <p className="trip-detail-content">
              Our emergency numbers are +977 9851033195, +44 7983806200 and +977
              9849780311. We have Whatsapp & Viber on the same numbers. You can
              call our office number during office hours, weekdays, 9 am to 5 pm
              local time. <br />
              We are available 24/7 on our emergency numbers, so feel free to
              contact us if any urgent event occurs.
            </p>
            {/*---------------------- visa and entry requirements starts --------------------- */}
            <h4 id="visa" className="trip-detail-title">
              Visa and Entry Requirements
            </h4>
            <p className="trip-detail-content">
              Adventure Master is not responsible for Visa. Guests are
              accountable to acquire the visa by themselves before the journey
              begins. Nepal offers on-arrival visas at Tribhuvan International
              Airport, and other land entry points to citizens of many
              countries. It takes a few minutes to complete the process, and
              you'll get the visa right away. <br />
              Trekkers from a few countries need to apply for a Nepali visa in
              their home country (not eligible for an on-arrival visa). So,
              check your visa status for your own country before leaving for
              Nepal. If your country falls under the ineligible list, then
              obtain the visa through the Nepali embassy or consulate office in
              your country.
              <br />
              <em>
                <strong>
                  *Note: Failure in getting a visa may postpone or cancel the
                  trip with us. You may also have to bear the cancellation
                  charge.{" "}
                </strong>
              </em>
            </p>
            {/*---------------------- money exchange starts --------------------- */}
            <h4 id="money-exchange" className="trip-detail-title">
              Money Exchange
            </h4>
            <p className="trip-detail-content">
              You can exchange your money in money exchange centers in Thamel or
              in a bank. There are ATMs in every corner of Kathmandu where you
              can withdraw Nepali cash using your credit/visa card. Most of the
              ATMs also accept master cards. In hotels and restaurants, you can
              pay the bill using your card.
              <br />
              There are ATMs in Namche Bazar. You have the last chance to
              withdraw money here because the trail from here onwards has no
              ATM. The locals, along the way, do not accept cards or other
              currency. And you may need extra cash to buy services out of the
              package cost.
            </p>
            {/*---------------------- emergency fund starts --------------------- */}
            <h4 id="emergency-fund" className="trip-detail-title">
              Emergency Fund
            </h4>
            <p className="trip-detail-content">
              We always recommend our guests to have some emergency money in
              case they have to stay longer in Lukla/Kathmandu or rebook an
              international flight because of unseen situations.
            </p>
            {/*---------------------- advence payment starts --------------------- */}
            <h4 id="advance-payment" className="trip-detail-title">
              Advance payment
            </h4>
            <p className="trip-detail-content">
              To book the Gokyo Lake trek, we need 30% of the package cost in
              advance. We use this advance payment to do bookings in the hotels
              and lodges, obtain permits, and hire guides/porters for you. Once
              our team receives the payment, we'll send the trip confirmation
              mail to you.
              <br />
              You can check all the facilities included in the package cost in
              our cost include & exclude section. If you have any questions,
              feel free to ask during the time booking. If you cancel the trip a
              week or less than that before it begins, your advance payment will
              be kept as a cancellation fee.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default TripDetail;
