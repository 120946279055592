import React from "react";

import Accordion from "react-bootstrap/Accordion";
import { FaCheckCircle } from "react-icons/fa";

import CancelIcon from "@mui/icons-material/Cancel";

export default function Overview() {
  return (
    <div className="overview">
      <div className="container d-flex mt-5">
        <div className="row">
          {/* <div className="col-lg-9 col-sm-12"> */}
          <div className="col-12">
            <h4 className="overview-title">Overview</h4>
            <p className="overview-content">
              Annapurna region is in the western part of Nepal. Dhaulagiri,
              Manaslu, and Annapurna, the seventh, eighth, and tenth highest
              mountains in the world, are all in this area. The Annapurna
              Conservation Area looks out for the area. The trans-Himalayan area
              is north of the main Himalayan ranges (Annapurna and Dhaulagiri)
              and has a dry landscape and a long history of Tibetan Buddhism.
              The Annapurna region is known for its exciting hiking trails.
            </p>

            <h4 className="overview-title mt-4">Outline Itinerary</h4>
            <ul className="p-0">
              <div className="overview-outline-itinerary">
                <div className="col-auto">
                  <strong>Day 01:</strong>
                </div>
                <li> Arrival in Kathmandu and transfer to the hotel</li>
              </div>
              <div className="overview-outline-itinerary">
                <div className="col-auto">
                  <strong>Day 02:</strong>
                </div>
                <li>Drive from Kathmandu to Pokhara</li>
              </div>
              <div className="overview-outline-itinerary">
                <div className="col-auto">
                  <strong>Day 03:</strong>
                </div>
                <li>
                  Drive from Pokhara to Nayapul (1,070 m) and trek to Ulleri
                  (1,960 m/5,430 ft) via Tikhedhunga
                </li>
              </div>
              <div className="overview-outline-itinerary">
                <div className="col-auto">
                  <strong>Day 04:</strong>
                </div>
                <li> Trek from Ulleri to Ghorepani (2,860 m/9,385 ft)</li>
              </div>
              <div className="overview-outline-itinerary">
                <div className="col-auto">
                  <strong>Day 05:</strong>
                </div>
                <li>
                  Early morning hike to Poon Hill viewpoint (3,210 m/10,525 ft),
                  then trek to Tadapani (2,630 m/8,630 ft)
                </li>
              </div>
              <div className="overview-outline-itinerary">
                <div className="col-auto">
                  <strong>Day 06:</strong>
                </div>
                <li>Trek from Tadapani to Chomrong (2,165 m/7,105 ft)</li>
              </div>
              <div className="overview-outline-itinerary">
                <div className="col-auto">
                  <strong>Day 07:</strong>
                </div>
                <li> Trek from Chomrong to Himalaya (2,920 m/9,580 ft)</li>
              </div>
              <div className="overview-outline-itinerary">
                <div className="col-auto">
                  <strong>Day 08:</strong>
                </div>
                <li>
                  Trek from Himalaya to Annapurna Base Camp (4,130 m/13,550 ft)
                  via Machapuchare Base Camp
                </li>
              </div>
              <div className="overview-outline-itinerary">
                <div className="col-auto">
                  <strong>Day 09:</strong>
                </div>
                <li>
                  Trek from Annapurna Base Camp to Bamboo (2,310 m/7,578 ft)
                </li>
              </div>
              <div className="overview-outline-itinerary">
                <div className="col-auto">
                  <strong>Day 10:</strong>
                </div>
                <li>Trek from Bamboo to Jhinu Danda (1,780 m/6,393 ft)</li>
              </div>
              <div className="overview-outline-itinerary">
                <div className="col-auto">
                  <strong>Day 11:</strong>
                </div>
                <li>
                  Trek to Siwa/Ghandruk Phedi (1,150 m/3,770 ft) and drive to
                  Pokhara (820 m) via Nayapul
                </li>
              </div>
              <div className="overview-outline-itinerary">
                <div className="col-auto">
                  <strong>Day 12:</strong>
                </div>
                <li> Drive from Pokhara to Kathmandu </li>
              </div>
              <div className="overview-outline-itinerary">
                <div className="col-auto">
                  <strong>Day 13:</strong>
                </div>
                <li> Final departure</li>
              </div>
            </ul>

            <h4 className="overview-title pt-5">Trip Highlight</h4>
            <ul className="trip-highlight">
              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>
                  Explore the western Himalayas and go on a base camp trek
                </li>
              </span>

              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>
                  Annapurna Conservation Area has green foothills and thick
                  forests that you can hike through
                </li>
              </span>

              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>See different plants and animals along the way</li>
              </span>

              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>
                  See a beautiful mountain panorama with many different kinds of
                  scenery
                </li>
              </span>

              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>
                  Talk to people from different places while spending the night
                  in lovely villages
                </li>
              </span>

              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>
                  From Poon Hill, you can see a beautiful sunrise over the
                  Himalayas
                </li>
              </span>

              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>
                  Spend the night at the base camp of Annapurna, the world's
                  tenth-highest mountain
                </li>
              </span>

              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>Untangle your mind and take it easy</li>
              </span>

              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>
                  Enjoy the fresh mountain air, the sound of birds chirping, the
                  smell of the forest, the friendly people, and the rich
                  cultural history
                </li>
              </span>
            </ul>

            <div className="row d-flex justify-content-between">
              <div className="col-12">
                <h4 className="overview-title pt-5">Cost Include</h4>
                <ul className="cost-include">
                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>
                      Private car service to and from the airport.
                    </li>
                  </span>

                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>
                      KDrive to Pokhara from Kathmandu-pokhara via a luxurious tourist bus. 
                    </li>
                  </span>

                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>
                      Drive to Nayapul from Pokhara via private jeep. 
                    </li>
                  </span>

                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>
                      Drive from Pokhara to Siwae via private jeep. 
                    </li>
                  </span>

                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>
                      Hotel stays in Kathmandu and Pokhara, including breakfast (as per the itinerary)
                    </li>
                  </span>

                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>During the trek, you will be provided with three meals a day: breakfast, lunch, and dinner with one cup of tea each meal.</li>
                  </span>

                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>A highly experienced, helpful, knowledgeable, friendly, English-speaking guide with all of his salary, food, drinks, lodging, transportation, and insurance covered.</li>
                  </span>

                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>You can use a sleeping bag, a down jacket, and a duffel bag (if you don't have your own you can rent.</li>
                  </span>

                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>
                      A map of a trek and a certificate of completion of the trip.
                    </li>
                  </span>

                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>Taxes and official costs paid to the government.</li>
                  </span>

                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>Oxymeter to check your pulse, oxygen saturation, and heart rate twice a day (very helpful to check for signs of Altitude Mountain Sickness, or AMS). This will make sure that your trek is in the safest hands possible, no matter how experienced you are.</li>
                  </span>

                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>Strong, helpful porters with the right safety gear and walking gear, as well as a salary, food, a place to live, and insurance. (one porter for two people).</li>
                  </span>
                  <span>
                    <div className="cost-include-icon">
                      All papers that need to be done, Fees for entering permits you need to go while hiking.
                    </div>
                  </span>
                </ul>
              </div>
              <div className="col-12">
                <h4 className="overview-title pt-5">Cost Exclude</h4>
                <ul className="cost-exclude">
                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>
                      Food that you will eat in Kathmandu for lunch and dinner.
                    </li>
                  </span>

                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>The cost of a visa to enter Nepal is
                      <p>
                        $30 USD: 15 Days <br/>
                      $50 USD: 30 Days<br/>
                      $50 USD: 90 Days
                      </p>
                      </li>
                  </span>

                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>Your insurance for travel and medical care.</li>
                  </span>

                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>Inernational airfare.</li>
                  </span>

                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>Your personal expenses.</li>
                  </span>

                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>All of the alcoholic and non-alcoholic cold and hot drinks you buy on trek (along the way and in the Tea Houses at night). </li>
                  </span>

                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>All of the sweet desserts, like chocolate, cake, pie, and pudding.</li>
                  </span>

                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>
                      You can use a sleeping bag, a down jacket (if you don't have your own you can rent in Thamel)
                    </li>
                  </span>

                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>The tea houses have hot showers and places to charge your phone which will cost you about 2$ to 5$</li>
                  </span>

                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>Tip  to the guide, the porter, and the driver. (Tipping is expected)</li>
                  </span>
                </ul>
              </div>
            </div>

            <h4 className="overview-title pt-5">FAQ</h4>

            <div className="trip-overview-accordion">
              <Accordion defaultActiveKey="0" flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <p class="accordion-title">
                      1) Is it hard to trek to Annapurna base camp?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class="overview-content">
                      The level of difficulty for the Annapurna base camp trek
                      is marked as "moderate." It means that people who have
                      never hiked before can join this hike. The trail has a lot
                      of obstacles, and the last part of the trip is hard
                      because the elevation keeps going up. You spend several
                      days in the middle of the mountains, so you need to be
                      well-prepared.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <p class="accordion-title">
                      2) How long is the trek to Annapurna Base Camp?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class="accordion-content">
                      We have a 13-day trek to Annapurna's base camp. It's the
                      best way to get to Annapurna base camp and see beautiful
                      views and villages along the way. You can also do a short
                      Annapurna base camp trek with us. If you want to do a long
                      trek, you can extend the trip to Annapurna Circuit or
                      other parts of the Annapurna region.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    <p class="accordion-title">
                      3) How much does it cost to trek Annapurna Base Camp?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class="overview-content">
                      The Annapurna base camp trek takes 10 to 14 days and costs
                      between USD 800 and USD 1200. Annapurna base camp treks
                      can be done on a tight budget or with a lot of money. The
                      cost of the package depends on the itinerary and services.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    <p class="accordion-title">
                      4) How many kilometers is the trek to the Annapurna base
                      camp?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class="overview-content">
                      The Annapurna base camp trek is about 110 kilometers long.
                      Each day of walking takes, on average, 6 hours and covers
                      10 km.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    <p class="accordion-title">
                      5) Is Annapurna or Everest better?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class="overview-content">
                      Annapurna Base Camp Trek and Everest Base Camp Trek are
                      two of the most popular and exciting treks in the
                      Himalayas. You get to see the beautiful sights of two
                      different areas, Annapurna and Everest. Both treks are
                      worth doing, but they have different levels of difficulty
                      and elevation gains. We suggest you look at the pages for
                      our EBC and ABC packages and make your own comparisons.
                      Check to see which one will fit your budget and your
                      needs.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header>
                    <p class="accordion-title">
                      6) From the Annapurna base camp, can you see Everest?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class="overview-content">
                    No, you can not  see Mt. Everest from Annapurna base camp or anywhere on the Annapurna base camp trail. Mt. Everest is in the eastern part of Nepal, while Annapurna base camp is in the western part.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                  <Accordion.Header>
                    <p className="accordion-title">
                      7) How do you make plans for the trek to Annapurna Base Camp?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class="overview-content">
                    Planning a trek to Annapurna Base Camp could be one of your most exciting trips. So, we want you to think about the following things so that the trip is memorable and goes smoothly:
                      <ul>
                        <li>
                        First, decide if you are going to trek alone or with a Nepalese trekking company. If you are going trekking alone, make sure you go during the best times (spring and fall), get all the necessary permits, and book places to stay along the way. Always start the day early so you can get where you're going before it gets dark.
                        </li>
                        <li>
                        If you decide to trek with a local company like ours, you can get in touch with us to see if the schedule we offer works for your trip. If not, then you can change the schedule to fit your needs.
                        </li>
                        <li>
                        After deciding on the itinerary and the day you will arrive in Kathmandu, the only things left to do are pack, book your international flight to Kathmandu, and get your body ready for the trek.
                        </li>
                        <li>
                        The part about training is not that hard. You can do a daily walk or go on a day hike. Strength training and cardio will help you have more stamina.
                        </li>
                      </ul>
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                  <Accordion.Header>
                    <p class="accordion-title">
                      8) How do I get to Annapurna Base Camp from Kathmandu?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class="overview-content">
                    First, you have to get to Pokhara from Kathmandu. From Kathmandu, you can either drive there or take a plane. You can drive from Pokhara to Nayapul and then hike for a few days to get to the Annapurna base camp. You can not  drive to ABC, but you can hire a helicopter to take you there.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="8">
                  <Accordion.Header>
                    <p class="accordion-title">
                      9) How far is ABC from Pokhara?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class="overview-content">
                    From Pokhara, ABC is around 55 km away. If you are near Jhinu Danda (Chhomrong), the Annapurna base camp is 28 km.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="9">
                  <Accordion.Header>
                    <p class="accordion-title">
                      10) Why is Annapurna Base Camp popular?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class="overview-content">
                    Annapurna base camp is well-known because of the beautiful views of the mountains and the exciting journey to get there. Trekkers can spend the night at the base camp for Annapurna. You get to see the world's seventh, eighth, and tenth highest mountains.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="10">
                  <Accordion.Header>
                    <p class="accordion-title">
                      11) Do you need a guide for the Annapurna trek?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class="overview-content">
                    Yes, you do need a guide for trekking to Annapurna base camp. The Trekking Agencies Association of Nepal (TAAN) says that trekkers must have a guide for their own safety and it is compulsory. 
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="11">
                  <Accordion.Header>
                    <p class="accordion-title">
                      12) Where can you find Annapurna Base Camp?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class="overview-content">
                    Annapurna base camp is at an elevation of 4,130 meters in the western Himalayas in Nepal. It is in the Annapurna area, which is protected by the Annapurna Conservation Area.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>

          {/* sidebar price */}
          {/* <div className="col-lg-3 col-sm-12">
            <div className="overview-sidebar bg-light">
              <span className="badge text-wrap">Top Seller</span>
              <div className="">
                <span className="overview-sidebar-days">13 Days</span>
                <br />
                <span className="overview-sidebar-destination">
                  Kathmandu to Kathmandu
                </span>
                <br />
                <h4 className="price-text my-3">From USD $N/A</h4>
                <div className="group-discount mb-3">
                  <table className="group-discount-table">
                    <p>Group Discount</p>
                    <tr>
                      <td>Pax</td>
                      <td>Price</td>
                    </tr>
                    <tr>
                      <th>1 Pax</th>
                      <th>$N/A</th>
                    </tr>
                    <tr>
                      <th>2 Pax</th>
                      <th>$N/A</th>
                    </tr>
                    <tr>
                      <th>3 Pax</th>
                      <th>$N/A</th>
                    </tr>
                    <tr>
                      <th>4-6 Pax</th>
                      <th>$N/A</th>
                    </tr>
                    <tr>
                      <th>7-10 Pax</th>
                      <th>$N/A</th>
                    </tr>
                    <tr>
                      <th>11-18 Pax</th>
                      <th>$N/A</th>
                    </tr>
                    <tr>
                      <th>19-24 Pax</th>
                      <th>$N/A</th>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="">
                <HashLink smooth to="/booking" className="link">
                  <button className="book-btn">Book Now</button>
                </HashLink>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
