import { useInView } from "react-intersection-observer";
import Modals from "./Modals";

import image1 from "../../../image/Everest/ebcHeliTrek/16.jpg";
import image2 from "../../../image/Everest/ebcHeliTrek/14.jpg";
import image3 from "../../../image/Everest/ebcHeliTrek/panoromaHotelRoom.jpeg";
import image4 from "../../../image/Everest/ebcHeliTrek/11.jpg";
import image5 from "../../../image/Everest/ebcHeliTrek/21.jpg";
import image6 from "../../../image/Everest/ebcHeliTrek/40.jpg";

const Overview = () => {
  const tripTitle = "EBC Helicopter Return Trek - 9 Days";
  const tripPrice = "$1740 pp (Budget Price)";
  const modaltrip = [
    {
      id: 1,
      modalhead: "Trip Overview",
      modaldetail: [
        {
          modaltitle: "Difficulty Level",
          modaltext: <strong>Moderate to Difficult</strong>,
        },
        {
          modaltitle: "Maximum Altitude",
          modaltext: <strong>5,644 m/18,519 ft</strong>,
        },
        {
          modaltitle: "Best Season",
          modaltext: <strong>Spring & Autumn</strong>,
        },
      ],
      trip: "EBC Heli Trek",
    },
    {
      id: 2,
      modalhead: "The Challenge",
      modaldetail: [
        {
          modaltitle: "Difficulty Level",
          modaltext: <strong>Moderate to Difficult</strong>,
        },
        {
          modaltitle: "Maximum Altitude",
          modaltext: <strong>5,644 m/18,519 ft</strong>,
        },
      ],
      trip: "EBC Heli Trek",
    },
    {
      id: 3,
      modalhead: "Accommodations",
      modaldetail: [
        {
          modaltitle: "Accommodation on the trail",
          modaltext: <strong>Lodge</strong>,
        },
        {
          modaltitle: "In Kathmandu",
          modaltext: <strong>Three-star hotel</strong>,
        },
        {
          modaltitle: "Style",
          modaltext: <strong>Shared</strong>,
        },
      ],
      trip: "EBC Heli Trek",
    },
    {
      id: 4,
      modalhead: "Nutrition",
      modaldetail: [
        {
          modaltitle: "Meals in Kathmandu",
          modaltext: <strong>All breakfasts & welcome/farewell dinners</strong>,
        },
        {
          modaltitle: "Meals during the trek",
          modaltext: (
            <strong>Three meals a day (breakfast, lunch & dinner)</strong>
          ),
        },
        {
          modaltitle: "Cuisine",
          modaltext: <strong>Nepali, Tibetan, Continental & Indian</strong>,
        },
      ],
      trip: "EBC Heli Trek Trek",
    },
    {
      id: 5,
      modalhead: "Regeneration",
      modaldetail: [
        {
          modaltitle: "Morning stretching",
          modaltext:
            "Practice yoga & meditation by yourself (Or upgrade to yoga trekking, led by a professional yoga instructor)",
        },
        {
          modaltitle: "",
          modaltext: (
            <strong>Re-learn to live slow & be present in every moment</strong>
          ),
        },
      ],
      trip: "Mardi Himal Base Camp Trek",
    },
    {
      id: 6,
      modalhead: "Destination",
      modaldetail: [
        {
          modaltitle: "Duration",
          modaltext: <strong>9 Days</strong>,
        },
        {
          modaltitle: "Location",
          modaltext: <strong>Khumbu Region, Nepal</strong>,
        },
        {
          modaltitle: "Province",
          modaltext: <strong>1</strong>,
        },
        {
          modaltitle: "Population",
          modaltext: <strong>9,133+</strong>,
        },
        {
          modaltitle: "Wildlife Species",
          modaltext: <strong>1,500+</strong>,
        },
        {
          modaltitle: "Highest Points",
          modaltext: <strong>EBC (5,364 m) & Kala Patthar (5,644 m)</strong>,
        },
      ],
      trip: "EBC Heli Trek",
    },
  ];
  let sendmodaldetails = {};

  const { ref, inView } = useInView();
  const { ref: ref2, inView: inView2 } = useInView();
  const { ref: ref3, inView: inView3 } = useInView();
  const { ref: ref4, inView: inView4 } = useInView();
  const { ref: ref5, inView: inView5 } = useInView();
  const { ref: ref6, inView: inView6 } = useInView();

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll, true);
  // }, [handleScroll]);
  if (inView6 === true) {
    sendmodaldetails = modaltrip[5];
  } else if (inView5 === true) {
    sendmodaldetails = modaltrip[4];
  } else if (inView4 === true) {
    sendmodaldetails = modaltrip[3];
  } else if (inView3 === true) {
    sendmodaldetails = modaltrip[2];
  } else if (inView2 === true) {
    sendmodaldetails = modaltrip[1];
  } else if (inView === true) {
    sendmodaldetails = modaltrip[0];
  } else {
    sendmodaldetails = modaltrip[5];
  }
  return (
    <>
      <div ref={ref} className="trip-details-next container mt-5">
        <div className="trip-fact col-7 flex-sm-wrap mb-3">
          <div className="facts">
            <div className="fact mb-3">
              {/* ---------------------- Trip overview section ----------------------------- */}
              <h1>EVEREST BASE CAMP HELICOPTER RETURN TREK - 9 DAYS</h1>
            </div>
          </div>
        </div>
        <div ref={ref} className="big-title col-sm-12 col-md-7 mb-3">
          A SHORT 9 DAYS EVEREST BASE CAMP TREK PACKAGE WITH AMAZING HELICOPTER
          FIGHT BACK TO LUKLA
        </div>
        <div ref={ref} className="title-content col-sm-12 col-md-7 mb-3">
          The most popular way to trek to Everest these days is to trek to
          Everest Base Camp and then take a helicopter back. The trip is short;
          it takes only nine days. This is a good way for people who do not have
          much time to enjoy Everest trekking. So, the best thing to do is the
          Everest Base Camp Helicopter Return Trek.
        </div>

        <img
          ref={ref}
          src={image1}
          alt="everest heli trek"
          className="col-sm-12 col-md-12 mb-3 w-100"
        />

        <div
          ref={ref2}
          className="row wrap justify-content-between col-sm-12 my-3"
        >
          <div className="col-sm-12 col-md-6">
            <div className="big-title col-sm-12 mb-3">
              {/* ---------------------- Difficulty section ----------------------------- */}
              A MEDIUM TRAIL THAT IS PERFECT FOR BEGINNER TREKKERS WHO ARE READY
              TO PUSH THEMSELVES.
            </div>
            <div className="title-content col-sm-12 mb-3">
              Everest base camp trek with helicopter return difficulty is
              moderate. The itinerary is 9 days long. You spend 7 full days in
              the mountains and return to Kathmandu in a helicopter on the last
              day of the trek. The trekking route gradually ascends to Kala
              Patthar (5,644 m/18,519 ft). Once you go above Dingboche (4,410
              m/14,470 ft), the trail gets more remote and steep. The Everest
              base camp helicopter return trek is guided by expert mountain
              guides who assure the safety and success of the group.
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <img
              src={image2}
              alt="everest heli trek"
              className="col-sm-12 mb-3 img-fluid"
            />
          </div>
        </div>
        <div
          ref={ref3}
          className="flex row wrap justify-content-between col-sm-12 mt-5"
        >
          <div className="col-sm-12 col-md-6">
            <img
              src={image3}
              alt="everest heli trek"
              className="col-sm-12 img-fluid"
            />
          </div>
          <div className="col-sm-12 col-md-6">
            {/* -------------------------- Accommodation Section --------------------------- */}
            <div className="big-title col-sm-12 mb-3">
              SPEND THE NIGHT IN SIMPLE LODGES WITH TWIN SHARED ROOMS, CLEAN
              ROOMS AND BATHROOM MAINTAINED BY THE LOCALS.
            </div>
            <div className="title-content col-sm-12 mb-3">
              At the end of each day of Everest base camp trekking, you can rest
              in safe and comfortable lodges. Enjoy the kind hospitality of the
              locals and hang out with other trekkers in the cozy dining area.
              Before you go to bed, get some warm soup or hot chocolate or tea.
              Relax your sore muscles and lay down on a clean bed. There are not
              many services, but they are good enough to make your trip better.
            </div>
          </div>
        </div>
        <div
          ref={ref4}
          className="row wrap justify-content-between col-sm-12 my-3"
        >
          <div className="col-sm-12 col-md-6">
            {/* ------------------------ Meals Section ------------------------------- */}
            <div className="big-title col-sm-12 mb-3">
              ON THE TRAIL, EAT BIG BREAKFASTS AND SIMPLE NEPALI MEALS FULL OF
              MOUTHWATERING FLAVORS.
            </div>
            <div className="title-content col-sm-12 mb-3">
              There is no doubt that the infrastructure for tourists in the
              Everest area is well developed. When it comes to food, this is the
              only trekking route in the Himalayas that offers a variety of
              cuisines, including Continental, Tibetan, Indian, Chinese, and
              Nepali. Our main goal is to get our guests to try as many kinds of
              Nepalese food as they can. Nepalese food has a lot of different
              tastes that most travelers may not know about. It might take you a
              few tries to understand and enjoy it, but once you do, it is hard
              to forget.
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <img
              src={image4}
              alt="everest heli trek"
              className="col-sm-12 mb-3 img-fluid"
            />
          </div>
        </div>
        <div
          ref={ref5}
          className="flex row wrap justify-content-between col-sm-12 mt-5"
        >
          <div className="col-sm-12 col-md-6">
            <img
              src={image5}
              alt="everest heli trek"
              className="col-sm-12  mb-3 img-fluid"
            />
          </div>
          <div className="col-sm-12 col-md-6">
            {/* ----------------------- Regeneration Section ------------------------------- */}
            <div className="big-title col-sm-12 mb-3">
              ENJOY THE PEACE OF THE HIMALAYAS, LET YOUR SOUL REST, AND CALM
              DOWN.
            </div>
            <div className="title-content col-sm-12 mb-3">
              One of the less talked-about things about trekking to Everest base
              camp is how peaceful and quiet it is. Everest base trekking with a
              helicopter ride back is a great mix of adventure and calm. You get
              a rush from your adrenaline, but being in the mountains calms you
              down and makes you feel at ease. The calm people who live there,
              the fresh mountain air, the sound of birds chirping, and the smell
              of the forests will all help you feel at ease.
            </div>
          </div>
        </div>
        <div
          ref={ref6}
          className="row wrap justify-content-between col-sm-12 my-3"
        >
          <div className="col-sm-12 col-md-6">
            {/* ----------------------- Destination starts ----------------- */}
            <div className="big-title col-sm-12 mb-3">
              HIKE TO MOUNT EVEREST BASE CAMP AND KALA PATTHAR BEFORE TAKING A
              THRILLING HELICOPTER RIDE OVER THE KHUMBU GLACIER AND KHUMBU
              ICEFALL.
            </div>
            <div className="title-content col-sm-12 mb-3">
              Tenzing Norgay Sherpa and Sir Edmund Hillary were the first people
              to reach the top of Everest. On this 9-day Everest base camp trek
              with a helicopter ride back, you will follow their footsteps. You
              will finish the trek by walking through beautiful Sherpa villages
              and getting a look at how they live every day. As you walk through
              Sagarmatha National Park, be ready to see wild animals and rare
              birds. On the way, you might see yaks, mules, or goats. The trail
              is beautiful and has peaks and mountains all along it. Everest, at
              8,849 meters (29,032 feet), Lhotse, at 8,516 meters (27,940 feet),
              Nuptse, at 7,861 meters (25,791 feet), Ama Dablam, at 6,812 meters
              (22,349 feet), Thamserku, at 6,608 meters (21,680 feet), Cholatse,
              at 6,440 meters (21,130 feet), Pumori, at 7,161 meters (23 Along
              the way, you will take a side hike to Hotel Everest View and Kala
              Patthar, which have some of the best views of the mountains.
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <img
              src={image6}
              alt="everest heli trek"
              className="col-sm-12 mb-3 img-fluid"
            />
          </div>
        </div>
      </div>
      {/* <div className="container map"></div> */}
      <div className="main-modal">
        <Modals
          sendmodaldetails={sendmodaldetails}
          tripTitle={tripTitle}
          tripPrice={tripPrice}
        />
      </div>
    </>
  );
};

export default Overview;
