import React from "react";
// import { HashLink } from "react-router-hash-link";
import Accordion from "react-bootstrap/Accordion";
import { FaCheckCircle } from "react-icons/fa";
import CancelIcon from "@mui/icons-material/Cancel";

import TripFact from "./TripFact";
import Gallery from "./Gallery";

export default function Overview() {
  return (
    <div className="overview">
      <div className="container d-flex mt-5">
        <div className="overview-column">
          {/* <div className="col-lg-9 col-sm-12"> */}
          <div className="col-12">
            <TripFact />

            <Gallery />

            <h4 className="mt-5 pt-4 title">Video During EBC Heli Tour</h4>
            <iframe
              className="mb-4"
              width="100%"
              height="600px"
              src="https://www.youtube.com/embed/Z73k3INYxi8"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>

            <h4 className="overview-title mt-4">Outline Itinerary</h4>
            <ul className="p-0">
              <div className="overview-outline-itinerary">
                <div className="col-auto">
                  <strong>5:30 AM: </strong>
                </div>
                <li>Hotel pickup</li>
              </div>

              <div className="overview-outline-itinerary">
                <div className="col-auto">
                  <strong>6:30 AM: </strong>{" "}
                </div>
                <li>Take off from Kathmandu Airport (TIA)</li>
              </div>

              <div className="overview-outline-itinerary">
                <div className="col-auto">
                  <strong>7:30 AM: </strong>{" "}
                </div>
                <li>Landing in Lukla airport (Tenzing Hillary Airport)</li>
              </div>

              <div className="overview-outline-itinerary">
                <div className="col-auto">
                  <strong>8:00 AM: </strong>{" "}
                </div>
                <li>Landing on Kalapathar/EBC fly over</li>
              </div>

              <div className="overview-outline-itinerary">
                <div className="col-auto">
                  <strong>8:45 AM: </strong>{" "}
                </div>
                <li>Breakfast in Syangboche (Everest View Hotel) (1 hour)</li>
              </div>
              <div className="overview-outline-itinerary">
                <div className="col-auto">
                  <strong>10:00 AM: </strong>{" "}
                </div>
                <li>Take off from Lukla airport</li>
              </div>
              <div className="overview-outline-itinerary">
                <div className="col-auto">
                  <strong>11:00 AM: </strong>{" "}
                </div>
                <li>Arrive Kathmandu and transfer to hotel</li>
              </div>
            </ul>

            <h4 className="overview-title pt-5">Trip Highlight</h4>
            <ul className="trip-highlight">
              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>
                  A safe and comfortable helicopter flight from Kathmandu to
                  Everest base camp
                </li>
              </span>

              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>Witness sensational aerial views of the Himalayas</li>
              </span>

              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>A quick four hours day trip in the Khumbu region</li>
              </span>

              {/* <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>Reach the Himalayas in no time</li>
              </span> */}

              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>
                  Enjoy multiple landings in places like Lukla, Kala Patthar,
                  Hotel Everest View, etc
                </li>
              </span>

              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>
                  Perfect trip to Everest for travelers bound by time or
                  physical limitations
                </li>
              </span>

              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>
                  Fly over verdant valleys, dense rhododendron forests, Sherpa
                  villages, Khumbu glaciers, and Khumbu Icefall
                </li>
              </span>

              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>
                  Land at Kala Patthar to enjoy breathtaking mountain panorama
                  from the surface
                </li>
              </span>

              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>Unwind and rejuvenate your soul</li>
              </span>

              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>
                  Enjoy a delicious breakfast of your choice with a stunning
                  mountain landscape view
                </li>
              </span>
            </ul>

            <div className="row d-flex justify-content-between">
              <div className="col-12">
                <h4 className="overview-title pt-5">Package Cost Include</h4>
                <ul className="cost-include">
                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>
                      Private car service to and from the airport.
                    </li>
                  </span>

                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>
                      Private helicopter ride to Everest Base Camp (upto 5 people per ride) and flight back to Kathmandu.
                    </li>
                  </span>

                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>
                      Getting off the helicopter at the Hotel Everest View for breakfast.
                    </li>
                  </span>

                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>Service fees for the oxygen tank and first-aid kit.</li>
                  </span>

                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>The government and the office charge taxes and fees.</li>
                  </span>
                </ul>
              </div>

              <div className="col-12">
                <h4 className="overview-title pt-5">Package Cost Exclude</h4>
                <ul className="cost-exclude">
                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>
                      Breakfast, snacks, drinks, and other personal costs are not included in the price of the package.
                    </li>
                  </span>
                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>
                      It costs you about $25 per person to get into Sagarmatha National Park which is not included in the package
                    </li>
                  </span>
                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>All of the alcoholic and non alcoholic cold and hot drinks you buy during the trip.</li>
                  </span>
                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>Personal items and a down jacket.</li>
                  </span>
                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>$4/ person airport tax</li>
                  </span>
                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>
                      The local government charges $16 per person for a permit to enter Khumbu.
                    </li>
                  </span>
                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>
                      Travel insurance
                    </li>
                  </span>
                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>
                      International Airfare 
                    </li>
                  </span>
                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>Tip  to the guide, the porter, and the driver. (Tipping is expected)</li>
                  </span>
                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>All of the sweet desserts, like chocolate, cake, pie, and pudding.</li>
                  </span>
                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>
                      The cost of a visa to enter Nepal charge:
                      <p>
                        15 Days: $30 <br/>
                        30 Days: $59 <br/>
                        90 Days: $125
                      </p>
                    </li>
                  </span>
                </ul>
              </div>
            </div>

            <h4 className="overview-title pt-5">FAQ</h4>

            <div className="trip-overview-accordion">
              <Accordion defaultActiveKey="0" flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <p class="accordion-title">
                      1) Can Helicopter land at Everest base camp?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class="overview-content">
                      Yes, helicopters can land at Everest base camp. However,
                      most mountain climbers on their way to the top of Mt.
                      Everest set up tents at the base camp. So, there is not
                      enough space to land a helicopter at EBC which can make it
                      difficult to land . Therefore, the helicopter lands at
                      Kala patthar or can also be landed at the Everest base
                      camp as per your choice.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <p class="accordion-title">
                      2) Can you go to Everest by helicopter?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class="accordion-content">
                      Helicopters are the only way to get to Everest base camp
                      or a little higher, to Camp I or Camp II. Usually,
                      helicopters fly to these two places for rescue missions.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    <p class="accordion-title">
                      3) How can I book a helicopter tour in Nepal?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class="overview-content">
                      You can get in touch with us and tell us about your plan
                      to book a helicopter tour in Nepal. Our team will help you
                      book and plan a trip that will be perfect for you. You can
                      get in touch with us and tell us about your plan to book a
                      helicopter tour in Nepal. Our team will help you book and
                      plan a trip that will be perfect for you.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    <p class="accordion-title">
                      4) How cold does it get on Kalapatthar?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class="overview-content">
                      The weather at Kalapatthar is always cold. So, wear some
                      warm clothes or carry them in your backpack. In the
                      winter, the weather is very cold.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    <p class="accordion-title">
                      5) How much do I need to get by?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class="overview-content">
                      The price of a helicopter tour from Kathmandu to Everest
                      Base Camp depends on the company you fly with and the
                      services they include in the package. With us, an Everest
                      base camp helicopter tour costs USD 990 or more per
                      person. If you go with a big group, the price may be a
                      little lower.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header>
                    <p class="accordion-title">
                      6) How high does the highest point of the Everest base
                      camp tour get?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class="overview-content">
                      During the Everest base camp helicopter tour, you will get
                      as high as 5,5454 m/18,192 ft at Kala Patthar
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                  <Accordion.Header>
                    <p className="accordion-title">
                      7) How long is the landing at Kalapatthar?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class="overview-content">
                      We stop for 10 minutes at Kalapatthar. Altitude sickness
                      could happen if we stay there for more than 10 minutes,
                      which we do not want.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                  <Accordion.Header>
                    <p class="accordion-title">
                      8) If the tour is canceled, will I get my money back or
                      not?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class="overview-content">
                      Yes, if the company cancels the EBC helicopter tour, we
                      will give you your money back in full. But if the person
                      cancels the helicopter tour, they must pay a 25%
                      cancellation fee based on when they cancel.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="8">
                  <Accordion.Header>
                    <p class="accordion-title">
                      9) Is there an oxygen tank in the helicopter in case it is
                      needed?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class="overview-content">
                      Yes, we always keep a first aid kit and an extra oxygen
                      tank in the helicopter when we fly in the Himalayas.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="9">
                  <Accordion.Header>
                    <p class="accordion-title">
                      10) Why can't a helicopter land at the base camp of Mount
                      Everest?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class="overview-content">
                      At the Everest base camp, other big mountains block the
                      view of Mt. Everest. So, we land at Kala Patthar to get a
                      beautiful view of Mt. Everest and other Himalayan peaks.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
