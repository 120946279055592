import React from "react";
import Header from "./components/Header";
import ArticleCards from "./components/Article/ArticleCards";
import Article from "./components/Article/Article";

function ArticlePage() {
  return (
    <div>
      <Header title='Travel Blog and News' />
      <h1 className='title text-center my-5'>Travel Blogs and News</h1>
      <div className='container'>
        <p className='content'>
          A travel blog is a type of blog that focuses on travel experiences and
          destinations. It is a platform for travel enthusiasts to share their
          adventures, recommendations, tips, and stories about their travels
          with others. Travel blogs can feature a variety of content such as
          photos, videos, and written articles.
        </p>
        <p className='content'>
          Travel news, on the other hand, refers to updates and information
          related to the travel industry. This can include news about airlines,
          hotels, tour operators, travel restrictions, visa regulations, and
          other travel-related topics. Travel news sources can include
          newspapers, websites, magazines, and television broadcasts. Travel
          news is important for travelers as it helps them stay informed about
          the latest developments and changes in the travel industry that may
          impact their travel plans.
        </p>
        <div className='mt-5'>
          <Article />
        </div>
      </div>
    </div>
  );
}

export default ArticlePage;
