import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import CancelIcon from "@mui/icons-material/Cancel";

const TripDetail = () => {
  return (
    <>
      <div className="trip-detail container mt-5">
        <div className="row">
          <div className="col-2 d-none d-lg-block d-xl-block">
            <div className="trip-sidebar">
              <ul>
                <li>
                  <a href="#price">Price Detail</a>
                </li>
                <li>
                  <a href="#highlight">Highlight</a>
                </li>
                <li>
                  <a href="#include">Cost Include</a>
                </li>
                <li>
                  <a href="#exclude">Cost Exclude</a>
                </li>
                <li>
                  <a href="#why">Why Us?</a>
                </li>
                <li>
                  <a href="#disclaimer">Disclaimer of Itinerary</a>
                </li>
                <li>
                  <a href="#important-note">Important Notes</a>
                </li>
                <li>
                  <a href="#safety">Safety and Security</a>
                </li>
                <li>
                  <a href="#insurance">Travel Insurance</a>
                </li>
                <li>
                  <a href="#age">Minimum Age</a>
                </li>
                <li>
                  <a href="#time">Best Time</a>
                </li>
                <li>
                  <a href="#tour-duration">Tour Duration</a>
                </li>
                <li>
                  <a href="#tipping">Tipping</a>
                </li>
                <li>
                  <a href="#activities">Activities You Can Choose</a>
                </li>
                <li>
                  <a href="#group-leader">Group Leader - What They Do</a>
                </li>
                <li>
                  <a href="#group-notes">Size of Group Notes</a>
                </li>
                <li>
                  <a href="#transport">Transport</a>
                </li>
                <li>
                  <a href="#local-flight">Local Flight</a>
                </li>
                <li>
                  <a href="#solo-travellers">Solo travellerss</a>
                </li>
                <li>
                  <a href="#joining">Joining Instructions</a>
                </li>
                <li>
                  <a href="#arrival">Arrival Complications</a>
                </li>
                <li>
                  <a href="#emergency-contact">Emergency Contanct</a>
                </li>
                <li>
                  <a href="#visa">Visa and Entry Requirement</a>
                </li>
                <li>
                  <a href="#money-exchange">Money Exchange</a>
                </li>
                <li>
                  <a href="#emergency-fund">Emergency Fund</a>
                </li>
              </ul>
            </div>
          </div>

          <div className="trip-info col-md-12 col-lg-10">
            {/* ---------------- price starts --------------------- */}
            <h4 id="price" className="trip-detail-title">
              Price Detail
            </h4>
            Private: USD $3900 (upto 3 people) <br />
            Private: USD $4200 (4-5 people) <br />
            Sharing : USD $950 pp <br/>
            Luxury Sharing: USD 1290 pp
            <h4 className="trip-detail-title">
              Group discount with sharing flight
            </h4>
            1-3 pax --------- USD $1000 pp
            <br />
            3-5 pax ---- USD $950 pp
            <br />
            5-10 pax ---- USD $900 pp
            <h4 className="trip-detail-title">Payment process:</h4>
            <p className="trip-detail-content">
              You need to pay a 25% deposit up front. If you pay with a credit
              card, you might have to pay an extra 4% charge.
            </p>
            <h4 className="trip-detail-title">Cancellation policy</h4>
            <p className="trip-detail-content">
              The cancellation fees are calculated as a percentage of the total
              tour price: <br />
              1) Cancellation one week before departure: 15% of full payment
              will be deducted <br />
              2) Cancellation on the same day: 25% of full payment will be
              deducted
            </p>
            {/* ---------------- trip highlight starts --------------------- */}
            <h4 id="highlight" className="trip-detail-title">
              TRIP HIGHLIGHTS AT THE EVEREST BASE CAMP HELICOPTER ONE DAY TOUR
            </h4>
            <ul className="trip-highlight">
              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>
                  The quickest, easiest, and safest flight from Kathmandu to
                  Everest Base Camp
                </li>
              </span>

              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>Enjoy amazing views of the Himalayas from above</li>
              </span>

              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>
                  A short day trip of only four hours to the Everest base camp
                </li>
              </span>

              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>
                  Enjoy landing at places like Lukla, Kala Patthar, Hotel
                  Everest View, etc.
                </li>
              </span>

              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>
                  Best way for people who don't have much time or can't travel
                  far
                </li>
              </span>

              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>
                  Fly over green valleys, thick rhododendron forests, Sherpa
                  villages, Khumbu glaciers, and the Khumbu Icefall
                </li>
              </span>

              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>
                  Land at 5545m at Kala Patthar, the original base camp for
                  Mount Everest, to see a breathtaking view of the mountains
                </li>
              </span>

              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>Doing yoga asanas can help you relax and feel better</li>
              </span>

              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>
                  Enjoy a delicious breakfast of your choice as you look at the
                  beautiful mountain scenery
                </li>
              </span>
            </ul>
            {/*---------------------- cost include starts --------------------- */}
            <h4 id="include" className="trip-detail-title">
              Price of the package includes
            </h4>
            <ul className="cost-include">
                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>
                      Private car service to and from the airport.
                    </li>
                  </span>

                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>
                      Private helicopter ride to Everest Base Camp (upto 5 people per ride) and flight back to Kathmandu.
                    </li>
                  </span>

                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>
                      Getting off the helicopter at the Hotel Everest View for breakfast.
                    </li>
                  </span>

                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>Service fees for the oxygen tank and first-aid kit.</li>
                  </span>

                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>The government and the office charge taxes and fees.</li>
                  </span>
                </ul>
            {/*---------------------- cost exclude starts --------------------- */}
            <h4 id="exclude" className="trip-detail-title">
              The price of the package excludes
            </h4>
             <ul className="cost-exclude">
                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>
                      Breakfast, snacks, drinks, and other personal costs are not included in the price of the package.
                    </li>
                  </span>
                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>
                      It costs you about $25 per person to get into Sagarmatha National Park which is not included in the package
                    </li>
                  </span>
                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>All of the alcoholic and non alcoholic cold and hot drinks you buy during the trip.</li>
                  </span>
                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>Personal items and a down jacket.</li>
                  </span>
                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>$4/ person airport tax</li>
                  </span>
                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>
                      The local government charges $16 per person for a permit to enter Khumbu.
                    </li>
                  </span>
                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>
                      Travel insurance
                    </li>
                  </span>
                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>
                      International Airfare 
                    </li>
                  </span>
                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>Tip  to the guide, the porter, and the driver. (Tipping is expected)</li>
                  </span>
                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>All of the sweet desserts, like chocolate, cake, pie, and pudding.</li>
                  </span>
                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>
                      The cost of a visa to enter Nepal charge:
                      <p>
                        15 Days: $30 <br/>
                        30 Days: $59 <br/>
                        90 Days: $125
                      </p>
                    </li>
                  </span>
                </ul>
            <h4 id="why">
              Why should you take the helicopter tour to Everest Base Camp with
              Adventure Master?
            </h4>
            <p className="trip-detail-content">
              Why should you take the helicopter tour to Everest Base Camp with
              Adventure Master? The Adventure Master Trek is a reputable
              company. The company's best services help travelers from all over
              the world. We look out for our customers' best interests so we can
              offer the best service at the best Everest helicopter landing
              price. Our company's culture and team will do their best to give
              you a trip in the Himalayas that you will remember forever.
              <br />
              During the Everest base camp helicopter tour with landing, we will
              help you in every way. Our team will keep in touch with you and
              answer any questions you might have. We will not charge you
              anything extra. Here are some of the reasons why we are the best
              helicopter tour company in Nepal: <br />
              <ul>
                <li>A team with licenses and a lot experience</li>
                <li>Routes are planned and tested by professionals</li>
                <li>Best price</li>
                <li>Payment gateway that is safe</li>
                <li>24-7 customer service</li>
                <li>Nepal Government authorized </li>
                <li>100% Fixed Departure</li>
              </ul>
            </p>
            {/*---------------------- Disclaimer of Itinerary starts --------------------- */}
            <h4 id="disclaimer" className="trip-detail-title">
              Disclaimer of itinerary
            </h4>
            <p className="trip-detail-content">
              Our plan for our Everest base camp helicopter tour will be the
              same as what we have written, unless something unexpected or bad
              weather comes up. We will stay on schedule and stop at Kala
              Patthar for a quick look. Even in the best season, the weather at
              Kala Patthar can be hard to predict, which could affect our
              landing. If we cannot land at Kala Patthar for any reason, we hope
              you will understand and work with the pilot.
            </p>
            {/*---------------------- important notes starts --------------------- */}
            <h4 id="important-note" className="trip-detail-title">
              Important Notes
            </h4>
            <p className="trip-detail-content">
              Here are some things you need to know to make your trip safer and
              more comfortable
            </p>
            <ul>
              <li>
                Please wear clothes that will keep you warm and comfortable.
                Also, put on socks and shoes that are easy to walk in to make
                your trip easier.
              </li>
              <li>
                Follow the instructions your pilot gives you, and if anything
                goes wrong, let him know.
              </li>
              <li>
                “When your environment is clean you feel happy, motivated and
                healthy.” Please put your trash in the bins and do not throw
                trash around the mountain.
              </li>
              <li>You can carry your water bottle.</li>
              <li>
                Do not approach the helicopter from behind or when the rotor is
                turning. The pilot will show you how to get in or out of the
                helicopter.
              </li>
              <li>Do not smoke inside the helicopter.</li>
              <li>Do not misbehave and be respectful to the locals.</li>
            </ul>
            {/*---------------------- safety and security starts --------------------- */}
            <h4 id="safety" className="trip-detail-title">
              Safety and Security
            </h4>
            <p className="trip-detail-content">
              To get to the Everest base camp from Kathmandu safely, you need to
              follow some rules. You will be flying with a pilot who has
              hundreds of hours of flying experience. You will have no trouble
              communicating in English. Our team members are very friendly,
              respectful, and reliable, so feel free to tell us what you think.
              <br />
              We give you first-aid kits and extra oxygen in case there is a
              medical emergency. Before every flight, the helicopter is checked
              in the right way. We keep up with maintenance on our helicopter,
              so there is less chance of technical problems.
            </p>
            {/*---------------------- minimum age starts --------------------- */}
            <h4 id="age" className="trip-detail-title">
              Minimum Age
            </h4>
            <p className="trip-detail-content">
              There is no minimum age requirement for the Everest base camp
              helicopter tour. If you are underage and want to join the
              helicopter tour, you can fly with an identified guardian. Parents
              can take their kids on the EBC helicopter tour too. You can talk
              to our travel consultant if you have any other queries.
            </p>
            {/*---------------------- best time starts --------------------- */}
            <h4 id="time" className="trip-detail-title">
              Best Time
            </h4>
            <p className="trip-detail-content">
              The best time to explore mount Everest by helicopter is Spring and
              Autumn. These seasons are peak travel seasons in Nepal, and people
              from all around the world gather here to witness the sensational
              beauty of the Himalayas. <strong>Spring and Autumn</strong> have
              the most favorable weather conditions, so the flights are on time,
              and the views are crystal clear. If weather by chance gets
              disturbed, our helicopters can handle the changes and let you
              enjoy the trip. <br />
              You can also fly to Everest base camp in the off seasons- winter &
              summer/monsoon if the weather is clear in the morning. There are
              high chances of unstable weather and flight getting
              delayed/cancelled. The Himalayas look exceptionally stunning in
              the winter season, so the tour is worth all the hurdles. In the
              worst case, if we have to cancel the EBC helicopter tour in the
              off-season, we'll postpone it to the following day as per your
              availability.
            </p>
            <h4 id="tour-duration" className="trip-detail-title">
              Everest Base Camp Helicopter Tour Time Duration
            </h4>
            <ul>
              <li>
                The length of an EBC helicopter tour is between 4 and 5 hours
              </li>
              <li>Kathmandu to Lukla flight: 45 minutes</li>
              <li>
                At Lukla, it takes 15 to 20 minutes to refuel a helicopter
              </li>
              <li>
                Flight time from Lukla to Mt. Everest or Kalapatthar: 30 minutes
              </li>
              <li>5 to 10 minutes on the ground at Kala Patthar</li>
              <li>
                15 minutes is how long it takes to fly from Kalapatthar to Hotel
                Everest View
              </li>
              <li>45–55-minute stopover at Hotel Everest View for breakfast</li>
              <li>
                It takes 15 minutes to fly from Hotel Everest View to Lukla
              </li>
              <li>Refueling at Lukla: 10 to 15 minutes</li>
              <li>Lukla to Kathmandu flight time: 45 minutes</li>
            </ul>
            {/*---------------------- tipping starts --------------------- */}
            <h4 id="tipping" className="trip-detail-title">
              Tips are not included in the price of our helicopter tour to
              Everest Base Camp.
            </h4>
            <p className="trip-detail-content">
              You can give tips to our driver and the rest of the crew if you
              want to.
            </p>
            {/*---------------------- Activities You Can Choose starts --------------------- */}
            <h4 id="activities" className="trip-detail-title">
              Activities you can choose
            </h4>
            <p className="trip-detail-content">
              You can go on many other exciting mountain flights and helicopter
              tours with our company. You can pick whichever tour you want. Some
              of the most popular tour packages are the Gosaikunda helicopter
              tour, the Muktinath helicopter tour, the Annapurna base camp
              helicopter tour, and more. We also have trekking packages with
              helicopter returns, like the Everest Base Camp Trek with
              Helicopter Return, the Annapurna Base Camp Trek with Helicopter
              Return, etc.
            </p>
            {/*---------------------- Activities You Can Choose starts --------------------- */}
            <h4 id="group-leader" className="trip-detail-title">
              Group Leader - What They Do?
            </h4>
            <p className="trip-detail-content">
              The captain leads the journey from Kathmandu to Mount Everest Base
              Camp by helicopter. During the helicopter tour, he will tell you
              about all the mountains and interesting places. Since our pilots
              are friendly, you can feel free to ask them anything during the
              tour. During the tour, you must do what he tells you to do for
              safety and smooth flight.
            </p>
            {/*---------------------- Size of Group Notes starts --------------------- */}
            <h4 id="group-notes" className="trip-detail-title">
              Size of group note:
            </h4>
            <p className="trip-detail-content">
              We use helicopters that can carry up to 500 kg of weight on the
              Everest Base Camp helicopter tour. We can take five people at a
              time from Kathmandu to Lukla and from Lukla to Pheriche. Due to
              the high altitude and weight limit of 250 kg above 4000 m, we can
              only carry up to 3 people from Pheriche to Kalapatthar.
              <br />
              If there are more than five of you, we will split you up into
              three. So, the helicopter will only take three people from
              Pheriche to Kalapatthar at first, which takes about 5 to 6
              minutes, and then it will come back for the rest of the people.
            </p>
            {/*---------------------- transport starts --------------------- */}
            <h4 id="transport" className="trip-detail-title">
              Transport
            </h4>
            <p className="trip-detail-content">
              We use a private car to pick up and drop off people at the
              airport. Similarly, we use a private vehicle for the sightseeing
              tour (if any).
            </p>
            {/*---------------------- local flights starts --------------------- */}
            <h4 id="local-flight" className="trip-detail-title">
              Local flight
            </h4>
            <p className="trip-detail-content">
              The helicopter ride to Everest base camp is usually on time. The
              Everest base camp helicopter tour does not involve any commercial
              flights unless you take it from Lukla or anywhere else on the EBC
              trail. The helicopter ride from Kathmandu to Lukla takes 45
              minutes. Then it takes another 30 minutes to fly to Kalapatthar.
              <br />
              During busy times, we fly by helicopter to Everest base camp
              often, and most of the flights are on time. As we said before, if
              the weather is bad, there may be a small delay. We cannot promise
              a set departure time during the off-season.
            </p>
            {/*---------------------- solo travellerss starts --------------------- */}
            <h4 id="solo-travellers" className="trip-detail-title">
              Solo Travellers
            </h4>
            <p className="trip-detail-content">
              You can also join our Everest base camp tour alone. During the
              spring and fall, we have a lot of other flights by helicopter to
              the Everest base camp. You can fit in with other solo travelers
              without a problem. By flying with a group, you can save a lot of
              money on your trip and meet people from all over the world. If
              that does not make you feel better, you can also rent a private
              helicopter and fly to the Everest base camp. With a private flight
              to the base camp, you can travel the way you want. The captain
              will show you the way the whole time. If you want, you can also
              hire a guide.
            </p>
            {/*---------------------- joining instructions starts --------------------- */}
            <h4 id="joining" className="trip-detail-title">
              Joining Instructions
            </h4>
            <p className="trip-detail-content">
              After you decide if you want to go with our group or rent a
              helicopter, you can get in touch with us for more information and
              to book the trip. You can also book the helicopter tour from
              Kathmandu to Everest base camp through our website. We have a
              simple and safe way for you to pay for the trip when you book.
              <br />
              We have started taking reservations for the Everest base camp
              helicopter tour with a stop in Nepal for fall/winter 2023. You can
              also book the helicopter tour for the coming spring of 2023 or any
              other time in 2023 or 2024.
              <br />
              You can send us a request to book here, or if you have any
              questions, you can talk to our travel consultant.
            </p>
            {/*---------------------- arrival complications starts --------------------- */}
            <h4 id="arrival" className="trip-detail-title">
              Problems with arrival
            </h4>
            <p className="trip-detail-content">
              If you cannot get to Kathmandu in time for the trip, let us know
              as soon as possible. We will postpone the trip because you
              requested us to. You can call the emergency number we have given
              you and tell the team about your situation. You may also have to
              pay some cancellation fees, depending on when you tell us.
              <br />
              We will do our best to give you a satisfying alternative. Also, if
              you could not find our airport representative, you could use the
              free WIFI at the airport to call the number they gave you. you can
              call us and we will get you a taxi to the hotel.
            </p>
            {/*---------------------- emergency contact starts --------------------- */}
            <h4 id="emergency-contact" className="trip-detail-title">
              Emergency Contact
            </h4>
            <p className="trip-detail-content">
              You can call us at any time at our emergency numbers. These
              numbers can also be used for WhatsApp and Viber. These are the
              numbers to call if you need to reach us in an emergency: +977
              9851033195, +44 7983806200, and +977 9849780311. You can even call
              us during office hours at our office number. (M-F, 9 a.m.–5 p.m.
              local time)
            </p>
            {/*---------------------- visa and entry requirements starts --------------------- */}
            <h4 id="visa" className="trip-detail-title">
              Visa and Entry Requirements
            </h4>
            <p className="trip-detail-content">
              Adventure Master has nothing to do with Nepal's visa. The guest is
              the one who needs to get the visa. We can only help you get the
              visa by giving you an official letter confirming your trip. Our
              policy says that you must pay the cancellation fee if you cannot
              get a visa and have to cancel the helicopter tour.
            </p>
            <p className="trip-detail-content">
              For people from certain countries, Nepal gives visas when they
              arrive. When you arrive at one of Nepal's entry points, like the
              Tribhuvan International Airport, you can apply for a visa (TIA).
              The process is easy and does not take that long. To get the visa,
              you will need a valid passport and a few other things.
            </p>
            <p className="trip-detail-content">
              Keep in mind that this service is only for people who live in
              certain countries. Check to see if you need a visa to go to Nepal
              before you leave. If your country is not on this list, you can
              apply for a visa at the Nepalese Embassy or consulate in your home
              country.
            </p>
            {/*---------------------- money exchange starts --------------------- */}
            <h4 id="money-exchange" className="trip-detail-title">
              Money Exchange
            </h4>
            <p className="trip-detail-content">
              You must use Nepali money in Nepal. There are many banks and
              places to exchange money in Kathmandu where you can get a good
              rate. You can use your international cards at most ATMs in
              Kathmandu to get cash.
              <br />
              Most hotels, restaurants, cafes, shops, entertainment centers, and
              other places in Kathmandu accept Visa, Mastercard, and other
              credit cards. You will not be able to get cash out during the
              helicopter tour of Everest Base Camp, so get it in Kathmandu.
            </p>
            {/*---------------------- emergency fund starts --------------------- */}
            <h4 id="emergency-fund" className="trip-detail-title">
              Emergency Fund
            </h4>
            <p className="trip-detail-content">
              Set aside some money in case you need to stay longer in Nepal, pay
              for something you did not expect to need, or spend more than you
              planned on shopping. This emergency fund will hold you back and
              give you the freedom to travel without worrying.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default TripDetail;
