import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import santosh from "../../image/team/santosh_adm.jpg";
import { FaWhatsapp, FaEnvelope } from "react-icons/fa";

function Santosh() {
  return (
    <>
      <Header />
      <div className="container mt-5">
        <div className="row team-profile">
          <div className="col-sm-12 col-md-6 col-lg-4">
            <img src={santosh} alt="santosh" />
            <br />
            <h1 className="title mt-2">Khom Raj Adhikari (Santosh)</h1>
            <a
              href="https://api.whatsapp.com/send/?phone=9779851033195&text&type=phone_number&app_absent=0"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <FaWhatsapp className="link-icon mx-1" /> +977 985-1033195
            </a>
            <br />
            <a
              href="mailto:info@adventuremaster.com"
              target="_blank"
              rel="noreferrer"
            >
              <FaEnvelope className="link-icon mx-1" />
              info@adventuremaster.com
            </a>
          </div>
          <div className="col-md-12 col-lg-8">
            <p className="content">
              <strong>
                He is Khom Raj Adhikari (Santosh) - CEO of Adventure Master!
              </strong>
              <br />
              Because of his love for the mountains and the wonderful Himalayas,
              Khom Raj Adhikari, whom you may also know as Santosh, joined the
              trekking industry two decades ago as a guide/porter. He is one
              hardworking individual, and his love for the land of mountains,
              Nepal, his home, kept him motivated to move forward with a vision.
              The vision to show everyone what Nepal is all about, to show
              everyone that a country like Nepal is a perfect blend of nature,
              culture & peace.
              <br />
              With that in mind, in 2003, Santosh founded Adventure Master, a
              travel agency that not only focuses on trekking and adventurous
              activities like river rafting, rock climbing, bungee jumping, and
              paragliding but also covers tours, excursions, pilgrimage tours,
              climbing, and helicopter tours in mountains.
              <br />
              Having mastered the art of organizing trips, Santosh knows how to
              get the best out of a trip. This looks clear in the reviews of
              travelers who choose Adventure Master as their traveling
              companion. His hard work and dedication towards traveling have
              made thousands of customers delighted, and he has no intention to
              stop.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Santosh;
