import ebcHeliTrek from "../../../../image/Everest/EBC-Heli/everest-heli-tour.jpeg";
import ebcHeliTour from "../../../../image/Everest/EBC-Heli/everestHeliTour.jpg";
import ebcTrek from "../../../../image/Everest/EBCTrek/8.jpg";
import gokyolake from "../../../../image/Everest/gokyolake1.jpg";
import islandPeakClimbing from "../../../../image/IslandPeakClimbing/islandPeakClimbing.jpeg";
import {
  FaStar,
  FaCalendarAlt,
  FaMapMarkerAlt,
  FaClock
} from "react-icons/fa";

// const TripCard = () => {
export const cardInfo = [
  {
    image: ebcHeliTour,
    reviewIcon1: <FaStar />,
    reviewIcon2: <FaStar />,
    reviewIcon3: <FaStar />,
    reviewIcon4: <FaStar />,
    reviewIcon5: <FaStar />,
    // review: "23 Reviews",
    countryIcon: <FaMapMarkerAlt />,
    country: "Nepal",
    durationIcon: <FaClock />,
    duration: "4-6 hours",
    title: "Everest Base Camp Helicopter Tour",
    price: "USD $1050 pp",
    btn: "View Trip",
    link: "./everest-base-camp-helicopter-tour/info",
  },
  {
    image: ebcTrek,
    reviewIcon1: <FaStar />,
    reviewIcon2: <FaStar />,
    reviewIcon3: <FaStar />,
    reviewIcon4: <FaStar />,
    reviewIcon5: <FaStar />,
    // review: "19 Reviews",
    countryIcon: <FaMapMarkerAlt />,
    country: "Nepal",
    durationIcon: <FaCalendarAlt />,
    duration: "14 Days",
    title: "Everest Base Camp Trek",
    price: "USD $1090 pp",
    btn: "View Trip",
    link: "./everest-base-camp-trek",
  },
  {
    image: ebcHeliTrek,
    reviewIcon1: <FaStar />,
    reviewIcon2: <FaStar />,
    reviewIcon3: <FaStar />,
    reviewIcon4: <FaStar />,
    reviewIcon5: <FaStar />,
    // review: "19 Reviews",
    countryIcon: <FaMapMarkerAlt />,
    country: "Nepal",
    durationIcon: <FaCalendarAlt />,
    duration: "9 Days",
    title: "Everest Base Camp Helicopter Return Trek",
    price: "USD $2150 pp",
    btn: "View Trip",
    link: "./everest-base-camp-helicopter-return-trek-9-days",
  },
  {
    image: gokyolake,
    reviewIcon1: <FaStar />,
    reviewIcon2: <FaStar />,
    reviewIcon3: <FaStar />,
    reviewIcon4: <FaStar />,
    reviewIcon5: <FaStar />,
    // review: "",
    countryIcon: <FaMapMarkerAlt />,
    country: "Nepal",
    durationIcon: <FaCalendarAlt />,
    duration: "13 Days",
    title: "Gokyo Lake Trek",
    price: "USD $1190 pp",
    btn: "View Trip",
    link: "./gokyo-lake-trek-13-days",
  },
  {
    image: islandPeakClimbing,
    reviewIcon1: <FaStar />,
    reviewIcon2: <FaStar />,
    reviewIcon3: <FaStar />,
    reviewIcon4: <FaStar />,
    reviewIcon5: <FaStar />,
    // review: "",
    countryIcon: <FaMapMarkerAlt />,
    country: "Nepal",
    durationIcon: <FaCalendarAlt />,
    duration: "19 Days",
    title: "Island Peak Climbing With EBC Trek",
    price: "USD $2700 pp",
    btn: "View Trip",
    link: "./island-peak-climbing-with-ebc-19-days",
  },
];
// }
