import React from "react";
import { Col, Row } from "react-bootstrap";
import { FaMountain, FaUtensils, FaBed, FaHiking } from "react-icons/fa";
import { FaBus } from "react-icons/fa";
import { FaClock } from "react-icons/fa";

import "../../../custom-sass/pages/trip/_trip-detail.scss";
import Map from "../../../image/Map/meraPeakClimbingMap.jpg";
import Pdf from "../../../itineraryDocuments/EverestRegion/MeraPeakClimbing.pdf";

import day1 from "../../../image/kathmandu.jpeg";
import day2 from "../../../image/boudha.jpg";
import day3 from "../../../image/Everest/EBCTrek/1.jpg";
import day4 from "../../../image/MeraPeakClimbing/pangom.jpg";
import day7 from "../../../image/MeraPeakClimbing/kothe.jpg";
import day8 from "../../../image/MeraPeakClimbing/thaknak.jpg";
import day9 from "../../../image/MeraPeakClimbing/khare.jpg";
import day11 from "../../../image/MeraPeakClimbing/meraHighCamp.jpg";
import day15 from "../../../image/MeraPeakClimbing/thuliKharka.jpg";
import day16 from "../../../image/Everest/EBCTrek/11.jpg";
import day18 from "../../../image/kathmanduAirport1.jpg";

const Itinerary = () => {
  const days = [
    {
      day1: [
        {
          title: "Day 1: Arrival in Kathmandu",
          image: day1,
          caption:
            "You will be entering Nepal's airspace overlooking the mesmerizing Himalayan belt. Once you land at the Tribhuvan International Airport in Kathmandu, do the visa work and meet our representatives at the terminal gate. They'll be holding your name card and our company's banner, so you will not have a hard time finding them. They will transfer you to the hotel. Rest and unpack. We meet you in the late evening to have a welcome dinner. ",
          tripDetail: [
            // {
            //   icon: "FaMountain",
            //   detailTitle: "Maximum Altitude",
            //   detailCaption: "1400m",
            // },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "Null",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Hotel",
            },
          ],
        },
      ],
      day2: [
        {
          title: "Day 2:  Kathmandu: sightseeing & trip preparation",
          image: day2,
          caption:
            "We have a day full of activities ahead of us today. We’ll spend the morning visiting some monuments like Monkey temple, Boudhanath Stupa, Pashupatinath Temple, and Kathmandu Durbar Square. A professional tour guide will be with you during the excursion. You will get to know a lot about Nepal's culture, tradition, architecture, and art on the tour.",
          caption1:
            "In the later afternoon, we meet you in the Adventure Master's office to prepare and pack for the journey ahead of us. Meet with the mountain guide and pack your duffle bags. He will give a briefing about the trip. If any shopping is needed, we'll do it in the evening.",
          tripDetail: [
            // {
            //   icon: "FaBus",
            //   detailTitle: "Distance",
            //   detailCaption: "160 km/99.4 miles",
            // },
            // {
            //   icon: "FaClock",
            //   detailTitle: "Trek Duration",
            //   detailCaption: "6/8 horus",
            // },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Hotel",
            },
            // {
            //   icon: "FaMountain",
            //   detailTitle: "Maximum Altitude",
            //   detailCaption: "730m",
            // },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "Breakfast only",
            },
          ],
        },
      ],
      day3: [
        {
          title:
            "Day 3: Fly to Lukla (2,846 m/9,337 ft), trek to Paiya (Chutok) (2,800 m/9,187 ft)",
          image: day3,
          caption:
            "Our Mera peak climbing begins from today onwards. To reach the starting point of the trail, we will take an early morning flight to Lukla airport. The flight will be short, and the aerial views of the Himalayas make it fantastic. At Lukla, porters join the team, and we'll begin trekking toward Paiya.",
          caption1:
            "Instead of following the mainstream trail that leads to Namche Bazaar, we will be trekking on an opposite route to Mera Peak. The trail goes through lush forests, crossing Handi Kola on the way. We'll reach Surke village and continue trekking across Chutok La pass. The next stop after this is Paiya, where we will be spending the night.",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Trek Distance",
              detailCaption: "6.2 km/3.8 miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "35 minutes flight & 5-6 hours trek",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Lodge",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "Breakfast, lunch & dinner",
            },
          ],
        },
      ],
      day4: [
        {
          title: "Day 4: Paiya to Panggom (2,846 m/9,338 ft)",
          image: day4,
          caption:
            "Leaving Paiya, our next stop is Panggom. We trek on a downhill trail that leads to a small bridge. Crossing the bridge, walk on a slippery path. Gradually, we will ascend to Kari La pass and walk through dense forests of bamboo and rhododendron. After coming out of the woods, we will be trekking enjoying gorgeous mountain sceneries and beautiful views of Dudh Koshi valley. Panggom is a small settlement.",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Trek Distance",
              detailCaption: "km/ miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "5-6 hours",
            },
            // {
            //   icon: "FaMountain",
            //   detailTitle: "Maximum Altitude",
            //   detailCaption: "890m",
            // },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Lodge",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "Breakfast, lunch & dinner",
            },
          ],
        },
      ],
      day5: [
        {
          title: "Day 5:  Panggom to Ningsow (2,863 m/9,394 ft)",
          caption:
            "After trekking for some time, we’ll ascend to Panggom La pass. The trail from here continues to ascend and turns north. Further on the way, we'll cross Peseng Kharka river and follow an uphill trail to Peseng Kharka Danda. From here, we walk to Ningsow river and cross it to enter Ningsow village. While trekking, we get to see excellent views of snow-capped mountains.",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Trek Distance",
              detailCaption: "km/miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "5-6 horus",
            },
            // {
            //   icon: "FaMountain",
            //   detailTitle: "Maximum Altitude",
            //   detailCaption: "1804m",
            // },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "Breakfast, lunch & dinner",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Lodge",
            },
          ],
        },
      ],
      day6: [
        {
          title: "Day 6: Ningsow to Chhatra Khola (2,800 m/9,187 ft)",
          caption:
            "We begin the day with a delicious breakfast and serene surroundings. The trail from Ningsow ascends for a while and drops down for half an hour or so. We then walk uphill to Ramailo Danda, which offers a stunning view of Mera Peak. The trail further ascends and descends, and we eventually enter Makalu Barun National Park. Following the Pasang Lhamu trail, we reach Chhatra Khola. If we are lucky, we may come across red pandas in the bamboo trees.",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Trek Distance",
              detailCaption: "km/miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "7-8 horus",
            },
            // {
            //   icon: "FaMountain",
            //   detailTitle: "Maximum Altitude",
            //   detailCaption: "1804m",
            // },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "Breakfast, lunch & dinner",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Lodge",
            },
          ],
        },
      ],
      day7: [
        {
          title: "Day 07: Chhatra Khola to Kothe (3,691 m/12,110 ft)",
          image: day7,
          caption:
            "The trail follows the northern route that joins the main path to Mera Peak. We walk alongside the Majang river and continue trekking, following the trail that goes down to Hinku Khola. We'll rarely come across other trekkers on the way. From Hinku Khola, we walk to Tashing Ongma. It is a small settlement with a few seasonal tea houses. The trail then continues to Sanu Khola. Crossing the khola, we reach Kothe.",
          tripDetail: [
            // {
            //   icon: "FaHiking",
            //   detailTitle: "Trek Distance",
            //   detailCaption: "km/miles",
            // },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "6-7 horus",
            },
            // {
            //   icon: "FaMountain",
            //   detailTitle: "Maximum Altitude",
            //   detailCaption: "3500m",
            // },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "Breakfast, lunch & dinner",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Lodge",
            },
          ],
        },
      ],
      day8: [
        {
          title: "Day 8: Kothe to Thaknak (4,358 m/14,299 ft)",
          image: day8,
          caption:
            "We follow the riverbed of the Hinku river that will lead us to Gondishung. Taking a lunch break here, we'll pay a visit to an ancient monastery called Lungsumgba Gompa. Locals believe praying in this gompa makes Mera peak climbing successful. The trail then gradually ascends to Thangnak, a summer pasture land with some basic tea houses to cater to travelers.",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Duration",
              detailCaption: "km/miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "3-4 hours",
            },
            // {
            //   icon: "FaMountain",
            //   detailTitle: "Maximum Altitude",
            //   detailCaption: "4,800 m/15,748 ft",
            // },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "Breakfast, lunch & dinner",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Lodge",
            },
          ],
        },
      ],
      day9: [
        {
          title: "Day 9: Thaknak to Khare (5,045 m/16,553 ft)",
          image: day9,
          caption:
            "We have a bit of a difficult route ahead of us on this day. From Khare, we walk through the steep moraines of Dig Glacier to Dig Kharka. At the end of the morning, we will find ourselves in front of Charpate Himal. We will be walking near the nose of Hinku Nap and Shar glaciers. We have to be extra careful today. Further, we will steeply ascend to Khare, where the trek ends for the day.",
          tripDetail: [
            // {
            //   icon: "FaHiking",
            //   detailTitle: "Duration",
            //   detailCaption: "km/miles",
            // },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "2-3 hours",
            },
            // {
            //   icon: "FaMountain",
            //   detailTitle: "Maximum Altitude",
            //   detailCaption: "3875",
            // },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "Breakfast, lunch & dinner",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Lodge",
            },
          ],
        },
      ],
      day10: [
        {
          title: "Day 10: Khare: Acclimatization and pre-climb training",
          caption:
            "We take an acclimatization day in Khare. It will help us avoid altitude sickness. Even though Mera peak is not technical, we have to be acclimatized to reach the summit and return safely. Today, the mountain guide will also give you pre-climbing training to boost your confidence and brief climbing strategy.",
          tripDetail: [
            // {
            //   icon: "FaHiking",
            //   detailTitle: "Duration",
            //   detailCaption: "km/miles",
            // },
            // {
            //   icon: "FaClock",
            //   detailTitle: "Trek Duration",
            //   detailCaption: "3/4 horus",
            // },
            // {
            //   icon: "FaMountain",
            //   detailTitle: "Maximum Altitude",
            //   detailCaption: "3875m",
            // },
            // {
            //   icon: "fa fa-meals",
            //   detailTitle: "Meals",
            //   detailCaption: "Breakfast, lunch & dinner",
            // },
            // {
            //   icon: "fa fa-bed",
            //   detailTitle: "Accommodation",
            //   detailCaption: "Teahouse",
            // },
          ],
        },
      ],
      day11: [
        {
          title: "Day 11: Khare to Mera High Camp (5,780 m/18,964 ft)",
          image: day11,
          caption:
            "We will be ascending to Mera High Camp on this day. On this part of the route, we will be crossing the Mera Glacier. The trail to the high camp further involves walking up to Mera La pass. It is steep climbing through snowy and rocky tracks. The trail from Mera La further steeply stretches all the way to Mera High Camp.",
          caption1:
            "We spend the remaining day at the high camp, recovering and enjoying the magical panorama of the mountains like Everest, Baruntse, Makalu, Chamlang, Nuptse, and many others. The crew will set up the tents and prepare meals.",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Duration",
              detailCaption: "km/miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "5-6 hours",
            },
            // {
            //   icon: "FaMountain",
            //   detailTitle: "Maximum Altitude",
            //   detailCaption: "4480m",
            // },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "Breakfast, lunch & dinner",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Camp",
            },
          ],
        },
      ],
      day12: [
        {
          title:
            "Day 12:  Mera High Camp to summit and back to Khare (6,476 m/21,247 ft)",
          caption:
            "It is a big day for us because we will be summiting Mera Peak. Following the best summit window, we'll leave the Mera High Camp and begin ascending towards the summit. For your information, we will wake up at 2 AM in order to reach the summit before noon and safely return to base camp. After 12 PM, it is impossible to move because of strong winds.",
          caption1:
            "Following the footsteps of your guide, you will climb. There are no technical sections involved. However, the high altitude and the hostile environment make it very difficult to climb. Once we reach Mera peak summit, spectacular views of the Himalayan mountains will come into our perspective. After summiting, we will descend to Khare to spend the night.",
          tripDetail: [
            // {
            //   icon: "FaHiking",
            //   detailTitle: "Duration",
            //   detailCaption: "24.7 km/15.3 miles",
            // },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "9-10 horus",
            },
            // {
            //   icon: "FaMountain",
            //   detailTitle: "Maximum Altitude",
            //   detailCaption: "3720m",
            // },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "Breakfast, lunch & dinner",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Camp",
            },
          ],
        },
      ],
      day13: [
        {
          title: "Day 13: Reserve Day for Contingency (5,045m/16,553ft)",
          caption:
            "It is an extra day. We use it if we do not get a proper summit window or see any delays in the Lukla flight. If not used in the mountains, you will have one full day in Kathmandu to enjoy.",
          tripDetail: [
            // {
            //   icon: "FaHiking",
            //   detailTitle: "Duration",
            //   detailCaption: "26 km",
            // },
            // {
            //   icon: "FaClock",
            //   detailTitle: "Trek Duration",
            //   detailCaption: "5/6 horus",
            // },
            // {
            //   icon: "FaMountain",
            //   detailTitle: "Maximum Altitude",
            //   detailCaption: "1700m",
            // },
            // {
            //   icon: "fa fa-meals",
            //   detailTitle: "Meals",
            //   detailCaption: "Breakfast, lunch & dinner",
            // },
            // {
            //   icon: "fa fa-bed",
            //   detailTitle: "Accommodation",
            //   detailCaption: "Teahouse",
            // },
          ],
        },
      ],
      day14: [
        {
          title: "Day 14: Khare to Kothe (3,691 m/12,110 ft)",
          caption:
            "From today onwards, our journey back to Lukla begins. On this day, we descend from Khare to Kothe. Following a downhill trail, we retrace the path back to Thaknak. From here, we walk through forests of rhododendron, pine, and oak to Kothe. We will come across lots of vegetation and probably birds on the way. From Thaknak, Kothe is located 3 to 4 hours away.",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Duration",
              detailCaption: "km/miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "4-5 horus",
            },
            // {
            //   icon: "FaMountain",
            //   detailTitle: "Maximum Altitude",
            //   detailCaption: "1300m",
            // },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "Breakfast, lunch & dinner",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Lodge",
            },
          ],
        },
      ],
      day15: [
        {
          title: "Day 15: Kothe to Thuli Kharka (4,300 m/14,108 ft)",
          image: day15,
          caption:
            "After having a big breakfast, we'll leave for Thuli Kharka. We have more uphill walks today than descents. The trail goes through a lush forested trail to the western side of the Hinku valley. Thuli Kharka is located near the Hinku valley. On the way, we will see a superb mountain panorama. It will be a lovely walk to Thuli Kharka from Kothe.",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Duration",
              detailCaption: "km/miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "5-6 horus",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "Breakfast, lunch & dinner",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Lodge",
            },
          ],
        },
      ],
      day16: [
        {
          title:
            "Day 16: Thuli Kharka to Lukla (2,846 m/9,337 ft) via Zatrwa La pass (4,600 m/15,093 ft)",
          image: day16,
          caption:
            "Today is the last day of our trek. Leaving Thuli Kharka, we ascend to Zatrawala pass. The trail is steep and rocky. We get to witness fantastic mountain panoramas on the way and from the top of the pass. Further, we descend and continue trekking to Lukla. On the way, we will walk through lush forests. We will celebrate our successful scent in Lukla.",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Duration",
              detailCaption: "km/miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "6-7 horus",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "Breakfast, lunch & dinner",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Lodge",
            },
          ],
        },
      ],
      day17: [
        {
          title: "Day 17: Fly to Kathmandu (1,440m/4,724 ft)",
          caption:
            "Here comes another thrilling flight from Lukla to Kathmandu today. We'll leave Lukla early in the morning and enjoy the flight back to Kathmandu. The guide will drop you at the hotel in Thamel. You have the rest of the day free, so explore the city, do shopping, and rest. We meet you in the late evening for a farewell dinner. End the day strolling on the bustling streets of Thamel.",
          tripDetail: [
            // {
            //   icon: "FaBus",
            //   detailTitle: "Duration",
            //   detailCaption: "km/miles",
            // },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "35 minutes",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "Breakfast & farewell dinner",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Hotel",
            },
          ],
        },
      ],
      day18: [
        {
          title: "Day 18:  Final departure",
          image: day18,
          caption:
            "Our Mera peak climbing comes to an end. You will be flying to our next destination today. Our airport team will drop you at the international airport terminal gate as per your flight departure time.",
        },
      ],
    },
  ];
  return (
    <>
      <div className="detail-itinerary container mt-5">
        <div className="row">
          <div className="d-lg-none mb-5">
            <div className="">
              <h4 className="title">Route Map</h4>
              <img
                src={Map}
                alt="Everest Heli Tour Map"
                style={{ width: "100%" }}
              />
              <button type="button" className="btn btn-dark mt-3 col-12">
                <a href={Pdf} target="_blank" className="text-light">
                  Download Itinerary
                </a>
              </button>
            </div>
          </div>
          <div className="col-md-12 col-lg-8">
            {days &&
              days.map((day) => (
                <>
                  {day.day1 &&
                    day.day1.map((day1) => (
                      <>
                        <h4 className="itinerary-title">{day1.title}</h4>
                        <div className="itinerary-image">
                          <img src={day1.image} alt="kathmandu" />
                          <figcaption className="text-secondary">
                            Kathmandu (Capital city of Nepal)
                          </figcaption>
                        </div>
                        <div className="row">
                          {day1.tripDetail &&
                            day1.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col p-0"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>

                        <div className="pb-5 itinerary-content">
                          {day1.caption}
                        </div>
                      </>
                    ))}

                  {day.day2 &&
                    day.day2.map((day2) => (
                      <>
                        <h4 className="itinerary-title">{day2.title}</h4>
                        <div className="itinerary-image">
                          <img src={day2.image} alt="kathmandu" />
                          <figcaption className="text-secondary">
                            Kathmandu: Boudha Stupa
                          </figcaption>
                        </div>
                        <div className="row">
                          {day2.tripDetail &&
                            day2.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaBus" && (
                                        <FaBus fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col p-0"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>

                        <div className="pb-5 itinerary-content">
                          {day2.caption}
                          <br />
                          {day2.caption1}
                        </div>
                      </>
                    ))}
                  {day.day3 &&
                    day.day3.map((day3) => (
                      <>
                        <h4 className="itinerary-title">{day3.title}</h4>
                        <div className="itinerary-image">
                          <img src={day3.image} alt="lukla" />
                          <figcaption className="text-secondary">
                            Lukla airport
                          </figcaption>
                        </div>
                        <div className="row">
                          {day3.tripDetail &&
                            day3.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col p-0"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day3.caption}
                          <br />
                          {day3.caption1}
                        </div>
                      </>
                    ))}
                  {day.day4 &&
                    day.day4.map((day4) => (
                      <>
                        <h4 className="itinerary-title">{day4.title}</h4>
                        <div className="itinerary-image">
                          <img src={day4.image} alt="panggom" />
                          <figcaption className="text-secondary">
                            Panggom
                          </figcaption>
                        </div>
                        <div className="row">
                          {day4.tripDetail &&
                            day4.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col p-0"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day4.caption}
                        </div>
                      </>
                    ))}
                  {day.day5 &&
                    day.day5.map((day5) => (
                      <>
                        <h4 className="itinerary-title">{day5.title}</h4>
                        {/* <div className="itinerary-image">
                          <img src={day5.image} alt="ningsow" />
                          <figcaption className="text-secondary">
                            Ningsow
                          </figcaption>
                        </div> */}
                        <div className="row">
                          {day5.tripDetail &&
                            day5.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col p-0"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day5.caption}
                        </div>
                      </>
                    ))}
                  {day.day6 &&
                    day.day6.map((day6) => (
                      <>
                        <h4 className="itinerary-title">{day6.title}</h4>
                        {/* <div className="itinerary-image">
                          <img src={day6.image} alt="chhatra khola" />
                          <figcaption className="text-secondary">
                            Chhatra Khola
                          </figcaption>
                        </div> */}
                        <div className="row">
                          {day6.tripDetail &&
                            day6.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col p-0"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day6.caption}
                        </div>
                      </>
                    ))}
                  {day.day7 &&
                    day.day7.map((day7) => (
                      <>
                        <h4 className="itinerary-title">{day7.title}</h4>
                        <div className="itinerary-image">
                          <img src={day7.image} alt="khote" />
                          <figcaption className="text-secondary">
                            Khote
                          </figcaption>
                        </div>
                        <div className="row">
                          {day7.tripDetail &&
                            day7.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-4 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaBus" && (
                                        <FaBus fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col p-0"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day7.caption}
                        </div>
                      </>
                    ))}
                  {day.day8 &&
                    day.day8.map((day8) => (
                      <>
                        <h4 className="itinerary-title">{day8.title}</h4>
                        <div className="itinerary-image">
                          <img src={day8.image} alt="thaknak" />
                          <figcaption className="text-secondary">
                            Thaknak
                          </figcaption>
                        </div>
                        <div className="row">
                          {day8.tripDetail &&
                            day8.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col p-0"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day8.caption}
                        </div>
                      </>
                    ))}
                  {day.day9 &&
                    day.day9.map((day9) => (
                      <>
                        <h4 className="itinerary-title">{day9.title}</h4>
                        <div className="itinerary-image">
                          <img src={day9.image} alt="khare" />
                          <figcaption className="text-secondary">
                            Khare
                          </figcaption>
                        </div>
                        <div className="row">
                          {day9.tripDetail &&
                            day9.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-4 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {/* {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )} */}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col p-0"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day9.caption}
                        </div>
                      </>
                    ))}
                  {day.day10 &&
                    day.day10.map((day10) => (
                      <>
                        <h4 className="itinerary-title">{day10.title}</h4>
                        {/* <div className="itinerary-image">
                          <img src={day10.image} alt="kathmandu" />
                          <figcaption className="text-secondary">
                            Kathmandu: Boudha Stupa
                          </figcaption>
                        </div> */}
                        <div className="row">
                          {day10.tripDetail &&
                            day10.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col p-0"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day10.caption}
                        </div>
                      </>
                    ))}
                  {day.day11 &&
                    day.day11.map((day11) => (
                      <>
                        <h4 className="itinerary-title">{day11.title}</h4>
                        <div className="itinerary-image">
                          <img src={day11.image} alt="mera high camp" />
                          <figcaption className="text-secondary">
                            Mera high camp
                          </figcaption>
                        </div>
                        <div className="row">
                          {day11.tripDetail &&
                            day11.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col p-0"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day11.caption}
                          <br />
                          {day11.caption1}
                        </div>
                      </>
                    ))}
                  {day.day12 &&
                    day.day12.map((day12) => (
                      <>
                        <h4 className="itinerary-title">{day12.title}</h4>
                        <div className="row">
                          {day12.tripDetail &&
                            day12.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col p-0"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day12.caption}
                          <br />
                          {day12.caption1}
                        </div>
                      </>
                    ))}
                  {day.day13 &&
                    day.day13.map((day13) => (
                      <>
                        <h4 className="itinerary-title">{day13.title}</h4>
                        <div className="row">
                          {day13.tripDetail &&
                            day13.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaBus" && (
                                        <FaBus fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col p-0"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day13.caption}
                        </div>
                      </>
                    ))}
                  {day.day14 &&
                    day.day14.map((day14) => (
                      <>
                        <h4 className="itinerary-title">{day14.title}</h4>
                        <div className="row">
                          {day14.tripDetail &&
                            day14.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col p-0"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day14.caption}
                        </div>
                      </>
                    ))}
                  {day.day15 &&
                    day.day15.map((day15) => (
                      <>
                        <h4 className="itinerary-title">{day15.title}</h4>
                        <div className="itinerary-image">
                          <img src={day15.image} alt="thuli kharka" />
                          <figcaption className="text-secondary">
                            Thuli Kharka
                          </figcaption>
                        </div>
                        <div className="row">
                          {day15.tripDetail &&
                            day15.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col p-0"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day15.caption}
                        </div>
                      </>
                    ))}
                  {day.day16 &&
                    day.day16.map((day16) => (
                      <>
                        <h4 className="itinerary-title">{day16.title}</h4>
                        <div className="itinerary-image">
                          <img src={day16.image} alt="lukla" />
                          <figcaption className="text-secondary">
                            Lukla airport
                          </figcaption>
                        </div>
                        <div className="row">
                          {day16.tripDetail &&
                            day16.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col p-0"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day16.caption}
                        </div>
                      </>
                    ))}
                  {day.day17 &&
                    day.day17.map((day17) => (
                      <>
                        <h4 className="itinerary-title">{day17.title}</h4>
                        {/* <div className="itinerary-image">
                          <img src={day17.image} alt="kathmandu" />
                          <figcaption className="text-secondary">
                            Kathmandu
                          </figcaption>
                        </div> */}
                        <div className="row">
                          {day17.tripDetail &&
                            day17.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-4 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col p-0"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day17.caption}
                        </div>
                      </>
                    ))}
                  {day.day18 &&
                    day.day18.map((day18) => (
                      <>
                        <h4 className="itinerary-title">{day18.title}</h4>
                        <div className="itinerary-image">
                          <img src={day18.image} alt="kathmandu airport" />
                          <figcaption className="text-secondary">
                            Kathmandu Tribhuwan International Airport (TIA)
                          </figcaption>
                        </div>
                        <div className="row">
                          {day18.tripDetail &&
                            day18.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col p-0"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day18.caption}
                        </div>
                      </>
                    ))}
                </>
              ))}
          </div>

          <div className="main-modal-map d-none d-lg-flex">
            <div className="modal-map d-flex flex-column">
              <h4 className="title">Trekking Route Map</h4>
              <img src={Map} alt="ManasluMap" />
              <button type="button" className="btn btn-dark mt-3 col-12">
                <a href={Pdf} target="_blank" className="text-light">
                  Download Itinerary
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Itinerary;
