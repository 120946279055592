import React, { useState } from "react";
import {
  AiOutlineArrowUp,
  AiOutlineArrowDown,
  AiOutlineCloseCircle,
} from "react-icons/ai";
import { HashLink } from "react-router-hash-link";

const Modals = (props) => {
  const { tripTitle, tripPrice, sendmodaldetails } = props;
  const { id, modalhead, modaldetail } = sendmodaldetails;
  const [expandModal, setExpandModal] = useState(false);

  const modaldetailtrip = [
    {
      id: 1,
      modaldetail: [
        {
          modaltitle: "Trekking Part:",
          modaltext:
            "Before we begin climbing, we spend 10 days trekking and acclimatizing, which increases the chance of a successful summit. The trekking part is moderate. As you trek from one village to another, the elevation gradually increases. We will take an acclimatization break in two villages while trekking. There will be a handful of steep slopes and rocky sections along our trail. ",
        },
        // {
        //   modaltitle: "Terrain:",
        //   modaltext:
        //     "The Everest base camp trekking route has a blend of plain and rough paths. The first steep ascent you encounter is while approaching Namche Bazaar from Phakding. From here, there are many steep ups & downs throughout the trek. Unless the trail goes beyond the treeline, you walk through dense alpine woodlands and along the verdant foothills. The trail crosses many thrilling suspension and old wooden bridges over rushing rivers & streams. A final couple of days before reaching EBC involves a glacial walk and passes by a narrow moraine.",
        // },
        // {
        //   modaltitle: "Altitude Sickness:",
        //   modaltext:
        //     "Altitude sickness is an illness seen during high-altitude ventures. It's common, at the same time avoidable and treatable. Flying from Kathmandu to Lukla airport itself brings a drastic change in the elevation in a short time. Therefore, we descend to Phakding village on the same day we fly to acclimatize our bodies. Likewise, we have two acclimatization days in our itinerary that provides ample time for trekkers to adjust to the elevation. The acclimatization days are well used by hiking to a high point and returning to the same village to sleep low. We also make sure the group does not ascend more than 500 meters a day.",
        // },
        // {
        //   modaltitle: "Team:",
        //   modaltext:
        //     "Our Adventure Master team is composed of the most experienced trekking leaders and porters who know the Everest base camp trek route inside-out. We are your map on the trail that not only shows the path but also tells all the stories & myths encircling the trail. We help you hone your trekking and survival skills amidst the mountains of the Himalayas.",
        // },
        // {
        //   modaltitle: "Support:",
        //   modaltext:
        //     "Our Everest base camp trekking package has everything that makes the overall trip hassle-free for our guests. Likewise, our team obtains all the permits on your behalf and arranges all other things. We do all the bookings in Kathmandu and along the EBC trail in advance. The porters carry all the heavy luggage during trekking. We are committed to offering safe and satisfying treks in the Himalayas.",
        // },
        // {
        //   modaltitle: "Equipment:",
        //   modaltext:
        //     "Some of the major trekking equipment you need during the Everest base camp trek are a trekking pole, trekking boots, trekking clothes, sleeping bag, pocket torch, toiletries, snacks, etc. You can bring all the gear from your home if you want, but we highly recommend you buy or rent in Thamel, Kathmandu, instead. It'll save you a lot of baggage. Go through the checklist provided once the trip is confirmed and pack according to that.",
        // },
      ],
    },
    {
      id: 2,
      modaldetail: [
        {
          modaltitle: "Trekking Part:",
          modaltext:
            "Before we begin climbing, we spend 10 days trekking and acclimatizing, which increases the chance of a successful summit. The trekking part is moderate. As you trek from one village to another, the elevation gradually increases. We will take an acclimatization break in two villages while trekking. There will be a handful of steep slopes and rocky sections along our trail.",
        },
        {
          modaltitle: "Island Peak Climbing Route:",
          modaltext:
            "The climbing route of the Island peak gradually ascends along rocky ridges and snow mantles. We will leave the Island peak base camp early in the morning and walk on a rocky ridge to the glacier. Above the rock ridge, we will cross a steep snow headwall that leads to the summit. The guides will fix the ropes and make the course safe. We use different climbing gears like jumar and harnesses to ensure safety. The final climb involves a 60 - 70 degree face about 150 meters to the summit ridge.",
        },
        {
          modaltitle: "Pre-climbing Training",
          modaltext:
            "It is fine if you are unaware of climbing gear. During our stay at the Island peak base camp, the team leader will give pre-climbing training and briefing to all the participants. You will learn about all the possible circumstances that may occur during the final push. Likewise, the team leader will teach you how to use climbing gear properly. ",
        },
        {
          modaltitle: "Altitude Sickness:",
          modaltext:
            "To avoid altitude sickness, we take acclimatization days and go short hikes from the very start of our journey. We'll do our best to not get altitude sickness during Island peak climbing. If you see symptoms like headache, breathing difficulty, insomnia, vomiting, nausea, etc inform the team leader and take necessary precautions on time. Keep yourself hydrated, get ample sleep, and eat nutritious food.",
        },
        {
          modaltitle: "Support:",
          modaltext:
            "You will be supported by well-trained and experienced mountaineers during the Island peak climbing. Porter will take care of all the luggage and base camp preparation. We will reserve accommodation and obtain all the required permits on your behalf, which make the journey hassle-free for you. The guides will fix the rope and take care of you in the mountains.",
        },
        {
          modaltitle: "Equipment:",
          modaltext:
            "We provide you with a detailed equipment list for peak climbing in Nepal after the booking confirmation. For now, you can check our equipment list in the Trip Details section of our package page. ",
        },
      ],
    },
    {
      id: 3,
      modaldetail: [
        {
          modaltitle: "Facilities:",
          modaltext:
            "All modern facilities come with a hotel room in Kathmandu, so you will have no issue with that. The facilities along the trekking route are minimal. We suggest you carry extra Nepali cash to buy additional services while trekking. For the base camp, we carry all the tents and essentials. At Island peak base camp, we'll be self-sufficient. We provide comfortable alpine mattresses, a dining area, etc.",
        },
        {
          modaltitle: "Single room:",
          modaltext:
            "Our Island peak climbing with Everest base camp trek cost covers twin-shared rooms. If you want a single room, let our team know at the time of the booking. You will see a slight change in the package cost if you opt for a single room.",
        },
        {
          modaltitle: "Hot water:",
          modaltext:
            "Hot water for the shower during the trek is only available if you pay some extra bucks. Usually, lodges give enough hot water to wash your face and hands. In Kathmandu, there will be a 24/7 hot water supply.",
        },
        {
          modaltitle: "Charging:",
          modaltext:
            "In the mountains, you can charge your devices at the charging station of the lodges. It may cost you some money. Because Island peak climbing is a long journey, we recommend you buy a solar charger, or you can carry extra batteries/power bank for backup.",
        },
        {
          modaltitle: "WiFi:",
          modaltext:
            "Free WiFi is available in the hotel and cafes in Kathmandu. On the trail, some villages in the lower elevation offer paid WiFi. For USD 2 to USD 5, you may get to use the internet for a few hours. A Nepali sim with data is a much better option. If you want, our team will help you get a tourist sim that you can use for the internet throughout the journey.",
        },
      ],
    },
    {
      id: 4,
      modaldetail: [
        {
          modaltitle: "Cuisine:",
          modaltext:
            "In Kathmandu, you can find cuisine from all around the world. Most cafes and restaurants have a multi-cuisine menu. In the mountains, the options get limited. Up to Namche Bazaar, you can still find places selling pizza, burgers, fish & chips, steak, bakery items, etc. Above this village, the menu gets short and expensive.",
        },
        {
          modaltitle: "Nepali Flavors:",
          modaltext:
            "Nepal is home to numerous ethnic groups and each of them has its own delicacies. The fragment species, ancient cooking techniques, exotic veggies & fruits, and stories behind them enhance the eating experience. Some of the dishes you must try before leaving Kathmandu are sekuwa, sukuti, gundruk, tama, yomari, bara, chatamari, Nepali momo, etc.",
        },
        {
          modaltitle: "Super Food:",
          modaltext:
            "Dal bhat is a superfood. It is a staple Nepali meal that comes with steamed rice and lentil soup. The set is served with quite a few kinds of side dishes like curry, stir-fried veggies, salad, pickles, papad, etc.",
        },
        {
          modaltitle: "Suitability:",
          modaltext:
            "There are plenty of options for vegans, vegetarians, and gluten-free diners.",
        },
      ],
    },
    {
      id: 5,
      modaldetail: [
        {
          modaltitle: "Morning stretching:",
          modaltext:
            "Practice yoga & meditation by yourself (Or hire a professional yoga instructor) Learn to live in the moment, fight with your demons, and come back as a new individual",
        },
      ],
    },
    {
      id: 6,
      modaldetail: [
        {
          modaltitle: "Overview :",
          modaltext:
            "Island peak climbing with the Everest base camp trek begins in Kathmandu. We take a day to explore historical sites and prepare for the expedition. On the third day, we leave Kathmandu and fly to Lukla, from where the journey begins. For the next few days, we will be walking through alpine woods and terraces, taking overnight stops at picturesque Sherpa villages. The trail ascends and descends, crossing numerous hanging suspension bridges. We explore beautiful river valleys and glaciers and make it to EBC. After a hike to Kala Patthar, we walk to Chhukung valley and continue towards Island Peak Base Camp. The following morning, we’ll attempt to scale the Island Peak and return back to base camp on the same day. Then all left is a trek back to Lukla to catch the final flight to Kathmandu.",
        },
        {
          modaltitle: "Mountain views during Island peak climbing:",
          modaltext:
            "The Island peak climbing with Everest base camp is an incredible venture if you wish to witness breathtaking mountain landscapes. You get to see five eight-thousand mountains along the trail- Everest (8,849 m/29,032 ft), Lhotse (8,516 m/27,940 ft, Kanchenjunga (8,586 m/28,169 ft), Cho Oyu (8,188 m/26,864 ft), and Makalu (8,463 m/27,766 ft). Likewise, we hike to three viewpoints in the course, and all of them present a surreal mountain panorama. Many other stunning mountains, like Khumbila (5,717 m/18,757 ft), Thamserku (6,608 m/21,680 ft), Ama Dablam (6,812 m/22,349 ft), Pumori (7,161 m/23,494 ft), Nuptse (7,861 m/25,791 ft), Kangtega (6,782 m/22,251 ft), etc, are seen on the way. Likewise, we will catch several sunrises and sunsets. And the bird's eye view of the Himalayas from the summit of the Island peak is exceptionally phenomenal.",
        },
        {
          modaltitle: "Restaurant & Tea shops:",
          modaltext:
            "There are many restaurants and cafes in Kathmandu. Similarly, along the trail, there is also a decent number of tea shops where you can buy snacks if you wish. The price of snacks and beverages in tea shops is way higher than in the city, so it is better to carry them with you and buy them if they get finished.",
        },
        {
          modaltitle: "ATM/Currency:",
          modaltext:
            "Withdraw cash in Kathmandu to use in the mountains. There are many ATMs around the city, and all of them support international cards. If you have foreign currency, exchange it in a money center or bank. You cannot use foreign currency in the mountains. There is an ATM lounge in Namche Bazaar where you get the last chance to withdraw money before the trek proceeds further. ",
        },
        {
          modaltitle: "Internet:",
          modaltext:
            "There are WiFi services in the lodges in exchange for a few bucks. The internet may not be stable all the time, but you'll get a chance to send messages to your loved ones. The better alternative is buying a tourist sim that we'll help you with. It is much more affordable and reliable than using WiFi.",
        },
        {
          modaltitle: "Flora + Fauna:",
          modaltext:
            "During the Island peak climbing, you'll be exploring inside the Sagarmatha National Park. It begins from Monjo onwards and goes up to the top of Mt. Everest. The trail is very enriched with a diverse range of flowers, plants, animals, and birds. You may come across wildlife, like Himalayan tahr, blue sheep, or snow leopards. Either you will be walking through dense forests or alongside verdant hills during the initial phase of the trek.",
        },
        {
          modaltitle: "Locals:",
          modaltext:
            "The locals of the Khumbu region (Everest region) are Lamas, Bhotiyas, Sherpas, Rais, etc. These are some of the native communities that reside along the trail. The locals are very welcoming and kind. They will make you feel at home and do their best to make your stay with them comfortable. Our guide will help you interact with them and break the conversation. Don't feel shy to say Namaste and talk to them.",
        },
        {
          modaltitle: "Language:",
          modaltext:
            "Our primary language during the venture will be English. If you cannot speak English at all, we will assign you a guide who can communicate in your language. We will make sure there is no communication barrier between you and us.",
        },
        {
          modaltitle: "Monasteries:",
          modaltext:
            "You will get to visit a lot of religious sites in Kathmandu and during the expedition. In Kathmandu, you will get to explore Boudhnath Stupa, Swayambhunath, Pashupatinath Temple, Living Goddess Kumari's temple, etc. Likewise, the trekking route has a lot of stupas, chortens, and prayer wheels scattered. You will visit ancient Tengboche and Pangboche monastery.",
        },
      ],
    },
  ];
  const expandHandler = () => {
    setExpandModal(!expandModal);
  };

  const collapseHandler = () => {
    setExpandModal(false);
  };

  const onTripChange = () => {
    sessionStorage.removeItem("tripTitle");
    sessionStorage.removeItem("tripPrice");
    sessionStorage.setItem("tripTitle", tripTitle);
    sessionStorage.setItem("tripPrice", tripPrice);
  };

  return (
    <>
      <div className={`modals ${expandModal ? "expand" : ""}`}>
        <div className={`modal-head `} onClick={expandHandler}>
          <div className="modal-head-title">
            {!expandModal ? (
              <AiOutlineArrowUp fontSize="2rem" className="px-1" />
            ) : (
              <AiOutlineArrowDown fontSize="2rem" className="px-1" />
            )}
            <div className="head-title">{modalhead}</div>
          </div>
          <div className="iclose">
            {expandModal && (
              <AiOutlineCloseCircle
                fontSize="1.3rem"
                onClick={collapseHandler}
              />
            )}
          </div>
        </div>
        <div className={`modal-body ${expandModal ? "modal-body-expand" : ""}`}>
          {!expandModal ? (
            <>
              {modaldetail &&
                modaldetail?.map((detail) => (
                  <div className="modal-content">
                    <div className="modal-title d-none d-sm-flex">
                      {detail.modaltitle}
                    </div>
                    <div className="modal-text">{detail.modaltext}</div>
                  </div>
                ))}
            </>
          ) : (
            <>
              {modaldetailtrip &&
                modaldetailtrip[id - 1]?.modaldetail?.map((detail) => (
                  <div className="modal-content">
                    <div className="modal-title fw-bold">
                      {detail.modaltitle}
                    </div>
                    <div className="modal-text">{detail.modaltext}</div>
                  </div>
                ))}
            </>
          )}
        </div>
        <HashLink
          smooth
          to="/booking"
          className="modal-book link"
          style={{ color: "#000" }}
          onClick={onTripChange}
        >
          BOOK NOW
        </HashLink>
      </div>
    </>
  );
};

export default Modals;
