import React from 'react'
import { Container } from 'react-bootstrap'
import santi1 from "../../../image/ReviewImages/santi.png"
import santi2 from "../../../image/ReviewImages/santiMob.png"
import { FaStar, FaTripadvisor, FaCalendarAlt } from 'react-icons/fa'

function santiReview() {
    return (
        <div>
            <Container className='review-section my-5'>
                <div className='row home-review'>
                    {/* <div className='col-4 review-image'>
                        <img src={santi1} alt="santi" />
                    </div> */}
                    <div className='col-2 review-image'>
                        <img src={santi1} alt="santi" />
                    </div>
                    <div className='col-2 review-image-mobile'>

                        <img src={santi2} alt="santi" />
                    </div>
                    <div className='col-8 review-content'>

                        <h4 className='title'>Santi Moara</h4>
                        <div className='title text-success'><FaTripadvisor /> TripAdvisor Review</div>
                        <div className='text-warning'>
                            <FaStar />
                            <FaStar />
                            <FaStar />
                            <FaStar />
                            <FaStar />
                        </div>
                        <div className='title my-2'>100% My trekking company, every time I visit Nepal</div>
                        <p className='d-flex align-items-center'><FaCalendarAlt className='me-2' />Nov 2022 • Solo</p>
                        <p> After asking information to several trekking companies, comparing prices and making sure I was going with a trustworthy company, Adventure Master Trek was the one that made me feel more confident, so I decided to book my Manaslu Circuit Trek with them. I couldn’t be happier with my decision.
                        </p><p>
                            I completed my trip in November 2022, and the weather was just perfect.
                            “Adventure Master Trek” took care of literally everything (airport pickup, accommodation, every meal, permits, guide, porter, etc), but they were also completely flexible to fit my preferences (and very good price as well, compared to other options I had)
                        </p><p>
                            Santosh, Guy, and all the people working at Adventure Master are incredibly friendly and will make sure you leave Nepal with a smile on your face… They surely did it with me.
                        </p><p>
                            10 out of 10 experience, and I will be back for more. Probably the Everest Region next..
                            <br />
                            See you soon.</p>
                        <a href="https://www.tripadvisor.com/Attraction_Review-g293890-d8661857-Reviews-or10-Adventure_Master_Trek-Kathmandu_Kathmandu_Valley_Bagmati_Zone_Central_Region.html" target="_blank"><div className='btn btn-outline-success'>View more</div></a>
                    </div>
                    {/* <div className='col-2'>
                        <h4 className='title'>Next departure</h4>
                        <ul className='home-departure-date'>
                            <li><FaCalendarAlt className='me-2' />Date: March 10, 2023</li>
                        </ul>
                    </div> */}
                </div >
            </Container >
        </div >
    )
}

export default santiReview