import React, { useState } from "react";
import {
  AiOutlineArrowUp,
  AiOutlineArrowDown,
  AiOutlineCloseCircle,
} from "react-icons/ai";
import { HashLink } from "react-router-hash-link";
import ManasluCircuitTrekDepartureDateModal from "../../components/DepartureDateModal/ManasluCircuitTrekDepartureDateModal";

const Modals = (props) => {
  const { tripTitle, tripPrice, sendmodaldetails } = props;
  const { id, modalhead, modaldetail } = sendmodaldetails;
  const [expandModal, setExpandModal] = useState(false);

  const modaldetailtrip = [
    {
      id: 1,
      modaldetail: [
        {
          modaltitle: "Trekking part:",
          modaltext:
            "You trek for seven days before reaching the base camp of Mera peak. The trekking part is equally challenging as the terrain is steep and offbeat. Even if you will be trekking in the Khumbu region, the tourist infrastructure is very limited from the first day in comparison to the famous EBC trail. The altitude gradually increases as we trek and walk past villages. We will cross many bridges, passes, and moraines on the way. The trail goes through dense forests and beautiful river valleys.",
        },
        // {
        //   modaltitle: "Difficulty Level:",
        //   modaltext:
        //     "As we have been mentioning from the very start, the Everest base camp helicopter tour is easy. Unlike trekking, you do not need to walk 6 hours a day on steep, rugged paths whatsoever. There is no challenging factor in the Everest helicopter tour. This is a perfect family tour package in Nepal that you can do with your kids & senior members. You only need to commit 4 to 5 hours of your day for a helicopter tour to EBC.",
        // },
        // {
        //   modaltitle: "Terrain:",
        //   modaltext:
        //     "The walking part only involves when you land at Kala Patthar and at Everest View Hotel. It's simple walking on rough terrain for a few minutes. Your body will not see any stress or exhaustion.",
        // },
        // {
        //   modaltitle: "Altitude Sickness:",
        //   modaltext:
        //     "Even though the Everest base camp helicopter tour with landing bypasses all the physical difficulties, the risks of altitude sickness still remain there. At the end of the day, you'll be flying from Kathmandu at 1,400 m to Kala Patthar at 5,644 m. The elevation increase is drastic in the short time frame, which may trigger altitude sickness in some travelers. The chances are very less likely, though. Till this date, we have not seen any participants getting altitude sickness during the Everest base camp heli tour. It's a quick trip, so you'll get the opportunity to escape from altitude sickness. To reduce even the slightest possibility of altitude sickness, we do not stay more than 10 minutes at Kala Patthar.",
        // },
        // {
        //   modaltitle: "Team:",
        //   modaltext:
        //     "At Adventure Master, we have a professional team of individuals who love traveling and have been organizing ventures in the Himalayas for numerous years. For the Everest base camp helicopter tour, we are working with one of the most experienced aviation companies in Nepal, who have been flying in the Himalayas for multiple decades. Our office team and flight crew together make the finest team that you can fly with, in the Himalayas freely.",
        // },
        // {
        //   modaltitle: "Support:",
        //   modaltext:
        //     "We take care of all of your requests while traveling with us in Nepal. Our team will make sure everything is as per your requirements. For the Everest helicopter tour with landing, we obtain the necessary permits and help you with boarding. We make sure the booking and boarding both go seemingly. Our team will assist you during the flight and take care of your safety. Our goal is to make the Everest base camp helicopter tour with landing as memorable as possible but not at the cost of your safety.",
        // },
        // {
        //   modaltitle: "Equipment:",
        //   modaltext:
        //     "Another great thing about the Everest base camp helicopter tour is that you'll not need to pack numerous things. Wear normal appropriate clothes and a windproof down jacket (you can rent it in Thamel) with comfortable shoes & warm socks. Carry sun hat, UV protection sunglasses, reusable water bottle, toiletries (tissue paper, sunscreen, lip balm, etc), camera, passport & other documents.",
        // },
      ],
    },
    {
      id: 2,
      modaldetail: [
        {
          modaltitle: "Trekking part:",
          modaltext:
            "You trek for seven days before reaching the base camp of Mera peak. The trekking part is equally challenging as the terrain is steep and offbeat. Even if you will be trekking in the Khumbu region, the tourist infrastructure is very limited from the first day in comparison to the famous EBC trail. The altitude gradually increases as we trek and walk past villages. We will cross many bridges, passes, and moraines on the way. The trail goes through dense forests and beautiful river valleys.",
        },
        {
          modaltitle: "Island Peak Climbing Route:",
          modaltext:
            " We build a high camp at 5,780 m/18,964 ft, which makes our summit push short. The Mera Peak High Camp will be on a rocky shelf behind a large vertical rock that will give us some degree of protection and shelter. From here, we will manage our pace following the northern route to reach the summit. The outstanding mountain panorama will always be in our sight throughout the summit push and while returning.",
        },
        {
          modaltitle: "Pre-climbing training:",
          modaltext:
            "Depending on the route and the level of experience of the trekkers, the guides will provide training on various climbing skills, such as ice climbing or the use of crampons and other mountain gear. This training will help you to feel more confident and prepared for the climb. Overall, the pre-climbing training provided by the mountain guides is an important part of preparing for the climb of Mera Peak. By taking the time to learn and practice these skills and techniques, you can increase your chances of success on the mountain and have a safer and more enjoyable experience.",
        },
        {
          modaltitle: "Altitude Sickness:",
          modaltext:
            "Acclimatizing to the high altitudes before the summit push is very crucial. Symptoms of altitude sickness include headache, fatigue, dizziness, shortness of breath, and nausea. In severe cases, it can lead to more serious conditions such as high-altitude cerebral edema (HACE) or high-altitude pulmonary edema (HAPE), which can be life-threatening. To prevent altitude sickness, climbers need to acclimatize properly by ascending gradually, taking regular breaks, and drinking plenty of fluids. If you experience any of these symptoms, inform your guide immediately.",
        },
        {
          modaltitle: "Support:",
          modaltext:
            "During your Mera Peak climbing adventure, you will be accompanied by highly skilled and seasoned mountaineers. These professionals will be there to support you every step of the way, ensuring that you have a safe and enjoyable experience. Additionally, a porter will be responsible for carrying all of your luggage and preparing the high camp, allowing you to focus on the climb. To make the journey even more stress-free, we will handle all of the logistics, including securing accommodations and obtaining necessary permits on your behalf. Our guides will also take care of setting up the ropes and providing any other assistance you may need in the mountains.",
        },
        {
          modaltitle: "Equipment:",
          modaltext:
            "Mera peak climbing in Nepal requires the use of specialized equipment to ensure the safety and comfort of the climbers. You have to be well-packed to have a smooth journey. Check out the packing list at the end of our Trip Details page for in-depth detail.",
        },
      ],
    },
    {
      id: 3,
      modaldetail: [
        {
          modaltitle: "Facilities:",
          modaltext:
            "The facilities in the lodges/tea houses are minimal. These are simple accommodations. You get a shared dormitory-style room and a common washroom. Apart from bed and breakfast, any additional service like charging, wifi, hot shower, etc will cost you additional money. During climbing, we use tents in the high camp. We will be self-sufficient at the high camp. Meals will be cooked by the kitchen staff and other crew takes care of tents.",
        },
        {
          modaltitle: "Single room:",
          modaltext:
            "It is possible to request a single room in the lodges and tea houses along the trails. You have to inform us at the time of booking if you are expecting a single room. These rooms are typically basic. Keep in mind that single rooms may not be available in all locations, and availability may vary depending on the season and the level of demand. We will do our best to book you single rooms, though.",
        },
        {
          modaltitle: "Hot water:",
          modaltext:
            "Hot showers are typically provided at an additional cost and may not be available at all locations. The availability of hot showers will depend on a variety of factors, including the location, the season, and the availability of electricity or other energy sources. You have to learn to manage showers and wipe your body.",
        },
        {
          modaltitle: "Charging:",
          modaltext:
            "You can pay a few bucks every time you charge your device at the charging station in the lodge/tea house. Or carry a solar charger and spare batteries for backup. Because locals highly rely on solar power, they ask for extra money to let you charge your device.",
        },
        {
          modaltitle: "WiFi:",
          modaltext:
            "Your best sort to use the internet during the Mera peak climbing is by using a Nepali sim card. It is much more affordable and convenient too. Our team will help you get the tourist sim with data. Do understand that the remoteness of the region and elevation will highly affect the network. You may not get single at some points too.",
        },
      ],
    },
    {
      id: 4,
      modaldetail: [
        {
          modaltitle: "Cuisine:",
          modaltext:
            "Kathmandu is home to a wide variety of cuisines, reflecting the city's diverse cultural influences. As the capital and largest city of Nepal, Kathmandu serves as a hub for the country's many different ethnic groups, each with its own unique culinary traditions. Whether you are looking for a traditional Nepali meal or a taste of something from further afield, you are sure to find something to suit your tastes in Kathmandu. From Italian pizzas and pasta to Mexican tacos and burritos, there is something to suit every taste.",
        },
        {
          modaltitle: "Nepali Flavors:",
          modaltext:
            "Nepali cuisine is known for its bold and complex flavors, which are a reflection of the country's diverse cultural influences. Nepali dishes often feature a variety of aromatic spices, such as cumin, coriander, turmeric, and garam masala. Some of the dishes you should try are sel roti, gundruk, tama, chatamari, momo, dal bhat, sekuwa, choyla, sukuti, titaura, and taas.",
        },
        {
          modaltitle: "Super Food",
          modaltext:
            "Dal bhat is a staple Nepali dish that is widely consumed throughout the country. The dish consists of steamed rice served with a lentil soup (dal), and is typically accompanied by a variety of side dishes. It is a nourishing and satisfying meal that is enjoyed by people of all ages in Nepal and will become your staple during trekking.",
        },
        {
          modaltitle: "Suitability:",
          modaltext:
            "There are plenty of options for vegans, gluten-free, and vegetarian diners.",
        },
      ],
    },
    {
      id: 5,
      modaldetail: [
        // {
        //   modaltitle: "a:",
        //   modaltext:
        //     "abc",
        // },
      ],
    },
    {
      id: 6,
      modaldetail: [
        {
          modaltitle: "Overview:",
          modaltext:
            "Mera Peak has three summits- Mera Central (6,461 m/21,198 ft), Mera North (6,476 m/21,247 ft), and Mera South (6,065 m/19,898 ft). During our Mera Peak expedition, we will summit the Mera North. Our exhilarating adventure to Mera Peak begins with a short 35 minute flight to Lukla. From here, we trek to Paiya for a night's stay. The trail goes through Surke village, crossing Handi Khola and Chutok La pass. The next day, cross a suspension bridge and ascend to Kari La pass before walking through alpine woods to Panggom village. From here, walk on an uphill trail to Panggom La pass and further cross the Peseng river before climbing to Peseng Kharka Danda. The trail from here takes us to Ningsow. We now enter the Makalu Barun National Park and walk via Chhatra Khola, Kothe, and Thaknak. Khare is the last settlement before Mera High Camp. We now walk on Mera La pass and cross Mera Glacier to make it to the summit of Mera Peak.",
        },
        {
          modaltitle: "Mountain views during Mera Peak Climbing:",
          modaltext:
            "Mera peak climbing offers sensational mountain panoramas. During the trek, you will cross many passes from where you can see diverse landscapes. From the summit of Mera Peak, five of the ten highest mountains in the world is visible- Everest (8,849 m/29,032 ft), Kanchenjunga (8,586 m/28,169 ft), Cho Oyu (8,188 m/26,864 ft), Lhotse (8,516 m/27,940 ft), and Makalu (8,463 m/27,766 ft). Other mountains like Nuptse(7,861 m/25,791 ft), Kangtega (6,782 m/22,251 ft), Chamlang (7,319 m/24,012 ft), Ama Dablam (6,812 m/22,349 ft), Pumori (7,161 m/23,494 ft), Cholatse (6,440 m/21,130 ft), etc, are also seen on the Mera peak trekking route.",
        },
        {
          modaltitle: "Restaurants & Tea Shops:",
          modaltext:
            "In Kathmandu, there is a wide selection of restaurants and cafes to choose from. Along the Mera Peak trail, there are also a few tea shops where travelers can purchase snacks, though the prices are generally higher than in the city. It is advisable to bring your own snacks with you and only purchase more if necessary. This can help you save money and avoid paying higher prices at tea shops.",
        },
        {
          modaltitle: "ATM/Currency:",
          modaltext:
            "It is recommended to withdraw cash in Kathmandu to use during your trip to the mountains. The city has numerous ATMs that accept international cards, so you should have no difficulty finding one. If you have foreign currency, it is best to exchange it for local currency at a money center or bank, as a foreign currency will not be accepted in the mountains. Please note that there are no ATMs available on the Mera Peak trail.",
        },
        {
          modaltitle: "Internet:",
          modaltext:
            "Many of the lodges on the trail offer WiFi services for a fee, although the internet connection may not always be stable. If you want to stay in touch with loved ones during your trip, you may want to consider purchasing a tourist SIM card, which is usually more affordable and reliable than using WiFi. We would be happy to assist you in obtaining a SIM card for your trip.",
        },
        {
          modaltitle: "Flora + Fauna:",
          modaltext:
            "The Makalu Barun National Park, which is located along the route to Mera Peak, is home to a diverse array of flora and fauna. As you travel through the park, you may encounter a variety of plants and animals, each with its own unique characteristics and adaptations to the local environment. The park is home to many species of trees, including rhododendrons, fir, and pine, as well as a wide variety of other plants, such as herbs, grasses, and shrubs. Along the way, you may also see a range of animals, including Himalayan black bears, red pandas, snow leopards, and various species of birds and butterflies.",
        },
        {
          modaltitle: "Locals:",
          modaltext:
            "The locals of the Khumbu region are known for their hardworking and hospitable nature and are an integral part of the region's rich cultural tapestry. Their welcoming and friendly nature adds to the sense of community and connection that is often felt on the trails. The majority of the people in this region are Sherpas. In addition to the Sherpas, the Khumbu region is also home to other ethnic groups, including the Tamangs, Rais, Lamas, and Bhotiyas. These groups have their own distinct cultures and traditions and play important roles in the local community.",
        },
        {
          modaltitle: "Language:",
          modaltext:
            "English will be the main language used during the trip. However, if you are unable to speak English, we will assign you a guide who is able to communicate in your language to ensure that there are no barriers to communication between us. We will do our best to ensure that you feel comfortable and able to fully participate in all aspects of the journey.",
        },
        {
          modaltitle: "Monasteries:",
          modaltext:
            "You will come across many stupas and chortens on the way to Mera Peak. One of the oldest monasteries we will visit is Lungsumgba Gompa on the way to Thaknak. Likewise, in Kathmandu, you will explore many UNESCO World Heritage Sites like Boudhanath Stupa, Monkey Temple, Kathmandu Durbar Square, and Pashupatinath Temple.",
        },
      ],
    },
  ];
  const expandHandler = () => {
    setExpandModal(!expandModal);
  };

  const collapseHandler = () => {
    setExpandModal(false);
  };

  const onTripChange = () => {
    sessionStorage.removeItem("tripTitle");
    sessionStorage.setItem("tripTitle", tripTitle);
    sessionStorage.setItem("tripPrice", tripPrice);
  };

  return (
    <>
      <div className={`modals ${expandModal ? "expand" : ""}`}>
        <div className={`modal-head `} onClick={expandHandler}>
          <div className="modal-head-title">
            {!expandModal ? (
              <AiOutlineArrowUp fontSize="2rem" className="px-1" />
            ) : (
              <AiOutlineArrowDown fontSize="2rem" className="px-1" />
            )}
            <div className="head-title">{modalhead}</div>
          </div>
          <div className="iclose">
            {expandModal && (
              <AiOutlineCloseCircle
                fontSize="1.3rem"
                onClick={collapseHandler}
              />
            )}
          </div>
        </div>
        <div className={`modal-body ${expandModal ? "modal-body-expand" : ""}`}>
          {!expandModal ? (
            <>
              {modaldetail &&
                modaldetail?.map((detail) => (
                  <div className="modal-content">
                    <div className="modal-title d-none d-sm-flex">
                      {detail.modaltitle}
                    </div>
                    <div className="modal-text">{detail.modaltext}</div>
                  </div>
                ))}
            </>
          ) : (
            <>
              {modaldetailtrip &&
                modaldetailtrip[id - 1]?.modaldetail?.map((detail) => (
                  <div className="modal-content">
                    <div className="modal-title fw-bold">
                      {detail.modaltitle}
                    </div>
                    <div className="modal-text">{detail.modaltext}</div>
                  </div>
                ))}
            </>
          )}
        </div>
        <HashLink
          smooth
          to="/booking"
          className="modal-book link"
          style={{ color: "#000" }}
          onClick={onTripChange}
        >
          BOOK NOW
        </HashLink>
        
      </div>
    </>
  );
};

export default Modals;
