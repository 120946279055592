import React, { useState } from "react";
import {
  AiOutlineArrowUp,
  AiOutlineArrowDown,
  AiOutlineCloseCircle,
} from "react-icons/ai";
import { HashLink } from "react-router-hash-link";

import ManasluCircuitTrekDepartureDateModalTripInfo from "../../components/DepartureDateModal/DepartureDateForTripInfo/ManasluCircuitTrekDepartureDateModalTripInfo";

const Modals = (props) => {
  const { tripTitle, tripPrice, sendmodaldetails } = props;
  const { id, modalhead, modaldetail } = sendmodaldetails;
  const [expandModal, setExpandModal] = useState(false);

  const modaldetailtrip = [
    {
      id: 1,
      modaldetail: [
        {
          modaltitle: "Manaslu Region:",
          modaltext:
            "The northern part of Nepal is where the Manaslu region is. The region has natural resources that are fragile but diverse, and it protects a lot of exotic plants and animals. Based on natural features and ethnic groups, the Manaslu region is split into three different areas. Jagat is at the beginning of the Manaslu region. Nubri is the name of the area where the villages of Samagaon, Lho, and Prok are. In the same way, the area around Bihi is called Kutang, and the area around Chumchet and Chhekampar is called Tsum valley.",
        },
        // {
        //   modaltitle: "Difficulty Level:",
        //   modaltext:
        //     "As we have been mentioning from the very start, the Everest base camp helicopter tour is easy. Unlike trekking, you do not need to walk 6 hours a day on steep, rugged paths whatsoever. There is no challenging factor in the Everest helicopter tour. This is a perfect family tour package in Nepal that you can do with your kids & senior members. You only need to commit 4 to 5 hours of your day for a helicopter tour to EBC.",
        // },
        // {
        //   modaltitle: "Terrain:",
        //   modaltext:
        //     "The walking part only involves when you land at Kala Patthar and at Everest View Hotel. It's simple walking on rough terrain for a few minutes. Your body will not see any stress or exhaustion.",
        // },
        // {
        //   modaltitle: "Altitude Sickness:",
        //   modaltext:
        //     "Even though the Everest base camp helicopter tour with landing bypasses all the physical difficulties, the risks of altitude sickness still remain there. At the end of the day, you'll be flying from Kathmandu at 1,400 m to Kala Patthar at 5,644 m. The elevation increase is drastic in the short time frame, which may trigger altitude sickness in some travelers. The chances are very less likely, though. Till this date, we have not seen any participants getting altitude sickness during the Everest base camp heli tour. It's a quick trip, so you'll get the opportunity to escape from altitude sickness. To reduce even the slightest possibility of altitude sickness, we do not stay more than 10 minutes at Kala Patthar.",
        // },
        // {
        //   modaltitle: "Team:",
        //   modaltext:
        //     "At Adventure Master, we have a professional team of individuals who love traveling and have been organizing ventures in the Himalayas for numerous years. For the Everest base camp helicopter tour, we are working with one of the most experienced aviation companies in Nepal, who have been flying in the Himalayas for multiple decades. Our office team and flight crew together make the finest team that you can fly with, in the Himalayas freely.",
        // },
        // {
        //   modaltitle: "Support:",
        //   modaltext:
        //     "We take care of all of your requests while traveling with us in Nepal. Our team will make sure everything is as per your requirements. For the Everest helicopter tour with landing, we obtain the necessary permits and help you with boarding. We make sure the booking and boarding both go seemingly. Our team will assist you during the flight and take care of your safety. Our goal is to make the Everest base camp helicopter tour with landing as memorable as possible but not at the cost of your safety.",
        // },
        // {
        //   modaltitle: "Equipment:",
        //   modaltext:
        //     "Another great thing about the Everest base camp helicopter tour is that you'll not need to pack numerous things. Wear normal appropriate clothes and a windproof down jacket (you can rent it in Thamel) with comfortable shoes & warm socks. Carry sun hat, UV protection sunglasses, reusable water bottle, toiletries (tissue paper, sunscreen, lip balm, etc), camera, passport & other documents.",
        // },
      ],
    },
    {
      id: 2,
      modaldetail: [
        {
          modaltitle: "Manaslu Region:",
          modaltext:
            "The northern part of Nepal is where the Manaslu region is. The region has natural resources that are fragile but diverse, and it protects a lot of exotic plants and animals. Based on natural features and ethnic groups, the Manaslu region is split into three different areas. Jagat is at the beginning of the Manaslu region. Nubri is the name of the area where the villages of Samagaon, Lho, and Prok are. In the same way, the area around Bihi is called Kutang, and the area around Chumchet and Chhekampar is called Tsum valley.",
        },
        {
          modaltitle: "Level of Difficulty:",
          modaltext:
            "The Manaslu circuit trek is only for experienced hikers who have been to high altitudes before and are in good shape. The hike takes 6 to 7 hours and goes along rough, forested slopes and rocky foothills. For the trekkers to push themselves, they need a lot of stamina, core strength, and the right mindset. You will cross the Larkya La pass to get to a remote part of the Himalayas.",
        },
        {
          modaltitle: "Terrain:",
          modaltext:
            "From Arughat to the Larkya La pass, the Manaslu circuit trail goes through six different climate zones and has a huge change in elevation. You start trekking in tropical forests and along the lower banks of the Budhi Gandaki and Marsyangdi rivers and climb to the arctic zone. You will mostly be walking through open meadows between 4,000 m and 5,000 m as you trek through dense forests. There are a lot of steep, straight ups. The way down the hill is just as hard.",
        },
        {
          modaltitle: "Altitude Sickness:",
          modaltext:
            "In a few days, the trail goes from valleys and low hills below 1,000 m to a high mountain pass above 5,000 m. This sudden change in altitude could give you altitude sickness, so your body needs to get used to the higher altitude as you hike up. Our plan for the Manaslu circuit trek has a lot of free time and beautiful stops. While trekking, we do everything we can to avoid altitude sickness. In the unlikely event that you get sick or show signs of altitude sickness, our mountain guide will help you.",
        },
        {
          modaltitle: "Team:",
          modaltext:
            "We have separate office and mountain teams that take care of all of your needs during the Manaslu circuit trekking and make it go smoothly. The office staff will take care of your booking, getting ready, packing, and getting any permits you need. While the mountain crew keeps an eye out for your safety on the trail. They act as your walking map, book all of your lodging, help you meet the locals, and tell you a lot of stories about the area.",
        },
        {
          modaltitle: "Support:",
          modaltext:
            "Our Manaslu circuit trek package includes everything you need to stay, eat, get permits, etc, according to the itinerary. You trek with mountain guides and porters who know what they are doing. You will be trekking with people from the area, so it will be a very immersive experience. We will  be ready to help you with anything in Nepal at any time, day or night.",
        },
        {
          modaltitle: "Equipment:",
          modaltext:
            "When you book a trek to the Manaslu Circuit, you will be given a list of things to bring. You don't have to buy everything in your own country and bring it all to Nepal. You can buy and rent trekking gear in Kathmandu. There are a lot of options to choose from, and the prices vary.",
        },
      ],
    },
    {
      id: 3,
      modaldetail: [
        {
          modaltitle: "Facilities:",
          modaltext:
            "In Kathmandu, you'll get all the modern facilities. However, there are not many services provided in the tea houses. Tea houses are the most simple type of accommodation on the trail.",
        },
        {
          modaltitle: "Single room:",
          modaltext:
            "Guests only get single rooms if they ask for them when they book. Most of the time, single rooms are not available and are a bit pricey. Tell us if you think it will be hard for you to get used to sharing a room. The team will book single rooms for you in Kathmandu and while you are trekking.",
        },
        {
          modaltitle: "Hot water:",
          modaltext:
            "Most of the time, hot water is given to wash with. If you want to take a hot shower in the mountains, you might have to pay a little bit more.",
        },
        {
          modaltitle: "Charging:",
          modaltext:
            "Like a lodge, a tea house has a place to charge your phone. You can charge your cell phones and cameras for a fee. For this trip, you should definitely bring a power bank, extra batteries, or a solar charger.",
        },
        {
          modaltitle: "WiFi:",
          modaltext:
            "WiFi is free everywhere in Kathmandu. Also, the network works well. Some villages on the Manaslu circuit trekking route have WiFi, but the connection may not always be that good or stable. Even if you have a Nepali sim card and use cell data, you may not always be able to connect to a network in the Manaslu region.",
        },
      ],
    },
    {
      id: 4,
      modaldetail: [
        {
          modaltitle: "Cuisine:",
          modaltext:
            "Kathmandu has many different kinds of food and cuisines. There are also restaurants that only serve certain kinds of food. You will  have a lot of choices, from fine dining to street food. Most of the food on the Manaslu circuit trail is Tibetan or Nepalese.",
        },
        {
          modaltitle: "Nepali Flavors:",
          modaltext:
            "Nepali Flavors You should try dishes like bara, choila, sekuwa, taas, sukuti, chyang, gundruk, etc. Also, don't forget to try some Lapsi candies and the well-known juju dhau (local yogurt found in Bhaktapur).",
        },
        {
          modaltitle: "The Best Food",
          modaltext:
            "The staple Nepali meal dal bhat will be our go-to food while trekking or in Kathmandu. It has steamed rice, lentil soup, vegetables, curry made with meat, pickles, and a few other sides. It has steamed rice, lentil soup, vegetables, curry made with meat, pickles, and a few other sides.",
        },
        {
          modaltitle: "Suitability:",
          modaltext:
            "There are many options for vegans, vegetarians, and people who cannot eat gluten.",
        },
      ],
    },
    {
      id: 5,
      modaldetail: [
        // {
        //   modaltitle: "a:",
        //   modaltext:
        //     "abc",
        // },
      ],
    },
    {
      id: 6,
      modaldetail: [
        {
          modaltitle: "Overview:",
          modaltext:
            "Trek in the remote Manaslu region, which is full of great mountains, Tibetan Buddhism culture, and beautiful trails. The Manaslu circuit trek has been popular with travelers ever since it was first open to people from other countries in 1992. About 2,000 or more trekkers visit the route every year, which is a small number compared to other popular trekking routes in the Himalayas. Because of this, the Manaslu circuit trek is known for its quiet and unspoiled trail. On rough, steep mountain terrain, the trail goes up and down a lot. During acclimatization, you will enjoy side hikes to hidden places like Birendra Tal, Manaslu Base Camp, et. During acclimatization, you will enjoy side hikes to hidden places like Birendra Tal, Manaslu Base Camp, etc.",
        },
        {
          modaltitle: "Restaurants & Tea Shops:",
          modaltext:
            "There are many different kinds of restaurants in Kathmandu. Some serve many different kinds of food, while others focus on just one. You can try a variety of dishes at traditional Nepali restaurants. On the Manaslu circuit trail, tea shops sell only a few snacks and drinks. You should not depend on them and instead buy your favorite snacks in Kathmandu.",
        },
        {
          modaltitle: "ATM/Currency:",
          modaltext:
            "On the  Manaslu Circuit trail, you will not  find a single ATM. Get a lot of Nepali money in Kathmandu so you can use it on the trek. Keep in mind that the service providers along the way do not accept any kind of card or foreign currency.",
        },
        {
          modaltitle: "Internet:",
          modaltext:
            "On the Manaslu circuit trek, it's best not to count on getting WiFi. Even if it is available in some villages, the connection may not be good and you may have to pay more. Buy a Nepalese SIM card that has data on it so you can use the internet while trekking.",
        },
        {
          modaltitle: "Internet:",
          modaltext:
            "It's better not to rely on getting WiFi on the Manaslu circuit trek. Even if it is available in some villages, the internet connection may not be good, and you may have to pay a few extra bucks. Buy a Nepali SIM with data and use it for the internet while trekking.",
        },
        {
          modaltitle: "Flora and Fauna:",
          modaltext:
            "There are 19 different kinds of forests and climate zones in the Manaslu area. During the Manaslu circuit trek, the path you'll be on goes through lush forests and up to high pastures. There are 201 kinds of birds, 39 kinds of mammals, 11 kinds of butterflies, and 3 kinds of reptiles in the area. In the same way, the bioclimatic zone can be subtropical or Nival. During trekking, you'll see many different kinds of plants and animals.",
        },
        {
          modaltitle: "Locals:",
          modaltext:
            "The people who live in the Manaslu area are friendly and open. You'll feel right at home and moved by how humble they are. The main groups of people in the Manaslu region are Nubri and Tsum. You meet the Nubri group on the 15-day Manaslu circuit trek. Gurungs, Sherpas, and Bhotias live near the bottom of the trail. Most people in the Manaslu area believe in Tibetan Buddhism, which is the main religion there.",
        },
        {
          modaltitle: "Language:",
          modaltext:
            "The people who live there speak a different language than what you may have heard in Kathmandu. Each group of people has its own language and way of speaking. The people who run the teahouses and tea shops along the trail can only speak and understand broken English. You can talk to them with the help of our mountain guide.",
        },
        {
          modaltitle: "Monasteries:",
          modaltext:
            "The Manaslu circuit trek is also a great way to learn about the culture of the area. The trek teaches a lot about the locals' beliefs and their ancient ways of life. You'll see some monasteries that are hundreds of years old, like the Pun Gyen Gompa. Aside from this one, you will see many small stupas, chortens, prayer flags, and mani walls along the way.",
        },
      ],
    },
  ];
  const expandHandler = () => {
    setExpandModal(!expandModal);
  };

  const collapseHandler = () => {
    setExpandModal(false);
  };

  const onTripChange = () => {
    sessionStorage.removeItem("tripTitle");
    sessionStorage.setItem("tripTitle", tripTitle);
    sessionStorage.setItem("tripPrice", tripPrice);
  };

  return (
    <>
      <div className={`modals ${expandModal ? "expand" : ""}`}>
        <div className={`modal-head `} onClick={expandHandler}>
          <div className="modal-head-title">
            {!expandModal ? (
              <AiOutlineArrowUp fontSize="2rem" className="px-1" />
            ) : (
              <AiOutlineArrowDown fontSize="2rem" className="px-1" />
            )}
            <div className="head-title">{modalhead}</div>
          </div>
          <div className="iclose">
            {expandModal && (
              <AiOutlineCloseCircle
                fontSize="1.3rem"
                onClick={collapseHandler}
              />
            )}
          </div>
        </div>
        <div className={`modal-body ${expandModal ? "modal-body-expand" : ""}`}>
          {!expandModal ? (
            <>
              {modaldetail &&
                modaldetail?.map((detail) => (
                  <div className="modal-content">
                    <div className="modal-title d-none d-sm-flex">
                      {detail.modaltitle}
                    </div>
                    <div className="modal-text">{detail.modaltext}</div>
                  </div>
                ))}
            </>
          ) : (
            <>
              {modaldetailtrip &&
                modaldetailtrip[id - 1]?.modaldetail?.map((detail) => (
                  <div className="modal-content">
                    <div className="modal-title fw-bold">
                      {detail.modaltitle}
                    </div>
                    <div className="modal-text">{detail.modaltext}</div>
                  </div>
                ))}
            </>
          )}
        </div>
        <div className="modal-book">
        <HashLink
          smooth
          to="/booking"
          className="modal-book link p-0"
          style={{ color: "#000" }}
          onClick={onTripChange}
        >
          BOOK NOW 
        </HashLink>
        <ManasluCircuitTrekDepartureDateModalTripInfo/>
        </div>
      </div>
    </>
  );
};

export default Modals;
