import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import CancelIcon from "@mui/icons-material/Cancel";


const TripDetail = () => {
  return (
    <>
      <div className="trip-detail container mt-5">
        <div className="row">
          <div className="col-2 d-none d-lg-block d-xl-block">
            <div className="trip-sidebar">
              <ul>
                <li>
                  <a href="#price">Price Detail</a>
                </li>
                <li>
                  <a href="#highlight">Highlight</a>
                </li>
                <li>
                  <a href="#include">Cost Include</a>
                </li>
                <li>
                  <a href="#exclude">Cost Exclude</a>
                </li>
                <li>
                  <a href="#disclaimer">Disclaimer of Itinerary</a>
                </li>
                <li>
                  <a href="#important-note">Important Note</a>
                </li>
                <li>
                  <a href="#safety">Safety and Security</a>
                </li>
                <li>
                  <a href="#insurance">Travel Insurance</a>
                </li>
                <li>
                  <a href="#age">Minimum Age</a>
                </li>
                <li>
                  <a href="#time">Best Time</a>
                </li>
                <li>
                  <a href="#tipping">Tipping</a>
                </li>
                <li>
                  <a href="#activities">Activities You Can Choose</a>
                </li>
                <li>
                  <a href="#group-leader">Group Leader - What They Do</a>
                </li>
                <li>
                  <a href="#group-notes">Size of Group Notes</a>
                </li>
                <li>
                  <a href="#transport">Transport</a>
                </li>
                <li>
                  <a href="#local-flight">Local Flight</a>
                </li>
                <li>
                  <a href="#solo-traveler">Solo Travelers</a>
                </li>
                <li>
                  <a href="#joining">Joining Instructions</a>
                </li>
                <li>
                  <a href="#arrival">Arrival Complications</a>
                </li>
                <li>
                  <a href="#emergency-contact">Emergency Contanct</a>
                </li>
                <li>
                  <a href="#visa">Visa and Entry Requirement</a>
                </li>
                <li>
                  <a href="#money-exchange">Money Exchange</a>
                </li>
                <li>
                  <a href="#payment-process">Payment Process</a>
                </li>
                <li>
                  <a href="#cancellation-policy">Cancellation Policy</a>
                </li>
                <li>
                  <a href="#emergency-fund">Emergency Fund</a>
                </li>
                <li>
                  <a href="#equipment">Trekking Equipment</a>
                </li>
              </ul>
            </div>
          </div>

          {/*---------------------- price starts --------------------- */}
          <div className="trip-info col-md-12 col-lg-10">
            <h4 id="price" className="trip-detail-title">
              Price Detail
            </h4>
            Luxury Price: USD $1290 pp <br />
            Budger Price: USD $ 1090 pp <br />
            {/* <h4 className="trip-detail-title">Group Discount</h4>
            1-2 pax --------- USD $1290
            <br />
            upto 5 pax ---- USD $1360
            <br />
            upto 10 pax ---- USD $1245 */}
            {/* ---------------- trip highlight starts --------------------- */}
            <h4 id="highlight" className="trip-detail-title">
              Highlights
            </h4>
            <ul className="trip-highlight">
              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>
                  Trek to the base of Mt. Everest, the highest mountain in the
                  world
                </li>
              </span>
              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>The thrilling flight between Kathmandu and Lukla</li>
              </span>
              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>
                  Explore the beautiful mountain village, rushing rivers,
                  cascading waterfalls, verdant valleys, lush forests, streams,
                  and glacial moraines of the Khumbu region
                </li>
              </span>
              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>Get to see the culture and lifestyle of the Sherpas</li>
              </span>
              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>Visit ancient monasteries along the way</li>
              </span>
              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>Unwind and enjoy breathtaking landscapes</li>
              </span>
              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>Witness gorgeous sunrise from Kala Patthar</li>
              </span>
              <span>
                <div className="trip-highlight-icon">
                  <FaCheckCircle />
                </div>
                <li>
                  Outstanding Himalayan mountains views throughout the trail
                </li>
              </span>
            </ul>
            {/*---------------------- cost include starts --------------------- */}
            <h4 id="include" className="trip-detail-title">
              Cost Include
            </h4>
            <ul className="cost-include">
                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>
                    Private car service to and from the airport.
                    </li>
                  </span>
                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>
                    Two nights at a standard hotel in Kathmandu with breakfast for two people sharing a room.
                    </li>
                  </span>
                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>
                    You will get all three meals, including tea: breakfast, lunch, and dinner.
                    </li>
                  </span>
                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>
                    During the trek, you will be provided with three meals a day: breakfast, lunch, and dinner with one cup of tea. 
                    </li>
                  </span>
                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>
                    During the trek, you will be provided with Trekking Lodges (Tea Houses).
                    </li>
                  </span>
                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>
                    All papers that need to be done, Fees for entering Sagarmatha National Park and Khumbu Pasang Lhamu Rural Municipality
                    </li>
                  </span>
                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>Flight from Kathmandu to Lukla and back to Kathmandu, with a private airport transfer and airport tax within Nepal.</li>
                  </span>
                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>A highly experienced, helpful, knowledgeable, friendly, English-speaking guide with all of his salary, food, drinks, lodging, transportation, and insurance covered.</li>
                  </span>
                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>Strong, helpful porters with the right safety gear and walking gear, as well as a salary, food, a place to live, and insurance. (one porter for two people).</li>
                  </span>
                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>Setting up a service for an emergency helicopter (paid by your Travel Insurance company).</li>
                  </span>
                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>You can use a sleeping bag, a down jacket, and a duffel bag (if you don't have your own).</li>
                  </span>
                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>Taxes and official costs paid to the government.</li>
                  </span>
                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>Everest base camp trekking map.</li>
                  </span>
                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>A map of a trek and a certificate of completion of the trip</li>
                  </span>
                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>Oxymeter to check your pulse, oxygen saturation, and heart rate twice a day (very helpful to check for signs of Altitude Mountain Sickness, or AMS). This will make sure that your trek is in the safest hands possible, no matter how experienced you are.</li>
                  </span>
                  <span>
                    <div className="cost-include-icon">
                      <FaCheckCircle />
                    </div>
                    <li>Assistant guide group size above 10+ people.</li>
                  </span>
                </ul>
            {/*---------------------- cost exclude starts --------------------- */}
            <h4 id="exclude" className="trip-detail-title">
              Cost Exclude
            </h4>
            <ul className="cost-exclude"><span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>Food that you will eat in Kathmandu for lunch and dinner.</li>
                  </span>
                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>The cost of a visa to enter Nepal is $30 USD for a 15-day stay.</li>
                  </span>
                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>Your insurance for travel and medical care.</li>
                  </span>
                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>International Airfare.</li>
                  </span>
                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>Your Personal Expenses.</li>
                  </span>
                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>
                    All of the alcoholic and non-alcoholic cold and hot drinks you buy on trek (along the way and in the Tea Houses at night). 
                    </li>
                  </span>
                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>All of the sweet desserts, like chocolate, cake, pie, and pudding.</li>
                  </span>
                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>The tea houses have hot showers and places to charge your phone which will cost you about 2$ to 5$</li>
                  </span>
                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>Tip  to the guide, the porter, and the driver. (Tipping is expected)</li>
                  </span>
                  <span>
                    <div className="cost-exclude-icon">
                      <CancelIcon />
                    </div>
                    <li>More than 15 kg of extra luggage for the flight to Lukla.</li>
                  </span>
                </ul>
            {/*---------------------- Disclaimer of Itinerary starts --------------------- */}
            <h4 id="disclaimer" className="trip-detail-title">
              Disclaimer of Itinerary
            </h4>
            <p className="trip-detail-content">
            We do our best to stick to the schedule we have laid out for the Everest Base Camp trek. We may have to change the route, though, if the situation calls for it.  Therefore, because of the nature of the trip and how far away it is, anything could happen. During a trek, if something comes up that changes the plan, the trek leader will make decisions that are best for the group.
            </p>
            {/*---------------------- important notes starts --------------------- */}
            <h4 id="important-note" className="trip-detail-title">
              Points to be noted
            </h4>
            <p className="trip-detail-content">
            Here are some things you should know before going on the Everest Base Camp trek:
            </p>
            <ul>
              <li>
              There is a chance of getting altitude sickness on the trek. So, talk to your doctor and find out what might happen to your body at high altitudes.
              </li>
              <li>
              The Everest base camp trek has a lot of modern facilities, which makes it easier to hike than other trails.
              </li>
              <li>Altitude sickness can be avoided by drinking a lot of water.</li>
              <li>
              Do not go hiking in brand-new hiking boots. You will get blisters, which will make it hard for you to walk.
              </li>
              <li>
              You can go to the Everest base camp trek in the off-season as well if you are ready. During the off-season, prices are low and the trail is not crowded.
              </li>
              <li>
              You can make changes to the schedule to fit your needs.
              </li>
              <li>
              Have extra money so you can pay for things like hot showers, charging stations, WiFi, etc (not covered in the package cost).
              </li>
            </ul>
            {/*---------------------- safety and security starts --------------------- */}
            <h4 id="safety" className="trip-detail-title">
              Safety and Security
            </h4>
            <p className="trip-detail-content">
            Adventure Master Trek is a trekking company with a license. Therefore, you can have a safe and pleasant trip with us. We have a safe way for people to pay. So, you can trust us with your money and time. Our team has worked in this field before, so all treks go smoothly. Also, you will not have to worry about food, water, or a hotel room.
            </p>
            <p className="trip-detail-content">
            During your Everest base camp trek, the leader of your group will look out for your safety. The trek leader has a satellite phone with him in case he needs to get in touch with the city office. Our guide will keep us informed about the trek all the time. Therefore, your safety is our top priority.
            </p>
            <p className="trip-detail-content">
            Also, the whole team has been immunized against Covid-19. Therefore, there is no emergency on a national scale, and the country is open to people from all over the world. Most people have had their first or second dose of the Covid-19 vaccine, so it is safe to go on the trek.
            </p>
            {/*---------------------- safety and security starts --------------------- */}
            <h4 id="insurance" className="trip-detail-title">
              Travel Insurance
            </h4>
            <p className="trip-detail-content">
            To go on the Everest base camp trek, you need to have travel insurance. We do not accept a reservation if the person who wants to go does not have good travel insurance. You should know that the EBC trek is at a high altitude and in an area that has not been developed and is hard to get to. If there is a medical emergency on the trek that cannot be fixed with first aid, we will send the person by helicopter for emergency rescue.
            </p>
            <p className="trip-detail-content">
            In case of an emergency, your travel insurance should pay for your medical bills and get you to a safe place by air. You can also add flight cancellation and loss or theft of personal items to your travel insurance if you want to. Nepal does not offer travel insurance to people from other countries, so you must get it before you leave.
            </p>
            {/*---------------------- minimum age starts --------------------- */}
            <h4 id="age" className="trip-detail-title">
              Minimum Age
            </h4>
            <p className="trip-detail-content">
            To join our trek on your own and without a guardian, you must be 18 years old. Those younger than this age (up to 12 years old) must hike with a known guardian. Everest Base Camp is the highest point in the world, so it is not safe for kids under 5 to hike there.
            </p>
            {/*---------------------- best time starts --------------------- */}
            <h4 id="time" className="trip-detail-title">
              Best Time
            </h4>
            <p className="trip-detail-content">
            Spring and fall are the best times to go hiking to Everest base camp. Therefore, it is the best time to trek or climb in the Himalayas, and the weather is most stable. From March to April and October to November, the weather is nice and clear.
            </p>
            <p className="trip-detail-content">
            During the day, it is sunny and windy, and you can see the mountains clearly. In Autumn, the air smells cold and crisp, and in Spring, it smells warm. The temperature can be anywhere between 5°C and 18°C. The mornings and nights are not too warm.
            </p>

            {/*---------------------- tipping starts --------------------- */}
            <h4 id="tipping" className="trip-detail-title">
              Tipping
            </h4>
            <p className="trip-detail-content">
            The cost of the Everest base camp trek does not include tips. We let our guests decide how much to tip the people on our field crew. Tipping is more of a cultural thing than something you have to do. But we recommend tipping to show your appreciation for your team's hard work, and it is best to tip as a group.
            </p>
            {/*---------------------- Activities You Can Choose starts --------------------- */}
            <h4 id="activities" className="trip-detail-title">
              Activities that you can choose
            </h4>
            <p className="trip-detail-content">
            You can take part in a lot of different things here. First, if you are fit enough, you can extend your trek in the Khumbu region to Gokyo Lakes or Three Passes. Our Everest Panorama Trek, Pikey Peak Trek, and Everest base camp helicopter tour are all good options for travelers with limited time.
            </p>
            <p className="trip-detail-content">
            Annapurna Base Camp Trek, Langtang Valley Trek, Mardi Himal Trek, Khopra Danda Trek, and Ghorepani Poon Hill Trek are some other treks that are about the same level of difficulty.
            </p>
            <p className="trip-detail-content">
            We also have many tour packages, jungle safaris, and outdoor activities like paragliding, bungee jumping, mountain biking, skiing, rafting, mountain flights, etc.
            </p>
            {/*---------------------- Activities You Can Choose starts --------------------- */}
            <h4 id="group-leader" className="trip-detail-title">
              Group Leader - What They Do
            </h4>
            <p className="trip-detail-content">
            You hike with a strong group of guides and porters. Depending on the size of the group, we may also send a second guide. Our guides have been trained well and have been trekking for many years. Accordingly, they have led groups from all over the world to the base camp of Mt. Everest an uncountable number of times, so you can feel free to trek with us.
            </p>
            <p className="trip-detail-content">
            Our guides have licenses for trekking, survival in the wilderness, first aid, and other skills. On your trip to Nepal, we take care of everything. We promise that your trek through the Himalayas will be safe and enjoyable to the fullest. We are one of the most reliable and experienced local trekking companies in Nepal, and we offer the best itineraries, prices, and service.
            </p>
            {/*---------------------- Size of Group Notes starts --------------------- */}
            <h4 id="group-notes" className="trip-detail-title">
              Size of Group Notes
            </h4>
            <p className="trip-detail-content">
            We organize private treks for solo travelers who prefer to trek alone and take their time. Likewise, our group size usually ranges between 2 to 12 trekkers. If you are planning a Everest base camp trek  in a group bigger than 12 people, we suggest you share your requirements with us. We will organize the perfect Everest base camp trek for you.
            </p>
            {/*---------------------- transport starts --------------------- */}
            <h4 id="transport" className="trip-detail-title">
              Transport
            </h4>
            <p className="trip-detail-content">
            We use a private car to pick up and drop off people at the airport. Similarly, we use a private vehicle for the sightseeing tour (if any). The Everest base camp trek includes a short domestic flight between the Kathmandu and Lukla airport.
            </p>
            {/*---------------------- local flights starts --------------------- */}
            <h4 id="local-flight" className="trip-detail-title">
              Local flight
            </h4>
            <p className="trip-detail-content">
              During the Everest base camp trekking, you take a local flight
              from Kathmandu to Lukla airport. It's a 35 minutes flight. Lukla
              flights are very famous because of the airport location. If you
              are trekking in the peak season (Spring & Autumn), the flights are
              usually on time. There can be some delays or cancellations if the
              weather gets unstable. <br />
              Likewise, in off-seasons (winter & summer/monsoon), we cannot
              guarantee a fixed flight because of unstable weather. There is
              also the option of helicopter flight. Of course, it is much more
              comfortable and has less chance of delays/cancellations, even
              during off-seasons. You can book a private helicopter flight to
              Lukla or join a group departure. <br />
              Many trekkers these days are opting for a helicopter flight back
              to Kathmandu from Kala Patthar because of the sensational aerial
              panorama of the Himalayas and the time-saving.
            </p>
            {/*---------------------- solo travelers starts --------------------- */}
            <h4 id="solo-traveler" className="trip-detail-title">
              Solor Travelers
            </h4>
            <p className="trip-detail-content">
            Our Everest base camp trekking package is open to people who are traveling alone. You can join one of our groups or go on a private trek, depending on what you want. The Everest base camp trek is led by an experienced guide who knows how to handle the group, knows the trail, and looks out for everyone's safety.
            </p>
            <p className="trip-detail-content">
            If you do not want a guide or a porter, you can trek on your own. Every year, there are a fair number of solo trekkers on the Everest Base Camp Trail. Make sure you know about all the obstacles, have everything you need, a good map or compass, a place to stay booked ahead of time, and all the necessary permits.
            </p>
            {/*---------------------- joining instructions starts --------------------- */}
            <h4 id="joining" className="trip-detail-title">
              Joining Instructions
            </h4>
            <p className="trip-detail-content">
            You can join the 14-day trek to Everest Base Camp by sending us an inquiry. On our package page, you can find a very simple way to book the trek in just a few minutes.
            </p>
            <p className="trip-detail-content">
            Besides that, Everest Base Camp Trek 2023 Autumn and EBC Trek 2023 Spring/Autumn bookings are now open. You can send us a request to book here, or if you have any questions, you can talk to our travel consultant at <a href="mailto:info@adventuremastertrek.com">info@adventuremastertrek.com</a>
            </p>
            {/*---------------------- arrival complications starts --------------------- */}
            <h4 id="arrival" className="trip-detail-title">
              Problems with arrival
            </h4>
            <p className="trip-detail-content">
            If you cannot get to Kathmandu in time for the trip, let us know as soon as possible. We will postpone the trip because you requested us to. You can call the emergency number we have given you and tell the team about your situation. You may also have to pay some cancellation fees, depending on when you tell us.
            </p>
            <p className="trip-detail-content">
            We will do our best to give you a satisfying alternative. Also, if you could not find our airport representative, you could use the free WIFI at the airport to call the number they gave you. You can call us and we will get you a taxi to the hotel.
            </p>
            {/*---------------------- emergency contact starts --------------------- */}
            <h4 id="emergency-contact" className="trip-detail-title">
              Emergency Contact
            </h4>
            <p className="trip-detail-content">
            You can call us at any time at our emergency numbers. These numbers can also be used for WhatsApp and Viber. These are the numbers to call if you need to reach us in an emergency: +977 9851033195, +44 7983806200, and +977 9849780311. You can even call us during office hours at our office number. (Monday - Friday, 9 a.m.–5 p.m. local time)
            </p>
            {/*---------------------- visa and entry requirements starts --------------------- */}
            <h4 id="visa" className="trip-detail-title">
              Visa and Entry Requirements
            </h4>
            <p className="trip-detail-content">
            People who want to go to Nepal need a valid passport that is still valid for a minimum of six months and a Visa. Most countries can get a visa when they arrive in Nepal. This is true at Tribhuvan International Airport and other entry points. Make sure to find out if your country can get a visa when you arrive or not. If not, you can apply for a travel visa at Nepal's embassy or consulate in your home country.
            </p>
            <p className="trip-detail-content">
            The traveler is responsible for getting a visa. Check all of the visa requirements and make sure you have the right papers with you. We can send you a letter confirming your trip. If you don't get a visa, the company won't be responsible for that.
            </p>
            {/*---------------------- money exchange starts --------------------- */}
            <h4 id="money-exchange" className="trip-detail-title">
              Money Exchange
            </h4>
            <p className="trip-detail-content">
            You must use Nepali money in Nepal. There are many banks and places to exchange money in Kathmandu where you can get a good rate. You can use your international cards at most ATMs in Kathmandu to get cash.
            </p>
            <p className="trip-detail-content">
            Most hotels, restaurants, cafes, shops, entertainment centers, and other places in Kathmandu accept Visa, Mastercard, and other credit cards. You will not be able to get cash out during the Everest Base Camp trek, so get it in Kathmandu.
            </p>
            {/*---------------------- payment process starts --------------------- */}
            <h4 id="payment-process" className="trip-detail-title">
              Payment process
            </h4>
            <p className="trip-detail-content">
            You need to pay a 25% deposit up front. If you pay with a credit card, you might have to pay an extra 4% charge.
            </p>
            {/*---------------------- cancellation policy starts --------------------- */}
            <h4 id="cancellation-policy" className="trip-detail-title">
              Cancellation policy
            </h4>
            <p className="trip-detail-content">
            The cancellation fees are calculated as a percentage of the total tour price:<br/>
Cancellation one week before departure: 15% of full payment will be deducted<br/>
Cancellation on the same day: 25% of full payment will be deducted<br/>
            </p>
            {/*---------------------- emergency fund starts --------------------- */}
            <h4 id="emergency-fund" className="trip-detail-title">
              Emergency Fund
            </h4>
            <p className="trip-detail-content">
            Set aside some money in case you need to stay longer in Nepal, pay for something you did not expect to need, or spend more than you planned on shopping. This emergency fund will hold you back and give you the freedom to travel without worrying.
            </p>
            <h4 id="equipment" className="trip-detail-title">
              List of Trekking Equipment for Everest Base Camp Trek
            </h4>
            <p className="trip-detail-content">
            For trekking to Everest base camp, you will need the following. You can rent or buy most of the gear mentioned in Thamel, Kathmandu.
            </p>
            <strong>Head and Hand</strong>
            <ul>
              <li>Sun Cap </li>
              <li>Wool/Fleece Hat </li>
              <li>Balaclava </li>
              <li>Neck Gaiter/High Neck</li>
              <li>Lightweight Synthetic Liner Glove </li>
              <li>Wind Stopper Fleece Gloves </li>
            </ul>
            <strong>Upper Body</strong>
            <ul>
              <li>Short-Sleeved Shirts/T-shirts </li>
              <li>Lightweight Top/Thermo Coat </li>
              <li>Synthetic or Fleece Jacket </li>
              <li>Down Insulated Jacket </li>
            </ul>
            <strong>Lower Body</strong>
            <ul>
              <li>Underwear</li>
              <li>Lightweight Long Underpants </li>
              <li>Midweight Long Underpants </li>
              <li>Trekking Pants </li>
              <li>Trekking Socks </li>
              <li>Sandals </li>
              <li>Lightweight Hiking/Trekking Boots </li>
            </ul>
            <strong>Toiletries</strong>
            <ul>
              <li>Hand Sanitizers & Hand wash </li>
              <li>Wet Tissues </li>
              <li>Toothpaste </li>
              <li>Toothbrush </li>
              <li>Bath Soap </li>
              <li>Shampoo</li>
              <li>Moisturizers </li>
              <li>Laundry Soap</li>
              <li>Garbage Bag </li>
              <li>Eye Drops </li>
              <li>Zip-Lock Bags</li>
              <li>Sunscreen (-50 Spf)</li>
              <li>Lips Guard (-20/-50 Spf) </li>
              <li>Water Purification Tablets </li>
              <li>Baby Wipes or Wet Towels </li>
            </ul>
            <strong>Miscellaneous</strong>
            <ul>
              <li>Swiss Knife </li>
              <li>Rucksacks (45 - 55 Ltr.) </li>
              <li>1 Duffel Bag (provided by the company)</li>
              <li>Trekking poles</li>
              <li>Sleeping bag</li>
              <li>Torchlight </li>
              <li>Water Bottles </li>
              <li>Thermos/Flask </li>
              <li>Pack Towel </li>
              <li>Spare batteries </li>
              <li>Waterproof Stuff Sacks </li>
              <li>Satellite Phone (if possible)</li>
              <li>Solar chargeable power bank (optional)</li>
              <li>Journal/Pen</li>
              <li>Book/Board Game</li>
              <li>Bars & Snacks</li>
            </ul>
            <strong>Travel Document</strong>
            <ul>
              <li>Valid Passport </li>
              <li>Valid Visa </li>
              <li>4 PP Size Photo </li>
              <li>Insurance Paper (Medical & Rescue) & Contact Address </li>
              <li>Family/Company Contact Number & Address </li>
              <li>Credit Card</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default TripDetail;
