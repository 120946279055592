import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import CancelIcon from "@mui/icons-material/Cancel";

const TripDetail = () => {
  return (
    <>
      <div className='trip-detail container mt-5'>
        <div className='row'>
          <div className='col-2 d-none d-lg-block d-xl-block'>
            <div className='trip-sidebar'>
              <ul>
                <li>
                  <a href='#price'>Price Detail</a>
                </li>
                <li>
                  <a href='#highlight'>Highlight</a>
                </li>
                <li>
                  <a href='#include'>Cost Include</a>
                </li>
                <li>
                  <a href='#exclude'>Cost Exclude</a>
                </li>
                <li>
                  <a href='#disclaimer'>Disclaimer of Itinerary</a>
                </li>
                <li>
                  <a href='#important-note'>Important Note</a>
                </li>
                <li>
                  <a href='#safety'>Safety and Security</a>
                </li>
                <li>
                  <a href='#insurance'>Travel Insurance</a>
                </li>
                <li>
                  <a href='#age'>Minimum Age</a>
                </li>
                <li>
                  <a href='#time'>Best Time</a>
                </li>
                <li>
                  <a href='#tipping'>Tipping</a>
                </li>
                <li>
                  <a href='#activities'>Activities You Can Choose</a>
                </li>
                <li>
                  <a href='#group-leader'>Group Leader - What They Do</a>
                </li>
                <li>
                  <a href='#group-notes'>Size of Group Notes</a>
                </li>
                <li>
                  <a href='#transport'>Transport</a>
                </li>
                <li>
                  <a href='#local-flight'>Local Flight</a>
                </li>
                <li>
                  <a href='#solo-traveler'>Solo Travelers</a>
                </li>
                <li>
                  <a href='#joining'>Joining Instructions</a>
                </li>
                <li>
                  <a href='#arrival'>Arrival Complications</a>
                </li>
                <li>
                  <a href='#emergency-contact'>Emergency Contanct</a>
                </li>
                <li>
                  <a href='#visa'>Visa and Entry Requirement</a>
                </li>
                <li>
                  <a href='#money-exchange'>Money Exchange</a>
                </li>
                <li>
                  <a href='#emergency-fund'>Emergency Fund</a>
                </li>
                <li>
                  <a href='#first-aid'>First-Aid Kit</a>
                </li>
                <li>
                  <a href='#package-list'>Package List</a>
                </li>
              </ul>
            </div>
          </div>

          <div className='trip-info col-md-12 col-lg-10'>
            {/* ---------------- price starts --------------------- */}
            <h4 id='price' className='trip-detail-title'>
              Price Detail
            </h4>
            Trip Price: USD $2650 pp
            {/* <h4 className="trip-detail-title">Group Discount</h4>
            Upto 5 people --------- USD $1245
            <br />
            upto 10 people ---- USD $1120
            <br /> */}
            {/* ---------------- trip highlight starts --------------------- */}
            <h4 id='highlight' className='trip-detail-title'>
              Highlights
            </h4>
            <ul className='trip-highlight'>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  <strong>Summit a 6,476 meter (21,246 ft) peak:</strong> Mera
                  peak is the highest trekking peak in Nepal, offering a
                  challenging and rewarding climb for experienced trekkers.
                </li>
              </span>

              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  <strong>
                    Explore the diverse landscapes of the Solu-Khumbu region:
                  </strong>{" "}
                  The trek to Mera peak passes through a variety of landscapes,
                  including lush forests, alpine meadows, and glacial valleys.
                </li>
              </span>

              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  <strong>
                    Experience the culture and way of life of the Sherpa people:
                  </strong>{" "}
                  The trek to Mera peak passes through several traditional
                  Sherpa villages, allowing you to learn about and interact with
                  the local culture.
                </li>
              </span>

              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  <strong>Take in breathtaking views of the Himalayas:</strong>{" "}
                  From the summit of Mera peak, you can enjoy panoramic views of
                  several of the highest peaks in the world, including Mount
                  Everest, Lhotse, and Cho Oyu.
                </li>
              </span>
            </ul>
            {/*---------------------- cost include starts --------------------- */}
            <h4 id='include' className='trip-detail-title'>
              Cost Include
            </h4>
            <ul className='cost-include'>
              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  All ground travel, including getting to and from the airport,
                  is done in a private car.
                </li>
              </span>

              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  One night accomodation at a standard hotel in Kathmandu with
                  breakfast included as per the itinerary.
                </li>
              </span>

              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  During the trek, you will be provided with three meals a day:
                  breakfast, lunch, and dinner with one cup of tea each meal.
                </li>
              </span>

              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Full board accommodation of lodges or guest houses in twin
                  sharing based during the trek, and tents will be available
                  during the climbing period.
                </li>
              </span>

              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>All camping equipment in base camp.</li>
              </span>

              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Flight ticket from Kathmandu-Lukla-Kathmandu for members and
                  staff his salary, food, drinks, lodging, transportation, and
                  insurance covered.
                </li>
              </span>

              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Strong, helpful porters with the right safety gear and walking
                  gear, as well as a salary, food, a place to live, and
                  insurance. (one porter for two people).
                </li>
              </span>

              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  A map of a trek and a certificate of completion of the trip.
                </li>
              </span>

              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>Taxes and official costs paid to the government.</li>
              </span>

              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  All papers that need to be done, Fees for entering permits you
                  need to go while hiking.
                </li>
              </span>

              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>Duffle bag for trekking.</li>
              </span>

              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  First aid kit- Oxymeter to check your pulse, oxygen
                  saturation, and heart rate twice a day (very helpful to check
                  for signs of Altitude Mountain Sickness, or AMS). This will
                  make sure that your trek is in the safest hands possible, no
                  matter how experienced you are.
                </li>
              </span>
              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Necessary fixed and dynamic ropes will be available during the
                  climbing period.
                </li>
              </span>
              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  The necessary kitchen crew will be available in the base camp.
                </li>
              </span>
              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Complete pre-departure information, flight ticket
                  reconfirmation, and extend visa service(if necessary).
                </li>
              </span>
              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>Farewell Dinner in Kathmandu before departure.</li>
              </span>
            </ul>
            {/*---------------------- cost exclude starts --------------------- */}
            <h4 id='exclude' className='trip-detail-title'>
              Cost Exclude
            </h4>
            <ul className='cost-exclude'>
              <span>
                <div className='cost-exclude-icon'>
                  <CancelIcon />
                </div>
                <li>
                  Food that you will eat in Kathmandu for lunch and dinner.
                </li>
              </span>

              <span>
                <div className='cost-exclude-icon'>
                  <CancelIcon />
                </div>
                <li>
                  <p>
                    The cost of a visa to enter Nepal is
                    <br />
                    $30 USD for a 15-day stay,
                    <br />
                    30 Days - $50,
                    <br />
                    90 Days - $125
                  </p>
                </li>
              </span>

              <span>
                <div className='cost-exclude-icon'>
                  <CancelIcon />
                </div>
                <li>Your insurance for travel and medical care.</li>
              </span>

              <span>
                <div className='cost-exclude-icon'>
                  <CancelIcon />
                </div>
                <li>International airfare.</li>
              </span>

              <span>
                <div className='cost-exclude-icon'>
                  <CancelIcon />
                </div>
                <li>Your personal expenses.</li>
              </span>

              <span>
                <div className='cost-exclude-icon'>
                  <CancelIcon />
                </div>
                <li>
                  All of the alcoholic and non-alcoholic cold and hot drinks you
                  buy on trek (along the way and in the Tea Houses at night).
                </li>
              </span>

              <span>
                <div className='cost-exclude-icon'>
                  <CancelIcon />
                </div>
                <li>
                  All of the sweet desserts, like chocolate, cake, pie, and
                  pudding.
                </li>
              </span>

              <span>
                <div className='cost-exclude-icon'>
                  <CancelIcon />
                </div>
                <li>
                  The tea houses have hot showers and places to charge your
                  phone which will cost you about 2$ to 5$.
                </li>
              </span>

              <span>
                <div className='cost-exclude-icon'>
                  <CancelIcon />
                </div>
                <li>
                  Tip to the guide, the porter, and the driver. (Tipping is
                  expected).
                </li>
              </span>
            </ul>
            {/*---------------------- Disclaimer of Itinerary starts --------------------- */}
            <h4 id='disclaimer' className='trip-detail-title'>
              Disclaimer of Itinerary
            </h4>
            <p className='trip-detail-content'>
              Our 18 days Mera peak climbing program may see changes in the
              course of unseen circumstances. We will follow the proposed
              itinerary unless the situation demands an alteration in it. You
              will be traveling in the Himalayas and its unpredictable
              environment, so it is wise to accept the fact that there can be a
              change in the plan. You have to be flexible and open-minded. Given
              the situation, the team leader will make the decisions in the best
              interest of the group.
            </p>
            {/*---------------------- important notes starts --------------------- */}
            <h4 id='important-note' className='trip-detail-title'>
              Important Notes
            </h4>
            <p className='trip-detail-content'>
              Climbing a peak in Nepal can be a challenging and rewarding
              experience, but it's essential to be prepared and to follow safe
              practices to ensure a successful and enjoyable trip. Here are a
              few tips for climbing a peak in Nepal:
            </p>
            <ul>
              <li>
                <strong>Get in shape:</strong> Physical fitness is a crucial
                factor in your ability to successfully climb a peak. Make sure
                to train and build up your endurance before your trip.
              </li>
              <li>
                <strong>Pack wisely:</strong> Bring all the necessary gear and
                clothing for the climb, including warm and waterproof clothing,
                sturdy boots, a helmet, and a harness.
              </li>
              <li>
                <strong>Follow your guide's instructions:</strong> Your guide
                will have extensive knowledge and experience in mountain
                climbing. Follow their instructions and guidance to ensure your
                safety.
              </li>
              <li>
                <strong>Stay hydrated:</strong> Dehydration can be a serious
                problem at high altitudes. Make sure to drink plenty of water
                and avoid alcohol and caffeine.
              </li>
              <li>
                <strong>Take breaks and rest when needed:</strong> Climbing at
                high altitudes can be physically demanding. Make sure to take
                breaks and rest when needed to conserve your energy.
              </li>
              <li>
                <strong>Listen to your body:</strong> If you are feeling unwell
                or experiencing symptoms of altitude sickness, it's vital to
                listen to your body and take appropriate action. Don't push
                yourself beyond your limits.
              </li>
            </ul>
            {/*---------------------- safety and security starts --------------------- */}
            <h4 id='safety' className='trip-detail-title'>
              Safety and Security
            </h4>
            <p className='trip-detail-content'>
              Nepal is a very safe country to traverse solo or with your loved
              ones. With Adventure Master Treks, you will be in safe hands. We
              will arrange everything for your trip with us to Nepal. You do not
              have to worry about anything, including accommodation or permits.
              Our team will take care of it. Moreover, we'll assign you one of
              our team members who will take care of your requirements
              throughout your journey with us. <br />
              We are a team of travel enthusiasts and want to make the time of
              travelers coming to Nepal worth every second. Our professional
              team and numerous years of expertise make every venture very
              special. Our on-field crew takes care of your luggage and safety
              in the mountains. They are equipped with all the necessary gear
              that makes climbing or trekking very safe with us. We stay in
              touch with the group in the mountains, and make sure everything is
              going smoothly.
              <br />
              There are currently no nationwide emergencies related to Covid-19.
              The team has received the Covid-19 vaccine, and the country is
              welcoming travelers from all over the world. The majority of the
              population has received the first and second doses of the vaccine,
              so the trek/expedition is considered safe.
            </p>
            {/* ---------------------- covid 19 info starts ---------------------
            <h4 id="covid" className="trip-detail-title">
              Covid-19 2023 Travel Update in Nepal
            </h4>
            <p className="trip-detail-content">
              If you are worried about Covid-19, we want to inform you that
              everything is fine. The number of active cases is very low. Most
              of the citizens have taken a second dose of vaccines and booster
              doses. Even during the major crisis, there were a handful to no
              active cases in the Himalayas.
            </p> */}
            {/*---------------------- travel insurance starts --------------------- */}
            <h4 id='insurance' className='trip-detail-title'>
              Travel Insurance
            </h4>
            <p className='trip-detail-content'>
              It's important to be prepared for the possibility of medical
              emergencies, especially at high altitudes, where access to medical
              care may be limited.
              <br />
              You have to get travel insurance, which covers emergency
              evacuation, to join Mera peak climbing.
              <br />
              Some insurance policies may exclude certain types of high-risk
              activities, such as mountain climbing, or may have specific
              exclusions related to altitude. Be sure to carefully review the
              terms and conditions of any travel insurance policy before
              purchasing to ensure that it meets your needs and provides the
              coverage you need.
            </p>
            {/*---------------------- minimum age starts --------------------- */}
            <h4 id='age' className='trip-detail-title'>
              Minimum Age
            </h4>
            <p className='trip-detail-content'>
              It's important to consider whether you are physically and mentally
              prepared for the demands of high-altitude climbing or not. Mera
              Peak is a challenging climb that requires a good level of physical
              fitness and the ability to cope with the effects of altitude. It's
              important to be realistic about your abilities and to train
              appropriately before attempting the climb.
              <br />
              The minimum age for climbing Mera Peak in Nepal is typically 18
              years old. However, this can vary depending on the previous
              experience of the participant. Individuals below 18 years can also
              join Mera peak climbing with us under certain conditions. For more
              information, talk to our travel consultants.
            </p>
            {/*---------------------- best time starts --------------------- */}
            <h4 id='time' className='trip-detail-title'>
              Mera Peak Climbing Season
            </h4>
            <p className='trip-detail-content'>
              The best time to climb Mera Peak in Nepal is autumn (October to
              November) and spring (March to early May). Mera Peak is located in
              the Solu-Khumbu region of Nepal, which is known for its
              challenging and unpredictable weather. The peak climbing season in
              this region typically runs from March to early May, when the
              weather is generally warmer and stable.
              <br />
              Spring is the most popular time to climb Mera Peak, as it offers
              the best chance of clear weather and a relatively low risk of
              landslides, avalanches, and other hazards. You may also consider
              climbing Mera Peak in the autumn
            </p>
            {/*---------------------- tipping starts --------------------- */}
            <h4 id='tipping' className='trip-detail-title'>
              Tipping
            </h4>
            <p className='trip-detail-content'>
              Tipping during peak climbing in Nepal is a matter of personal
              choice, but it is a common and appreciated practice that can help
              to show your appreciation and support for the hardworking
              individuals who contribute to your expedition.
              <br />
              The amount you choose to tip will depend on your personal budget
              and your level of satisfaction with the service you received.
              There are no hard and fast rules for how much to tip, but a common
              range is USD 100 to USD 200 for mountain guides/porters, USD 5 to
              USD 10 for tour guides, and USD 2 for drivers.
              <br />
              You can tip at the end of the expedition, as this allows you to
              assess the overall quality of the service you received. Tip in
              local currency, as this allows the recipient to easily use the tip
              for everyday expenses.
            </p>
            {/*---------------------- Activities You Can Choose starts --------------------- */}
            <h4 id='activities' className='trip-detail-title'>
              Activities You Can Choose
            </h4>
            <p className='trip-detail-content'>
              If you are not satisfied with our Mera peak climbing itinerary,
              get in touch with us to customize it. Or, if you want to check a
              few other peak climbing packages in Nepal, then we highly
              recommend these packages-{" "}
              <strong>
                Island Peak Climbing, Lobuche Peak Climbing, Pisang Peak
                Climbing, Tent Pea Climbing, and Yala Peak Climbing.
              </strong>
              <br />
              Likewise, here are some of our Challenging offbeat trekking
              packages in the Himalayas-{" "}
              <strong>
                Everest Three High Passes Trek, Manaslu Circuit with Tsum Valley
                Trek, Makalu Base Camp Trek, Kanchenjunga Circuit Trek, etc.{" "}
              </strong>
              <br />
              We also have <strong>family-friendly</strong> treks and{" "}
              <strong>tours in Nepal</strong>, along with many day hikes, day
              tours, helicopter tours, and other sports activities such as{" "}
              <strong>
                rafting, paragliding, mountain biking, zip-lining, etc.
              </strong>
            </p>
            {/*----------------------group leader starts --------------------- */}
            <h4 id='group-leader' className='trip-detail-title'>
              Group Leader - What They Do
            </h4>
            <p className='trip-detail-content'>
              Our Mera peak climbing Sherpas are highly skilled and experienced
              in navigating the challenging terrain of the Himalayas and are
              known for their expertise in high-altitude climbing and their
              ability to adapt to the harsh mountain environment. They are
              essential members of any expedition to the Himalayas with us, as
              they provide valuable guidance, support, and protection to
              climbers and trekkers.
              <br />
              In addition to their technical skills and knowledge of the
              mountains, our guides have a strong work ethic and a commitment to
              the safety of their clients. They are an integral part of the
              climbing and trekking community in the Himalayas with us,
              Adventure Master Treks. Our guide's contributions to the success
              of expeditions and the safety of travelers cannot be overstated.
            </p>
            {/*---------------------- Size of Group Notes starts --------------------- */}
            <h4 id='group-notes' className='trip-detail-title'>
              Size of Group Notes
            </h4>
            <p className='trip-detail-content'>
              At Adventure Master Treks, we try to keep our climbing group size
              small. It doesn't mean we do not lead big groups, we do but with
              proper assessment and planning. In general, our group size goes
              from 2 to 8 people for peak climbing in Nepal. Solo climbers can
              join our Mera peak climbing 2023 departure date or{" "}
              <strong>
                <a
                  href='https://adventuremastertrek.com/contact'
                  target='_blank'
                >
                  contact us
                </a>
              </strong>
              a private climbing expedition.
              <br />
              We provide two guides/porters for one climber, which ensures the
              safety of the expedition and makes the journey hassle-free for our
              guests. There will be assistant guides, porters, a cook, medical
              personnel, and other supporting crew members during the peak
              climbing.
            </p>
            {/*---------------------- transport starts --------------------- */}
            <h4 id='transport' className='trip-detail-title'>
              Transport
            </h4>
            <p className='trip-detail-content'>
              During the Mera peak climbing, we take the Kathmandu Lukla flight
              to commute in/out of our starting/ending point of the venture. The
              flight cost is included in our Mera Peak climbing cost. In
              Kathmandu, we use a private vehicle to commute as per our
              itinerary. If you wish to hire a car or a two-wheeler, let us
              know. We will hook you up with the best vehicle rental provider.
              Additional charges apply.
            </p>
            {/*---------------------- local flights starts --------------------- */}
            <h4 id='transport' className='trip-detail-title'>
              Local Flights
            </h4>
            <p className='trip-detail-content'>
              Mera peak climbing involves the Kathmandu-Lukla flight. The
              Kathmandu-Lukla flight is a popular route for travelers visiting
              Nepal, as it connects the capital city of Kathmandu with the small
              town of Lukla, which serves as the starting point for many
              trekking and climbing expeditions in the Everest region.
              <br />
              This flight is known for being one of the most challenging and
              dangerous flights in the world due to the narrow and winding
              mountain terrain, high altitudes, and unpredictable weather
              conditions. Due to challenging flight conditions, even a slight
              fluctuation in weather can delay the flight and in the worse case,
              cancel it.
              <br />
              Therefore, we have a contingency day in our Mera peak climbing
              itinerary if we face such a situation. You can also join a
              helicopter flight, which is much safer and more comfortable. The
              helicopter flight lets you enjoy the superb aerial panorama of the
              Himalayas.
            </p>
            {/*---------------------- solo travelers starts --------------------- */}
            <h4 id='solo-traveler' className='trip-detail-title'>
              Solor Travelers
            </h4>
            <p className='trip-detail-content'>
              As a solo traveler, you can either join our fixed departure date
              for Mera peak climbing in Nepal or do private climbing with us. In
              the group, there will be other climbers, too, with our supporting
              crew. While private climbing, you will be climbing with our guides
              and porters alone. Climbing in a group and a private expedition
              both have their perks. You have to choose what suits your
              requirements and budget best.
            </p>
            {/*---------------------- joining instructions starts --------------------- */}
            <h4 id='joining' className='trip-detail-title'>
              Joining Instructions
            </h4>
            <p className='trip-detail-content'>
              Welcome to our Mera Peak climbing expedition in Nepal with the
              Adventure Master Treks team. We are excited to have you join us on
              this challenging and rewarding journey through the beautiful
              Himalayas.
              <br />
              Our booking is open for Mera peak climbing 2023 spring/autumn
              departures. Similarly, you can also make an advance booking for
              Mera peak climbing 2024.
              <br />
              <strong>
                <em>
                  To ensure a safe and successful trip for all participants, we
                  ask that you carefully review and follow the following
                  instructions:
                </em>
              </strong>
              <ul>
                <li>
                  <strong>Arrival:</strong> Please plan to arrive in Kathmandu
                  on the designated arrival date and time.
                </li>
                <li>
                  <strong>Gear:</strong> It is essential that you bring all
                  necessary climbing gear and equipment with you (or rent/buy in
                  Thamel, Kathmandu). We will provide a list of the required
                  gear.
                </li>
                <li>
                  <strong>Health:</strong> In order to participate in the Mera
                  peak expedition, you should be in good physical condition and
                  have some high-altitude experience.
                </li>
                <li>
                  <strong>Insurance:</strong> Please make sure to bring a copy
                  of your travel insurance policy with you.
                </li>
                <li>
                  <strong>Documentation:</strong> Please bring all necessary
                  documents, such as your passport, visa, and any other required
                  documents, with you on the trip.
                </li>
              </ul>
            </p>
            {/*---------------------- arrival complications starts --------------------- */}
            <h4 id='arrival' className='trip-detail-title'>
              Arrival Complications
            </h4>
            <p className='trip-detail-content'>
              If you encounter any difficulties that prevent you from arriving
              in Kathmandu on time, please don't hesitate to contact us at our
              emergency contact number. Our team will do our best to rearrange
              your trip if possible.
              <br />
              If you are unable to find our airport representatives upon
              arrival, you can call us or book a taxi to the designated hotel
              using the free WiFi at the airport.
              <br />
              While such complications are rare, it is always a good idea to
              have a plan in place in case of unexpected issues. Please do not
              hesitate to reach out to us if you have any concerns.
            </p>
            {/*---------------------- emergency contact starts --------------------- */}
            <h4 id='emergency-contact' className='trip-detail-title'>
              Emergency Contact
            </h4>
            <p className='trip-detail-content'>
              You can reach out to us at +977 9851033195, +44 7983806200 and
              +977 9849780311. These are our emergency contact numbers. The
              office time is from 9 am to 5 pm on weekdays (Sunday to Friday),
              so you can give us a call at +977 9851033195, +44 7983806200 and
              +977 9849780311 between the office hours. <br />
              We have our Whatsapp/Viber on these numbers +977 9851033195, +44
              7983806200 and +977 9849780311. Or, mail us at{" "}
              <strong>advmt2009@gmail.com</strong> for any support.
            </p>
            {/*---------------------- visa and entry requirements starts --------------------- */}
            <h4 id='visa' className='trip-detail-title'>
              Visa and Entry Requirements
            </h4>
            <p className='trip-detail-content'>
              If you plan to travel to Nepal, you will need a valid passport
              with at least six months of validity remaining and a visa. Many
              countries are eligible for an on-arrival visa, which can be
              obtained upon arrival at Tribhuvan International Airport or other
              entry points in Nepal.
              <br />
              However, it is noteworthy to check whether your country is
              eligible for an on-arrival visa or if you need to apply for a visa
              through Nepal's embassy or consulate in your home country.
              <br />
              Obtaining a visa is the responsibility of the traveler, and it is
              important to check all visa requirements and carry the necessary
              documents. If you are unable to obtain a visa, the company will
              not be responsible for it. We can provide you with a trip
              confirmation letter, but it is ultimately your responsibility to
              ensure that you have the necessary travel documents.
            </p>
            {/*---------------------- money exchange starts --------------------- */}
            <h4 id='money-exchange' className='trip-detail-title'>
              Money Exchange
            </h4>
            <p className='trip-detail-content'>
              You can use your credit/debit cards in most of the places in big
              cities like Kathmandu, Pokhara, and Chitwan. However, in many
              small shops and in local markets or taking public transportation,
              you have to use the Nepali rupee. Likewise, you cannot use cards
              or foreign currency on the way to Mera peak.
              <br />
              Locals in the mountains of Nepal only accept Nepali currency.
              There is no ATM on the trail, so your last chance to withdraw
              money for the journey is in Kathmandu. There are many ATMs, banks,
              and money exchange centers in Kathmandu where you can obtain
              Nepali currency.
            </p>
            {/*---------------------- emergency fund starts --------------------- */}
            <h4 id='emergency-fund' className='trip-detail-title'>
              Emergency Fund
            </h4>
            <p className='trip-detail-content'>
              An emergency fund is a financial resource set aside specifically
              for unexpected or unforeseen expenses that may arise while
              traveling. Travelers need to have an emergency fund in place to
              cover unexpected costs, such as unexpected travel delays, medical
              emergencies, or lost or stolen belongings.
              <br />
              Having an emergency fund can provide peace of mind and financial
              security while traveling, as it can help you handle unexpected
              expenses without having to rely on credit cards or other forms of
              borrowing. It can also help you avoid having to cut your trip
              short due to a lack of funds.
              <br />
              You can set aside a certain amount of money in a separate account
              or a readily accessible place, such as a prepaid debit card. It is
              good to have enough money in your emergency fund to cover at least
              a few weeks of travel expenses in case of unexpected delays or
              emergencies.
            </p>
            {/*---------------------- first aid starts --------------------- */}
            <h4 className='title' id='first-aid'>
              First-Aid Kit
            </h4>
            <p className='content'>
              We have a well-stocked first aid kit when traveling to the
              Himalayas. The Himalayas are a remote and challenging environment,
              and it is crucial to be prepared for any medical emergencies that
              may arise.
              <br />
              Here are some items that we have in our first-aid kit for Mera
              peak climbing:
              <ul>
                <li>
                  <strong>Pain relievers:</strong> We keep a variety of pain
                  relievers, such as acetaminophen, ibuprofen, and aspirin, to
                  help alleviate headaches, muscle pain, and other common aches
                  and pains.
                </li>
                <li>
                  <strong>Antibiotics:</strong> We carry a course of
                  antibiotics, in case you develop an infection or food
                  poisoning while traveling.
                </li>
                <li>
                  <strong>Bandages:</strong> The kit contains a variety of
                  bandages, including adhesive bandages, gauze, and medical
                  tape, to help cover and protect wounds.
                </li>
                <li>
                  <strong>Antiseptic:</strong> Antiseptic wipes or ointments
                  that help prevent infection in cuts and scrapes.
                </li>
                <li>
                  <strong>Cold and flu medication:</strong> Over-the-counter
                  cold and flu medication to help alleviate symptoms of common
                  illnesses.
                </li>
                <li>
                  <strong>Altitude sickness medication:</strong> Medication to
                  help prevent or treat altitude sickness.
                </li>
                <li>
                  <strong>Other items:</strong> Thermometer, tweezers, scissors,
                  Oximeter, and any other items that you may need in the event
                  of a medical emergency.
                </li>
              </ul>
            </p>
            {/*---------------------- package list starts --------------------- */}
            <h4 class='title' id='package-list'>
              Mera Peak Climbing Packing List
            </h4>
            <strong>Head & Hand</strong>
            <ul>
              <li>Balaclava </li>
              <li>Neck Gaiter/High Neck</li>
              <li>Sun Cap </li>
              <li>Wool/Fleece Hat </li>
              <li>Lightweight Synthetic Liner Glove </li>
              <li>Wind Stopper Fleece Gloves </li>
              <li>Heavy Gloves (Mitten) </li>
            </ul>
            <strong>Upper Body</strong>
            <ul>
              <li>Lightweight Top/Thermo Coat</li>
              <li>Short-Sleeved Shirts/T-shirts </li>
              <li>Midweight Top</li>
              <li>Down Insulated Jacket </li>
              <li>Synthetic or Fleece Jacket </li>
              <li>Gore-Tex Jacket</li>
            </ul>
            <strong>Lower Body </strong>
            <ul>
              <li>Lightweight Long Underpants </li>
              <li>Midweight Long Underpants </li>
              <li>Trekking Pants </li>
              <li>Gore-Tex Pants </li>
              <li>Synthetic Insulated Pants </li>
              <li>Underwear</li>
              <li>Summit Socks </li>
              <li>Trekking & Climbing Socks </li>
            </ul>
            <strong>Technical Devices/Climbing Gears </strong>
            <ul>
              <li>Snow Goggle UV Protection </li>
              <li>Sun/Glacier Glasses UV Protection </li>
              <li>1 Sleeping Bag: -30 +C to -40 +C </li>
              <li>Thermarest Cell Foam Mattress </li>
              <li>Harness </li>
              <li>Carabiners (Both Lock & Unlock) </li>
              <li>Ascenders/Jumar</li>
              <li>Climbing Helmet </li>
              <li>Headlamp with Spare Batteries (Petzl/BD) </li>
              <li>Summit Boot/G2SM</li>
              <li>Ice Axe Semi-technical </li>
              <li>Crampons </li>
              <li>Belay Device (ATC Guide/Figure of 8) </li>
              <li>Assistant Rope </li>
              <li>Tape Sling </li>
              <li>Ice Screw </li>
              <li>Extendable Trekking Poles (BD Alpine Flz) </li>
            </ul>
            <strong>First Aid Kit </strong>
            <ul>
              <li>Water Purification Tablets </li>
              <li>Sunscreen (-50 Spf) </li>
              <li>Lips Guard (-20/-50 Spf) </li>
              <li>Zip-Lock Bags</li>
              <li>Baby Wipes or Wet Towels </li>
              <li>Paracetamol</li>
              <li>Anti-Diarrhea Capsule/Eldoper </li>
              <li>Brufen/Ibuprofens</li>
              <li>Handy Plaster </li>
              <li>Crack Bandage </li>
              <li>Tincture </li>
              <li>Strepsils </li>
              <li>Antibiotic </li>
              <li>Diamox Lodine </li>
              <li>Aspirin </li>
              <li>Sinex </li>
              <li>Eye Drops </li>
            </ul>
            <strong>Toiletries </strong>
            <ul>
              <li>Laundry Soap </li>
              <li>Garbage Bag </li>
              <li>Toothpaste & Toothbrush </li>
              <li>Hand Sanitizers & Hand wash </li>
              <li>Wet Tissues</li>
              <li>Shampoo</li>
              <li>Bath Soap </li>
              <li>Lip Balm</li>
              <li>Moisturizers </li>
            </ul>
            <strong>Miscellaneous </strong>
            <ul>
              <li>1 Duffel Bag (provided by company )</li>
              <li>Rucksacks (45 - 55 Ltr.) </li>
              <li>Water Bottles </li>
              <li>Thermos/Flask </li>
              <li>Pack Towel </li>
              <li>RainCoat </li>
              <li>Knife </li>
              <li>Satellite Phone (if possible)</li>
              <li>Waterproof Stuff Sacks </li>
              <li>Lightweight Hiking/Trekking Boots </li>
              <li>Sandals </li>
              <li>Camp Booties</li>
              <li>Solar chargeable power bank (optional)</li>
              <li>Spare batteries </li>
              <li>Book/Board Game</li>
              <li>Journal/Pen</li>
              <li>Bars & Snacks</li>
            </ul>
            <strong>Travel Documents </strong>
            <ul>
              <li>Valid Passport </li>
              <li>4 PP Size Photo </li>
              <li>Family/Company Contact Number & Address </li>
              <li>Valid Visa </li>
              <li>Insurance Paper (Medical & Rescue) & Contact Address </li>
              <li>Credit Card</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default TripDetail;
