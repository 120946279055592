import React from "react";
import {
  FaFacebook,
  FaInstagram,
  FaTwitter,
  FaWhatsapp,
  FaEnvelope,
  FaLinkedinIn,
} from "react-icons/fa";
// import { HashLink } from "react-router-hash-link";

import Headquater from "./headquater";

import FooterImg1 from "../../image/footerImg/1.png";
import FooterImg2 from "../../image/footerImg/nma.png";
import FooterImg3 from "../../image/footerImg/ntb.png";
import FooterImg4 from "../../image/footerImg/taan.png";
import teamFooter from "../../image/footerImg/teamFooter.png";
import Payment from "../../image/footerImg/payment.png";
import { HashLink } from "react-router-hash-link";
import TripAdvisorReview from "./TripAdvisorReview";
// import { Helmet } from "react-helmet-async";
import { Helmet } from "react-helmet";

const footer = () => {
  return (
    <>
      <div className='footer'>
        <div className='container'>
          <div className='row footer-row'>
            <div className='col-sm-12 col-md-12 col-lg-3 mb-3 footer-content'>
              <h4 className='footer-title'>Adventure Master Trek</h4>
              <p>
                <a
                  href='https://api.whatsapp.com/send/?phone=9779851033195&text&type=phone_number&app_absent=0'
                  target='_blank'
                  rel='noreferrer'
                >
                  <FaWhatsapp /> +977 9851033195
                </a>
                <br />
                <a
                  href='mailto:info@adventuremastertrek.com'
                  target='_blank'
                  rel='noreferrer'
                >
                  <FaEnvelope /> info@adventuremastertrek.com
                </a>
                <br />
                <a
                  href='mailto:advmt2009@gmail.com'
                  target='_blank'
                  rel='noreferrer'
                >
                  <FaEnvelope /> advmt2009@gmail.com
                </a>
                <br />
                <a
                  href='mailto:adventuremastertrek@gmail.com'
                  target='_blank'
                  rel='noreferrer'
                >
                  <FaEnvelope /> adventuremastertrek@gmail.com
                </a>
              </p>
            </div>
            <div className='col-sm-12 col-md-6 col-lg-3 mb-3 useful-link footer-content footer-useful-link'>
              <h4 className='footer-title'>Useful Links</h4>
              <ul>
                <li>
                  <HashLink smooth to='/contact' className='link'>
                    Contact us
                  </HashLink>
                </li>
                <li>
                  <HashLink smooth to='/about' className='link'>
                    About us
                  </HashLink>
                </li>
                <li>
                  <HashLink smooth to='/travel-insurance' className='link'>
                    Travel Insurance
                  </HashLink>
                </li>
                <li>
                  <HashLink smooth to='/termsandcondition' className='link'>
                    Terms and condition
                  </HashLink>
                </li>
              </ul>
            </div>
            <div className='col-md-12 col-lg-3 mb-3 footer-affiliated'>
              <h4 className='footer-title'>We are affiliated</h4>
              <div className='affiliated-img mt-3'>
                <ul>
                  <li>
                    <img src={FooterImg1} alt='footer' />
                  </li>
                  <li>
                    <img src={FooterImg2} alt='footer' />
                  </li>
                  <li>
                    <img src={FooterImg3} alt='footer' />
                  </li>
                  <li>
                    <img src={FooterImg4} alt='footer' />
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-md-12 col-lg-3 mb-3 footer-content footer-connect'>
              <h4 className='footer-title'>Connect with us</h4>
              <div className='row footer-social-icon d-flex justify-content-center justify-content-md-start'>
                <div className='col-auto'>
                  <span className='instaIcon'>
                    <a
                      href='https://www.instagram.com/adventuremastertrek/'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <FaInstagram />
                    </a>
                  </span>
                </div>
                <div className='col-auto'>
                  <span className='fbIcon'>
                    <a
                      href='https://www.facebook.com/Advmt2009'
                      target='_blank'
                    >
                      <FaFacebook />
                    </a>
                  </span>
                </div>
                <div className='col-auto'>
                  <span className='twitterIcon'>
                    <a
                      href='https://twitter.com/AdvMasterTreks'
                      target='_blank'
                    >
                      <FaTwitter />
                    </a>
                  </span>
                </div>
                <div className='col-auto'>
                  <span className='linkedinIcon'>
                    <a
                      href='https://www.linkedin.com/in/adventure-mastertrek-077963130/'
                      target='_blank'
                    >
                      <FaLinkedinIn />
                    </a>
                  </span>
                </div>
              </div>
              <div className='mt-3'>
                We accept:
                <br />
                <span className='payment-img'>
                  <img src={Payment} alt='footer' />
                </span>
              </div>
            </div>
          </div>
          <div className='text-center mt-3'>
            <h4>Recommended On</h4>
            <div className='tripadvisor-footer'>
              <TripAdvisorReview />
            </div>
          </div>
          <div className='text-center mt-5'>
            <h1>Meet our leadership team</h1>
            <img
              src={teamFooter}
              alt='adventure master team'
              style={{ width: "600px" }}
              className='img-fluid'
            />
          </div>
          <h4 className='text-center pt-5 mb-4 footer-title global-title mb-4'>
            Our Global Headquaters
          </h4>
          <Headquater />
        </div>
        <div className='container copyright'>
          <p>&copy; Copyright 2023 Adventure Master Trek</p>
          <p>
            <strong>
              Registration No: 74651/067/068 | Tourism Registration No: 1180-067
            </strong>
          </p>
          <p style={{ fontSize: "18px" }}>
            <HashLink smooth to='/termsandcondition' className='link'>
              <strong>Terms and Conditions</strong>
            </HashLink>
          </p>
          {/* <p>
            <a href='https://itsansaar.com.np' target='_blank' rel='noreferrer'>
              Develop By: IT Sansaar
            </a>
          </p> */}
        </div>
      </div>
    </>
  );
};

export default footer;
