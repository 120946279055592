import * as React from "react";
import Box from "@mui/material/Box";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";

import mardiTrek1 from "../../../image/MardiHimalTrek/mardiHimal/1.jpg";
import mardiTrek2 from "../../../image/MardiHimalTrek/mardiHimal/2.jpg";
import mardiTrek3 from "../../../image/MardiHimalTrek/mardiHimal/3.jpg";
import mardiTrek5 from "../../../image/MardiHimalTrek/mardiHimal/5.jpg";
import mardiTrek6 from "../../../image/MardiHimalTrek/mardiHimal/6.jpg";
import mardiTrek7 from "../../../image/MardiHimalTrek/mardiHimal/7.jpg";
import mardiTrek8 from "../../../image/MardiHimalTrek/mardiHimal/8.jpg";
import mardiTrek9 from "../../../image/MardiHimalTrek/mardiHimal/9.jpg";
import mardiTrek10 from "../../../image/MardiHimalTrek/mardiHimal/10.jpg";
import mardiTrek11 from "../../../image/MardiHimalTrek/mardiHimal/11.jpg";
import mardiTrek12 from "../../../image/MardiHimalTrek/mardiHimal/12.jpg";
import mardiTrek13 from "../../../image/MardiHimalTrek/mardiHimal/13.jpg";
import mardiTrek14 from "../../../image/MardiHimalTrek/mardiHimal/14.jpg";
import mardiTrek15 from "../../../image/MardiHimalTrek/mardiHimal/15.jpg";
import mardiTrek16 from "../../../image/MardiHimalTrek/mardiHimal/16.jpg";
import mardiTrek17 from "../../../image/MardiHimalTrek/mardiHimal/17.jpg";
import mardiTrek18 from "../../../image/MardiHimalTrek/mardiHimal/18.jpg";
import mardiTrek19 from "../../../image/MardiHimalTrek/mardiHimal/19.jpg";
import mardiTrek20 from "../../../image/MardiHimalTrek/mardiHimal/20.jpg";
import mardiTrek21 from "../../../image/MardiHimalTrek/mardiHimal/21.jpg";
import mardiTrek22 from "../../../image/MardiHimalTrek/mardiHimal/22.jpg";
import mardiTrek23 from "../../../image/MardiHimalTrek/mardiHimal/23.jpg";
import mardiTrek24 from "../../../image/MardiHimalTrek/mardiHimal/24.jpg";
import mardiTrek25 from "../../../image/MardiHimalTrek/mardiHimal/25.jpg";
import mardiTrek26 from "../../../image/MardiHimalTrek/mardiHimal/26.jpg";
import mardiTrek27 from "../../../image/MardiHimalTrek/mardiHimal/27.jpg";
import mardiTrek28 from "../../../image/MardiHimalTrek/mardiHimal/28.jpg";
import mardiTrek29 from "../../../image/MardiHimalTrek/mardiHimal/29.jpg";
import mardiTrek30 from "../../../image/MardiHimalTrek/mardiHimal/30.jpg";

export default function MasonryImageList() {
  return (
    <>
      <h4 className="title mt-5">Photo Gallery</h4>
      <Box
        className="gallery-box mb-5"
        sx={{ overflowY: "scroll" }}
        style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
      >
        <ImageList variant="masonry" cols={3} gap={8}>
          {itemData.map((item) => (
            <ImageListItem key={item.img}>
              <img
                src={`${item.img}?w=248&fit=crop&auto=format`}
                srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                alt={item.title}
                // loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
    </>
  );
}

const itemData = [
  {
    img: mardiTrek1,
    title: "everest heli",
  },
  {
    img: mardiTrek2,
    title: "everest heli",
  },
  {
    img: mardiTrek3,
    title: "everest heli",
  },

  {
    img: mardiTrek5,
    title: "everest heli",
  },
  {
    img: mardiTrek6,
    title: "everest heli",
  },
  {
    img: mardiTrek7,
    title: "everest heli",
  },
  {
    img: mardiTrek8,
    title: "everest heli",
  },
  {
    img: mardiTrek9,
    title: "everest heli",
  },
  {
    img: mardiTrek10,
    title: "everest heli",
  },
  {
    img: mardiTrek11,
    title: "everest heli",
  },
  {
    img: mardiTrek12,
    title: "everest heli",
  },
  {
    img: mardiTrek13,
    title: "everest heli",
  },
  {
    img: mardiTrek14,
    title: "everest heli",
  },
  {
    img: mardiTrek15,
    title: "everest heli",
  },
  {
    img: mardiTrek16,
    title: "everest heli",
  },
  {
    img: mardiTrek17,
    title: "everest heli",
  },
  {
    img: mardiTrek18,
    title: "everest heli",
  },
  {
    img: mardiTrek19,
    title: "everest heli",
  },
  {
    img: mardiTrek20,
    title: "everest heli",
  },
  {
    img: mardiTrek21,
    title: "everest heli",
  },
  {
    img: mardiTrek22,
    title: "everest heli",
  },
  {
    img: mardiTrek23,
    title: "everest heli",
  },
  {
    img: mardiTrek24,
    title: "everest heli",
  },
  {
    img: mardiTrek25,
    title: "everest heli",
  },
  {
    img: mardiTrek26,
    title: "everest heli",
  },
  {
    img: mardiTrek27,
    title: "everest heli",
  },
  {
    img: mardiTrek28,
    title: "everest heli",
  },
  {
    img: mardiTrek29,
    title: "everest heli",
  },
  {
    img: mardiTrek30,
    title: "everest heli",
  },
];
