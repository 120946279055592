import React from 'react'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { HashLink } from 'react-router-hash-link';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import GroupIcon from '@mui/icons-material/Group';
import EventNoteIcon from '@mui/icons-material/EventNote';

function createData(date, available, status, book) {
  return { date, available, status, book };
}

const rows = [
  createData(<span><EventAvailableIcon className='me-2 text-success' />April 8, 2023</span>, <span><GroupIcon className='me-2 text-success' />3/12</span>,
    <span className='badge rounded-pill text-bg-success departure-date-badge'><CheckCircleIcon className='me-2' />Available</span>,
    // <a href="/everest-base-camp-trek" target="_blank" className='btn btn-success btn-sm'>Book Now</a>
  ),

  createData(<span><EventAvailableIcon className='me-2 text-success' />April 27, 2023</span>, <span><GroupIcon className='me-2 text-success' />2/12</span>,
    <span className='badge rounded-pill text-bg-success departure-date-badge'><CheckCircleIcon className='me-2' />Available</span>,
    // <a href="/everest-base-camp-helicopter-return-trek-9-days" target="_blank" className='btn btn-success btn-sm'>Book Now</a>
  ),
  createData(<span><EventAvailableIcon className='me-2 text-success' />May 11, 2023</span>, <span><GroupIcon className='me-2 text-success' />1/12</span>,
    <span className='badge rounded-pill text-bg-success departure-date-badge'><CheckCircleIcon className='me-2' />Available</span>,
    // <a href="/everest-base-camp-helicopter-return-trek-9-days" target="_blank" className='btn btn-success btn-sm'>Book Now</a>
  ),
  createData(<span><EventAvailableIcon className='me-2 text-success' />May 25, 2023</span>, <span><GroupIcon className='me-2 text-success' />2/12</span>,
    <span className='badge rounded-pill text-bg-success departure-date-badge'><CheckCircleIcon className='me-2' />Available</span>,
    // <a href="/everest-base-camp-helicopter-return-trek-9-days" target="_blank" className='btn btn-success btn-sm'>Book Now</a>
  ),
];

function ManasluCircuitTrekDepartureDateModalTripInfo() {
  return (
    <div>
      <div class="modal fade luxury-package-modal" id="manaslucircuittrekdeparturedatemodaltripinfo" aria-hidden="true" aria-labelledby="manaslucircuittrekdeparturedatemodaltripinfoLabel" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered departure-date-modal">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="manaslucircuittrekdeparturedatemodaltripinfoLabel">Manaslu Circuit Trek Fix departure dates</h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell align="right">Availibility</TableCell>
                      <TableCell align="right">Status</TableCell>
                      <TableCell align="right"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow
                        key={row.date}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          {row.date}
                        </TableCell>
                        <TableCell align="right">{row.available}</TableCell>
                        <TableCell align="right">{row.status}</TableCell>
                        <TableCell align="right">{row.book}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <div className='text-center fs-6'><em>For inquiry please send us message at: <a href='mailto:info@adventuremastertrek.com' target={'_blank'}>info@adventuremastertrek.com</a></em></div>
              </TableContainer>
            </div>
            <div class="modal-footer">
              <HashLink
                smooth
                to="/manaslu-circuit-trek"
                className="link"
              >
                <button class="btn btn-dark" data-bs-target="#manaslucircuittrekdeparturedatemodaltripinfo2" data-bs-toggle="modal">View Trip</button>
              </HashLink>
              <button class="btn btn-outline-secondary" data-bs-target="#manaslucircuittrekdeparturedatemodaltripinfo2" data-bs-toggle="modal">Close</button>
            </div>
          </div>
        </div>
      </div>

      <hr />
      <p class="departure-date-modal-trip-info m-0" data-bs-target="#manaslucircuittrekdeparturedatemodaltripinfo" data-bs-toggle="modal"><EventNoteIcon className='me-2' />View fix departure dates</p>


    </div>
  )
}

export default ManasluCircuitTrekDepartureDateModalTripInfo
