import { useInView } from "react-intersection-observer";
import Modals from "./Modals";

import abc1 from "../../../image/Annapurna/abc1.jpg";
import abc2 from "../../../image/Annapurna/abc2.jpg";
import abc4 from "../../../image/Annapurna/abc4.jpg";
import abc5 from "../../../image/Annapurna/abc5.jpg";
import annapurna1 from "../../../image/Annapurna/annapurna1.jpeg";
import annapurna2 from "../../../image/Annapurna/annapurna2.jpg";
const Overview = () => {
  const tripTitle = "Annapurna Base Camp Trek - 12 Days";
  const tripPrice = "890 pp";
  const modaltrip = [
    {
      id: 1,
      modalhead: "Trip Overview",
      modaldetail: [
        {
          modaltitle: "Difficulty Level",
          modaltext: <strong>Moderate to Difficult</strong>,
        },
        {
          modaltitle: "Maximum Altitude",
          modaltext: <strong>4,130 m/13,549 ft</strong>,
        },
        {
          modaltitle: "Best Season",
          modaltext: <strong>Spring & Autumn</strong>,
        },
      ],
      trip: "Annapurna Base Camp Trek",
    },
    {
      id: 2,
      modalhead: "The Challenge",
      modaldetail: [
        {
          modaltitle: "Difficulty Level",
          modaltext: <strong>Moderate to Difficult</strong>,
        },
        {
          modaltitle: "Maximum Altitude",
          modaltext: <strong>4,130 m/13,549 ft</strong>,
        },
      ],
      trip: "Annapurna Base Camp Trek",
    },
    {
      id: 3,
      modalhead: "Accommodations",
      modaldetail: [
        {
          modaltitle: "Accommodation on the trail",
          modaltext: <strong>Lodge</strong>,
        },
        {
          modaltitle: "In Kathmandu",
          modaltext: <strong>Three-star hotel</strong>,
        },
        {
          modaltitle: "Style",
          modaltext: <strong>Shared</strong>,
        },
      ],
      trip: "Annapurna Base Camp Trek",
    },
    {
      id: 4,
      modalhead: "Nutrition",
      modaldetail: [
        {
          modaltitle: "Meals in Kathmandu & Pokhara",
          modaltext: <strong>All breakfasts & farewell dinner</strong>,
        },
        {
          modaltitle: "Meals during the trek",
          modaltext: (
            <strong>Three meals a day (breakfast, lunch & dinner)</strong>
          ),
        },
        {
          modaltitle: "Cuisine",
          modaltext: <strong>Nepali, Tibetan, Continental & Indian</strong>,
        },
      ],
      trip: "Annapurna Base Camp Trek",
    },
    {
      id: 5,
      modalhead: "Regeneration",
      modaldetail: [
        {
          modaltitle: "Morning stretching",
          modaltext:
            "Practice yoga & meditation by yourself (Or upgrade to yoga trekking, led by a professional yoga instructor)",
        },
        {
          modaltitle: "",
          modaltext: (
            <strong>Re-learn to live slow & be present in every moment</strong>
          ),
        },
      ],
      trip: "Annapurna Base Camp Trek",
    },
    {
      id: 6,
      modalhead: "Destination",
      modaldetail: [
        {
          modaltitle: "Duration",
          modaltext: <strong>13 Days</strong>,
        },
        {
          modaltitle: "Location",
          modaltext: <strong>Annapurna Region, Nepal</strong>,
        },
        {
          modaltitle: "Province",
          modaltext: <strong>1</strong>,
        },
        {
          modaltitle: "Population",
          modaltext: <strong>100,000+</strong>,
        },
        {
          modaltitle: "Wildlife Species",
          modaltext: <strong>2,000+</strong>,
        },
        {
          modaltitle: "Highest Points",
          modaltext: <strong>Poon Hill (3,210 m) & ABC (4,1300 m)</strong>,
        },
      ],
      trip: "Annapurna Base Camp Trek",
    },
  ];
  let sendmodaldetails = {};

  const { ref, inView } = useInView();
  const { ref: ref2, inView: inView2 } = useInView();
  const { ref: ref3, inView: inView3 } = useInView();
  const { ref: ref4, inView: inView4 } = useInView();
  const { ref: ref5, inView: inView5 } = useInView();
  const { ref: ref6, inView: inView6 } = useInView();

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll, true);
  // }, [handleScroll]);
  if (inView6 === true) {
    sendmodaldetails = modaltrip[5];
  } else if (inView5 === true) {
    sendmodaldetails = modaltrip[4];
  } else if (inView4 === true) {
    sendmodaldetails = modaltrip[3];
  } else if (inView3 === true) {
    sendmodaldetails = modaltrip[2];
  } else if (inView2 === true) {
    sendmodaldetails = modaltrip[1];
  } else if (inView === true) {
    sendmodaldetails = modaltrip[0];
  } else {
    sendmodaldetails = modaltrip[5];
  }
  return (
    <>
      <div ref={ref} className="trip-details-next container">
        <div className="trip-fact col-10 flex-sm-wrap mb-2 mb-sm-3">
          <div className="facts">
            <div className="fact">
              <h1>13 Days Trek To Annapurna Base Camp</h1>
            </div>
            <div ref={ref} className="title-content col-sm-12 col-md-8 mb-3">
            The Annapurna Base Camp Trek is also one of Nepal's most popular and well-known treks. The trek to the base of the Annapurna in the northwest part of Nepal takes 13 days. You can see the beautiful landscape. Trek through rhododendron forests and remote mountain villages to see a beautiful mountain panorama. Hike in the famous Annapurna area and meet people from all over the world. Learn about real village life and the rich culture of the people who live there.  
        </div>
          </div>
        </div>
        <div ref={ref} className="big-title col-sm-12 col-md-7 mb-3">
          MAJOR TOURIST ATTRACTION AT ABC
        </div>
        <div ref={ref} className="title-content col-sm-12 col-md-7 mb-3">
        Annapurna I (8,091 m/26,545 ft), Annapurna South (7,219 m/23,684 ft), Annapurna III (7,555 m/24,787 ft), Hiunchuli (6,441 m/21,132 ft), Gangapurna (7,455 m/24,459 ft), Mardi Himal (5,587 m/18,330 ft), At the end of the trek, soak your tired muscles in natural hot springs and watch the sunrise from Poon Hill. When you arrive at Annapurna Base Camp, there will be clouds and a white blanket of snow all around you.
        </div>

        <img
          ref={ref}
          src={annapurna1}
          alt="everest"
          className="col-sm-12 col-md-12 mb-3 w-100"
        />

        <div
          ref={ref2}
          className="row wrap justify-content-between col-sm-12 my-3"
        >
          <div className="col-sm-12 col-md-6">
            <div className="big-title col-sm-12 mb-3">
            THE BEST VIEWS COME AFTER THE HARDEST WALKS, AND THE GREATEST GRATITUDE COMES AFTER THE LONGEST DAYS OF WALKING.
            </div>
            <div className="title-content col-sm-12 mb-3">
            Even though we say it's good for beginners, the trek to Annapurna Base Camp is not easy. There are many obstacles in the way. The land is rough and hilly. The trek to Annapurna's base camp will test your strength and determination. The trek is definitely doable, but only if you have the right help, pack right, and are in good shape. During the trek, you'll be with our well-trained team. They take care of your needs, like where to stay, what to eat, etc. They will push you to keep walking and won't let you stop unless it's necessary.
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <img
              src={annapurna2}
              alt="everest"
              className="col-sm-12 mb-3 long-img"
            />
          </div>
        </div>
        <div
          ref={ref3}
          className="flex row wrap justify-content-between col-sm-12 mt-5"
        >
          <div className="col-sm-12 col-md-6">
            <img
              src={abc2}
              alt="everest"
              className="col-sm-12  mb-3 short-img"
            />
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="big-title col-sm-12 mb-3">
            SPEND THE NIGHT IN BEAUTIFUL GURUNG AND MAGAR VILLAGE, THE BASIC LODGE OPERATED BY THE LOCALS
            </div>
            <div className="title-content col-sm-12 mb-3">
            The heartwarming hospitality of the locals at the end of the day is one of the best parts of Annapurna base camp trekking. The Annapurna base camp trail provides basic lodging. You get to enjoy a cozy dining experience with excellent conversations after a long walk. Spend time in the dining room, warming your body with hot chocolate or tea, and sleeping well in a comfortable bed. Since the rooms are small, two trekkers should share them.
            </div>
          </div>
        </div>
        <div
          ref={ref4}
          className="row wrap justify-content-between col-sm-12 my-3"
        >
          <div className="col-sm-12 col-md-6">
            <div className="big-title col-sm-12 mb-3">
            TASTE AUTHENTIC NEPALI CUISINE MADE WITH LOVE
            </div>
            <div className="title-content col-sm-12 mb-3">
            Our trips in and around Nepal and the Himalayas are full of rich local flavors. Before our guests leave Nepal, we make sure they try all the country's best foods. The history of the dishes and how good they taste give the trip a deep meaning. By sharing their tastes, the locals can get to know the tourists on a deeper level. We think that the best bonds are made over food. It puts you at ease and makes it easy to start a conversation. Nepal is home to many different ethnic groups, so you'll never run out of things to try.
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <img src={abc4} alt="everest" className="col-sm-12 mb-3 long-img" />
          </div>
        </div>
        <div
          ref={ref5}
          className="flex row wrap justify-content-between col-sm-12 mt-5"
        >
          <div className="col-sm-12 col-md-6">
            <img
              src={abc1}
              alt="everest"
              className="col-sm-12  mb-3 short-img"
            />
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="big-title col-sm-12 mb-3">
            START A MAGICAL JOURNEY AMONG THE HUGE HIMALAYAN MOUNTAINS AND GREEN VALLEYS, RELAX, AND CONNECT WITH YOUR TRUE SELF.
            </div>
            <div className="title-content col-sm-12 mb-3">
            Adventure is only meaningful if it has a good mix of thrills and calm. Annapurna base camp trekking is a real adventure because of all the little things that are hidden behind big mountains and glaciers. We call them the sound of birds chirping, the sound of rivers rushing, the smell of lush forests, the sound of waterfalls falling, the cool air, and the friendly people who live there. You will learn to take things slowly and pay attention to each breath you take. You will learn how to be strong, and your confidence will grow.
            </div>
          </div>
        </div>
        <div
          ref={ref6}
          className="row wrap justify-content-between col-sm-12 my-3"
        >
          <div className="col-sm-12 col-md-6">
            <div className="big-title col-sm-12 mb-3">
            ANNAPURNA BASE CAMP, AT THE FOOT OF MT. ANNAPURNA, THE TENTH HIGHEST MOUNTAIN IN THE WORLD.
            </div>
            <div className="title-content col-sm-12 mb-3">
            The Annapurna Base Camp Trek is one of the best treks in the Annapurna region. In the Annapurna Conservation Area, where you trek, there are many plants and animals. The trail goes up and down through dense forests of rhododendron, oak, bamboo, and pine. Every turn in the trail gives you a different view, and each new village gives you a chance to meet people from different places. You will have to push yourself to get through the difficulties of the Himalayas and reach Annapurna Base Camp.
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <img src={abc5} alt="everest" className="col-sm-12 mb-3 long-img" />
          </div>
        </div>
      </div>
      {/* <div className="container map"></div> */}
      <div className="main-modal">
        <Modals
          sendmodaldetails={sendmodaldetails}
          tripTitle={tripTitle}
          tripPrice={tripPrice}
        />
      </div>
    </>
  );
};

export default Overview;
