import React from "react";
import { Button, Row } from "react-bootstrap";
import { BiTimeFive } from "react-icons/bi";

import "../../../custom-sass/pages/trip/_trip-detail.scss";
import Map from "../../../image/Map/EverestHeliTourWhite.jpg";
import Pdf from "../../../itineraryDocuments/EverestRegion/EBCHeliTour.pdf";

const Itinerary = () => {
  return (
    <>
      <div className="detail-itinerary container mt-5">
        <div className="row">
          <div className="d-lg-none mb-5">
            <div className="">
              <h4 className="title">Heli Tour Route Map</h4>
              <img
                src={Map}
                alt="Everest Heli Tour Map"
                style={{ width: "100%" }}
              />
              <button type="button" className="btn btn-dark mt-3 col-12">
                <a href={Pdf} target="_blank" className="text-light">
                  Download Itinerary
                </a>
              </button>
            </div>
          </div>

          <div className="col-md-12 col-lg-8">
            <div className="col-12 itinerary-icon">
              <Row className="align-items-center mt-4">
                <h4 className="overview-title mt-4">Detail Itinerary</h4>

                <ul className="overview-outline-itinerary itinerary-content ebc-heli-itinerary">
                  <li className="mt-4">
                    <strong>
                      <BiTimeFive fontSize={20} /> 5:30 AM:
                    </strong>{" "}
                    <br />
                    Hotel to airport in private vehicle.
                  </li>
                  <li className="mt-4">
                    <strong>
                      <BiTimeFive fontSize={20} /> 6:30 AM:{" "}
                    </strong>{" "}
                    <br />
                    Take off from Kathmandu Airport (TIA)
                  </li>
                  <li className="mt-4">
                    <strong>
                      <BiTimeFive fontSize={20} /> 7:30 AM:
                    </strong>{" "}
                    <br />
                    Landing in Lukla airport (Tenzing Hillary Airport)<br/>
                    Note: If there more than 250 kg, two shuttles will be from Pheriche. Maximum weight: 500kg of passenger
                  </li>
                  <li className="mt-4">
                    <strong>
                      <BiTimeFive fontSize={20} /> 8:00 AM:
                    </strong>{" "}
                    <br />
                    Landing on Kalapathar/EBC fly over
                  </li>
                  <li className="mt-4">
                    <strong>
                      <BiTimeFive fontSize={20} /> 8:45 AM:
                    </strong>{" "}
                    <br />
                    Breakfast in Syangboche (Everest View Hotel) (1 hour)
                  </li>
                  <li className="mt-4">
                    <strong>
                      <BiTimeFive fontSize={20} /> 10:00 AM:
                    </strong>{" "}
                    <br />
                    Take off from Lukla airport
                  </li>
                  <li className="mt-4">
                    <strong>
                      <BiTimeFive fontSize={20} /> 11:00 AM:
                    </strong>{" "}
                    <br />
                    Arrive Kathmandu and transfer to hotel
                  </li>
                </ul>
              </Row>
            </div>
          </div>

          <div className="main-modal-map d-none d-lg-flex">
            <div className="modal-map d-flex flex-column">
              <h4 className="title">Heli Tour Route Map</h4>
              <img src={Map} alt="Everest Heli Tour Map" />
              <button type="button" className="btn btn-dark mt-3">
                <a href={Pdf} target="_blank" className="text-light">
                  Download Itinerary
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Itinerary;

/*
day title
day caption
trip detail - ( with icon
- Accommodation
- meals
-Maximum Altitude
)
*/
