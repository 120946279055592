import React from 'react'
import { Col, Row } from 'react-bootstrap'
import NTE from "../../../image/NTE.png"
import FYK from "../../../image/FYK.png"

function SisterCompany() {
  return (
    <div className='sister-company container'>
            <h3 className='text-center'>Our Sister Company</h3>
        <div className='row d-flex justify-content-center align-items-center mt-5'>
            <div className='col-auto'>
                <a href='https://www.nepaltravelexperience.com/' target='_blank'>
                <img src={NTE}/>
                </a>
            </div>
            <div className='col-auto'>
                <a href='https://www.flyingyakkathmandu.com/' target='_blank'>
                <img src={FYK}/>
                </a>
            </div>
        </div>
    </div>
  )
}

export default SisterCompany