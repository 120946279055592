import { useInView } from "react-intersection-observer";
import Modals from "./Modals";

import ebchelitour from "../../../image/Everest/ebc.jpg";
import ebchelitour3 from "../../../image/Everest/everestmeals.jpeg";
import ebchelitour4 from "../../../image/Everest/everestviewhotel.jpeg";
import ebctrek1 from "../../../image/Everest/everesttrek.jpeg";
import ebctrek from "../../../image/Everest/dingboche.jpg";
import islandpeak from "../../../image/IslandPeakClimbing/islandPeakClimbing.jpeg";

const Overview = () => {
  const tripTitle = "Island Peak Climbing With EBC - 19 Days";
  const tripPrice = "$2700 pp";
  const modaltrip = [
    {
      id: 1,
      modalhead: "Trip Overview",
      modaldetail: [
        {
          modaltitle: "Difficulty Level",
          modaltext: <strong>Strenuous (Alpine PD+)</strong>,
        },
        {
          modaltitle: "Maximum Altitude",
          modaltext: <strong>6,189 m/20,305 ft</strong>,
        },
        {
          modaltitle: "Best Season",
          modaltext: <strong>Spring & Autumn</strong>,
        },
      ],
      trip: "Island Peak Climb With EBC",
    },
    {
      id: 2,
      modalhead: "The Challenge",
      modaldetail: [
        {
          modaltitle: "Difficulty Level",
          modaltext: <strong>Strenuous (Alpine PD+)</strong>,
        },
        {
          modaltitle: "Maximum Altitude",
          modaltext: <strong>6,189 m/20,305 ft</strong>,
        },
      ],
      trip: "Island Peak Climb With EBC",
    },
    {
      id: 3,
      modalhead: "Accommodations",
      modaldetail: [
        {
          modaltitle: "Accommodation on the trail",
          modaltext: <strong>Lodge</strong>,
        },
        {
          modaltitle: "During Climbing",
          modaltext: <strong>Alpine tents</strong>,
        },
        {
          modaltitle: "In Kathmandu",
          modaltext: <strong>Three-star hotel</strong>,
        },
        {
          modaltitle: "Style",
          modaltext: <strong>Shared</strong>,
        },
      ],
      trip: "Island Peak Climb With EBC",
    },
    {
      id: 4,
      modalhead: "Nutrition",
      modaldetail: [
        {
          modaltitle: "Meals in Kathmandu",
          modaltext: <strong>All breakfasts & welcome/farewell dinners</strong>,
        },
        {
          modaltitle: "Meals during the trek",
          modaltext: (
            <strong>Three meals a day (breakfast, lunch & dinner)</strong>
          ),
        },
        {
          modaltitle: "Cuisine",
          modaltext: <strong>Nepali, Tibetan, Continental & Indian</strong>,
        },
      ],
      trip: "Island Peak Climb With EBC",
    },
    {
      id: 5,
      modalhead: "Regeneration",
      modaldetail: [
        {
          modaltitle: "Morning stretching",
          modaltext:
            "Practice yoga & meditation by yourself (Or hire a professional yoga instructor) Learn to live in the moment, fight with your demons, and come back as a new individual",
        },
        {
          modaltitle: "",
          modaltext: (
            <strong>Re-learn to live slow & be present in every moment</strong>
          ),
        },
      ],
      trip: "Island Peak Climb With EBC",
    },
    {
      id: 6,
      modalhead: "Destination",
      modaldetail: [
        {
          modaltitle: "Duration",
          modaltext: <strong>19 Days</strong>,
        },
        {
          modaltitle: "Location",
          modaltext: <strong>Khumbu Region, Nepal</strong>,
        },
        {
          modaltitle: "Province",
          modaltext: <strong>1</strong>,
        },
        {
          modaltitle: "Population",
          modaltext: <strong>9,133+</strong>,
        },
        {
          modaltitle: "Wildlife Species",
          modaltext: <strong>1,500+</strong>,
        },
        {
          modaltitle: "Highest Points",
          modaltext: (
            <strong>
              Island Peak (6,189 m/20,305 ft), EBC (5,364 m/17,598 ft) & Kala
              Patthar (5,644 m/18,519 ft)
            </strong>
          ),
        },
      ],
      trip: "Island Peak Climb With EBC",
    },
  ];
  let sendmodaldetails = {};

  const { ref, inView } = useInView();
  const { ref: ref2, inView: inView2 } = useInView();
  const { ref: ref3, inView: inView3 } = useInView();
  const { ref: ref4, inView: inView4 } = useInView();
  const { ref: ref5, inView: inView5 } = useInView();
  const { ref: ref6, inView: inView6 } = useInView();

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll, true);
  // }, [handleScroll]);
  if (inView6 === true) {
    sendmodaldetails = modaltrip[5];
  } else if (inView5 === true) {
    sendmodaldetails = modaltrip[4];
  } else if (inView4 === true) {
    sendmodaldetails = modaltrip[3];
  } else if (inView3 === true) {
    sendmodaldetails = modaltrip[2];
  } else if (inView2 === true) {
    sendmodaldetails = modaltrip[1];
  } else if (inView === true) {
    sendmodaldetails = modaltrip[0];
  } else {
    sendmodaldetails = modaltrip[5];
  }
  return (
    <>
      <div ref={ref} className="trip-details-next container mt-5">
        <div className="trip-fact col-10 flex-sm-wrap mb-3">
          <div className="facts">
            <div className="fact mb-3">
              <h1>Island Peak Climbing With EBC - 19 Days</h1>
            </div>
          </div>
        </div>
        <div ref={ref} className="big-title col-sm-12 col-md-7 mb-3">
          A FANTASTIC 6,000 METERS PEAK TO CLIMB AS YOUR FIRST EXPEDITION IN THE
          HIMALAYAS- A GREAT INTRODUCTION TO PEAK CLIMBING IN NEPAL.
        </div>
        <div ref={ref} className="title-content col-sm-12 col-md-7 mb-3">
          Island peak climbing 19 Days is one of the most famous climbing
          expeditions in Nepal. Known as a trekking peak, Island peak offers a
          great introduction to peak climbing in Nepal if you are a beginner
          climber. The real name of the peak is Imja Tse, and was nicknamed
          Island Peak by Tenzing Norgay and Edmund Hillary during their 1953
          Mount Everest Expedition. Island peak climbing is very physically
          demanding but less technical. Our Island peak climbing itinerary also
          combines a hike to Everest base camp (5,364 m/17,598 ft) and Kala
          Patthar (5,644 m/18,517 ft), which make the venture very adventurous
          and exciting. We take this hike as an acclimatization before heading
          towards the summit of Island peak (6,189 m/20,305 ft). It doesn't
          matter if you have climbed a peak or not before because our expert
          team will take care of it. However, you have to have a high-altitude
          experience to join Island peak climbing with Everest base camp.
          Supported by professional mountaineers, the Island peak climbing is a
          once-in-a-lifetime venture that will let you witness the grand beauty
          of the Himalayas and experience a unique mountain lifestyle.
        </div>

        <img
          ref={ref}
          src={islandpeak}
          alt="island peak"
          className="col-sm-12 col-md-12 mb-3 w-100"
        />

        <div
          ref={ref2}
          className="row wrap justify-content-between col-sm-12 my-3"
        >
          <div className="col-sm-12 col-md-6">
            <div className="big-title col-sm-12 mb-3">
              UNLOCK A NEW ACHIEVEMENT IN YOUR LIFE AND GO BEYOND YOUR LIMIT TO
              SCALE A HIMALAYAN MOUNTAIN- PHYSICALLY DEMANDING.
            </div>
            <div className="title-content col-sm-12 mb-3">
              Our Island peak climbing with Everest base camp has three phases.
              Phase I involves 10 days of trekking in the Khumbu region. Phase
              II involves climbing Island Peak, and Phase II involves trekking
              back to Lukla. The trekking part has lots of ascends and descends
              along uneven trails. You will walk through forests, pastures,
              moraines, and glaciers. Climbing Island peak is graded Alpine PD+,
              which means the mountain is not technically challenging but
              physically very demanding. The participants have to be physically
              and mentally strong to overcome the challenges. Island peak
              climbing is by no means an easy venture, but we can assure you a
              very safe climbing
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <img
              src={ebctrek1}
              alt="everest"
              className="col-sm-12 mb-3 long-img"
            />
          </div>
        </div>
        <div
          ref={ref3}
          className="flex row wrap justify-content-between col-sm-12 mt-5"
        >
          <div className="col-sm-12 col-md-6">
            <img
              src={ebchelitour4}
              alt="everest"
              className="col-sm-12 short-img"
            />
            <em className="photo-caption text-muted">Everest View Hotel</em>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="big-title col-sm-12 mb-3">
              THREE-STAR HOTEL IN KATHMANDU, COMFORTABLE LODGES ALONG THE
              TREKKING ROUTE & CAMP AT ISLAND PEAK BASE CAMP.
            </div>
            <div className="title-content col-sm-12 mb-3">
              We have three different kinds of accommodations involved in the
              Island peak climbing with Everest base camp. In Kathmandu, you
              will spend nights in beautiful hotels equipped with all the modern
              amenities. While trekking, we book simple lodges for you. Runned
              by the villagers, the lodges offer a twin-shared room with clean
              bedding and a blanket. During the Island peak climbing, we’ll
              spend a couple of nights at the Island peak base camp in tents.
              The group will get shared dining and washroom tents along with
              separate sleeping tents.
            </div>
          </div>
        </div>
        <div
          ref={ref4}
          className="row wrap justify-content-between col-sm-12 my-3"
        >
          <div className="col-sm-12 col-md-6">
            <div className="big-title col-sm-12 mb-3">
              DELICIOUS THREE MEALS A DAY DURING TREK AS PER THE MENU OF THE
              LODGE. AT BASE CAMP, THE CREW PREPARES AND COOKS ALL THE MEALS.
            </div>
            <div className="title-content col-sm-12 mb-3">
              All of our trips are wrapped with delicious Nepali dishes. We make
              sure our guests taste the flavourful Nepali staples, if not all,
              at least the must ones. Nepali flavors are very rich and filled
              with a wide range of spices. In Kathmandu, you get the opportunity
              to explore a wide range of options, but in the mountains, the
              options narrow down to a few selected ones. During the trekking
              period, all the meals are served as per the menu of the lodges.
              Once we reach the base camp, we prepare and cook our meals. Even
              in the isolated mountains, we make sure our guests get delicious
              and nutritious three meals a day with beverages.
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <img
              src={ebchelitour3}
              alt="everest"
              className="col-sm-12 mb-3 long-img"
            />
          </div>
        </div>
        <div
          ref={ref5}
          className="flex row wrap justify-content-between col-sm-12 mt-5"
        >
          <div className="col-sm-12 col-md-6">
            <img
              src={ebctrek}
              alt="everest"
              className="col-sm-12  mb-3 short-img"
            />
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="big-title col-sm-12 mb-3">
              PUSH YOUR LIMITS AND CHALLENGE YOURSELF ON THIS 19 DAYS VOYAGE IN
              THE HIMALAYAS OF NEPAL. UNWIND & CONNECT TO YOUR HIGHER SELF.
            </div>
            <div className="title-content col-sm-12 mb-3">
              The Island peak expedition is an adventurous journey that takes
              you to the wilds and tests your limitations. You will be
              sacrificing your comfortable modern life, but at the same time,
              you will be getting strong in the remote mountains of the
              Himalayas. Away from the bustle and noise of the cities, you'll
              embark on a journey in serenity. Nature will compose your
              thoughts. You get to unwind and rejuvenate. The fresh mountain
              air, the crisp hint of coldness, the gushing sound of rivers,
              lovely waterfalls, the chirping of birds, and the numerous
              silhouettes of the Himalayas adds more character to the journey.
            </div>
          </div>
        </div>
        <div
          ref={ref6}
          className="row wrap justify-content-between col-sm-12 my-3"
        >
          <div className="col-sm-12 col-md-6">
            <div className="big-title col-sm-12 mb-3">
              EXPLORE THE FAMOUS NAMCHE BAZAAR, EVEREST BASE CAMP, AND KALA
              PATTHAR BEFORE SCALING THE ISLAND PEAK (IMJA TSE).
            </div>
            <div className="title-content col-sm-12 mb-3">
              Climb a mountain in the Himalayas in 19 days with the Adventure
              Master Treks team during the Island peak climbing. Follow the
              footstep of Tenzing Norgay and Edmund Hillary through the lush
              rhododendron forests, Sherpa villages, and glacier moraines to the
              base camp of Mount Everest (8,849 m/29,032 ft), the tallest
              mountain in the world. Hike to Kala Patthar (5,644 m/18,517 ft) to
              catch a gorgeous sunrise and ascend to Island Peak Base Camp
              (5,644 m/18,517 ft). Enjoy excellent acclimatization hikes to
              Hotel Everest View (3,962 m/13,000 ft) and Nagarjuna Hill (5,100
              m/16,732 ft) in Namche Bazaar (3,440 m/11,290 ft) and Dingboche
              (4,410 m/14,470 ft), respectively. Following the guidance of the
              team leader, scale Island peak and return to base camp safely.
              We’ll clean the base camp before beginning our trek back to Lukla
              (2,846 m/9,337 ft).
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <img
              src={ebctrek1}
              alt="everest"
              className="col-sm-12 mb-3 long-img"
            />
          </div>
        </div>
      </div>
      {/* <div className="container map"></div> */}
      <div className="main-modal">
        <Modals
          sendmodaldetails={sendmodaldetails}
          tripTitle={tripTitle}
          tripPrice={tripPrice}
        />
      </div>
    </>
  );
};

export default Overview;
