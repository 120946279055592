import React from "react";

const Header = (props) => {
  const { title } = props;
  return (
    <div>
      <div className="header-image">{title}</div>
    </div>
  );
};
export default Header;
