import React from "react";

import { FaCheckCircle } from "react-icons/fa";
import CancelIcon from "@mui/icons-material/Cancel";
import TripFact from "./TripFact";

import Accordion from "react-bootstrap/Accordion";

export default function Overview() {
  return (
    <div className='overview'>
      <div className='container d-flex mt-5'>
        <div className='row'>
          {/* <div className="col-lg-9 col-sm-12"> */}
          <div className='col-12'>
            <TripFact />
            <h4 className='overview-title mt-4'>Outline Itinerary</h4>
            <ul className='p-0'>
              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 01:</strong>
                </div>
                <li>Arrival in Kathmandu</li>
              </div>

              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 02:</strong>
                </div>
                <li>Kathmandu: sightseeing & trip preparation</li>
              </div>

              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 03:</strong>
                </div>
                <li>
                  Fly to Lukla (2,846 m/9,337 ft), trek to Paiya (Chutok) (2,800
                  m/9,187 ft)
                </li>
              </div>

              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 04:</strong>
                </div>
                <li>Paiya to Panggom (2,846 m/9,338 ft)</li>
              </div>

              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 05:</strong>
                </div>
                <li>Panggom to Ningsow (2,863 m/9,394 ft)</li>
              </div>

              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 06:</strong>
                </div>
                <li>Ningsow to Chhatra Khola (2,800 m/9,187 ft)</li>
              </div>

              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 07:</strong>
                </div>
                <li>Chhatra Khola to Kothe (3,691 m/12,110 ft)</li>
              </div>

              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 08:</strong>
                </div>
                <li>Kothe to Thaknak (4,358 m/14,299 ft)</li>
              </div>

              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 09:</strong>
                </div>
                <li>Thaknak to Khare (5,045 m/16,553 ft)</li>
              </div>

              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 10:</strong>
                </div>
                <li>Khare: Acclimatization and pre-climb training</li>
              </div>

              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 11:</strong>
                </div>
                <li>Khare to Mera High Camp (5,780 m/18,964 ft)</li>
              </div>

              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 12:</strong>
                </div>
                <li>
                  Mera High Camp to Summit and back to Khare (6,476 m/21,247 ft)
                </li>
              </div>

              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 13:</strong>
                </div>
                <li>Reserve Day for Contingency (5,045m/16,553ft)</li>
              </div>

              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 14:</strong>
                </div>
                <li>Khare to Kothe (3,691 m/12,110 ft)</li>
              </div>

              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 15:</strong>
                </div>
                <li>Kothe to Thuli Kharka (4,300 m/14,108 ft)</li>
              </div>

              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 16:</strong>
                </div>
                <li>
                  Thuli Kharka to Lukla (2,846 m/9,337 ft) via Zatrwa La pass
                  (4,600 m/15,093 ft)
                </li>
              </div>

              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 17:</strong>
                </div>
                <li>Fly to Kathmandu (1,440m/4,724 ft)</li>
              </div>

              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 18:</strong>
                </div>
                <li>Final Departure</li>
              </div>
            </ul>

            <h3 className='overview-title pt-5'>Trip Highlight</h3>
            <ul className='trip-highlight'>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  <strong>Summit a 6,476 meter (21,246 ft) peak:</strong> Mera
                  peak is the highest trekking peak in Nepal, offering a
                  challenging and rewarding climb for experienced trekkers.
                </li>
              </span>

              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  <strong>
                    Explore the diverse landscapes of the Solu-Khumbu region:
                  </strong>
                  The trek to Mera peak passes through a variety of landscapes,
                  including lush forests, alpine meadows, and glacial valleys.
                </li>
              </span>

              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  <strong>
                    Experience the culture and way of life of the Sherpa people:
                  </strong>
                  The trek to Mera peak passes through several traditional
                  Sherpa villages, allowing you to learn about and interact with
                  the local culture.
                </li>
              </span>

              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  <strong>Take in breathtaking views of the Himalayas:</strong>{" "}
                  From the summit of Mera peak, you can enjoy panoramic views of
                  several of the highest peaks in the world, including Mount
                  Everest, Lhotse, and Cho Oyu.
                </li>
              </span>
            </ul>

            <div className='row d-flex justify-content-between'>
              <div className='col-12'>
                <h3 className='overview-title pt-5'>Cost Include</h3>
                <ul className='cost-include'>
                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>
                      All ground travel, including getting to and from the
                      airport, is done in a private car.
                    </li>
                  </span>

                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>
                      One night accomodation at a standard hotel in Kathmandu
                      with breakfast included as per the itinerary.
                    </li>
                  </span>

                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>
                      During the trek, you will be provided with three meals a
                      day: breakfast, lunch, and dinner with one cup of tea each
                      meal.
                    </li>
                  </span>

                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>
                      Full board accommodation of lodges or guest houses in twin
                      sharing based during the trek, and tents will be available
                      during the climbing period.
                    </li>
                  </span>

                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>All camping equipment in base camp.</li>
                  </span>

                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>
                      Flight ticket from Kathmandu-Lukla-Kathmandu for members
                      and staff his salary, food, drinks, lodging,
                      transportation, and insurance covered.
                    </li>
                  </span>

                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>
                      Strong, helpful porters with the right safety gear and
                      walking gear, as well as a salary, food, a place to live,
                      and insurance. (one porter for two people).
                    </li>
                  </span>

                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>
                      A map of a trek and a certificate of completion of the
                      trip.
                    </li>
                  </span>

                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>Taxes and official costs paid to the government.</li>
                  </span>

                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>
                      All papers that need to be done, Fees for entering permits
                      you need to go while hiking.
                    </li>
                  </span>

                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>Duffle bag for trekking.</li>
                  </span>

                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>
                      First aid kit- Oxymeter to check your pulse, oxygen
                      saturation, and heart rate twice a day (very helpful to
                      check for signs of Altitude Mountain Sickness, or AMS).
                      This will make sure that your trek is in the safest hands
                      possible, no matter how experienced you are.
                    </li>
                  </span>
                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>
                      Necessary fixed and dynamic ropes will be available during
                      the climbing period.
                    </li>
                  </span>
                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>
                      The necessary kitchen crew will be available in the base
                      camp.
                    </li>
                  </span>
                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>
                      Complete pre-departure information, flight ticket
                      reconfirmation, and extend visa service(if necessary).
                    </li>
                  </span>
                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>Farewell Dinner in Kathmandu before departure.</li>
                  </span>
                </ul>
              </div>

              <div className='col-12'>
                <h3 className='overview-title pt-5'>Cost Exclude</h3>
                <ul className='cost-exclude'>
                  <span>
                    <div className='cost-exclude-icon'>
                      <CancelIcon />
                    </div>
                    <li>
                      Food that you will eat in Kathmandu for lunch and dinner.
                    </li>
                  </span>

                  <span>
                    <div className='cost-exclude-icon'>
                      <CancelIcon />
                    </div>
                    <li>
                      <p>
                        The cost of a visa to enter Nepal is
                        <br />
                        $30 USD for a 15-day stay,
                        <br />
                        30 Days - $50,
                        <br />
                        90 Days - $125
                      </p>
                    </li>
                  </span>

                  <span>
                    <div className='cost-exclude-icon'>
                      <CancelIcon />
                    </div>
                    <li>Your insurance for travel and medical care.</li>
                  </span>

                  <span>
                    <div className='cost-exclude-icon'>
                      <CancelIcon />
                    </div>
                    <li>International airfare.</li>
                  </span>

                  <span>
                    <div className='cost-exclude-icon'>
                      <CancelIcon />
                    </div>
                    <li>Your personal expenses.</li>
                  </span>

                  <span>
                    <div className='cost-exclude-icon'>
                      <CancelIcon />
                    </div>
                    <li>
                      All of the alcoholic and non-alcoholic cold and hot drinks
                      you buy on trek (along the way and in the Tea Houses at
                      night).
                    </li>
                  </span>

                  <span>
                    <div className='cost-exclude-icon'>
                      <CancelIcon />
                    </div>
                    <li>
                      All of the sweet desserts, like chocolate, cake, pie, and
                      pudding.
                    </li>
                  </span>

                  <span>
                    <div className='cost-exclude-icon'>
                      <CancelIcon />
                    </div>
                    <li>
                      The tea houses have hot showers and places to charge your
                      phone which will cost you about 2$ to 5$.
                    </li>
                  </span>

                  <span>
                    <div className='cost-exclude-icon'>
                      <CancelIcon />
                    </div>
                    <li>
                      Tip to the guide, the porter, and the driver. (Tipping is
                      expected).
                    </li>
                  </span>
                </ul>
              </div>
            </div>

            <h4 className='overview-title pt-5'>FAQ</h4>

            <div className='trip-overview-accordion'>
              <Accordion defaultActiveKey='0' flush>
                <Accordion.Item eventKey='0'>
                  <Accordion.Header>
                    <p class='accordion-title'>
                      1) How hard is it to climb Mera Peak?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='overview-content'>
                      Mera peak is the highest trekking peak in Nepal. In terms
                      of technical difficulty, Mera peak is not that difficult,
                      but the high altitude makes climbing quite arduous for
                      beginner climbers. The summit route is straight forward,
                      but the exposure to sun and cold with added high elevation
                      & low oxygen level stack up the difficulty.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='1'>
                  <Accordion.Header>
                    <p class='accordion-title'>
                      2) How much does it cost to climb Mera Peak?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='accordion-content'>
                      The cost to climb Mera peak begins at USD 2000 and goes
                      above USD 2500, depending upon the itinerary, facility,
                      and service of the company. At Adventure Master Treks, we
                      offer good discounts for groups above 5 people. For more
                      information, feel free to{" "}
                      <strong>
                        <a
                          href='https://adventuremastertrek.com/contact'
                          target='_blank'
                        >
                          contact us.
                        </a>
                      </strong>
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='2'>
                  <Accordion.Header>
                    <p class='accordion-title'>
                      3) Is Mera Peak harder than Island Peak?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='overview-content'>
                      Yes, Mera Peak is harder than climbing Island peak. First
                      of all, Mera peak is located in a remote part of the
                      Khumbu region. The trial does not have facilities like on
                      the way to Island peak. Plus, Mera peak is higher than
                      Island Peak, and the trekking route to Mera peak is also
                      much more steep & offbeat.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='3'>
                  <Accordion.Header>
                    <p class='accordion-title'>
                      4) Can you climb Mera Peak without a guide?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='overview-content'>
                      No, you cannot climb Mera peak without a guide. As per the
                      rules of the Nepal government, no foreigner is allowed to
                      climb any peak or mountain in Nepal without a guide.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='4'>
                  <Accordion.Header>
                    <p class='accordion-title'>
                      5) How do I prepare for Mera Peak?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='overview-content'>
                      To prepare for Mera peak, you can do strength training,
                      cardiovascular training, flexibility training, and if
                      possible, expose yourself to similar climbing conditions.
                      You can go hiking or short trekking with a backpack that
                      weighs at least 5 kg. Go on regular walks and also work on
                      your mental endurance.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='5'>
                  <Accordion.Header>
                    <p class='accordion-title'>
                      6) How long does it take to climb Mera Peak?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='overview-content'>
                      From Mera base camp, you climb to Mera high camp and push
                      towards the summit. From the high camp, you can climb to
                      the summit and return to base camp in 9 to 10 hours.
                      Likewise, to reach Mera base camp, you have to trek for
                      seven days.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='6'>
                  <Accordion.Header>
                    <p className='accordion-title'>
                      7) Can a beginner climb Mera Peak?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='overview-content'>
                      Mera Peak is a non-technical mountain and therefore
                      recommended to beginner climbers. You need to have
                      previous high-altitude experience, though. Our mountain
                      guides will give you pre-climbing training before pushing
                      towards the summit, so you do not have to worry about
                      climbing knowledge.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='7'>
                  <Accordion.Header>
                    <p class='accordion-title'>8) What boots for Mera Peak?</p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='overview-content'>
                      You have to use double-layer expedition boots (one good
                      for above 6,000 meters) to climb Mera peak. Similarly, you
                      will be using different trekking booths while walking up
                      to the base camp of Mera peak.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='8'>
                  <Accordion.Header>
                    <p class='accordion-title'>
                      9) Can you see Everest from Mera Peak?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='overview-content'>
                      Yes, you can see Mount Everest from Mera Peak. Moreover,
                      you can also see jaw dropping perspectives of other
                      eight-thousand mountains like Cho Oyu, Makalu, Lhotse, and
                      Kanchenjunga.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='9'>
                  <Accordion.Header>
                    <p class='accordion-title'>
                      10) How is the phone signal on the way to Mera Peak?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='overview-content'>
                      There is no phone signal on the way to the summit of Mera
                      Peak. Our mountain guide carries a satellite phone to stay
                      connected with the crew at the base camp and in our office
                      in Kathmandu.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='10'>
                  <Accordion.Header>
                    <p class='accordion-title'>
                      11) Is there any ATM on the route?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='overview-content'>
                      No, there is no ATM on the Mera peak trekking route.
                      Therefore, withdraw ample cash to use while trekking in
                      Kathmandu.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>

          {/* sidebar price
          <div className="col-lg-3 col-sm-12">
            <div className="overview-sidebar bg-light">
              <span className="badge text-wrap">Top Seller</span>
              <div className="">
                <span className="overview-sidebar-days">15 Days</span>
                <br />
                <span className="overview-sidebar-destination">
                  Kathmandu to Kathmandu
                </span>
                <br />
                <h4 className="price-text my-3">From USD $1190</h4>
                <div className="group-discount mb-3">
                  <table className="group-discount-table">
                    <p>Group Discount</p>
                    <tr>
                      <td>Pax</td>
                      <td>Price</td>
                    </tr>
                    <tr>
                      <th>1 Pax</th>
                      <th>$N/A</th>
                    </tr>
                    <tr>
                      <th>2 Pax</th>
                      <th>$N/A</th>
                    </tr>
                    <tr>
                      <th>3 Pax</th>
                      <th>$N/A</th>
                    </tr>
                    <tr>
                      <th>4-6 Pax</th>
                      <th>$N/A</th>
                    </tr>
                    <tr>
                      <th>7-10 Pax</th>
                      <th>$N/A</th>
                    </tr>
                    <tr>
                      <th>11-18 Pax</th>
                      <th>$N/A</th>
                    </tr>
                    <tr>
                      <th>19-24 Pax</th>
                      <th>$N/A</th>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="">
                <HashLink smooth to="/booking" className="link">
                  <button className="book-btn">Book Now</button>
                </HashLink>
              </div>
            </div>
          </div> */}

          {/* <div className="col-lg-3 col-sm-12">
            <div className="overview-sidebar">
              <span className="badge bg-primary text-wrap">Top Seller</span>
              <div className="my-3">
                <span className="overview-sidebar-days">9 Days</span>
                <br></br>
                <span className="overview-sidebar-destination">
                  Kathmandu to Kathmandu
                </span>
              </div>
              <span className="overview-sidebar-price text-danger fw-semibold">
                <p className="overview-sidebar-from">From</p>
                <sup className="text-danger fw-semibold text-align-center">
                  $
                </sup>
                1199
                <sup className="text-danger fw-semibold text-align-center">
                  USD
                </sup>
              </span>
              <br></br>
              <div className="py-4">
                <HashLink smooth to="/booking" className="link">
                  <button className="btn btn-danger">Book Now</button>
                </HashLink>
                <button className="btn btn-primary mx-2">Enquiry</button>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
