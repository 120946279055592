import React from "react";

import santosh1 from "../../image/team/santosh_adm.jpg";
import milan from "../../image/team/milan.jpeg";
import guy from "../../image/team/guy.jpg";
import majid from "../../image/team/majid.jpeg";
import nikesh from "../../image/team/nikesh.jpeg";
import madhab from "../../image/team/madhab.jpeg";
import rifat from "../../image/team/rifat.jpeg";
import michaela from "../../image/team/michaela.jpeg";

import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";

import { FaWhatsapp, FaEnvelope } from "react-icons/fa";
import { HashLink } from "react-router-hash-link";

function teamCard() {
  return (
    <div>
      <div className='row my-5 team'>
        <h2 className=''>Meet Our Team</h2>
        <div className='col-sm-12 col-md-6 col-lg-3'>
          <Card className='team-card text-center'>
            <div className='team-card-img'>
              <HashLink smooth to='/santosh' className='link'>
                <Card.Img variant='top' src={santosh1} />
              </HashLink>
            </div>
            <Card.Body>
              <span className='photo-caption'>Nepal</span>
              <Card.Title>Khom Raj Adhikari (Santosh)</Card.Title>
              <Card.Text>
                {/* CEO
                <br /> */}
                <a
                  href='https://api.whatsapp.com/send/?phone=9779851033195&text&type=phone_number&app_absent=0'
                  target='_blank'
                  rel='noreferrer'
                >
                  <FaWhatsapp className='mx-1' />
                  +977 985 1033195
                  <br />
                </a>
                <a
                  href='mailto:info@adventuremaster.com'
                  target='_blank'
                  rel='noreferrer'
                >
                  <FaEnvelope className='mx-1' />
                  info@adventuremaster.com
                </a>
              </Card.Text>
              {/* <HashLink smooth to="/santosh" className="link">
                <Button variant="outline-secondary">View Detail</Button>
              </HashLink> */}
            </Card.Body>
          </Card>
        </div>

        <div className='col-sm-12 col-md-6 col-lg-3'>
          <Card className='team-card text-center lh-5'>
            <div className='team-card-img'>
              <Card.Img variant='top' src={milan} />
            </div>
            <Card.Body>
              <span className='photo-caption'>Nepal</span>
              <Card.Title>Milan Pandey</Card.Title>
              <Card.Text>
                {/* Co-Founder
                <br /> */}
                <a
                  href='https://api.whatsapp.com/send/?phone=9779849780311&text&type=phone_number&app_absent=0'
                  target='_blank'
                  rel='noreferrer'
                >
                  <FaWhatsapp className='mx-1' />
                  +977 9849 780311
                </a>
                <br />
                <a
                  href='mailto:info@adventuremaster.com'
                  target='_blank'
                  rel='noreferrer'
                >
                  <FaEnvelope className='mx-1' />
                  info@adventuremaster.com
                </a>
              </Card.Text>
              {/* <Button variant="outline-secondary">View Detail</Button> */}
            </Card.Body>
          </Card>
        </div>

        <div className='col-sm-12 col-md-6 col-lg-3'>
          <Card className='team-card text-center lh-5'>
            <div className='team-card-img'>
              <Card.Img variant='top' src={guy} />
            </div>
            <Card.Body>
              <span className='photo-caption'>Britain</span>
              <Card.Title>Guy Harper</Card.Title>
              <Card.Text>
                {/* Team Member
                <br /> */}
                <a
                  href='https://api.whatsapp.com/send/?phone=447983806200&text&type=phone_number&app_absent=0'
                  target='_blank'
                  rel='noreferrer'
                >
                  <FaWhatsapp className='mx-1' />
                  +44 7983 806200
                </a>
                <br />
                <a
                  href='mailto:info@adventuremaster.com'
                  target='_blank'
                  rel='noreferrer'
                >
                  <FaEnvelope className='mx-1' />
                  info@adventuremaster.com
                </a>
              </Card.Text>
              {/* <Button variant="outline-secondary">View Detail</Button> */}
            </Card.Body>
          </Card>
        </div>

        <div className='col-sm-12 col-md-6 col-lg-3'>
          <Card className='team-card text-center lh-5'>
            <div className='team-card-img'>
              <Card.Img variant='top' src={michaela} />
            </div>
            <Card.Body>
              <span className='photo-caption'>Italy</span>
              <Card.Title>Michaela Markart</Card.Title>
              <Card.Text>
                {/* Team Member
                <br /> */}
                <a
                  href='https://api.whatsapp.com/send/?phone=393488981381&text&type=phone_number&app_absent=0'
                  target='_blank'
                  rel='noreferrer'
                >
                  <FaWhatsapp className='mx-1' />
                  +39 348 898 1381
                </a>
                <br />
                <a
                  href='mailto:info@adventuremaster.com'
                  target='_blank'
                  rel='noreferrer'
                >
                  <FaEnvelope className='mx-1' />
                  info@adventuremaster.com
                </a>
              </Card.Text>
              {/* <Button variant="outline-secondary">View Detail</Button> */}
            </Card.Body>
          </Card>
        </div>
      </div>

      <div className='row my-5 team'>
        <div className='col-sm-12 col-md-6 col-lg-3'>
          <Card className='team-card text-center lh-5'>
            <div className='team-card-img'>
              <Card.Img variant='top' src={majid} />
            </div>

            <Card.Body>
              <span className='photo-caption'>Barahin</span>
              <Card.Title>Majid Najam</Card.Title>
              <Card.Text>
                {/* Team Member
                <br /> */}
                <a
                  href='https://api.whatsapp.com/send/?phone=97334383250&text&type=phone_number&app_absent=0'
                  target='_blank'
                  rel='noreferrer'
                >
                  <FaWhatsapp className='mx-1' />
                  +973 3438 3250 (Barahin)
                </a>
                <br />
                <a
                  href='mailto:info@adventuremaster.com'
                  target='_blank'
                  rel='noreferrer'
                >
                  <FaEnvelope className='mx-1' />
                  info@adventuremaster.com
                </a>
              </Card.Text>
              {/* <Button variant="outline-secondary">View Detail</Button> */}
            </Card.Body>
          </Card>
        </div>

        <div className='col-sm-12 col-md-6 col-lg-3'>
          <Card className='team-card text-center lh-5'>
            <div className='team-card-img'>
              <Card.Img variant='top' src={rifat} />
            </div>
            <Card.Body>
              <span className='photo-caption'>Barahin</span>
              <Card.Title>Rifat Najam</Card.Title>
              <Card.Text>
                {/* Team Member
                <br /> */}
                <a
                  href='https://api.whatsapp.com/send/?phone=97336630786&text&type=phone_number&app_absent=0'
                  target='_blank'
                  rel='noreferrer'
                >
                  <FaWhatsapp className='mx-1' />
                  +973 3663 0786
                </a>
                <br />
                <a
                  href='mailto:info@adventuremaster.com'
                  target='_blank'
                  rel='noreferrer'
                >
                  <FaEnvelope className='mx-1' />
                  info@adventuremaster.com
                </a>
              </Card.Text>
              {/* <Button variant="outline-secondary">View Detail</Button> */}
            </Card.Body>
          </Card>
        </div>

        <div className='col-sm-12 col-md-6 col-lg-3'>
          <Card className='team-card text-center'>
            <div className='team-card-img'>
              <Card.Img variant='top' src={nikesh} />
            </div>
            <Card.Body>
              <span className='photo-caption'>Russia</span>
              <Card.Title>Nikesh Acharya</Card.Title>
              <Card.Text>
                {/* SEO & Marketing Head
                <br /> */}
                <a
                  href='https://api.whatsapp.com/send/?phone=79531748198&text&type=phone_number&app_absent=0'
                  target='_blank'
                  rel='noreferrer'
                >
                  <FaWhatsapp className='mx-1' />
                  +79 5317 48198
                  <br />
                </a>
                <a
                  href='mailto:info@adventuremaster.com'
                  target='_blank'
                  rel='noreferrer'
                >
                  <FaEnvelope className='mx-1' />
                  info@adventuremaster.com
                </a>
              </Card.Text>
              {/* <Button variant="outline-secondary">View Detail</Button> */}
            </Card.Body>
          </Card>
        </div>

        {/* <div className="col-sm-12 col-md-6 col-lg-3">
          <Card className="team-card text-center lh-5">
            <div className="team-card-img">
              <Card.Img variant="top" src={madhab} />
            </div>
            <Card.Body>
              <span className="photo-caption">Australia</span>
              <Card.Title>Madhab Dakal</Card.Title>
              <Card.Text>
                Digital Marketing Head
                <br />
                <a
                  href="https://api.whatsapp.com/send/?phone=0433516355&text&type=phone_number&app_absent=0"
                  target="_blank" rel="noreferrer"
                >
                  <FaWhatsapp className="mx-1" />
                  +0433 516 355
                </a>
                <br />
                <a href="mailto:info@adventuremaster.com" target="_blank" rel="noreferrer"><FaEnvelope className="mx-1" />
                  info@adventuremaster.com
                </a>
              </Card.Text>
              <Button variant="outline-secondary">View Detail</Button>
            </Card.Body>
          </Card>
        </div> */}
      </div>
    </div>
  );
}

export default teamCard;
