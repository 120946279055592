import React, { useState } from "react";
import {
  AiOutlineArrowUp,
  AiOutlineArrowDown,
  AiOutlineCloseCircle,
} from "react-icons/ai";
import { HashLink } from "react-router-hash-link";

const Modals = (props) => {
  const { tripTitle, tripPrice, sendmodaldetails } = props;
  const { id, modalhead, modaldetail } = sendmodaldetails;
  const [expandModal, setExpandModal] = useState(false);

  const modaldetailtrip = [
    {
      id: 1,
      modaldetail: [
        {
          modaltitle: "Difficulty Level:",
          modaltext:
            "The trek takes nine days and goes through the northeastern Himalayas in Nepal. Overall, the trip is rated as moderate, and new trekkers who can walk 5 to 6 hours a day are encouraged to take it. After a few days and when you get to a high altitude, we think the EBC trek can get a little harder. The best thing about the trek back to Everest Base Camp by helicopter is that you do not have to walk back. Going down the Himalayan trail is just as hard as going up it. And by following this plan, you can get rid of it.",
        },
        // {
        //   modaltitle: "Terrain:",
        //   modaltext:
        //     "The Everest base camp trekking route has a blend of plain and rough paths. The first steep ascent you encounter is while approaching Namche Bazaar from Phakding. From here, there are many steep ups & downs throughout the trek. Unless the trail goes beyond the treeline, you walk through dense alpine woodlands and along the verdant foothills. The trail crosses many thrilling suspension and old wooden bridges over rushing rivers & streams. A final couple of days before reaching EBC involves a glacial walk and passes by a narrow moraine.",
        // },
        // {
        //   modaltitle: "Altitude Sickness:",
        //   modaltext:
        //     "Altitude sickness is an illness seen during high-altitude ventures. It's common, at the same time avoidable and treatable. Flying from Kathmandu to Lukla airport itself brings a drastic change in the elevation in a short time. Therefore, we descend to Phakding village on the same day we fly to acclimatize our bodies. Likewise, we have two acclimatization days in our itinerary that provides ample time for trekkers to adjust to the elevation. The acclimatization days are well used by hiking to a high point and returning to the same village to sleep low. We also make sure the group does not ascend more than 500 meters a day.",
        // },
        // {
        //   modaltitle: "Team:",
        //   modaltext:
        //     "Our Adventure Master team is composed of the most experienced trekking leaders and porters who know the Everest base camp trek route inside-out. We are your map on the trail that not only shows the path but also tells all the stories & myths encircling the trail. We help you hone your trekking and survival skills amidst the mountains of the Himalayas.",
        // },
        // {
        //   modaltitle: "Support:",
        //   modaltext:
        //     "Our Everest base camp trekking package has everything that makes the overall trip hassle-free for our guests. Likewise, our team obtains all the permits on your behalf and arranges all other things. We do all the bookings in Kathmandu and along the EBC trail in advance. The porters carry all the heavy luggage during trekking. We are committed to offering safe and satisfying treks in the Himalayas.",
        // },
        // {
        //   modaltitle: "Equipment:",
        //   modaltext:
        //     "Some of the major trekking equipment you need during the Everest base camp trek are a trekking pole, trekking boots, trekking clothes, sleeping bag, pocket torch, toiletries, snacks, etc. You can bring all the gear from your home if you want, but we highly recommend you buy or rent in Thamel, Kathmandu, instead. It'll save you a lot of baggage. Go through the checklist provided once the trip is confirmed and pack according to that.",
        // },
      ],
    },
    {
      id: 2,
      modaldetail: [
        {
          modaltitle: "Khumbu Region:",
          modaltext:
            "The Sherpas live in the Khumbu region, the Solukhumbu district in the northeast of the country, Lukla is at a height of 2,860 meters and the top of Mt. Everest is at a height of 8,849 meters. From Monjo, which is 2,835 m (9,301 ft) high, the Sagarmatha National Park goes all the way up to Everest. Lukla (2,860 m or 9,380 ft), Phakding (2,610 m or 8,563 ft), Namche Bazaar (3,440 m or 11,290 ft), Tengboche (3,867 m or 12,687 ft), Pangboche (3,985 m or 13,074 ft), Dingboche (4,410 m or 14,470 ft), and Lobuche (4,940 m or 16, Gorak Shep, at an altitude of 5,164 m (16,942 ft), is the last place where people live before the Everest base camp.",
        },
        {
          modaltitle: "Difficulty Level:",
          modaltext:
            "The trek takes nine days and goes through the northeastern Himalayas in Nepal. Overall, the trip is rated as moderate, and new trekkers who can walk 5 to 6 hours a day are encouraged to take it. After a few days and when you get to a high altitude, we think the EBC trek can get a little harder. The best thing about the trek back to Everest Base Camp by helicopter is that you do not have to walk back. Going down the Himalayan trail is just as hard as going up it. And by following this plan, you can get rid of it.",
        },
        {
          modaltitle: "Terrain:",
          modaltext:
            "The terrain at Everest base camp is a mix of flat and bumpy paths. The trail is well-made and goes through beautiful river valleys. Because the land is mountainous, there are always difficulties. Before you get to Namche Bazaar, you will walk on a steep path that goes uphill. Most of the hiking trail goes through subtropical and alpine forests. All around the trail are a lot of streams and rivers. You will walk across a lot of hanging bridges and a few wooden ones. After you have gone above the treeline (past Dingboche), you will be walking on and along glaciers. Then there are moraines and narrow canyons.",
        },
        {
          modaltitle: "Altitude Sickness:",
          modaltext:
            "Altitude sickness is a very common problem for people who are traveling to Everest base camp. Not everyone who hikes to Everest base camp gets sick from the high altitude. But it is likely to happen if people do not take enough precautions on the trek. Between Kathmandu and Lukla, there is a big change in altitude in a short amount of time. So, we sleep in the village of Phakding, which is a couple hundred feet below Lukla. Also, we take a day off in Namche Bazaar so that our bodies can get used to the altitude. We always sleep low after walking high. All of these help our guests avoid getting sick from the high altitude. Even if we do everything we can, if a guest shows signs of altitude sickness, the team leader will take care of them on the mountain.",
        },
        {
          modaltitle: "Team:",
          modaltext:
            "At Adventure Master, we have a strong team that takes care of all bookings and reservations for our guests. We have experts in the field who know their jobs well and act in a professional way. Our team will help you from the time you start getting ready until you leave Everest base camp. During your trip with us, we will be with you every step of the way.",
        },
        {
          modaltitle: "Support:",
          modaltext:
            "A professional mountain guide will be with you on the Everest base trek with a helicopter ride back. On the trail, the guide will be your dependable friend who can help you find your way and learn about the area. The porters who carry the duffel bags will also work in groups. We will book all of the accommodations ahead of time, so you don't have to worry about not having a place to stay the night during the busy trekking season. The leader of the group (the guide) will teach you a lot about hiking. Our expert team will help you improve your survival skills and learn more about traveling in the Himalayas. ",
        },
        {
          modaltitle: "Equipment:",
          modaltext:
            "There is nothing special about the gear you need for the EBC helicopter trek. We will give you a list of things to bring. When packing for the Himalayas, it is especially important not to forget small things and to have enough stuff to deal with all kinds of weather. Even if you hike during the best times of year, the weather can change, and you could find yourself in a drizzle, a strong wind, or snow unexpectedly. In Thamel, Kathmandu, you can buy or rent all the clothes and gear you need for trekking.",
        },
      ],
    },
    {
      id: 3,
      modaldetail: [
        {
          modaltitle: "Facilities:",
          modaltext:
            "Do not expect them to be anything like the hotels in Kathmandu. The rooms are small and have two beds and a table that everyone uses. You need to pay for extras like hot showers, charging stations, WiFi, etc., so, bring some extra cash as well. You can stay in a much more comfortable luxury lodge if you pay a little extra. The rooms will be larger and have their own bathroom.",
        },
        {
          modaltitle: "Single room:",
          modaltext:
            "It is hard to get a single room during the busy trekking season. So, please let us know when you book. You will also have to pay a little bit more. In the spring and fall, there are a lot of trekkers and climbers in the Khumbu area. Because of this, travelers sometimes spend the night in the dining area",
        },
        {
          modaltitle: "Hot water:",
          modaltext:
            "Solar panels heat the water for showers at the lodge, which also has hot water. They will, however, charge you a few dollars (around $5) for the service. Most of the time, you can get free water that is warm enough to wash your face.",
        },
        {
          modaltitle: "Charging:",
          modaltext:
            "There are no plugs in the rooms to charge your phone or other device. You can charge your devices for USD 2 in the dining area or near the front desk. Bring extra batteries or a solar charger to avoid having to pay for this.",
        },
        {
          modaltitle: "WiFi:",
          modaltext:
            "You get paid WiFi in villages like Phakding, Namche Bazaar, Tengboche, etc. The WiFi network will not be as strong and stable as it is in cities, but you will still be able to talk to your friends and family. We suggest that you get a Nepali SIM card and use data. It is a much cheaper option, and you can use the internet all the way up to the base camp of Mt. Everest.",
        },
      ],
    },
    {
      id: 4,
      modaldetail: [
        {
          modaltitle: "Cuisine:",
          modaltext:
            "There are foods from all over the world to eat in Kathmandu. Fine dining restaurants have done a great job of putting together cuisines that are among the best in the world. Therefore, In villages like Lukla, Phakding, Namche Bazaar, and Tengboche, you can get food like pizza, burgers, pasta, steak, soup, and baked goods along the EBC trail.",
        },
        {
          modaltitle: "Nepali Flavors:",
          modaltext:
            "You cannot leave Nepal without trying some of its famous foods, like chatamari, which is kind of like a Nepali pizza. In the same way, you should try yomori, momo, sekuwa, sukuti, taas, Newari khaja set, chyang, and other dishes.",
        },
        {
          modaltitle: "Super Food:",
          modaltext:
            "Dal bhat tarkari will be the main meal during the trek. It means rice, lentil soup, vegetables, and a few other dishes on the side. Dal bhat is the main meal in Nepal, and it has all the nutrients you need. This is one of the freshest things you can eat while trekking, and it does not cost too much. Dal bhat will not make you feel sick or make you bloated.",
        },
        {
          modaltitle: "Suitability:",
          modaltext:
            "There are plenty of options for vegetarians, vegans, and gluten-free diners.",
        },
      ],
    },
    {
      id: 5,
      modaldetail: [
        // {
        //   modaltitle: "Khumbu Region:",
        //   modaltext:
        //     "First of all, the Khumbu region is also known as the Everest region because it houses Mt. Everest. In Nepal, the Khumbu region is located northeast of the country and is part of the Solukhumbu district. The elevation ranges from 2,860 meters (Lukla) to 8,849 meters (top of Mt. Everest). The region has a protected area called Sagarmatha National Park that begins at Monjo and goes up to Everest. Lukla, Phakding, Namche Bazaar, Thame, Phortse, Tengboche, Pangboche, Dingboche, Lobuche, and Goraks Shep are some major settlement areas.",
        // },
        // {
        //   modaltitle: "Difficulty Level:",
        //   modaltext:
        //     "Everest base camp trail is considered moderate. As you spend almost 11 days in the remote mountains, the challenges only add up. Yes, there are comfortable lodges and services that make the journey somewhat bearable, but this is not the lifestyle we are used to. So, the trek only gets difficult as it progresses. ",
        // },
        // {
        //   modaltitle: "Terrain:",
        //   modaltext:
        //     "The Everest base camp trekking route has a blend of plain and rough paths. The first steep ascent you encounter is while approaching Namche Bazaar from Phakding. From here, there are many steep ups & downs throughout the trek. Unless the trail goes beyond the treeline, you walk through dense alpine woodlands and along the verdant foothills. The trail crosses many thrilling suspension and old wooden bridges over rushing rivers & streams. A final couple of days before reaching EBC involves a glacial walk and passes by a narrow moraine.",
        // },
        // {
        //   modaltitle: "Altitude Sickness:",
        //   modaltext:
        //     "Altitude sickness is an illness seen during high-altitude ventures. It's common, at the same time avoidable and treatable. Flying from Kathmandu to Lukla airport itself brings a drastic change in the elevation in a short time. Therefore, we descend to Phakding village on the same day we fly to acclimatize our bodies. Likewise, we have two acclimatization days in our itinerary that provides ample time for trekkers to adjust to the elevation. The acclimatization days are well used by hiking to a high point and returning to the same village to sleep low. We also make sure the group does not ascend more than 500 meters a day.",
        // },
        // {
        //   modaltitle: "Team:",
        //   modaltext:
        //     "Our Adventure Master team is composed of the most experienced trekking leaders and porters who know the Everest base camp trek route inside-out. We are your map on the trail that not only shows the path but also tells all the stories & myths encircling the trail. We help you hone your trekking and survival skills amidst the mountains of the Himalayas.",
        // },
        // {
        //   modaltitle: "Support:",
        //   modaltext:
        //     "Our Everest base camp trekking package has everything that makes the overall trip hassle-free for our guests. Likewise, our team obtains all the permits on your behalf and arranges all other things. We do all the bookings in Kathmandu and along the EBC trail in advance. The porters carry all the heavy luggage during trekking. We are committed to offering safe and satisfying treks in the Himalayas.",
        // },
        // {
        //   modaltitle: "Equipment:",
        //   modaltext:
        //     "Some of the major trekking equipment you need during the Everest base camp trek are a trekking pole, trekking boots, trekking clothes, sleeping bag, pocket torch, toiletries, snacks, etc. You can bring all the gear from your home if you want, but we highly recommend you buy or rent in Thamel, Kathmandu, instead. It'll save you a lot of baggage. Go through the checklist provided once the trip is confirmed and pack according to that.",
        // },
      ],
    },
    {
      id: 6,
      modaldetail: [
        {
          modaltitle: "Overview :",
          modaltext:
            "The goal of the Everest Base Camp Helicopter Return Trek is to take this trip to the next level of adventure. If you want to do something unique and different, our EBC helicopter return trek is for you. The package includes your two-night stay in Kathmandu, as well as all your meals, lodging, and the guide and porters' costs. In the same way, the cost of a helicopter ride from Kala Patthar to Kathmandu is included in our package price, so you do not have to pay anything extra. You will climb up to glaciers and moraines as you explore the green river valleys, hillsides, lush forests, and mountain settlements. Along the way, you will see a lot of beautiful sunrises and sunsets over snowy mountains. On the other hand, the humble conversations with locals and visits to old monasteries will make the trek even more interesting.",
        },
        {
          modaltitle: "Restaurants & Tea Shops:",
          modaltext:
            "Restaurants and tea shops: Namche Bazaar has many restaurants and cafes that serve different kinds of food, like pizza, steak, macaroni, and burgers. In other villages, you will find a small but decent selection of food. Along the Everest base camp trekking route, there are also a lot of tea shops that serve the trekkers. Even though the prices are different from in the city, you can still buy snacks and drinks in this far-off area.",
        },
        {
          modaltitle: "ATM/Currency :",
          modaltext:
            "There are ATM lounges in Namche Bazaar. Aside from this village, you will not find any other ATMs along the trail. So, get a lot of Nepali cash for your own needs. The service providers do not take cards or any other form of payment.",
        },
        {
          modaltitle: "Internet:",
          modaltext:
            "During the Everest base trek with a helicopter ride back, you can use both Wi-Fi and cell service. Wi-Fi is not available everywhere on the trail, nor is it a cheap option. We help our guests buy a Nepali SIM card with enough data to get them to Everest Base Camp, if they want to. Do know that the high elevation will make the network less stable and smooth.",
        },
        {
          modaltitle: "Flora + Fauna:",
          modaltext:
            "There are many pines, magnolia, fir, birch, and rhododendron forests along the trail. You will walk through a lot of alpine forests. If you like plants, flowers, animals, and birds, the Sagarmatha National Park is a great place to go trekking. In the upper part of the trail, there are junipers, and it is possible to see a snow leopard, which is very rare.",
        },
        {
          modaltitle: "Locals:",
          modaltext:
            "People who live in the Khumbu area are from groups like the Sherpas, Bhotiya, Limbu, Rai, etc. Each of these groups of people has its own culture and traditions. The people who live there are friendly and open-hearted. You will learn a lot about their lives if you stay in their lodges and talk to them.",
        },
        {
          modaltitle: "Language:",
          modaltext:
            "There are many different languages spoken by people in the Khumbu region. But because it is a popular tourist spot, many people who work there can speak and understand English. The person who travels with you will speak your language and help you talk to people there.",
        },
        {
          modaltitle: "Monastery:",
          modaltext:
            "There are a lot of monasteries on the trail to Everest Base Camp. You will go to both Tengboche and Pangboche monasteries. During your hike to Hotel Everest View, you can also visit Khumjung monastery. Aside from these monasteries, the trail has many stupas, mani walls, chortens, prayer wheels, and prayer flags.",
        },
      ],
    },
    {
      id: 7,
      modaldetail: [
        {
          modaltitle: "Regenration :",
          modaltext:
            "Doing yoga asanas in the area around Everest Base Camp is a great way to sit in peace and calm your soul. You will step out of your normal life and into something new. you will feel refreshed. Yoga in the quiet Himalayas will help you both physically and mentally.",
        },
        {
          modaltitle: "Activities:",
          modaltext:
            "Stretches in the morning, Yoga and meditation can be done by yourself (Or upgradeto yoga trekking, led by a professional yoga instructor), Re-learn how to live slowly and enjoy each moment, Be physically and mentally calm.",
        },
      ],
    },
  ];
  const expandHandler = () => {
    setExpandModal(!expandModal);
  };

  const collapseHandler = () => {
    setExpandModal(false);
  };

  const onTripChange = () => {
    sessionStorage.removeItem("tripTitle");
    sessionStorage.removeItem("tripPrice");
    sessionStorage.setItem("tripTitle", tripTitle);
    sessionStorage.setItem("tripPrice", tripPrice);
  };

  return (
    <>
      <div className={`modals ${expandModal ? "expand" : ""}`}>
        <div className={`modal-head `} onClick={expandHandler}>
          <div className="modal-head-title">
            {!expandModal ? (
              <AiOutlineArrowUp fontSize="2rem" className="px-1" />
            ) : (
              <AiOutlineArrowDown fontSize="2rem" className="px-1" />
            )}
            <div className="head-title">{modalhead}</div>
          </div>
          <div className="iclose">
            {expandModal && (
              <AiOutlineCloseCircle
                fontSize="1.3rem"
                onClick={collapseHandler}
              />
            )}
          </div>
        </div>
        <div className={`modal-body ${expandModal ? "modal-body-expand" : ""}`}>
          {!expandModal ? (
            <>
              {modaldetail &&
                modaldetail?.map((detail) => (
                  <div className="modal-content">
                    <div className="modal-title d-none d-sm-flex">
                      {detail.modaltitle}
                    </div>
                    <div className="modal-text">{detail.modaltext}</div>
                  </div>
                ))}
            </>
          ) : (
            <>
              {modaldetailtrip &&
                modaldetailtrip[id - 1]?.modaldetail?.map((detail) => (
                  <div className="modal-content">
                    <div className="modal-title fw-bold">
                      {detail.modaltitle}
                    </div>
                    <div className="modal-text">{detail.modaltext}</div>
                  </div>
                ))}
            </>
          )}
        </div>
        <HashLink
          smooth
          to="/booking"
          className="modal-book link"
          style={{ color: "#000" }}
          onClick={onTripChange}
        >
          BOOK NOW
        </HashLink>
      </div>
    </>
  );
};

export default Modals;
