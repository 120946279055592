import { useInView } from "react-intersection-observer";
import Modals from "./Modals";

import ebcheli1 from "../../../image/Everest/EBC-Heli/ebcheli.jpg";
import ebcheli from "../../../image/Everest/EBC-Heli/everest-heli-tour.jpeg";
import everest2 from "../../../image/Everest/EBCTrek/everestViewHotel.jpg";
import everest3 from "../../../image/Everest/ebc.jpeg";
import ebcvirtual from "../../../image/Everest/ebcvirtual.webp";

// import { Helmet } from "react-helmet-async";
import { Helmet } from "react-helmet";

const Overview = () => {
  const tripTitle = "Everest Base Camp Helicopter";
  const tripPrice = "Sharing: $1050 pp | Private: $3900 pp";
  const modaltrip = [
    {
      id: 1,
      modalhead: "Trip Overview",
      modaldetail: [
        {
          modaltitle: "Duration",
          modaltext: <strong>5-7 hours</strong>,
        },
        {
          modaltitle: "Maximum Altitude",
          modaltext: <strong>5,644 m /18,519 ft</strong>,
        },
        {
          modaltitle: "Best Season",
          modaltext: <strong>Spring and Autumn</strong>,
        },
      ],
      trip: "Everest Base Camp Trek",
    },
    {
      id: 2,
      modalhead: "The Challenge",
      modaldetail: [
        {
          modaltitle: "Difficulty Level",
          modaltext: <strong>Easy</strong>,
        },
        {
          modaltitle: "Maximum Altitude",
          modaltext: <strong>5,644 m /18,519 ft</strong>,
        },
      ],
      trip: "Everest Base Camp Trek",
    },
    {
      id: 3,
      modalhead: "Accommodations",
      modaldetail: [
        {
          modaltitle: "Accommodation on the trail",
          modaltext: <strong>None</strong>,
        },
        {
          modaltitle: "In Kathmandu",
          modaltext: <strong>None</strong>,
        },
      ],
      trip: "Everest Base Camp Trek",
    },
    {
      id: 4,
      modalhead: "Nutrition",
      modaldetail: [
        {
          modaltitle: "Meals in Kathmandu",
          modaltext: <strong>None</strong>,
        },
        {
          modaltitle: "Meals during the tour",
          modaltext: <strong>Breakfast</strong>,
        },
        {
          modaltitle: "Cuisine",
          modaltext: <strong>Nepali & Continental</strong>,
        },
      ],
      trip: "Everest Base Camp Trek",
    },
    {
      id: 5,
      modalhead: "Regeneration",
      modaldetail: [
        {
          modaltitle: "Morning stretching",
          modaltext: (
            <strong>
              Enjoy the tranquility of the himalayas and rejuvenate your soul,
              get inspired by the giant mountains & befriend them.
            </strong>
          ),
        },
      ],
      trip: "Everest Base Camp Trek",
    },
    {
      id: 6,
      modalhead: "Destination",
      modaldetail: [
        {
          modaltitle: "Duration",
          modaltext: <strong>4-5 hours</strong>,
        },
        {
          modaltitle: "Location",
          modaltext: <strong>Khumbu Region, Nepal</strong>,
        },
        {
          modaltitle: "Province",
          modaltext: <strong>1</strong>,
        },
        {
          modaltitle: "Population",
          modaltext: <strong>9,133+</strong>,
        },
        {
          modaltitle: "Wildlife Species",
          modaltext: <strong>1,500+</strong>,
        },
        {
          modaltitle: "Highest Points",
          modaltext: <strong>Kala Patthar (5,644 m)</strong>,
        },
      ],
      trip: "Everest Base Camp Trek",
    },
  ];
  let sendmodaldetails = {};

  const { ref, inView } = useInView();
  const { ref: ref2, inView: inView2 } = useInView();
  // const { ref: ref3, inView: inView3 } = useInView();
  const { ref: ref4, inView: inView4 } = useInView();
  const { ref: ref5, inView: inView5 } = useInView();
  const { ref: ref6, inView: inView6 } = useInView();

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll, true);
  // }, [handleScroll]);
  if (inView6 === true) {
    sendmodaldetails = modaltrip[5];
  } else if (inView5 === true) {
    sendmodaldetails = modaltrip[4];
  } else if (inView4 === true) {
    sendmodaldetails = modaltrip[3];
  }
  // else if (inView3 === true) {
  //   sendmodaldetails = modaltrip[2];
  // }
  else if (inView2 === true) {
    sendmodaldetails = modaltrip[1];
  } else if (inView === true) {
    sendmodaldetails = modaltrip[0];
  } else {
    sendmodaldetails = modaltrip[5];
  }
  // console.log(
  //   "checking view",
  //   inView,
  //   inView2,
  //   inView3,
  //   inView4,
  //   inView5,
  //   inView6
  // );
  return (
    <>
      <Helmet>
        <title>Everest Base Camp Helicopter Tour</title>
        <meta
          name="description"
          content="Everest Base Camp Helicopter or Heli tour is by far one of the best Everest holiday packages. The trip does not involve any physically straining walking or 2 weeks of time commitment that usually EBC trek asks People of all ages can join our Everest base camp helicopter day tour."
        />
        <meta
          name="keywords"
          content="everest base camp helicopter tour, everest base camp heli tour, ebc heli tour, everest helicopter tour, everest heli tour"
        />
        <link rel="canonical" href="/everest-base-camp-helicopter-tour/info" />
        <meta
          property="og:image"
          content="https://images.unsplash.com/photo-1620734630836-4fa8203b924e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
        />
      </Helmet>
      <div ref={ref} className="trip-details-next container mt-3">
        <div className="trip-fact col-12 flex-sm-wrap mb-3">
          <div className="facts">
            <div className="fact mb-3 col-lg-8">
              <h1>ONE DAY HELICOPTER LANDING TOUR AT EVEREST BASE CAMP</h1>
            </div>
            <div ref={ref} className="title-content col-md-12 col-lg-8 mb-3">
              One of the best ways to see the Himalayas up close is on an
              Everest base camp tour. From Kathmandu, it will take about 3 to 4
              hours to get to Everest base camp by helicopter. The Everest
              helicopter tour is a good choice for people who do not have much
              time. People of all ages and with disabilities can enjoy this kind
              of travel. So, a helicopter tour is a great choice.
            </div>
          </div>
        </div>
        <div ref={ref} className="big-title col-sm-12 col-md-7 mb-3">
          A SAFE AND COMFORTABLE HELICOPTER RIDE TO EVEREST BASE CAMP AND KALA
          PATTHAR
        </div>
        <div ref={ref} className="title-content col-md-12 col-lg-8 mb-3">
          The fastest, easiest, and safest way to do something thrilling. People
          think of it as a high-end way to get around that is worth the money.
          The flight is better because it is shared (you can do this as a group
          if you are alone). OR your group can also rent a helicopter to fly to
          the base camp of Mount Everest. It is a unique way to spend time with
          the people you care about.
        </div>

        <a
          href="https://ebc1dayhelitour.3dvirtualtournepal.com/"
          target="_blank"
          rel="noreferrer"
        >
          <button className="btn btn-dark mb-2">
            Click To View Virtual Map
          </button>

          <img
            ref={ref}
            src={ebcvirtual}
            alt="everest"
            className="col-sm-12 col-md-12 mb-3 w-100"
          />
        </a>
        <div
          ref={ref2}
          className="row wrap justify-content-between col-sm-12 my-3"
        >
          <div className="col-sm-12 col-md-6">
            <img
              src={ebcheli}
              alt="everest"
              className="col-sm-12 mb-3 long-img"
              style={{ height: "auto" }}
            />
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="big-title col-sm-12 mb-3">
              THIS PACKAGE IS THE ONE THAT WE THINK IS THE EASIEST TOUR
            </div>
            <div className="title-content col-sm-12 mb-3">
              This helicopter tour is open to anyone, even if they have never
              been to a high altitude or done anything special to prepare. You
              do not have to train or push your body. A helicopter tour from
              Kathmandu to Everest base camp is a safe and pleasant way to
              travel. Travel to one of the most remote parts of the world with
              the best expert you can find. So, our Everest base camp helicopter
              tour not only gives you a quick look at the Himalayas but also
              lets you touch the ground and feel what it is like to stand there.
            </div>
          </div>
        </div>
        {/* <div
          ref={ref3}
          className="flex row wrap justify-content-between col-sm-12 mt-5"
        >
          <div className="col-sm-12 col-md-6">
            <img
              src={everest1}
              alt="everest"
              className="col-sm-12  mb-3 short-img"
            />
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="big-title col-sm-12 mb-3">
              A COMFORTABLE & SAFE DAY TRIP TO EVEREST BASE CAMP AND KALA
              PATTHAR BY HELICOPTER.
            </div>
            <div className="title-content col-sm-12 mb-3">
              Say hello to the Himalayas at its best on our 1-day Everest base
              camp helicopter tour in Nepal. If you have ever dreamed of
              exploring the famous Everest region and its wonders, then we are
              glad to present to you our Everest base camp helicopter tour with
              a landing package. This is by far one of the best Everest holiday
              packages. The trip does not involve any physically straining
              walking or 2 weeks of time commitment that usually Everest base
              camp trek asks. People of all ages can join our Everest base camp
              helicopter day tour. Moreover, the EBC heli tour also includes
              multiple landings that enhance your flying experience. Fly safely
              with an expert flying crew who well knows the Himalayan air route
              and all the places of interest. Witness astonishing aerial views
              of the snowy mountains, fly over picturesque Sherpa villages,
              beautiful valleys & Khumbu glacier, and have a scrumptious
              breakfast at Hotel Everest View (3,962 m/13,000 ft), enjoying the
              view of Mt. Everest (8,849/29,032 ft).
            </div>
          </div>
        </div> */}
        <div
          ref={ref4}
          className="row wrap justify-content-between col-sm-12 my-3 "
        >
          <div className="col-sm-12 col-md-6">
            <div className="big-title col-sm-12 mb-3">
              BREAKFAST AT ONE OF THE WORLD'S HIGHEST HOTELS
            </div>
            <div className="title-content col-sm-12 mb-3">
              Before we fly back to Kathmandu, we have breakfast at the Everest
              View Hotel. The Everest View Hotel, breakfast is a great time to
              take in the beautiful view of the snow-covered mountains. Most
              trekkers come here as a side trip while they are getting used to
              the altitude in Namche Bazaar.
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <img
              src={everest2}
              alt="everest"
              className="col-sm-12 mb-3 long-img"
              style={{ height: "auto" }}
            />
          </div>
        </div>
        <div
          ref={ref5}
          className="flex row wrap justify-content-between col-sm-12 mt-5 "
        >
          <div className="col-sm-12 col-md-6">
            <img
              src={everest3}
              alt="everest"
              className="col-sm-12  mb-3 short-img"
            />
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="big-title col-sm-12 mb-3">
              THE BEST TIME OF YEAR FOR A HELI-TOUR OF EVEREST
            </div>
            <div className="title-content col-sm-12 mb-3">
              Almost any time of year is a good time for the Everest Heli-tour.
              When planning a Heli-tour, you need to know that the weather will
              be clear and good. The sky is clear and easy to see in the spring
              and fall, which are much better times of year. Except during the
              monsoon season, when it is mostly cloudy in the Everest area.
              During the winter, people can join the tour, as it is less
              crowded.
            </div>
          </div>
        </div>
        <div
          ref={ref6}
          className="row wrap justify-content-between col-sm-12 my-3 "
        >
          <div className="col-sm-12 col-md-6">
            <div className="big-title col-sm-12 mb-3">
              MAJOR ATTRACTION AT EBC
            </div>
            <div className="title-content col-sm-12 mb-3">
              On the way to the Everest base camp, you will be able to see
              beautiful landscapes and scenes from above. You can see beautiful
              Sherpa villages, valleys, and the Khumbu glacier from the air. The
              helicopter will first land in the village of Lukla, where you can
              see some of the most beautiful views in the world. The helicopter
              then lands at 5545m at Kalapatthar, which is the original base
              camp for Mount Everest. The best part is that you can see Mount
              Everest, Mount Nuptse, Lhotse, Khumbuste, Pumori, and Ama Dablam
              right up close. At the Everest View Hotel, breakfast is a great
              time to take in the beautiful view of the snow-covered mountains.
              The world's highest base camp is the best place to see the
              Himalayas. You can also learn about the Sherpa way of life. Take
              beautiful pictures of yourself with the Himalayas in the
              background. Travel to one of the most remote parts of the world
              with the best expert you can find. So, our Everest base camp
              helicopter tour not only gives you a quick look at the Himalayas
              but also lets you touch the ground and feel what it is like to
              stand there.
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <img
              src={ebcheli1}
              alt="everest"
              className="col-sm-12 mb-3 long-img"
            />
          </div>
        </div>
      </div>
      {/* <div className="container map"></div> */}
      <div className="main-modal">
        <Modals
          sendmodaldetails={sendmodaldetails}
          tripTitle={tripTitle}
          tripPrice={tripPrice}
        />
      </div>
    </>
  );
};

export default Overview;
