import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import CancelIcon from "@mui/icons-material/Cancel";

const TripDetail = () => {
  return (
    <>
      <div className='trip-detail container mt-5'>
        <div className='row'>
          <div className='col-2 d-none d-lg-block d-xl-block'>
            <div className='trip-sidebar'>
              <ul>
                <li>
                  <a href='#price'>Price Detail</a>
                </li>
                <li>
                  <a href='#highlight'>Highlight</a>
                </li>
                <li>
                  <a href='#include'>Cost Include</a>
                </li>
                <li>
                  <a href='#exclude'>Cost Exclude</a>
                </li>
                <li>
                  <a href='#disclaimer'>Disclaimer of Itinerary</a>
                </li>

                <li>
                  <a href='#important-note'>Important Note</a>
                </li>
                <li>
                  <a href='#safety'>Safety and Security</a>
                </li>
                <li>
                  <a href='#insurance'>Travel Insurance</a>
                </li>
                <li>
                  <a href='#age'>Minimum Age</a>
                </li>
                <li>
                  <a href='#time'>Best Time</a>
                </li>
                <li>
                  <a href='#tipping'>Tipping</a>
                </li>
                <li>
                  <a href='#activities'>Activities You Can Choose</a>
                </li>
                <li>
                  <a href='#group-leader'>Group Leader - What They Do</a>
                </li>
                <li>
                  <a href='#group-notes'>Size of Group Notes</a>
                </li>
                <li>
                  <a href='#transport'>Transport</a>
                </li>
                <li>
                  <a href='#local-flight'>Local Flight</a>
                </li>
                <li>
                  <a href='#solo-traveler'>Solo Travelers</a>
                </li>
                <li>
                  <a href='#joining'>Joining Instructions</a>
                </li>
                <li>
                  <a href='#arrival'>Arrival Complications</a>
                </li>
                <li>
                  <a href='#emergency-contact'>Emergency Contanct</a>
                </li>
                <li>
                  <a href='#visa'>Visa and Entry Requirement</a>
                </li>
                <li>
                  <a href='#money-exchange'>Money Exchange</a>
                </li>
                <li>
                  <a href='#emergency-fund'>Emergency Fund</a>
                </li>
                <li>
                  <a href='#first-aid'>First Aid</a>
                </li>
                <li>
                  <a href='#packing'>Packing</a>
                </li>
              </ul>
            </div>
          </div>

          <div className='trip-info col-md-12 col-lg-10'>
            {/* ---------------- price starts --------------------- */}
            <h4 id='price' className='trip-detail-title'>
              Price Detail
            </h4>
            Trip Price: USD $1350
            <h4 className='trip-detail-title'>Group Discount</h4>
            2-5 pax --------- USD $1180
            <br />
            5-10 pax ---- USD $999
            {/* ---------------- trip highlight starts --------------------- */}
            <h4 id='highlight' className='trip-detail-title'>
              Highlights
            </h4>
            <ul className='trip-highlight'>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  During the Buddhist pilgrimage tour in Nepal, you can see some
                  of the stupas and monasteries that have been built over
                  hundreds of years.
                </li>
              </span>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  In Kathmandu, visit the Swayambhunath temple and the
                  Bouddhanath Stupa.
                </li>
              </span>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  You can see Kopan monastery and Namobuddha on the edge of
                  Kathmandu.
                </li>
              </span>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>Learn about the roots of Buddhism in Lumbini</li>
              </span>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>Learn more about Gautam Buddha and his childhood</li>
              </span>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Lumbini has many religious sites built by different countries.
                  You can visit the Maya Devi temple, the holy pond, the garden,
                  the museum, the Ashoka pillar, and many others.
                </li>
              </span>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Find out about the culture, traditions, and foods of Nepal
                </li>
              </span>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>Relax, recharge, and get in touch with your higher self</li>
              </span>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Try yoga and meditation, watch monks pray, and look around the
                  local markets
                </li>
              </span>
            </ul>
            {/*---------------------- cost include starts --------------------- */}
            <h4 id='include' className='trip-detail-title'>
              Cost Include
            </h4>
            <ul className='cost-include'>
              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  All ground travel, including getting to and from the airport,
                  is done in a private car.
                </li>
              </span>
              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>Drive from Kathmandu to Namobuddha by private car.</li>
              </span>
              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>Drive from Bhairahawa to lumbini via private car.</li>
              </span>
              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>Kathmandu to Bhairahawa via flight</li>
              </span>
              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Two night stay at a standard hotel in Kathmandu with breakfast
                  included.
                </li>
              </span>
              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  One night accommodation at a standard hotel in Namo Buddha
                  with breakfast included.
                </li>
              </span>
              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Two nights accommodation at a standard hotel in lumbini with
                  breakfast included.
                </li>
              </span>
              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  we will drive to Tilaurakot, near Kapilvastu via private
                  vehicle for sightseeing.
                </li>
              </span>
              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  All papers that need to be done, Fees for entering permits you
                  need to go while your journey.
                </li>
              </span>
              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>Taxes and official costs paid to the government.</li>
              </span>
              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  First-aid kit and a certificate of completion for the trip
                </li>
              </span>
              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  A highly experienced, helpful, knowledgeable, friendly,
                  English-speaking guide with all of his salary, food, drinks,
                  lodging, transportation, and insurance covered.
                </li>
              </span>
            </ul>
            {/*---------------------- cost exclude starts --------------------- */}
            <h4 id='exclude' className='trip-detail-title'>
              Cost Exclude
            </h4>
            <ul className='cost-exclude'>
              <span>
                <div className='cost-exclude-icon'>
                  <CancelIcon />
                </div>
                <li>Food that you will eat for lunch and dinner</li>
              </span>
              <span>
                <div className='cost-exclude-icon'>
                  <CancelIcon />
                </div>
                <li>
                  <p>
                    The cost of a visa to enter Nepal is
                    <br />
                    $30 USD for a 15-day stay,
                    <br />
                    30 Days - $50,
                    <br />
                    90 Days - $125
                  </p>
                </li>
              </span>
              <span>
                <div className='cost-exclude-icon'>
                  <CancelIcon />
                </div>
                <li>Your insurance for travel and medical care.</li>
              </span>
              <span>
                <div className='cost-exclude-icon'>
                  <CancelIcon />
                </div>
                <li>International airfare.</li>
              </span>
              <span>
                <div className='cost-exclude-icon'>
                  <CancelIcon />
                </div>
                <li>Your personal expenses.</li>
              </span>
              <span>
                <div className='cost-exclude-icon'>
                  <CancelIcon />
                </div>
                <li>
                  All of the alcoholic and non-alcoholic cold and hot drinks you
                  buy on the journey.
                </li>
              </span>
              <span>
                <div className='cost-exclude-icon'>
                  <CancelIcon />
                </div>
                <li>
                  All of the sweet desserts, like chocolate, cake, pie, and
                  pudding.
                </li>
              </span>
              <span>
                <div className='cost-exclude-icon'>
                  <CancelIcon />
                </div>
                <li>
                  Tip to the guide, and the driver. (Tipping is expected).
                </li>
              </span>
              <span>
                <div className='cost-exclude-icon'>
                  <CancelIcon />
                </div>
                <li>
                  Meals that are not listed in the "Meals Included" section.
                </li>
              </span>
            </ul>
            {/*---------------------- Disclaimer of Itinerary starts --------------------- */}
            <h4 id='disclaimer' className='trip-detail-title'>
              Disclaimer of Itinerary
            </h4>
            <p className='trip-detail-content'>
              The length of our Buddhist pilgrimage tour is seven days. During
              the whole tour, we will stick to the plan. But if the situation
              calls for it, we might have to make some changes to the plan. This
              kind of thing doesn't happen very often, but if it does, we will
              think about what is best for the participants before making any
              changes to the plan. You will be told everything and given the
              best choices.
            </p>
            {/*---------------------- important notes starts --------------------- */}
            <h4 id='important-note' className='trip-detail-title'>
              Important Notes
            </h4>
            <p className='trip-detail-content'>
              When traveling or visiting holy sites specifically, you have to
              understand the local's practices and be not impolite to them. We
              understand as a foreigner you possibly couldn't know the norms and
              values, but at least you can try to have basic knowledge about the
              place you are visiting and the most general things that you
              shouldn't do. <br />
              You don't want to disrespect the ongoing legacy of the locals and
              hurt their emotional & spiritual sentiments. Therefore, we have
              come up with the below lists that will help you a lot to be
              respectful to locals and mingle with them.
              <ul>
                <li>
                  We say Namaste as a hello to each other in Nepal. The proper
                  way to do so is by touching both of your palms vertically,
                  keeping them close to your heart & bending your head a little.
                </li>
                <li>
                  Open your shoes before entering any temples or monasteries.
                </li>
                <li>
                  Look out for prohibitions on taking pictures & videos. Do not
                  click pictures and make videos if it is not allowed. You may
                  be fined by the authorities.
                </li>
                <li>
                  Respect the practices you come across while visiting the
                  pilgrimage sites, do not make noise or disturb the monks if
                  you see them chanting/meditation.
                </li>
                <li>
                  Get your blessings and give offerings to the deities if you
                  wish.
                </li>
                <li>
                  Do not touch anything randomly, do not litter the monument,
                  and do not use curse words.
                </li>
              </ul>
            </p>
            {/*---------------------- safety and security starts --------------------- */}
            <h4 id='safety' className='trip-detail-title'>
              Safety and Security
            </h4>
            <p className='trip-detail-content'>
              Nepal's Buddhist cultural tour is a very safe thing to do. Nepal
              is one of the world's safest places to travel alone or with your
              family. On top of that, you will be traveling with a trekking and
              tour company that is registered with the government. This makes
              sure that your trip will be safe. You don't have to worry about
              anything because the team at Adventure Master will take care of
              everything you need.
            </p>
            <p className='trip-detail-content'>
              We will book all of your accommodations ahead of time and get all
              of the necessary permits. We will make sure the rooms are safe,
              clean, and comfortable. One of our tour guides will be with you at
              all times to help with whatever you need. Also, when we travel
              between cities, we use the safest and quickest ways to get there.
            </p>
            <p className='trip-detail-content'>
              As planned, we use private vehicles to get around. The tour guide
              will also make sure that everyone in the group is healthy. We
              promise that you will be safe on a Buddhist pilgrimage tour with
              us in Nepal.
            </p>
            {/*---------------------- safety and security starts --------------------- */}
            <h4 id='insurance' className='trip-detail-title'>
              Travel Insurance
            </h4>
            <p className='trip-detail-content'>
              For Buddhist pilgrimage tours, you do not need travel insurance.
              If you want to buy one, you should make sure that it covers things
              like lost luggage, canceled international flights, medical bills,
              etc. Check the document twice before you sign it, and keep it with
              you during the tour. You can't buy travel insurance in Nepal, so
              get one in your own country.
            </p>
            {/*---------------------- minimum age starts --------------------- */}
            <h4 id='age' className='trip-detail-title'>
              Minimum Age
            </h4>
            <p className='trip-detail-content'>
              A Buddhist pilgrimage tour does not have an age requirement. You
              can take this tour with your babies, toddlers, and older family
              members. This is a tour package for families. The only other rule
              is that travelers under 18 must be with a known guardian.
            </p>
            <em>Contact us at any time if you want to know more. </em>
            {/*---------------------- best time starts --------------------- */}
            <h4 id='time' className='trip-detail-title'>
              Best Time
            </h4>
            <p className='trip-detail-content'>
              The Nepal Buddhist Pilgrimage Tour can be done at any time of the
              year. The best months for climate and weather are spring (March to
              April) and fall (October to November). At this time of year, the
              weather will not get in the way, and the tour will go off without
              a hitch.
            </p>
            <p className='trip-detail-content'>
              The weather is nice—neither too hot nor too cold—so you won't need
              to bring much with you and can move around freely. In the country,
              there are also many events in the spring and fall. You might be
              able to join in on some of them and get a close look at the
              Buddhist culture in Nepal.
            </p>
            <p className='trip-detail-content'>
              Between 18°C and 25°C, the temperature varies. Lumbini is in
              Nepal's Terai belt, so it may be a little warm in the spring and
              fall, but you'll be fine.
            </p>
            <h4 className='trip-detail-title'>
              Buddhist pilgrimage tour in summer/rainy season in Nepal
            </h4>
            <p className='trip-detail-content'>
              During the summer and rainy seasons, you can go on a Buddhist
              pilgrimage tour. This season lasts from May to late September. The
              tour can be a little uncomfortable when it's very hot, like when
              the temperature goes above 35 °C in Lumbini. In the same way, it
              rains a lot and can get in the way of the tour.
            </p>
            <p className='trip-detail-content'>
              If you want to do this tour in Nepal during the summer/monsoon
              season, you should get ready and talk to our representative to
              book the best time.
            </p>
            <h4 className='trip-detail-title'>
              In the winter, a Buddhist pilgrimage tour
            </h4>
            <p className='trip-detail-content'>
              It is also possible to go on a Buddhist pilgrimage tour in the
              winter. All you have to do to face the cold is pack well and be
              ready. The monument might not be visible because of the fog in the
              morning. Temperatures fall below 0 degrees Celsius.
            </p>
            {/*---------------------- tipping starts --------------------- */}
            <h4 id='tipping' className='trip-detail-title'>
              Tipping
            </h4>
            <p className='trip-detail-content'>
              Our package price does not include tips for our guide and drivers,
              who will help you during the Buddhist pilgrimage tour. So you can
              give them whatever tips you want. Tips are the best and most
              sincere way to show someone you appreciate their work. You don't
              have to leave a tip, but if the service was good, don't be shy
              about doing so.
            </p>
            <p className='trip-detail-content'>
              If everyone in the group gives $2, that's a good amount for the
              driver. In the same way, everyone in the group should give the
              tour guide between USD 50 and USD 100.
            </p>
            {/*---------------------- Activities You Can Choose starts --------------------- */}
            <h4 id='activities' className='trip-detail-title'>
              Activities You Can Choose
            </h4>
            <p className='trip-detail-content'>
              We offer many tours in Nepal, such as day tours, city tours,
              helicopter tours, jungle safaris, overland tours, and pilgrimage
              tours.
            </p>
            <p className='trip-detail-content'>
              You can choose the program that best fits your needs and send us
              your inquiry. In the same way, you can change the Buddhist
              pilgrimage tour schedule to fit your needs.
            </p>
            <p className='trip-detail-content'>
              We chose some of our best tour packages for you, like the Nepal
              Bhutan tour, Nepal Tibet tour, Nepal India Buddhist pilgrimage
              tour, Lumbini tour, Nepal family tour, etc.
            </p>
            <p className='trip-detail-content'>
              If you want to see how Buddhism is practiced in the remote
              mountains of Nepal's Himalayas, we recommend the following
              packages: the Mani Rimdu festival tour, the Tiji festival tour,
              the Tamang heritage tour, the Sherpa village tour, the Pungyen
              gompa trek, etc.
            </p>
            {/*---------------------- Activities You Can Choose starts --------------------- */}
            <h4 id='group-leader' className='trip-detail-title'>
              Group Leader - What They Do
            </h4>
            <p className='trip-detail-content'>
              During the 7-day Buddhist pilgrimage tour in Nepal, you will be
              with an experienced guide the whole time. The guide will go with
              you through all of the cities and make your trip easy. The guide
              will tell you about the historical sites, how they relate to
              religion, and what the people who live there believe. He will not
              only tell you about the people and culture of the area, but he
              will also make sure that everyone is safe.
            </p>
            <p className='trip-detail-content'>
              Our senior guides have a lot of experience and know what to do.
              They have a lot of experience and have been well trained. They are
              good at what they do and can handle any emergency. The people who
              lead our tours are nice and helpful. They can be counted on. We
              will also give you a guide who speaks your language.
            </p>
            {/*---------------------- Size of Group Notes starts --------------------- */}
            <h4 id='group-notes' className='trip-detail-title'>
              Size of Group Notes
            </h4>
            <p className='trip-detail-content'>
              On our Buddhist pilgrimage tour, there are between 8 and 12 people
              in each group. Solo travelers can join a fixed departure and
              travel with people from all over the world, or they can choose a
              private Buddhist pilgrimage tour. We also take groups of more than
              12 people on Buddhist pilgrimage tours. Depending on how big the
              group is, there may be more guides to help.
            </p>
            {/*---------------------- transport starts --------------------- */}
            <h4 id='transport' className='trip-detail-title'>
              Transport
            </h4>
            <p className='trip-detail-content'>
              As planned, we will pick you up at the airport and drop you off
              there. We always drive our own cars to get where we need to go. If
              you want to rent a car for your own trips, we can help you do that
              for an extra cost.
            </p>
            <p className='trip-detail-content'>
              We also have flights between the airports in Kathmandu and
              Bhairahawa. From Bhairawa, it is about 22 kilometers to Lumbini.
              To go this far, we use a private car or van.
            </p>
            {/*---------------------- local flights starts --------------------- */}
            <h4 id='local-flight' className='trip-detail-title'>
              Local Flight
            </h4>
            <p className='trip-detail-content'>
              Most of the time, flights from Kathmandu to Bhairahawa arrive on
              time. Depending on the weather, there may be some wait times.
              Cancellations are rare. It is a 30 minute flight with no stops. If
              you fly during the monsoon or summer, there may be a lot more
              delays, and your flight could be canceled. If the flight is very
              late or gets canceled, we will drive to Lumbini in a four-wheeler.
              The trip will take between eight and nine hours on a good road.
            </p>
            {/*---------------------- solo travelers starts --------------------- */}
            <h4 id='solo-traveler' className='trip-detail-title'>
              Solor Travelers
            </h4>
            <p className='trip-detail-content'>
              Our Buddhist pilgrimage tour in Nepal is open to people who want
              to go alone. You can choose a private tour or join our group
              departure. We have a lot of group trips that leave in the spring
              and fall, and you can join them whenever it works for you. A
              Buddhist pilgrimage tour is a great thing to do in Nepal if you
              want an in-depth trip and to learn more about the area outside of
              your country. You will always have a guide with you, so you will
              never feel lost or out of place.
            </p>
            {/*---------------------- joining instructions starts --------------------- */}
            <h4 id='joining' className='trip-detail-title'>
              How To Get Together
            </h4>
            <p className='trip-detail-content'>
              To sign up for the Buddhist pilgrimage tour in 2023, you can send
              us a message through our package page or call us at the number we
              have given. You can make reservations for 2023. One of our travel
              agents will help you book your trip and answer any questions you
              have. Once we confirm your reservation, we'll send you a
              personalized packing list and help you with other things.
            </p>
            <p className='trip-detail-content'>
              You can also book early for the 2024 Buddhist pilgrimage tour.
              Booking early gives you time to get ready for your trip, and you
              might even get a discount if you book early.
            </p>
            {/*---------------------- arrival complications starts --------------------- */}
            <h4 id='arrival' className='trip-detail-title'>
              Problems with Arrival
            </h4>
            <p className='trip-detail-content'>
              We know that you could be late getting to Kathmandu, Nepal,
              because of your airline, your health, or something else. If this
              happens, we need to know as soon as possible so we can change the
              reservations. If you don't let us know in time, you'll have to pay
              for an extra night.
            </p>
            <p className='trip-detail-content'>
              You can write to us, call us, or use a messenger app to get in
              touch with us. At the airport, you can use the free WiFi to get in
              touch with us. In the same way, if you missed our representative
              at the airport, you can call or text us or take a taxi to your
              hotel. Please let us know when you get to your hotel.
            </p>
            {/*---------------------- emergency contact starts --------------------- */}
            <h4 id='emergency-contact' className='trip-detail-title'>
              Emergency Contact
            </h4>
            <p className='trip-detail-content'>
              Here are the numbers to call in case of an emergency:+977
              9851033195, +44 7983806200, and +977 9849780311 You can call us at
              any time and talk to one of our travel consultants. Our WhatsApp
              and Viber service is also available 24/7 at these numbers.
            </p>
            <p className='trip-detail-content'>
              Also, you can call us at:+977 9851033195 during office hours
              (Monday through Friday, 9 a.m. to 5 p.m. local time) at our office
              number.
            </p>
            {/*---------------------- visa and entry requirements starts --------------------- */}
            <h4 id='visa' className='trip-detail-title'>
              Visa and Entry Requirements
            </h4>
            <p className='trip-detail-content'>
              To get into Nepal, you need a travel visa that is good for at
              least six months. At all points of entry into Nepal, travelers
              from many countries can get a visa when they arrive. You can land
              at Tribhuvan International Airport and get your visa before
              meeting with our company's representatives. Check to see if your
              country can get a visa when you get there. If not, you will need
              to go to the Nepalese embassy or consulate to get a travel visa.
            </p>
            <p className='trip=detail-content'>
              Adventure Master is not in charge of getting the visa for you. The
              travelers are on their own to get it. Check what kind of travel
              visa you need and make sure you apply for it on time. We'll send
              you a letter confirming your trip, which you can use to get the
              visa. If you can't get a visa and have to cancel your trip, you'll
              have to pay a fee.
            </p>
            {/*---------------------- money exchange starts --------------------- */}
            <h4 id='money-exchange' className='trip-detail-title'>
              Money Exchange
            </h4>
            <p className='trip-detail-content'>
              During your tour, you have to use Nepalese money. You can also use
              credit cards at major shopping centers, restaurants, hotels, and
              cafes. But it's better to bring Nepalese cash to use in local
              markets and to buy souvenirs.
            </p>
            <p className='trip-detail-content'>
              You can change money in Thamel at banks or money exchange shops.
              In the same way, you can also use your international card to get
              Nepali cash from ATMs. But keep in mind that there will be a fee
              for each transaction.
            </p>
            {/*---------------------- emergency fund starts --------------------- */}
            <h4 id='emergency-fund' className='trip-detail-title'>
              Emergency Fund
            </h4>
            <p className='trip-detail-content'>
              We recommend that, in addition to your budget for the Buddhist
              pilgrimage tour, you set some money aside in case of an emergency
              or if you have to stay in Nepal longer than you had planned. With
              this money, you'll be safe.
            </p>
            {/*-------------------------- first aid starts ------------------------------ */}
            <h4 id='first-aid' className='title pt-5'>
              First Aid
            </h4>
            <p>
              For the Buddhist pilgrimage tour, our team will give you a
              personalized packing list that you can use to pack for the trip.
              You'll need to bring the right clothes and shoes. Here is a list
              of things you will need for the tour in general.
            </p>
            {/* --------------- packing --------------- */}
            <h4 id='packing' className='title pt-5'>
              Packing
            </h4>
            <p>
              Our team will give you a customized packing list for the Buddhist
              pilgrimage tour that you can follow to pack for the trip. You need
              to carry proper clothes and comfortable shoes. Below is the list
              of general things you need for the tour.
            </p>
            <h4 className='title pt-3'>Documents</h4>
            <ul>
              <li>Passport, visa</li>
              <li>Tickets for airline</li>
              <li>Copies of passport, tickets, etc</li>
              <li>Drivers license</li>
              <li>List of medications, prescriptions (if any)</li>
              <li>Travel Insurance (optional)</li>
              <li>Student card (if any)</li>
              <li>Extra passport photos</li>
              <li>Pre-arranged visas or medical certificates</li>
              <li>Hotel or tour booking vouchers</li>
            </ul>
            <h4 className='title pt-3'>Travel aids</h4>
            <ul>
              <li>Suitcases, backpack</li>
              <li>Itinerary</li>
              <li>Maps and directions</li>
              <li>Language guide</li>
              <li>Travel guide</li>
              <li>Travel pillow, sleeping mask, earplugs</li>
              <li>Travel locks</li>
              <li>Luggage tags</li>
              <li>Pens and paper</li>
              <li>Snacks, drinks</li>
              <li>Small pocket knife (not in a carry-on!)</li>
              <li>Umbrella</li>
              <li>Sunglasses</li>
            </ul>
            <h4 className='title pt-3'>Financial</h4>
            <ul>
              <li>Foreign currency</li>
              <li>Emergency money</li>
              <li>Credit card, debit card</li>
              <li>Extra wallet</li>
              <li>Money belt</li>
            </ul>
            <h4 className='title pt-3'>Devices</h4>
            <ul>
              <li>Cellphone, charger</li>
              <li>A photo camera, memory card, charger</li>
              <li>Laptop, iPad or Tablet, E-reader, chargers</li>
              <li>Travel adapter and converter</li>
              <li>Flashlight</li>
              <li>Headphones</li>
            </ul>
            <h4 className='title pt-3'>Clothes</h4>
            <ul>
              <li>Underwear</li>
              <li>Socks</li>
              <li>Sleepwear</li>
              <li>Shirts, polos</li>
              <li>Jeans, trousers, shorts</li>
              <li>Dresses, skirts</li>
              <li>Shoes, sneakers</li>
              <li>Flip Flops, slippers</li>
              <li>Jackets, coats, raincoats</li>
              <li>Belts, ties</li>
              <li>Scarves, hats, gloves</li>
              <li>Windcheater</li>
            </ul>
            <h4 className='title pt-3'>Toiletries</h4>
            <ul>
              <li>Toothbrush, paste, dental floss</li>
              <li>Deodorant</li>
              <li>Tweezers (not in a carry-on!)</li>
              <li>Soap, shampoo, conditioner</li>
              <li>Towels</li>
              <li>Nail Care</li>
              <li>Tissues, toilet roll</li>
              <li>Feminine hygiene</li>
              <li>Makeup, makeup remover</li>
              <li>Shaving supplies</li>
              <li>Skin products</li>
              <li>Brush, comb, and hair products</li>
              <li>Glasses, contact lenses, supplies</li>
              <li>Sunscreen</li>
              <li>Hand sanitizer</li>
              <li>Face masks</li>
              <li>Insect repellant</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default TripDetail;
