import React, { useState } from "react";
import {
  AiOutlineArrowUp,
  AiOutlineArrowDown,
  AiOutlineCloseCircle,
} from "react-icons/ai";
import { HashLink } from "react-router-hash-link";
import AnnapurnaCircuitDepartureDateModalTripInfo from "../../components/DepartureDateModal/DepartureDateForTripInfo/AnnapurnaCircuitDepartureDateModalTripInfo";

const Modals = (props) => {
  const { tripTitle, tripPrice, sendmodaldetails } = props;
  const { id, modalhead, modaldetail } = sendmodaldetails;
  const [expandModal, setExpandModal] = useState(false);

  const modaldetailtrip = [
    {
      id: 1,
      modaldetail: [
        // {
        //   modaltitle: "Annapurna Region:",
        //   modaltext:
        //     "Annapurna region is the western part of Nepal. The region houses the seventh, eighth, and tenth highest mountains in the world; Dhaulagiri, Manaslu & Annapurna. The region is protected under the Annapurna Conservation Area. In the north of the main Himalayan ranges (Annapurna & Dhaulagiri) is the trans-Himalayan area and boasts arid landscape & Tibetan Buddhism cultural heritage. Annapurna region is famous for its adventurous trekking routes.",
        // },
        {
          modaltitle: "Annapurna Region:",
          modaltext:
            "Annapurna Region: The Annapurna region is the northwestern part of Nepal. It is a protected area, and has some of the most adventurous trekking routes in the Himalayas. Known for its trekking routes and Mt. Annapurna, the tenth highest mountain in the world, the Annapurna region is very beautiful and resided by people of different communities. ",
        },
      ],
    },
    {
      id: 2,
      modaldetail: [
        {
          modaltitle: "Difficulty Level:",
          modaltext:
            "The difficulty level of the Annapurna circuit trek with Tilicho lake is considered strenuous. This is a very difficult trekking route in the Himalayas that involves high elevation and spending many days above 2,500 meters. Many factors like terrain, distance, walking hours, elevation pressure, limited facilities, etc add up to the difficulty of the Annapurna circuit & Tilicho lake trek. ",
        },
        {
          modaltitle: "Terrain:",
          modaltext:
            "The terrain is rough and steep. The trail has constant ascents and descents along the rocky foothills. You'll be walking through dense forests, land-slide-prone areas, pastures, glacial paths, a mountain pass, boulders-filled paths, and gorges. ",
        },
        {
          modaltitle: "Altitude Sickness:",
          modaltext:
            "There is a risk of altitude sickness during the Annapurna circuit and Tilicho Lake trekking. Our itinerary gives you ample time to adjust to the elevation and enjoy the trek.",
        },
        {
          modaltitle: "Team:",
          modaltext:
            "Our office team will be there for you to help with anything while your journey with us in Nepal. We'll do all the reservations as per the itinerary and offer you the best service",
        },
        {
          modaltitle: "Support:",
          modaltext:
            "An expert team of mountain guides and porters will support you during the Annapurna base camp and Tilicho lake trekking. They will become a bridge between you and the locals. You'll not have to worry about anything as the crew will take care of everything.",
        },
        {
          modaltitle: "Equipment:",
          modaltext:
            "Do not overpack for the trek, at the same time, do not forget to carry basis essentials. Follow our packing list and prepare for the trek according to that. No need to buy everything. If you are not an active trekker, then you can rent (or buy) most of the expensive trekking gear in Thamel, Kathmandu. ",
        },
      ],
    },
    {
      id: 3,
      modaldetail: [
        {
          modaltitle: "Facilities:",
          modaltext:
            " The facilities in the tea houses are basic. Each room is shared between two trekkers of the group. The rooms have separate beds and pillows with a side table. The rooms are quite small in size most of the time, with enough space to walk around. ",
        },
        {
          modaltitle: "Single room",
          modaltext:
            "Single rooms are only available if you inform us during the booking. The package cost covers shared rooms only. So, upgrading the room may slightly change the package cost. ",
        },
        {
          modaltitle: "Hot water:",
          modaltext:
            "Hot water is available to wash your face and do basic cleaning. To take a hot shower, you have to pay a few extra bucks.",
        },
        {
          modaltitle: "Charging:",
          modaltext:
            "Charging facilities are available in all the tea houses. You may be asked to pay a few bucks for the service. As it is a long trek, we recommend you carry spare batteries or a solar charger.",
        },
        {
          modaltitle: "WiFi:",
          modaltext:
            "You can use WiFi in some of the villages at lower elevations by paying a few bucks. The connection may not be strong. Similarly, you also have an option to carry a Nepali sim and use cellular data.",
        },
      ],
    },
    {
      id: 4,
      modaldetail: [
        {
          modaltitle: "Cuisine:",
          modaltext:
            "In Kathmandu and Pokhara, you'll get a wide range of cuisine. Apart from Nepali cuisine, the cities offer Indian, Tibetan, Chinese, Japanese, Korean, Continental, Turkish, Italian, etc cuisines. There are many restaurants where you can enjoy fine dining.",
        },
        {
          modaltitle: "Nepali Flavors:",
          modaltext:
            "One of the most exciting parts of this venture is all the delicious Nepali flavors you'll get to try. Some of the dishes that you should try before leaving Nepal are sekuwa, sukuti, chatamari, bara, yomori, chyang, gundruk, lapsi candy, etc.",
        },
        {
          modaltitle: "Super Food",
          modaltext:
            "Dal bhat is a superfood. You'll be having dal bhat with non-veg curry (optional), veggies, pickles, and other side dishes at least in one of the meals during the trek. This is the most nutritious, fresh, and filling menu.",
        },
        {
          modaltitle: "Suitability:",
          modaltext:
            "Nepal is home to a huge number of vegetarians. Vegetarians, vegans, and gluten-free diners will not have a hard time getting food.",
        },
      ],
    },
    {
      id: 5,
      modaldetail: [
        // {
        //   modaltitle: "Annapurna Region:",
        //   modaltext:
        //     "Annapurna region is the western part of Nepal. The region houses the seventh, eighth, and tenth highest mountains in the world; Dhaulagiri, Manaslu & Annapurna. The region is protected under the Annapurna Conservation Area. In the north of the main Himalayan ranges (Annapurna & Dhaulagiri) is the trans-Himalayan area and boasts arid landscape & Tibetan Buddhism cultural heritage. Annapurna region is famous for its adventurous trekking routes.",
        // },
        // {
        //   modaltitle: "Difficulty Level:",
        //   modaltext:
        //     "The difficulty level of the Annapurna base camp trek from Kathmandu/Pokhara is marked moderate. As the trek progresses and you touch high elevation, other factors like altitude, isolation of the region, etc add up to the difficulty. Overall the journey is moderate to difficult because you spend a long time in the mountains.",
        // },
        // {
        //   modaltitle: "Terrain:",
        //   modaltext:
        //     "The terrain is diverse. You begin walking through subtropical forests. Some part of the forested trail is dense and damp. There are many rocky staircases you walk on. Likewise, walking alongside foothills, you'll ascend and descend throughout the trek. The latter part of the trail involves meadows and an alpine zone. You walk on steep, rocky paths.",
        // },
        // {
        //   modaltitle: "Altitude Sickness:",
        //   modaltext:
        //     "The Annapurna base camp trek involves altitude sickness risks. Do not take altitude sickness lightly because, in the worst case, we have to evacuate you to a hospital in Pokhara or Kathmandu. The chances of altitude sickness during the trek get low if you follow the precautions. Our crew will make sure you are not being careless and doing things that may trigger altitude sickness while trekking.",
        // },
        // {
        //   modaltitle: "Team:",
        //   modaltext:
        //     "Our on-field team is very experienced and knowledgeable about the Annapurna base camp trek route. They are trained to guide travelers in the Himalayas and can tackle any emergency situation. All of our team members are vaccinated for Covid-19. We make sure they are healthy before embarking on any treks.",
        // },
        // {
        //   modaltitle: "Support:",
        //   modaltext:
        //     "We give full support for the Annapurna base camp trek in Nepal. We take care of your accommodation and meals, arrange the permits, and help you with little things like getting a sim card, airport pickup/drop, etc. Likewise, during the trek, you trek with a reliable and experienced trekking guide and porters. Our guides not only show the trail, but also bridge the gap between locals and tourists. ",
        // },
        // {
        //   modaltitle: "Equipment:",
        //   modaltext:
        //     "The Annapurna base camp trek does not need any advanced climbing gear. We'll provide you with a trekking packing list that you can follow. You can buy most of the trekking clothes and gear in Thamel, Kathmandu. Therefore, no heavy luggage. Moreover, there are also renting options available, so you do not have to spend lots of money if you are not an active trekker.",
        // },
      ],
    },
    {
      id: 6,
      modaldetail: [
        {
          modaltitle: "Overview:",
          modaltext:
            "Everest base camp trek 14 days is one of the most famous and best Everest base camp trek packages. This itinerary has ample acclimatization days and tempting night stops. Sufficed with the bustle of travelers from all around the world and the serenity of nature, the Everest base camp trek provides a very gratifying experience. You get to challenge your strength and unlock a lifelong achievement. Learn about your inner self as you unwind and make new friends. Walk past forests, bridges, moraines, and glaciers.",
        },
        {
          modaltitle: "Restaurants & Tea Shops:",
          modaltext:
            "In villages like Lukla, Phakding, Namche Bazaar, Tengboche, and Dingboche, there are many cafes & restaurants. The Everest base camp trekking route has numerous tea shops too, where you can buy refreshments. Likewise, in Namche Bazaar, there are book shops, souvenir shops, gear shops, etc.",
        },
        {
          modaltitle: "ATM/Currency:",
          modaltext:
            "ATM is only available in Namche Bazaar. You can withdraw Nepali currency here to use while trekking. Most of the lodges and tea shops do not accept cards or other currency, so carry ample Nepali cash.",
        },
        {
          modaltitle: "Internet:",
          modaltext:
            "Paid WiFi is available in villages at lower elevations. The connection fluctuates a lot because of the weather and remoteness of the region. The Nepali telecommunication company Ncell offers a somewhat decent network up to the Everest base camp. We'll help you get a Nepali sim with data to use the internet on the EBC trail",
        },
        {
          modaltitle: "Flora + Fauna:",
          modaltext:
            "The trekking route goes through lush forests of rhododendron, pine, fir, oak, birch, etc. You'll come across a range of alpine vegetation and wildlife along with different species of birds on the way to EBC. Dingboche onwards, the trail leaves the treeline and passes by meadows & bushes before joining the glacial & rocky paths.",
        },
        {
          modaltitle: "Locals:",
          modaltext:
            " The Khumbu region is resided by people from different ethnic groups like Rai, Bhotiya & Limbu, along with various clans of Sherpas. They are very friendly and kind to travelers. With a range of cultures, customs & values, you get to see their lifestyle and daily chores.",
        },
        {
          modaltitle: "Language:",
          modaltext:
            "The locals speak different languages. There are many dialects of the Tibetan language. Most of the lodge owners and tea shop owners understand & speak English, so you'll have no issues communicating.",
        },
        {
          modaltitle: "Monasteries:",
          modaltext:
            "Tengboche monastery is the biggest monastery, whereas Pangboche and Khumjung monasteries are one of the oldest monasteries in the Khumbu region. There are many other small stupas, chortens, prayer wheels, sculpted rocks, artifacts, and prayer flags throughout the route.",
        },
      ],
    },
  ];
  const expandHandler = () => {
    setExpandModal(!expandModal);
  };

  const collapseHandler = () => {
    setExpandModal(false);
  };

  const onTripChange = () => {
    sessionStorage.removeItem("tripTitle");
    sessionStorage.removeItem("tripPrice");
    sessionStorage.setItem("tripTitle", tripTitle);
    sessionStorage.setItem("tripPrice", tripPrice);
  };

  return (
    <>
      <div className={`modals ${expandModal ? "expand" : ""}`}>
        <div className={`modal-head `} onClick={expandHandler}>
          <div className="modal-head-title">
            {!expandModal ? (
              <AiOutlineArrowUp fontSize="2rem" className="px-1" />
            ) : (
              <AiOutlineArrowDown fontSize="2rem" className="px-1" />
            )}
            <div className="head-title">{modalhead}</div>
          </div>
          <div className="iclose">
            {expandModal && (
              <AiOutlineCloseCircle
                fontSize="1.3rem"
                onClick={collapseHandler}
              />
            )}
          </div>
        </div>
        <div className={`modal-body ${expandModal ? "modal-body-expand" : ""}`}>
          {!expandModal ? (
            <>
              {modaldetail &&
                modaldetail?.map((detail) => (
                  <div className="modal-content">
                    <div className="modal-title d-none d-sm-flex">
                      {detail.modaltitle}
                    </div>
                    <div className="modal-text">{detail.modaltext}</div>
                  </div>
                ))}
            </>
          ) : (
            <>
              {modaldetailtrip &&
                modaldetailtrip[id - 1]?.modaldetail?.map((detail) => (
                  <div className="modal-content">
                    <div className="modal-title fw-bold">{detail.modaltitle}</div>
                    <div className="modal-text">{detail.modaltext}</div>
                  </div>
                ))}
            </>
          )}
        </div>
        <div className="modal-book">
          <HashLink
            smooth
            to="/booking"
            className="modal-book link p-0"
            style={{ color: "#000" }}
            onClick={onTripChange}
          >
            BOOK NOW
          </HashLink>
          <AnnapurnaCircuitDepartureDateModalTripInfo />
        </div>
      </div>
    </>
  );
};

export default Modals;
