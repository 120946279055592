import { Card, Button } from "react-bootstrap";
import CardGroup from "react-bootstrap/CardGroup";
import { HashLink } from "react-router-hash-link";
import EBCHeliTour from "../../../image/Everest/EBC-Heli/everestHeliTour.jpg";
import News from "../../../image/Manaslu/manaslu4.jpg";
import TopDestination from "../../../image/Annapurna/AnnapurnaCircuitTilicho/10.jpg";
import EssentialThingsToPack from "../../../image/essentialThingsToPack.jpg";
import { FaCalendarAlt } from "react-icons/fa";

function ArticleCards() {
  return (
    <CardGroup className='article-cards'>
      <div className='row'>
        <div className='col-md-12 col-lg-3'>
          <Card>
            <HashLink to='/essential-things-to-pack-while-you-are-on-a-trek-in-himalayas'>
              <Card.Img variant='top' src={EssentialThingsToPack} />
            </HashLink>
            <Card.Body>
              <HashLink to='/essential-things-to-pack-while-you-are-on-a-trek-in-himalayas'>
                <Card.Title>
                  Essential Thinkgs To Pack While You Are On A Trekking In The
                  Himalayas
                </Card.Title>
              </HashLink>
              <Card.Text>
                If you are looking for an epic adventure, you have to check out
                the one-day Everest helicopter tour! It is seriously one of the
                most popular and easiest trips you can take. Trust me, the views
                from up there are absolutely breathtaking. A one-day Everest
                helicopter tour is one of the most popular and easiest trips you
                can take. Anyone of any age can go on this tour.
              </Card.Text>
            </Card.Body>
            <Card.Footer className='d-flex justify-content-between align-items-center'>
              <small className='text-muted'>
                <span className='me-2'>
                  <FaCalendarAlt />
                </span>
                May 14, 2023
              </small>

              <HashLink to='/essential-things-to-pack-while-you-are-on-a-trek-in-himalayas'>
                <Button variant='warning'>Read More</Button>
              </HashLink>
            </Card.Footer>
          </Card>
        </div>

        <div className='col-md-12 col-lg-3'>
          <Card>
            <HashLink to='/top-summer-destinations-for-2023-2024'>
              <Card.Img variant='top' src={TopDestination} />
            </HashLink>
            <Card.Body>
              <HashLink to='/top-summer-destinations-for-2023-2024'>
                <Card.Title>Top Summer Destinations For 2023/2024</Card.Title>
              </HashLink>
              <Card.Text>
                If you are looking for an epic adventure, you have to check out
                the one-day Everest helicopter tour! It is seriously one of the
                most popular and easiest trips you can take. Trust me, the views
                from up there are absolutely breathtaking. A one-day Everest
                helicopter tour is one of the most popular and easiest trips you
                can take. Anyone of any age can go on this tour.
              </Card.Text>
            </Card.Body>
            <Card.Footer className='d-flex justify-content-between align-items-center'>
              <small className='text-muted'>
                <span className='me-2'>
                  <FaCalendarAlt />
                </span>
                April 21, 2023
              </small>

              <HashLink to='/top-summer-destinations-for-2023-2024'>
                <Button variant='warning'>Read More</Button>
              </HashLink>
            </Card.Footer>
          </Card>
        </div>

        <div className='col-md-12 col-lg-3'>
          <Card>
            <HashLink to='/no-treks-in-nepal-are-allowed-without-a-guide'>
              <Card.Img variant='top' src={News} />
            </HashLink>
            <Card.Body>
              <HashLink to='/no-treks-in-nepal-are-allowed-without-a-guide'>
                <Card.Title>
                  No treks in Nepal are allowed without a guide
                </Card.Title>
              </HashLink>
              <Card.Text>
                As of April 1, 2023, anyone trekking in the Himalayas must have
                a licensed guide with them at all times, the Khumbu Pasang Lhamu
                Rural Municipality says. According to TAAN, trekking in Nepal's
                designated national parks and protected regions must be done
                with a local guide or porter, and trekkers must get a trekkers'
                information management system (TIMS) card before going on treks
                in managed areas.
              </Card.Text>
            </Card.Body>
            <Card.Footer className='d-flex justify-content-between align-items-center'>
              <small className='text-muted'>
                <span className='me-2'>
                  <FaCalendarAlt />
                </span>
                April 6, 2023
              </small>

              <HashLink to='/no-treks-in-nepal-are-allowed-without-a-guide'>
                <Button variant='warning'>Read More</Button>
              </HashLink>
            </Card.Footer>
          </Card>
        </div>

        <div className='col-md-12 col-lg-3'>
          <Card>
            <HashLink to='/everest-heli-tour-blog'>
              <Card.Img variant='top' src={EBCHeliTour} />
            </HashLink>
            <Card.Body>
              <HashLink to='/everest-heli-tour-blog'>
                <Card.Title>Why Everest Base Camp Helicopter Tour?</Card.Title>
              </HashLink>
              <Card.Text>
                The Everest Base Camp Helicopter Tour is one of the best ways to
                see the Himalayas up close and personal. The helicopter ride to
                Everest base camp will take 3 to 4 hours from Kathmandu.
                Therefore, it is regarded as one of the easiest options for
                individuals with limited time, the elderly, and the differently
                abled.
              </Card.Text>
            </Card.Body>
            <Card.Footer className='d-flex justify-content-between align-items-center'>
              <small className='text-muted'>
                <span className='me-2'>
                  <FaCalendarAlt />
                </span>
                February 26, 2023
              </small>

              <HashLink to='/everest-heli-tour-blog'>
                <Button variant='warning'>Read More</Button>
              </HashLink>
            </Card.Footer>
          </Card>
        </div>
      </div>
    </CardGroup>
  );
}

export default ArticleCards;
