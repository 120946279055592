import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  FaMountain,
  FaUtensils,
  FaBed,
  FaHiking,
  FaHelicopter,
} from "react-icons/fa";
import { FaClock } from "react-icons/fa";
import kathmandu from "../../../image/kathmandu.jpeg";

import "../../../custom-sass/pages/trip/_trip-detail.scss";

import day2 from "../../../image/Everest/ebcHeliTrek/37.jpg";
import day3 from "../../../image/Everest/ebcHeliTrek/38.jpg";
import day4 from "../../../image/Everest/ebcHeliTrek/5.jpg";
import day5 from "../../../image/Everest/ebcHeliTrek/40.jpg";
import day6 from "../../../image/Everest/ebcHeliTrek/18.jpg";
import day7 from "../../../image/Everest/ebcHeliTrek/19.jpg";
import day8 from "../../../image/Everest/ebcHeliTrek/15.jpg";
import day9 from "../../../image/Everest/EBC-Heli/everest-heli-tour.jpeg";

import Map from "../../../image/Map/ebcHeliTrekMap.jpg";
import Pdf from "../../../itineraryDocuments/EverestRegion/EBCHeliTrek.pdf";


const Itinerary = () => {
  const days = [
    {
      day1: [
        {
          title: "Day 1: Arrival in Kathmandu",
          image: kathmandu,
          caption:
            "Our airport team will be waiting for you at the international airport terminal to take you to the hotel. Also, they will help you check in and carry your bags. After getting some rest, get together with the rest of the core team in the office of our Adventure Master. We tell you about the trek leader and go over some last-minute details. Evening is free. You can get last-minute things for your trek and walk around Thamel's busy streets.",
          tripDetail: [
            // {
            //   icon: "FaMountain",
            //   detailTitle: "Maximum Altitude",
            //   detailCaption: "1400m",
            // },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "Null",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Hotel Arts",
            },
          ],
        },
      ],
      day2: [
        {
          title:
            "Day 2: Flight from Kathmandu to Lukla airport (2,846 m/9,337 ft) and trek to Phakding (2,610 m/8,563 ft)",
          image: day2,
          caption:
            "Get up early to catch an exciting flight from the airport in Kathmandu to the airport in Lukla. The flight is short and has beautiful views. As soon as we land at Tenzing Hillary Airport, we are joined by porters and start walking toward Phakding village.  From Lukla, it is an easy walk downhill through lush forests and along the Dudh Koshi river. On the way to Phakding village, we pass by Cheplung village (2,660 m) and Thado Koshi Gaon (2,580 m). You go over a few bridges. Get to see views of Kusum Kangguru and Kongde Ri.",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Trek Distance",
              detailCaption: "6.2 km/3.8 miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "35 minutes flight & 3 hours trek",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B,L,D",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Phakding Lodge",
            },
          ],
        },
      ],
      day3: [
        {
          title:
            "Day 3: Trek from Phakding to Namche Bazaar (3,440 m/11,290 ft)",
          image: day3,
          caption:
            "From the village of Phakding, the path leads through rhododendron and magnolia forests to the town of Toktok (2,760 m). To get to Monjo from Bengkar Village (2,630 m), you have to cross two bridges, including the long Hillary Bridge (2,835 m). At the police checkpoint, we will turn in our permits, and then we will keep walking through lush forests, over a few suspension bridges, and through small villages until we reach Namche Bazaar. As we get closer to Namche Bazaar, the path gets steeper, and we get our first look at Everest.",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Trek Distance",
              detailCaption: "7.4 km/4.6 miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "6 hours",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B,L,D",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Panorama Lodge",
            },
          ],
        },
      ],
      day4: [
        {
          title:
            "Day 4: Acclimatization in Namche Bazaar: hike to Syangboche Airstrip (3,748 m/12,297 ft) and Everest View Hotel (3,962 m/13,000 ft)",
          image: day4,
          caption:
            "We rest for a day in Namche Bazaar so that our bodies can get used to the high altitude. Consequently, we will take the Syangboche Airstrip above Namche Bazaar to get to the Everest View Hotel. Therefore, this will be a great hike that starts early in the morning. During the hike, we can see Everest, Thamserku, Ama Dablam, Lhotse, Nuptse, Taboche, Khumbila, and other mountains. The Guinness World Record is held by the Everest View Hotel, which is one of the highest luxury hotels in the world.  We could keep hiking to Khumjung monastery, which is one of the oldest monasteries in the Khumbu area. After the hike, we will go down to Namche Bazaar to spend the rest of the day. In the village, we can visit gift shops, cafes, museums, and other places.",
          tripDetail: [
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "4-5 hours",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B,L,D",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Panorama Lodge",
            },
          ],
        },
      ],
      day5: [
        {
          title:
            "Day 5: Trek from Namche Bazaar to Tengboche/Dibuche (3,860 m/12,660 ft)",
          image: day5,
          caption:
            "The trail goes up along the green foothills from Namche Bazaar to Kyangjuma (3,550 m). Amazing mountain views can be seen along the way. Therefore, the trail winds its way uphill and soon drops down to Phungi Thanga (3,250 m). We walk through a lush forest and up to the village of Tengboche after crossing a bridge. From the back of the Tengboche monastery, you can see Everest, Lhotse, Nuptse, and Ama Dablam very well.",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Trek Distance",
              detailCaption: "9.2 km/5.7 miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "5 hours",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B,L,D",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Rivendell Lodge",
            },
          ],
        },
      ],
      day6: [
        {
          title:
            "Day 6: Trek from Tengboche/Dibuche to Dingboche (4,410 m/14,470 ft)",
          image: day6,
          caption:
            "On this day, we get up early to see a beautiful sunrise. We leave Tengboche after breakfast and go down through the lush rhododendron forest to Deboche (3,320 m). We might see musk deer, blue sheep, and monal as we go further. We pass through the village of Pangboche after crossing a suspension bridge over the Imja Khola (3,930 m). Keep walking and cross another bridge. The trail will soon pass through Shomare (4,010 m), then Orsho (4,190 m). Then we walk along the Imja Khola and slowly climb up to the village of Dingboche.",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Trek Distance",
              detailCaption: "12 km/7.45 miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "5 hours",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B,L,D",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Century Lodge",
            },
          ],
        },
      ],
      day7: [
        {
          title: "Day 7: Trek from Dingboche to Lobuche (4,940 m/16,210 ft)",
          image: day7,
          caption:
            "Most of the day, we will walk along the Khumbu glacier. As we leave the village of Dingboche, we will climb through a ridge. The trail then goes up a little bit. We can see how beautiful Cholatse and Tawache are. Then, we walk on a path made of glacier that takes us to Dughla. We will cross an exciting bridge on the way.",
          caption1:
            "The path gets steeper, and after crossing the Khumbu Khla from Dughla, we climb up a rocky path to the Khumbu glacier's end moraine. We keep going and stop at memorial cairns on the way to Lobuche village. During the last part of the climb, the views of Khumbutse, Pumori, and Lingtren are unlike anything in the world.",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Trek Distance",
              detailCaption: "8.5 km/5.2 miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "5-6 hours",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B,L,D",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Altitude Home",
            },
          ],
        },
      ],
      day8: [
        {
          title:
            "Day 8: Trek from Lobuche to Everest Base Camp (5,364 m/17,598 ft) via Gorak Shep (5,164 m/16,942 ft) and back to Gorak Shep for a night's stay",
          image: day8,
          caption:
            "It is a big day. We leave the village of Lobuche and follow the wide valley up to Gorak Shep. The trail slowly goes up a rocky path, and as the elevation goes up, it gets steeper.",
          caption1:
            "We walk up and down Gorak Shep past the Changri Nup Glacier. From here, we walk along the Khumbu Glacier and up a steep, rough path to Everest base camp.",
          caption2:
            "From the Everest base camp, you can see the famous Everest Icefall and glaciers in a way that will blow your mind. We will stay here for a while and take pictures before going back to Gorak Shep to spend the night.",
          tripDetail: [
            {
              icon: "FaHiking",
              detailTitle: "Trek Distance",
              detailCaption: "15 km/9.32 miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "8 hours",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B,L,D",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Himalayan Inn/Everest Inn",
            },
          ],
        },
      ],
      day9: [
        {
          title: "DAY 9: Final departure",
          image: day9,
          caption:
            "We cannot leave the Everest area until we have seen the beautiful view of the mountains from Kala Patthar. The Kala Patthar trail is well-used but easy to follow. It starts above Gorak Shep. The early sun's golden rays on snow-capped mountains are beyond this world, and so is the view.",
          caption1:
            "Take your time to enjoy the view, and then head back down to Gorak Shep. Our helicopter crew will pick us up there. We will head down to Lukla after flying over the Khumbu glacier, icefall, and Everest base camp. The villages, valleys, and gorges look great from above. After getting more fuel, we will keep going from Lukla to Kathmandu.",
          tripDetail: [
            {
              icon: "FaHelicopter",
              detailTitle: "Trek Distance",
              detailCaption: "9.6 km/6 miles",
            },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "3-4 hours",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "B,L,D",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Hotel Arts",
            },
          ],
        },
      ],
    },
  ];

  return (
    <>
      <div className="detail-itinerary container mt-4">
        <div className="row">
          <div className="d-lg-none mb-5">
            <div className="">
              <h4 className="title">Trekking Route Map</h4>
              <img
                src={Map}
                alt="everest base camp heli trek map"
                style={{ width: "100%" }}
              />
              <button type="button" className="btn btn-dark mt-3 col-12">
                <a href={Pdf} target="_blank" className="text-light">
                  Download Itinerary
                </a>
              </button>
            </div>
          </div>

          <div className="col-md-12 col-lg-8">
            {days &&
              days.map((day) => (
                <>
                  {day.day1 &&
                    day.day1.map((day1) => (
                      <>
                        <h4 className="itinerary-title">{day1.title}</h4>
                        {/* <div className="itinerary-image">
                          <img src={day1.image} alt="kathmandu" />
                          <figcaption className="text-secondary">
                            Kathmandu (Capital city of Nepal)
                          </figcaption>
                        </div> */}
                        <div className="row">
                          {day1.tripDetail &&
                            day1.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-4 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-auto"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day1.caption}
                        </div>
                      </>
                    ))}

                  {day.day2 &&
                    day.day2.map((day2) => (
                      <>
                        <h4 className="itinerary-title">{day2.title}</h4>
                        <div className="itinerary-image">
                          <img src={day2.image} alt="lukla airport" />
                          <figcaption className="text-secondary">
                            Lukla Airport
                          </figcaption>
                        </div>
                        <div className="row">
                          {day2.tripDetail &&
                            day2.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-aut0"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day2.caption}
                        </div>
                      </>
                    ))}
                  {day.day3 &&
                    day.day3.map((day3) => (
                      <>
                        <h4 className="itinerary-title">{day3.title}</h4>
                        <div className="itinerary-image">
                          <img src={day3.image} alt="" />
                          <figcaption className="text-secondary">
                            Namche Bazaar
                          </figcaption>
                        </div>
                        <div className="row">
                          {day3.tripDetail &&
                            day3.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-aut0"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day3.caption}
                        </div>
                      </>
                    ))}
                  {day.day4 &&
                    day.day4.map((day4) => (
                      <>
                        <h4 className="itinerary-title">{day4.title}</h4>
                        <div className="itinerary-image">
                          <img src={day4.image} alt="" />
                          <figcaption className="text-secondary">
                            Everest View Hotel
                          </figcaption>
                        </div>
                        <div className="row">
                          {day4.tripDetail &&
                            day4.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-aut0"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day4.caption}
                        </div>
                      </>
                    ))}
                  {day.day5 &&
                    day.day5.map((day5) => (
                      <>
                        <h4 className="itinerary-title">{day5.title}</h4>
                        <div className="itinerary-image">
                          <img src={day5.image} alt="" />
                          <figcaption className="text-secondary">
                            Tengboche Monastery (Tibetan Buddhist monastery in
                            the Khumbu valley )
                          </figcaption>
                        </div>
                        <div className="row">
                          {day5.tripDetail &&
                            day5.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-aut0"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day5.caption}
                        </div>
                      </>
                    ))}
                  {day.day6 &&
                    day.day6.map((day6) => (
                      <>
                        <h4 className="itinerary-title">{day6.title}</h4>
                        <div className="itinerary-image">
                          <img src={day6.image} alt="kathmandu" />
                          <figcaption className="text-secondary">
                            Kathmandu (Capital city of Nepal)
                          </figcaption>
                        </div>
                        <div className="row">
                          {day6.tripDetail &&
                            day6.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-aut0"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day6.caption}
                        </div>
                      </>
                    ))}
                  {day.day7 &&
                    day.day7.map((day7) => (
                      <>
                        <h4 className="itinerary-title">{day7.title}</h4>
                        <div className="itinerary-image">
                          <img src={day7.image} alt="kathmandu" />
                          <figcaption className="text-secondary">
                            Lobuche
                          </figcaption>
                        </div>
                        <div className="row">
                          {day7.tripDetail &&
                            day7.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-aut0"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day7.caption}
                          <br />
                          {day7.caption1}
                        </div>
                      </>
                    ))}
                  {day.day8 &&
                    day.day8.map((day8) => (
                      <>
                        <h4 className="itinerary-title">{day8.title}</h4>
                        <div className="itinerary-image">
                          <img src={day8.image} alt="kathmandu" />
                          <figcaption className="text-secondary">
                            Kathmandu (Capital city of Nepal)
                          </figcaption>
                        </div>
                        <div className="row">
                          {day8.tripDetail &&
                            day8.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-aut0"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day8.caption}
                          <br />
                          {day8.caption1}
                          <br />
                          {day8.caption2}
                        </div>
                      </>
                    ))}
                  {day.day9 &&
                    day.day9.map((day9) => (
                      <>
                        <h4 className="itinerary-title">{day9.title}</h4>
                        <div className="itinerary-image">
                          <img src={day9.image} alt="kathmandu" />
                          <figcaption className="text-secondary"></figcaption>
                        </div>
                        <div className="row">
                          {day9.tripDetail &&
                            day9.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-3 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHelicopter" && (
                                        <FaHelicopter fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col-aut0"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day9.caption}
                          <br />
                          {day9.caption1}
                        </div>
                      </>
                    ))}
                </>
              ))}
          </div>

          <div className="main-modal-map d-none d-lg-flex">
            <div className="modal-map d-flex flex-column">
              <h4 className="title">Trekking Route Map</h4>
              <img src={Map} alt="everest base camp heli trek map" />
              <button type="button" className="btn btn-dark mt-3">
                <a href={Pdf} target="_blank" className="text-light">
                  Download Itinerary
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Itinerary;
