import React from "react";

import { FaCheckCircle } from "react-icons/fa";
import CancelIcon from "@mui/icons-material/Cancel";
import TripFact from "./TripFact";
import ManasluTable from "./ManasluTable";

import Gallery from "./Gallery";
import SantiReview from "../../components/HomeReview/santiReview";

import Accordion from "react-bootstrap/Accordion";

export default function Overview() {
  return (
    <div className='overview'>
      <div className='container d-flex mt-5'>
        <div className='row'>
          {/* <div className="col-lg-9 col-sm-12"> */}
          <div className='col-12'>
            <TripFact />
            <Gallery />
            <div>
              <SantiReview />
            </div>

            <h4 className='overview-title mt-4'>Outline Itinerary</h4>
            <ul className='p-0'>
              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 01:</strong>
                </div>
                <li>Arrival in Kathmandu</li>
              </div>

              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 02:</strong>
                </div>
                <li>
                  Drive from Kathmandu to Soti Khola (730 m/2,896 ft) via
                  Arughat
                </li>
              </div>

              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 03:</strong>
                </div>
                <li>Trek from Soti Khola to Machha Khola (890 m/2,965 ft)</li>
              </div>

              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 04:</strong>
                </div>
                <li>Machhakhola to Jagat (1,410 m/4,625 ft)</li>
              </div>

              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 05:</strong>
                </div>
                <li>Jagat to Deng (1,804 m/5,917 ft)</li>
              </div>

              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 06:</strong>
                </div>
                <li>Deng to Namrung (2,630 m/8,626 ft)</li>
              </div>

              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 07:</strong>
                </div>
                <li>Namrung to Shyala (3,500 m/11,482 ft)</li>
              </div>

              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 08:</strong>
                </div>
                <li>
                  Trek to Pung Gyen Gompa (4,800 m/15,748 ft) then walk to
                  Samagaun (3,520 m/11,548 ft) to spend the night
                </li>
              </div>

              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 09:</strong>
                </div>
                <li>Acclimatization day in Samagaun</li>
              </div>

              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 10:</strong>
                </div>
                <li>Samagaun to Samdo (3,875 m/12,713 ft)</li>
              </div>

              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 11:</strong>
                </div>
                <li>Samdo to Larke Phedi (Dharamsala) (4,480 m/14,698 ft)</li>
              </div>

              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 12:</strong>
                </div>
                <li>
                  Dharamsala to Bhimtang (3,720 m/12,205 ft) by crossing Larkya
                  la pass (5,160 m/16,929 ft)
                </li>
              </div>

              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 13:</strong>
                </div>
                <li>Bimthang to Tilije (1,700 m / 5,576 ft)</li>
              </div>

              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 14:</strong>
                </div>
                <li>
                  Tilije to Dharapani and to Kathmandu (1,400 m) via Besisahar
                  (760 m/2,493 ft)
                </li>
              </div>

              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 15:</strong>
                </div>
                <li>Final departure</li>
              </div>
            </ul>

            <h3 className='overview-title pt-5'>Trip Highlight</h3>
            <ul className='trip-highlight'>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Explore the Manaslu Conservation Area, a protected region in
                  Nepal that is subject to a number of restrictions and
                  regulations.
                </li>
              </span>

              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  In Nepal's Himalayas, embark on the ultimate trek that is off
                  the beaten path.
                </li>
              </span>

              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Enjoy the scenery as you travel through a variety of climate
                  zones and set locations.
                </li>
              </span>

              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Travel across a number of rivers via exhilarating suspension
                  bridges, and take a stroll along the Budhi Gandaki Gorge.
                </li>
              </span>

              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  A route with fewer hikers that passes through picturesque
                  mountain towns at each layover.
                </li>
              </span>

              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  A chance to learn about Tibetan Buddhism and stop at some
                  historic monasteries along the way.
                </li>
              </span>

              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Take in stunning panoramas of the western Himalayas, complete
                  with glaciers, open meadows, waterfalls, and lakes, among
                  other natural features.
                </li>
              </span>

              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>Hike to Manaslu Base Camp or Birendra Lake.</li>
              </span>

              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Larkya La is a high mountain pass that must be traversed.
                </li>
              </span>
            </ul>

            <div className='row d-flex justify-content-between'>
              <div className='col-12'>
                <h3 className='overview-title pt-5'>Cost Include</h3>
                <ul className='cost-include'>
                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>
                      All ground travel, including getting to and from the
                      airport, is done in a private car.
                    </li>
                  </span>

                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>
                      During the trek, you will be provided with three meals a
                      day: breakfast, lunch, and dinner with one cup of tea each
                      meal.
                    </li>
                  </span>

                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>
                      You will be provided with the best available lodge with
                      private or shared bathrooms.
                    </li>
                  </span>

                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>
                      A highly experienced, helpful, knowledgeable, friendly,
                      English-speaking guide with all of his salary, food,
                      drinks, lodging, transportation, and insurance covered.
                    </li>
                  </span>

                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>
                      Strong, helpful porters with the right safety gear and
                      walking gear, as well as a salary, food, a place to live,
                      and insurance. (one porter for two people).
                    </li>
                  </span>

                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>
                      A map of a trek and a certificate of completion of the
                      trip.
                    </li>
                  </span>

                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>Taxes and official costs paid to the government.</li>
                  </span>

                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>
                      A map of a trek and a certificate of completion of the
                      trip.
                    </li>
                  </span>

                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>
                      All papers that need to be done, Fees for entering permits
                      you need to go while hiking.
                    </li>
                  </span>

                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>Duffle bag for trekking.</li>
                  </span>

                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>
                      First aid kit- Oxymeter to check your pulse, oxygen
                      saturation, and heart rate twice a day (very helpful to
                      check for signs of Altitude Mountain Sickness, or AMS).
                      This will make sure that your trek is in the safest hands
                      possible, no matter how experienced you are.
                    </li>
                  </span>
                </ul>
              </div>
              <div className='col-12'>
                <h3 className='overview-title pt-5'>Cost Exclude</h3>
                <ul className='cost-exclude'>
                  <span>
                    <div className='cost-exclude-icon'>
                      <CancelIcon />
                    </div>
                    <li>
                      Food that you will eat in Kathmandu for lunch and dinner.
                    </li>
                  </span>

                  <span>
                    <div className='cost-exclude-icon'>
                      <CancelIcon />
                    </div>
                    <li>
                      <p>
                        The cost of a visa to enter Nepal is
                        <br />
                        $30 USD for a 15-day stay,
                        <br />
                        $50 USD for 30 Days,
                        <br />
                        90 Days - $125
                      </p>
                    </li>
                  </span>

                  <span>
                    <div className='cost-exclude-icon'>
                      <CancelIcon />
                    </div>
                    <li>Your insurance for travel and medical care.</li>
                  </span>

                  <span>
                    <div className='cost-exclude-icon'>
                      <CancelIcon />
                    </div>
                    <li>Internamtional airfare.</li>
                  </span>

                  <span>
                    <div className='cost-exclude-icon'>
                      <CancelIcon />
                    </div>
                    <li>Your personal expenses.</li>
                  </span>

                  <span>
                    <div className='cost-exclude-icon'>
                      <CancelIcon />
                    </div>
                    <li>
                      All of the alcoholic and non-alcoholic cold and hot drinks
                      you buy on trek (along the way and in the Tea Houses at
                      night)
                    </li>
                  </span>

                  <span>
                    <div className='cost-exclude-icon'>
                      <CancelIcon />
                    </div>
                    <li>
                      All of the sweet desserts, like chocolate, cake, pie, and
                      pudding.
                    </li>
                  </span>

                  <span>
                    <div className='cost-exclude-icon'>
                      <CancelIcon />
                    </div>
                    <li>
                      The tea houses have hot showers and places to charge your
                      phone which will cost you about 2$ to 5$.
                    </li>
                  </span>

                  <span>
                    <div className='cost-exclude-icon'>
                      <CancelIcon />
                    </div>
                    <li>
                      Tip to the guide, the porter, and the driver. (Tipping is
                      expected).
                    </li>
                  </span>
                </ul>
              </div>
            </div>

            <ManasluTable />

            <h4 className='overview-title pt-5'>FAQ</h4>

            <div className='trip-overview-accordion'>
              <Accordion defaultActiveKey='0' flush>
                <Accordion.Item eventKey='0'>
                  <Accordion.Header>
                    <p class='accordion-title'>1) Where is Manaslu located?</p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='overview-content'>
                      Mt. Manaslu is the eighth highest mountain in the world,
                      located in the west-central part of Nepal.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='1'>
                  <Accordion.Header>
                    <p class='accordion-title'>
                      2) How long is Manaslu Circuit Trek?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='accordion-content'>
                      The Manaslu circuit trek is 15 days long, and each day
                      involves 6 hours of walking at a minimum. You can do this
                      trek in less time too, or extend the trip further to Tsum
                      valley.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='2'>
                  <Accordion.Header>
                    <p class='accordion-title'>3) Is Manaslu Trek difficult?</p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='overview-content'>
                      Yes, the Manaslu trek is difficult. Beginner trekkers are
                      not fit for trekking in the Manaslu region. The rugged,
                      steep route demands strong fitness, previous trekking
                      experience, and awareness of altitude sickness.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='3'>
                  <Accordion.Header>
                    <p class='accordion-title'>4) How can I go to Manaslu?</p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='overview-content'>
                      You can traverse the Manaslu region by driving from
                      Kathmandu to Soti Khola. The road distance from Kathmandu
                      to Soti Khola is 126 km (80 miles) and needs 6 hours of
                      drive.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='4'>
                  <Accordion.Header>
                    <p class='accordion-title'>
                      5) Is Manaslu harder than Everest?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='overview-content'>
                      Yes, the Manaslu circuit trek is way harder than the
                      Everest base camp trek. Even though the elevation is
                      almost the same, you cross challenging Larkya La pass and
                      walk on vertical slopes & rocky paths.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='5'>
                  <Accordion.Header>
                    <p class='accordion-title'>
                      6) Can you see Everest from the Manaslu Circuit?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='overview-content'>
                      No, you cannot see Mt. Everest during the Manaslu circuit
                      trek. Instead, you get to witness a breathtaking panorama
                      of western Himalayan mountains, including Annapurna, Mt.
                      Manaslu, Hiunchuli, etc.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='6'>
                  <Accordion.Header>
                    <p className='accordion-title'>
                      7) Can I do Manaslu Circuit trek solo?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='overview-content'>
                      No, you cannot do Manaslu circuit trek solo. Manaslu trek
                      compulsory requires trekking in a group of two people with
                      an experienced guide & porter. If you are a solo traveler,
                      then you can join our group departure to the Manaslu
                      circuit.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='7'>
                  <Accordion.Header>
                    <p class='accordion-title'>
                      8) How experienced is the guide or a porter accompanying
                      me during the trek?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='overview-content'>
                      We work with licensed and certified mountain guides who
                      have numerous years of trekking experience. The trail is
                      not well marked, and many tough sections demand heads up.
                      Our guides are friendly and can speak English fluently, so
                      you'll have no issue with communication.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='8'>
                  <Accordion.Header>
                    <p class='accordion-title'>
                      9) Can I join Manaslu Circuit trekking as a beginner
                      trekker?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='overview-content'>
                      No, you cannot join the Manaslu circuit trek as a beginner
                      trekker unless you are confident about your fitness and
                      endurance.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='9'>
                  <Accordion.Header>
                    <p class='accordion-title'>
                      10) Does the package cover international flight fare?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='overview-content'>
                      No, our Manaslu circuit trek cost does not cover
                      international flight fares.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='10'>
                  <Accordion.Header>
                    <p class='accordion-title'>
                      11) Can I extend my trip to Nepal after completing the
                      trek?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='overview-content'>
                      Yes, you can extend your trip to Nepal after completing
                      the trk. Let us know our travel consultant what you want
                      to do next, and they will help you with arranging it.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>

          {/* sidebar price
          <div className="col-lg-3 col-sm-12">
            <div className="overview-sidebar bg-light">
              <span className="badge text-wrap">Top Seller</span>
              <div className="">
                <span className="overview-sidebar-days">15 Days</span>
                <br />
                <span className="overview-sidebar-destination">
                  Kathmandu to Kathmandu
                </span>
                <br />
                <h4 className="price-text my-3">From USD $1190</h4>
                <div className="group-discount mb-3">
                  <table className="group-discount-table">
                    <p>Group Discount</p>
                    <tr>
                      <td>Pax</td>
                      <td>Price</td>
                    </tr>
                    <tr>
                      <th>1 Pax</th>
                      <th>$N/A</th>
                    </tr>
                    <tr>
                      <th>2 Pax</th>
                      <th>$N/A</th>
                    </tr>
                    <tr>
                      <th>3 Pax</th>
                      <th>$N/A</th>
                    </tr>
                    <tr>
                      <th>4-6 Pax</th>
                      <th>$N/A</th>
                    </tr>
                    <tr>
                      <th>7-10 Pax</th>
                      <th>$N/A</th>
                    </tr>
                    <tr>
                      <th>11-18 Pax</th>
                      <th>$N/A</th>
                    </tr>
                    <tr>
                      <th>19-24 Pax</th>
                      <th>$N/A</th>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="">
                <HashLink smooth to="/booking" className="link">
                  <button className="book-btn">Book Now</button>
                </HashLink>
              </div>
            </div>
          </div> */}

          {/* <div className="col-lg-3 col-sm-12">
            <div className="overview-sidebar">
              <span className="badge bg-primary text-wrap">Top Seller</span>
              <div className="my-3">
                <span className="overview-sidebar-days">9 Days</span>
                <br></br>
                <span className="overview-sidebar-destination">
                  Kathmandu to Kathmandu
                </span>
              </div>
              <span className="overview-sidebar-price text-danger fw-semibold">
                <p className="overview-sidebar-from">From</p>
                <sup className="text-danger fw-semibold text-align-center">
                  $
                </sup>
                1199
                <sup className="text-danger fw-semibold text-align-center">
                  USD
                </sup>
              </span>
              <br></br>
              <div className="py-4">
                <HashLink smooth to="/booking" className="link">
                  <button className="btn btn-danger">Book Now</button>
                </HashLink>
                <button className="btn btn-primary mx-2">Enquiry</button>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
