import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import CancelIcon from "@mui/icons-material/Cancel";

const TripDetail = () => {
  return (
    <>
      <div className='trip-detail container mt-5'>
        <div className='row'>
          <div className='col-2 d-none d-lg-block d-xl-block'>
            <div className='trip-sidebar'>
              <ul>
                <li>
                  <a href='#price'>Price Detail</a>
                </li>
                <li>
                  <a href='#highlight'>Highlight</a>
                </li>
                <li>
                  <a href='#include'>Cost Include</a>
                </li>
                <li>
                  <a href='#exclude'>Cost Exclude</a>
                </li>
                <li>
                  <a href='#disclaimer'>Disclaimer of Itinerary</a>
                </li>
                <li>
                  <a href='#important-note'>Important Note</a>
                </li>
                <li>
                  <a href='#safety'>Safety and Security</a>
                </li>
                <li>
                  <a href='#insurance'>Travel Insurance</a>
                </li>
                <li>
                  <a href='#age'>Minimum Age</a>
                </li>
                <li>
                  <a href='#time'>Best Time</a>
                </li>
                <li>
                  <a href='#tipping'>Tipping</a>
                </li>
                <li>
                  <a href='#optional-activities'>Oprtional Activities</a>
                </li>
                <li>
                  <a href='#group-leader'>Group Leader - What They Do</a>
                </li>
                <li>
                  <a href='#group-notes'>Size of Group Notes</a>
                </li>
                <li>
                  <a href='#transport'>Transport</a>
                </li>
                <li>
                  <a href='#local-flight'>Local Flight</a>
                </li>
                <li>
                  <a href='#solo-traveler'>Solo Travelers</a>
                </li>
                <li>
                  <a href='#joining'>Joining Instructions</a>
                </li>
                <li>
                  <a href='#arrival'>Arrival Complications</a>
                </li>
                <li>
                  <a href='#emergency-contact'>Emergency Contanct</a>
                </li>
                <li>
                  <a href='#visa'>Visa and Entry Requirement</a>
                </li>
                <li>
                  <a href='#money-exchange'>Money Exchange</a>
                </li>
                <li>
                  <a href='#emergency-fund'>Emergency Fund</a>
                </li>
                <li>
                  <a href='#first-aid'>First Aid</a>
                </li>
                <li>
                  <a href='#packing-list'>Trekking Equipment</a>
                </li>
              </ul>
            </div>
          </div>

          {/*---------------------- price starts --------------------- */}
          <div className='trip-info col-md-12 col-lg-10'>
            <h4 id='price' className='trip-detail-title'>
              Price Detail
            </h4>
            Trip Price: USD $2170 pp
            {/* <h4 className="trip-detail-title">Group Discount</h4>
            1-2 pax --------- USD N/A
            <br />
            upto 5 pax ---- USD N/A
            <br />
            upto 10 pax ---- USD N/A */}
            {/* ---------------- trip highlight starts --------------------- */}
            <h4 id='highlight' className='trip-detail-title'>
              Highlights
            </h4>
            <ul className='trip-highlight'>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  A well-led and safe trek to Mt. Everest's base camp, Everest
                  lap.
                </li>
              </span>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  An exciting journey through the Khumbu area of Sagarmatha
                  National Park.
                </li>
              </span>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Explore the eastern Himalayas of Nepal, which are home to
                  eight-thousand mountains, the legendary Sherpa people, and
                  beautiful river valleys.
                </li>
              </span>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  See beautiful waterfalls and streams and use hanging bridges
                  to cross the Dudh Koshi river many times.
                </li>
              </span>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Spend the night in beautiful mountain villages and get to know
                  how the locals live.
                </li>
              </span>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Walk through rhododendron and magnolia forests, then climb
                  above the tree line and walk along glaciers and moraines.
                </li>
              </span>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Hike to Kala Patthar and enjoy the beautiful sunrise and views
                  of the mountains.
                </li>
              </span>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  On the trail, you can always see snow-capped mountains, green
                  hills, and other different landscapes.
                </li>
              </span>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Take a thrilling helicopter ride out of the Khumbu area and
                  look out over the breathtaking Himalayan landscapes.
                </li>
              </span>
            </ul>
            {/*---------------------- cost include starts --------------------- */}
            <h4 id='include' className='trip-detail-title'>
              Cost Include
            </h4>
            <ul className='cost-include'>
              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  All ground travel, including getting to and from the airport,
                  is done in a private car.
                </li>
              </span>
              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Two nights accomodation at a standard hotel in kathmandu
                  including breakfast ( as per the itinerary)
                </li>
              </span>
              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>The flight from kathmandu to lukla- kathmandu</li>
              </span>
              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  During the trek, you will be provided with three meals a day:
                  breakfast, lunch, and dinner with one cup of tea each meal
                </li>
              </span>
              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  You will be provided with the best available lodge with
                  private or shared bathrooms in Phakding, Namche, and Lukla,
                  where two people can share a room.
                </li>
              </span>
              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Strong, helpful porters with the right safety gear and walking
                  gear, as well as a salary, food, a place to live, and
                  insurance. (one porter for two people).
                </li>
              </span>
              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  A highly experienced, helpful, knowledgeable, friendly,
                  English-speaking guide with all of his salary, food, drinks,
                  lodging, transportation, and insurance covered.
                </li>
              </span>
              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>The helicopter flight from Gorak Shep to Lukla.</li>
              </span>
              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  A map of a trek and a certificate of completion of the trip.
                </li>
              </span>
              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  All papers that need to be done, Fees for entering permits you
                  need to go while hiking.
                </li>
              </span>
              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>Helicopter Insurance Covered.</li>
              </span>
              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>Taxes and official costs paid to the government.</li>
              </span>

              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  First aid kit- Oxymeter to check your pulse, oxygen
                  saturation, and heart rate twice a day (very helpful to check
                  for signs of Altitude Mountain Sickness, or AMS). This will
                  make sure that your trek is in the safest hands possible, no
                  matter how experienced you are.
                </li>
              </span>
            </ul>
            {/*---------------------- cost exclude starts --------------------- */}
            <h4 id='exclude' className='trip-detail-title'>
              Cost Exclude
            </h4>
            <ul className='cost-exclude'>
              <span>
                <div className='cost-exclude-icon'>
                  <CancelIcon />
                </div>
                <li>
                  Food that you will eat in Kathmandu for lunch and dinner.
                </li>
              </span>
              <span>
                <div className='cost-exclude-icon'>
                  <CancelIcon />
                </div>
                <li>
                  <p>
                    The cost of a visa to enter Nepal is
                    <br />
                    $30 USD for a 15-day stay,
                    <br />
                    30 Days - $50,
                    <br />
                    90 Days - $125
                  </p>
                </li>
              </span>
              <span>
                <div className='cost-exclude-icon'>
                  <CancelIcon />
                </div>
                <li>Travel insurance.</li>
              </span>
              <span>
                <div className='cost-exclude-icon'>
                  <CancelIcon />
                </div>
                <li>Your insurance for travel and medical care.</li>
              </span>
              <span>
                <div className='cost-exclude-icon'>
                  <CancelIcon />
                </div>
                <li>International Airfare.</li>
              </span>
              <span>
                <div className='cost-exclude-icon'>
                  <CancelIcon />
                </div>
                <li>Your Personal Expenses.</li>
              </span>
              <span>
                <div className='cost-exclude-icon'>
                  <CancelIcon />
                </div>
                <li>
                  All of the alcoholic and non-alcoholic cold and hot drinks you
                  buy on trek (along the way and in the Tea Houses at night).
                </li>
              </span>
              <span>
                <div className='cost-exclude-icon'>
                  <CancelIcon />
                </div>
                <li>
                  All of the sweet desserts, like chocolate, cake, pie, and
                  pudding.
                </li>
              </span>
              <span>
                <div className='cost-exclude-icon'>
                  <CancelIcon />
                </div>
                <li>
                  The tea houses have hot showers and places to charge your
                  phone which will cost you about 2$ to 5$
                </li>
              </span>
              <span>
                <div className='cost-exclude-icon'>
                  <CancelIcon />
                </div>
                <li>
                  Tip to the guide, the porter, and the driver. (Tipping is
                  expected)
                </li>
              </span>
            </ul>
            {/*---------------------- Disclaimer of Itinerary starts --------------------- */}
            <h4 id='disclaimer' className='trip-detail-title'>
              Disclaimer of Itinerary
            </h4>
            <p className='trip-detail-content'>
              The goal of our 9-day Everest base camp trek is to stay on
              schedule. We do our best to stick to the schedule we have laid out
              for the Everest Base Camp trek. We may have to change the route,
              though, if the situation calls for it. Therefore, because of the
              nature of the trip and how far away it is, anything could happen.
              During a trek, if something comes up that changes the plan, the
              trek leader will make decisions that are best for the group.
            </p>
            {/*---------------------- important notes starts --------------------- */}
            <h4 id='important-note' className='trip-detail-title'>
              Points to be noted
            </h4>
            <p className='trip-detail-content'>
              Here are some things you should know before going on the Everest
              Base Camp trek:
            </p>
            <ul>
              <li>
                There is a chance of getting altitude sickness on the trek. So,
                talk to your doctor and find out what might happen to your body
                at high altitudes.
              </li>
              <li>
                The Everest base camp trek has a lot of modern facilities, which
                makes it easier to hike than other trails.
              </li>
              <li>
                Altitude sickness can be avoided by drinking a lot of water.
              </li>
              <li>
                Do not go hiking in brand-new hiking boots. You will get
                blisters, which will make it hard for you to walk.
              </li>
              <li>
                You can go to the Everest base camp trek in the off-season as
                well if you are ready. During the off-season, prices are low and
                the trail is not crowded.
              </li>
              <li>You can make changes to the schedule to fit your needs.</li>
              <li>
                Have extra money so you can pay for things like hot showers,
                charging stations, WiFi, etc (not covered in the package cost).
              </li>
            </ul>
            {/*---------------------- safety and security starts --------------------- */}
            <h4 id='safety' className='trip-detail-title'>
              Safety and Security
            </h4>
            <p className='trip-detail-content'>
              Adventure Master Trek is a trekking company with a license.
              Therefore, you can have a safe and pleasant trip with us. We have
              a safe way for people to pay. So, you can trust us with your money
              and time. Our team has worked in this field before, so all treks
              go smoothly. Also, you will not have to worry about food, water,
              or a hotel room.
            </p>
            <p className='trip-detial-content'>
              With our understanding and well-trained, experienced crew,
              trekking to Everest Base Camp and coming back by helicopter is
              very safe with us. A professional mountain guide will be with you
              while you trek in the mountains. We book the best and safest
              places to stay along the way.
            </p>
            <p className='trip-detail-content'>
              Also, the whole team has been immunized against Covid-19.
              Therefore, there is no emergency on a national scale, and the
              country is open to people from all over the world. Most people
              have had their first or second dose of the Covid-19 vaccine, so it
              is safe to go on the trek.
            </p>
            {/*---------------------- safety and security starts --------------------- */}
            <h4 id='insurance' className='trip-detail-title'>
              Travel Insurance
            </h4>
            <p className='trip-detail-content'>
              To go on the Everest base camp trek, you need to have travel
              insurance. We do not accept a reservation if the person who wants
              to go does not have good travel insurance. You should know that
              the EBC trek is at a high altitude and in an area that has not
              been developed and is hard to get to. If there is a medical
              emergency on the trek that cannot be fixed with first aid, we will
              send the person by helicopter for emergency rescue.
            </p>
            <p className='trip-detial-content'>
              In case of an emergency, your travel insurance should pay for your
              medical bills and get you to a safe place by air. You can also add
              flight cancellation and loss or theft of personal items to your
              travel insurance if you want to. Nepal does not offer travel
              insurance to people from other countries, so you must get it
              before you leave.
            </p>
            <p className='trip-detial-content'>
              Do not forget that it is still a remote place where bad things can
              happen at any time. And there are still no good places to get
              medical care.
            </p>
            {/*---------------------- minimum age starts --------------------- */}
            <h4 id='age' className='trip-detail-title'>
              Minimum Age
            </h4>
            <p className='trip-detail-content'>
              To join our trek on your own and without a guardian, you must be
              18 years old. Those younger than this age (up to 12 years old)
              must hike with a known guardian. Everest Base Camp is the highest
              point in the world, so it is not safe for kids under 5 to hike
              there.
            </p>
            {/*---------------------- best time starts --------------------- */}
            <h4 id='time' className='trip-detail-title'>
              Best Time
            </h4>
            <p className='trip-detail-content'>
              Spring and fall are the best times to go hiking to Everest base
              camp. Therefore, it is the best time to trek or climb in the
              Himalayas, and the weather is most stable. From March to April and
              October to November, the weather is nice and clear.
            </p>
            <p className='trip-detail-content'>
              During the day, it is sunny and windy, and you can see the
              mountains clearly. In Autumn, the air smells cold and crisp, and
              in Spring, it smells warm. The temperature can be anywhere between
              5°C and 18°C. The mornings and nights are not too warm.
            </p>
            <h4 className='title'>
              Everest Base Trek with a return by helicopter in the fall.
            </h4>
            <p className='content'>
              The best time to trek to Everest Base Camp or anywhere else in the
              Himalayas is in the fall. Nepal has autumn from March to April.
              Because of the monsoon rain, the sky's the clearest during these
              months. As a result, the scenery and views are beautiful. During
              the day, the temperature is between 0°C and 15°C, and at night, it
              is between -5°C and -10°C. This means that the weather is neither
              too hot nor too cold.
            </p>
            <h4 className='title'>
              Springtime Everest base trek with return by helicopter.
            </h4>
            <p className='content'>
              In the same way, spring is also one of the best times to trek to
              EBC. The weather is clear and not too hot or cold. You get to see
              beautiful views of river valleys and mountain peaks. The trails
              are full of new plants and flowers, like rhododendrons, that make
              the area look clean and green. In spring, the average temperature
              is between 5°C and 17°C. The days are sunny and warm, but the
              nights are cooler.
            </p>
            <h4 className='title'>
              Everest Base Trek with Return by Helicopter in Winter.
            </h4>
            <p className='content'>
              Winter is very hard because the weather is always changing and the
              temperature swings a lot. The weather at high altitudes is always
              hard to predict. During the day, it will be warm, but after dark,
              the temperature will drop to -20 degrees, which means it will be
              very cold and there is not much chance of success.
            </p>
            <p className='content'>
              If care is not taken, frostbite is likely to happen. Even so,
              winter hiking is possible. Many experienced trekkers choose this
              time of year because it gives them more challenges and lets them
              avoid the crowds of spring and fall. You need to be smart about
              when you start the trek and have a few extra days in case
              something unexpected happens.
            </p>
            {/*---------------------- tipping starts --------------------- */}
            <h4 className='title'>
              Everest Base Trek with a return by helicopter in the rainy or hot
              season.
            </h4>
            <p className='content'>
              The best time to trek to Everest base camp is not during the
              monsoon/summer season (June, July, and August). The weather will
              be harder to deal with, and there could be problems in different
              parts of the trail. During this time of year, when the temperature
              is going up, it will be very humid. When it rains, the path gets
              muddy and slippery, and a dark cloud hides the mountain from view.
            </p>
            {/*---------------------- tipping starts --------------------- */}
            <h4 id='tipping' className='trip-detail-title'>
              Tipping
            </h4>
            <p className='trip-detail-content'>
              The cost of the Everest base camp trek does not include tips. We
              let our guests decide how much to tip the people on our field
              crew. Tipping is more of a cultural thing than something you have
              to do. But we recommend tipping to show your appreciation for your
              team's hard work, and it is best to tip as a group.
            </p>
            {/* ----------------------- Activities You Can Choose starts ---------------- */}
            <h4 className='title' id='optional-activities'>
              Activities that you can choose
            </h4>
            <p className='content'>
              Nepal has a lot of different treks and tours, such as helicopter
              tours, paragliding, rafting, mountain biking, luxury tours, yoga
              treks and tours, and so on. You can choose a package from us based
              on your interests, how much time you have, and how fit you are.
            </p>
            <p className='content'>
              We offer a variety of trips to Everest base camp, such as the
              14-day Everest base camp trek, the Everest base camp luxury trek,
              the Everest base camp and Gokyo lake trek, and the Island peak
              climbing with EBC.
            </p>
            <p className='content'>
              You can also choose our Everest panorama trek and Everest base
              camp helicopter tour with landing if you cannot go on long hikes.
              Here are some other exciting trekking packages that travelers
              love: Annapurna base camp trek, Manaslu circuit trek, Upper
              Mustang trek, Mardi Himal trek, Poon Hill trek, Annapurna circuit
              trek, etc.
            </p>
            {/* ----------------------- Group Leader - What They Do starts ---------------- */}
            <h4 id='group-leader' className='trip-detail-title'>
              Good leader Description
            </h4>
            <p className='trip-detail-content'>
              You hike with a strong group of guides and porters. Depending on
              the size of the group, we may also send a second guide. Our guides
              have been trained well and have been trekking for many years.
              Accordingly, they have led groups from all over the world to the
              base camp of Mt. Everest an uncountable number of times, so you
              can feel free to trek with us.
            </p>
            <p className='content'>
              Our guides have licenses for trekking, survival in the wilderness,
              first aid, and other skills. On your trip to Nepal, we take care
              of everything. We promise that your trek through the Himalayas
              will be safe and enjoyable to the fullest. We are one of the most
              reliable and experienced local trekking companies in Nepal, and we
              offer the best itineraries, prices, and service.
            </p>
            {/*---------------------- Size of Group Notes starts --------------------- */}
            <h4 id='group-notes' className='trip-detail-title'>
              Size of Group Notes
            </h4>
            <p className='trip-detail-content'>
              Our general Everest base trekking with helicopter return group
              size is small (5-8 trekkers), but we do organize treks for groups
              bigger than 8 people. Trekking in a big group with us, Adventure
              Master, makes much more sense because we provide assistant guides
              and manage the journey.
              <br />
              To trek with us, all you have to do is send us your inquiry. Our
              team will get back to you and help you plan a perfect Everest base
              trek with a helicopter return for you and your loved ones.
            </p>
            {/*---------------------- transport starts --------------------- */}
            <h4 id='transport' className='trip-detail-title'>
              Transport
            </h4>
            <p className='trip-detail-content'>
              We use a private car to pick up and drop off people at the
              airport. Similarly, we use a private vehicle for the sightseeing
              tour (if any). The package also includes a thrilling commercial
              flight from Kathmandu to Lukla Airport and a helicopter ride back
              to Kathmandu from Kala Patthar.
            </p>
            {/*---------------------- local flights starts --------------------- */}
            <h4 id='local-flight' className='trip-detail-title'>
              Local flight
            </h4>
            <p className='trip-detail-content'>
              The domestic flight to Lukla, where the trek starts, is part of
              the Everest Base Camp Helicopter Return Trek. And the trek ends
              with a ride back to Kathmandu by helicopter. Depending on how busy
              the airspace is during your trip, the flight to Lukla takes off
              from either Kathmandu airport or Manthali airport.
            </p>
            <p className='content'>
              Flight from Manthali to Lukla: All flights to Lukla will leave
              from Manthali airport in Ramechhap until the end of November 2022.
              It takes 4 to 5 hours to get to the airport from Kathmandu. So,
              you need to get up and leave your hotel around 2:30 AM if you want
              to make it to the airport in time for your flight. It takes 20
              minutes to get to Lukla from Manthali airport.
            </p>
            <p className='content'>
              Flight from Kathmandu to Lukla: If your flight is from Kathmandu
              to the Lukla airport, you must be at the airport by 6 a.m. From
              the airport in Kathmandu to Lukla takes about 35 minutes.
            </p>
            {/*---------------------- solo travelers starts --------------------- */}
            <h4 id='solo-traveler' className='trip-detail-title'>
              Solor Travelers
            </h4>
            <p className='trip-detail-content'>
              Solo travelers can join our Everest base camp trek that includes a
              helicopter tour. You can join our group departure or trek
              privately as you feel convenient. When you trek with a group, you
              pay less per person and get to meet people from all over the
              world.
            </p>
            <p className='content'>
              A professional guide and porter will go with you on a private
              trek. You can also charter a private helicopter or take a shared
              helicopter ride from Kala Patthar to Kathmandu.
            </p>
            {/*---------------------- joining instructions starts --------------------- */}
            <h4 id='joining' className='trip-detail-title'>
              Joining Instructions
            </h4>
            <p className='trip-detail-content'>
              Everest base camp helicopter return trekking is best done in the
              spring and fall. To join us during either of these seasons, go to
              our package page and click on the button that says "booking." Fill
              out the form and go to the next step. Pay the deposit and wait for
              our answer.
            </p>
            <p className='content'>
              You can call us at the number we gave you if you want to change
              the schedule or make any other changes to the service.
            </p>
            <p className='content'>
              Once you send us your inquiry request, a member of our team will
              get in touch with you and help you figure out what to do next.
              Joining a trek with us is easy and will not take you more than a
              few minutes.
            </p>
            <em>
              Before you book a trip, please read our{" "}
              <a
                href='https://www.adventuremastertrek.com/termsandcondition'
                target={"_blank"}
              >
                policy on booking and canceling.
              </a>
            </em>
            {/*---------------------- arrival complications starts --------------------- */}
            <h4 id='arrival' className='trip-detail-title'>
              Arrival Complications
            </h4>
            <p className='trip-detail-content'>
              If you cannot get to Kathmandu in time for the trip, let us know
              as soon as possible. We will postpone the trip because you
              requested us to. You can call the emergency number we have given
              you and tell the team about your situation. You may also have to
              pay some cancellation fees, depending on when you tell us.
            </p>
            <p className='content'>
              We will do our best to give you a satisfying alternative. Also, if
              you could not find our airport representative, you could use the
              free WIFI at the airport to call the number they gave you. You can
              call us and we will get you a taxi to the hotel.
            </p>
            {/*---------------------- emergency contact starts --------------------- */}
            <h4 id='emergency-contact' className='trip-detail-title'>
              Emergency Contact
            </h4>
            <p className='trip-detail-content'>
              You can call us at any time at our emergency numbers. These
              numbers can also be used for WhatsApp and Viber. These are the
              numbers to call if you need to reach us in an emergency: +977
              9851033195, +44 7983806200, and +977 9849780311. You can even call
              us during office hours at our office number. (M-F, 9 a.m.–5 p.m.
              local time)
            </p>
            {/*---------------------- visa and entry requirements starts --------------------- */}
            <h4 id='visa' className='trip-detail-title'>
              Visa and Entry Requirements
            </h4>
            <p className='trip-detail-content'>
              People who want to go to Nepal need a valid passport that is still
              valid for a minimum of six months and a Visa. Most countries can
              get a visa when they arrive in Nepal. This is true at Tribhuvan
              International Airport and other entry points. Make sure to find
              out if your country can get a visa when you arrive or not. If not,
              you can apply for a travel visa at Nepal's embassy or consulate in
              your home country.
            </p>
            <p className='content'>
              The traveler is responsible for getting a visa. Check all the visa
              requirements and make sure you have the right papers with you. We
              can send you a letter confirming your trip. If you do not get a
              visa, the company will not be responsible for that.
            </p>
            {/*---------------------- money exchange starts --------------------- */}
            <h4 id='money-exchange' className='trip-detail-title'>
              Money Exchange
            </h4>
            <p className='trip-detail-content'>
              You must use Nepali money in Nepal. There are many banks and
              places to exchange money in Kathmandu where you can get a good
              rate. You can use your international cards at most ATMs in
              Kathmandu to get cash.
            </p>
            <p className='content'>
              Most hotels, restaurants, cafes, shops, entertainment centers, and
              other places in Kathmandu accept Visa, Mastercard, and other
              credit cards. You will not be able to get cash out during the
              Everest Base Camp trek, so get it in Kathmandu.
            </p>
            {/*---------------------- payment and cancellation process starts --------------------- */}
            <h4 className='trip-detail-title'>Payment Process</h4>
            <p className='content'>
              You need to pay a 25% deposit up front. If you pay with a credit
              card, you might have to pay an extra 4% charge.
            </p>
            <h4 className='trip-detail-title'>Cancellation Policy:</h4>
            <p className='content'>
              The cancellation fees are calculated as a percentage of the total
              tour price:b
              <br />
              Cancellation one week before departure: 15% of full payment will
              be deducted
              <br />
              Cancellation on the same day: 25% of full payment will be deducted
            </p>
            {/*---------------------- emergency fund starts --------------------- */}
            <h4 id='emergency-fund' className='trip-detail-title'>
              Emergency Fund
            </h4>
            <p className='trip-detail-content'>
              Put aside a few hundred dollars to use in case of emergency.
              Budget your trip wisely. This surplus money will return with you
              if anything unseen does not happen, which may need you to spend
              money.
              <br />
              Apart from the package cost, you will need money for buying your
              travel insurance, international flight tickets, lunch/dinner in
              Kathmandu, shopping, tips, and personal expenses while trekking.
              USD 15 extra a day is the ideal number to spend on miscellaneous
              things like charging, hot shower internet, snacks, etc, in the
              mountains.
            </p>
            {/* ------------------- first aid starts --------------------- */}
            <h4 id='first-aid' className='title'>
              First Aid
            </h4>
            <p>
              Your team leader will be equipped with a first-aid kit, so
              trekkers do not have to carry it on their own. Our first-aid kit
              is made to serve in the mountains and includes all the necessary
              medications & tools. The team leader (mountain guide) will help
              you with first aid if needed as he is trained to offer it.
              <br />
              The kit contains an Oximeter, Bandages/plasters, Cleaning gel,
              Antiseptic ointment for cuts, Bandages/plasters, Ankle bandages,
              Set of sterile syringes (anti-AIDS precaution), Diamox 250/500mg
              (for altitude sickness), broad-spectrum antibiotic (norfloxacin or
              ciprofloxacin), Oral rehydration salts, Iodine or water filter
              (optional), Moleskin/Second skin – for blisters,
              Aspirin/Paracetamol – general painkiller, Antibacterial throat
              lozenges (with antiseptic), Antibiotic for Guardia or similar
              microbe or bacteria, etc.
            </p>
            <h4 className='title'>
              List of Trekking Equipment for Everest Base Camp Helicopter Return
              Trek
            </h4>
            <p className='content'>
              Below are the things you will need for Everest Base Camp
              Helicopter Return Trek. Depending upon the season you are trekking
              in, you may have to add a couple more things. We will give you a
              personalized packing check-list at the time of booking to help you
              with packing.
            </p>
            {/*---------------------- packing starts --------------------- */}
            <h4 id='packing-list' className='trip-detail-title'>
              Packing list
            </h4>
            <p className='trip-detail-content'>
              After the booking, our team will also give you a personalized
              packing checklist that you can use to pack for the Mardi Himal
              trek.
              <br />
              For now, you can have a look at the below things for a general
              overview.
            </p>
            <strong>Head and Hand</strong>
            <ul>
              <li>Sun Cap </li>
              <li>Wool/Fleece Hat </li>
              <li>Balaclava </li>
              <li>Neck Gaiter/High Neck</li>
              <li>Lightweight Synthetic Liner Glove </li>
              <li>Wind Stopper Fleece Gloves </li>
            </ul>
            <strong>Upper Body</strong>
            <ul>
              <li>Short-Sleeved Shirts/T-shirts </li>
              <li>Lightweight Top/Thermo Coat </li>
              <li>Synthetic or Fleece Jacket </li>
              <li>Down Insulated Jacket </li>
            </ul>
            <strong>Lower Body</strong>
            <ul>
              <li>Underwear</li>
              <li>Lightweight Long Underpants </li>
              <li>Midweight Long Underpants </li>
              <li>Trekking Pants </li>
              <li>Trekking Socks </li>
              <li>Sandals </li>
              <li>Lightweight Hiking/Trekking Boots </li>
            </ul>
            <strong>Toiletries</strong>
            <ul>
              <li>Hand Sanitizers & Hand wash </li>
              <li>Wet Tissues </li>
              <li>Toothpaste </li>
              <li>Toothbrush </li>
              <li>Bath Soap </li>
              <li>Shampoo</li>
              <li>Moisturizers </li>
              <li>Laundry Soap</li>
              <li>Garbage Bag </li>
              <li>Eye Drops </li>
              <li>Zip-Lock Bags</li>
              <li>Sunscreen (-50 Spf)</li>
              <li>Lips Guard (-20/-50 Spf) </li>
              <li>Water Purification Tablets </li>
              <li>Baby Wipes or Wet Towels </li>
            </ul>
            <strong>Miscellaneous</strong>
            <ul>
              <li>Swiss Knife </li>
              <li>Rucksacks (45 - 55 Ltr.) </li>
              <li>1 Duffel Bag (provided by the company)</li>
              <li>Trekking poles</li>
              <li>Sleeping bag</li>
              <li>Torchlight </li>
              <li>Water Bottles </li>
              <li>Thermos/Flask </li>
              <li>Pack Towel </li>
              <li>Spare batteries </li>
              <li>Waterproof Stuff Sacks </li>
              <li>Satellite Phone (if possible)</li>
              <li>Solar chargeable power bank (optional)</li>
              <li>Journal/Pen</li>
              <li>Book/Board Game</li>
              <li>Bars & Snacks</li>
            </ul>
            <strong>Travel Document</strong>
            <ul>
              <li>Valid Passport </li>
              <li>Valid Visa </li>
              <li>4 PP Size Photo </li>
              <li>Insurance Paper (Medical & Rescue) & Contact Address </li>
              <li>Family/Company Contact Number & Address </li>
              <li>Credit Card</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default TripDetail;
