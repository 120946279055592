import React from "react";
import { Col, Row } from "react-bootstrap";
import { FaMountain, FaUtensils, FaBed, FaHiking } from "react-icons/fa";
import { FaBus } from "react-icons/fa";
import { FaClock } from "react-icons/fa";

import day5 from "../../../image/MardiHimalTrek/mardiHimal/2.jpg";
import day6 from "../../../image/MardiHimalTrek/mardiHimal/4.jpg";
import day4 from "../../../image/MardiHimalTrek/mardiHimal/lowcamp.jpg";
import day3 from "../../../image/MardiHimalTrek/mardiHimal/pitamDeurali.jpg";
import day2 from "../../../image/MardiHimalTrek/pokhara.jpg";
import day1 from "../../../image/boudha.jpg";
import day9 from "../../../image/kathmanduAirport.jpg";
import day8 from "../../../image/kathmandu.jpeg";
import day7 from "../../../image/pokhara1.jpg";
import Map from "../../../image/Map/mardiHimalBaseCampTrekMap.jpg";
import Pdf from "../../../itineraryDocuments/AnapurnaRegion/MardiHimalBaseCampTrek.pdf";

import "../../../custom-sass/pages/trip/_trip-detail.scss";

const Itinerary = () => {
  const days = [
    {
      day1: [
        {
          title: "Day 1: Arrive in Kathmandu",
          image: day1,
          caption:
            "When you get to Tribhuvan International Airport in Kathmandu, representatives from Adventure Master will be there to meet you. They will take care of getting you to your hotel. Since you do not  know when you will arrive, this day is free for you to take it easy.",
          caption1:
            "In the evening, you will meet us at our office, where we will introduce you to your trek guide and give you a rundown of what to expect on the trek.Also, we will spend the evening getting ready for the hike by packing.",
          tripDetail: [
            // {
            //   icon: "FaMountain",
            //   detailTitle: "Maximum Altitude",
            //   detailCaption: "1400m",
            // },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "Null",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Hotel",
            },
          ],
        },
      ],
      day2: [
        {
          title: "Flight from Kathmandu to Pokhara (822m)",
          image: day2,
          caption:
            "Today, we will  start our trip to Pokhara, which is where the trek to the Mardi Himal base camp starts. The trek starts just an hour from the city, and we will leave Kathmandu for Pokhara early in the morning.",
          caption1:
            "You can see the beautiful Himalayan range from there. The trip is relaxing and a great way to start the hike.",
          tripDetail: [
            // {
            //   icon: "FaHiking",
            //   detailTitle: "Trek Distance",
            //   detailCaption: "6.2 km/3.8 miles",
            // },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "6-7 hours drive",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "Breakfast, Lunch, Dinner",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Hotel",
            },
          ],
        },
      ],
      day3: [
        {
          title:
            "Day 3: Drive to Kande (1,700 m/5,577 ft) and hike to Pitam Deurali (1,900 m/6,233 ft)",
          image: day3,
          caption:
            "After breakfast, we will  drive from Pokhara to Kande, where our trek will begin. From there, we will start our trip with a steep climb through the jungle, passing by local villages until we reach Australian Camp, where we will stop for lunch.",
          tripDetail: [
            // {
            //   icon: "FaHiking",
            //   detailTitle: "Trek Distance",
            //   detailCaption: "7.4 km/4.6 miles",
            // },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "1 hour drive & 4 hours trek",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "Breakfast, Lunch, Dinner",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Teahouse",
            },
          ],
        },
      ],
      day4: [
        {
          title: "Day 4: From Pitam Deurali to Low Camp (2,985 m/9,793 ft)",
          image: day4,
          caption:
            "FFrom Pitam Deurali, we leave the main Annapurna Sanctuary trail and head towards Mardi Himal Base Camp on a less traveled path to the right. The path takes us through a thick forest of oak, maple, hemlock, and rhododendron trees. After walking through the jungle for about four hours, we stop for lunch at a clearing in the forest called Forest Camp or Kokar.",
          caption1:
            "From there, we keep going up towards Low Camp, passing through a forest with more rhododendrons and trees with moss, lichen, and tree ferns on them. This can be a long and hard day of trekking, but fit trekkers should be able to handle it.",
          tripDetail: [
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "1 hour drive & 4 hours trek",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "Breakfast, Lunch, Dinner",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Tea house",
            },
          ],
        },
      ],
      day5: [
        {
          title: "Day 5: Trek to High Camp (3,580 m/11,745 ft)",
          image: day5,
          caption:
            "Middle Camp is reached after a 1.5-hour climb through a rhododendron forest. From there, we go to Badal Danda, which has beautiful views of snow-capped peaks. As we move up from Low Camp, the trail breaks out above the treeline and gives us a good view of Annapurna South and Hiunchuli to the west.",
          caption1:
            "The trail then goes along the Mardi Himal ridge, which is mostly grassy and has a few rhododendron bushes here and there. We will find a larger lodge with eight rooms and a separate dining room with a heater when we get to High Camp. From the lodge, you can see Annapurna South, Hiunchuli, and Machhapuchhre, which is a great view (Fishtail).",
          tripDetail: [
            // {
            //   icon: "FaHiking",
            //   detailTitle: "Trek Distance",
            //   detailCaption: "9.2 km/5.7 miles",
            // },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "1 hour drive & 4 hours trek",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "Breakfast, Lunch, Dinner",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Tea house",
            },
          ],
        },
      ],
      day6: [
        {
          title:
            "Day 6: Hike to Mardi Himal Base Camp (4,500 m/14,763 ft) and back to High Camp (3,580 m/11,745 ft)",
          image: day6,
          caption:
            "The plan for today is to get up early to see the mountains at sunrise, then walk up the ridge toward Mardi Himal Base Camp after an early breakfast. The path to Base Camp is mostly grassy, but there are a few steeper parts. Overall, it is not too hard.",
          caption1:
            "As we go up, we might see dzoko (crosses between yaks and cows) and the beautiful Danphe pheasant grazing in the area. During the summer, sheep and goats from villages lower down are brought up to this area to graze. We may also pass a few herders' huts on the way.",
          caption2:
            "We will walk for about three hours to get to base camp, which is 4,500 meters above sea level. From here, we can see the south face of Annapurna and all of the peaks in the Annapurna Sanctuary, as well as Hiunchuli and Machhapuchhre, in a beautiful, all-around view (Fishtail).",
          tripDetail: [
            // {
            //   icon: "FaHiking",
            //   detailTitle: "Trek Distance",
            //   detailCaption: "",
            // },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "4-5 hours",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "Breakfast, Lunch, Dinner",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Tea house",
            },
          ],
        },
      ],
      day7: [
        {
          title:
            "Day 7: Hike to Siding (6,184 m) and then drive to Pokhara (2,713 m)",
            image: day7,
          caption:
            "When we go back, we will  take a different path and end up making a loop. Instead of going back to Kokar on the Mardi Himal trail, we choose to go down to the village of Siding on a different path. Siding is an adorable village in Nepal that has not  been changed much by tourism yet.",
          caption1:
            "We might be able to stay in a homestay that is owned by the person who runs one of the lodges at Low Camp. This cultural experience gives us a chance to learn more about life in a village and maybe even eat dhal bhat with the family in their kitchen.",
          tripDetail: [
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "5 hours trek & drive",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "Breakfast, Lunch, Dinner",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Hotel",
            },
          ],
        },
      ],
      day8: [
        {
          title: "Day 8: Drive back to Kathmandu by private car",
          image: day8,
          caption:
            "We'll be driving back to Kathmandu today. Like the last time, the drive from Pokhara to Kathmandu is easy and beautiful. When we get to Kathmandu, we will take you to your hotel. Spend the rest of the day in Thamel window shopping and checking out the local market. We'll have a farewell dinner with you in the late evening.",
          tripDetail: [
            // {
            //   icon: "FaHiking",
            //   detailTitle: "Trek Distance",
            //   detailCaption: "8.5 km/5.2 miles",
            // },
            {
              icon: "FaClock",
              detailTitle: "Trek Duration",
              detailCaption: "6-7 hours",
            },
            {
              icon: "fa fa-meals",
              detailTitle: "Meals",
              detailCaption: "Breakfast, Lunch, Dinner",
            },
            {
              icon: "fa fa-bed",
              detailTitle: "Accommodation",
              detailCaption: "Lodge",
            },
          ],
        },
      ],
      day9: [
        {
          title: "DAY 9: Final departure",
          image: day9,
          caption:
            "Your journey with us is now over. You can stay longer and join one of our other exciting Himalayan trips. If not, our airport staff will make sure you get to the airport on time for your flight.",
          // tripDetail: [
          //   {
          //     icon: "FaHiking",
          //     detailTitle: "Trek Distance",
          //     detailCaption: "15 km/9.32 miles",
          //   },
          //   {
          //     icon: "FaClock",
          //     detailTitle: "Trek Duration",
          //     detailCaption: "8 hours",
          //   },
          //   {
          //     icon: "fa fa-meals",
          //     detailTitle: "Meals",
          //     detailCaption: "Breakfast, Lunch, Dinner",
          //   },
          //   {
          //     icon: "fa fa-bed",
          //     detailTitle: "Accommodation",
          //     detailCaption: "Accomodation: Rivendell Lodge",
          //   },
          // ],
        },
      ],
    },
  ];

  return (
    <>
      <div className="detail-itinerary container mt-4">
        <div className="row">
          <div className="d-lg-none mb-5">
            <h4 className="title">Trekking Route Map</h4>
            <img
              src={Map}
              alt="everest base camp trek map"
              style={{ width: "100%" }}
            />
            <button type="button" className="btn btn-dark mt-3 col-12">
                <a href={Pdf} target="_blank" className="text-light">
                  Download Itinerary
                </a>
              </button>
          </div>

          <div className="col-md-12 col-lg-8">
            {days &&
              days.map((day) => (
                <>
                  {day.day1 &&
                    day.day1.map((day1) => (
                      <>
                        <h4 className="itinerary-title">{day1.title}</h4>
                        <div className="itinerary-image">
                          <img src={day1.image} alt="kathmandu" />
                          <figcaption className="text-secondary">
                            Kathmandu: Boudha Stupa
                          </figcaption>
                        </div>
                        <div className="row">
                          {day1.tripDetail &&
                            day1.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-6 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col p-0"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day1.caption}
                          <br />
                          {day1.caption1}
                        </div>
                      </>
                    ))}

                  {day.day2 &&
                    day.day2.map((day2) => (
                      <>
                        <h4 className="itinerary-title">{day2.title}</h4>
                        <div className="itinerary-image">
                          <img src={day2.image} alt="pokhara" />
                          <figcaption className="text-secondary">
                            Pokhara: Phewa Lake
                          </figcaption>
                        </div>
                        <div className="row">
                          {day2.tripDetail &&
                            day2.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-4 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col p-0"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day2.caption}
                          <br />
                          {day2.caption1}
                          <br />
                        </div>
                      </>
                    ))}
                  {day.day3 &&
                    day.day3.map((day3) => (
                      <>
                        <h4 className="itinerary-title">{day3.title}</h4>
                        <div className="itinerary-image">
                          <img src={day3.image} alt="" />
                          <figcaption className="text-secondary">
                            Pitam Deurali
                          </figcaption>
                        </div>
                        <div className="row">
                          {day3.tripDetail &&
                            day3.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-4 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col p-0"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day3.caption}
                          <br />
                          {day3.caption1}
                        </div>
                      </>
                    ))}
                  {day.day4 &&
                    day.day4.map((day4) => (
                      <>
                        <h4 className="itinerary-title">{day4.title}</h4>
                        <div className="itinerary-image">
                          <img src={day4.image} alt="kathmandu" />
                          <figcaption className="text-secondary">
                            Low Camp
                          </figcaption>
                        </div>
                        <div className="row">
                          {day4.tripDetail &&
                            day4.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-4 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col p-0"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day4.caption}
                          <br />
                          {day4.caption1}
                        </div>
                      </>
                    ))}
                  {day.day5 &&
                    day.day5.map((day5) => (
                      <>
                        <h4 className="itinerary-title">{day5.title}</h4>
                        <div className="itinerary-image">
                          <img src={day5.image} alt="kathmandu" />
                          <figcaption className="text-secondary">
                            High Camp
                          </figcaption>
                        </div>
                        <div className="row">
                          {day5.tripDetail &&
                            day5.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-4 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col p-0"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day5.caption}
                          <br />
                          {day5.caption1}
                        </div>
                      </>
                    ))}
                  {day.day6 &&
                    day.day6.map((day6) => (
                      <>
                        <h4 className="itinerary-title">{day6.title}</h4>
                        <div className="itinerary-image">
                          <img src={day6.image} alt="kathmandu" />
                          <figcaption className="text-secondary">
                            Mardi Himal
                          </figcaption>
                        </div>
                        <div className="row">
                          {day6.tripDetail &&
                            day6.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-4 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col p-0"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day6.caption}
                          <br />
                          {day6.caption1}
                          <br />
                          {day6.caption2}
                        </div>
                      </>
                    ))}
                  {day.day7 &&
                    day.day7.map((day7) => (
                      <>
                        <h4 className="itinerary-title">{day7.title}</h4>
                        <div className="itinerary-image">
                          <img src={day7.image} alt="pokhara" />
                          <figcaption className="text-secondary">
                            Pokhara
                          </figcaption>
                        </div>
                        <div className="row">
                          {day7.tripDetail &&
                            day7.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-4 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaBus" && (
                                        <FaBus fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col p-0"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day7.caption}
                          <br />
                          {day7.caption1}
                        </div>
                      </>
                    ))}
                  {day.day8 &&
                    day.day8.map((day8) => (
                      <>
                        <h4 className="itinerary-title">{day8.title}</h4>
                        <div className="itinerary-image">
                          <img src={day8.image} alt="kathmandu" />
                          <figcaption className="text-secondary">
                            Kathmandu
                          </figcaption>
                        </div>
                        <div className="row">
                          {day8.tripDetail &&
                            day8.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-4 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col p-0"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day8.caption}
                        </div>
                      </>
                    ))}
                  {day.day9 &&
                    day.day9.map((day9) => (
                      <>
                        <h4 className="itinerary-title">{day9.title}</h4>
                        <div className="itinerary-image">
                          <img src={day9.image} alt="kathmandu airport" />
                          <figcaption className="text-secondary">
                            Tribhuwan International Airport (TIA)
                          </figcaption>
                        </div>
                        <div className="row">
                          {day9.tripDetail &&
                            day9.tripDetail.map((tripDetail) => (
                              <>
                                <div className="col-sm-12 col-md-4 itinerary-icon">
                                  <Row className="align-items-center my-2">
                                    <Col className="col-auto">
                                      {tripDetail.icon === "FaHiking" && (
                                        <FaHiking fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaClock" && (
                                        <FaClock fontSize={20} />
                                      )}
                                      {tripDetail.icon === "FaMountain" && (
                                        <FaMountain fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-meals" && (
                                        <FaUtensils fontSize={20} />
                                      )}
                                      {tripDetail.icon === "fa fa-bed" && (
                                        <FaBed fontSize={20} />
                                      )}
                                    </Col>

                                    <Col
                                      // sm={10}
                                      className="itinerary-icon-title col p-0"
                                    >
                                      <Row className="d-flex justify-content-between align-items">
                                        {/* <Col sm={4} className=""> */}
                                        <Col className="col-12">
                                          {tripDetail.detailTitle}{" "}
                                        </Col>
                                        <Col className="col-auto">
                                          {tripDetail.detailCaption}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ))}
                        </div>
                        <div className="pb-5 itinerary-content">
                          {day9.caption}
                        </div>
                      </>
                    ))}
                </>
              ))}
          </div>

          <div className="main-modal-map d-md-none d-lg-flex">
            <div className="modal-map d-flex flex-column">
              <h4 className="title">Trekking Route Map</h4>
              <img src={Map} alt="everest base camp trek map" />
              <button type="button" className="btn btn-dark mt-3 col-12">
                <a href={Pdf} target="_blank" className="text-light">
                  Download Itinerary
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Itinerary;
