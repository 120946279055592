import React from "react";

import Accordion from "react-bootstrap/Accordion";
import TripFact from "./TripFact";

import { FaCheckCircle } from "react-icons/fa";
import CancelIcon from "@mui/icons-material/Cancel";

export default function Overview() {
  return (
    <div className='overview'>
      <div className='container d-flex mt-5'>
        <div className='row'>
          {/* <div className="col-lg-9 col-sm-12"> */}
          <div className='col-12'>
            <TripFact />

            <h4 className='overview-title mt-4'>Outline Itinerary</h4>
            <ul className='p-0'>
              <div className='overview-outline-itinerary'>
                <div className='col-auto'>
                  <strong>Day 01:</strong>
                </div>
                <li>Arrival in Kathmandu, visit Swayambhunath Temple</li>
              </div>
              <div className='overview-outline-itinerary '>
                <div className='col-auto'>
                  <strong>Day 02:</strong>
                </div>
                <li>Visit Boudhanath Stupa and Kopan Monastery</li>
              </div>
              <div className='overview-outline-itinerary '>
                <div className='col-auto'>
                  <strong>Day 03:</strong>
                </div>
                <li>
                  Namobuddha tour with meditation, overnight stay in Namobuddha
                </li>
              </div>
              <div className='overview-outline-itinerary '>
                <div className='col-auto'>
                  <strong>Day 04:</strong>
                </div>
                <li>
                  Fly from Kathmandu to Bhairawaha and drive to Lumbini, Lumbini
                  day tour
                </li>
              </div>
              <div className='overview-outline-itinerary '>
                <div className='col-auto'>
                  <strong>Day 05:</strong>
                </div>
                <li>Explore Lumbini and Tilaurakot</li>
              </div>
              <div className='overview-outline-itinerary '>
                <div className='col-auto'>
                  <strong>Day 06:</strong>
                </div>
                <li>
                  Fly back to Kathmandu, visit the Himalayan Buddhist Meditation
                  Center and do shopping
                </li>
              </div>
              <div className='overview-outline-itinerary '>
                <div className='col-auto'>
                  <strong>Day 07:</strong>
                </div>
                <li>Final departure</li>
              </div>
            </ul>

            <h4 className='overview-title pt-5'>Trip Highlight</h4>
            <ul className='trip-highlight'>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  During the Buddhist pilgrimage tour in Nepal, you can see some
                  of the stupas and monasteries that have been built over
                  hundreds of years.
                </li>
              </span>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  In Kathmandu, visit the Swayambhunath temple and the
                  Bouddhanath Stupa.
                </li>
              </span>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  You can see Kopan monastery and Namobuddha on the edge of
                  Kathmandu.
                </li>
              </span>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>Learn about the roots of Buddhism in Lumbini</li>
              </span>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>Learn more about Gautam Buddha and his childhood</li>
              </span>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Lumbini has many religious sites built by different countries.
                  You can visit the Maya Devi temple, the holy pond, the garden,
                  the museum, the Ashoka pillar, and many others.
                </li>
              </span>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Find out about the culture, traditions, and foods of Nepal
                </li>
              </span>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>Relax, recharge, and get in touch with your higher self</li>
              </span>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Try yoga and meditation, watch monks pray, and look around the
                  local markets
                </li>
              </span>
            </ul>

            <div className='row d-flex justify-content-between'>
              <div className='col-12'>
                <h4 className='overview-title pt-5'>Cost Include</h4>
                <ul className='cost-include'>
                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>
                      All ground travel, including getting to and from the
                      airport, is done in a private car.
                    </li>
                  </span>
                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>Drive from Kathmandu to Namobuddha by private car.</li>
                  </span>
                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>Drive from Bhairahawa to lumbini via private car.</li>
                  </span>
                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>Kathmandu to Bhairahawa via flight</li>
                  </span>
                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>
                      Two night stay at a standard hotel in Kathmandu with
                      breakfast included.
                    </li>
                  </span>
                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>
                      One night accommodation at a standard hotel in Namo Buddha
                      with breakfast included.
                    </li>
                  </span>
                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>
                      Two nights accommodation at a standard hotel in lumbini
                      with breakfast included.
                    </li>
                  </span>
                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>
                      we will drive to Tilaurakot, near Kapilvastu via private
                      vehicle for sightseeing.
                    </li>
                  </span>
                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>
                      All papers that need to be done, Fees for entering permits
                      you need to go while your journey.
                    </li>
                  </span>
                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>Taxes and official costs paid to the government.</li>
                  </span>
                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>
                      First-aid kit and a certificate of completion for the trip
                    </li>
                  </span>
                  <span>
                    <div className='cost-include-icon'>
                      <FaCheckCircle />
                    </div>
                    <li>
                      A highly experienced, helpful, knowledgeable, friendly,
                      English-speaking guide with all of his salary, food,
                      drinks, lodging, transportation, and insurance covered.
                    </li>
                  </span>
                </ul>
              </div>
              <div className='col-12'>
                <h4 className='overview-title pt-5'>Cost Exclude</h4>
                <ul className='cost-exclude'>
                  <span>
                    <div className='cost-exclude-icon'>
                      <CancelIcon />
                    </div>
                    <li>Food that you will eat for lunch and dinner</li>
                  </span>
                  <span>
                    <div className='cost-exclude-icon'>
                      <CancelIcon />
                    </div>
                    <li>
                      <p>
                        The cost of a visa to enter Nepal is
                        <br />
                        $30 USD for a 15-day stay,
                        <br />
                        30 Days - $50,
                        <br />
                        90 Days - $125
                      </p>
                    </li>
                  </span>
                  <span>
                    <div className='cost-exclude-icon'>
                      <CancelIcon />
                    </div>
                    <li>Your insurance for travel and medical care.</li>
                  </span>
                  <span>
                    <div className='cost-exclude-icon'>
                      <CancelIcon />
                    </div>
                    <li>International airfare.</li>
                  </span>
                  <span>
                    <div className='cost-exclude-icon'>
                      <CancelIcon />
                    </div>
                    <li>Your personal expenses.</li>
                  </span>
                  <span>
                    <div className='cost-exclude-icon'>
                      <CancelIcon />
                    </div>
                    <li>
                      All of the alcoholic and non-alcoholic cold and hot drinks
                      you buy on the journey.
                    </li>
                  </span>
                  <span>
                    <div className='cost-exclude-icon'>
                      <CancelIcon />
                    </div>
                    <li>
                      All of the sweet desserts, like chocolate, cake, pie, and
                      pudding.
                    </li>
                  </span>
                  <span>
                    <div className='cost-exclude-icon'>
                      <CancelIcon />
                    </div>
                    <li>
                      Tip to the guide, and the driver. (Tipping is expected).
                    </li>
                  </span>
                  <span>
                    <div className='cost-exclude-icon'>
                      <CancelIcon />
                    </div>
                    <li>
                      Meals that are not listed in the "Meals Included" section.
                    </li>
                  </span>
                </ul>
              </div>
            </div>

            <h4 className='title pt-5'>Activities You Can Choose</h4>
            <p>
              We have many tours in Nepal, including
              <strong>
                day tours, city tours, helicopter tours, jungle safaris,
                overland tours, and pilgrimage tours.
              </strong>
              You can choose the program that best suits your needs and send us
              your inquiry. Likewise, you can also customize the Buddhist
              pilgrimage tour itinerary according to your needs.
            </p>

            <p>
              We have hand-picked some of our best tour packages for you-
              <strong>
                Nepal Bhutan tour, Nepal Tibet tour, Nepal India Buddhist
                pilgrimage tour, Lumbini tour, Nepal family tour
              </strong>
              , etc.
            </p>

            <p>
              If you want to experience the ancient Buddhist religion and
              traditions practiced in the remote mountains of Nepal's Himalayas,
              we highly recommend the following packages-
              <strong>
                Mani Rimdu festival tour, Tiji festival tour, Tamang heritage
                tour, Sherpa village tour, Pungyen gompa trek, etc.
              </strong>
            </p>

            <h4 className='overview-title pt-5'>FAQ</h4>

            <div className='trip-overview-accordion'>
              <Accordion defaultActiveKey='0' flush>
                <Accordion.Item eventKey='0'>
                  <Accordion.Header>
                    <p class='accordion-title'>
                      1) What is a popular pilgrimage for Buddhists in Nepal?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='overview-content'>
                      The most important pilgrimage site in Kathmandu is the
                      Boudhanath Stupa. It is built on a trade route from the
                      past. In the same way, Lumbini, where Gautam Buddha was
                      born, has many important historical monasteries. In Nepal,
                      around the Himalayas, there are also many monasteries that
                      have been there for hundreds of years.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='1'>
                  <Accordion.Header>
                    <p class='accordion-title'>
                      2) How long does the pilgrimage take?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='accordion-content'>
                      The length of our Buddhist pilgrimage tour is seven days.
                      You can make the plan short or long, depending on what you
                      want. During the tour, you will visit many monasteries,
                      stupas, and museums.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='2'>
                  <Accordion.Header>
                    <p class='accordion-title'>
                      3) Which Buddhist pilgrimage site in Nepal is the most
                      important?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='overview-content'>
                      Nepal and Buddhism go back a long time and are very close.
                      The very fact that the creator of Buddhism was born in
                      Nepal makes the country rich in Buddhism. We have many
                      important monuments all over the country, such as the
                      Bouddhnath Stupa, Namo Buddha, Maya Devi Temple, Tengboche
                      Monastery, Ghar Gompa, Pungyen Gompa, etc.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='3'>
                  <Accordion.Header>
                    <p class='accordion-title'>
                      4) What does a Buddhist pilgrimage look like?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='overview-content'>
                      During the Buddhist pilgrimage tour, you will visit many
                      monasteries and see monks chanting and meditating. You
                      will learn about where Buddhism came from, see many
                      monuments, look around local markets, try Nepalese
                      specialties, shop in local markets, etc.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='4'>
                  <Accordion.Header>
                    <p class='accordion-title'>
                      5) What is a Buddhist temple called?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='overview-content'>
                      The Buddhist temple has many names, depending upon the
                      size and its purpose. The Monastery is the common term. It
                      is called Gompa in local words. Stupas are usually small
                      and are around the premises of the main monastery. Stupas
                      are also widely found along the trekking routes in Nepal.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='5'>
                  <Accordion.Header>
                    <p class='accordion-title'>
                      6) Are there any long walks on a Buddhist pilgrimage tour?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='overview-content'>
                      No, there are no long walks on our 7-day Buddhist
                      pilgrimage tour. You will take a few short walks around
                      the grounds and nearby markets. During the tour, your body
                      will not be put under any kind of stress.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='6'>
                  <Accordion.Header>
                    <p className='accordion-title'>
                      7) Does the tour include yoga and meditation?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='overview-content'>
                      Yes, yoga and meditation will be part of the tour. You can
                      do the session on your own or hire a professional
                      instructor. You can also add other paid activities to your
                      tour, like sound healing, painting sessions, massages,
                      etc.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='7'>
                  <Accordion.Header>
                    <p class='accordion-title'>
                      8) Can I extend my trip with you?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='overview-content'>
                      Yes, you can always add more time to your trip with us.
                      Tell us what you want to do next, and our team will help
                      you come up with a great plan.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='8'>
                  <Accordion.Header>
                    <p class='accordion-title'>
                      9) Can I take my family on a Buddhist pilgrimage tour?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='overview-content'>
                      Yes, you can take your family on the Buddhist pilgrimage
                      tour. Everything is carefully planned and run so that
                      everyone in the family can enjoy it. We also offer paid
                      add-ons like yoga, painting, sound healing, and other
                      things that you and your family can do together.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='9'>
                  <Accordion.Header>
                    <p class='accordion-title'>
                      10) During the tour, can we take pictures of the
                      monuments?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='overview-content'>
                      During the tour, you can take pictures of the monuments.
                      Some of the monasteries may not let you take pictures
                      inside, so be sure to follow the rules.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='10'>
                  <Accordion.Header>
                    <p class='accordion-title'>
                      11) Do we need tickets to get into the monuments?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p class='overview-content'>
                      All of the permit fees are included in the price of our
                      Buddhist pilgrimage tour. So you don't have to spend extra
                      money on it. In the same way, our team will get all the
                      permits for you.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>

          {/* sidebar price */}
          {/* <div className="col-lg-3 col-sm-12">
            <div className="overview-sidebar bg-light">
              <span className="badge text-wrap">Top Seller</span>
              <div className="">
                <span className="overview-sidebar-days">13 Days</span>
                <br />
                <span className="overview-sidebar-destination">
                  Kathmandu to Kathmandu
                </span>
                <br />
                <h4 className="price-text my-3">From USD $N/A</h4>
                <div className="group-discount mb-3">
                  <table className="group-discount-table">
                    <p>Group Discount</p>
                    <tr>
                      <td>Pax</td>
                      <td>Price</td>
                    </tr>
                    <tr>
                      <th>1 Pax</th>
                      <th>$N/A</th>
                    </tr>
                    <tr>
                      <th>2 Pax</th>
                      <th>$N/A</th>
                    </tr>
                    <tr>
                      <th>3 Pax</th>
                      <th>$N/A</th>
                    </tr>
                    <tr>
                      <th>4-6 Pax</th>
                      <th>$N/A</th>
                    </tr>
                    <tr>
                      <th>7-10 Pax</th>
                      <th>$N/A</th>
                    </tr>
                    <tr>
                      <th>11-18 Pax</th>
                      <th>$N/A</th>
                    </tr>
                    <tr>
                      <th>19-24 Pax</th>
                      <th>$N/A</th>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="">
                <HashLink smooth to="/booking" className="link">
                  <button className="book-btn">Book Now</button>
                </HashLink>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
