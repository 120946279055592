import React, { useState } from "react";
import {
  AiOutlineArrowUp,
  AiOutlineArrowDown,
  AiOutlineCloseCircle,
} from "react-icons/ai";
import { HashLink } from "react-router-hash-link";

const Modals = (props) => {
  const { tripTitle, tripPrice, sendmodaldetails } = props;
  const { id, modalhead, modaldetail } = sendmodaldetails;
  const [expandModal, setExpandModal] = useState(false);

  const modaldetailtrip = [
    {
      id: 1,
      modaldetail: [
        // {
        //   modaltitle: "Annapurna Region:",
        //   modaltext:
        //     "Annapurna region is the western part of Nepal. The region houses the seventh, eighth, and tenth highest mountains in the world; Dhaulagiri, Manaslu & Annapurna. The region is protected under the Annapurna Conservation Area. In the north of the main Himalayan ranges (Annapurna & Dhaulagiri) is the trans-Himalayan area and boasts arid landscape & Tibetan Buddhism cultural heritage. Annapurna region is famous for its adventurous trekking routes.",
        // },
        {
          modaltitle: "Annapurna Region:",
          modaltext:
            "Annapurna region is in the western part of Nepal. Dhaulagiri, Manaslu, and Annapurna, the seventh, eighth, and tenth highest mountains in the world, are all in this area. The Annapurna Conservation Area looks out for the area. The trans-Himalayan area is north of the main Himalayan ranges (Annapurna and Dhaulagiri) and has a dry landscape and a long history of Tibetan Buddhism. The Annapurna region is known for its exciting hiking trails.",
        },
      ],
    },
    {
      id: 2,
      modaldetail: [
        {
          modaltitle: "Difficulty Level:",
          modaltext:
            "The Annapurna base camp trek from Kathmandu or Pokhara is rated as moderately difficult. As the trek goes on and you reach higher elevations, things like altitude, the lack of people in the area, etc. make it harder. So, stay hydrated and prepare yourself both mentally and physically.",
        },
        {
          modaltitle: "Terrain:",
          modaltext:
            "There are many kinds of terrain. You start your walk in subtropical forests. Part of the trail through the forest is dense and wet. You walk up and down many rocky stairs. As you walk along foothills, you will also go up and down as you go along the trail. In the last part of the trail, you will pass through meadows and an alpine zone. You walk on rocky paths that are steep.",
        },
        {
          modaltitle: "Altitude Sickness:",
          modaltext:
            "There is a chance of getting altitude sickness on the Annapurna base camp trek. Don't take altitude sickness lightly, because in the worst case, we will have to take you to a hospital in Pokhara or Kathmandu. If you take the precautions, your chances of getting altitude sickness on the trek are low. Our crew will watch out for you on the trek to make sure you do not  do anything careless that could cause altitude sickness.",
        },
        {
          modaltitle: "Team:",
          modaltext:
            "Our team on the ground has done the Annapurna base camp trek many times and knows it well. They have been trained to help tourists in the Himalayas and can handle any kind of emergency. All of the people on our team have been vaccinated against Covid-19. Before they go on any treks, we make sure they are healthy.",
        },
        {
          modaltitle: "Support:",
          modaltext:
            "We give full support for the Annapurna base camp trek in Nepal. We take care of your accommodation and meals, arrange the permits, and help you with little things like getting a sim card, airport pickup/drop, etc. Likewise, during the trek, you trek with a reliable and experienced trekking guide and porters. Our guides not only show the trail, but also bridge the gap between locals and tourists. ",
        },
        {
          modaltitle: "Equipments:",
          modaltext:
            "We will  give you a list of what to bring on trekking that you can follow. Most of the clothes and gear you need for trekking can be bought in Thamel, Kathmandu. So, do not have to bring heavy bags. There are also ways to rent, so if you are not a very active trekker, you do not have to spend a lot of money.",
        },
      ],
    },
    {
      id: 3,
      modaldetail: [
        {
          modaltitle: "Facilities:",
          modaltext:
            "The facilities in the lodes along the Annapurna base camp trail are basic. Most of the services like hot shower, charging, WiFi, etc are more sort of paid add-ons (not covered by the package cost). You get a small room with comfortable bedding to spend the night. The washrooms are clean & common.",
        },
        {
          modaltitle: "Single room",
          modaltext:
            "Single rooms are available on request. If you are traveling by yourself and do not  want to share a room with another trekker, you can let us know when you book. We will get you a single room. If you choose to stay alone, there may be a small change in the package price.Single rooms are on the expensive side.",
        },
        {
          modaltitle: "Hot water:",
          modaltext:
            "Hot water for showers is available on “paid service” in most of the villages. Therefore, If you want to take a hot shower, you can ask for it in the lodge. The charge for the service is not included in the package cost, so you have to bear it by yourself. You can carry some extra Nepali cash with you for service like this.",
        },
        {
          modaltitle: "Charging:",
          modaltext:
            "The rooms do not have any plugs for electricity. The charging station is built around the front desk, where trekkers can pay a few extra bucks to charge their devices. Again, everything in the Himalayas costs money. Therefore, you can carry extra batteries or a solar charger with you.",
        },
        {
          modaltitle: "WiFi:",
          modaltext:
            "Some villages at lower elevations have paid WiFi service. You can use wifi for a few hours for $2 to $5. Because of the height, the link might not be stable. We suggest that you use cellular data so you can browse the Internet better. To let you know, you can use a Nepali SIM card and cellular data to connect to the internet. This is a much cheaper option, and you can use the Internet while you are hiking as well. Hence, the network is also better.",
        },
      ],
    },
    {
      id: 4,
      modaldetail: [
        {
          modaltitle: "Cuisine:",
          modaltext:
            "There are restaurants in Kathmandu and Pokhara that serve food from all over the world. There are a lot of restaurants that serve real foreign food.On the Annapurna base camp trail, you can find a good mix of Nepali, Indian, Tibetan, and Continental dishes. On the menu, you can also find pizza, pasta, steak, and burgers. But keep in mind that the taste might not be the same because there may not be as many spices or cooking methods.",
        },
        {
          modaltitle: "Nepali Flavors:",
          modaltext:
            "You cannot leave Nepal without trying some of its famous foods, like chatamari, which is kind of like a Nepali pizza. In the same way, you should try yomori, momo, sekuwa, sukuti, taas, Newari khaja set, chyang, and other dishes.",
        },
        {
          modaltitle: "Super Food",
          modaltext:
            "Dal bhat tarkari will be the main meal during the trek. It means rice, lentil soup, vegetables, and a few other dishes on the side. Dal bhat is the main meal in Nepal, and it has all the nutrients you need. This is one of the freshest things you can eat while trekking, and it does not cost too much. Dal bhat will not make you feel sick or make you bloated.",
        },
        {
          modaltitle: "Suitability:",
          modaltext:
            "There are plenty of options for vegetarians, vegans, and gluten-free diners.",
        },
      ],
    },
    {
      id: 5,
      modaldetail: [
        {
          modaltitle: "Annapurna Region:",
          modaltext:
            "Annapurna region is the western part of Nepal. The region houses the seventh, eighth, and tenth highest mountains in the world; Dhaulagiri, Manaslu & Annapurna. The region is protected under the Annapurna Conservation Area. In the north of the main Himalayan ranges (Annapurna & Dhaulagiri) is the trans-Himalayan area and boasts arid landscape & Tibetan Buddhism cultural heritage. Annapurna region is famous for its adventurous trekking routes.",
        },
        {
          modaltitle: "Difficulty Level:",
          modaltext:
            "The difficulty level of the Annapurna base camp trek from Kathmandu/Pokhara is marked moderate. As the trek progresses and you touch high elevation, other factors like altitude, isolation of the region, etc add up to the difficulty. Overall the journey is moderate to difficult because you spend a long time in the mountains.",
        },
        {
          modaltitle: "Terrain:",
          modaltext:
            "The terrain is diverse. You begin walking through subtropical forests. Some part of the forested trail is dense and damp. There are many rocky staircases you walk on. Likewise, walking alongside foothills, you'll ascend and descend throughout the trek. The latter part of the trail involves meadows and an alpine zone. You walk on steep, rocky paths.",
        },
        {
          modaltitle: "Altitude Sickness:",
          modaltext:
            "The Annapurna base camp trek involves altitude sickness risks. Do not take altitude sickness lightly because, in the worst case, we have to evacuate you to a hospital in Pokhara or Kathmandu. The chances of altitude sickness during the trek get low if you follow the precautions. Our crew will make sure you are not being careless and doing things that may trigger altitude sickness while trekking.",
        },
        {
          modaltitle: "Team:",
          modaltext:
            "Our on-field team is very experienced and knowledgeable about the Annapurna base camp trek route. They are trained to guide travelers in the Himalayas and can tackle any emergency situation. All of our team members are vaccinated for Covid-19. We make sure they are healthy before embarking on any treks.",
        },
        {
          modaltitle: "Support:",
          modaltext:
            "We give full support for the Annapurna base camp trek in Nepal. We take care of your accommodation and meals, arrange the permits, and help you with little things like getting a sim card, airport pickup/drop, etc. Likewise, during the trek, you trek with a reliable and experienced trekking guide and porters. Our guides not only show the trail, but also bridge the gap between locals and tourists. ",
        },
        {
          modaltitle: "Equipment:",
          modaltext:
            "The Annapurna base camp trek does not need any advanced climbing gear. We'll provide you with a trekking packing list that you can follow. You can buy most of the trekking clothes and gear in Thamel, Kathmandu. Therefore, no heavy luggage. Moreover, there are also renting options available, so you do not have to spend lots of money if you are not an active trekker.",
        },
      ],
    },
    {
      id: 6,
      modaldetail: [
        {
          modaltitle: "Overview:",
          modaltext:
            "Our 13 days Annapurna base camp trekking has the best itinerary, with adequate rest days. You get to spend nights in some of the most beautiful villages, including Ulleri, Ghorepani, Chomrong, Himalaya, etc. The warm hospitality of the locals and their lifestyle will amaze you. You'll go on a side excursion to Poon Hill to catch a surreal sunrise over snow-capped mountains before leaving Ghorepani village. The trail goes along Modi Khola and Kimrong Khola. You'll spend the night at the Annapurna base camp, as there are a few lodges available there, and descend to Jhinu Danda to enjoy a natural hot spring.",
        },
        {
          modaltitle: "Restaurants & Tea Shops:",
          modaltext:
            "In cities, you can find many different kinds of food. There are tea shops along the Annapurna base camp trekking route where you can stop and buy snacks. Along the trail, the tea shops do not have many snacks, and the prices are higher than in the city.",
        },
        {
          modaltitle: "ATM/Currency:",
          modaltext:
            "There is no ATM on the trail to Annapurna's base camp. In Kathmandu and Pokhara, you can get Nepali cash from an ATM. Most ATMs let you use international cards to get money out. In the same way, you can change your money into Nepalese currency at a bank or money exchange in Thamel. ",
        },
        {
          modaltitle: "Internet:",
          modaltext:
            "During the Annapurna base trek , you can use both Wi-Fi and cell service. Wi-Fi is not available everywhere on the trail, nor is it a cheap option. We help our guests buy a Nepali SIM card with enough data to get them to Everest Base Camp, if they want to. Do know that the high elevation will make the network less stable and smooth.",
        },
        {
          modaltitle: "Flora + Fauna:",
          modaltext:
            "The trail goes through the Annapurna Conservation Area, which has a lot of plants and animals. The conservation area protects 1,226 flowering plant species, 105 mammal species, 518 bird species, 40 reptile species, and 23 amphibian species.",
        },
        {
          modaltitle: "Locals:",
          modaltext:
            "Along the Annapurna base camp trail, people from different groups like Brahmins, Chhetri, Gurung, Magar, etc. live. Most of them follow either Buddhism or Hinduism. They are very friendly and helpful. Spend some time getting to know them.  You can Start the conversation with Namaste and a big smile.",
        },
        {
          modaltitle: "Language:",
          modaltext:
            "People will be speaking in a variety of languages and dialects. Nepali is the language that most people speak. You will not  have any trouble talking to people in English in cities. Most of the people you meet on the trail can talk to you in simple English.",
        },
        {
          modaltitle: "Monasteries:",
          modaltext:
            "On the way to Annapurna base camp, there are temples, stupas, chortens, mani walls, prayer flags, and mani walls.",
        },
      ],
    },
  ];
  const expandHandler = () => {
    setExpandModal(!expandModal);
  };

  const collapseHandler = () => {
    setExpandModal(false);
  };

  const onTripChange = () => {
    sessionStorage.removeItem("tripTitle");
    sessionStorage.removeItem("tripPrice");
    sessionStorage.setItem("tripTitle", tripTitle);
    sessionStorage.setItem("tripPrice", tripPrice);
  };

  return (
    <>
      <div className={`modals ${expandModal ? "expand" : ""}`}>
        <div className={`modal-head `} onClick={expandHandler}>
          <div className="modal-head-title">
            {!expandModal ? (
              <AiOutlineArrowUp fontSize="2rem" className="px-1" />
            ) : (
              <AiOutlineArrowDown fontSize="2rem" className="px-1" />
            )}
            <div className="head-title">{modalhead}</div>
          </div>
          <div className="iclose">
            {expandModal && (
              <AiOutlineCloseCircle
                fontSize="1.3rem"
                onClick={collapseHandler}
              />
            )}
          </div>
        </div>
        <div className={`modal-body ${expandModal ? "modal-body-expand" : ""}`}>
          {!expandModal ? (
            <>
              {modaldetail &&
                modaldetail?.map((detail) => (
                  <div className="modal-content">
                    <div className="modal-title d-none d-sm-flex">
                      {detail.modaltitle}
                    </div>
                    <div className="modal-text">{detail.modaltext}</div>
                  </div>
                ))}
            </>
          ) : (
            <>
              {modaldetailtrip &&
                modaldetailtrip[id - 1]?.modaldetail?.map((detail) => (
                  <div className="modal-content">
                    <div className="modal-title fw-bold">{detail.modaltitle}</div>
                    <div className="modal-text">{detail.modaltext}</div>
                  </div>
                ))}
            </>
          )}
        </div>
        <HashLink
          smooth
          to="/booking"
          className="modal-book link"
          style={{ color: "#000" }}
          onClick={onTripChange}
        >
          BOOK NOW
        </HashLink>
      </div>
    </>
  );
};

export default Modals;
