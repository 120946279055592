import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import CancelIcon from "@mui/icons-material/Cancel";

const TripDetail = () => {
  return (
    <>
      <div className='trip-detail container mt-5'>
        <div className='row'>
          <div className='col-2 d-none d-lg-block d-xl-block'>
            <div className='trip-sidebar'>
              <ul>
                <li>
                  <a href='#price'>Price Detail</a>
                </li>
                <li>
                  <a href='#highlight'>Highlight</a>
                </li>
                <li>
                  <a href='#include'>Cost Include</a>
                </li>
                <li>
                  <a href='#exclude'>Cost Exclude</a>
                </li>
                <li>
                  <a href='#disclaimer'>Disclaimer of Itinerary</a>
                </li>
                <li>
                  <a href='#difference'>
                    Difference Between Annapurna Base Camp and Annapurna Circuit
                  </a>
                </li>
                <li>
                  <a href='#important-note'>Important Note</a>
                </li>
                <li>
                  <a href='#safety'>Safety and Security</a>
                </li>
                <li>
                  <a href='#insurance'>Travel Insurance</a>
                </li>
                <li>
                  <a href='#age'>Minimum Age</a>
                </li>
                <li>
                  <a href='#time'>Best Time</a>
                </li>
                <li>
                  <a href='#tipping'>Tipping</a>
                </li>
                <li>
                  <a href='#activities'>Activities You Can Choose</a>
                </li>
                <li>
                  <a href='#group-leader'>Group Leader - What They Do</a>
                </li>
                <li>
                  <a href='#group-notes'>Size of Group Notes</a>
                </li>
                <li>
                  <a href='#transport'>Transport</a>
                </li>
                <li>
                  <a href='#local-flight'>Local Flight</a>
                </li>
                <li>
                  <a href='#solo-traveler'>Solo Travelers</a>
                </li>
                <li>
                  <a href='#joining'>Joining Instructions</a>
                </li>
                <li>
                  <a href='#arrival'>Arrival Complications</a>
                </li>
                <li>
                  <a href='#emergency-contact'>Emergency Contanct</a>
                </li>
                <li>
                  <a href='#visa'>Visa and Entry Requirement</a>
                </li>
                <li>
                  <a href='#money-exchange'>Money Exchange</a>
                </li>
                <li>
                  <a href='#emergency-fund'>Emergency Fund</a>
                </li>
              </ul>
            </div>
          </div>

          <div className='trip-info col-md-12 col-lg-10'>
            {/* ---------------- price starts --------------------- */}
            <h4 id='price' className='trip-detail-title'>
              Price Detail
            </h4>
            Luxury Price: USD $1260
            <br />
            Budget Price: USD $570
            {/* <h4 className="trip-detail-title">Group Discount</h4>
            1-2 pax --------- USD $N/A
            <br />
            upto 5 pax ---- USD $N/A
            <br />
            upto 10 pax ---- USD $N/A */}
            {/* ---------------- trip highlight starts --------------------- */}
            <h4 id='highlight' className='trip-detail-title'>
              Highlights
            </h4>
            <ul className='trip-highlight'>
              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Walk through lush rhododendron forests and meadows, crossing
                  thrilling suspension bridges
                </li>
              </span>

              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>Overnight stays in picturesque villages along the trail</li>
              </span>

              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Go on a side excursion to Gangapurna Lake (ice lake) during
                  acclimatization day in Manang village
                </li>
              </span>

              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>Unwind and relax at the shore of Tilicho Lake</li>
              </span>

              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>Cross the highest pass in Nepal, Thorong La</li>
              </span>

              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>Get blessing in the Muktinath Temple, a pilgrimage site</li>
              </span>

              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Walking along the deepest gorge in the world, Kali Gandaki
                  gorge
                </li>
              </span>

              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Pass by beautiful apple orchards and white-washed houses on
                  the way to Jomsom
                </li>
              </span>

              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Enjoy natural hot springs in Tatopani and hike to the famous
                  Poon hill viewpoint to catch gorgeous sunrise
                </li>
              </span>

              <span>
                <div className='trip-highlight-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Spend a night in Pokhara, enjoying tranquility of Phewa Lake
                </li>
              </span>
            </ul>
            {/*---------------------- cost include starts --------------------- */}
            <h4 id='include' className='trip-detail-title'>
              Cost Include
            </h4>
            <ul className='cost-include'>
              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  All ground transportation, including airport transfers, is
                  done by private car.
                </li>
              </span>
              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Drive from Pokhara from Kathmandu by luxurious tourist bus.{" "}
                </li>
              </span>
              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>Drive from Kathmandu to Besisahar via bus.</li>
              </span>

              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>Drive from Muktinath to Tatopani to Jomsom by bus.</li>
              </span>

              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  One night accommodation in a standard hotel at kathmandu with
                  breakfast included. (as per the itinerary)
                </li>
              </span>

              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  One night accommodation in a standard hotel at Pokhara with
                  breakfast included. (as per the itinerary)
                </li>
              </span>

              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  The best available lodge for two people to share during the
                  trek
                </li>
              </span>

              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  During the trek, you will be provided with three meals a day:
                  breakfast, lunch, and dinner with one cup of tea.{" "}
                </li>
              </span>

              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  The best available lodge for two people to share during the
                  trek
                </li>
              </span>

              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  A map of a trek and a certificate of completion of the trip
                </li>
              </span>

              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>Taxes and official costs paid to the government.</li>
              </span>

              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Oxymeter to check your pulse, oxygen saturation, and heart
                  rate twice a day (very helpful to check for signs of Altitude
                  Mountain Sickness, or AMS). This will make sure that your trek
                  is in the safest hands possible, no matter how experienced you
                  are.
                </li>
              </span>

              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  Strong, helpful porters with the right safety gear and walking
                  gear, as well as a salary, food, a place to live, and
                  insurance. (one porter for two people).
                </li>
              </span>

              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  A highly experienced, helpful, knowledgeable, friendly,
                  English-speaking guide with all of his salary, food, drinks,
                  lodging, transportation, and insurance covered.
                </li>
              </span>

              <span>
                <div className='cost-include-icon'>
                  <FaCheckCircle />
                </div>
                <li>
                  All papers that need to be done, Fees for entering permits you
                  need to go while hiking.
                </li>
              </span>
            </ul>
            {/*---------------------- cost exclude starts --------------------- */}
            <h4 id='exclude' className='trip-detail-title'>
              Cost Exclude
            </h4>
            <ul className='cost-exclude'>
              <span>
                <div className='cost-exclude-icon'>
                  <CancelIcon />
                </div>
                <li>
                  Food that you will eat in Kathmandu for lunch and dinner
                </li>
              </span>

              <span>
                <div className='cost-exclude-icon'>
                  <CancelIcon />
                </div>
                <li>
                  <p>
                    The cost of a visa to enter Nepal is: <br />
                    $30 USD for a 15-day stay
                    <br />
                    30 Days - $50, <br />
                    90 Days - $125
                  </p>
                </li>
              </span>

              <span>
                <div className='cost-exclude-icon'>
                  <CancelIcon />
                </div>
                <li>International Airfare.</li>
              </span>

              <span>
                <div className='cost-exclude-icon'>
                  <CancelIcon />
                </div>
                <li>Your personal expenses.</li>
              </span>

              <span>
                <div className='cost-exclude-icon'>
                  <CancelIcon />
                </div>
                <li>
                  All of the alcoholic and non-alcoholic cold and hot drinks you
                  buy on trek (along the way and in the Tea Houses at night).
                </li>
              </span>

              <span>
                <div className='cost-exclude-icon'>
                  <CancelIcon />
                </div>
                <li>
                  All of the sweet desserts, like chocolate, cake, pie, and
                  pudding.
                </li>
              </span>

              <span>
                <div className='cost-exclude-icon'>
                  <CancelIcon />
                </div>
                <li>
                  The tea houses have hot showers and places to charge your
                  phone which will cost you about 2$ to 5$
                </li>
              </span>

              <span>
                <div className='cost-exclude-icon'>
                  <CancelIcon />
                </div>
                <li>
                  Tip to the guide, the porter, and the driver. (Tipping is
                  expected)
                </li>
              </span>
            </ul>
            {/*---------------------- Disclaimer of Itinerary starts --------------------- */}
            <h4 id='disclaimer' className='trip-detail-title'>
              Disclaimer of Itinerary
            </h4>
            <p className='trip-detail-content'>
              As this trip takes place in the Himalayas, full of uncertainties,
              we may have to make changes to the itinerary if the situation
              demands it. We'll be following the same itinerary we mentioned,
              however if circumstances ask, the team leader will change it
              considering the best interest of the group. We request you to
              cooperate with him if something like this comes up in the
              mountain.
            </p>
            {/*---------------------- important notes starts --------------------- */}
            <h4 id='important-note' className='trip-detail-title'>
              Important Notes
            </h4>
            <p className='trip-detail-content'>
              Below we have mentioned a few things that you should know before
              joining the Annapurna circuit with Tilicho lake trek:
            </p>
            <ul>
              <li>
                It is a high-altitude trek that goes above 5,000 meters and
                explores an isolated area. There are risks of altitude sickness.
                Likewise, your body may see changes or react in an unexpected
                way because of a drastic elevation shift. So, have a
                consultation with your doctor and get to know all the possible
                side effects.
              </li>
              <li>
                The Annapurna circuit trail is famous but strenuous. So, do not
                take it lightly. The difficulties are real and may take a toll
                on your mind & body if the trek is not done wisely.
              </li>
              <li>
                Keep yourself hydrated during the trek to avoid altitude
                sickness. Do not drink water directly from the tap or stream.
                You may not digest it. Carry a reusable water bottle and refill
                boiled water in the lodges.
              </li>
              <li>
                Do not wear new trekking boots on the trail. You'll end up with
                blisters and have a hard time walking.
              </li>
            </ul>
            {/*---------------------- safety and security starts --------------------- */}
            <h4 id='safety' className='trip-detail-title'>
              Safety and Security
            </h4>
            <p className='trip-detail-content'>
              Traveling to any new country comes with risks. Some places may be
              very tourist friendly, whereas some can get a little scary. You
              have to choose the destination wisely while keeping the factors
              like the best time, your traveling partner, the local culture,
              your budget, etc in mind. In regards to Nepal, it is one of the
              safest countries to travel to. <br />
              You can go on treks & tours solo or in a group. We, Adventure
              Master, have a long legacy in the travel and hospitality industry
              in Nepal. We have been organizing adventurous treks and tours in
              Nepal for many years. Our core team is made up of backpackers who
              have first-hand trekking experience in the mountains of Nepal.
              Therefore we are very attentive to the smallest details that make
              traveling with us comfortable and a lifetime experience. <br />
              Best mountain guides and porters assist you while trekking. You
              can trek freely as our team will take care of your safety and
              needs. In regards to Covid-19, all we have to say is the country
              is open to all travelers. The active cases are very low, and the
              patients are getting well-treated. Our entire team is vaccinated
              and healthy.
            </p>
            {/*---------------------- travel insurance starts --------------------- */}
            <h4 id='insurance' className='trip-detail-title'>
              Travel Insurance
            </h4>
            <p className='trip-detail-content'>
              Travel insurance is important for around Annapurna trek. It is a
              very challenging trek with potential risks of altitude sickness
              and other unseen events. We mandatorily need travel insurance that
              covers emergency air evacuation for the Annapurna circuit with
              Tilicho lake trek. If any medical emergency occurs while trekking,
              regardless of all the efforts we put in, we use travel insurance
              to give a quick medical response to our guests.
            </p>
            {/*---------------------- minimum age starts --------------------- */}
            <h4 id='age' className='trip-detail-title'>
              Minimum Age
            </h4>
            <p className='trip-detail-content'>
              Annapurna circuit trek with Tilicho lake excursion is not for
              minors/toddlers. If you are below 18 years old, then the only way
              you can join this trek with us is by trekking with an identified
              guardian and proving the needed physical fitness for the trek.
            </p>
            {/*---------------------- best time starts --------------------- */}
            <h4 id='time' className='trip-detail-title'>
              Best Time
            </h4>
            <p className='trip-detail-content'>
              The best time to do the Annapurna circuit is from March to April
              and October to November. These months are the peak trekking time,
              so you'll see a lot of trekkers. If you want to experience more of
              a tranquil walk, then trek in early May & December or late
              September or February when either peak trekking season is about to
              start or end. <br />
              Trekking in the Spring and Autumn seasons lets you enjoy the best
              views and stable weather. The moderate climate makes the strenuous
              walk pleasant. The seasonal vegetation decorates the trail, and
              the blooming rhododendron forests, green pastures, etc enhance the
              trekking route.
            </p>
            {/*---------------------- tipping starts --------------------- */}
            <h4 id='tipping' className='trip-detail-title'>
              Tipping
            </h4>
            <p className='trip-detail-content'>
              Our Annapurna circuit and Tilicho Lake trek cost do not cover
              tips. We let our guests tip our on-field crew members (driver,
              tour guide, mountain guide & porters) as per their wish. Tipping
              is a custom in Nepal and is expected at the end of the journey.
              <br />
              It is not mandatory though. If you are not happy with the service
              provided by the team, you can refuse to tip them. It's all up to
              your wish. But if you love the service, then the tip is the best
              way to say thank you.
            </p>
            {/*---------------------- Activities You Can Choose starts --------------------- */}
            <h4 id='activities' className='trip-detail-title'>
              Activities You Can Choose
            </h4>
            <p className='trip-detail-content'>
              First of all, you can tailor-make the Annapurna circuit trek with
              Tilicho lake with us. You can do the{" "}
              <strong>Annapurna circuit trek</strong> and the{" "}
              <strong>Tilicho Lake trek</strong> separately too. We have the
              best Annapurna circuit trek package. <br />
              <strong>Manaslu circuit trek</strong> and{" "}
              <strong>Kanchenjunga circuit trek</strong> are two great
              alternatives to the Annapurna circuit. The trail is far more
              offbeat and isolated. <br />
              You can also check out these famous trek & tour packages-{" "}
              <strong>
                Upper Mustang trek, Everest base camp trek, Everest three high
                passes trek, and Annapurna base camp trek.
              </strong>
            </p>
            {/*---------------------- group leader starts --------------------- */}
            <h4 id='group-leader' className='trip-detail-title'>
              Group Leader - What They Do
            </h4>
            <p className='trip-detail-content'>
              During the Annapurna circuit with Tilicho lake trekking, one
              experienced and licensed mountain guide will accompany you along
              with a porter. Depending upon the group size, we'll also give an
              assistant guide and additional porters. <br />
              Our mountain guides have been working and leading foreign trekkers
              in the mountains for numerous years, so you can rely on them and
              have a safe journey.
              <br />
              Likewise, our on-field crew is very friendly and can fluently
              speak English and many other languages, so you'll also not have a
              hard time communicating with them.
            </p>
            {/*---------------------- Size of Group Notes starts --------------------- */}
            <h4 id='group-notes' className='trip-detail-title'>
              Size of Group Notes
            </h4>
            <p className='trip-detail-content'>
              We lead groups of all sizes in the Himalayas. Whether you are
              trekking with your loved ones in a big group or planning a solo
              trip in the Annapurna region, Adventure Master will become your
              reliable travel partner. <br />
              For solo trekkers, we have a private trek option for you, or you
              can also join one of our departure dates to trek in a group with
              individuals from all around the world. <br />
              Our groups range between 2 to 12 people. For groups bigger than 8
              trekkers, an assistant guide will also join. We suggest you share
              your requirements with us. We'll organize the best Annapurna
              circuit trek with Tilicho lake for you.
            </p>
            {/*---------------------- transport starts --------------------- */}
            <h4 id='transport' className='trip-detail-title'>
              Transport
            </h4>
            <p className='trip-detail-content'>
              We use private four-wheelers for airport pick-up and drop-off.
              During the sightseeing tour in Kathmandu, we use a private vehicle
              too. While traveling to/from the starting and ending point of the
              trail, we'll use a private or a public vehicle as per the group
              size. All the vehicles are provided as per the itinerary.
            </p>
            {/*---------------------- local flights starts --------------------- */}
            <h4 id='local-flight' className='trip-detail-title'>
              Local Flight
            </h4>
            <p className='trip-detail-content'>
              No domestic flight is involved in our Annapurna circuit trek 17
              days. You have an option to choose a flight from Pokhara to
              Kathmandu at the end of the trek by paying a few additional bucks.
              You can also
              <strong>
                <em>
                  rent a helicopter to fly back to Kathmandu from Pokhara or a
                  helicopter flight to Pokhara from Jomsom.
                </em>
              </strong>
            </p>
            {/*---------------------- solo travelers starts --------------------- */}
            <h4 id='solo-traveler' className='trip-detail-title'>
              Solor Travelers
            </h4>
            <p className='trip-detail-content'>
              If you are a solo traveler, then you can be part of the Annapurna
              Circuit and Tilicho Lake trek either by doing a private trek with
              us or joining our group. During the peak trekking season, we have
              many groups embarking on the Annapurna circuit, so you can easily
              find a suitable date for yourself. At the same time, if you do not
              feel comfortable trekking with strangers, then opt for our private
              trek.
            </p>
            {/*---------------------- joining instructions starts --------------------- */}
            <h4 id='joining' className='trip-detail-title'>
              Joining Instructions
            </h4>
            <p className='trip-detail-content'>
              It's the right time to join the Annapurna circuit trek with
              Tilicho lake for 2023. The peak trekking season is right around
              the corner, so we are now actively taking{" "}
              <strong>
                bookings for the autumn 2023 Annapurna circuit trek.
              </strong>{" "}
              <br />
              Similarly, you can also do the{" "}
              <strong>
                booking for Annapurna circuit trek with Tilicho lake 2023/2024.
              </strong>{" "}
              You can send us a booking inquiry here or talk to our travel
              consultant if you have any questions.
            </p>
            {/*---------------------- arrival complications starts --------------------- */}
            <h4 id='arrival' className='trip-detail-title'>
              Arrival Complications
            </h4>
            <p className='trip-detail-content'>
              Our team will be at the airport to pick you up on the day of your
              arrival. In case you couldn't find them at the airport, feel free
              to give us a call on the number we shared with you or on our
              emergency number. There is free wifi at the airport, so you'll not
              have a hard time getting in touch with us. Similarly, if you
              couldn't make it to Kathmandu as per the itinerary, inform us as
              soon as you can. We'll rearrange everything as per your new
              arrival time.
            </p>
            {/*---------------------- emergency contact starts --------------------- */}
            <h4 id='emergency-contact' className='trip-detail-title'>
              Emergency Contact
            </h4>
            <p className='trip-detail-content'>
              These are our emergency numbers +977 9851033195, +44 7983806200
              and +977 9849780311. We are always available all the time on these
              two numbers. You can text or call us as you feel comfortable. Our
              Whatsapp & Viber service is also available on the same numbers.
              <br />
              Likewise, you can call us at our office number during office hours
              (Weekdays, 9 am to 5 pm local time)
            </p>
            {/*---------------------- visa and entry requirements starts --------------------- */}
            <h4 id='visa' className='trip-detail-title'>
              Visa and Entry Requirements
            </h4>
            <p className='trip-detail-content'>
              Adventure Master does not take responsibility for the Nepali visa.
              It's travelers responsibility to get a visa on time to join any
              trip with the company. The company will only provide a trip
              confirmation letter that will help you obtain the visa. Make sure
              your passport is valid and has at least a 6 month expiration time.
              Most travelers can get an on-arrival visa at all entry points of
              Nepal, including the Tribhuvan International Airport in Kathmandu.{" "}
              <br />
              Citizens of some countries cannot obtain an on-arrival visa, so
              check the visa status for your country before leaving for Nepal.
              Travelers from those countries can apply for a visa at the Nepali
              embassy or consulate office in their home country. If the
              travelers fail to get a visa and have to postpone/cancel the trip,
              then they have to bear the cancellation charge, as per the given
              conditions.
            </p>
            {/*---------------------- money exchange starts --------------------- */}
            <h4 id='money-exchange' className='trip-detail-title'>
              Money Exchange
            </h4>
            <p className='trip-detail-content'>
              The Nepali rupee is used in Nepal. You can easily exchange or
              withdraw money in big cities like Kathmandu, Pokhara, Chitwan,
              etc. Always have some Nepali cash on you because not all places
              accept cards or foreign currency, especially if you are not in
              major tourist areas. <br />
              You can withdraw Nepali cash using international cards from almost
              all ATMs in Kathmandu and Pokhara. Big hotels, shopping centers,
              restaurants, etc accept card payments too. While traveling in
              remote areas or small cities/towns use cash.
            </p>
            {/*---------------------- emergency fund starts --------------------- */}
            <h4 id='emergency-fund' className='trip-detail-title'>
              Emergency Fund
            </h4>
            <p className='trip-detail-content'>
              Keep a few hundred dollars aside in case you may have to bear any
              expenses. This also helps you if you decide at the last minute to
              extend your trip to Nepal or need to rebook any international
              flights.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default TripDetail;
