import { useEffect, useState } from "react";
import { Container, Modal } from "react-bootstrap";
import { HashLink } from "react-router-hash-link";
import Footer from "./components/Footer";
import { motion } from "framer-motion";
import Popupcard from "./components/popupcard";
import BannerVideo from "../video/banner1.mp4";
import EBCluxuryIcon from "./components/Luxurypackage/EBCluxuryIcon";
import EBCHeliReturnluxuryIcon from "./components/Luxurypackage/EBCHeliReturnluxuryIcon";
import AnnapurnaCircuitTilicholuxuryIcon from "./components/Luxurypackage/AnnapurnaCircuitTilicholuxuryIcon";
import MeraPeakluxuryIcon from "./components/Luxurypackage/MeraPeakluxuryIcon";
import MardiHimalLuxuryIcon from "./components/Luxurypackage/MardiHimalLuxuryIcon";
import ManasluCircuitLuxuryIcon from "./components/Luxurypackage/ManasluCircuitLuxuryIcon";

import EBCDepartureDateModal from "./components/DepartureDateModal/EBCDepartureDateModal";
import EBCHeliTourluxuryIcon from "./components/Luxurypackage/EBCHeliTourluxuryIcon";
import ManasluCircuitTrekDepartureDateModal from "./components/DepartureDateModal/ManasluCircuitTrekDepartureDateModal";
import Testimonial from "./components/HomeReview/Testimonial";
import IslandPeakClimbingWithEBCLuxuryIcon from "./components/Luxurypackage/IslandPeakClimbingWithEBCLuxuryIcon";
import AnnapurnaCircuitDepartureDateModal from "./components/DepartureDateModal/AnnapurnaCircuitDepartureDateModal";
import Article from "./components/Article/Article";
import SisterCompany from "./components/SisterCompany/SisterCompany";

const HomePage = () => {
  const [show, setShow] = useState(true);

  const handleClose = () => {
    setShow(false);
    sessionStorage.setItem("modalON", "open");
  };

  const checkModalOpen = () => {
    if (sessionStorage.getItem("modalON")) {
      setShow(false);
    }
  };

  useEffect(() => {
    checkModalOpen();
  });

  const cardVariants = {
    offscreen: {
      rotate: 0,
      y: 200,
    },
    onscreen: {
      rotate: 0,
      y: 0,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 3,
      },
    },
  };

  return (
    <div className="">
      <div className="flex hero bg-dark-overlay" id="top">
        <h3>
          {/* <video src={BannerVideo} autoPlay loop muted playsInline /> */}
        </h3>
        {/* <div className="home-down-icon text-center">
          <HashLink smooth to="#body" className="link">
            <FaChevronDown />
          </HashLink>
        </div> */}
      </div>

      <motion.div
        id="body"
        className=" hero2 sub-hero"
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true }}
      >
        <motion.h3 variants={cardVariants}>
          EVEREST BASE CAMP HELICOPTER TOUR
        </motion.h3>
        <motion.div variants={cardVariants} className="trip-box">
          <div className="trip-detail">
            <p className="main-title">Everest Base Camp Helicopter Tour</p>
            <div className="activity d-flex justify-content-center">
              <div className="luxury-package-info">
                <p className="text-center text-light ms-2">Luxury package </p>
              </div>
              <EBCHeliTourluxuryIcon />
            </div>
            {/* <div className="activity">
              <p>
                Private: <span className="trip-modal-title">$3900 pp</span>
              </p>
            </div> */}
            <div className="activity">
              <p>
                Sharing: <span className="trip-modal-title">$950 pp</span>
              </p>
            </div>

            <HashLink
              smooth
              to="/everest-base-camp-helicopter-tour/info"
              className="link"
            >
              <p className="view-trip">
                <div className="hover-underline-animation-black">VIEW TRIP</div>
              </p>
            </HashLink>
          </div>
        </motion.div>
      </motion.div>

      <motion.div
        id="body"
        className=" hero1 sub-hero"
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true }}
      >
        <motion.h3 variants={cardVariants}>
          EVEREST BASE CAMP TREK - 14 DAYS
        </motion.h3>
        <motion.div variants={cardVariants} className="trip-box">
          <div className="trip-detail">
            <p className="main-title">Everest Base Camp Trek</p>
            <div className="activity d-flex justify-content-center">
              <div className="luxury-package-info">
                <p className="text-center text-light ms-2">Luxury package </p>
              </div>
              <EBCluxuryIcon />
            </div>

            <div className="activity">
              <p>
                Activity: <span className="trip-modal-title">Trekking</span>
              </p>
              <p>
                Luxury Price: <span className="trip-modal-title">$1290 pp</span>
              </p>
              <p>
                Budget Price: <span className="trip-modal-title">$1090 pp</span>
              </p>
            </div>
            <EBCDepartureDateModal />
            <HashLink smooth to="/everest-base-camp-trek" className="link">
              <p className="view-trip">
                <div className="hover-underline-animation-black">VIEW TRIP</div>
              </p>
            </HashLink>
          </div>
        </motion.div>
      </motion.div>

      <motion.div
        id="body"
        className=" hero4 sub-hero"
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true }}
      >
        <motion.h3 variants={cardVariants}>
          EVEREST BASE CAMP HELICOPTER RETURN TREK - 9 DAYS
        </motion.h3>
        <motion.div variants={cardVariants} className="trip-box">
          <div className="trip-detail">
            <p className="main-title">
              Everest Base Camp Helicopter Return Trek
            </p>

            <div className="activity d-flex justify-content-center">
              <div className="luxury-package-info">
                <p className="text-center text-light ms-2">Luxury package </p>
              </div>
              <EBCHeliReturnluxuryIcon />
            </div>

            <div className="activity">
              <p>
                Activity: <span className="trip-modal-title">Heli Trek</span>
              </p>
              <p>
                Luxury Price: <span className="trip-modal-title">$2170 pp</span>
              </p>
              <p>
                Budget Price: <span className="trip-modal-title">$1740 pp</span>
              </p>
            </div>
            <HashLink
              smooth
              to="/everest-base-camp-helicopter-return-trek-9-days"
              className="link"
            >
              <p className="view-trip">
                <div className="hover-underline-animation-black">VIEW TRIP</div>
              </p>
            </HashLink>
          </div>
        </motion.div>
      </motion.div>

      <motion.div
        id="body"
        className=" hero3 sub-hero"
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true }}
      >
        <motion.h3 variants={cardVariants}>
          Annapurna Circuit Trek With Tilicho Lake and Poonhill
        </motion.h3>
        <motion.div variants={cardVariants} className="trip-box">
          <div className="trip-detail">
            <p className="main-title">
              Annapurna Circuit Trek With Tilicho Lake and Poonhill - 17 DAYS
            </p>

            <div className="activity d-flex justify-content-center">
              <div className="luxury-package-info">
                <p className="text-center text-light ms-2">Luxury package </p>
              </div>
              <AnnapurnaCircuitTilicholuxuryIcon />
            </div>

            <div className="activity">
              <p>
                Activity: <span className="trip-modal-title">Trekking</span>
              </p>
              <p>
                Luxury Price:
                {/* <del className="text-warning">$1499</del> */}
                <span className="trip-modal-title"> $1260 pp</span>
              </p>
              <p>
                Budget Price:
                {/* <del className="text-warning">$1499</del> */}
                <span className="trip-modal-title"> $570 pp</span>
              </p>
            </div>
            <AnnapurnaCircuitDepartureDateModal />
            <HashLink
              smooth
              to="/annapurna-circuit-with-tilicho-lake-and-poon-hill-17-days"
              className="link"
            >
              <p className="view-trip">
                <div className="hover-underline-animation-black">VIEW TRIP</div>
              </p>
            </HashLink>
          </div>
        </motion.div>
      </motion.div>

      <motion.div
        id="body"
        className=" hero8 sub-hero"
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true }}
      >
        <motion.h3 variants={cardVariants}>
          ISLAND PEAK CLIMBING WITH EBC TREK - 19 DAYS
        </motion.h3>
        <motion.div variants={cardVariants} className="trip-box">
          <div className="trip-detail">
            <p className="main-title">Island Peak Climbing With EBC Trek</p>
            <div className="activity d-flex justify-content-center">
              <div className="luxury-package-info">
                <p className="text-center text-light ms-2">Luxury package</p>
              </div>
              <IslandPeakClimbingWithEBCLuxuryIcon />
            </div>
            <div className="activity">
              <p>
                Activity: <span className="trip-modal-title">Climbing</span>
              </p>
              <p>
                Price: <span className="trip-modal-title">$2700 pp</span>
              </p>
            </div>
            <HashLink
              smooth
              to="/island-peak-climbing-with-ebc-19-days"
              className="link"
            >
              <p className="view-trip">
                <div className="hover-underline-animation-black">VIEW TRIP</div>
              </p>
            </HashLink>
          </div>
        </motion.div>
      </motion.div>

      <motion.div
        id="body"
        className=" hero7 sub-hero"
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true }}
      >
        <motion.h3 variants={cardVariants}>
          MARDI HIMAL BASE CAMP TREK - 9 DAYS
        </motion.h3>
        <motion.div variants={cardVariants} className="trip-box">
          <div className="trip-detail">
            <p className="main-title">Mardi Himal Base Camp Trek</p>
            <div className="activity d-flex justify-content-center">
              <div className="luxury-package-info">
                <p className="text-center text-light ms-2">Luxury package </p>
              </div>
              <MardiHimalLuxuryIcon />
            </div>

            <div className="activity">
              <p>
                Activity: <span className="trip-modal-title">Trekking</span>
              </p>
              <p>
                Luxury Price: <span className="trip-modal-title">$880 pp</span>
              </p>
              <p>
                Budget Price: <span className="trip-modal-title">$540 pp</span>
              </p>
            </div>
            <HashLink
              smooth
              to="/mardi-himal-base-camp-trek-9-days"
              className="link"
            >
              <p className="view-trip">
                <div className="hover-underline-animation-black">VIEW TRIP</div>
              </p>
            </HashLink>
          </div>
        </motion.div>
      </motion.div>

      <motion.div
        id="body"
        className=" hero5 sub-hero"
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true }}
      >
        <motion.h3 variants={cardVariants}>
          MANASLU CIRCUIT TREK - 14 DAYS
        </motion.h3>
        <motion.div variants={cardVariants} className="trip-box">
          <div className="trip-detail">
            <p className="main-title">Manaslu Circuit Trek</p>
            <div className="activity d-flex justify-content-center">
              <div className="luxury-package-info">
                <p className="text-center text-light ms-2">Luxury package </p>
              </div>
              <ManasluCircuitLuxuryIcon />
            </div>
            <div className="activity">
              <p>
                Activity: <span className="trip-modal-title">Trekking</span>
              </p>
              <p>
                Price: <span className="trip-modal-title">$1390 pp</span>
              </p>
            </div>
            <ManasluCircuitTrekDepartureDateModal />
            <HashLink smooth to="/manaslu-circuit-trek" className="link">
              <p className="view-trip">
                <div className="hover-underline-animation-black">VIEW TRIP</div>
              </p>
            </HashLink>
          </div>
        </motion.div>
      </motion.div>

      <motion.div
        id="body"
        className=" hero6 sub-hero"
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true }}
      >
        <motion.h3 variants={cardVariants}>
          MERA PEAK CLIMBING - 18 DAYS
        </motion.h3>
        <motion.div variants={cardVariants} className="trip-box">
          <div className="trip-detail">
            <p className="main-title">Mera Peak Climbing</p>
            <div className="activity d-flex justify-content-center">
              <div className="luxury-package-info">
                <p className="text-center text-light ms-2">Luxury package</p>
              </div>
              <MeraPeakluxuryIcon />
            </div>
            <div className="activity">
              <p>
                Activity: <span className="trip-modal-title">Climbing</span>
              </p>
              <p>
                Price: <span className="trip-modal-title">$2650 pp</span>
              </p>
            </div>
            <HashLink smooth to="/mera-peak-climbing-18-days" className="link">
              <p className="view-trip">
                <div className="hover-underline-animation-black">VIEW TRIP</div>
              </p>
            </HashLink>
          </div>
        </motion.div>
      </motion.div>

      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="first-modal text-center"
        style={{ maxWidth: "100%", zIndex: "9999999" }}
      >
        <Modal.Body className="p-5 first-modal-body align-items-center">
          {/* <div className="first-modal-text">Some of our best feature trips</div> */}
          <br />
          <Popupcard />
          {/* <div className="first-modal-text pb-5">Adventure Master Trek</div> */}
          <div onClick={handleClose} className="mt-3 btn btn-dark">
            CLOSE
          </div>
        </Modal.Body>
      </Modal>

      {/* <Container>
        <h4 className="title">Other services</h4>
        <div className="d-flex align-items-center justify-content-between">
          <div className="row d-flex flex-column">
            <div className="col mb-3">
              <FaBinoculars style={{ fontSize: "70px" }} />
            </div>
            <div className="col">Sightseeing</div>
          </div>
          <div className="row d-flex flex-column">
            <div className="col mb-3">
              <FaHiking style={{ fontSize: "70px" }} />
            </div>
            <div className="col">Trekking</div>
          </div>
          <div className="row d-flex flex-column">
            <div className="col mb-3">
              <FaHelicopter style={{ fontSize: "70px" }} />
            </div>
            <div className="col">Heli Tour</div>
          </div>
          <div className="row d-flex flex-column">
            <div className="col mb-3">
              <FaPaw style={{ fontSize: "70px" }} />
            </div>
            <div className="col">Safari</div>
          </div>
        </div>
      </Container> */}

      <Container className="my-5">
        <div>
          <Testimonial />
        </div>
      </Container>

      <Article />
      <SisterCompany />
      <hr style={{ width: "85%", margin: "1rem auto" }} />
      <Footer />
    </div>
  );
};

export default HomePage;
