import { useInView } from "react-intersection-observer";
import Modals from "./Modals";

import image1 from "../../../image/Manaslu/manasluTrek/12v3.jpg";
import image2 from "../../../image/Manaslu/manasluTrek/7.jpg";
import image3 from "../../../image/Manaslu/manasluTrek/8.jpg";
import image4 from "../../../image/Manaslu/manasluTrek/9.jpg";
import image5 from "../../../image/Manaslu/manasluTrek/6.jpg";
import image6 from "../../../image/Manaslu/manasluTrek/12v2.jpg";

const Overview = () => {
  const tripTitle = "Manaslu Circuit Trek - 14 Days";
  const tripPrice = "$1390 pp";
  const modaltrip = [
    {
      id: 1,
      modalhead: "Trip Overview",
      modaldetail: [
        {
          modaltitle: "Difficulty Level",
          modaltext: <strong>Difficult to Strenuous</strong>,
        },
        {
          modaltitle: "Maximum Altitude",
          modaltext: <strong>5,160 m/16,929 ft</strong>,
        },
        {
          modaltitle: "Best Season",
          modaltext: <strong>Spring & Autumn</strong>,
        },
      ],
      trip: "Manaslu Circuit Trek",
    },
    {
      id: 2,
      modalhead: "The Difficulty",
      modaldetail: [
        {
          modaltitle: "Difficult to strenuous",
          modaltext: <strong>Moderate to Difficult</strong>,
        },
        {
          modaltitle: "Maximum Altitude",
          modaltext: <strong>5,160 m/16,929 ft</strong>,
        },
      ],
      trip: "Manaslu Circuit Trek",
    },
    {
      id: 3,
      modalhead: "Accommodations",
      modaldetail: [
        {
          modaltitle: "Accommodation on the trail",
          modaltext: <strong>Tea house</strong>,
        },
        {
          modaltitle: "In Kathmandu",
          modaltext: <strong>Three-star hotel</strong>,
        },
        {
          modaltitle: "Style",
          modaltext: <strong>Shared</strong>,
        },
      ],
      trip: "Manaslu Circuit Trek",
    },
    {
      id: 4,
      modalhead: "Nutrition",
      modaldetail: [
        {
          modaltitle: "Meals in Kathmandu",
          modaltext: <strong>All breakfasts & welcome/farewell dinners</strong>,
        },
        {
          modaltitle: "Meals during the trek",
          modaltext: (
            <strong>Three meals a day (breakfast, lunch & dinner)</strong>
          ),
        },
        {
          modaltitle: "Cuisine",
          modaltext: <strong>Nepali, Tibetan, Continental & Indian</strong>,
        },
      ],
      trip: "Manaslu Circuit Trek",
    },
    {
      id: 5,
      modalhead: "Regeneration",
      modaldetail: [
        {
          modaltitle: "Morning stretching",
          modaltext:
            "Practice yoga & meditation by yourself (Or upgrade to yoga trekking, led by a professional yoga instructor)",
        },
        {
          modaltitle: "",
          modaltext: (
            <strong>Re-learn to live slow & be present in every moment</strong>
          ),
        },
      ],
      trip: "Manaslu Circuit Trek",
    },
    {
      id: 6,
      modalhead: "Destination",
      modaldetail: [
        {
          modaltitle: "Duration",
          modaltext: <strong>13 Days</strong>,
        },
        {
          modaltitle: "Location",
          modaltext: <strong>Manaslu Region, Nepal</strong>,
        },
        {
          modaltitle: "Province",
          modaltext: "",
        },
        {
          modaltitle: "Population",
          modaltext: <strong>9000++</strong>,
        },
        {
          modaltitle: "Wildlife Species",
          modaltext: <strong>255+</strong>,
        },
        {
          modaltitle: "Highest Points",
          modaltext: (
            <strong>
              Larkya la pass (5,160 m/16,929 ft) & Manaslu Base Camp (5,210 m/
              ft)
            </strong>
          ),
        },
      ],
      trip: "Manaslu Circuit Trek",
    },
  ];
  let sendmodaldetails = {};

  const { ref, inView } = useInView();
  const { ref: ref2, inView: inView2 } = useInView();
  const { ref: ref3, inView: inView3 } = useInView();
  const { ref: ref4, inView: inView4 } = useInView();
  const { ref: ref5, inView: inView5 } = useInView();
  const { ref: ref6, inView: inView6 } = useInView();

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll, true);
  // }, [handleScroll]);
  if (inView6 === true) {
    sendmodaldetails = modaltrip[5];
  } else if (inView5 === true) {
    sendmodaldetails = modaltrip[4];
  } else if (inView4 === true) {
    sendmodaldetails = modaltrip[3];
  } else if (inView3 === true) {
    sendmodaldetails = modaltrip[2];
  } else if (inView2 === true) {
    sendmodaldetails = modaltrip[1];
  } else if (inView === true) {
    sendmodaldetails = modaltrip[0];
  } else {
    sendmodaldetails = modaltrip[5];
  }
  return (
    <>
      <div ref={ref} className="trip-details-next container mt-5">
        <div className="trip-fact col-md-7 flex-sm-wrap mb-3">
          <div className="facts">
            <div className="fact mb-3">
              <h1>14 Days Manaslu Circuit Trek, to the base of the Mountain</h1>
            </div>
           
          </div>
        </div>
        <div ref={ref} className="title-content col-sm-12 col-md-7 mb-3">
          The best trek in Nepal is the Manaslu circuit trek. It takes a look at the beautiful Manaslu region and the things that are hidden there. The Manaslu circuit trek goes west of Annapurna (8,091 m/26,545 ft) and east of Ganesh Himal (7,422 m/24,350 ft). It goes through the Budhi Gandaki Gorge and crosses many rivers and waterfalls. Manaslu circuit trek is becoming more well-known as the best alternative to the well-known Annapurna circuit trek because the trail is less crowded and the trekking route is more exciting.
        </div>
        <div ref={ref} className="big-title col-sm-12 col-md-7 mb-3">
          MAJOR ATTRACTION DURING MANASLU CIRCUIT TREK
        </div>
        <div ref={ref} className="title-content col-sm-12 col-md-7 mb-3">
        You will be exploring the restricted area of Nepal called the Manaslu Conservation Area. People stay away from the Manaslu trail because it has strict rules and costs a lot to get a permit. The Manaslu circuit trekking lets you see how people live in the mountains, learn about their ancient culture, see a breathtaking view of the mountains, and see moraines and glaciers. You need a guide to do the Manaslu circuit trek. Western Himalayas mountains like Gyaji Kang (7,038 m/23,090 ft), Himlung Himal (7,126 m/23,345 ft), Manaslu (8,163 m/26,781 ft), Dhaulagiri (8,167 m/26,795 ft), Mt. Kang Guru (6,981 m/22,904 ft), Buddha Himal (6,692 m/21955 ft), etc, are seen along the trail.
        </div>

        <img
          ref={ref}
          src={image1}
          alt="manaslu"
          className="col-sm-12 col-md-12 mb-3 w-100"
        />

        <div
          ref={ref2}
          className="row wrap justify-content-between col-sm-12 my-3"
        >
          <div className="col-sm-12 col-md-6">
            <div className="big-title col-sm-12 mb-3">
            THE ROUGH MOUNTAINOUS TERRAIN, LIMITED SERVICES, AND ALMOST COMPLETE LACK OF MODERN COMFORTS MAKE YOU STRONGER AND MORE WILLING TO TAKE RISKS IN YOUR NEXT VENTURE.
            </div>
            <div className="title-content col-sm-12 mb-3">
            Trekking the Manaslu circuit is hard. It is one of the most difficult treks in the Himalayas, and it takes place on a rough path in the most remote part of Nepal. Himalayas on a rough path in one of the most remote parts of Nepal. The Manaslu region is very far away and hard to get to from the rest of the country. Locals do not go there that often. During this hike, you will really get to enjoy the wild. The trek pushes you a lot and keeps you close to your limit. You'll be putting your stamina and strength to the test.
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <img
              src={image2}
              alt="manaslu"
              className="col-sm-12 mb-3 img-fluid"
            />
          </div>
        </div>
        <div
          ref={ref3}
          className="flex row wrap justify-content-between col-sm-12 mt-5"
        >
          <div className="col-sm-12 col-md-6">
            <img
              src={image3}
              alt="manaslu"
              className="col-sm-12  mb-3 img-fluid"
            />
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="big-title col-sm-12 mb-3">
            THERE ARE TEA HOUSES ON THE MANASLU CIRCUIT TREK, AND THE LOCALS ARE VERY FRIENDLY. 
            </div>
            <div className="title-content col-sm-12 mb-3">
            If you think that the lodges in the mountains of Nepal are the most basic places to stay, the tea houses on the Manaslu trail will be even simpler. In the last few years, a lot of tea houses have opened on the Manaslu circuit trail, which has made it a little bit easier to deal with. There are small rooms with two twin beds and clean sheets. Toilets are common. The dining area is warmer than the rooms and has room for people to hang out. You no longer have to camp on the Manaslu circuit trek because there are simple tea houses along the way.
            </div>
          </div>
        </div>
        <div
          ref={ref4}
          className="row wrap justify-content-between col-sm-12 my-3"
        >
          <div className="col-sm-12 col-md-6">
            <div className="big-title col-sm-12 mb-3">
            Feast on dishes from Nepal and Tibet. TRY FLAVORS THAT MAKE YOU FEEL GOOD
            </div>
            <div className="title-content col-sm-12 mb-3">
            We can not  go to Nepal without trying some of the best food in the country. From the first day of the trek to the last, you will not only get to taste authentic Nepalese dishes that will make your mouth water, but you will also learn about exotic plants and animals that are not used anywhere else in the world. You will have a lot to choose from, like the famous Dal bhat tarkari, sukuti, and a variety of vegetarian dishes. We will make sure you also get to see Nepal through its food and understand the culture and significance of each dish.
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <img
              src={image4}
              alt="manaslu"
              className="col-sm-12 mb-3 img-fluid"
            />
          </div>
        </div>
        <div
          ref={ref5}
          className="flex row wrap justify-content-between col-sm-12 mt-5"
        >
          <div className="col-sm-12 col-md-6">
            <img
              src={image5}
              alt="manaslu"
              className="col-sm-12  mb-3 img-fluid"
            />
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="big-title col-sm-12 mb-3">
            SPEND 10 OR MORE DAYS IN NEPAL'S BEAUTIFUL MOUNTAINS AND ADMIRE THEM
            </div>
            <div className="title-content col-sm-12 mb-3">
            Traveling in the Himalayas is much more than just exciting and thrilling. It calms your soul, makes you grateful, makes you want to live a happy life, and gives you strength right now. At first, the simple living and fresh food might make you feel a little uncomfortable, but as the days go by, you will remember how good simple living can be. The smell of the forests, the cold of the tall mountains, the sound of the rushing rivers, and the sight of the falling waterfalls will refresh your spirit. At the end of the journey, your body will be light and your heart will be free.
            </div>
          </div>
        </div>
        <div
          ref={ref6}
          className="row wrap justify-content-between col-sm-12 my-3"
        >
          <div className="col-sm-12 col-md-6">
            <div className="big-title col-sm-12 mb-3">
            TREK AROUND MT. MANASLU , THE WORLD’S EIGHTH HIGHEST MOUNTAIN, ON OUR 13 DAYS MANASLU CIRCUIT TREK 
            </div>
            <div className="title-content col-sm-12 mb-3">
            In this part of the Himalayas, the Manaslu Conservation Area protects nature, the places where animals live, the people who live there, and the mountains. The area around Mt. Manaslu, which is the eighth highest mountain on Earth, is not as busy as the EBC and ABC trekking routes in Nepal. But trekking in the Manaslu area is especially popular with people who love to be challenged and can keep up with the off-beaten-path trail. A very beautiful place to hike and enjoy the outdoors. Hike to the base camp of Manaslu and then cross the Larkya La pass to end your trip.
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <img
              src={image6}
              alt="manaslu"
              className="col-sm-12 mb-3 img-fluid"
            />
          </div>
        </div>
      </div>
      {/* <div className="container map"></div> */}
      <div className="main-modal">
        <Modals
          sendmodaldetails={sendmodaldetails}
          tripTitle={tripTitle}
          tripPrice={tripPrice}
        />
      </div>
    </>
  );
};

export default Overview;
